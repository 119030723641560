import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as AlternateIdsActions from './alternate-ids.actions';
import { AlternateId, AlternateIdsRes } from './alternate-ids.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceAlternateIdsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlternateIdsActions.LoadAlternateIdsData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/alternate-ids?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AlternateIdsRes) => {
                            const payload: ResponseWrapper<AlternateIdsRes> = {
                                data: res,
                                loading: false
                            };
                            return AlternateIdsActions.LoadAlternateIdsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load Alternate IDs.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(AlternateIdsActions.LoadAlternateIdsDataError(error));
                        })
                    );
            })
        )
    );

    downloadAlternateIds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlternateIdsActions.DownloadAlternateIds),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/alternate-ids/download-list?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { content?: Array<string>, filename?: string }) => {
                            const blob: Blob = new Blob([...res.content?.join('\r\n')], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = res.filename;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return AlternateIdsActions.DownloadAlternateIdsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download alternate ids.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(AlternateIdsActions.DownloadAlternateIdsError(error));
                        })
                    );
            })
        )
    );

    getSelectedAlternateId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlternateIdsActions.GetSelectedAlternateId),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/alternate-ids/${action.payload.alternateId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AlternateId) => {
                            const payload: ResponseWrapper<AlternateId> = {
                                data: res,
                                loading: false
                            };
                            return AlternateIdsActions.GetSelectedAlternateIdSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load Alternate Id.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['self-service/main/alternate-ids']);
                            this.showError(null, errorMessage);
                            return of(AlternateIdsActions.GetSelectedAlternateIdError(error));
                        })
                    );
            })
        )
    );

    saveAlternateId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlternateIdsActions.SaveAlternateId),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/alternate-ids?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess(`Alternate ID ${action.payload?.customerExternalNamespaceId ? 'saved' : 'created'} successfully.`);
                            this.router.navigate(['self-service/main/alternate-ids']);
                            return AlternateIdsActions.SaveAlternateIdSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to ${action.payload?.customerExternalNamespaceId ? 'save' : 'create'} Alternate ID.`;
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(AlternateIdsActions.SaveAlternateIdError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
