import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { ManageUsersState } from './interfaces';

const initalAppState: ManageUsersState = {
    user: null,
    initiated: false,
    databases: null,
    toast: null,
    manageUsers: {
        loading: false,
        data: {
            items: [],
            totalItems: 0
        }
    },
    userStatus: null,
    deleteManageUsers: {
        loading: false,
        data: null
    },
};

function getInitalState(): ManageUsersState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initalAppState;
    // }
}

function getSavedState(): ManageUsersState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.GetManageUsers, (state) => {
        return {
            ...state,
            manageUsers: {
                loading: true,
                data: {
                    items: state.manageUsers.data.items,
                    totalItems: state.manageUsers.data.totalItems
                }
            }
        };
    }),
    on(AppActions.GetManageUsersSuccess, (state, action) => {
        return {
            ...state,
            manageUsers:  {
                loading: false,
                data: {
                    items: action.payload?.data?.items || [],
                    totalItems: action.payload?.data?.totalItems || 0
                }
            }
        };
    }),
    on(AppActions.UpdateManageUsers, (state) => {
        return {
            ...state,
            userStatus: {
                loading: true,
            }
        };
    }),
    on(AppActions.UpdateManageUsersSuccess, (state) => {
        return {
            ...state,
            userStatus:  {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.DeleteManageUsers, (state) => {
        return {
            ...state,
            deleteManageUsers: {
                loading: true
            }
        };
    }),
    on(AppActions.DeleteManageUsersSuccess, (state, action) => {
        return {
            ...state,
            deleteManageUsers:  {
                loading: false,
                data: action.payload,
                success: true
            }
        };
    }),
    on(AppActions.DeleteManageUsersError, (state, action) => {
        return {
            ...state,
            deleteManageUsers:  {
                loading: false,
                data: state.deleteManageUsers?.data,
                error: action.error
            }
        };
    }),
);

// tslint:disable-next-line: typedef
export function manageUsersReducer(state: ManageUsersState | undefined, action: Action) {
    return appReducer(state, action);
}
