import { on } from '@ngrx/store';
import * as OptOutActions from './opt-out.actions';
import { IEmailBuilderState } from '../../interfaces';

export const optOutReducer = [
    on(OptOutActions.GetOptOutOrganizationSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state?.optOutSettings?.data,
                    organizationSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutOrganizationSettingsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: action?.payload?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutOrganizationSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutDatabaseSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutDatabaseSettingsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state.optOutSettings.data.organizationSettings.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: action.payload.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutDatabaseSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state.optOutSettings.data.organizationSettings.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state.optOutSettings.data.databaseSettings.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutTypeSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state.optOutSettings.data.organizationSettings.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state.optOutSettings.data.databaseSettings.data
                    },
                    typeSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.GetOptOutTypeSettingsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state.optOutSettings.data.organizationSettings.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state.optOutSettings.data.databaseSettings.data
                    },
                    typeSettings: {
                        loading: false,
                        data: action.payload.data
                    }
                },
            },
        };
    }),
    on(OptOutActions.GetOptOutTypeSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state.optOutSettings.data.organizationSettings.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state.optOutSettings.data.databaseSettings.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state.optOutSettings.data.typeSettings.data
                    }
                },
            },
        };
    }),
    //////////////////
    on(OptOutActions.UpdateOptOutOrganizationSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.UpdateOptOutOrganizationSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.UpdateOptOutDatabaseSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.UpdateOptOutDatabaseSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.UpdateOptOutTypeSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: true,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            }
        };
    }),
    on(OptOutActions.UpdateOptOutTypeSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            optOutSettings: {
                data: {
                    ...state.optOutSettings.data,
                    organizationSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.organizationSettings?.data
                    },
                    databaseSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.databaseSettings?.data
                    },
                    typeSettings: {
                        loading: false,
                        data: state?.optOutSettings?.data?.typeSettings?.data
                    }
                },
            },
        };
    }),
];