import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DeployemntProductsActions from './deployment-products.actions';
import { DeploymentDesignation, DeploymentProductsListRes, DeploymentTypesWithoutProductRes, SelectedDeploymentProductData } from './deployment-products.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceDeploymentProductsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadDeployemntProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.LoadDeploymentProducts),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('status', action.payload.status || '');
                searchParams.set('productType', action.payload.productType?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DeploymentProductsListRes) => {
                            const payload: ResponseWrapper<DeploymentProductsListRes> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntProductsActions.LoadDeployemntProductsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load deployment products data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.LoadDeployemntProductsDataError(error));
                        })
                    );
            })
        )
    );

    downloadDeploymentProductsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.DownloadDeployemntProductsList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product/download-list?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = 'deployment-products';
                            a.click();
                            window.URL.revokeObjectURL(url);

                            return DeployemntProductsActions.DownloadDeployemntProductsListSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download deployment products list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.DownloadDeployemntProductsListError(error));
                        })
                    );
            })
        )
    );

    getProductDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.GetProductDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDeploymentProductData) => {
                            const payload: ResponseWrapper<SelectedDeploymentProductData> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntProductsActions.GetProductDetailsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.router.navigate(['self-service', 'main', 'deployment-products'], { relativeTo: this.route });
                            let errorMessage = 'An error has occurred while trying to get product details data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.GetProductDetailsError(error));
                        })
                    );
            })
        )
    );

    createDeploymentProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.CreateDeploymentProduct),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/deployment-product?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDeploymentProductData) => {
                            const payload: ResponseWrapper<SelectedDeploymentProductData> = {
                                data: res,
                                loading: false
                            };
                            this.showSuccess('',`Deployment Product has been ${action?.payload?.productId ? 'updated' : 'created'} successfully.`);
                            this.router.navigate(['self-service', 'main', 'deployment-products'], { relativeTo: this.route });
                            return DeployemntProductsActions.CreateDeploymentProductSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to ${action?.payload?.productId ? 'Update' : 'Create'} deployment product.`;
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.CreateDeploymentProductError(error));
                        })
                    );
            })
        )
    );

    updateEmailDeploymentProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.UpdateEmailDeploymentProduct),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(environment.settingsApiEndPoint + `/deployment-product/email-deployment?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDeploymentProductData) => {
                            const payload: ResponseWrapper<SelectedDeploymentProductData> = {
                                data: res,
                                loading: false
                            };
                            this.showSuccess('Product has been updated successfully.');
                            return DeployemntProductsActions.UpdateEmailDeploymentProductSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update email deployment product.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.UpdateEmailDeploymentProductError(error));
                        })
                    );
            })
        )
    );

    getDeploymentTypesWithoutProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.GetDeploymentTypesWithoutProducts),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product/deployment-types-with-no-products?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DeploymentTypesWithoutProductRes) => {
                            const payload: ResponseWrapper<DeploymentTypesWithoutProductRes> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntProductsActions.GetDeploymentTypesWithoutProductsSuccess({ payload });
                        }),
                        catchError((error) => {
                            return of(DeployemntProductsActions.GetDeploymentTypesWithoutProductsError(error));
                        })
                    );
            })
        )
    );

    getDeploymentDesignations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.GetDeploymentDesignations),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product/designations?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<DeploymentDesignation>) => {
                            const payload: ResponseWrapper<Array<DeploymentDesignation>> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntProductsActions.GetDeploymentDesignationsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get product details data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.GetDeploymentDesignationsError(error));
                        })
                    );
            })
        )
    );

    getDeploymentDomains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntProductsActions.GetDeploymentDomains),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-product/deployment-domains?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<DeploymentDesignation>) => {
                            const payload: ResponseWrapper<Array<DeploymentDesignation>> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntProductsActions.GetDeploymentDomainsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get product details data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntProductsActions.GetDeploymentDomainsError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
