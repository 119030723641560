import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GTMService {
    private gtmId: string;

    constructor() {
        this.gtmId = environment.gtmId;
        //console.log('GTM ID:', environment.gtmId);  // Testing purposes
    }

    initializeGTM(userId,secretkey): void {
        //console.log('GTM ID:',this.gtmId);    // Testing purposes
        if (!this.gtmId || this.gtmId.length === 0) {
            // eslint-disable-next-line no-console
            console.warn('GTM ID not found in environment configuration');
            return;
        }

        // Add GTM script to head
        const script = document.createElement('script');
        script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
           'user_id': '${userId}',
           'user_hash': '${secretkey}',
         });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.gtmId}');
        `;
        document.head.appendChild(script);
        // Add GTM noscript iframe to body
        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmId}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        noscript.appendChild(iframe);
        document.body.insertBefore(noscript, document.body.firstChild);
    }
}
