import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailPreviewComponent } from 'projects/email-builder/src/app/main/email-preview/email-preview.component';

const routes: Routes = [
    { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
    { path: 'email-preview/:environmentId/:id/:splitId', component: EmailPreviewComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
