import { on } from '@ngrx/store';
import { CampaignsState } from '../interfaces';
import * as HostedContentActions from './hosted-content.action';

export const hostedContentReducer = [
    on(HostedContentActions.GetHostedContentFiles, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                hostedFiles: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(HostedContentActions.GetHostedContentFilesSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                hostedFiles: {
                    loading: false,
                    data: action?.payload
                }
            }
        };
    }),
    on(HostedContentActions.GetHostedContentFilesError, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                hostedFiles: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(HostedContentActions.ClearHostedContentFiles, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                hostedFiles: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(HostedContentActions.UploadHostedContentFiles, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                uploadedFiles: {
                    loading: true,
                    data: state?.hostedContentState?.uploadedFiles?.data || [],
                }
            }
        };
    }),
    on(HostedContentActions.UploadHostedContentFilesSuccess, (state: CampaignsState, action) => {
        let files = JSON.parse(JSON.stringify(state?.hostedContentState?.uploadedFiles?.data));
        if (files?.length > 0) {
            files?.forEach((file, i) => {
                if (file?.filename === action?.payload[0]?.filename) {
                    files[i] = { ...action?.payload[0] };
                }
            });
        } else {
            files = action?.payload;
        }
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                uploadedFiles: {
                    loading: false,
                    data: files
                }
            }
        };
    }),
    on(HostedContentActions.UploadHostedContentFilesError, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                uploadedFiles: {
                    loading: false,
                    data: state?.hostedContentState?.uploadedFiles?.data || [],
                }
            }
        };
    }),
    on(HostedContentActions.ClearUploadedHostedContentFiles, (state: CampaignsState) => {
        return {
            ...state,
            hostedContentState: {
                ...state?.hostedContentState,
                uploadedFiles: {
                    loading: false,
                    data: [],
                }
            }
        };
    }),
];