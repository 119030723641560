import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as SkuManagementActions from './sku-management.actions';

export const skuManagementReducers = [
    on(SkuManagementActions.GetSkusData, (state: SelfServiceState) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                skuManagementData: {
                    data: state.skuState?.skuManagementData?.data,
                    loading: true
                }
            }
        };
    }),

    on(SkuManagementActions.GetSkusDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                skuManagementData: {
                    data: action.payload,
                    loading: false
                }
            }
        };
    }),

    on(SkuManagementActions.GetSkusDataError, (state: SelfServiceState) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                skuManagementData: {
                    data: state.skuState?.skuManagementData?.data,
                    loading: false
                }
            }
        };
    }),

    on(SkuManagementActions.GetSelectedSkuData, (state: SelfServiceState) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                selectedSku: {
                    loading: true,
                    data: null
                }
            }
        };
    }),

    on(SkuManagementActions.GetSelectedSkuDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                selectedSku: {
                    data: action.payload,
                    loading: false
                }
            }
        };
    }),

    on(SkuManagementActions.GetSelectedSkuDataError, (state: SelfServiceState) => {
        return {
            ...state,
            skuState: {
                ...state.skuState,
                selectedSku: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
];