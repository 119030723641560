import { IntegrationsAdrollEventBasedEffects } from '@integrations/state/adroll-event-based/adroll-event-based.effects';
import { IntegrationsAdrollEffects } from '@integrations/state/adroll/adroll.effects';
import { IntegrationsEffects } from '@integrations/state/api.effects';
import { IntegrationsBlueconicEffects } from '@integrations/state/blueconic/blueconic.effects';
import { IntegrationsDeltFtpEffects } from '@integrations/state/delta-ftp/delta-ftp.effects';
import { IntegrationsGoogleAdManagerEffects } from '@integrations/state/google-ad-manager/google-ad-manager.effects';
import { IntegrationsMetaAdsManagerEffects } from '@integrations/state/meta-ads-manager/meta-ads-manager.effects';
import { IntegrationsMetaEventsManagerEffects } from '@integrations/state/meta-events-manager/meta-events-manager.effects';
import { IntegrationsShopifyEffects } from '@integrations/state/shopify/shopify.effects';

export const IntegrationsCommonEffects = [
    IntegrationsEffects,
    IntegrationsAdrollEffects,
    IntegrationsAdrollEventBasedEffects,
    IntegrationsBlueconicEffects,
    IntegrationsDeltFtpEffects,
    IntegrationsGoogleAdManagerEffects,
    IntegrationsMetaAdsManagerEffects,
    IntegrationsMetaEventsManagerEffects,
    IntegrationsShopifyEffects
];
