import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getCurrentUser, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsGoogleAdManagerActions from './google-ad-manager.actions';
import { Store } from '@ngrx/store';
import { GoogleAdManagerDomainGroupItem, GoogleAdManagerDomainSetupResponse, GoogleAdManagerExistingKeyResponse, GoogleAdManagerExistingValuesItem, GoogleAdManagerExternalSegmentDetailsResponse, JavascriptSetup } from './google-ad-manager.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsGoogleAdManagerEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
    ) { }

    getJavascriptSetup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetup),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/google-setup?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: JavascriptSetup) => {
                        const payload: ResponseWrapper<JavascriptSetup> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetupSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load google javascript setup.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetupError(error));
                    })
                );
            })
        )
    );

    requestDemo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.RequestDemo),
            exhaustMap(() => {
                return this.http.post(
                    environment.campaignApiEndPoint +
                    '/integration/google-ad-manager/request-demo',
                    { withCredentials: true },
                ).pipe(
                    take(1),
                    map(() => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Success',
                                    design: 'Success',
                                    placement: 'TopEnd',
                                    message: 'An email has been sent, you will be contacted within 24 hours.',
                                },
                            })
                        );
                        return IntegrationsGoogleAdManagerActions.RequestDemoSuccess();
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load google javascript setup.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.RequestDemoError(error));
                    })
                );
            })
        )
    );

    getDomainRestrictions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetDomainRestrictions),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/domain-restriction-groups?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerDomainSetupResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerDomainSetupResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetDomainRestrictionsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load domain restrictions data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetDomainRestrictionsError(error));
                    })
                );
            })
        )
    );

    getDomainSelectedRestrictions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetSelectedDomainRestriction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('groupId', action.payload?.groupId?.toString());

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/domain-restriction-group?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerDomainGroupItem) => {
                        const payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetSelectedDomainRestrictionSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load domain restriction data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetSelectedDomainRestrictionError(error));
                    })
                );
            })
        )
    );

    deleteDomainRestriction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.DeleteDomainRestriction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('groupId', action.payload?.groupId?.toString());

                return this.http.delete(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/domain-restriction-group?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map(() => {
                        return IntegrationsGoogleAdManagerActions.DeleteDomainRestrictionSuccess();
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to delete domain restrictions.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.DeleteDomainRestrictionError(error));
                    })
                );
            })
        )
    );

    createDomainRestriction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.CreateDomainRestriction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/domain-restriction-group?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerDomainGroupItem) => {
                        const payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> = {
                            loading: false,
                            data: res,
                        };

                        return IntegrationsGoogleAdManagerActions.CreateDomainRestrictionSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to create domain restrictions.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.CreateDomainRestrictionError(error));
                    })
                );
            })
        )
    );

    editDomainRestriction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.EditDomainRestriction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/domain-restriction-group?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerDomainGroupItem) => {
                        const payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> = {
                            loading: false,
                            data: res,
                        };

                        return IntegrationsGoogleAdManagerActions.EditDomainRestrictionSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to edit domain restrictions.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.EditDomainRestrictionError(error));
                    })
                );
            })
        )
    );

    getKeyValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetAllKeyValues),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-key-values?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExistingKeyResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetAllKeyValuesSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load key-values data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetAllKeyValuesError(error));
                    })
                );
            })
        )
    );

    getSelectedKeyDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetSelectedKeyDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('id', action.payload.id?.toString());

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-key?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExistingKeyResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetSelectedKeyDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load the selected key-value data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetSelectedKeyDetailsError(error));
                    })
                );
            })
        )
    );

    deleteKeyValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.DeleteKeyValue),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('id', action.payload?.id?.toString());

                return this.http.delete(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-key?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map(() => {
                        return IntegrationsGoogleAdManagerActions.DeleteKeyValueSuccess();
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to delete selected key-value.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.DeleteKeyValueError(error));
                    })
                );
            })
        )
    );

    editKeyDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.EditKeyDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-key?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExistingKeyResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> = {
                            loading: false,
                            data: res,
                        };

                        return IntegrationsGoogleAdManagerActions.EditKeyDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to edit key details.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.EditKeyDetailsError(error));
                    })
                );
            })
        )
    );

    createKeyValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.CreateNewKeyValue),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/create-new-key-value?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExistingKeyResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> = {
                            loading: false,
                            data: res,
                        };

                        return IntegrationsGoogleAdManagerActions.CreateNewKeyValueSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to create new key-value.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.CreateNewKeyValueError(error));
                    })
                );
            })
        )
    );

    getRestriction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetRestriction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('id', action.payload.id?.toString());

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-restriction?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerDomainGroupItem[]) => {
                        const payload: ResponseWrapper<GoogleAdManagerDomainGroupItem[]> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetRestrictionSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load restriction.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetRestrictionError(error));
                    })
                );
            })
        )
    );

    getKeyValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetKeyValue),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('id', action.payload.id?.toString());

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/existing-value?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExistingValuesItem[]) => {
                        const payload: ResponseWrapper<GoogleAdManagerExistingValuesItem[]> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetKeyValueSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load the value.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetKeyValueError(error));
                    })
                );
            })
        )
    );

    getExternalSegments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsGoogleAdManagerActions.GetExternalSegments),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('typeId', action.payload.typeId?.toString());

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/google-ad-manager/external-segments?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: GoogleAdManagerExternalSegmentDetailsResponse) => {
                        const payload: ResponseWrapper<GoogleAdManagerExternalSegmentDetailsResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsGoogleAdManagerActions.GetExternalSegmentsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load external segments.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsGoogleAdManagerActions.GetExternalSegmentsError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
