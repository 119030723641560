import { CampaignsEffects } from './api.effects';
import { HostedContentEffects } from './hosted-content/hosted-content.effect';
import { CampaignsLeadScoringEffects } from './lead-scoring/lead-scoring.effects';
import { PromoCodesEffects } from './promo-codes/promo-codes.effects';
import { CampaignsScheduleOutputsEffects } from './schedule-outputs/schedule-outputs.effects';

export const CampaignsCommonEffects = [
    CampaignsEffects,
    PromoCodesEffects,
    CampaignsLeadScoringEffects,
    CampaignsScheduleOutputsEffects,
    HostedContentEffects,
];
