import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CasHttpClient, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, debounceTime, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CDPActions from './actions';
import { QueriesSearchResults, SaveGlobalSettingsPayload } from './interfaces';

@Injectable({ providedIn: 'root' })
export class CustomerDataPlatformEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private casHttp: CasHttpClient
    ) { }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }

    getGlobalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CDPActions.GetGlobalSettings),
            exhaustMap(() => {
                return this.http
                    .get(
                        environment.campaignApiEndPoint + `/cdp/global-settings?environmentId=${this.currentDatabase.id}`, { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SaveGlobalSettingsPayload) => {
                            const payload: ResponseWrapper<SaveGlobalSettingsPayload> = {
                                loading: false,
                                data: res
                            };
                            return CDPActions.GetGlobalSettingsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get Global Settings data.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(CDPActions.GetGlobalSettingsError(error));
                        })
                    );
            }
            )
        )
    );

    saveGlobalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CDPActions.SaveGlobalSettings),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.campaignApiEndPoint + `/cdp/global-settings?environmentId=${this.currentDatabase.id}`, action.payload, { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SaveGlobalSettingsPayload) => {
                            const payload: ResponseWrapper<SaveGlobalSettingsPayload> = {
                                loading: false,
                                data: res
                            };
                            return CDPActions.SaveGlobalSettingsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to save Global Settings data.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(CDPActions.SaveGlobalSettingsError(error));
                        })
                    );
            }
            )
        )
    );

    searchQueries$ = createEffect(() =>
        this.actions$.pipe(
            debounceTime(300),
            ofType(CDPActions.SearchQueries),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('allDataViews', 'true');
                searchParams.set('profileId', action.payload.profileId ? action.payload.profileId : '');
                searchParams.set('createdBy', action.payload.createdBy ? action.payload.createdBy : '');
                searchParams.set('keyword', action.payload.keyword ? action.payload.keyword : '');
                searchParams.set('lastUpdated', action.payload.lastUpdated ? action.payload.lastUpdated : '');
                searchParams.set('lockedQueriesOnly', action.payload.lockedQueriesOnly ? action.payload.lockedQueriesOnly?.toString() : '');
                searchParams.set('name', action.payload.name ? action.payload.name : '');
                searchParams.set('nameSearchType', action.payload.nameSearchType ? action.payload.nameSearchType : '');
                searchParams.set('numResults', action.payload.numResults ? action.payload.numResults?.toString() : '');
                searchParams.set('olyticsQueriesOnly', action.payload.olyticsQueriesOnly ? action.payload.olyticsQueriesOnly?.toString() : '');
                searchParams.set('offset', action.payload.offset ? action.payload.offset?.toString() : '');
                searchParams.set('sortBy', action.payload.sortBy ? action.payload.sortBy : '');
                searchParams.set('order', action.payload.order ? action.payload.order : '');
                searchParams.set('mode', action.payload.mode ? action.payload.mode : '');
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/search?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: QueriesSearchResults) => {
                        const payload: ResponseWrapper<QueriesSearchResults> = { loading: false, data: res };
                        return CDPActions.SearchQueriesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(CDPActions.SearchQueriesError(error));
                    })
                );
            })
        )
    );

    checkCustomerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CDPActions.CheckCustomerId),
            exhaustMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabase.id?.toString();
                const url = new URL(environment.campaignApiEndPoint + '/cdp' + `/customer/encrypted-id-lookup?environmentId=${databaseId}`);
                url.searchParams.append('customerId', action.payload.customerId?.toString());
                return this.http
                    .get(url.toString(), { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: { encryptedCustomerId: string }) => {
                            return CDPActions.CheckCustomerIdSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to test customer Id.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage, false);
                            return of(CDPActions.CheckCustomerIdError(error));
                        })
                    );
            })
        )
    );

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }
}
