import { on } from '@ngrx/store';
import { CampaignsState } from '../interfaces';
import * as ScheduleOutputActions from './schedule-outputs.actions';

export const campaignsScheduleOutputsReducer = [
    on(ScheduleOutputActions.GetScheduleOutputsLogs, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                scheduleOutputs: {
                    loading: true,
                    data: null,
                },
            }
        };
    }),
    on(ScheduleOutputActions.GetScheduleOutputsLogsSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                scheduleOutputs: {
                    loading: false,
                    data: action.payload?.data,
                },
            }
        };
    }),
    on(ScheduleOutputActions.GetScheduleOutputsLogsError, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                scheduleOutputs: {
                    loading: false,
                    data: state?.scheduleOutputsState?.scheduleOutputs?.data,
                },
            }
        };
    }),

    on(ScheduleOutputActions.GetSelectedScheduleOutput, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: true,
                    data: null,
                },
            }
        };
    }),
    on(ScheduleOutputActions.GetSelectedScheduleOutputSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: false,
                    data: action.payload?.data,
                },
            }
        };
    }),
    on(ScheduleOutputActions.GetSelectedScheduleOutputError, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: false,
                    data: state?.scheduleOutputsState?.selectedScheduleOutput?.data,
                },
            }
        };
    }),

    on(ScheduleOutputActions.SaveSelectedScheduleOutput, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: true,
                    data: state?.scheduleOutputsState?.selectedScheduleOutput?.data,
                },
            }
        };
    }),
    on(ScheduleOutputActions.SaveSelectedScheduleOutputSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: false,
                    data: action.payload?.data,
                },
            }
        };
    }),
    on(ScheduleOutputActions.SaveSelectedScheduleOutputError, (state: CampaignsState, error) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                selectedScheduleOutput: {
                    loading: false,
                    data: state?.scheduleOutputsState?.selectedScheduleOutput?.data,
                    errors: error
                },
            }
        };
    }),

    on(ScheduleOutputActions.SearchQueries, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                seachQueriesResults: {
                    loading: true,
                    data: state?.scheduleOutputsState?.seachQueriesResults?.data
                }
            }
        };
    }),
    on(ScheduleOutputActions.SearchQueriesSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                seachQueriesResults: {
                    loading: false,
                    data: action?.payload?.data
                }
            }
        };
    }),
    on(ScheduleOutputActions.SearchQueriesError, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                seachQueriesResults: {
                    loading: false,
                    data: state?.scheduleOutputsState?.seachQueriesResults?.data
                }
            }
        };
    }),
    on(ScheduleOutputActions.ClearSearchQueriesResults, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                seachQueriesResults: {
                    loading: false,
                    data: {}
                }
            }
        };
    }),

    on(ScheduleOutputActions.GetOutputCriteria, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                outputCriteriaList: {
                    loading: false,
                    data: state?.scheduleOutputsState?.outputCriteriaList?.data
                }
            }
        };
    }),
    on(ScheduleOutputActions.GetOutputCriteriaSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                outputCriteriaList: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(ScheduleOutputActions.GetOutputCriteriaError, (state: CampaignsState) => {
        return {
            ...state,
            scheduleOutputsState: {
                ...state?.scheduleOutputsState,
                outputCriteriaList: {
                    loading: false,
                    data: state?.scheduleOutputsState?.outputCriteriaList?.data
                }
            }
        };
    }),
];
