import { on } from '@ngrx/store';
import { DeploymentTypeEnum } from 'common';
import { IEmailBuilderState, SplitDetails } from '.././interfaces';
import * as DeploymentActions from './deployment.actions';
import { CopiedTriggerSettingsResponse } from './deployment.interfaces';

export const deploymentReducer = [
    on(DeploymentActions.UpdateDeploymentSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: true,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateDeploymentSummarySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: action.payload
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateDeploymentSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: true,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredSummarySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: action.payload
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.GetTriggeredCriterion, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: true,
                        data: state.selectedDeployment?.data?.triggeredCriterion?.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.GetTriggeredCriterionSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: false,
                        data: action.payload.data
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetTriggeredCriterionError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: false,
                        data: state.selectedDeployment?.data?.triggeredCriterion?.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredCriterion, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: true,
                        data: state.selectedDeployment?.data?.triggeredCriterion?.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredCriterionSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: false,
                        data: action.payload.data
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateTriggeredCriterionError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    triggeredCriterion: {
                        loading: false,
                        data: state.selectedDeployment?.data?.triggeredCriterion?.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateAudience, (state: IEmailBuilderState) => {
        const selectedDeployment = state.selectedDeployment;
        const audience = selectedDeployment.data.audience;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment.data,
                    audience: {
                        ...audience,
                        loading: true
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateAudienceSuccess, (state: IEmailBuilderState, action) => {
        const audienceData = JSON.parse(JSON.stringify(action.payload.data));
        const deplType = action.payload.type;
        if (deplType === DeploymentTypeEnum.REGULAR) {
            if ( audienceData.defaultSuppressions) {
                audienceData.defaultSuppressions.forEach((el) => {
                    if (el.excludedDomains) {
                        const data = el.excludedDomains.split(' ');
                        el.excludedDomains = data;
                    }
                });
            }
            if (audienceData?.recipientSources) {
                audienceData.recipientSources = [...audienceData.recipientSources].map((el) => {
                    const recipientsList = [...el];
                    const mainRecipientList = {...recipientsList.shift(), subLists: [], subRowsExpanded: false, selected: false };
                    mainRecipientList.subLists = recipientsList || [];
                    mainRecipientList.splitsAssigned = mainRecipientList.splitsAssigned.replace(/\s/g, '');
                    return mainRecipientList;
                });
            } else { audienceData.recipientSources = []; }
            audienceData.domainSuppressions = audienceData.domainSuppressions || [];
            audienceData.emailSuppressions = audienceData.emailSuppressions || [];
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        loading: false,
                        data: audienceData
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateAudienceError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        loading: false,
                        data: state.selectedDeployment.data.audience.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTracking, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: true,
                        data: state.selectedDeployment.data.tracking.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTrackingSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.tracking.data,
                            ...action.payload.data
                        }
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTrackingError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: null
                    }
                }
            },
        };
    }),
    on(DeploymentActions.GetDeploymentSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDeploymentSummarySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: action.payload
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetDeploymentSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDeploymentDefaultsSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentDefaultsSettings: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDeploymentDefaultsSettingsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentDefaultsSettings: {
                        loading: false,
                        data: action.payload
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetDeploymentDefaultsSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentDefaultsSettings: {
                        loading: false,
                        data: state.selectedDeployment.data.deploymentDefaultsSettings.data
                    }
                }
            }
        };
    }),
    on(DeploymentActions.ClearDeploymentTypeDefaults, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentDefaultsSettings: {
                        loading: false,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetTracking, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetTrackingSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.tracking.data,
                            ...action.payload.data
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetTrackingError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: true,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleLibrary: null
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleLibrary: action.payload,
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetDynamicContentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleLibrary: state.selectedDeployment.data.dynamicContent.data?.ruleLibrary,
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: true,
                                data: null,
                            },
                            fieldsData: {
                                loading: true,
                                data: {
                                    conditionFields: {},
                                    subConditionFields: {},
                                }
                            }
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleSummarySuccess, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        const subConditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.subConditionFields;
        selectedDeployment.dynamicContent.data.ruleSummary = { loading: false, data: action.payload };
        selectedDeployment.dynamicContent.data.fieldsData = { ...selectedDeployment.dynamicContent.data.fieldsData, loading: false };
        const conditions = JSON.parse(JSON.stringify(action?.payload?.conditions));
        conditions.forEach(condition => {
            let demoCompareArray = null;
            if (!Array.isArray(condition.demoCompareMap)) {
                demoCompareArray = [];
                Object.entries(condition.demoCompareMap).forEach(([key, value]) => {
                    demoCompareArray?.push({ description: value, value: key });
                });
            }
            if (condition?.thirdColOptions?.isSearchable && condition?.thirdColOptions?.validValues?.length > 1 && condition?.thirdColOptions?.validValues[0]?.value === '-1' && condition?.thirdColOptions?.validValues[0]?.description === '~~too~many~behavior~attr~values~~') {
                condition?.thirdColOptions?.validValues.shift();
                conditionFields.isSearch = true;
            }
            conditionFields[condition?.attribute] = {
                dataDropdown: condition?.secondColOptions,
                dataMap: {
                    ...conditionFields[condition?.attribute]?.dataMap,
                    [condition?.data]: {
                        dropdownValues: demoCompareArray ? demoCompareArray : condition?.demoCompareMap,
                        compareMap: {
                            ...conditionFields[condition?.attribute]?.dataMap[condition?.data]?.compareMap,
                            [condition?.isNot]: {
                                dropdownValues: condition?.thirdColOptions,
                                valuesMap: {},
                            }
                        }
                    }
                }
            };
            if (condition?.subConditions) {
                condition?.subConditions?.forEach(subCondition => {
                    let behDataColArray = null;
                    if (!Array.isArray(subCondition?.behDataColOptions)) {
                        behDataColArray = [];
                        Object.entries(subCondition?.behDataColOptions).forEach(([key, value]) => {
                            behDataColArray?.push({ name: value, code: key });
                        });
                    }
                    let behavCompareArray = null;
                    if (!Array.isArray(subCondition?.behavCompareOptions)) {
                        behavCompareArray = [];
                        Object.entries(subCondition?.behavCompareOptions).forEach(([key, value]) => {
                            behavCompareArray?.push({ description: value, value: key });
                        });
                    }
                    subConditionFields[subCondition?.condition === 'WHEN' ? condition?.attribute : 'bhvVisitAttr'] = {
                        dataDropdown: (subCondition?.condition === 'WHEN' ? subCondition?.behDataColOptions : { dataValues: (behDataColArray ? behDataColArray : subCondition?.behDataColOptions) }),
                        dataValuesLength: subCondition?.condition === 'WHEN' ? null : behDataColArray ? behDataColArray?.length : Object?.keys(subCondition?.behDataColOptions)?.length,
                        dataMap: {
                            ...subConditionFields[subCondition?.condition === 'WHEN' ? condition?.attribute : 'bhvVisitAttr']?.dataMap,
                            [subCondition?.data]: {
                                dropdownValues: behavCompareArray ? behavCompareArray : subCondition?.behavCompareOptions,
                                compareMap: {
                                    ...subConditionFields[subCondition?.condition === 'WHEN' ? condition?.attribute : 'bhvVisitAttr']?.dataMap[subCondition?.data]?.compareMap,
                                    [subCondition?.isNot]: {
                                        dropdownValues: subCondition?.behavValueColOptions,
                                        valuesMap: {},
                                    }
                                }
                            }
                        }
                    };
                });
            }
        });
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                },
            },
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: false,
                                data: null,
                            },
                            fieldsData: {
                                ...state.selectedDeployment?.data?.dynamicContent?.data?.fieldsData,
                                loading: false,
                            }
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.SaveNewDynamicContentRuleSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: true,
                                data: null,
                            },
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.SaveNewDynamicContentRuleSummarySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        ...state.selectedDeployment?.data?.dynamicContent,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: false,
                                data: action.payload,
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.SaveNewDynamicContentRuleSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: false,
                                data: null,
                            },
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.UpdateDynamicContentRuleSummary, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: true,
                                data: null,
                            },
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.UpdateDynamicContentRuleSummarySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: false,
                                data: action?.payload,
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateDynamicContentRuleSummaryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            ruleSummary: {
                                loading: false,
                                data: null,
                            },
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.DeleteDynamicContentRule, (state:IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        ...state.selectedDeployment.data.dynamicContent,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            deleteRule: {
                                loading: true,
                                data: null,
                            }
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.DeleteDynamicContentRuleSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        ...state.selectedDeployment.data.dynamicContent,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            deleteRule: {
                                loading: false,
                                data: action?.payload,
                            }
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.DeleteDynamicContentRuleError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        ...state.selectedDeployment.data.dynamicContent,
                        data: {
                            ...state.selectedDeployment?.data?.dynamicContent?.data,
                            deleteRule: {
                                loading: false,
                                data: null,
                            }
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleAttributes, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.ruleAttributes = { loading: true, data: null };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleAttributesSuccess, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.ruleAttributes = { loading: false, data: action?.payload };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleAttributesError, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.ruleAttributes = { loading: false, data: null };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleDataList, (state: IEmailBuilderState) => {
        const dynamicContent = state.selectedDeployment?.data?.dynamicContent;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContent: {
                        ...dynamicContent,
                        data: {
                            ...dynamicContent?.data,
                            fieldsData: {
                                loading: true,
                                data: {
                                    conditionFields: (dynamicContent?.data?.fieldsData?.data?.conditionFields ? dynamicContent?.data?.fieldsData?.data?.conditionFields : {}),
                                    subConditionFields: (dynamicContent?.data?.fieldsData?.data?.subConditionFields ? dynamicContent?.data?.fieldsData?.data?.subConditionFields : {}),
                                }
                            }
                        }
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleDataListSuccess, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        const subConditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.subConditionFields;
        if (action?.payload?.data?.conditionType === 'condition') {
            conditionFields[action?.payload?.data?.selectedAttributeId] = {
                dataDropdown: action?.payload?.returnedData,
                dataMap: {},
            };
        } else if (action?.payload?.data?.conditionType === 'subCondition') {
            subConditionFields[action?.payload?.data?.selectedAttributeId] = {
                dataDropdown: action?.payload?.returnedData,
                dataValuesLength: Object?.keys(action?.payload?.returnedData?.dataValues)?.length,
                dataMap: {},
            };
        }
        if (action?.payload?.returnedData?.behaviorDataValues) {
            subConditionFields[action?.payload?.data?.selectedAttributeId] = {
                dataDropdown: action?.payload?.returnedData?.behaviorDataValues,
                dataMap: {},
            };
        }
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleDataListError, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleComparisonList, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.fieldsData.loading = true;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleComparisonListSuccess, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        const subConditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.subConditionFields;
        if (action?.payload?.data?.conditionType === 'condition' && conditionFields[action?.payload?.data?.selectedAttributeId]) {
            conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId] = {
                dropdownValues: action?.payload?.returnedData,
                compareMap: {}
            };
        } else if (action?.payload?.data?.conditionType === 'subCondition' && subConditionFields[action?.payload?.data?.selectedAttributeId]) {
            subConditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId] = {
                dropdownValues: action?.payload?.returnedData,
                compareMap: {}
            };
        }
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleComparisonListError, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleValuesList, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.fieldsData.loading = true;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleValuesListSuccess, (state: IEmailBuilderState, action) => {
        const payloadData = JSON.parse(JSON.stringify(action?.payload?.returnedData));
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        const subConditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.subConditionFields;
        if (payloadData?.validValues?.length === 1 && payloadData?.validValues[0]?.value === '-1' && payloadData?.validValues[0]?.description === '~~too~many~behavior~attr~values~~') {
            payloadData.validValues = [];
            payloadData.isSearchable = true;
        }
        if (action?.payload?.data?.conditionType === 'condition' && conditionFields[action?.payload?.data?.selectedAttributeId]) {
            conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId] = {
                dropdownValues: payloadData,
                valuesMap: {}
            };
        } else if (action?.payload?.data?.conditionType === 'subCondition' &&  subConditionFields[action?.payload?.data?.selectedAttributeId]) {
            subConditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId] = {
                dropdownValues: action?.payload?.returnedData,
                valuesMap: {}
            };
        }
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                },
            },
        };
    }),
    on(DeploymentActions.GetDynamicContentRuleValuesListError, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.SearchDynamicContentRuleValues, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        selectedDeployment.dynamicContent.data.fieldsData.loading = true;
        if (conditionFields[action?.payload?.selectedAttributeId]
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId]
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId].compareMap[action?.payload?.selectedComparatorId]
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId].compareMap[action?.payload?.selectedComparatorId]
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId].compareMap[action?.payload?.selectedComparatorId]?.dropdownValues
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId].compareMap[action?.payload?.selectedComparatorId]?.dropdownValues?.isSearchable
            && conditionFields[action?.payload?.selectedAttributeId].dataMap[action?.payload?.selectedDataId].compareMap[action?.payload?.selectedComparatorId]?.dropdownValues?.validValues) {
            conditionFields[action.payload.selectedAttributeId].dataMap[action.payload.selectedDataId].compareMap[action.payload.selectedComparatorId].dropdownValues.validValues = [];
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.SearchDynamicContentRuleValuesSuccess, (state: IEmailBuilderState, action) => {
        const payloadData = JSON.parse(JSON.stringify(action?.payload?.returnedData));
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        if (payloadData?.validValues?.length === 1 && payloadData?.validValues[0]?.value === '-1' && payloadData?.validValues[0]?.description === '~~too~many~behavior~attr~values~~') {
            payloadData.validValues = [];
            payloadData.isSearchable = true;
        }
        if (action?.payload?.data?.conditionType === 'condition' && conditionFields[action?.payload?.data?.selectedAttributeId]) {
            const data = conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]?.dropdownValues?.isSearchable ?
                {...conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]?.dropdownValues, validValues: payloadData?.validValues} :
                payloadData;
            conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId] = {
                dropdownValues: data,
                valuesMap: {}
            };
        }
        conditionFields.isSearch = true;
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                },
            },
        };
    }),
    on(DeploymentActions.SearchDynamicContentRuleValuesError, (state: IEmailBuilderState, action) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        const conditionFields = selectedDeployment?.dynamicContent?.data?.fieldsData?.data?.conditionFields;
        selectedDeployment.dynamicContent.data.fieldsData.loading = false;
        if (conditionFields[action?.payload?.data?.selectedAttributeId]
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId]
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]?.dropdownValues
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]?.dropdownValues?.isSearchable
            && conditionFields[action?.payload?.data?.selectedAttributeId].dataMap[action?.payload?.data?.selectedDataId].compareMap[action?.payload?.data?.selectedComparatorId]?.dropdownValues?.validValues) {
            conditionFields[action.payload.data.selectedAttributeId].dataMap[action.payload.data.selectedDataId].compareMap[action.payload.data.selectedComparatorId].dropdownValues.validValues = [];
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.ClearAllRuleData, (state: IEmailBuilderState) => {
        const selectedDeployment = JSON.parse(JSON.stringify(state.selectedDeployment?.data));
        if (selectedDeployment?.dynamicContent?.data?.fieldsData) {
            selectedDeployment.dynamicContent.data.fieldsData.data.conditionFields = {};
            selectedDeployment.dynamicContent.data.fieldsData.data.subConditionFields = {};
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...selectedDeployment,
                }
            }
        };
    }),
    on(DeploymentActions.UpdateMessageContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: true,
                        data: state.selectedDeployment.data.messageContent.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateMessageContentSuccess, (state: IEmailBuilderState, action) => {
        const messageData = JSON.parse(JSON.stringify(action.payload));
        if (messageData && messageData.splits && messageData.splits.length) {
            for (const split of messageData?.splits) {
                if (!split?.mergeVariableDefault) {
                    split.mergeVariableDefault = [];
                }
                if (split?.mergeVariableSelectedList) {
                    split?.mergeVariableSelectedList?.forEach(selectedMergeVariable => {
                        if (split?.mergeVariableDefault?.filter(item => item?.mergeVariableName === selectedMergeVariable)?.length === 0) {
                            split?.mergeVariableDefault?.push({
                                mergeVariableName: selectedMergeVariable,
                                mergeVariableValue: '',
                                canRestore: false
                            });
                        }
                    });
                }
                if (!split.mergeVariableSelectedList) {
                    split.mergeVariableSelectedList = [];
                } else {
                    split.mergeVariableSelectedList.sort();
                }
            }
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: {
                            ...messageData
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateMessageContentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: state.selectedDeployment.data.messageContent.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTestSeedReport, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: true,
                        data: state.selectedDeployment.data.testSeedReport.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.UpdateTestSeedReportSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: action.payload,
                    }
                }
            }
        };
    }),
    on(DeploymentActions.UpdateTestSeedReportError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: state.selectedDeployment.data.testSeedReport.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.AddRecipient, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: true,
                                data: null
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.AddRecipientSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: false,
                                data: action?.payload?.data
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.AddRecipientError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: false,
                                data: null
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.AddRecipientFromForm, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: true,
                                data: null
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.AddRecipientFromFormSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: false,
                                data: action?.payload?.data
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.AddRecipientFromFormError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipient: {
                                loading: false,
                                data: null
                            },
                        }
                    }
                },
            },
        };
    }),
    on(DeploymentActions.UpdateDynamicContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: state.selectedDeployment.data,
                loading: true
            },
        };
    }),
    on(DeploymentActions.CreateDeployment, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: true,
                        data: null
                    }
                },
            }
        };
    }),
    on(DeploymentActions.CreateDeploymentSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        loading: false,
                        data: action.payload
                    }
                },
            }
        };
    }),
    on(DeploymentActions.CreateDeploymentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment?.data,
                    deploymentSummary: {
                        loading:false,
                        data: state.selectedDeployment.data.deploymentSummary.data
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetRecipients, (state: IEmailBuilderState) => {
        return {
            ...state,
            recipientsList: {
                loading: false,
                data: state.recipientsList?.data
            }
        };
    }),
    on(DeploymentActions.GetTestSeedReport, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetTestSeedReportSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: action.payload.data
                    }
                },
            },
        };
    }),
    on(DeploymentActions.GetTestSeedReportError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        loading: false,
                        data: state.selectedDeployment.data.testSeedReport.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.GetAudience, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentActions.GetAudienceSuccess, (state: IEmailBuilderState, action) => {
        const audienceData = JSON.parse(JSON.stringify(action.payload.data));
        const deplType = action.payload.type;
        if (deplType === DeploymentTypeEnum.REGULAR) {
            if (audienceData.defaultSuppressions) {
                audienceData.defaultSuppressions.forEach((el) => {
                    if (el.excludedDomains) {
                        const data = el.excludedDomains.split(' ');
                        el.excludedDomains = data;
                    }
                });
            }
            if (audienceData?.recipientSources) {
                audienceData.recipientSources = [...audienceData.recipientSources].map((el) => {
                    const recipientsList = [...el];
                    const mainRecipientList = {...recipientsList.shift(), subLists: [], subRowsExpanded: false, selected: false };
                    mainRecipientList.subLists = recipientsList || [];
                    mainRecipientList.splitsAssigned = mainRecipientList.splitsAssigned.replace(/\s/g, '');
                    return mainRecipientList;
                });
            } else { audienceData.recipientSources = []; }
            audienceData.domainSuppressions = audienceData.domainSuppressions || [];
            audienceData.emailSuppressions = audienceData.emailSuppressions || [];
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        loading: false,
                        data: audienceData
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetAudienceError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        loading: false,
                        data: state.selectedDeployment.data.audience.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.GetOutputCriteria, (state: IEmailBuilderState) => {
        return {
            ...state,
            abQueryOutputCriteria: {
                data: state?.abQueryOutputCriteria?.data,
                loading: true
            }
        };
    }),
    on(DeploymentActions.GetOutputCriteriaSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            abQueryOutputCriteria: {
                data: action.payload,
                loading: false
            }
        };
    }),
    on(DeploymentActions.GetOutputCriteriaError, (state: IEmailBuilderState) => {
        return {
            ...state,
            abQueryOutputCriteria: {
                data: state?.abQueryOutputCriteria?.data,
                loading: false
            },
        };
    }),
    on(DeploymentActions.SearchQueries, (state: IEmailBuilderState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: true,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(DeploymentActions.SearchQueriesSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(DeploymentActions.SearchQueriesError, (state: IEmailBuilderState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(DeploymentActions.ClearSearchQueries, (state: IEmailBuilderState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: {}
            }
        };
    }),
    on(DeploymentActions.QueryUploadStatus, (state: IEmailBuilderState, action) => {
        const data = action.payload.data && (!action.payload.data.isDone || !action.payload.data.isError) ? action.payload.data : null;
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: data,
            }
        };
    }),
    on(DeploymentActions.QueryUploadStatusSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(DeploymentActions.QueryUploadStatusError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.GetOmlInternalFileLists, (state: IEmailBuilderState) => {
        return {
            ...state,
            omlInternalFileList: {
                loading: true,
                data: null
            }
        };
    }),
    on(DeploymentActions.GetOmlInternalFileListsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            omlInternalFileList: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(DeploymentActions.GetOmlInternalFileListsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            omlInternalFileList: {
                loading: false,
                data: state.omlInternalFileList?.data
            }
        };
    }),
    on(DeploymentActions.UploadFileWithRecipients, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadFileWithRecipientsSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadFileWithRecipientsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.SetSuppressions, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.SetSuppressionsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: action.payload.data.success ? true : false,
                data: { isDone: action.payload.data.success ? false : true }
            }
        };
    }),
    on(DeploymentActions.SetSuppressionsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.UploadPriorListForSuppression, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadPriorListForSuppressionSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isDone: true }
            }
        };
    }),
    on(DeploymentActions.UploadPriorListForSuppressionError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.UploadUniqueIdentifiersFile, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadUniqueIdentifiersFileSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isDone: true }
            }
        };
    }),
    on(DeploymentActions.UploadUniqueIdentifiersFileError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.UploadInternalList, (state: IEmailBuilderState) => {
        return {
            ...state,
            uploadInternalList: {
                loading: true,
                data: null
            },
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadInternalListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            uploadInternalList: {
                loading: false,
                data: action.payload,
            },
            queryUploadStatus: {
                loading: action?.payload?.data?.success,
                data: { isDone: !action?.payload?.data?.success }
            }
        };
    }),
    on(DeploymentActions.UploadInternalListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            uploadInternalList: {
                loading: false,
                data: state.uploadInternalList?.data
            },
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.UploadFromSecureFTP, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadFromSecureFTPSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: action?.payload?.data?.success,
                data: { isDone: !action?.payload?.data?.success }
            }
        };
    }),
    on(DeploymentActions.UploadFromSecureFTPError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.UploadRecipientsToDeployment, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.UploadRecipientsToDeploymentSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: action?.payload?.success ? true : false,
                data: {
                    isDone: false,
                    isError: action?.payload?.success ? false : true
                }
            }
        };
    }),
    on(DeploymentActions.UploadRecipientsToDeploymentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.ABQueryForSuppression, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.ABQueryForSuppressionSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: true,
                data: { isDone: false }
            }
        };
    }),
    on(DeploymentActions.ABQueryForSuppressionError, (state: IEmailBuilderState) => {
        return {
            ...state,
            queryUploadStatus: {
                loading: false,
                data: { isError: true }
            }
        };
    }),
    on(DeploymentActions.AudiencePanelItemsDelete, (state: IEmailBuilderState) => {
        return {
            ...state,
            audiencePanelItemsDelete: {
                loading: true,
                data: null
            },
            queryUploadStatus: {
                loading: true,
                data: { isDone: false, isDelete: true }
            }
        };
    }),
    on(DeploymentActions.AudiencePanelItemsDeleteSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            audiencePanelItemsDelete: {
                loading: true,
                data: action.payload
            },
            queryUploadStatus: {
                loading: false,
                data: { isDone: true, isDelete: false }
            }
        };
    }),
    on(DeploymentActions.AudiencePanelItemsDeleteError, (state: IEmailBuilderState) => {
        return {
            ...state,
            audiencePanelItemsDelete: {
                loading: false,
                data: state.audiencePanelItemsDelete.data
            },
            queryUploadStatus: {
                loading: false,
                data: { isError: true, isDelete: false }
            }
        };
    }),
    on(DeploymentActions.PriorListSearch, (state: IEmailBuilderState) => {
        return {
            ...state,
            priorListSearchResults: {
                loading: true,
                data: state.priorListSearchResults?.data
            }
        };
    }),
    on(DeploymentActions.PriorListSearchSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            priorListSearchResults: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(DeploymentActions.PriorListSearchError, (state: IEmailBuilderState) => {
        return {
            ...state,
            priorListSearchResults: {
                loading: false,
                data: state.priorListSearchResults?.data
            }
        };
    }),
    on(DeploymentActions.ClearPriorListSearch, (state: IEmailBuilderState) => {
        return {
            ...state,
            priorListSearchResults: {
                loading: false,
                data: {}
            }
        };
    }),
    on(DeploymentActions.GetMessageContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: true,
                        data: null
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetBeeAuthToken, (state: IEmailBuilderState) => {
        return {
            ...state,
            beeAuthToken: {
                loading: true,
                data: null
            }
        };
    }),
    on(DeploymentActions.BeeAuthTokenSuccess, (state: IEmailBuilderState, action) => {
        const beeData = JSON.parse(JSON.stringify(action.payload));
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                data: beeData
            }
        };
    }),
    on(DeploymentActions.BeeAuthTokenError, (state: IEmailBuilderState) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false
            },
        };
    }),
    on(DeploymentActions.GetMessageContentSuccess, (state: IEmailBuilderState, action) => {
        const messageData = JSON.parse(JSON.stringify(action.payload));
        if (messageData?.splits) {
            for (const split of messageData?.splits) {
                if (!split?.mergeVariableDefault) {
                    split.mergeVariableDefault = [];
                }
                if (split?.mergeVariableSelectedList) {
                    split?.mergeVariableSelectedList?.forEach(selectedMergeVariable => {
                        if (split?.mergeVariableDefault?.filter(item => item?.mergeVariableName === selectedMergeVariable)?.length === 0) {
                            split?.mergeVariableDefault?.push({
                                mergeVariableName: selectedMergeVariable,
                                mergeVariableValue: '',
                                canRestore: false
                            });
                        }
                    });
                }
                if (!split.mergeVariableSelectedList) {
                    split.mergeVariableSelectedList = [];
                } else {
                    split.mergeVariableSelectedList.sort();
                }
            }
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: {
                            ...state?.selectedDeployment?.data?.messageContent?.data,
                            ...messageData
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetMessageContentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: state.selectedDeployment?.data?.messageContent.data
                    }
                }
            },
        };
    }),
    on(DeploymentActions.RetreiveContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: true,
                        data: state.selectedDeployment.data.messageContent.data,
                    }
                },
            }
        };
    }),
    on(DeploymentActions.RetreiveContentSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: {
                            retrivedContent: action.payload
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.RetreiveContentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                loading: false,
                data: {
                    ...state.selectedDeployment.data,
                }
            },
        };
    }),
    on(DeploymentActions.ClearRetreiveContentData, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        data: {
                            retrivedContent: null,
                            uploadedContet: null
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GenerateTextFromHTMLSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        data: {
                            ...state.selectedDeployment.data.messageContent.data,
                            retrivedTextFromHTML: action.payload
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GenerateTextFromHTMLError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                }
            },
        };
    }),
    on(DeploymentActions.UploadMessageContent, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: true,
                        data: state.selectedDeployment.data.messageContent.data,
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadMessageContentSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        doNotUpdateSplits: true,
                        data: {
                            ...state.selectedDeployment.data.messageContent.data,
                            uploadedContet: action.payload
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadMessageContentError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    messageContent: {
                        loading: false,
                        data: state.selectedDeployment.data.messageContent.data,
                    }
                }
            },
        };
    }),
    on(DeploymentActions.SearchContacts, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchContacts: {
                loading: true,
                data: null
            }
        };
    }),
    on(DeploymentActions.SearchContactsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            searchContacts: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(DeploymentActions.SearchContactsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchContacts: {
                loading: false,
                data: {
                    ...state.searchContacts.data,
                }
            },
        };
    }),
    on(DeploymentActions.ClearSearchContacts, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchContacts: {
                data: null
            }
        };
    }),
    on(DeploymentActions.UploadRecipientListFromFile, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipientsFromList: {
                                loading: true,
                                data: null
                            }
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadRecipientListFromFileSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipientsFromList: {
                                loading: false,
                                data: action.payload
                            }
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadRecipientListFromFileError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    testSeedReport: {
                        data: {
                            ...state.selectedDeployment.data.testSeedReport.data,
                            addNewRecipientsFromList: {
                                loading: false,
                                data: state.selectedDeployment.data.testSeedReport.data?.addNewRecipientsFromList?.data
                            }
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingParameters, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingParameters: {
                loading: true,
                data: state.webTrackingParameters?.data || []
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingParametersSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            webTrackingParameters: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingParametersError, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingParameters: {
                loading: false,
                data: state.webTrackingParameters.data
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingConfigurations, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfigurations: {
                loading: true,
                data: state.webTrackingConfigurations?.data
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingConfigurationsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            webTrackingConfigurations: action.payload
        };
    }),
    on(DeploymentActions.GetWebTrackingConfigurationsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfigurations: {
                loading: false,
                data: state.webTrackingConfigurations?.data
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingConfiguration, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfiguration: {
                loading: true,
                data: state.webTrackingConfiguration?.data
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingConfigurationSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            webTrackingConfiguration: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(DeploymentActions.GetWebTrackingConfigurationError, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfiguration: {
                loading: false,
                data: state.webTrackingConfiguration.data
            }
        };
    }),
    on(DeploymentActions.SaveWebTrackingConfiguration, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfiguration: {
                ...state.webTrackingConfiguration,
                saving: true,
            }
        };
    }),
    on(DeploymentActions.SaveWebTrackingConfigurationSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfiguration: {
                ...state.webTrackingConfiguration,
                saving: false,
            }
        };
    }),
    on(DeploymentActions.SaveWebTrackingConfigurationError, (state: IEmailBuilderState) => {
        return {
            ...state,
            webTrackingConfiguration: {
                ...state.webTrackingConfiguration,
                saving: false,
            }
        };
    }),
    on(DeploymentActions.GetTrackingDefaultCategory, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        ...state.selectedDeployment.data.tracking,
                        loading: true
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetTrackingDefaultCategorySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: {
                            ...state.selectedDeployment.data.tracking.data,
                            defaultCategory: action.payload
                        }
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetTrackingDefaultCategoryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        ...state.selectedDeployment.data.tracking,
                        loading: false
                    }
                },
            }
        };
    }),
    on(DeploymentActions.RetrieveLinkNames, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        ...state.selectedDeployment.data.tracking,
                        loading: true
                    }
                },
            }
        };
    }),
    on(DeploymentActions.RetrieveLinkNamesSuccess, (state: IEmailBuilderState, action) => {
        const linkNamesMap = new Map();
        if (action.payload && action.payload.length) {
            action.payload.forEach(link => {
                linkNamesMap.set(link.linkId, link.linkName);
            });
        }
        const trackingData = JSON.parse(JSON.stringify(state.selectedDeployment.data.tracking.data));
        if (trackingData && trackingData.splits && trackingData.splits.length) {
            trackingData.splits.forEach(split => {
                if (split.trackers && split.trackers.length) {
                    split.trackers.forEach(tracker => {
                        const retrievedName = linkNamesMap.get(tracker.id);
                        if (retrievedName) {
                            tracker['linkName'] = retrievedName;
                        }
                    });
                }
            });
        }
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        loading: false,
                        data: trackingData
                    }
                },
            }
        };
    }),
    on(DeploymentActions.RetrieveLinkNamesError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    tracking: {
                        ...state.selectedDeployment.data.tracking,
                        loading: false
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetModificationHistory, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    modificationHistory: {
                        loading: true,
                        data: state.selectedDeployment.data.modificationHistory?.data
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetModificationHistorySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    modificationHistory: action.payload
                },
            }
        };
    }),
    on(DeploymentActions.GetModificationHistoryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    modificationHistory: {
                        loading: false,
                        data: []
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetDynamicTag, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        loading: true,
                        data: null
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetDynamicTagSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        loading: false,
                        data: action.payload
                    }
                },
            }
        };
    }),
    on(DeploymentActions.GetDynamicTagError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        loading: false,
                        data: null
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadDynamicTagContent, (state: IEmailBuilderState) => {
        const dynamicContentTag = {...state?.selectedDeployment?.data?.dynamicContentTag};
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        ...dynamicContentTag,
                        loading: false,
                        data: {
                            ...dynamicContentTag.data,
                            uploadedContent: null
                        },
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadDynamicTagContentSuccess, (state: IEmailBuilderState, action) => {
        const dynamicContentTag = {...state?.selectedDeployment?.data?.dynamicContentTag};
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        ...dynamicContentTag,
                        loading: false,
                        data: {
                            ...dynamicContentTag.data,
                            uploadedContent: action.payload
                        },
                    }
                },
            }
        };
    }),
    on(DeploymentActions.UploadDynamicTagContentError, (state: IEmailBuilderState) => {
        const dynamicContentTag = {...state?.selectedDeployment?.data?.dynamicContentTag};
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        ...dynamicContentTag,
                        loading: false,
                        data: {
                            ...dynamicContentTag.data,
                            uploadedContent: null
                        },
                    }
                }
            },
        };
    }),
    on(DeploymentActions.ClearUploadedDynamicTagContent, (state: IEmailBuilderState) => {
        const dynamicContentTag = {...state?.selectedDeployment?.data?.dynamicContentTag};
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    dynamicContentTag: {
                        ...dynamicContentTag,
                        data: {
                            ...dynamicContentTag.data,
                            uploadedContent: null
                        },
                    }
                }
            },
        };
    }),
    on(DeploymentActions.SendTestAcceptCheckbox, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: true,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.SendTestAcceptCheckboxSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(DeploymentActions.SendTestAcceptCheckboxError, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.SendTestSaveMissedMergeVariables, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: true,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.SendTestSaveMissedMergeVariablesSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(DeploymentActions.SendTestSaveMissedMergeVariablesError, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.SendTestAndGetMissedData, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: true,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.SendTestAndGetMissedDataSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(DeploymentActions.SendTestAndGetMissedDataError, (state: IEmailBuilderState) => {
        return {
            ...state,
            sendTestResult: {
                loading: false,
                data: state.sendTestResult?.data
            }
        };
    }),
    on(DeploymentActions.DomainAndEmailSuppressionUrlTestFile, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.data?.type;
        let suppressionData = null;
        if (state.selectedDeployment.data.audience.data['suppressionData']) {
            suppressionData = JSON.parse(JSON.stringify(state.selectedDeployment.data.audience.data['suppressionData']));
        }
        else {
            suppressionData = {
                domain: {
                    data: null,
                    loading: false
                },
                email: {
                    data: null,
                    loading: false
                }
            };
        }
        suppressionData[type] = {
            data: null,
            loading: true
        };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        ...state.selectedDeployment?.data?.audience,
                        data: {
                            ...state.selectedDeployment?.data?.audience.data,
                            suppressionData: suppressionData
                        }
                    }
                }
            },
        };
    }),
    on(DeploymentActions.DomainAndEmailSuppressionUrlTestFileSuccess, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.type;
        const suppressionData = JSON.parse(JSON.stringify(state.selectedDeployment.data.audience.data['suppressionData']));
        suppressionData[type] = {
            data: action?.payload?.data[type],
            loading: false
        };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        ...state.selectedDeployment?.data?.audience,
                        data: {
                            ...state.selectedDeployment?.data?.audience.data,
                            suppressionData: suppressionData
                        }
                    }
                }
            },
        };
    }),
    on(DeploymentActions.DomainAndEmailSuppressionUrlTestFileError, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.type;
        const suppressionData = JSON.parse(JSON.stringify(state.selectedDeployment.data.audience.data['suppressionData']));
        suppressionData[type] = {
            data: null,
            loading: false
        };
        return {
            ...state,
            selectedDeployment: {
                data: {
                    ...state.selectedDeployment.data,
                    audience: {
                        ...state.selectedDeployment?.data?.audience,
                        data: {
                            ...state.selectedDeployment?.data?.audience.data,
                            suppressionData: suppressionData
                        }
                    }
                }
            },
        };
    }),
    on(DeploymentActions.CopyTriggeredSettings, (state: IEmailBuilderState) => {
        return {
            ...state,
            copyToTriggeredDeploymentsResult: {
                loading: true,
                data: state.copyToTriggeredDeploymentsResult?.data
            }
        };
    }),
    on(DeploymentActions.CopyTriggeredSettingsSuccess, (state: IEmailBuilderState, action) => {
        const copiedTriggeredSettings: Map<string, Array<SplitDetails>> = Object.assign({}, state.copyToTriggeredDeploymentsResult?.data);
        action?.payload?.forEach((item: CopiedTriggerSettingsResponse) => {
            copiedTriggeredSettings[item.deplId] = item?.splitDetails;
        });
        return {
            ...state,
            copyToTriggeredDeploymentsResult: {
                loading: false,
                data: copiedTriggeredSettings
            }
        };
    }),
    on(DeploymentActions.CopyTriggeredSettingsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            copyToTriggeredDeploymentsResult: {
                loading: false,
                data: state.copyToTriggeredDeploymentsResult?.data
            }
        };
    }),
    on(DeploymentActions.DeleteTriggeredSettings, (state: IEmailBuilderState, action) => {
        const copiedTriggeredSettings = Object.assign({}, state.copyToTriggeredDeploymentsResult?.data);
        if (copiedTriggeredSettings[action.payload.deploymentId]) {
            delete copiedTriggeredSettings[action.payload.deploymentId];
        }
        return {
            ...state,
            copyToTriggeredDeploymentsResult: {
                loading: true,
                data: copiedTriggeredSettings
            }
        };
    }),
    // Message Content Tagging
    on(DeploymentActions.GetMessageContentTags, (state: IEmailBuilderState, action) => {
        if (action.payload.showLoading) {
            return {
                ...state,
                selectedDeploymentTaggingSplits: {
                    loading: true,
                    data: null
                }
            };
        }
        return {
            ...state
        };
    }),

    on(DeploymentActions.GetMessageContentTagsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeploymentTaggingSplits: action.payload
        };
    }),

    on(DeploymentActions.GetMessageContentTagsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeploymentTaggingSplits: {
                loading: false, 
                data: null
            }
        };
    }),

    on(DeploymentActions.ToggleTagSelection, (state: IEmailBuilderState, action) => {
        const { splitId, tagId } = action.payload;
        const currentSplits = state.selectedDeploymentTaggingSplits?.data || [];
        
        const updatedSplits = currentSplits.map(split => {
            if (split.splitId === splitId) {
                const updatedCategories = split.contentFeedbackSplitSchema.tagCategoryList.map(category => {
                    const updatedTags = category.tagFeedbackList.map(tag => {
                        if (tag.tagId === tagId) {
                            return { ...tag, feedbackSelected: !tag.feedbackSelected };
                        }
                        return tag;
                    });
                    return { ...category, tagFeedbackList: updatedTags };
                });
                return {
                    ...split,
                    contentFeedbackSplitSchema: {
                        ...split.contentFeedbackSplitSchema,
                        tagCategoryList: updatedCategories
                    }
                };
            }
            return split;
        });

        return {
            ...state,
            selectedDeploymentTaggingSplits: {
                ...state.selectedDeploymentTaggingSplits,
                data: updatedSplits
            }
        };
    }),

    on(DeploymentActions.SaveTagsFeedback, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeploymentTaggingSplits: {
                ...state.selectedDeploymentTaggingSplits,
                loading: true
            }
        };
    }),
    on(DeploymentActions.SaveTagsFeedbackError, (state: IEmailBuilderState) => {
        return {
            ...state,
            selectedDeploymentTaggingSplits: {
                ...state.selectedDeploymentTaggingSplits,
                loading: false
            }
        };
    }),
    on(DeploymentActions.SaveTagsFeedbackSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            selectedDeploymentTaggingSplits: {
                loading: false,
                data: action.payload
            }
        };
    })
];