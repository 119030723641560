import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ApiKeysActions from './api-keys.actions';
import { ApiKeysRes, SelectedApiKeyData } from './api-keys.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceApiKeysEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadApiKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.LoadApiKeysData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/api-keys?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ApiKeysRes) => {
                            const payload: ResponseWrapper<ApiKeysRes> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.LoadApiKeysDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load ApiKeys.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.LoadApiKeysDataError(error));
                        })
                    );
            })
        )
    );

    getCustomerLookupServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetCustomerLookupServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/customer-lookup', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetCustomerLookupServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Customer Lookup Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetCustomerLookupServicesError(error));
                        })
                    );
            })
        )
    );

    getStoreCustomerInfoServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetStoreCustomerInfoServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/store-customer-info', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetStoreCustomerInfoServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Store Customer Info Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetStoreCustomerInfoServicesError(error));
                        })
                    );
            })
        )
    );

    getBrandLookupServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetBrandLookupServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/brand-lookup-store', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetBrandLookupServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Brand Lookup Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetBrandLookupServicesError(error));
                        })
                    );
            })
        )
    );

    getEmailBuilderServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetEmailBuilderServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/email-builder', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetEmailBuilderServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Email Builder Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetEmailBuilderServicesError(error));
                        })
                    );
            })
        )
    );

    getUtilityServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetUtilityServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/utility', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetUtilityServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Utility Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetUtilityServicesError(error));
                        })
                    );
            })
        )
    );

    getSpecificApiKeyDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetSpecificApiKeyDetails),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/api-keys/${action.payload.apiKey}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedApiKeyData) => {
                            const payload: ResponseWrapper<SelectedApiKeyData> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetSpecificApiKeyDetailsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Specific Api Key Details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/api-keys']);
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetSpecificApiKeyDetailsError(error));
                        })
                    );
            })
        )
    );

    saveApiKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.SaveApiKey),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/api-keys?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', `${!action.payload?.edit ? 'Api Key created successfully' : 'Api Key saved successfully'}.`);
                            this.router.navigate(['/self-service/main/api-keys']);
                            const payload: ResponseWrapper<BasicResponse> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.SaveApiKeySuccess({ payload});
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to ${!action.payload?.edit ? 'save' : 'create'} Api Key.`;
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.SaveApiKeyError(error));
                        })
                    );
            })
        )
    );

    generateApiKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GenerateApiKey),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/genarate-api-key', {...this.defaultHttpOptions, responseType: 'text'},)
                    .pipe(
                        take(1),
                        map((res: string) => {
                            return ApiKeysActions.GenerateApiKeySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to generate Api Key.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GenerateApiKeyError(error));
                        })
                    );
            })
        )
    );

    getOlyticsServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetOlyticsServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/olytics', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetOlyticsServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Olytics Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetOlyticsServicesError(error));
                        })
                    );
            })
        )
    );

    getSmsServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetSmsServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/sms', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetSmsServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Sms Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetSmsServicesError(error));
                        })
                    );
            })
        )
    );

    getAuthenticationServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetAuthenticationServices),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/api-keys/authentication', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return ApiKeysActions.GetAuthenticationServicesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Authentication Services.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetAuthenticationServicesError(error));
                        })
                    );
            })
        )
    );

    getFullApiKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApiKeysActions.GetFullApiKey),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/api-keys/full-api-key/${action.payload}?${searchParams}`, {...this.defaultHttpOptions, responseType: 'text'})
                    .pipe(
                        take(1),
                        map((res: string) => {
                            return ApiKeysActions.GetFullApiKeySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Full Api Key.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApiKeysActions.GetFullApiKeyError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
