import { on } from '@ngrx/store';
import { SmsBuilderState } from '../interfaces';
import * as ManageSmsMessageActions from './manage-sms-message.actions';

export const smsMessageReducer = [
    on(ManageSmsMessageActions.ClearSmsMessageData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: null
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageAdditionalDetails, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    additionalMessageDetails: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.additionalMessageDetails?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageAdditionalDetailsSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    additionalMessageDetails: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageAdditionalDetailsError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    additionalMessageDetails: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ScheduleMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    scheduleMessage: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ScheduleMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    scheduleMessage: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ScheduleMessageError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    scheduleMessage: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UnscheduleMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    unscheduleMessage: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UnscheduleMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    unscheduleMessage: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UnscheduleMessageError, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    unscheduleMessage: {
                        loading: false,
                        data: action,
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendMessageTest, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendTest: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendMessageTestSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendTest: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendMessageTestError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendTest: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendToOne, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendToOne: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendToOneSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendToOne: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.SendToOneError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    sendToOne: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CancelMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    cancelMessage: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CancelMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    cancelMessage: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CancelMessageError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    cancelMessage: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ClearScheduleAndUnscheduleMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    scheduleMessage: {
                        loading: false,
                        data: null,
                    },
                    unscheduleMessage: {
                        loading: false,
                        data: null,
                    },
                    sendToOne: {
                        loading: false,
                        data: null,
                    },
                    cancelMessage: {
                        loading: false,
                        data: null,
                    },
                }
            }
        };
    }),

    // Summary Pleat
    on(ManageSmsMessageActions.CreateSmsMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CreateSmsMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageName: action?.payload?.name,
                    trackingNumber: action?.payload?.trackingNumber,
                    summaryPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CreateSmsMessageError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSummaryPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSummaryPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageName: action?.payload?.name,
                    trackingNumber: action?.payload?.trackingNumber,
                    summaryPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSummaryPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateSummaryPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateSummaryPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageName: action?.payload?.name,
                    trackingNumber: action?.payload?.trackingNumber,
                    summaryPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateSummaryPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CloneSmsMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CloneSmsMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageName: action?.payload?.name,
                    trackingNumber: action?.payload?.trackingNumber,
                    summaryPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CloneSmsMessageError, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    summaryPleatData: {
                        loading: false,
                        errors: action,
                    }
                }
            }
        };
    }),

    // Audience Targeting Pleat
    on(ManageSmsMessageActions.GetAudienceTargetingPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetAudienceTargetingPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetAudienceTargetingPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateAudienceTargetingPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateAudienceTargetingPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateAudienceTargetingPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadQuery, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadQuerySuccess, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadQueryError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadFile, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadFileSuccess, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableUploadFileError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableDeleteAudiences, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableDeleteAudiencesSuccess, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AudienceTargetingTableDeleteAudiencesError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    audienceTargetingPleatData: {
                        loading: false,
                        data: state?.smsMessageState?.smsMessageData?.audienceTargetingPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSingleAudienceData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    uploadInProgress: true,
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSingleAudienceDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    singleAudienceData: action?.payload,
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetSingleAudienceDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    uploadInProgress: true,
                }
            }
        };
    }),

    // Message Content Pleat
    on(ManageSmsMessageActions.GetMessageContentPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageContentPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageContentPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageContentPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageContentPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageContentPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    messageContentPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),

    // Test and Seed Recipients Pleat
    on(ManageSmsMessageActions.GetMessageTesterPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageTesterPleatDataSuccess, (state: SmsBuilderState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        data?.testers?.forEach(tester => {
            tester.delete = false;
            if (tester?.firstName || tester?.lastName) {
                tester.fullName = `${tester?.firstName ? tester?.firstName : ''} ${tester?.lastName ? tester?.lastName : ''}`;
            } else {
                tester.fullName = 'N/A';
            }
        });
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetMessageTesterPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageTesterPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageTesterPleatDataSuccess, (state: SmsBuilderState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        data?.testers?.forEach(tester => {
            tester.delete = false;
            tester.fullName = `${tester?.firstName} ${tester?.lastName}`;
        });
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateMessageTesterPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetTestersList, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableTestersList: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetTestersListSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableTestersList: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetTestersListError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableTestersList: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CreateNewTesterAndAssignToMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CreateNewTesterAndAssignToMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        success: true,
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.CreateNewTesterAndAssignToMessageError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AssignTesterToMessage, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AssignTesterToMessageSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        success: true,
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.AssignTesterToMessageError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    assignTester: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ClearTesterList, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableTestersList: {
                        loading: false,
                        data: null,
                    },
                    assignTester: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UploadRecipientListFromFile, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: true,
                        data: state?.smsMessageState?.smsMessageData?.testAndSeedPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UploadRecipientListFromFileSuccess, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: state?.smsMessageState?.smsMessageData?.testAndSeedPleatData?.data,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UploadRecipientListFromFileError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    testAndSeedPleatData: {
                        loading: false,
                        data: state?.smsMessageState?.smsMessageData?.testAndSeedPleatData?.data,
                    }
                }
            }
        };
    }),

    // Tracking Pleat
    on(ManageSmsMessageActions.GetTrackingPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetTrackingPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetTrackingPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateTrackingPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateTrackingPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateTrackingPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    trackingPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingList, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingStringList: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingListSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingStringList: {
                        loading: false,
                        data: action?.payload,
                    }
                }

            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingListError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingStringList: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetAvailableWebTrackingParameters, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableWebTrackingParametersList: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetAvailableWebTrackingParametersSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableWebTrackingParametersList: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetAvailableWebTrackingParametersError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    availableWebTrackingParametersList: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingConfigurationData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingConfigurationDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetWebTrackingConfigurationDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateWebTrackingConfiguration, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateWebTrackingConfigurationSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.UpdateWebTrackingConfigurationError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.ClearWebTrackingConfigurationData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    webTrackingConfiguration: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),

    // Modification History Pleat
    on(ManageSmsMessageActions.GetModificationHistoryPleatData, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    modificationHistoryPleatData: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetModificationHistoryPleatDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    modificationHistoryPleatData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ManageSmsMessageActions.GetModificationHistoryPleatDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            smsMessageState: {
                ...state?.smsMessageState,
                smsMessageData: {
                    ...state?.smsMessageState?.smsMessageData,
                    modificationHistoryPleatData: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
];
