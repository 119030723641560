import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { IFormBuilderState } from './interfaces';

const initalAppState: IFormBuilderState = {
    currentDatabase: null,
    user: null,
    form: {
        loading: false
    },
    forms: {
        loading: false,
        data: undefined
    },
    formsDownload: {
        loading: false,
        data: undefined
    },
    formSearchFilters: {
        loading: false
    },
    keywords: {
        loading: false,
        data: []
    },
    formAutoSave: {
        loading: false
    },
    page: {
        loading: false
    },
    preview: {
        loading: false
    },
    controlTemplate: {
        loading: false
    },
    elementAttributes: {
        loading: false
    },
    allContents: {
        loading: false,
        data: null
    },
    expressions: {
        loading: false,
        data: undefined
    },
    expressionSearchFilters: {
        loading: false
    },
    errorLogs: {
        loading: false,
        data: []
    },
    errorLogSearchFilters: {
        loading: false
    },
    cloneForm: {
        loading: false,
        data: null
    },
    siteStyles: {
        loading: false,
        data: null
    },
    styles: {
        loading: false,
        data: null
    },
    saveSiteStyles: {
        loading: false
    },
    abTest: {
        loading: false,
        data: null
    },
    saveABTest: {
        loading: false
    },
    resourceProperties: {
        loading: false,
        data: null
    },
    siteResourceProperties: {
        loading: false,
        data: null
    },
    saveSiteResourceProperties: {
        loading: false
    },
    saveConditionalPages: {
        loading: false
    },
    publishLiveForm: {
        loading: false,
        data: null
    },
    addPage: {
        loading: false
    },
    clonePage: {
        loading: false
    },
    deletePage: {
        loading: false
    },
    pagedContents: {
        loading: false,
        data: null
    },
    saveForm: {
        loading: false,
        data: null
    },
    saveGenericContent: {
        loading: false
    },
    saveClientContent: {
        loading: false
    },
    saveEmailNotification: {
        loading: false
    },
    formsWithEmailNotification: {
        loading: false
    },
    demographic: {
        loading: false
    },
    formsWithDemographic: {
        loading: false
    },
    saveDemographic: {
        loading: false
    },
    uploadContentFile: {
        loading: false
    },
    updateFormWithContent: {
        loading: false
    },
    urlContent: {
        loading: false
    },
    savePageEmailNotifications: {
        loading: false
    },
    saveExpression: {
        loading: false
    },
    clientContent: {
        loading: false
    },
    messageTypes: {
        loading: false
    },
    smsNotifications: {
        loading: false
    },
    saveSmsNotification: {
        loading: false
    },
    pageSmsNotifications: {
        loading: false
    },
    savePageSmsNotification: {
        loading: false
    },
    credSparkToken: {
        loading: false
    }
};

function getInitalState(): IFormBuilderState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initalAppState;
    // }
}

function getSavedState(): IFormBuilderState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.GetAllContentList, (state) => {
        return {
            ...state,
            allContents: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetAllContentListSuccess, (state, action) => {
        return {
            ...state,
            allContents: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetAllContentListError, (state) => {
        return {
            ...state,
            allContents: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.GetPagedContents, (state) => {
        return {
            ...state,
            pagedContents: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetPagedContentsSuccess, (state, action) => {
        return {
            ...state,
            pagedContents: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetPagedContentsError, (state) => {
        return {
            ...state,
            pagedContents: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.GetContentsByMask, (state) => {
        return {
            ...state,
            contentsByMask: {
                loading: true,
                data: state.contentsByMask?.data
            }
        };
    }),
    on(AppActions.GetContentsByMaskSuccess, (state, action) => {
        return {
            ...state,
            contentsByMask: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetContentsByMaskError, (state) => {
        return {
            ...state,
            contentsByMask: {
                loading: false,
                errors: true,
                data: state.contentsByMask?.data
            }
        };
    }),
    on(AppActions.GetElementAttributes, (state) => {
        return {
            ...state,
            elementAttributes: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetElementAttributesSuccess, (state, action) => {
        return {
            ...state,
            elementAttributes: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetElementAttributesError, (state) => {
        return {
            ...state,
            elementAttributes: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementAttributes, (state) => {
        return {
            ...state,
            saveElementAttributes: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementAttributesSuccess, (state, action) => {
        return {
            ...state,
            saveElementAttributes: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveElementAttributesError, (state) => {
        return {
            ...state,
            saveElementAttributes: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElements, (state) => {
        return {
            ...state,
            saveElements: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementsSuccess, (state, action) => {
        return {
            ...state,
            saveElements: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveElementsError, (state) => {
        return {
            ...state,
            saveElements: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteElements, (state) => {
        return {
            ...state,
            deleteElements: {
                loading: true
            }
        };
    }),
    on(AppActions.DeleteElementsSuccess, (state) => {
        return {
            ...state,
            deleteElements: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.DeleteElementsError, (state) => {
        return {
            ...state,
            deleteElements: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.SaveElementResponse, (state) => {
        return {
            ...state,
            saveElementResponse: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementResponseSuccess, (state, action) => {
        return {
            ...state,
            saveElementResponse: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveElementResponseError, (state) => {
        return {
            ...state,
            saveElementResponse: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementBehaviorAttribute, (state) => {
        return {
            ...state,
            saveElementBehaviorAttribute: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementBehaviorAttributeSuccess, (state, action) => {
        return {
            ...state,
            saveElementBehaviorAttribute: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveElementBehaviorAttributeError, (state) => {
        return {
            ...state,
            saveElementBehaviorAttribute: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementComplexTag, (state) => {
        return {
            ...state,
            saveElementComplexTag: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveElementComplexTagSuccess, (state, action) => {
        return {
            ...state,
            saveElementComplexTag: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveElementComplexTagError, (state) => {
        return {
            ...state,
            saveElementComplexTag: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetForm, (state) => {
        return {
            ...state,
            form: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFormSuccess, (state, action) => {
        return {
            ...state,
            form: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormError, (state, action) => {
        return {
            ...state,
            form: {
                loading: false,
                data: null,
                errors: action.error
            }
        };
    }),
    on(AppActions.SaveForm, (state) => {
        return {
            ...state,
            saveForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveFormSuccess, (state, action) => {
        return {
            ...state,
            saveForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveFormError, (state) => {
        return {
            ...state,
            saveForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.CloneForm, (state) => {
        return {
            ...state,
            cloneForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.CloneFormSuccess, (state, action) => {
        return {
            ...state,
            cloneForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.CloneFormError, (state) => {
        return {
            ...state,
            cloneForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.ArchiveForm, (state) => {
        return {
            ...state,
            archiveForm: {
                loading: true,
                data: null
            },
            forms: {
                loading: true,
                data: state.forms?.data
            }
        };
    }),
    on(AppActions.ArchiveFormSuccess, (state, action) => {
        return {
            ...state,
            archiveForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.ArchiveFormError, (state) => {
        return {
            ...state,
            archiveForm: {
                loading: false,
                errors: true,
                data: null
            },
            forms: {
                loading: false,
                data: state.forms?.data
            }
        };
    }),
    on(AppActions.RestoreArchivedForm, (state) => {
        return {
            ...state,
            restoreArchivedForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.RestoreArchivedFormSuccess, (state, action) => {
        return {
            ...state,
            restoreArchivedForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.RestoreArchivedFormError, (state) => {
        return {
            ...state,
            restoreArchivedForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.RevertForm, (state) => {
        return {
            ...state,
            revertForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.RevertFormSuccess, (state, action) => {
        return {
            ...state,
            revertForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.RevertFormError, (state) => {
        return {
            ...state,
            revertForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.RemoveLiveForm, (state) => {
        return {
            ...state,
            removeLiveForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.RemoveLiveFormSuccess, (state, action) => {
        return {
            ...state,
            removeLiveForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.RemoveLiveFormError, (state) => {
        return {
            ...state,
            removeLiveForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.RestoreBackupForm, (state) => {
        return {
            ...state,
            restoreBackupForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.RestoreBackupFormSuccess, (state, action) => {
        return {
            ...state,
            restoreBackupForm: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.RestoreBackupFormError, (state) => {
        return {
            ...state,
            restoreBackupForm: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetEmbeddedScript, (state) => {
        return {
            ...state,
            embeddedScript: {
                loading: true,
                data: state.embeddedScript?.data
            }
        };
    }),
    on(AppActions.GetEmbeddedScriptSuccess, (state, action) => {
        return {
            ...state,
            embeddedScript: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetEmbeddedScriptError, (state) => {
        return {
            ...state,
            embeddedScript: {
                loading: false,
                errors: true,
                data: state.embeddedScript?.data
            }
        };
    }),
    on(AppActions.GetFormList, (state) => {
        return {
            ...state,
            forms: {
                loading: true,
                data: state.forms?.data
            }
        };
    }),
    on(AppActions.GetFormListSuccess, (state, action) => {
        return {
            ...state,
            forms: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormListError, (state) => {
        return {
            ...state,
            forms: {
                loading: false,
                errors: true,
                data: state.forms?.data
            }
        };
    }),
    on(AppActions.GetFormList2, (state) => {
        return {
            ...state,
            forms2: {
                loading: true,
                data: state.forms2?.data
            }
        };
    }),
    on(AppActions.GetFormList2Success, (state, action) => {
        return {
            ...state,
            forms2: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormList2Error, (state) => {
        return {
            ...state,
            forms2: {
                loading: false,
                errors: true,
                data: state.forms2?.data
            }
        };
    }),
    on(AppActions.GetFormsDownload, (state) => {
        return {
            ...state,
            formsDownload: {
                loading: true,
                data: state.formsDownload?.data
            }
        };
    }),
    on(AppActions.GetFormsDownloadSuccess, (state, action) => {
        return {
            ...state,
            formsDownload: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormsDownloadError, (state) => {
        return {
            ...state,
            formsDownload: {
                loading: false,
                errors: true,
                data: state.formsDownload?.data
            }
        };
    }),
    on(AppActions.GetKeywords, (state) => {
        return {
            ...state,
            keywords: {
                loading: true,
                data: state.keywords?.data
            }
        };
    }),
    on(AppActions.GetKeywordsSuccess, (state, action) => {
        return {
            ...state,
            keywords: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetKeywordsError, (state) => {
        return {
            ...state,
            keywords: {
                loading: false,
                errors: true,
                data: state.keywords?.data
            }
        };
    }),
    on(AppActions.SaveKeyword, (state) => {
        return {
            ...state,
            saveKeyword: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveKeywordSuccess, (state, action) => {
        return {
            ...state,
            saveKeyword: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveKeywordError, (state) => {
        return {
            ...state,
            saveKeyword: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteKeyword, (state) => {
        return {
            ...state,
            deleteKeyword: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteKeywordSuccess, (state) => {
        return {
            ...state,
            deleteKeyword: {
                loading: false,
                success: true,
                data: true
            }
        };
    }),
    on(AppActions.DeleteKeywordError, (state) => {
        return {
            ...state,
            deleteKeyword: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveFormKeyword, (state) => {
        return {
            ...state,
            saveFormKeyword: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveFormKeywordSuccess, (state, action) => {
        return {
            ...state,
            saveFormKeyword: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveFormKeywordError, (state) => {
        return {
            ...state,
            saveFormKeyword: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteFormKeyword, (state) => {
        return {
            ...state,
            deleteFormKeyword: {
                loading: true
            }
        };
    }),
    on(AppActions.DeleteFormKeywordSuccess, (state) => {
        return {
            ...state,
            deleteFormKeyword: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.DeleteFormKeywordError, (state) => {
        return {
            ...state,
            deleteFormKeyword: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetPreview, (state) => {
        return {
            ...state,
            preview: {
                loading: true,
                data: state.preview?.data
            }
        };
    }),
    on(AppActions.GetPreviewSuccess, (state, action) => {
        return {
            ...state,
            preview: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetPreviewError, (state) => {
        return {
            ...state,
            preview: {
                loading: false,
                errors: true,
                data: state.preview?.data
            }
        };
    }),
    on(AppActions.PublishTestForm, (state) => {
        return {
            ...state,
            publishTestForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.PublishTestFormSuccess, (state, action) => {
        return {
            ...state,
            publishTestForm: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.PublishTestFormError, (state) => {
        return {
            ...state,
            publishTestForm: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.PublishLiveForm, (state) => {
        return {
            ...state,
            publishLiveForm: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.PublishLiveFormSuccess, (state, action) => {
        return {
            ...state,
            publishLiveForm: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.PublishLiveFormError, (state, action) => {
        return {
            ...state,
            publishLiveForm: {
                loading: false,
                data: null,
                errors: action?.error?.errors || true
            }
        };
    }),
    on(AppActions.GetFormSearchFilter, (state) => {
        return {
            ...state,
            formSearchFilters: {
                loading: true,
                data: state.formSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetFormSearchFilterSuccess, (state, action) => {
        return {
            ...state,
            formSearchFilters: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormSearchFilterError, (state) => {
        return {
            ...state,
            formSearchFilters: {
                loading: false,
                errors: true,
                data: state.formSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetDomains, (state) => {
        return {
            ...state,
            domains: {
                loading: true,
                data: state.domains?.data
            }
        };
    }),
    on(AppActions.GetDomainsSuccess, (state, action) => {
        return {
            ...state,
            domains: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetDomainsError, (state) => {
        return {
            ...state,
            domains: {
                loading: false,
                errors: true,
                data: state.domains?.data
            }
        };
    }),
    on(AppActions.GetTemplates, (state) => {
        return {
            ...state,
            templates: {
                loading: true,
                data: state.templates?.data
            }
        };
    }),
    on(AppActions.GetTemplatesSuccess, (state, action) => {
        return {
            ...state,
            templates: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetTemplatesError, (state) => {
        return {
            ...state,
            templates: {
                loading: false,
                errors: true,
                data: state.templates?.data
            }
        };
    }),
    on(AppActions.GetInputs, (state) => {
        return {
            ...state,
            inputs: {
                loading: true,
                data: state.inputs?.data
            }
        };
    }),
    on(AppActions.GetInputsSuccess, (state, action) => {
        return {
            ...state,
            inputs: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetInputsError, (state) => {
        return {
            ...state,
            inputs: {
                loading: false,
                errors: true,
                data: state.inputs?.data
            }
        };
    }),
    on(AppActions.GetDemographics, (state) => {
        return {
            ...state,
            demographics: {
                loading: true,
                data: state.demographics?.data
            }
        };
    }),
    on(AppActions.GetDemographicsSuccess, (state, action) => {
        return {
            ...state,
            demographics: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetDemographicsError, (state) => {
        return {
            ...state,
            demographics: {
                loading: false,
                errors: true,
                data: state.demographics?.data
            }
        };
    }),
    on(AppActions.GetDemographic, (state) => {
        return {
            ...state,
            demographic: {
                loading: true,
                data: state.demographic?.data
            }
        };
    }),
    on(AppActions.GetDemographicSuccess, (state, action) => {
        return {
            ...state,
            demographic: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetDemographicError, (state) => {
        return {
            ...state,
            demographic: {
                loading: false,
                errors: true,
                data: state.demographic?.data
            }
        };
    }),
    on(AppActions.GetFormsWithDemographic, (state) => {
        return {
            ...state,
            formsWithDemographic: {
                loading: true,
                data: state.formsWithDemographic?.data
            }
        };
    }),
    on(AppActions.GetFormsWithDemographicSuccess, (state, action) => {
        return {
            ...state,
            formsWithDemographic: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormsWithDemographicError, (state) => {
        return {
            ...state,
            formsWithDemographic: {
                loading: false,
                errors: true,
                data: state.formsWithDemographic?.data
            }
        };
    }),
    on(AppActions.SaveDemographic, (state) => {
        return {
            ...state,
            saveDemographic: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveDemographicSuccess, (state, action) => {
        return {
            ...state,
            saveDemographic: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveDemographicError, (state) => {
        return {
            ...state,
            saveDemographic: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetThemes, (state) => {
        return {
            ...state,
            themes: {
                loading: true,
                data: state.themes?.data
            }
        };
    }),
    on(AppActions.GetThemesSuccess, (state, action) => {
        return {
            ...state,
            themes: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetThemesError, (state) => {
        return {
            ...state,
            themes: {
                loading: false,
                errors: true,
                data: state.themes?.data
            }
        };
    }),
    on(AppActions.GetFormAutoSave,
        AppActions.SaveFormAutoSave, (state) => {
            return {
                ...state,
                formAutoSave: {
                    loading: true,
                    data: state.formAutoSave?.data,
                }
            };
        }),
    on(AppActions.GetFormAutoSaveSuccess,
        AppActions.SaveFormAutoSaveSuccess,
        (state, action) => {
            return {
                ...state,
                formAutoSave: {
                    loading: false,
                    data: action.payload,
                    success: true
                }
            };
        }),
    on(AppActions.GetFormAutoSaveError,
        AppActions.SaveFormAutoSaveError,
        (state) => {
            return {
                ...state,
                formAutoSave: {
                    loading: false,
                    data: null,
                    errors: true
                }
            };
        }),
    on(AppActions.GetPage, (state, action) => {
        return {
            ...state,
            page: {
                loading: true,
                saving: Boolean(action?.payload?.isAutoSave),
                data: null
            }
        };
    }),
    on(AppActions.GetPageSuccess, (state, action) => {
        return {
            ...state,
            page: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetPageError, (state) => {
        return {
            ...state,
            page: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.GetProducts, (state) => {
        return {
            ...state,
            products: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetProductsSuccess, (state, action) => {
        return {
            ...state,
            products: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductsError, (state) => {
        return {
            ...state,
            products: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.SavePage, (state) => {
        return {
            ...state,
            savePage: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SavePageSuccess, (state, action) => {
        return {
            ...state,
            savePage: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.SavePageError, (state) => {
        return {
            ...state,
            savePage: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.SavePageTitle, (state) => {
        return {
            ...state,
            savePageTitle: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SavePageTitleSuccess, (state, action) => {
        return {
            ...state,
            savePageTitle: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.SavePageTitleError, (state) => {
        return {
            ...state,
            savePageTitle: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.GetPageEmailNotifications, (state) => {
        return {
            ...state,
            pageEmailNotifications: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetPageEmailNotificationsSuccess, (state, action) => {
        return {
            ...state,
            pageEmailNotifications: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetPageEmailNotificationsError, (state) => {
        return {
            ...state,
            pageEmailNotifications: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SavePageEmailNotifications, (state) => {
        return {
            ...state,
            savePageEmailNotifications: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SavePageEmailNotificationsSuccess, (state, action) => {
        return {
            ...state,
            savePageEmailNotifications: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SavePageEmailNotificationsError, (state) => {
        return {
            ...state,
            savePageEmailNotifications: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetPages, (state) => {
        return {
            ...state,
            pages: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetPagesSuccess, (state, action) => {
        return {
            ...state,
            pages: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetPagesError, (state) => {
        return {
            ...state,
            pages: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetConditionalPages, (state) => {
        return {
            ...state,
            conditionalPages: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetConditionalPagesSuccess, (state, action) => {
        return {
            ...state,
            conditionalPages: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetConditionalPagesError, (state) => {
        return {
            ...state,
            conditionalPages: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveConditionalPages, (state) => {
        return {
            ...state,
            saveConditionalPages: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveConditionalPagesSuccess, (state) => {
        return {
            ...state,
            saveConditionalPages: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveConditionalPagesError, (state) => {
        return {
            ...state,
            saveConditionalPages: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.AddPage, (state) => {
        return {
            ...state,
            addPage: {
                loading: true,
            }
        };
    }),
    on(AppActions.AddPageSuccess, (state) => {
        return {
            ...state,
            addPage: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.AddPageError, (state) => {
        return {
            ...state,
            addPage: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.ClonePage, (state) => {
        return {
            ...state,
            clonePage: {
                loading: true
            }
        };
    }),
    on(AppActions.ClonePageSuccess, (state) => {
        return {
            ...state,
            clonePage: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.ClonePageError, (state) => {
        return {
            ...state,
            clonePage: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.DeletePage, (state) => {
        return {
            ...state,
            deletePage: {
                loading: true
            }
        };
    }),
    on(AppActions.DeletePageSuccess, (state) => {
        return {
            ...state,
            deletePage: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.DeletePageError, (state) => {
        return {
            ...state,
            deletePage: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetStyles, (state) => {
        return {
            ...state,
            styles: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetStylesSuccess, (state, action) => {
        return {
            ...state,
            styles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetStylesError, (state) => {
        return {
            ...state,
            styles: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.GetSiteStyles, (state) => {
        return {
            ...state,
            siteStyles: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSiteStylesSuccess, (state, action) => {
        return {
            ...state,
            siteStyles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetSiteStylesError, (state) => {
        return {
            ...state,
            siteStyles: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),
    on(AppActions.SaveSiteStyles, (state) => {
        return {
            ...state,
            saveSiteStyles: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveSiteStylesSuccess, (state) => {
        return {
            ...state,
            saveSiteStyles: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveSiteStylesError, (state) => {
        return {
            ...state,
            saveSiteStyles: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetResourceProperties, (state) => {
        return {
            ...state,
            resourceProperties: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetResourcePropertiesSuccess, (state, action) => {
        return {
            ...state,
            resourceProperties: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetResourcePropertiesError, (state) => {
        return {
            ...state,
            resourceProperties: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSiteResourceProperties, (state) => {
        return {
            ...state,
            siteResourceProperties: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSiteResourcePropertiesSuccess, (state, action) => {
        return {
            ...state,
            siteResourceProperties: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetSiteResourcePropertiesError, (state) => {
        return {
            ...state,
            siteResourceProperties: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveSiteResourceProperties, (state) => {
        return {
            ...state,
            saveSiteResourceProperties: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveSiteResourcePropertiesSuccess, (state) => {
        return {
            ...state,
            saveSiteResourceProperties: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveSiteResourcePropertiesError, (state) => {
        return {
            ...state,
            saveSiteResourceProperties: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetABTest, (state) => {
        return {
            ...state,
            abTest: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetABTestSuccess, (state, action) => {
        return {
            ...state,
            abTest: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetABTestError, (state) => {
        return {
            ...state,
            abTest: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveABTest, (state) => {
        return {
            ...state,
            saveABTest: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveABTestSuccess, (state) => {
        return {
            ...state,
            saveABTest: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveABTestError, (state) => {
        return {
            ...state,
            saveABTest: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetClientContentSearchFilter, (state) => {
        return {
            ...state,
            clientContentSearchFilters: {
                loading: true,
                data: state.clientContentSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetClientContentSearchFilterSuccess, (state, action) => {
        return {
            ...state,
            clientContentSearchFilters: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetClientContentSearchFilterError, (state) => {
        return {
            ...state,
            clientContentSearchFilters: {
                loading: false,
                errors: true,
                data: state.clientContentSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetGenericContentSearchFilter, (state) => {
        return {
            ...state,
            genericContentSearchFilters: {
                loading: true,
                data: state.genericContentSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetGenericContentSearchFilterSuccess, (state, action) => {
        return {
            ...state,
            genericContentSearchFilters: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetGenericContentSearchFilterError, (state) => {
        return {
            ...state,
            genericContentSearchFilters: {
                loading: false,
                errors: true,
                data: state.genericContentSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetClientContentCategoryGroups, (state) => {
        return {
            ...state,
            clientContentCategoryGroups: {
                loading: true,
                data: state.clientContentCategoryGroups?.data
            }
        };
    }),
    on(AppActions.GetClientContentCategoryGroupsSuccess, (state, action) => {
        return {
            ...state,
            clientContentCategoryGroups: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetClientContentCategoryGroupsError, (state) => {
        return {
            ...state,
            clientContentCategoryGroups: {
                loading: false,
                errors: true,
                data: state.clientContentCategoryGroups?.data
            }
        };
    }),
    on(AppActions.GetGenericContentCategoryGroups, (state) => {
        return {
            ...state,
            genericContentCategoryGroups: {
                loading: true,
                data: state.genericContentCategoryGroups?.data
            }
        };
    }),
    on(AppActions.GetGenericContentCategoryGroupsSuccess, (state, action) => {
        return {
            ...state,
            genericContentCategoryGroups: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetGenericContentCategoryGroupsError, (state) => {
        return {
            ...state,
            genericContentCategoryGroups: {
                loading: false,
                errors: true,
                data: state.genericContentCategoryGroups?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategory, (state) => {
        return {
            ...state,
            clientContentByCategory: {
                loading: true,
                data: state.clientContentByCategory?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategorySuccess, (state, action) => {
        return {
            ...state,
            clientContentByCategory: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategoryError, (state) => {
        return {
            ...state,
            clientContentByCategory: {
                loading: false,
                errors: true,
                data: state.clientContentByCategory?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategory2, (state) => {
        return {
            ...state,
            clientContentByCategory2: {
                loading: true,
                data: state.clientContentByCategory2?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategory2Success, (state, action) => {
        return {
            ...state,
            clientContentByCategory2: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetClientContentByCategory2Error, (state) => {
        return {
            ...state,
            clientContentByCategory2: {
                loading: false,
                errors: true,
                data: state.clientContentByCategory2?.data
            }
        };
    }),
    on(AppActions.GetGenericContentByCategory, (state) => {
        return {
            ...state,
            genericContentByCategory: {
                loading: true,
                data: state.genericContentByCategory?.data
            }
        };
    }),
    on(AppActions.GetGenericContentByCategorySuccess, (state, action) => {
        return {
            ...state,
            genericContentByCategory: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetGenericContentByCategoryError, (state) => {
        return {
            ...state,
            genericContentByCategory: {
                loading: false,
                errors: true,
                data: state.genericContentByCategory?.data
            }
        };
    }),
    on(AppActions.GetGenericContent, (state) => {
        return {
            ...state,
            genericContent: {
                loading: true,
                data: state.genericContent?.data
            }
        };
    }),
    on(AppActions.GetGenericContentSuccess, (state, action) => {
        return {
            ...state,
            genericContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetGenericContentError, (state) => {
        return {
            ...state,
            genericContent: {
                loading: false,
                errors: true,
                data: state.genericContent?.data
            }
        };
    }),
    on(AppActions.SaveGenericContent, (state) => {
        return {
            ...state,
            saveGenericContent: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveGenericContentSuccess, (state, action) => {
        return {
            ...state,
            saveGenericContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveGenericContentError, (state) => {
        return {
            ...state,
            saveGenericContent: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetClientContent, (state) => {
        return {
            ...state,
            clientContent: {
                loading: true,
                data: state.clientContent?.data
            }
        };
    }),
    on(AppActions.GetClientContentSuccess, (state, action) => {
        return {
            ...state,
            clientContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetClientContentError, (state) => {
        return {
            ...state,
            clientContent: {
                loading: false,
                errors: true,
                data: state.clientContent?.data
            }
        };
    }),
    on(AppActions.SaveClientContent, (state) => {
        return {
            ...state,
            saveClientContent: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveClientContentSuccess, (state, action) => {
        return {
            ...state,
            saveClientContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveClientContentError, (state) => {
        return {
            ...state,
            saveClientContent: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteClientContent, (state) => {
        return {
            ...state,
            deleteClientContent: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteClientContentSuccess, (state) => {
        return {
            ...state,
            deleteClientContent: {
                loading: false,
                success: true,
                data: true
            }
        };
    }),
    on(AppActions.DeleteClientContentError, (state) => {
        return {
            ...state,
            deleteClientContent: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteEmailNotification, (state) => {
        return {
            ...state,
            deleteEmailNotification: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.DeleteEmailNotificationSuccess, (state) => {
        return {
            ...state,
            deleteEmailNotification: {
                loading: false,
                success: true,
                data: true
            }
        };
    }),
    on(AppActions.DeleteEmailNotificationError, (state) => {
        return {
            ...state,
            deleteEmailNotification: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.UploadContentFile, (state) => {
        return {
            ...state,
            uploadContentFile: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.UploadContentFileSuccess, (state, action) => {
        return {
            ...state,
            uploadContentFile: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.UploadContentFileError, (state) => {
        return {
            ...state,
            uploadContentFile: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.UpdateFormWithContent, (state) => {
        return {
            ...state,
            updateFormWithContent: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.UpdateFormWithContentSuccess, (state, action) => {
        return {
            ...state,
            updateFormWithContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.UpdateFormWithContentError, (state) => {
        return {
            ...state,
            updateFormWithContent: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetEmailNotifications, (state) => {
        return {
            ...state,
            emailNotifications: {
                loading: true,
                data: state.emailNotifications?.data
            }
        };
    }),
    on(AppActions.GetEmailNotificationsSuccess, (state, action) => {
        return {
            ...state,
            emailNotifications: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetEmailNotificationsError, (state) => {
        return {
            ...state,
            emailNotifications: {
                loading: false,
                errors: true,
                data: state.emailNotifications?.data
            }
        };
    }),
    on(AppActions.GetEmailNotification, (state) => {
        return {
            ...state,
            emailNotification: {
                loading: true,
                data: state.emailNotification?.data
            }
        };
    }),
    on(AppActions.GetEmailNotificationSuccess, (state, action) => {
        return {
            ...state,
            emailNotification: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetEmailNotificationError, (state) => {
        return {
            ...state,
            emailNotification: {
                loading: false,
                errors: true,
                data: state.emailNotification?.data
            }
        };
    }),
    on(AppActions.GetFormsWithEmailNotification, (state) => {
        return {
            ...state,
            formsWithEmailNotification: {
                loading: true,
                data: state.formsWithEmailNotification?.data
            }
        };
    }),
    on(AppActions.GetFormsWithEmailNotificationSuccess, (state, action) => {
        return {
            ...state,
            formsWithEmailNotification: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFormsWithEmailNotificationError, (state) => {
        return {
            ...state,
            formsWithEmailNotification: {
                loading: false,
                errors: true,
                data: state.formsWithEmailNotification?.data
            }
        };
    }),
    on(AppActions.RetrieveUrlContent, (state) => {
        return {
            ...state,
            urlContent: {
                loading: true,
                data: state.urlContent?.data
            }
        };
    }),
    on(AppActions.RetrieveUrlContentSuccess, (state, action) => {
        return {
            ...state,
            urlContent: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.RetrieveUrlContentError, (state) => {
        return {
            ...state,
            urlContent: {
                loading: false,
                errors: true,
                data: state.urlContent?.data
            }
        };
    }),
    on(AppActions.SaveEmailNotification, (state) => {
        return {
            ...state,
            saveEmailNotification: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveEmailNotificationSuccess, (state, action) => {
        return {
            ...state,
            saveEmailNotification: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveEmailNotificationError, (state) => {
        return {
            ...state,
            saveEmailNotification: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetExpressions, (state) => {
        return {
            ...state,
            expressions: {
                loading: true,
                data: state.expressions?.data
            }
        };
    }),
    on(AppActions.GetExpressionsSuccess, (state, action) => {
        return {
            ...state,
            expressions: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetExpressionsError, (state) => {
        return {
            ...state,
            expressions: {
                loading: false,
                errors: true,
                data: state.expressions?.data
            }
        };
    }),
    on(AppActions.GetExpressionsForElement, (state) => {
        return {
            ...state,
            expressionsForElement: {
                loading: true,
                data: state.expressionsForElement?.data
            }
        };
    }),
    on(AppActions.GetExpressionsForElementSuccess, (state, action) => {
        return {
            ...state,
            expressionsForElement: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetExpressionsForElementError, (state) => {
        return {
            ...state,
            expressionsForElement: {
                loading: false,
                errors: true,
                data: state.expressionsForElement?.data
            }
        };
    }),
    on(AppActions.GetExpressionSearchFilter, (state) => {
        return {
            ...state,
            expressionSearchFilters: {
                loading: true,
                data: state.expressionSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetExpressionSearchFilterSuccess, (state, action) => {
        return {
            ...state,
            expressionSearchFilters: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetExpressionSearchFilterError, (state) => {
        return {
            ...state,
            expressionSearchFilters: {
                loading: false,
                errors: true,
                data: state.expressionSearchFilters?.data
            }
        };
    }),
    on(AppActions.ArchiveExpression, (state) => {
        return {
            ...state,
            archiveExpression: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.ArchiveExpressionSuccess, (state) => {
        return {
            ...state,
            archiveExpression: {
                loading: false,
                success: true,
                data: true
            }
        };
    }),
    on(AppActions.ArchiveExpressionError, (state) => {
        return {
            ...state,
            archiveExpression: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.RestoreExpression, (state) => {
        return {
            ...state,
            restoreExpression: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.RestoreExpressionSuccess, (state) => {
        return {
            ...state,
            restoreExpression: {
                loading: false,
                success: true,
                data: true
            }
        };
    }),
    on(AppActions.RestoreExpressionError, (state) => {
        return {
            ...state,
            restoreExpression: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveExpression, (state) => {
        return {
            ...state,
            saveExpression: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveExpressionSuccess, (state, action) => {
        return {
            ...state,
            saveExpression: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveExpressionError, (state) => {
        return {
            ...state,
            saveExpression: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetExpressionValues, (state) => {
        return {
            ...state,
            expressionValues: {
                loading: true,
                data: state.expressionValues?.data
            }
        };
    }),
    on(AppActions.GetExpressionValuesSuccess, (state, action) => {
        return {
            ...state,
            expressionValues: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetExpressionValuesError, (state) => {
        return {
            ...state,
            expressionValues: {
                loading: false,
                errors: true,
                data: state.expressionValues?.data
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeTypes, (state) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeTypesSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeTypesError, (state, action) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                loading: false,
                data: null,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValues, (state) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValuesSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: false,
                data: action.payload.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValuesError, (state, action) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: false,
                data: null,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetErrorLogs, (state) => {
        return {
            ...state,
            errorLogs: {
                loading: true,
                data: state.errorLogs?.data
            }
        };
    }),
    on(AppActions.GetErrorLogsSuccess, (state, action) => {
        return {
            ...state,
            errorLogs: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetErrorLogsError, (state) => {
        return {
            ...state,
            errorLogs: {
                loading: false,
                errors: true,
                data: state.errorLogs?.data
            }
        };
    }),
    on(AppActions.GetErrorLogDetails, (state) => {
        return {
            ...state,
            errorLogDetails: {
                loading: true,
                data: state.errorLogDetails?.data
            }
        };
    }),
    on(AppActions.GetErrorLogDetailsSuccess, (state, action) => {
        return {
            ...state,
            errorLogDetails: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetErrorLogDetailsError, (state) => {
        return {
            ...state,
            errorLogDetails: {
                loading: false,
                errors: true,
                data: state.errorLogDetails?.data
            }
        };
    }),
    on(AppActions.GetErrorLogSearchFilter, (state) => {
        return {
            ...state,
            errorLogSearchFilters: {
                loading: true,
                data: state.errorLogSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetErrorLogSearchFilterSuccess, (state, action) => {
        return {
            ...state,
            errorLogSearchFilters: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetErrorLogSearchFilterError, (state) => {
        return {
            ...state,
            errorLogSearchFilters: {
                loading: false,
                errors: true,
                data: state.errorLogSearchFilters?.data
            }
        };
    }),
    on(AppActions.GetBeeAuthToken, (state) => {
        return {
            ...state,
            beeAuthToken: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetBeeAuthTokenSuccess, (state, action) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetBeeAuthTokenError, (state) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveBeeAIToken, (state) => {
        return {
            ...state,
            beeAuthToken: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveBeeAITokenSuccess, (state, action) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SaveBeeAITokenError, (state) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                errors: true,
                data: null
            }
        };
    }),
    on(AppActions.GetMessageTypes, (state) => {
        return {
            ...state,
            messageTypes: {
                ...state.messageTypes,
                loading: true
            }
        };
    }),
    on(AppActions.GetMessageTypesSuccess, (state, action) => {
        return {
            ...state,
            messageTypes: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetMessageTypesError, (state) => {
        return {
            ...state,
            messageTypes: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetSmsNotifications, state => {
        return {
            ...state,
            smsNotifications: {
                loading: true
            }
        };
    }),
    on(AppActions.GetSmsNotificationsSuccess, (state, action) => {
        return {
            ...state,
            smsNotifications: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetSmsNotificationsError, state => {
        return {
            ...state,
            smsNotifications: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetSmsNotification, state => {
        return {
            ...state,
            smsNotification: {
                loading: true
            }
        };
    }),
    on(AppActions.GetSmsNotificationSuccess, (state, action) => {
        return {
            ...state,
            smsNotification: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetSmsNotificationError, state => {
        return {
            ...state,
            smsNotification: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.SaveSmsNotification, state => {
        return {
            ...state,
            saveSmsNotification: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveSmsNotificationSuccess, (state, action) => {
        return {
            ...state,
            saveSmsNotification: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.SaveSmsNotificationError, state => {
        return {
            ...state,
            saveSmsNotification: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.DeleteSmsNotification, state => {
        return {
            ...state,
            deleteSmsNotification: {
                loading: true
            }
        };
    }),
    on(AppActions.DeleteSmsNotificationSuccess, state => {
        return {
            ...state,
            deleteSmsNotification: {
                loading: false,
                success: true,
            }
        };
    }),
    on(AppActions.DeleteSmsNotificationError, state => {
        return {
            ...state,
            deleteSmsNotification: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetPageSmsNotifications, state => {
        return {
            ...state,
            pageSmsNotifications: {
                loading: true
            }
        };
    }),
    on(AppActions.GetPageSmsNotificationsSuccess, (state, action) => {
        return {
            ...state,
            pageSmsNotifications: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetPageSmsNotificationsError, state => {
        return {
            ...state,
            pageSmsNotifications: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.SavePageSmsNotification, state => {
        return {
            ...state,
            savePageSmsNotification: {
                loading: true
            }
        };
    }),
    on(AppActions.SavePageSmsNotificationSuccess, (state, action) => {
        return {
            ...state,
            savePageSmsNotification: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.SavePageSmsNotificationError, state => {
        return {
            ...state,
            savePageSmsNotification: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.GetCredSparkToken, state => {
        return {
            ...state,
            credSparkToken: {
                loading: true
            }
        };
    }),
    on(AppActions.GetCredSparkTokenSuccess, (state, action) => {
        return {
            ...state,
            credSparkToken: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetCredSparkTokenError, state => {
        return {
            ...state,
            credSparkToken: {
                loading: false,
                errors: true
            }
        };
    }),       
    /* Clears */
    on(AppActions.ClearStoreValue, (state, action) => {
        const storeVal: string = action.payload.storeValue;
        const initVal = initalAppState[storeVal];
        return {
            ...state,
            [storeVal]: initVal
        };
    })
);

export function formBuilderReducer(state: IFormBuilderState | undefined, action: Action) {
    return appReducer(state, action);
}
