import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CdpOlyticsActions from './olytics.actions';
import { OlyticsBehavior, OlyticsBehaviorResponse, OlyticsDomainsResponse, OlyticsImplementationData, OlyticsProfiles, OlyticsTagsResponse, PageTypeExclusionsResponse, OlyticsTitleSourceResponse } from './olytics.interface';

@Injectable({ providedIn: 'root' })
export class CustomerDataPlatformOlyticsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService,
        private route: ActivatedRoute
    ) { }

    getOlyticsSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetOlyticsImplementationData),
            exhaustMap(() => {
                const url = new URL(this.baseUrl + `/olytics?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('includeFireScript', 'true');
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsImplementationData) => {
                            const payload: ResponseWrapper<OlyticsImplementationData> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetOlyticsImplementationDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load olytics data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetOlyticsImplementationDataError(error));
                        })
                    );
            })
        )
    );

    getOlyticsBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetOlyticsBehaviors),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/behavior?environmentId=${this.currentDatabaseId}`);
                action.payload?.offset ? url.searchParams.append('offset', action.payload?.offset?.toString()) : null;
                action.payload?.numResults ? url.searchParams.append('numResults', action.payload?.numResults?.toString()) : null;
                action.payload?.sortBy ? url.searchParams.append('sortBy', action.payload?.sortBy) : null;
                action.payload?.order ? url.searchParams.append('order', action.payload?.order) : null;
                action.payload?.name ? url.searchParams.append('name', action.payload?.name) : null;
                action.payload?.createdData ? url.searchParams.append('createdDate', action.payload?.createdData) : null;
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsBehaviorResponse) => {
                            const payload: ResponseWrapper<OlyticsBehaviorResponse> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetOlyticsBehaviorsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load olytics behaviors.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetOlyticsBehaviorsError(error));
                        })
                    );
            })
        )
    );

    getOlyticsDomains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetOlyticsDomains),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/domain?environmentId=${this.currentDatabaseId}`);
                action.payload?.offset ? url.searchParams.append('offset', action.payload?.offset?.toString()) : null;
                action.payload?.numResults ? url.searchParams.append('numResults', action.payload?.numResults?.toString()) : null;
                action.payload?.sortBy ? url.searchParams.append('sortBy', action.payload?.sortBy) : null;
                action.payload?.order ? url.searchParams.append('order', action.payload?.order) : null;
                action.payload?.name ? url.searchParams.append('name', action.payload?.name) : null;
                action.payload?.createdData ? url.searchParams.append('createdDate', action.payload?.createdData) : null;

                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsDomainsResponse) => {
                            const payload: ResponseWrapper<OlyticsDomainsResponse> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetOlyticsDomainsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load olytics domains.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetOlyticsDomainsError(error));
                        })
                    );
            })
        )
    );

    saveOlyticsBehavior$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveOlyticsBehavior),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/behavior/save?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsBehaviors({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            this.store.dispatch(CdpOlyticsActions.GetContentRecommendationBehaviors());
                            return CdpOlyticsActions.SaveOlyticsBehaviorSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save olytics behavior.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveOlyticsBehaviorError(error));
                        })
                    );
            })
        )
    );

    saveOlyticsDomain$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveOlyticsDomain),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/domain/save?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsDomains({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.SaveOlyticsDomainSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save olytics Domain.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveOlyticsDomainError(error));
                        })
                    );
            })
        )
    );

    getOlyticsProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetOlyticsProfiles),
            exhaustMap(() => {
                const url = new URL(this.baseUrl + `/olytics/profile?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<OlyticsProfiles>) => {
                            const payload: ResponseWrapper<Array<OlyticsProfiles>> = {
                                loading: false,
                                data: res
                            };
                            // this.store.dispatch(CdpOlyticsActions.GetOlyticsBehaviors({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.GetOlyticsProfilesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get olytics profiles.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetOlyticsProfilesError(error));
                        })
                    );
            })
        )
    );

    deleteOlyticsDomain$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.DeleteOlyticsDomain),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/domain/delete?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsDomains({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.DeleteOlyticsDomainSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete olytics Domain.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.DeleteOlyticsDomainError(error));
                        })
                    );
            })
        )
    );

    deleteOlyticsBehavior$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.DeleteOlyticsBehavior),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/behavior/delete?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsBehaviors({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.DeleteOlyticsBehaviorSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete olytics behavior.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.DeleteOlyticsBehaviorError(error));
                        })
                    );
            })
        )
    );

    syncDomainsAndBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SyncDomainsAndBehaviors),
            exhaustMap(() => {
                const url = new URL(this.baseUrl + `/olytics/sync?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'Successfully synced environments.');
                            return CdpOlyticsActions.SyncDomainsAndBehaviorsSuccess({payload: res});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to sync environments.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SyncDomainsAndBehaviorsError(error));
                        })
                    );
            })
        )
    );

    uploadDomainsAndBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.UploadDomainsAndBehaviors),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/upload?environmentId=${this.currentDatabaseId}`);
                const formData = new FormData();
                formData.append('dataFile', action.payload?.file);
                return this.http
                    .post(url.toString(), formData, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'File Uploaded Successfully.');
                            this.modalService.closeModal(action.payload?.uploadModalId);
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsBehaviors({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsDomains({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.UploadDomainsAndBehaviorsSuccess({payload: res});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload domain(s) and behavior(s).';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.UploadDomainsAndBehaviorsError(error));
                        })
                    );
            })
        )
    );

    getOlyticsTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetOlyticsTags),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/tag?environmentId=${this.currentDatabaseId}`);
                action.payload?.offset ? url.searchParams.append('offset', action.payload?.offset?.toString()) : null;
                action.payload?.numResults ? url.searchParams.append('numResults', action.payload?.numResults?.toString()) : null;
                action.payload?.sortBy ? url.searchParams.append('sortBy', action.payload?.sortBy) : null;
                action.payload?.order ? url.searchParams.append('order', action.payload?.order) : null;
                action.payload?.name ? url.searchParams.append('name', action.payload?.name) : null;
                action.payload?.createdData ? url.searchParams.append('createdDate', action.payload?.createdData) : null;

                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsTagsResponse) => {
                            const payload: ResponseWrapper<OlyticsTagsResponse> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetOlyticsTagsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load olytics tags.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetOlyticsTagsError(error));
                        })
                    );
            })
        )
    );

    syncOlyticsTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SyncOlyticsTags),
            exhaustMap(() => {
                const url = new URL(this.baseUrl + `/olytics/tag/sync?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'Successfully synced environments.');
                            return CdpOlyticsActions.SyncOlyticsTagsSuccess({payload: res});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to sync olytics tags.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SyncOlyticsTagsError(error));
                        })
                    );
            })
        )
    );

    saveOlyticsTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveOlyticsTag),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/tag/save?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetOlyticsTags({payload: {offset: 0, numResults: 15, sortBy: 'name'}}));
                            return CdpOlyticsActions.SaveOlyticsTagSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save olytics tag.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveOlyticsTagError(error));
                        })
                    );
            })
        )
    );

    getOlyticsContentBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetContentRecommendationBehaviors),
            exhaustMap(() => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/behavior?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<OlyticsBehavior>) => {
                            if (!res?.length && this.router.url.includes('content-recommendations')) {
                                this.router.navigate(['customer-data-platform/main/dashboard'], { relativeTo: this.route });
                            }

                            const payload: ResponseWrapper<Array<OlyticsBehavior>> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetContentRecommendationBehaviorsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get olytics behaviors.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetContentRecommendationBehaviorsError(error));
                        })
                    );
            })
        )
    );

    getPageTypeExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetBehaviorPageTypeExclusions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/page-type-exclusion?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('behaviorId', action.payload?.behaviorId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: PageTypeExclusionsResponse) => {
                            const payload: ResponseWrapper<PageTypeExclusionsResponse> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetBehaviorPageTypeExclusionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get page type exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetBehaviorPageTypeExclusionsError(error));
                        })
                    );
            })
        )
    );

    savePageTypeExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveBehaviorPageTypeExlusions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/page-type-exclusion?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetBehaviorPageTypeExclusions({payload: {behaviorId: action.payload?.behaviorId}}));
                            return CdpOlyticsActions.SaveBehaviorPageTypeExlusionsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save page type exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveBehaviorPageTypeExlusionsError(error));
                        })
                    );
            })
        )
    );

    getSelectedSpecificExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetSelectedSpecificExclusions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/specific-page-exclusion/selected?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('behaviorId', action.payload?.behaviorId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: {totalResultCount: number, items: Array<{code: number, name: string}>}) => {
                            const payload: ResponseWrapper<{totalResultCount: number, items: Array<{code: number, name: string}>}> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetSelectedSpecificExclusionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get selected specific page exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetSelectedSpecificExclusionsError(error));
                        })
                    );
            })
        )
    );

    getAvailableSpecificExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetAvailableSpecificExclusions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/specific-page-exclusion/available?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('behaviorId', action.payload?.behaviorId?.toString());
                url.searchParams.append('searchText', action.payload?.searchText?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: {totalResultCount: number, items: Array<{code: number, name: string}>}) => {
                            const payload: ResponseWrapper<{totalResultCount: number, items: Array<{code: number, name: string}>}> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetAvailableSpecificExclusionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get available specific page exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetAvailableSpecificExclusionsError(error));
                        })
                    );
            })
        )
    );

    saveSpecificExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveSpecificExclusions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/specific-page-exclusion?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetSelectedSpecificExclusions({payload: {behaviorId: action.payload?.behaviorId}}));
                            this.store.dispatch(CdpOlyticsActions.GetAvailableSpecificExclusions({payload: {behaviorId: action.payload?.behaviorId}}));

                            return CdpOlyticsActions.SaveSpecificExclusionsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save specific page exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveSpecificExclusionsError(error));
                        })
                    );
            })
        )
    );

    getUrlStringRestrictions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetUrlStringRestrictions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/url-string-restriction?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('behaviorId', action.payload?.behaviorId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<string>) => {
                            const payload: ResponseWrapper<Array<string>> = {
                                loading: false,
                                data: res
                            };
                            return CdpOlyticsActions.GetUrlStringRestrictionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get url string page exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetUrlStringRestrictionsError(error));
                        })
                    );
            })
        )
    );

    saveUrlStringExclusions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveUrlStringRestrictions),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/url-string-restriction?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetUrlStringRestrictions({payload: {behaviorId: action.payload?.behaviorId}}));
                            return CdpOlyticsActions.SaveUrlStringRestrictionsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save url string page exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveUrlStringRestrictionsError(error));
                        })
                    );
            })
        )
    );

    getTitleSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.GetTitleSource),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/title-source?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('behaviorId', action.payload?.behaviorId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),                       
                        map((res: OlyticsTitleSourceResponse) => {
                            const payload: ResponseWrapper<OlyticsTitleSourceResponse> = {
                                loading: false,
                                data: res
                            };             
                            return CdpOlyticsActions.GetTitleSourceSuccess({payload});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get title source.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.GetTitleSourceError(error));
                        })
                    );
            })
        )
    );

    saveTitleSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpOlyticsActions.SaveTitleSource),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/olytics/content-recommendation/title-source?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpOlyticsActions.GetTitleSource({payload: {behaviorId: action.payload?.behaviorId}}));
                            return CdpOlyticsActions.SaveTitleSourceSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save title source.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpOlyticsActions.SaveTitleSourceError(error));
                        })
                    );
            })
        )
    );
    
    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): number {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute;
        }
        return this.currentDatabase?.id;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
