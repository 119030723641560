import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ChangeMainPath, getCurrentUser, ModalService, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, mergeMap, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetDeploymentDefaults, GetDeploymentDefaultsSuccess, GetDeploymentDefaultsError, GetContactDetails, GetContactDetailsSuccess, GetContactDetailsError, UpdateContactDetails, UpdateContactDetailsSuccess, UpdateContactDetailsError, GetFinalApproverList, GetFinalApproverListSuccess, GetFinalApproverListError, DomainAndEmailSuppressionTestFile, DomainAndEmailSuppressionTestFileSuccess, DomainAndEmailSuppressionTestFileError, GetPreferencePageData, GetPreferencePageDataSuccess, GetPreferencePageDataError, SavePreferencePageData, SavePreferencePageDataSuccess, SavePreferencePageDataError, UploadSeedFromFile, UploadSeedFromFileSuccess, UploadSeedFromFileError, DeleteContact, DeleteContactSuccess, DeleteContactError, AddRecipientDeploymentDefaults, AddRecipientDeploymentDefaultsSuccess, AddRecipientDeploymentDefaultsError, GetContactList, GetContactListSuccess, GetContactListError, DeleteRecipients, DeleteRecipientsSuccess, DeleteRecipientsError, UpdateDeploymentDefaults, UpdateDeploymentDefaultsSuccess, UpdateDeploymentDefaultsError, GetSingleResponseDemographics, GetSingleResponseDemographicsSuccess, GetSingleResponseDemographicsError, GetPromocodeProduct, GetPromocodeProductSuccess, GetPromocodeProductError, GetDynamicDemographics, GetDynamicDemographicsSuccess, GetDynamicDemographicsError, GetDynamicBehaviors, GetDynamicBehaviorsSuccess, GetDynamicBehaviorsError } from './deployment-defaults.actions';
import { ApproverItem, BehaviorOption, ContactDetails, ContactItem, DemographicsOption, PanelData, PreferencePage, PromocodeOption, TestFileResponse } from './deployment-defaults.interfaces';

@Injectable({ providedIn: 'root' })
export class EmailBuilderDeploymentDefaultsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) { }

    getDeploymentDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDeploymentDefaults),
            mergeMap((action) => {
                let url = `?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.deploymentTypeId}` : '';
                return this.http
                    .get(this.baseUrl + url, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: PanelData) => {
                            return GetDeploymentDefaultsSuccess({ payload: { data: res, sourceKey: action?.payload?.sourceKey } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load deployment defaults.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDeploymentDefaultsError({ payload: { error: error, sourceKey: action?.payload?.sourceKey } }));
                        })
                    );
            })
        )
    );

    updateDeploymentDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateDeploymentDefaults),
            mergeMap((action) => {
                let url = `?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&panel=${action?.payload?.panel}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.deploymentTypeId}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Deployment defaults saved successfully!');
                            }
                            this.store.dispatch(GetDeploymentDefaults({ payload: { orgId: action?.payload?.orgId, databaseId: action?.payload?.databaseId, deploymentTypeId: action?.payload?.deploymentTypeId, sourceKey: action?.payload?.sourceKey } }));
                            return UpdateDeploymentDefaultsSuccess({ payload: { data: res, sourceKey: action?.payload?.sourceKey } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update deployment defaults.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(UpdateDeploymentDefaultsError({ payload: { error: error, sourceKey: action?.payload?.sourceKey } }));
                        })
                    );
            })
        )
    );

    getContactList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetContactList),
            mergeMap((action) => {
                return this.http
                    .get(this.baseUrl + `/contact-list?orgId=${action?.payload?.orgId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ContactItem>) => {
                            return GetContactListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get contact list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetContactListError(error));
                        })
                    );
            })
        )
    );

    getContactDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetContactDetails),
            exhaustMap((action) => {
                return this.http
                    .get(this.baseUrl + `/contact-details?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&contactId=${action?.payload?.contactId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ContactDetails) => {
                            return GetContactDetailsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load contact details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetContactDetailsError(error));
                        })
                    );
            })
        )
    );

    updateContactDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateContactDetails),
            exhaustMap((action) => {
                let url = `/save-contact?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&mode=${action?.payload?.mode}`;
                url += action?.payload?.contactId ? `&contactId=${action?.payload?.contactId}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Contact details updated successfully.');
                            }
                            this.store.dispatch(GetContactList({ payload: { orgId: action?.payload?.orgId } }));
                            return UpdateContactDetailsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update contact details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(UpdateContactDetailsError(error));
                        })
                    );
            })
        )
    );

    deleteContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteContact),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `/delete-contact?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&contactId=${action?.payload?.contactId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Contact deleted successfully.');
                            }
                            this.store.dispatch(GetContactList({ payload: { orgId: action?.payload?.orgId } }));
                            return DeleteContactSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete contact.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeleteContactError(error));
                        })
                    );
            })
        )
    );

    getFinalApproverList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFinalApproverList),
            exhaustMap((action) => {
                let url = `/final-approvers?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.deploymentTypeId}` : '';
                return this.http
                    .get(this.baseUrl + url, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ApproverItem>) => {
                            return GetFinalApproverListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get final approver list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetFinalApproverListError(error));
                        })
                    );
            })
        )
    );

    domainAndEmailSuppresionTestFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DomainAndEmailSuppressionTestFile),
            mergeMap((action) => {
                let url = `/test-file?environmentId=${this.currentDatabase.id}`;
                url += action?.payload?.queryParams?.databaseId ? `&databaseId=${action?.payload?.queryParams?.databaseId}` : '';
                url += action?.payload?.queryParams?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.queryParams?.deploymentTypeId}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TestFileResponse) => {
                            return DomainAndEmailSuppressionTestFileSuccess({ payload: { data: res, sourceKey: action?.payload?.sourceKey, emailSuppression: action?.payload?.data?.emailSuppression } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to test suppresion file.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DomainAndEmailSuppressionTestFileError({ payload: { error: error, sourceKey: action?.payload?.sourceKey, emailSuppression: action?.payload?.data?.emailSuppression } }));
                        })
                    );
            })
        )
    );

    getPreferencePageData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetPreferencePageData),
            mergeMap((action) => {
                let url = `/preference-page?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.id ? `&id=${action?.payload?.id}` : '';
                url += action?.payload?.prefPageId ? `&prefPageId=${action?.payload?.prefPageId}` : '';
                return this.http
                    .get(this.baseUrl + url, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: PreferencePage) => {
                            return GetPreferencePageDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            if (!action?.payload?.hideMessage) {
                                let errorMessage = 'An error has occurred while trying to get preference page data.';
                                if (error && error.error && error.error.errors && error.error.errors.length) {
                                    errorMessage = error.error.errors[0];
                                }
                                this.showError(null, errorMessage);
                            }
                            return of(GetPreferencePageDataError({ error }));
                        })
                    );
            })
        )
    );

    savePreferencePageData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SavePreferencePageData),
            mergeMap((action) => {
                let url = `/preference-page/save?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.id ? `&id=${action?.payload?.id}` : '';
                url += action?.payload?.prefPageId ? `&prefPageId=${action?.payload?.prefPageId}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { preferencePageId?: number, preferencePageName?: string }) => {
                            this.showSuccess(null, 'Your chagnes have been successfully saved.');
                            if (action?.payload?.close && action.payload.modalId) {
                                this.modalService.closeModal(action.payload.modalId);
                            }
                            this.store.dispatch(GetPreferencePageData({ payload: { orgId: action?.payload?.orgId, databaseId: action?.payload?.databaseId, id: action?.payload?.id, prefPageId: res?.preferencePageId, hideMessage: true } }));
                            return SavePreferencePageDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save preference page data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(SavePreferencePageDataError({ error }));
                        })
                    );
            })
        )
    );

    uploadSeedFromFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadSeedFromFile),
            mergeMap((action) => {
                let url = `/upload-seed?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`;
                url += action?.payload?.panel?.toString() ? `&panel=${action?.payload?.panel}` : '';
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.deploymentTypeId}` : '';
                url += action?.payload?.isTest ? `&isTest=${action?.payload?.isTest}` : '';
                url += action?.payload?.mode ? `&mode=${action?.payload?.mode}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.file, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'File Uploaded Successfully.');
                            }
                            if (action?.payload?.sourceKey) {
                                this.store.dispatch(GetDeploymentDefaults({ payload: { orgId: action?.payload?.orgId, databaseId: action?.payload?.databaseId, deploymentTypeId: action?.payload?.deploymentTypeId, sourceKey: action?.payload?.sourceKey } }));
                            } else if (action?.payload?.mode) {
                                this.store.dispatch(GetContactList({ payload: { orgId: action?.payload?.orgId } }));
                            }
                            return UploadSeedFromFileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload seeds from file.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(UploadSeedFromFileError({ payload: { error: error, sourceKey: action?.payload?.sourceKey, mode: action?.payload?.mode } }));
                        })
                    );
            })
        )
    );

    addRecipientDeploymentDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddRecipientDeploymentDefaults),
            mergeMap((action) => {
                let url = `/add-recipient?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&panel=${action?.payload?.panel}`;
                url += action?.payload?.contactId ? `&contactId=${action?.payload?.contactId}` : '';
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += action?.payload?.deploymentTypeId ? `&deploymentTypeId=${action?.payload?.deploymentTypeId}` : '';
                return this.http
                    .post(this.baseUrl + url, action?.payload?.recipient, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Add recipient successfully.');
                            }
                            return AddRecipientDeploymentDefaultsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to add recipient.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(AddRecipientDeploymentDefaultsError(error));
                        })
                    );
            })
        )
    );

    deleteRecipients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteRecipients),
            mergeMap((action) => {
                const url = `/delete-recipient?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&panel=${action?.payload?.panel}`;
                return this.http
                    .post(this.baseUrl + url, { delRecipientIds: action?.payload?.recipients }, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Deleted recipients successfully.');
                            }
                            return DeleteRecipientsSuccess({ payload: { res: res, sourceKey: action?.payload?.sourceKey } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete recipients.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeleteRecipientsError({ payload: { error: error, sourceKey: action?.payload?.sourceKey } }));
                        })
                    );
            })
        )
    );

    getSingleResponseDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetSingleResponseDemographics),
            mergeMap((action) => {
                const url = new URL(this.baseUrl + `/single-response-demographics?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&databaseId=${action?.payload?.databaseId}`);
                if (action?.payload?.deploymentTypeId) {
                    url.searchParams.append('deploymentTypeId', action?.payload?.deploymentTypeId);
                }
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { availableSingleResponseDemographics: Array<DemographicsOption>, selectedSingleResponseDemographics: Array<DemographicsOption> }) => {
                            return GetSingleResponseDemographicsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Demographics Reports.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetSingleResponseDemographicsError({ payload: error }));
                        })
                    );
            })
        )
    );

    getPromocodeProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetPromocodeProduct),
            mergeMap((action) => {
                const url = new URL(this.baseUrl + `/promo-code-product?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&databaseId=${action?.payload?.databaseId}`);
                if (action?.payload?.deploymentTypeId) {
                    url.searchParams.append('deploymentTypeId', action?.payload?.deploymentTypeId);
                }
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<PromocodeOption>) => {
                            return GetPromocodeProductSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Promocodes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetPromocodeProductError({ payload: error }));
                        })
                    );
            })
        )
    );

    getDynamicDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDynamicDemographics),
            mergeMap((action) => {
                const url = new URL(this.baseUrl + `/dynamic-demographics?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&databaseId=${action?.payload?.databaseId}`);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { availableDynamicDemographics: Array<DemographicsOption>, selectedDynamicDemographics: Array<DemographicsOption> }) => {
                            return GetDynamicDemographicsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Dynamic Demographics.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDynamicDemographicsError({ payload: error }));
                        })
                    );
            })
        )
    );

    getDynamicBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDynamicBehaviors),
            mergeMap((action) => {
                const url = new URL(this.baseUrl + `/dynamic-behaviors?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&databaseId=${action?.payload?.databaseId}`);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { availableDynamicDemographics: Array<BehaviorOption>, selectedDynamicDemographics: Array<BehaviorOption> }) => {
                            return GetDynamicBehaviorsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Dynamic Behaviors.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDynamicBehaviorsError({ payload: error }));
                        })
                    );
            })
        )
    );

    changePath(path, queryParams) {
        if (!path) {
            return;
        }
        this.store.dispatch(
            ChangeMainPath({
                payload: {
                    path: [path],
                    queryParams,
                    extras: null,
                },
            })
        );
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools/deployment-defaults';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
