import { on } from '@ngrx/store';
import * as IntegrationsMetaAdsManagerActions from './meta-ads-manager.actions';
import { IntegrationsState } from '../interfaces';

export const initialMetaAdsManagerState = {
    pastAudiences: {
        loading: false
    },
    metaAccessInfo: null,
    facebookAdAccounts: null,
};

export const IntegrationsMetaAdsManagerReducer = [
    on(IntegrationsMetaAdsManagerActions.SetSelectedAdAccount, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                selectedAdAccount: action.payload
            }
        };
    }),
    on(IntegrationsMetaAdsManagerActions.SetMetaAccessInfo, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                metaAccessInfo: action.payload
            }
        };
    }),
    on(IntegrationsMetaAdsManagerActions.GetFacebookAdAccountsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                facebookAdAccounts: action.payload
            }
        };
    }),
    on(IntegrationsMetaAdsManagerActions.GetPastAudiences, (state: IntegrationsState) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                pastAudiences: {
                    loading: true,
                    data: state.metaAdsManager.pastAudiences?.data
                }
            }
        };
    }),
    on(IntegrationsMetaAdsManagerActions.GetPastAudiencesError, (state: IntegrationsState) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                pastAudiences: {
                    loading: false,
                    data: state.metaAdsManager.pastAudiences?.data
                }
            }
        };
    }),
    on(IntegrationsMetaAdsManagerActions.GetPastAudiencesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaAdsManager: {
                ...state?.metaAdsManager,
                pastAudiences: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
];
