/* eslint-disable no-console */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ConfigurationService, DeploymentType, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { forkJoin, from, of } from 'rxjs';
import { bufferTime, catchError, concatMap, exhaustMap, last, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as AudienceBuilderActions from './actions';
import { AddGroup, AddGroupError, AddGroupSuccess } from './folders/folders.actions';
import { AbBuild, AbLocalStorageKeys, AudienceOverlapReportI, AuditType, ContactRuleLHS, ContactRulesEtags, ContactRulesLHS, CrosshatchReport, CurrentOnQFoldersResponse, CurrentSelectionCriteriaHistoryItem, DemoValues, DemographicList, ExistingDeploymentSearchResult, ExportCsv, FieldCountExportData, FieldCountReportResponse, FieldCountsItem, Folder, GeoReport, IAudienceCounts, InventoryCalendarI, Issue, IssueBuild, IssueBuildCounts, IssueBuilderFolder, IssueListResponse, NewFolderDataRes, NthField, OmailFileOutput, OnqContactRules, OnqFolderGroups, OnqHistoryRes, OnqOutputFoldersResult, OnqSegmentsRes, OutputAvailableFieldsResponse, OutputDeploymentType, OutputLayloutRes, OutputLayout, OutputResponse, PaidProductSchema, PreviewIssueBuildRes, ProductMembershipTypes, QueriesSearchResults, Query, QueryBitsets, QueryGroup, QueryXmlRes, SaveRuleData, SavedReportCriteria, SelectedInventoryCalendarDay, SelectedOnqFolders, SelectionCriteria, SelectionCriteriaDetailsRes, SessionContactRuleGroup, Unit, UploadExternalListResponse, modes } from './interfaces';
import { getContactRulesLHS, getCurrentFolders } from './selectors';

@Injectable({ providedIn: 'root' })
export class AudienceBuilderEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private casHttp: CasHttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService,
        private configurationService: ConfigurationService,
        private route: ActivatedRoute
    ) { }

    searchQueries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SearchQueries),
            switchMap((action) => {
                //TODO Refactor params
                let url = environment.apiEndPoint + `/audience-builder/selection-criteria/search?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`;
                url += action.payload.profileId ? `&profileId=${action.payload.profileId}` : '';
                url += `&createdBy=${action.payload.createdBy}`;
                url += `&keyword=${action.payload.keyword}`;
                url += `&lastUpdated=${action.payload.lastUpdated}`;
                url += `&lockedQueriesOnly=${action.payload.lockedQueriesOnly}`;
                url += `&name=${action.payload.name}`;
                url += `&nameSearchType=${action.payload.nameSearchType}`;
                url += `&numResults=${action.payload.numResults}`;
                url += `&olyticsQueriesOnly=${action.payload.olyticsQueriesOnly}`;
                //TODO Fix interface defaults
                if (action.payload.showAllQueries) {
                    url += `&showAllQueries=${action.payload.showAllQueries}`;
                }
                url += `&offset=${action.payload.offset}`;
                url += `&sortBy=${action.payload.sortBy}`;
                url += `&order=${action.payload.order}`;
                url += action.payload.mode ? `&mode=${action.payload.mode}`: '';
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: QueriesSearchResults) => {
                        const payload: ResponseWrapper<QueriesSearchResults> = { loading: false, data: res };
                        return AudienceBuilderActions.SearchQueriesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SearchQueriesError(error));
                    })
                );
            })
        )
    );

    // searchQueries$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SearchQueries),
    //         exhaustMap((action) => {
    //             let params = new HttpParams()
    //                 .set('environmentId', this.currentDatabase.id)
    //                 .set('dataViewId', this.currentUser.currentProfile.dataViewId);

    //             Object.entries(action.payload).forEach(([key, value]) => {
    //                 if (value !== undefined) {
    //                     params = params.set(key, value);
    //                 }
    //             });

    //             const url = new URL(environment.apiEndPoint + '/audience-builder/selection-criteria/search');
    //             url.search = params.toString();

    //             return this.http.get(url.toString(), { withCredentials: true }).pipe(
    //                 take(1),
    //                 map((res: any) => {
    //                     const payload: ResponseWrapper<Array<Query>> = { loading: false, data: res };
    //                     return SearchQueriesSuccess({ payload: payload });
    //                 }),
    //                 catchError(error => {
    //                     this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
    //                     return of(SearchQueriesError(error));
    //                 })
    //             );
    //         })
    //     )
    // );

    searchOutputLayouts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SearchOutputLayouts),
            exhaustMap((action) => {
                //TODO check which one is correct
                // let url = environment.apiEndPoint + `/output/output-criteria/list?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}&mode=search`;
                // url += action.payload.profileId ? `&profileId=${action.payload.profileId}` : '';
                let url = environment.apiEndPoint + `/audience-builder/output/output-criteria/list?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}&mode=search`;
                url += action.payload.profileId ? `&profileId=${action.payload.profileId}`: '';
                url += `&createdBy=${action.payload.createdBy}`;
                url += `&lastUpdated=${action.payload.lastUpdated}`;
                url += `&name=${action.payload.name}`;
                url += `&nameSearchType=${action.payload.nameSearchType}`;
                url += `&numResults=${action.payload.numResults}`;
                url += `&resultsType=${action.payload.resultsType}`;
                url += action.payload.status ? `&status=${action.payload.status}` : '';
                url += `&offset=${action.payload.offset}`;
                if(action.payload.pageNum){
                    url += `&pageNum=${action.payload.pageNum}`;
                }
                url += `&sortBy=${action.payload.sortBy}`;
                url += `&order=${action.payload.order}`;
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OutputLayloutRes) => {
                        const payload: ResponseWrapper<OutputLayloutRes> = { loading: false, data: res };
                        return AudienceBuilderActions.SearchOutputLayoutsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for output layouts.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SearchOutputLayoutsError(error));
                    })
                );
            })
        )
    );

    // openQuery$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(AudienceBuilderActions.OpenQuery),
    //         exhaustMap((action) => {
    //             return this.http.get(environment.apiEndPoint + `/audience-builder/query/open?id=${action.payload}&environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
    //                 take(1),
    //                 map((res: Query) => {
    //                     const payload: ResponseWrapper<Query> = { loading: false, data: res };
    //                     return AudienceBuilderActions.OpenQuerySuccess({ payload: payload });
    //                 }),
    //                 catchError(error => {
    //                     this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
    //                     return of(AudienceBuilderActions.OpenQueryError(error));
    //                 })
    //             );
    //         })
    //     )
    // )

    saveQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveQuery),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/save?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    //TODO will not update this any type for now, since it's a pretty major change
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    map((res: any) => {
                        const payload: ResponseWrapper<Query> = { loading: false, data: res };
                        if (res.errors && res.errors.length > 0) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: res.errors.join('\n') } }));
                        }
                        if (action?.modal != null) {
                            const modal = this.modalService.getModalRef(action.modal);
                            if (action.payload.type === 0 && res.existing) {
                                modal.instance.data.isExisting = true;
                            }
                        }
                        if (res.success) {
                            localStorage.removeItem(AbLocalStorageKeys.PREVIOUS_COUNTS);
                            localStorage.setItem('previousStateIdentifier', 'Saved');
                            if (res.selectionCriteriaId) {
                                this.router.navigate(['/audience-builder/main/query'], { queryParams: { id: res.selectionCriteriaId }});
                                this.store.dispatch(AudienceBuilderActions.GetSelectionCriteria({ payload: { selectionCriteriaId: res.selectionCriteriaId } }));
                            }
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res.messages.join('\n') } }));
                            if (!action?.ignoreClose) {
                                this.modalService.closeModal(action.modal);
                            }
                            if (action.payload?.criteriaBased) {
                                this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                            }
                            this.store.dispatch(AudienceBuilderActions.ClearInventoryCalendar());
                        }
                        return AudienceBuilderActions.SaveQuerySuccess({ payload: payload });
                    }),
                    catchError(error => {
                        const errorMessage = error?.error?.errors?.length ? error.error.errors[0] : 'An error has occurred while trying to save query.';
                        if (errorMessage?.includes('There is an existing query with that name.')) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: errorMessage } }));
                        } else {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: errorMessage, icon: 'fa-circle-exclamation-solid' } }));
                        }
                        return of(AudienceBuilderActions.SaveQueryError());
                    })
                );
            })
        )
    );

    getExistingDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetExistingDeployments),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/email-builder/deployments/regular?environmentId=${this.currentDatabase.id}`, action.payload , { withCredentials: true }).pipe(
                    take(1),
                    map((res: ExistingDeploymentSearchResult) => {
                        const payload: ResponseWrapper<ExistingDeploymentSearchResult> = { loading: false, data: res };
                        return AudienceBuilderActions.GetExistingDeploymentsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get existing deployments.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetExistingDeploymentsError(error));
                    })
                );
            })
        )
    );

    getCurrentOnQFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetCurrentOnQFolders),
            withLatestFrom(this.store.select(getCurrentFolders)),
            exhaustMap(([_, oldPayload]) => {
                const headers = new HttpHeaders({'If-None-Match': oldPayload.etag != null ? oldPayload.etag : '' });
                return this.http.get(environment.apiEndPoint + `/audience-builder/library?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true, observe: 'response', headers }).pipe(
                    take(1),
                    map((res: HttpResponse<CurrentOnQFoldersResponse>) => {
                        const payload: ResponseWrapper<CurrentOnQFoldersResponse> = { loading: false, data: res.body, etag: res.headers.get('Etag') ? res.headers.get('Etag') : oldPayload.etag };
                        if (localStorage.getItem(AbLocalStorageKeys.ISSUE_BUILD_ID)) {
                            this.store.dispatch(AudienceBuilderActions.GetIssueBuilderFolders({ payload : { buildId: localStorage.getItem('issueBuildId') }}));
                        }
                        return AudienceBuilderActions.GetCurrentOnQFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (error?.status !== 304) {
                            if (typeof error?.error === 'string') {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get field library.', icon: 'fa-circle-exclamation-solid' } }));
                            } else if (!error?.error?.errors[0]?.includes('No Folder Library exists')) {
                                if (error?.error?.errors[0]?.includes('Please verify you are submitting the correct value')) {
                                    this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: 'The Database selected is currently unavailable for Audience Builder', icon: 'fa-circle-exclamation-solid' } }));
                                } else {
                                    this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors?.[0] ? error?.error?.errors[0] : 'An error has occurred while trying to get field library.', icon: 'fa-circle-exclamation-solid' } }));
                                }
                            }
                            return of(AudienceBuilderActions.GetCurrentOnQFoldersError(error));
                        } else {
                            return of(AudienceBuilderActions.GetCurrentOnQFoldersSuccess({ payload: oldPayload }));
                        }
                    })
                );
            })
        )
    );

    getAvailableOnQFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetAvailableOnQFolders),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                action.payload.folderGroupId ? searchParams.set('folderGroupId', action.payload.folderGroupId.toString()) : '';
                action.payload.searchCriteria ? searchParams.set('searchCriteria', action.payload.searchCriteria) : '';
                action.payload.mode ? searchParams.set('mode', action.payload.mode) : '';
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/available?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<Folder>) => {
                        const payload: ResponseWrapper<Array<Folder>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetAvailableOnQFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get available folders.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetAvailableOnQFoldersError(error));
                    })
                );
            })
        )
    );

    getSelectedOnQFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectedOnqFolders),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                action.payload.mode ? searchParams.set('mode', action.payload.mode) : '';
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/selected?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedOnqFolders) => {
                        const payload: ResponseWrapper<SelectedOnqFolders> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSelectedOnqFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (error?.error?.errors?.[0] !== 'There is currently no Field Library setup for this brand.') {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occurred while trying to get selected folders.', icon: 'fa-circle-exclamation-solid' } }));
                        }
                        return of(AudienceBuilderActions.GetSelectedOnqFoldersError(error));
                    })
                );
            })
        )
    );

    getFolderContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetFolderContent),
            bufferTime(300, null, 7),
            concatMap(actionGroup => from(actionGroup).pipe(
                mergeMap((action) => {
                    const folderId = action.payload?.folder?.onqFolderId;
                    const dataViewId = this.currentUser?.currentProfile?.dataViewId;
                    const environmentId = this.currentDatabase.id;
                    let query = `?folderId=${folderId}&groupId=${action.payload?.activeGroup || 0}&environmentId=${environmentId}&dataViewId=${dataViewId}`;
                    if (action.payload?.folder?.folderValueId) {
                        query += `&folderValueId=${action.payload?.folder?.folderValueId}`;
                    }
                    if (action.payload?.behaviorProductFilterText) {
                        query += `&behaviorProductFilterText=${action.payload?.behaviorProductFilterText}`;
                    }
                    if (action.payload?.behaviorFilterText) {
                        query += `&behaviorFilterText=${action.payload?.behaviorFilterText}`;
                    }
                    if(action.payload?.allFields){
                        query +=`&allFields=${action.payload?.allFields}` ;
                    }

                    return this.http.get(environment.apiEndPoint + '/audience-builder/library/folder' + query, { withCredentials: true }).pipe(
                        take(1),
                        map((res: Folder) => {
                            const folder: ResponseWrapper<Folder> = { loading: false, data: res };
                            return AudienceBuilderActions.GetFolderContentSuccess({ payload: {
                                folder,
                                folderValueId: action.payload.folder?.folderValueId,
                                activeGroup:  action.payload?.activeGroup,
                                columnValue: action.payload.folder?.columnValue,
                                behaviorProductFilterText: action.payload?.behaviorProductFilterText || null,
                                behaviorFilterText: action.payload?.behaviorFilterText || null
                            } });
                        }),
                        catchError(error => {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors?.[0] ? error?.error?.errors[0] : 'An error has occurred while trying to get output criteria fields.', icon: 'fa-circle-exclamation-solid' } }));
                            return of(AudienceBuilderActions.GetFolderContentError({ error, folderId, activeGroup: action.payload?.activeGroup }));
                        })
                    );
                })
            ))
        )
    );

    getOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOutputData),
            exhaustMap((action) => {
                const url = new URL(`${environment.apiEndPoint}/audience-builder/output/load`);

                Object.entries({
                    environmentId: this.currentDatabase.id,
                    dataViewId: this.currentUser?.currentProfile?.dataViewId,
                    ...action.payload
                })
                    .forEach(([key, value]) => {
                        if(value !== undefined && value !== null) {
                            url.searchParams.append(key, value?.toString());
                        }
                    }
                    );

                return this.http.get(url.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: OmailFileOutput) => {
                        const response: ResponseWrapper<OmailFileOutput> = { loading: false, data: res};
                        return AudienceBuilderActions.GetOutputDataSuccess({ payload: response });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load output data.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOutputDataError(error));
                    })
                );
            })
        )
    );

    getOutputCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOutputCriteria),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/output/output-criteria?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&standardOutputId=${action.payload.standardOutputId}&mode=${action.payload.mode}&outputCriteriaId=${action.payload.outputCriteriaId}&isOutputStandalone=${action.payload.isOutputStandalone}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OmailFileOutput) => {
                        const response: ResponseWrapper<OmailFileOutput> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOutputCriteriaSuccess({ payload: response });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load output data.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOutputDataError(error));
                    })
                );
            })
        )
    );

    createOutputWithoutQueue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CreateOutputWithoutQueue),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap((database) => {
                        return this.http.post(environment.apiEndPoint + `/audience-builder/output/output-criteria?environmentId=${database.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&mode=saveOutputCriteria`, action.payload, { withCredentials: true }).pipe(
                            take(1),
                            map((res: OutputResponse) => {
                                const payload: ResponseWrapper<OutputResponse> = { loading: false, data: res };
                                return AudienceBuilderActions.CreateOutputWithoutQueueSuccess({ payload });
                            }),
                            catchError(error => {
                                this.store.dispatch(ShowToast({ payload: { title: '', design: 'Error', placement: 'TopEnd', message: error.error.errors[0], icon: 'fa-circle-exclamation-solid' } }));
                                error['actionPayload'] = action.payload;
                                return of(AudienceBuilderActions.CreateOutputWithoutQueueError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    // createSegmentOutput$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(AudienceBuilderActions.CreateSegmentOutput),
    //         exhaustMap((action) => {
    //             return this.http.post(environment.apiEndPoint + `/audience-builder/output/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
    //                 take(1),
    //                 map((res) => {
    //                     //TODO create, update, delete in same call, toast message can be specific
    //                     this.store.dispatch(ShowToast({ payload: { title: '', design: '', placement: 'TopEnd', message: 'Output has been queued.' } }));
    //                     return AudienceBuilderActions.CreateSegmentOutputSuccess({payload: res});
    //                 }),
    //                 catchError((error) => {
    //                     this.store.dispatch(ShowToast({ payload: {
    //                         title: 'Error',
    //                         design: 'Error',
    //                         placement: 'TopEnd',
    //                         message: error?.error?.errors[0] || 'An error has occurred while trying to create segment output.',
    //                         icon: 'fa-circle-exclamation-solid' } }));
    //                     return of(AudienceBuilderActions.CreateSegmentOutputError(error));
    //                 })
    //             );
    //         })
    //     )
    // )

    getGeoReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetGeoReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/geo-heat-map/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&geoReportType=${action.payload.geoReportType}`,null,{ withCredentials: true }).pipe(
                    take(1),
                    map((res: GeoReport) => {
                        const payload: ResponseWrapper<GeoReport> = { loading: false, data: res };
                        return AudienceBuilderActions.GetGeoReportSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get geo report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetGeoReportError(error));
                    })
                );
            })
        )
    );

    createProfileReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CreateProfileReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/profile?environmentId=${this.currentDatabase.id}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        return AudienceBuilderActions.CreateProfileReportSuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to create profilel report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.CreateProfileReportError(error));
                    })
                );
            })
        )
    );

    // MATCH EVALUATION
    getMatchEvaluationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetMatchEvaluationData),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/match-evaluation?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&mode=${action.payload.mode}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: { mode?: string; textFieldList: { code: number; name: string }[] }) => {
                        const response: ResponseWrapper<{ mode?: string; textFieldList: { code: number; name: string }[] }> = { loading: false, data: res };
                        return AudienceBuilderActions.GetMatchEvaluationDataSuccess({ payload: response });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to load match evaluation data.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetMatchEvaluationDataError(error));
                    })
                );
            })
        )
    );

    uploadMatchEvaluationExternalList = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.UploadExternalList),
            exhaustMap((action) => {
                const url = new URL(environment.apiEndPoint + '/audience-builder/match-evaluation/external');
                const { dataFile, ...rest } = action.payload;
                const formData = new FormData();
                formData.append('dataFile', dataFile, dataFile.name);
                Object.entries({
                    environmentId: this.currentDatabase.id,
                    dataViewId: this.currentUser?.currentProfile?.dataViewId,
                    ...rest
                })
                    .forEach(([key, value]) => {
                        if(value) {
                            url.searchParams.append(key, value.toString());
                        }
                    }
                    );

                return this.http.post(url.toString(), formData, { withCredentials: true }).pipe(
                    take(1),
                    map((res: UploadExternalListResponse) => {
                        return AudienceBuilderActions.UploadExternalListSuccess({payload: res});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload:
                            {title: 'Error',
                                design: 'Error',
                                placement: 'TopEnd',
                                message: 'An error has occured while trying to upload external list.',
                                icon: 'fa-circle-exclamation-solid'
                            }}));
                        return of(AudienceBuilderActions.UploadExternalListError(error));
                    })
                );
            })
        )
    );

    addSelectionToQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.AddSelectionToQuery),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/match-evaluation?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        this.store.dispatch(AudienceBuilderActions.GetSelectionCriteria({ payload: { dataViewId: this.currentUser?.currentProfile?.dataViewId, selectionCriteriaId: parseInt(action?.payload?.selectionCriteriaId), mode: 'sessionQuery' }}));
                        return AudienceBuilderActions.AddSelectionToQuerySuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occurred while trying to add internal selection.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.AddSelectionToQueryError(error));
                    })
                );
            })
        )
    );

    refreshCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RefreshCounts),
            exhaustMap((action) => {
                const dataViewId = action.payload.dataViewId || this.currentUser.currentProfile.dataViewId;
                const environmentId = action.payload.environmentId || this.currentDatabase.id;
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/refresh-counts?environmentId=${environmentId}&dataViewId=${dataViewId}`, { refreshOnQSelectionCounts: action.payload.refreshOnQSelectionCounts }, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        //TODO temp disable check until counts issues are resolved
                        // const isUserOnSameEnv = action.payload.environmentId == this.currentDatabase.id;
                        // const isUserOnSameDataView = action.payload.dataViewId == this.currentUser?.currentProfile?.dataViewId;
                        // if (isUserOnSameEnv || isUserOnSameDataView) {
                        if(!action.payload.skipSelectionCriteriaReload) {
                            this.store.dispatch(AudienceBuilderActions.ReloadCurrentSelectionCriteria());
                        }
                        this.store.dispatch(AudienceBuilderActions.GetAudienceCounts());
                        // }
                        if (!action.payload?.hideToast) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Successfully refreshed counts' } }));
                        }
                        return AudienceBuilderActions.RefreshCountsSuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to refresh counts.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.RefreshCountsError(error));
                    })
                );
            })
        )
    );

    reloadCurrentSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ReloadCurrentSelectionCriteria),
            exhaustMap(() => {
                const queryId = this.route.snapshot?.queryParams?.id;
                const params = {
                    selectionCriteriaId: queryId ? parseInt(queryId) : 0,
                    mode: 'sessionQuery',
                    showNthMessage: queryId ? true : false
                };
                const url = new URL(environment.apiEndPoint + '/audience-builder/query');
                const searchParams = new URLSearchParams();
                searchParams.set('selectionCriteriaId', params.selectionCriteriaId.toString());
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('mode', params.mode);
                searchParams.set('showNthMessage', params.showNthMessage.toString());
                return this.http.get(`${url}?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        res = {...res, showNthMessage: params?.showNthMessage || false};
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        this.store.dispatch(AudienceBuilderActions.ShowNthToggle());
                        return AudienceBuilderActions.GetSelectionCriteriaSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors?.[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriaError(error));
                    })
                );
            })
        )
    );

    deleteQueries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.DeleteQueries),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/queries/delete?environmentId=${this.currentDatabase.id}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        return AudienceBuilderActions.DeleteQueriesSuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to delete queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.DeleteQueriesError(error));
                    })
                );
            })
        )
    );

    rebuildAudienceBuilder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RebuildAudienceBuilder),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap(() => {
                        return this.http.post(environment.apiEndPoint + `/audience-builder/admin/rebuild-bitsets?environmentId=${action.payload.database}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, {}, { withCredentials: true }).pipe(
                            take(1),
                            map(() => {
                                this.store.dispatch(AudienceBuilderActions.GetRecentBuilds({ payload: { database: action.payload.database } }));
                                this.store.dispatch(ShowToast({ payload: {
                                    title: 'Success',
                                    design: 'Success',
                                    placement: 'TopEnd',
                                    message: 'Rebuild has been queued. You will be notified by an alert in this window when it is complete.',
                                    icon: 'fa-circle-check'
                                }}));
                                return AudienceBuilderActions.RebuildAudienceBuilderSuccess();
                            }),
                            catchError(error => {
                                return of(AudienceBuilderActions.RebuildAudienceBuilderError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    checkRebuildProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CheckRebuildProgress),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/rebuild-bitsets/check-progress?environmentId=${action.payload.databaseId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        return(AudienceBuilderActions.CheckRebuildProgressSuccess({ payload: res }));
                    }),
                    catchError(error => {
                        //     this.store.dispatch(ShowToast({ payload: {
                        //         title: 'Error',
                        //         design: 'Error',
                        //         placement: 'TopEnd',
                        //         message: 'A Rebuild is currently in progress. Please wait for it to finish before starting another one.',
                        //         icon: 'fa-circle-exclamation-solid'
                        // }}));
                        return of(AudienceBuilderActions.CheckRebuildProgressError(error));
                    })
                );
            })
        )
    );

    getRecentBuilds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetRecentBuilds),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/recent-builds?environmentId=${action.payload.database}`, {withCredentials: true}).pipe(
                    take(1),
                    map((res: AbBuild[]) => {
                        return AudienceBuilderActions.GetRecentBuildsSuccess({payload: res});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'An error has occurred while trying to get output criteria fields.',
                            icon: 'fa-circle-exclamation-solid'
                        } }));
                        return of(AudienceBuilderActions.GetRecentBuildsError(error));
                    })
                );
            })

        )
    );

    changeActiveBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ChangeActiveBuild),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap(() => {
                        return this.http.put(environment.apiEndPoint + `/audience-builder/admin/activate-bitset?environmentId=${action.payload.database}&bitsetMetadataId=${action.payload.bitsetMetadataId}`, { withCredentials: true }).pipe(
                            take(1),
                            map(() => {
                                this.store.dispatch(AudienceBuilderActions.GetRecentBuilds({ payload: { database: action.payload.database } }));
                                return AudienceBuilderActions.ChangeActiveBuildSuccess();
                            }),
                            catchError(error => {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to change active build', icon: 'fa-circle-exclamation-solid' } }));
                                return of(AudienceBuilderActions.ChangeActiveBuildError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    refreshProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RefreshProfile),
            exhaustMap(() => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/refresh-profile?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res) => {
                        return AudienceBuilderActions.RefreshProfileSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to refresh profile', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.RefreshProfileError(error));
                    })
                );
            })
        )
    );

    checkRefreshProfileProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CheckRefreshProfileProgress),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/refresh-profile/check-progress?environmentId=${action.payload.databaseId}&dataViewId=${action.payload.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res) => {
                        return AudienceBuilderActions.CheckRefreshProfileProgressSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'A Refresh is currently in progress. Please wait for it to finish before starting another one.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.CheckRefreshProfileProgressError(error));
                    })
                );
            })
        )
    );

    updateOutputLayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.UpdateOutputLayoutField),
            exhaustMap((action) => {
                let url = environment.apiEndPoint + `/audience-builder/admin/output-criteria/edit?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`;
                url += `&mode=${action.payload.mode}`;
                url += `&outputCriteriaId=${action.payload.outputCriteriaId}`;
                url += action.payload.currentStatus !== null ? `&currentStatus=${action.payload.currentStatus}` : '';
                url += action.payload.name !== null ? `&name=${action.payload.name}` : '';
                return this.http.post(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OutputLayout) => {
                        const payload: ResponseWrapper<OutputLayout> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res.messages.join(',') } }));
                        if (action.payload?.reloadData) {
                            this.store.dispatch(AudienceBuilderActions.SearchOutputLayouts({ payload: action.payload.reloadData }));
                        }
                        return AudienceBuilderActions.UpdateOutputLayoutFieldSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error.errors != null ? error.error.errors.join(',') : 'An error has occurred while trying to upload external list.', icon: 'fa-circle-exclamation-solid' } }));
                        if (action.payload?.reloadData) {
                            this.store.dispatch(AudienceBuilderActions.SearchOutputLayouts({ payload: action.payload.reloadData }));
                        }
                        return of(AudienceBuilderActions.UpdateOutputLayoutFieldError(error));
                    })
                );
            })
        )
    );

    updateSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.UpdateSelectionCriteria),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/selection-criteria/edit?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        res = {...res, name: action.payload.name};
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        if (!action?.hideToast) {
                            if (!res.success) {
                                this.store.dispatch(ShowToast({ payload: { title: res.errors != null ? res.errors.join(',') : 'Error', design: 'Error', placement: 'TopEnd', message: res.messages != null ? res.messages.join(',') : 'An error has occurred while trying to update query.', icon: 'fa-circle-exclamation-solid' } }));
                            } else {
                                this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Successfully updated query!' } }));
                            }
                        }
                        return AudienceBuilderActions.UpdateSelectionCriteriaSuccess({ payload });
                    }),
                    catchError(error => {
                        if (!action?.hideToast) {
                            if (error?.error?.errors?.[0] === 'Save failed: \'name\' must be unique.' || error?.error?.errors?.[0] === 'There is an existing query with that name.') {
                                this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: error.error.errors.join(','), icon: 'fa-circle-exclamation-solid' } }));
                            } else {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error.errors != null ? error.error.errors.join(',') :'An error has occurred while trying to upload external list.', icon: 'fa-circle-exclamation-solid' } }));
                            }
                        }
                        return of(AudienceBuilderActions.UpdateSelectionCriteriaError(error));
                    })
                );
            })
        )
    );

    foldersOutputCSV$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.FoldersOutputCSV),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/library/folders/output?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ExportCsv) => {
                        const blob: Blob = new Blob([res.fileContent], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Folders output to CSV completed successfully!' } }));
                        return AudienceBuilderActions.FoldersOutputCSVSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to output folders to CSV.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.FoldersOutputCSVError(error));
                    })
                );
            })
        )
    );

    getFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetFields),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/report/field-count/fields?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FieldCountsItem[]) => {
                        const paylod: ResponseWrapper<FieldCountsItem[]> = { loading: false, data: res };
                        return AudienceBuilderActions.GetFieldsSuccess({ payload: paylod });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to generate field counts report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetFieldsError(error));
                    })
                );
            })
        )
    );

    fieldCountReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.FieldCountReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/field-count/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FieldCountReportResponse) => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Field counts report generated successfully!' } }));
                        return AudienceBuilderActions.FieldCountReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to generate field counts report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.FieldCountReportError(error));
                    })
                );
            })
        )
    );

    fieldCountExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.FieldCountExport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/field-count/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FieldCountExportData) => {
                        const jsonContentArr = res?.fieldCountReport[action.payload.reportName];
                        const headers = ['code', 'field', 'occurrences'];
                        const exportDisplayHeaders = ['Code', action.payload.reportName, 'Occurrences'];
                        const csvRows = [];
                        csvRows.push(['Field Count', '', ''].join(',')); // Placeholder for Field Count
                        csvRows.push(['Brand: ' + res?.brandName, 'Created: ' + res?.reportDateTime, ''].join(',')); // Metadata row
                        csvRows.push(exportDisplayHeaders.join(',')); // Column headers
                        for (const row of jsonContentArr) {
                            const values = headers.map(header => {
                                const cellValue = row[header];
                                return cellValue === undefined ? '' : `"${cellValue.toString()}"`;
                            });
                            csvRows.push(values.join(','));
                        }
                        const link = document.createElement('a');
                        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvRows.join('\r\n')));
                        link.setAttribute('download', action.payload.reportName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Field counts export generated successfully!' } }));
                        return AudienceBuilderActions.FieldCountExportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to generate field counts export.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.FieldCountExportError(error));
                    })
                );
            })
        )
    );

    createOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CreateOutput),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap((database) => {
                        /**
                         * All outputs from AB should have criteriaType set to 1
                         * criteriaType is a 'hidden' property, backend probably defaults to the right value unless it is set wrong by frontend
                        */
                        //TODO Review output component payloads
                        const payload = {
                            ...action.payload,
                            criteriaType: action?.payload?.criteriaType || 1
                        };
                        return this.http.post(environment.apiEndPoint + `/audience-builder/output/create?environmentId=${database.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, payload, { withCredentials: true }).pipe(
                            take(1),
                            map((res: OutputResponse) => {
                                const payload: ResponseWrapper<OutputResponse> = {loading: false, data: res};
                                this.store.dispatch(ShowToast({ payload: { title: '', design: '', placement: 'TopEnd', message: 'Output has been queued.' } }));
                                return AudienceBuilderActions.CreateOutputSuccess({ payload: payload });
                            }),
                            catchError(error => {
                                if (!error?.error?.errors?.[0]?.includes('exists')) {
                                    if (error?.error && error?.error?.errors) {
                                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error.errors[0], icon: 'fa-circle-exclamation-solid' } }));
                                    } else {
                                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to create output.', icon: 'fa-circle-exclamation-solid' } }));
                                    }
                                }
                                return of(AudienceBuilderActions.CreateOutputError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    updateContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.UpdateContactRules),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap((database) => {
                        return this.http.post(environment.apiEndPoint + `/audience-builder/contact-rules?environmentId=${database.id}`, action.payload, { withCredentials: true }).pipe(
                            take(1),
                            map((res: BasicResponse) => {
                                this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Contact Rules updated successfully!' } }));
                                return AudienceBuilderActions.UpdateContactRulesSuccess({ payload: res });
                            }),
                            catchError(error => {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to update contact rules.', icon: 'fa-circle-exclamation-solid' } }));
                                return of(AudienceBuilderActions.UpdateContactRulesError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    getSessionContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSessionContactRules),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/contact-rules/session?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&groupId=${action.payload.groupId}`, { withCredentials: true }).pipe(
                    take(1),
                    //TODO Check if CR's are always 4 in which case can be typed even more specifically
                    map((res: SessionContactRuleGroup[]) => {
                        return AudienceBuilderActions.GetSessionContactRulesSuccess({ payload: {
                            loading: false,
                            data: res
                        } });
                    }),
                    catchError(error => {
                        return of(AudienceBuilderActions.GetSessionContactRulesError(error));
                    })
                );
            })
        )
    );

    //TODO DEPRECATED: remove this effect once the new one is tested
    quickDeploy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.QuickDeploy),
            exhaustMap((action) => {
                return this.shellStore.currentDatabase$.pipe(
                    take(1),
                    exhaustMap((database) => {
                        return this.http.post(environment.apiEndPoint + `/audience-builder/quick-deploy?environmentId=${database.id}`, action.payload, { withCredentials: true }).pipe(
                            take(1),
                            map((res: BasicResponse) => {
                                this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Quick Deployment created successfully!' } }));
                                return AudienceBuilderActions.QuickDeploySuccess({ payload: res });
                            }),
                            catchError(error => {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to create quick deployment.', icon: 'fa-circle-exclamation-solid' } }));
                                return of(AudienceBuilderActions.QuickDeployError(error));
                            })
                        );
                    })
                );
            })
        )
    );

    newQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.NewQuery),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/query/new?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        localStorage.removeItem(AbLocalStorageKeys.PREVIOUS_COUNTS);
                        const reloadUrl = new URL(`${window.location.origin + window.location.pathname}`);
                        if (this.route.snapshot?.queryParams?.iframe) {
                            const queryParams = { ...this.route.snapshot.queryParams };
                            delete queryParams['id'];
                            Object.entries(queryParams)?.map(([key, value]) => {
                                reloadUrl?.searchParams?.append(key, value);
                            });
                            if (!this.route.snapshot?.queryParams?.shouldNotReload) {
                                reloadUrl?.searchParams?.append('shouldNotReload', 'true');
                            }
                        }
                        const queryParams = { ...this.route.snapshot.queryParams };
                        if (queryParams?.id) {
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: { id: null },
                                queryParamsHandling: 'merge',
                            }).then(() => {
                                if (action.payload?.reload && !this.route.snapshot?.queryParams?.iframe) {
                                    console.log(window.location);
                                    window.location.reload();
                                }
                            });
                        } else {
                            if (action.payload?.reload && !this.route.snapshot?.queryParams?.iframe) {
                                window.location.reload();
                            }
                        }
                        if (action.payload?.reload && !this.route.snapshot?.queryParams?.iframe) {
                            window.location.reload();
                        }

                        this.store.dispatch(AudienceBuilderActions.GetSessionContactRules({payload: {groupId: 0}}));

                        if (action.payload?.isIssueBuilderOn) {
                            this.store.dispatch(AudienceBuilderActions.GetIssueBuilderFolders({payload: {buildId: localStorage.getItem(AbLocalStorageKeys.ISSUE_BUILD_ID)}}));
                            this.store.dispatch(AudienceBuilderActions.ReloadCurrentSelectionCriteria());
                        }
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return AudienceBuilderActions.NewQuerySuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to create new query.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.NewQueryError(error));
                    })
                );
            })
        )
    );

    getBulkContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetBulkContactRules),
            withLatestFrom(this.store.select(getContactRulesLHS)),
            exhaustMap(([_, payload]) => {
                const requests = modes.map((mode, i) => {
                    const headers = new HttpHeaders({'If-None-Match': payload.etag != null && payload.etag[modes[i]] != null ? payload.etag[modes[i]] : '' });
                    const url = environment.apiEndPoint + `/audience-builder/contact-rules?mode=${mode}&environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`;
                    return this.http.get(url, { withCredentials: true, observe: 'response', headers }).pipe(
                        take(1),
                        catchError(error => {
                            // When a contact rule hasn't been modified the API returns a status of 304
                            if (error.status !== 304) {
                                this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: `An error has occurred while getting bulk contact rules for mode: ${mode}.`, icon: 'fa-exclamation-circle-solid' } }));
                            }
                            return of(AudienceBuilderActions.GetBulkContactRulesError(error));
                        })
                    );
                });

                return forkJoin(requests).pipe(
                    map((responses: HttpResponse<ContactRuleLHS[]>[]) => {
                        const rules: ContactRulesLHS = Object.assign({}, ...responses.map((res, i) => ({
                            [modes[i]]: res?.status === 200 ? res.body : payload.data[modes[i]]
                        })));
                        const etag: ContactRulesEtags = Object.assign({}, ...responses.map((res, i) => ({
                            [modes[i]]: res.headers.get('Etag') ? res.headers.get('Etag') : payload.etag[modes[i]]
                        })));
                        const successPayload: ResponseWrapper< ContactRulesLHS, ContactRulesEtags > = {
                            loading: false,
                            etag,
                            data: rules
                        };
                        return AudienceBuilderActions.GetBulkContactRulesSuccess({ payload: successPayload });
                    })
                );
            })
        )
    );

    //Opening a query sets the session to the query with that ID
    openSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.OpenSelectionCriteria),
            concatMap(action =>
                this.http.get(environment.apiEndPoint + `/audience-builder/query?selectionCriteriaId=${action?.payload?.selectionCriteriaId}&mode=${action?.payload?.mode}&environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    tap((openQueryResult: SelectionCriteria) => {
                        //TODO should be pullable from backend
                        if (openQueryResult?.query?.count != null && openQueryResult?.lastRefreshDate) {
                            const count = JSON.stringify({count: openQueryResult.query.count, date: openQueryResult.lastRefreshDate});
                            localStorage.setItem(AbLocalStorageKeys.PREVIOUS_COUNTS, count);
                        }
                        if (openQueryResult?.messageList?.length > 0) {
                            openQueryResult?.messageList?.forEach((message) => {
                                if (message?.length > 0) {
                                    this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: message, icon: 'fa-circle-info-solid' } }));
                                }
                            });
                        }
                    }),
                    map((data: SelectionCriteria) => {
                        this.store.dispatch(AudienceBuilderActions.RefreshCounts({ payload: { refreshOnQSelectionCounts: 'y', hideToast: true }}));
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { id: null, issueBuildId: null },
                            queryParamsHandling: 'merge',
                        });
                        return AudienceBuilderActions.OpenSelectionCriteriaSuccess({ payload: { loading: false, data } });
                    }),
                    catchError(error => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { id: null, issueBuildId: null },
                            queryParamsHandling: 'merge',
                        });
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to open query.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.OpenSelectionCriteriaError(error));
                    }
                    ))
            )
        )
    );

    openExternalDashboardCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.OpenExternalDashboardCriteria),
            exhaustMap(action =>
                this.http.post(environment.apiEndPoint + `/audience-builder/query/external-dashboard?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&compressedBitsetId=${action.payload.compressedBitsetId}&criteriaName=${action.payload.criteriaName}`, { 'filters' : action.payload.filters }, { withCredentials: true }).pipe(     
                    tap((openQueryResult: SelectionCriteria) => {
                        //TODO should be pullable from backend
                        if (openQueryResult?.query?.count != null && openQueryResult?.lastRefreshDate) {
                            const count = JSON.stringify({count: openQueryResult.query.count, date: openQueryResult.lastRefreshDate});
                            localStorage.setItem(AbLocalStorageKeys.PREVIOUS_COUNTS, count);
                        }
                        if (openQueryResult?.messageList?.length > 0) {
                            openQueryResult?.messageList?.forEach((message) => {
                                if (message?.length > 0) {
                                    this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: message, icon: 'fa-circle-info-solid' } }));
                                }
                            });
                        }
                    }),
                    map((data: SelectionCriteria) => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { id: null, issueBuildId: null },
                            queryParamsHandling: 'merge',
                        });
                        return AudienceBuilderActions.OpenSelectionCriteriaSuccess({ payload: { loading: false, data } });
                    }),
                    catchError(error => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { id: null, issueBuildId: null },
                            queryParamsHandling: 'merge',
                        });
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to open query.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.OpenSelectionCriteriaError(error));
                    })
                )
            )
        )
    );

    getSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectionCriteria),
            exhaustMap((action) => {
                let params = action.payload?.mode ? `&mode=${action.payload?.mode}` : '';
                params += action.payload?.dataViewId ? `&dataViewId=${action.payload.dataViewId}` : `&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`;
                return this.http.get(environment.apiEndPoint + `/audience-builder/query?selectionCriteriaId=${action?.payload?.selectionCriteriaId}&environmentId=${this.currentDatabase.id}` + params, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        // When triggering this effect from OpenSelectionCriteria it is assumed that the optional selectionCriteriaId is provided
                        // This case usually happens once after starting a new session
                        if (res?.messageList?.length > 0) {
                            res?.messageList?.forEach((message) => {
                                if (message?.length > 0) {
                                    this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: 'Warning', placement: 'TopEnd', message: message, icon: 'fa-circle-info-solid' } }));
                                }
                            });
                        }
                        if (res?.query?.count && res?.lastRefreshDate) {
                            const count = JSON.stringify({count: res.query.count, date: res.lastRefreshDate});
                            localStorage.setItem(AbLocalStorageKeys.PREVIOUS_COUNTS, count);
                        }
                        if (!res.query && action.payload.selectionCriteriaId !== 0) {
                            return AudienceBuilderActions.OpenSelectionCriteria({payload: {selectionCriteriaId: action.payload.selectionCriteriaId}});
                        } else if (!res.query && action.payload.selectionCriteriaId === 0) {
                            return AudienceBuilderActions.NewQuery({payload: {reload: false}});
                        } else {
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: { id: null },
                                queryParamsHandling: 'merge',
                            });
                            res = {...res, showNthMessage: action.payload?.showNthMessage || false};
                            const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                            this.store.dispatch(AudienceBuilderActions.ShowNthToggle());
                            return AudienceBuilderActions.GetSelectionCriteriaSuccess({ payload: payload });
                        }
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors?.[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriaError(error));
                    })
                );
            })
        )
    );

    saveSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveSelectionCriteria),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query?selectionCriteriaId=${action.payload.selectionCriteriaId}&environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload.selectionCriteria, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.SaveSelectionCriteriaSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.SaveSelectionCriteriaError(error));
                    })
                );
            })
        )
    );

    getQueryUnits$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetQueryUnits),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/portal/units?environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<Unit>) => {
                        const payload: ResponseWrapper<Array<Unit>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetQueryUnitsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (error && error.error && error.error.errors) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        }
                        return of(AudienceBuilderActions.GetQueryUnitsError(error));
                    })
                );
            })
        )
    );

    changeQueryUnitGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ChangeQueryUnitGroup),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/search?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        this.store.dispatch(AudienceBuilderActions.ReloadSelectionCriteria({ payload: {selectionCriteria: res, activeGroup: action?.payload?.groupId} }));
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.ChangeQueryUnitGroupSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors?.join('\n'), icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.ChangeQueryUnitGroupError(error));
                    })
                );
            })
        )
    );

    reloadSelectionCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ReloadSelectionCriteria),
            exhaustMap((action) => {
                this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                this.store.dispatch(AudienceBuilderActions.SetSelectionCriteria({ payload: action.payload }));
                return of(AudienceBuilderActions.ReloadSelectionCriteriaSuccess());
            }),
            catchError(error => {
                return of(AudienceBuilderActions.ReloadSelectionCriteriaError(error));
            })
        )
    );

    getSelectionCriteriaText$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectionCriteriaText),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                action?.payload?.selectionCriteriaId ? searchParams.set('selectionCriteriaId', action?.payload?.selectionCriteriaId?.toString()) : null;
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/text?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: {description: string}) => {
                        const payload: ResponseWrapper<{description: string}> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSelectionCriteriaTextSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriaTextError(error));
                    })
                );
            })
        )
    );

    getSelectionCriteriaHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectionCriteriaHistory),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/history?selectionCriteriaId=${action?.payload?.selectionCriteriaId}&environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: CurrentSelectionCriteriaHistoryItem[]) => {
                        const payload: ResponseWrapper<CurrentSelectionCriteriaHistoryItem[]> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSelectionCriteriaHistorySuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriaHistoryError(error));
                    })
                );
            })
        )
    );
    getSelectionCriterias$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectionCriterias),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/library/folder/selection-criterias?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSelectionCriteriasSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriasError(error));
                    })
                );
            })
        )
    );

    // Groups
    addGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddGroup),
            mergeMap((action) => {
                return this.http
                    .post(`${environment.apiEndPoint}/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { mode: action.payload.mode, groupId: action.payload.groupId}, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: SelectionCriteria) => {
                            const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                            // TODO Decide whether to reload the selection criteria or use the response from the server in the success reducer
                            // this.store.dispatch(GetSelectionCriteria({ payload: { selectionCriteriaId: action.payload.selectionCriteriaId, mode: 'sessionQuery' } }));
                            return AddGroupSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to add a group.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AddGroupError(error));
                        })
                    );
            })
        )
    );

    renameGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RenameGroup),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.RenameGroupSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to rename group.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.RenameGroupError(error));
                    })
                );
            })
        )
    );

    removeGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RemoveQueryGroup),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.RemoveQueryGroupSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to remove group.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.RemoveQueryGroupError(error));
                    })
                );
            })
        )
    );

    cloneGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CloneQueryGroup),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.CloneQueryGroupSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to clone group.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.CloneQueryGroupError(error));
                    })
                );
            })
        )
    );

    moveGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.MoveQueryGroup),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.MoveQueryGroupSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to move group.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.MoveQueryGroupError(error));
                    })
                );
            }
            )
        )
    );

    loadOutputAvailableFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.LoadAvailableOutputField),
            mergeMap((action) => {
                return this.http.put(environment.apiEndPoint + `/audience-builder/output/fields?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OutputAvailableFieldsResponse) => {
                        const payload: { loading: boolean, data: OutputAvailableFieldsResponse, outputGroupName: string} = { loading: false, data: res, outputGroupName: action.payload.outputGroupName };
                        return AudienceBuilderActions.LoadAvailableOutputFieldSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        const payload: { error: BasicResponse, outputGroupName: string} = { error: error, outputGroupName: action.payload.outputGroupName};
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load available output fields.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.LoadAvailableOutputFieldError({ payload: payload}));
                    })
                );
            })
        )
    );

    getDemoValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetDemoValues),
            exhaustMap((action) => {
                return this.casHttp.get(environment.settingsApiEndPoint, environment.settingsApiEndPoint + `/demographic/${action.payload.demographicType}?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: DemoValues) => {
                        const payload: ResponseWrapper<DemoValues> = { loading: false, data: res };
                        return AudienceBuilderActions.GetDemoValuesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetDemoValuesError(error));
                    })
                );
            })
        )
    );

    autoConfigureOnq$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.AutoConfigureOnq),
            exhaustMap((action) => {
                let url = environment.apiEndPoint + `/audience-builder/admin/library/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`;
                url += action.payload.mode !== null ? `&mode=${action.payload.mode}` : '';
                return this.http.put(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedOnqFolders) => {
                        const payload: ResponseWrapper<SelectedOnqFolders> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res.messages.join(',') } }));
                        this.store.dispatch(AudienceBuilderActions.RebuildAudienceBuilder({ payload: { database: this.currentDatabase.id } }));
                        this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                        if (action.payload.getSelectedFolders) {
                            this.store.dispatch(AudienceBuilderActions.GetSelectedOnqFolders({ payload: { mode: null }}));
                        }
                        return AudienceBuilderActions.AutoConfigureOnqSuccess({ payload });
                    }),
                    catchError(error => {
                        if (error.error.errors != null) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error.errors != null ? error.error.errors.join(',') : 'An error has occurred while trying to upload external list.', icon: 'fa-circle-exclamation-solid' } }));
                        }
                        this.store.dispatch(AudienceBuilderActions.RebuildAudienceBuilder({payload: { database: this.currentDatabase.id}}));
                        this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                        return of(AudienceBuilderActions.AutoConfigureOnqError(error));
                    })
                );
            })
        )
    );

    audienceOverlapReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.AudienceOverlapReport),
            switchMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/audience-overlap/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AudienceOverlapReportI) => {
                        const payload: ResponseWrapper<AudienceOverlapReportI> = { loading: false, data: res };
                        return AudienceBuilderActions.AudienceOverlapReportSuccess({ payload: payload});
                    }),
                    catchError(error => {
                        if (action.payload.selectedQueries.length > 0) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying get Audience Overlap Report.', icon: 'fa-circle-exclamation-solid' } }));
                        }
                        return of(AudienceBuilderActions.AudienceOverlapReportError(error));
                    })
                );
            })
        )
    );

    testFtpLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.TestFtpLocation),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + '/portal/ftp/test', action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        if (res.errors && res.errors.length > 0) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: res.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        }
                        if (res.success) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res.messages.join(',') } }));
                        }
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return AudienceBuilderActions.TestFtpLocationSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.TestFtpLocationError(error));
                    })
                );
            })
        )
    );
    getGroupDeploymentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetGroupDeploymentTypes),
            exhaustMap((action) => {
                const params = action.payload?.databaseId ? `?environmentId=${action?.payload?.databaseId}` : `?showAll=${action?.payload?.showAll}`;
                return this.http.get(this.configurationService.environment.apiEndPoint + '/email-builder/deployment-types' + params, { withCredentials: true }).pipe(
                    map((res: Array<DeploymentType>) => {
                        const payload: { deploymentTypes: Array<DeploymentType>, databaseId: number } = { deploymentTypes: res, databaseId: action.payload.databaseId};
                        return AudienceBuilderActions.GetGroupDeploymentTypesSuccess({payload: payload});
                    }),
                    catchError(error => {
                        const payload = { error: error, databaseId: action.payload.databaseId };
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors[0], icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetGroupDeploymentTypesError({ payload: payload }));
                    })
                );
            })
        )
    );

    updateCrosshatchReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.UpdateCrosshatchReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/crosshatch/update-report?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: CrosshatchReport) => {
                        const payload: ResponseWrapper<CrosshatchReport> = { loading: false, data: res };
                        return AudienceBuilderActions.UpdateCrosshatchReportSuccess({ payload: payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying get Audience Overlap Report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.UpdateCrosshatchReportError(error));
                    })
                );
            })
        )
    );

    exportCrosshatchReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ExportCrosshatchReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/crosshatch/export-report?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload , { withCredentials: true }).pipe(
                    take(1),
                    map((res: { title?: string; brand?: string; createdDate?: string; content?: string[]; filename?: string; }) => {
                        const contentArray = [res?.title, `Brand: ${res?.brand}  Created: ${res?.createdDate}`, ...res?.content];
                        const blob: Blob = new Blob([contentArray?.join('\r\n')], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Crosshatch export generated successfully!' } }));
                        return AudienceBuilderActions.ExportCrosshatchReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to generate crosshatch export.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ExportCrosshatchReportError(error));
                    })
                );
            })
        )
    );

    exportCrosshatchCustomersReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ExportCrosshatchCustomerReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/crosshatch/export-customers-report?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload , { withCredentials: true }).pipe(
                    take(1),
                    map((res: { content?: string[]; filename?: string; }) => {
                        const contentArray = [...res?.content];
                        const blob: Blob = new Blob([contentArray?.join('\r\n')], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = res.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Crosshatch export generated successfully!' } }));
                        return AudienceBuilderActions.ExportCrosshatchCustomerReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to generate crosshatch export.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ExportCrosshatchReportCustomerError(error));
                    })
                );
            })
        )
    );
    SaveCustomerQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveCustomerQuery),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/crosshatch/save_customer_query?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload , { withCredentials: true }).pipe(
                    take(1),
                    map((res: { content?: string[]; filename?: string; }) => {
                        return AudienceBuilderActions.ExportCrosshatchCustomerReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to save query.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ExportCrosshatchReportCustomerError(error));
                    })
                );
            })
        )
    );

    getIssuesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIssuesList),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-list?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}${action.payload.mode ? `&mode=${action.payload.mode}` : ''}&productId=${action.payload.productId}`, { withCredentials: true }).pipe(
                    map((res: Array<IssueListResponse>) => {
                        return AudienceBuilderActions.GetIssuesListSuccess({payload: res});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to load issue list.', icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.GetIssuesListError({ error }));
                    })
                );
            })
        )
    );

    getAudienceReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetAudienceReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/audience-report/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload , { withCredentials: true }).pipe(
                    take(1),
                    map((res: { url?: string, message?: string }) => {
                        if (res?.url) window.open(res?.url, '_blank');
                        this.store.dispatch(ShowToast({ payload: { title: '', design: res?.message ? '' : 'Success', placement: 'TopEnd', message: res?.message ? res?.message : 'Audience report generated successfully!', keepOpen: res?.url ? true : false } }));
                        return AudienceBuilderActions.GetAudienceReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occured while trying to generate audience report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetAudienceReportError(error));
                    }
                    ));
            })
        )
    );

    getDemoList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetDemoList),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/report/demographic/demo-list?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<DemographicList>) => {
                        const payload: ResponseWrapper<Array<DemographicList>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetDemoListSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to generate demographic list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetDemoListError(error));
                    })
                );
            })
        )
    );

    getSavedDemoList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSavedDemoList),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/report/demographic/load-demo-reports?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<SavedReportCriteria>) => {
                        const payload: ResponseWrapper<Array<SavedReportCriteria>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSavedDemoListSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load saved demographic list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetSavedDemoListError(error));
                    })
                );
            })
        )
    );

    generateDemoReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GenerateDemoReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/demographic/create?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return AudienceBuilderActions.GenereateDemoReportSuccess({ payload: payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying to Generate Demo Report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GenereateDemoReportError(error));
                    })
                );
            })
        )
    );

    exportDemoReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ExportDemoReport),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/report/demographic/export-report?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'The Export File Has Been Emailed To You' } }));
                        return AudienceBuilderActions.ExportDemoReportSuccess({ payload: payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying to get Audience Overlap Report.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ExportDemoReportError(error));
                    })
                );
            })
        )
    );

    loadDemoReportCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.LoadDemoReportCriteria),
            exhaustMap((action) => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/report/demographic/load-selected-fields?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}&demoReportCriteriaId=${action.payload.demoReportCriteriaId}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<DemographicList>) => {
                        const payload: ResponseWrapper<Array<DemographicList>> = { loading: false, data: res };
                        return AudienceBuilderActions.LoadDemoReportCriteriaSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load saved demographic list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.LoadDemoReportCriteriaError(error));
                    })
                );
            })
        )
    );

    getInventoryCalendarData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetInventoryCalendarData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('newMonth', action.payload.newMonth);
                action.payload?.deploymentTypeId ? searchParams.set('deploymentTypeId', action.payload.deploymentTypeId.toString()) : null;
                return this.http.get(environment.apiEndPoint + `/audience-builder/inventory-calendar?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: InventoryCalendarI) => {
                        const payload: ResponseWrapper<InventoryCalendarI> = { loading: false, data: res };
                        return AudienceBuilderActions.GetInventoryCalendarDataSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        const errorMessage = error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occurred while trying to load Inventory Calendar data.';
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: errorMessage, icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetInventoryCalendarDataError(error));
                    })
                );
            })
        )
    );

    getInventoryCalendarStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetInventoryCalendarStatus),
            mergeMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/inventory-calendar/status?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: InventoryCalendarI) => {
                        const payload: ResponseWrapper<InventoryCalendarI> = { loading: false, data: res };
                        return AudienceBuilderActions.GetInventoryCalendarStatusSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load Inventory Calendar Status.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetInventoryCalendarStatusError(error));
                    })
                );
            })
        )
    );

    selectInventoryCalendarDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SelectInventoryCalendarDate),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('cellIndex', action.payload.cellIndex.toString());
                return this.http.post(environment.apiEndPoint + `/audience-builder/inventory-calendar?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedInventoryCalendarDay) => {
                        if (action.modalId) {
                            this.store.dispatch(AudienceBuilderActions.CloseInventoryCalendar());
                            this.modalService.getModalRef(action.modalId).close();
                        }
                        const payload: ResponseWrapper<SelectedInventoryCalendarDay> = { loading: false, data: res };
                        this.store.dispatch(AudienceBuilderActions.RefreshCounts({ payload: { refreshOnQSelectionCounts: 'y', hideToast: true } }));
                        return AudienceBuilderActions.SelectInventoryCalendarDateSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (action.modalId) {
                            this.store.dispatch(AudienceBuilderActions.CloseInventoryCalendar());
                            this.modalService.getModalRef(action.modalId).close();
                        }
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load selected Inventory Calendar Date.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SelectInventoryCalendarDateError(error));
                    })
                );
            })
        )
    );

    inventoryCalendarClose$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.InventoryCalendarClose),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + '/audience-builder/inventory-calendar/close', { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.store.dispatch(AudienceBuilderActions.RefreshCounts({ payload: { refreshOnQSelectionCounts: 'y', hideToast: true } }));
                        return AudienceBuilderActions.InventoryCalendarCloseSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to close Inventory Calendar.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.InventoryCalendarCloseError(error));
                    })
                );
            })
        )
    );

    calculateCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CalculateCounts),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('productId', action.payload.productId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/query/copy-count?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: {copyCount: number} ) => {
                        const payload = { copyCount: res?.copyCount };
                        return AudienceBuilderActions.CalculateCountsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to calculate counts.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.CalculateCountsError(error));
                    })
                );
            })
        )
    );

    getProductsList = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetProductsList),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                Object.entries(action.payload).forEach(([key, value]) => {
                    if (value !== undefined) {
                        searchParams.set(key, value.toString());
                    }
                });
                return this.http.get(environment.apiEndPoint + `/portal/products?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    //TODO type
                    map((res: PaidProductSchema[]) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.GetProductsListSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get products list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetProductsListError(error));
                    })
                );
            })
        )
    );

    //Issue Builder
    rollbackIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RollbackIssueBuild),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-rollback${searchParams}`, {withCredentials: true}).pipe(
                    take(1),
                    map((_res: BasicResponse) => {
                        return AudienceBuilderActions.RollbackIssueBuildSuccess({payload: {}});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'An error has occurred while trying to rollback issue build.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.RollbackIssueBuildError(error));
                    })
                );
            })
        )
    );

    checkModelingVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CheckModelingVersion),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());

                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/check-modeling-version?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.CheckModelingVersionSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to check modeling version.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.CheckModelingVersionError(error));
                    })
                );
            })
        )
    );

    //TODO Copy success should return the ID of the created copy to open
    copyIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CopyIssueBuild),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildIdCopyFrom', action.payload.buildIdCopyFrom.toString());
                return this.http.post(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-copy?${searchParams}`, action.payload.body, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        this.store.dispatch(AudienceBuilderActions.GetIssueBuilds({payload: {showAllIssueBuilds: true}}));
                        return AudienceBuilderActions.CopyIssueBuildSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to copy issue build.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.CopyIssueBuildError(error));
                    })
                );
            })
        )
    );

    //TODO Success should return the new buildId to open
    saveIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveIssueBuild),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.post(environment.apiEndPoint + `/audience-builder/issue-builder/save?${searchParams}`, action.payload.body, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        if (res.success) {
                            if (res.messages?.length > 0) {
                                this.store.dispatch(AudienceBuilderActions.OpenIssueBuild({payload: { buildId: parseInt(res.messages[0]) }}));
                            } else {
                                this.store.dispatch(AudienceBuilderActions.GetIssueBuilds({payload: {showAllIssueBuilds: true}}));
                            }
                            this.store.dispatch(ShowToast({ payload: {
                                title: 'Success',
                                design: 'Success',
                                placement: 'TopEnd',
                                message: 'Issue build created successfully.'
                            }}));
                        }
                        return AudienceBuilderActions.SaveIssueBuildSuccess({ payload });
                    }),
                    catchError(error => {
                        const errorMessage = error.error?.errors?.length > 0 ? error.error.errors[0] : null;
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: errorMessage || 'An error has occurred while trying to save issue build.',
                            icon: 'fa-circle-exclamation-solid'
                        } }));
                        return of(AudienceBuilderActions.SaveIssueBuildError(error));
                    })
                );
            })
        )
    );

    deleteIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.DeleteIssueBuild),
            exhaustMap((action) => {
                const isBuildOpen = localStorage.getItem(AbLocalStorageKeys.ISSUE_BUILD_ID) === action.payload.buildId.toString();
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.post(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-delete?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        this.store.dispatch(AudienceBuilderActions.GetIssueBuilds({payload: {showAllIssueBuilds: true}}));
                        if (isBuildOpen) {
                            this.store.dispatch(AudienceBuilderActions.ExitIssueBuilder());
                        }
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Success',
                            design: 'Success',
                            placement: 'TopEnd',
                            message: 'Build deleted successfully.'
                        }}));
                        return AudienceBuilderActions.DeleteIssueBuildSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to delete issue build.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.DeleteIssueBuildError(error));
                    })
                );
            })
        )
    );

    getIssueBuildList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIssueBuilds),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('showAllIssueBuilds', action.payload.showAllIssueBuilds.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-list?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: IssueBuild[]) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.GetIssueBuildsSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get issue build list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetIssueBuildsError(error));
                    })

                );
            })
        )
    );

    getIssueCloseList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIssueCloseList),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('numResults', '99');
                if (action?.payload?.singlePage) {
                    searchParams.set('singlePage', 'true');
                }
                return this.http.get(environment.apiEndPoint + `/portal/issues/current?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: { totalResultCount: number, items: Issue[] }) => {
                        const payload = {loading: false, data: res};
                        if (payload?.data?.items?.length) {
                            payload.data.items.sort((a, b) => a.productName.localeCompare(b.productName));
                        }
                        return AudienceBuilderActions.GetIssueCloseListSuccess({ payload });
                    }),
                    catchError(error => {
                        if (!/Too Many Matches to Display/.test(error?.error?.errors[0])) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get issue close list.', icon: 'fa-circle-exclamation-solid' } }));
                        }
                        return of(AudienceBuilderActions.GetIssueCloseListError(error));
                    })
                );
            })
        )
    );

    getMailingList = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetMailingList),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('productId', action.payload.productId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/mailing-list?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<{ issueMailingId?: number, mailingName?: string }>) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.GetMailingListSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get mailing list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetMailingListError(error));
                    })
                );
            })
        )
    );

    openIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.OpenIssueBuild),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-open?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        if (res.success) {
                            this.store.dispatch(AudienceBuilderActions.ReloadCurrentSelectionCriteria());
                            localStorage.setItem('issueBuildId', action.payload.buildId.toString());
                        }
                        return AudienceBuilderActions.OpenIssueBuildSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to open issue build.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.OpenIssueBuildError(error));
                    })
                );
            })
        )
    );

    openIssueRuleFromRoute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.OpenIssueRuleFromRoute),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.issueBuildId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-open?${searchParams}`, { withCredentials: true }).pipe(
                    map((openIssueBuildResult: BasicResponse) => {
                        if (openIssueBuildResult?.success && action.payload.issueRuleId) {
                            this.store.dispatch(AudienceBuilderActions.OpenSelectionCriteria({payload: {selectionCriteriaId: action.payload.issueRuleId}}));
                        } else {
                            const payload = {
                                selectionCriteriaId: 0,
                                mode: 'sessionQuery',
                                showNthMessage: false
                            };
                            this.store.dispatch(AudienceBuilderActions.GetSelectionCriteria({ payload }));
                        }
                        return AudienceBuilderActions.OpenIssueRuleFromRouteSuccess({payload: {loading: false, data: openIssueBuildResult}});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to open issue build from route.', icon: 'fa-circle-exclamation-solid' } }));
                        console.log('Loading session');
                        const payload = {
                            selectionCriteriaId: 0,
                            mode: 'sessionQuery',
                            showNthMessage: false
                        };
                        this.store.dispatch(AudienceBuilderActions.GetSelectionCriteria({ payload }));
                        return of(AudienceBuilderActions.OpenIssueRuleFromRouteError(error));
                    })
                );
            })
        )
    );

    previewIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.PreviewIssueBuild),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                if(action.payload.previewOnly !== undefined) {
                    searchParams.set('previewOnly', action.payload.previewOnly.toString());
                }
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-preview?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: PreviewIssueBuildRes) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.PreviewIssueBuildSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to preview issue build.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.PreviewIssueBuildError(error));
                    })
                );
            })
        )
    );

    refreshModeling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RefreshModeling),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.put(environment.apiEndPoint + `/audience-builder/issue-builder/refresh-modeling?${searchParams}`, action.payload.body, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.RefreshModelingSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to refresh issue build.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.RefreshModelingError(error));
                    })
                );
            })
        )
    );

    getIssueBuilderCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIssueBuilderCounts),
            exhaustMap( action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/counts?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: IssueBuildCounts) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.GetIssueBuilderCountsSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get issue builder counts.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetIssueBuilderCountsError(error));
                    })
                );
            })
        )
    );

    getIssueBuilderFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIssueBuilderFolders),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/folders?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: IssueBuilderFolder) => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.GetIssueBuilderFoldersSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get issue builder folders.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetIssueBuilderFoldersError(error));
                    })
                );
            })
        )
    );

    exitIssueBuilder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ExitIssueBuilder),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-exit?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        this.store.dispatch(AudienceBuilderActions.ReloadCurrentSelectionCriteria());
                        localStorage.removeItem('issueBuildId');
                        this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                        this.store.dispatch(AudienceBuilderActions.ClearIssueBuilderFoldersMap());
                        return AudienceBuilderActions.ExitIssueBuilderSuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to exit issue builder.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ExitIssueBuilderError(error));
                    })
                );
            })
        )
    );

    approveIssueBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ApproveIssueBuild),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                return this.http.post(environment.apiEndPoint + `/audience-builder/issue-builder/issue-build-approve?${searchParams}`, {withCredentials: true}).pipe(
                    take(1),
                    map(() => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Success',
                            design: 'Success',
                            placement: 'TopEnd',
                            message: 'Build has been approved.',
                            icon: 'fa-circle-check'
                        }}));
                        if (action.payload.modalId) {
                            this.modalService.getModalRef(action.payload.modalId)?.close();
                        }
                        this.store.dispatch(AudienceBuilderActions.ExitIssueBuilder());
                        return AudienceBuilderActions.ApproveIssueBuildSuccess();
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: error?.error?.errors[0] || 'An error has occurred while trying to approve issue build.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.ApproveIssueBuildError(error));
                    })
                );
            })
        )
    );

    loadSaveRuleData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.LoadSaveRuleData),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/save-rule/load?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map(res => {
                        const payload = {loading: false, data: res};
                        return AudienceBuilderActions.LoadSaveRuleDataSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'An error has occurred while trying to load save rule data.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.LoadSaveRuleDataError(error));
                    })
                );
            })
        )
    );

    saveRuleAuditTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveRuleAuditTypes),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('classDefinitionId', action.payload.classDefinition);
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/save-rule/audit-types?${searchParams}`, {withCredentials: true}).pipe(
                    take(1),
                    map((res: AuditType[]) => {
                        const payload = {
                            loading: false,
                            data: res
                        };
                        return AudienceBuilderActions.SaveRuleAuditTypesSuccess({payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'An error has occurred while trying to get rule audit types.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.SaveRuleAuditTypesError(error));
                    })
                );
            })
        )
    );

    saveRuleNthFieldData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveRuleNthFieldData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId.toString());
                searchParams.set('demographicId', action.payload.demographicId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/issue-builder/save-rule/nth-field-data?${searchParams}`, {withCredentials: true}).pipe(
                    take(1),
                    map((res: NthField[]) => {
                        const payload = {
                            loading: false,
                            data: res
                        };
                        return AudienceBuilderActions.SaveRuleNthFieldDataSuccess({payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: 'An error has occurred while trying to get rule nth field data.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.SaveRuleNthFieldDataError(error));
                    })
                );
            })
        )
    );

    saveRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveRule),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.post(environment.apiEndPoint + `/audience-builder/issue-builder/save-rule?${searchParams}`, action.payload, {withCredentials: true}).pipe(
                    take(1),
                    map((res: SaveRuleData) => {
                        if (res.success) {
                            const payload = { loading: false, data: res};
                            this.store.dispatch(ShowToast({
                                payload: {
                                    title: 'Success',
                                    design: 'Success',
                                    placement: 'TopEnd',
                                    message: 'Rule saved successfully.'
                                }
                            }));
                            this.modalService.getModalRef(action.modalId)?.close();
                            this.store.dispatch(AudienceBuilderActions.NewQuery({ payload: { reload: true }}));
                            return AudienceBuilderActions.SaveRuleSuccess({payload: payload});
                        } else {
                            this.store.dispatch(ShowToast({ payload: {
                                title: 'Error',
                                design: 'Error',
                                placement: 'TopEnd',
                                message: res.errors[0],
                                icon: 'fa-circle-exclamation-solid'
                            }}));
                            return AudienceBuilderActions.SaveRuleError({ error: res });
                        }
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: error || 'An error has occurred while trying to save rule.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.SaveRuleError(error));
                    })
                );
            })
        )
    );

    deleteRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.DeleteRule),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('buildId', action.payload.buildId);
                searchParams.set('ruleId', action.payload.ruleId.toString());
                return this.http.delete(environment.apiEndPoint + `/audience-builder/issue-builder/delete-rule?${searchParams}`, {withCredentials: true}).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload = { loading: false, data: res};
                        this.store.dispatch(ShowToast({
                            payload: {
                                title: 'Success',
                                design: 'Success',
                                placement: 'TopEnd',
                                message: 'Rule deleted successfully.'
                            }
                        }));
                        this.store.dispatch(AudienceBuilderActions.PreviewIssueBuild({payload: {buildId: parseInt(action.payload.buildId)}}));
                        //TODO make buildId type consistent
                        this.store.dispatch(AudienceBuilderActions.GetIssueBuilderCounts({ payload: { buildId: action.payload.buildId } }));
                        return AudienceBuilderActions.DeleteRuleSuccess({payload: payload});
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: error || 'An error has occurred while trying to delete rule.',
                            icon: 'fa-circle-exclamation-solid'
                        }}));
                        return of(AudienceBuilderActions.DeleteRuleError(error));
                    })
                );
            })
        )
    );

    // -------//-------

    searchLibraryFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SearchLibraryFolders),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('searchValue', action.payload.searchValue);
                action.payload?.allSearchResults ? searchParams.set('allSearchResults', action.payload.allSearchResults.toString()) : null;

                return this.http.get(environment.apiEndPoint + `/audience-builder/library/search?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: IssueBuilderFolder) => {
                        const payload: ResponseWrapper<IssueBuilderFolder> = { loading: false, data: res };
                        return AudienceBuilderActions.SearchLibraryFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0], icon: 'fa-circle-exclamation-solid' } }));
                        this.store.dispatch(AudienceBuilderActions.GetCurrentOnQFolders());
                        return of(AudienceBuilderActions.SearchLibraryFoldersError(error));
                    })
                );
            })
        )
    );

    getOnqFolderGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOnqFolderGroups),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/library/folder-groups?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<OnqFolderGroups>) => {
                        const payload: ResponseWrapper<Array<OnqFolderGroups>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOnqFolderGroupsSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get output criteria fields.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOnqFolderGroupsError(error));
                    })
                );
            })
        )
    );

    addNewOnqFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.AddNewFolder),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/library/folder/new?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: NewFolderDataRes) => {
                        const payload: ResponseWrapper<NewFolderDataRes> = { loading: false, data: res };
                        return AudienceBuilderActions.AddNewFolderSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to add a new folder.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.AddNewFolderError(error));
                    })
                );
            })
        )
    );

    getOnqContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOnqContactRules),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/contact-rules/load?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqContactRules) => {
                        const payload: ResponseWrapper<OnqContactRules> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOnqContactRulesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get Onq Contact Rules.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOnqContactRulesError(error));
                    })
                );
            })
        )
    );

    getOnqHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOnqHistory),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('direction', action.payload.sortDirection);
                searchParams.set('sortBy', action.payload.sortColumn);
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/history?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<OnqHistoryRes>) => {
                        const payload: ResponseWrapper<Array<OnqHistoryRes>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOnqHistorySuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get Onq Modification History.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOnqHistoryError(error));
                    })
                );
            })
        )
    );

    getOnqSegments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOnqSegments),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/segments/load?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqSegmentsRes) => {
                        const payload: ResponseWrapper<OnqSegmentsRes> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOnqSegmentsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get Onq Segments.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOnqSegmentsError(error));
                    })
                );
            })
        )
    );

    getOnqSelectedSegments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOnqSelectedSegments),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('folderId', action.payload.folderId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/segments/contents?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqSegmentsRes) => {
                        const payload: ResponseWrapper<OnqSegmentsRes> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOnqSelectedSegmentsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get Onq Selected Segments.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetOnqSelectedSegmentsError(error));
                    })
                );
            })
        )
    );

    saveSelectedSegments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveSelectedSegments),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('folderId', action.payload.folderId.toString());
                searchParams.set('mode', action.payload.mode);
                action.payload.name ? searchParams.set('name', action.payload.name) : '';
                action.payload?.segmentIdList?.length > 0 ? action.payload?.segmentIdList?.forEach(segment => {
                    searchParams.append('segmentIdList', segment.toString());
                }) : '';
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/segments/save?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.store.dispatch(AudienceBuilderActions.GetOnqSegments());
                        this.store.dispatch(AudienceBuilderActions.GetOnqSelectedSegments({ payload: { folderId: action.payload.folderId } }));
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveSelectedSegmentsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to save Selected Segments.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveSelectedSegmentsError(error));
                    })
                );
            })
        )
    );

    saveOnqContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveOnqContactRules),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/contact-rules/save?${searchParams}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqOutputFoldersResult) => {
                        const payload: ResponseWrapper<OnqOutputFoldersResult> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveOnqContactRulesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to save Selected Segments.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveOnqContactRulesError(error));
                    })
                );
            })
        )
    );

    getAvailableFolderChildren$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetAvailableFolderChildren),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('folderId', action.payload.folderId.toString());
                return this.http.get(environment.apiEndPoint + `/audience-builder/admin/library/folder/children?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedOnqFolders) => {
                        const payload: ResponseWrapper<SelectedOnqFolders> = { loading: false, data: res };
                        return AudienceBuilderActions.GetAvailableFolderChildrenSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        return of(AudienceBuilderActions.GetAvailableFolderChildrenError(error));
                    })
                );
            })
        )
    );

    saveOnqCurrentFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveOnqCurrentFolders),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/library/save?${searchParams}`, action.payload?.selectedFolders, { withCredentials: true }).pipe(
                    take(1),
                    map((res: CurrentOnQFoldersResponse) => {
                        const payload: ResponseWrapper<CurrentOnQFoldersResponse> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveOnqCurrentFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors?.[0] ? error?.error?.errors[0] : 'An error has occured while trying to save Current Folders.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveOnqCurrentFoldersError(error));
                    })
                );
            })
        )
    );

    saveOnqOutputFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveOnqOutputFolders),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/output/save?${searchParams}`, action.payload?.selectedFolders, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqOutputFoldersResult) => {
                        const payload: ResponseWrapper<OnqOutputFoldersResult> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveOnqOutputFoldersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying to save Output Folders.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveOnqOutputFoldersError(error));
                    })
                );
            })
        )
    );

    saveOnqMatchEvaluation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveOnqMatchEvaluation),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/match-evaluation/save?${searchParams}`, action.payload?.selectedFolders, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqOutputFoldersResult) => {
                        const payload: ResponseWrapper<OnqOutputFoldersResult> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveOnqMatchEvaluationSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying to save Match Evaluation.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveOnqMatchEvaluationError(error));
                    })
                );
            })
        )
    );

    saveOnqReports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SaveOnqReports),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(environment.apiEndPoint + `/audience-builder/admin/reports/save?${searchParams}`, action.payload?.selectedFolders, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OnqOutputFoldersResult) => {
                        const payload: ResponseWrapper<OnqOutputFoldersResult> = { loading: false, data: res };
                        this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Saved Successfully!' } }));
                        return AudienceBuilderActions.SaveOnqReportsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors[0] ? error?.error?.errors[0] : 'An error has occured while trying to save Reports.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.SaveOnqReportsError(error));
                    })
                );
            })
        )
    );
    getAudienceCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetAudienceCounts),
            exhaustMap(() => {
                // Gets all counts without distinct emails
                const searchParams1 = new URLSearchParams();
                searchParams1.set('environmentId', this.currentDatabase.id);
                searchParams1.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);

                return this.http.get(`${environment.apiEndPoint}/audience-builder/customer/counts?${searchParams1}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res) => {
                            const payload: ResponseWrapper<IAudienceCounts> = {
                                loading: false,
                                data: { ...res as IAudienceCounts }
                            };

                            return AudienceBuilderActions.GetAudienceCountsSuccess({ payload });
                        }),
                        catchError(error => {
                            this.store.dispatch(ShowToast({ payload: {
                                title: 'Error',
                                design: 'Error',
                                placement: 'TopEnd',
                                message: 'An error has occurred while trying to get audience counts.',
                                icon: 'fa-circle-exclamation-solid'
                            }}));
                            return of(AudienceBuilderActions.GetAudienceCountsError(error));
                        })
                    );
            })
        )
    );

    getDistinctEmails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetDistinctEmails),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('mode', 'distinctEmails');
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/customer/counts?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: { distinctEmailCount?: number }) => {
                            const payload: ResponseWrapper<{ distinctEmailCount?: number }> = { loading: false, data: res };
                            return AudienceBuilderActions.GetDistinctEmailsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to get distinct emails.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.GetDistinctEmailsError(error));
                        })
                    );
            })
        )
    );

    getFolderProductTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetFolderProductTypes),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.casHttp
                    .get(environment.settingsApiEndPoint,environment.settingsApiEndPoint + `/product/product-types?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: Array<ProductMembershipTypes>) => {
                            const payload: ResponseWrapper<Array<ProductMembershipTypes>> = { loading: false, data: res };
                            return AudienceBuilderActions.GetFolderProductTypesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to get product membership types.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.GetFolderProductTypesError(error));
                        })
                    );
            })
        )
    );

    getQueryXml$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetQueryXml),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/query/xml?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: QueryXmlRes) => {
                            const payload: ResponseWrapper<QueryXmlRes> = { loading: false, data: res };
                            return AudienceBuilderActions.GetQueryXmlSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to get query XML.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.GetQueryXmlError(error));
                        })
                    );
            })
        )
    );

    changeGroupLogic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ChangeGroupLogic),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/group?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        // this.store.dispatch(GetSelectedGroupLogic({ payload: { selectionCriteriaId: res?.id }}));
                        return AudienceBuilderActions.ChangeGroupLogicSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to change group logic.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ChangeGroupLogicError(error));
                    })
                );
            })
        )
    );

    changeSetLogic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ChangeSetLogic),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/search?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.ChangeSetLogicSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to change set logic.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.ChangeSetLogicError(error));
                    })
                );
            })
        )
    );

    queryCheckboxContentSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.QueryCheckboxContentSearch),
            concatMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/query/search?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`, action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteria) => {
                        if (res) {
                            if (action?.payload['mode'] == 'remove') {
                                res = {...res, onqFolderId: action?.payload?.folderIds, folderValueId: action?.payload?.folderValueIds};
                            } else {
                                res = {...res, onqFolderId: action?.payload?.folderIds};
                            }
                            this.store.dispatch(AudienceBuilderActions.SetSelectionCriteria({ payload: {selectionCriteria: res, activeGroup: action?.payload?.groupId} }));
                        }
                        const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                        return AudienceBuilderActions.QueryCheckboxContentSearchSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error.error?.errors?.join('\n'), icon: 'fa-exclamation-circle-solid' } }));
                        return of(AudienceBuilderActions.QueryCheckboxContentSearchError(error));
                    })
                );
            })
        )
    );
    getQueryBitsets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetQueryBitsets),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/bit-sets?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: QueryBitsets) => {
                            const payload: ResponseWrapper<QueryBitsets> = { loading: false, data: res };
                            return AudienceBuilderActions.GetQueryBitsetsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to get query Bitesets.',
                                        icon: 'fa-circle-exclamation-solid',
                                    }
                                })
                            );
                            return of(AudienceBuilderActions.GetQueryBitsetsError(error));
                        })
                    );
            })
        )
    );

    changeContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ChangeContactRules),
            exhaustMap((action) => {
                const url = new URL(environment.apiEndPoint + '/audience-builder/query/search');
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                url.search = searchParams.toString();
                return this.http.post(url.toString(), action.payload, {withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: SelectionCriteria) => {
                            const payload: ResponseWrapper<SelectionCriteria> = { loading: false, data: res };
                            return AudienceBuilderActions.ChangeContactRulesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to apply contact rules',
                                        icon: 'fa-circle-exclamation-solid'
                                    }
                                })
                            );
                            return of(AudienceBuilderActions.ChangeContactRulesError(error));
                        })
                    );
            })
        )
    );

    setContactRulesFromPopup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SetContactRulesFromPopup),
            concatMap((action) => {
                const url = new URL(environment.apiEndPoint + `/audience-builder/query/search?dataViewId=${this.currentUser.currentProfile.dataViewId}&environmentId=${this.currentDatabase.id}`);
                const rules: {contactRuleId: number, mode: string, groupId: number, folderIds: string}[] = [];
                if (action.payload.emailProductRuleId) {
                    rules.push({
                        contactRuleId: action.payload.emailProductRuleId,
                        mode: 'changeContactRule',
                        groupId: action.payload.groupId,
                        folderIds: '0'
                    });
                }
                if (action.payload.shippingRuleId) {
                    rules.push({
                        contactRuleId: action.payload.shippingRuleId,
                        mode: 'changeContactRule',
                        groupId: action.payload.groupId,
                        folderIds: '0'
                    });
                }
                if (action.payload.messageTypeRuleId) {
                    rules.push({
                        contactRuleId: action.payload.messageTypeRuleId,
                        mode: 'changeContactRule',
                        groupId: action.payload.groupId,
                        folderIds: '0'
                    });
                }
                const requests = rules.map((rule) =>
                    this.http.post(url.toString(), rule, {withCredentials: true}).pipe(
                        take(1),
                        catchError(error => {
                            return of(AudienceBuilderActions.SetContactRulesFromPopupError(error));
                        })
                    )
                );
                return from(requests).pipe(
                    concatMap(request => request),
                    last(),
                    map(() => {
                        this.store.dispatch(AudienceBuilderActions.GetSessionContactRules({payload: {groupId: 0}}));
                        return AudienceBuilderActions.SetContactRulesFromPopupSuccess();
                    })
                );
            })
        )
    );

    removeInternalList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.RemoveInternalList),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('mode', 'removeInternalList');
                searchParams.set('internalListId', action.payload.internalListId.toString());
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/query?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                            return AudienceBuilderActions.RemoveInternalListSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to remove internal list.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.RemoveInternalListError(error));
                        })
                    );
            })
        )
    );

    getQueryGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetQueryGroups),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/query/groups?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: Array<QueryGroup>) => {
                            const payload: ResponseWrapper<Array<QueryGroup>> = { loading: false, data: res };
                            return AudienceBuilderActions.GetQueryGroupsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to get query groups.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.GetQueryGroupsError(error));
                        })
                    );
            })
        )
    );

    getInventoryCalendarPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetIsInventoryCalendarAllowed),
            exhaustMap(() => {
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/inventory-calendar/allowed?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map(() => {
                            return AudienceBuilderActions.GetIsInventoryCalendarAllowedSuccess();
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status !== 401) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:'An error has occurred while trying to get inventory calendar permission',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            return of(AudienceBuilderActions.GetIsInventoryCalendarAllowedError(error));
                        })
                    );
            })
        )
    );

    getInventoryCalendarRequired$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetInventoryCalendarRequired),
            exhaustMap(() => {
                return this.http
                    .get(environment.apiEndPoint + `/audience-builder/inventory-calendar/required?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res) => {
                            const payload: ResponseWrapper<InventoryCalendarI> = { loading: false, data: res as InventoryCalendarI };
                            return AudienceBuilderActions.GetInventoryCalendarDataSuccess({ payload: payload });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status !== 401) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:'An error has occurred while trying to get inventory calendar required permission',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            return of(AudienceBuilderActions.GetInventoryCalendarDataError(error));
                        })
                    );
            })
        )
    );

    closeInventoryCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.CloseInventoryCalendar),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http.get(environment.apiEndPoint + `/audience-builder/inventory-calendar/removeBean?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map(() => {
                        return AudienceBuilderActions.CloseInventoryCalendarSuccess({payload: {}});
                    }),
                    catchError((error) => {
                        return of(AudienceBuilderActions.CloseInventoryCalendarError(error));
                    })
                );
            })
        )
    );

    showNthToggle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.ShowNthToggle),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/nthing?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return AudienceBuilderActions.ShowNthToggleSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        return of(AudienceBuilderActions.ShowNthToggleError(error));
                    })
                );
            })
        )
    );

    groupNthChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GroupNthChange),
            exhaustMap((action) => {
                return this.http.post(environment.apiEndPoint + `/audience-builder/nthing?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, action?.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return AudienceBuilderActions.GroupNthChangeSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        // this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load saved demographic list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GroupNthChangeError(error));
                    })
                );
            })
        )
    );

    getCrosshatchFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetCrosshatchFields),
            exhaustMap(() => {
                return this.http.get(environment.apiEndPoint + `/audience-builder/report/crosshatch/fields?dataViewId=${this.currentUser?.currentProfile?.dataViewId}&environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FieldCountsItem[]) => {
                        const payload: ResponseWrapper<FieldCountsItem[]> = { loading: false, data: res };
                        return AudienceBuilderActions.GetCrosshatchFieldsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        // this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to load saved demographic list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetCrosshatchFieldsError(error));
                    })
                );
            })
        )
    );

    syncProdToStaging$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.SyncToStaging),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('confirm', 'true');
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                return this.http
                    .post(environment.apiEndPoint + `/audience-builder/admin/sync-production?${searchParams}`, { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (!res?.success) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message: res?.errors?.join('\n') || 'An error has occurred while trying to sync prod to staging.',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                                return AudienceBuilderActions.SyncToStagingSuccess({ payload: res });
                            }
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Success',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                        message:'Successfully synced prod to staging.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return AudienceBuilderActions.SyncToStagingSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: error.error?.errors?.join('\n') || 'An error has occurred while trying to sync prod to staging.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(AudienceBuilderActions.SyncToStagingError(error));
                        })
                    );
            })
        )
    );

    getOutputDeploymentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetOutputDeploymentTypes),
            exhaustMap((action) => {
                const filters = {
                    type: action?.payload?.type,
                    nameContains: action?.payload?.nameContains,
                    trackId: action?.payload?.trackId,
                };
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', action?.payload?.database || this.currentDatabase.id);
                action.payload.numResults ? searchParams.set('numResults', action.payload.numResults.toString()) : '';
                action.payload.offset ? searchParams.set('offset', action.payload.offset.toString()) : '';
                action.payload.order ? searchParams.set('order', action.payload.order) : '';
                action.payload.sortBy ? searchParams.set('sortBy', action.payload.sortBy) : '';
                return this.http.post(environment.apiEndPoint + `/audience-builder/output/deployments?${searchParams}`, { filters }, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<OutputDeploymentType>) => {
                        const payload: ResponseWrapper<Array<OutputDeploymentType>> = { loading: false, data: res };
                        return AudienceBuilderActions.GetOutputDeploymentTypesSuccess( {payload} );
                    }),
                    catchError(error => {
                        return of(AudienceBuilderActions.GetOutputDeploymentTypesError(error));
                    })
                );
            })
        )
    );

    getSelectionCriteriaDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetSelectionCriteriaDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('selectionCriteriaId', action.payload?.selectionCriteriaId?.toString());

                return this.http.get(environment.apiEndPoint + `/audience-builder/query/details?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectionCriteriaDetailsRes) => {
                        const payload: ResponseWrapper<SelectionCriteriaDetailsRes> = { loading: false, data: res };
                        return AudienceBuilderActions.GetSelectionCriteriaDetailsSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors?.[0] ? error?.error?.errors[0] : 'An error has occurred while trying to get query details.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(AudienceBuilderActions.GetSelectionCriteriaDetailsError(error));
                    })
                );
            })
        )
    );

    getFiltratedBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceBuilderActions.GetFiltratedBehaviors),
            mergeMap((action) => {
                const folderId = action.payload?.folder?.onqFolderId;
                const dataViewId = this.currentUser?.currentProfile?.dataViewId;
                const environmentId = this.currentDatabase.id;
                let query = `?folderId=${folderId}&groupId=${action.payload?.activeGroup || 0}&environmentId=${environmentId}&dataViewId=${dataViewId}`;
                if (action.payload?.folder?.folderValueId) {
                    query += `&folderValueId=${action.payload?.folder?.folderValueId}`;
                }
                if (action.payload?.behaviorProductFilterText) {
                    query += `&behaviorProductFilterText=${action.payload?.behaviorProductFilterText}`;
                }
                if (action.payload?.behaviorFilterText) {
                    query += `&behaviorFilterText=${action.payload?.behaviorFilterText}`;
                }
                return this.http.get(environment.apiEndPoint + '/audience-builder/library/folder' + query, { withCredentials: true }).pipe(
                    take(1),
                    map((res: { folder: Folder, folderValueId: number, activeGroup: number, columnValue?: string, behaviorProductFilterText?: string, behaviorFilterText?: string }) => {
                        const folder: ResponseWrapper<Folder> = { loading: false, data: res };
                        if (action.payload?.behaviorProductFilterText) {
                            return AudienceBuilderActions.GetFiltratedProductBehaviorsSuccess({ payload: {
                                folder,
                                folderValueId: action.payload.folder?.folderValueId,
                                activeGroup:  action.payload?.activeGroup,
                                columnValue: action.payload.folder?.columnValue,
                                behaviorProductFilterText: action.payload?.behaviorProductFilterText || null,
                                behaviorFilterText: action.payload?.behaviorFilterText || null
                            } });
                        }
                        if (action.payload?.behaviorFilterText) {
                            return AudienceBuilderActions.GetFiltratedIndividualBehaviorsSuccess({ payload: {
                                folder,
                                folderValueId: action.payload.folder?.folderValueId,
                                activeGroup:  action.payload?.activeGroup,
                                columnValue: action.payload.folder?.columnValue,
                                behaviorProductFilterText: action.payload?.behaviorProductFilterText || null,
                                behaviorFilterText: action.payload?.behaviorFilterText || null
                            } });
                        }
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: error?.error?.errors?.[0] ? error?.error?.errors[0] : 'An error has occurred while trying to filter behaviors.', icon: 'fa-circle-exclamation-solid' } }));
                        if (action.payload?.behaviorProductFilterText) {
                            return of(AudienceBuilderActions.GetFiltratedProductBehaviorsError({ error, folderId, activeGroup: action.payload?.activeGroup }));
                        }
                        if (action.payload?.behaviorFilterText) {
                            return of(AudienceBuilderActions.GetFiltratedIndividualBehaviorsError({ error, folderId, activeGroup: action.payload?.activeGroup }));
                        }
                    })
                );
            })
        )
    );

    //TODO Type these
    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(take(1), map(database => res = database)).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(take(1), map(user => res = user)).subscribe();
        return res;
    }
}

