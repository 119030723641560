import { ResponseWrapper } from 'common';

export const messageTypesInitialState: MessageTypesState = {
    messageTypesList: {
        data: null,
        loading: false
    }
};

export interface MessageTypesState {
    smsBuilderPermissions?: ResponseWrapper<Array<string>>,
    messageTypesList: ResponseWrapper<MessageTypesData>,
    messageTypeData?: ResponseWrapper<MessageType>,
    useCasesList?: ResponseWrapper<Array<CommonObjectResponse>>,
    volumeOptionsList?: ResponseWrapper<Array<CommonObjectResponse>>,
    optInOptionsList?: ResponseWrapper<Array<CommonObjectResponse>>,
    // Business Profiles
    businessProfilesList?: ResponseWrapper<Array<BusinessProfile>>,
    businessProfile?: ResponseWrapper<BusinessProfile>,
    businessIndustryOptions?: ResponseWrapper<Array<CommonObjectResponse>>,
    businessTypeOptions?: ResponseWrapper<Array<CommonObjectResponse>>,
    regionOptions?: ResponseWrapper<Array<RegionOption>>,
    // Business Reps
    businessRepsList?: ResponseWrapper<Array<BusinessRep>>,
    businessContact?: ResponseWrapper<BusinessRep>,
    jobTitlesOptionsList?: ResponseWrapper<Array<CommonObjectResponse>>,
}

export interface MessageTypesData {
    totalResultCount: number,
    items: Array<MessageType>,
}

export interface MessageType {
    messageTypeId: number,
    name: string,
    description: string,
    typeCode: number,
    publicName: string,
    statusCode: number,
    useCase: string,
    useCaseSummary: string,
    sampleMessage: string,
    sampleMessage2: string,
    optinType: string,
    optinImageUrl: string,
    privacyPolicyUrl: string,
    expectedVolume: string,
    phoneTypeCode: number,
    createdDate: string,
    createdBy: string,
    phoneTypeCodeDescription: string,
    useCaseDescription: string,
    businessProfileId: number,
    businessRepId: number,
    phoneNumberAssigned: string,
    status: string,
    assignedPhoneNumber?: AssignedPhoneNumberData,
    submitVerification?: boolean,
    disableCampaignDetailFields?: boolean,
}

export interface AssignedPhoneNumberData {
    sendingPhoneNumberId: number,
    friendlyName: string,
    typeCode: number,
    statusCode: number,
    verificationCode: number,
    verificationCodeDescription: string,
    typeCodeDescription: string,
    countryCode: string,
    createdDate: string,
    createdBy: string,
    phoneNumber: string,
    verificationRequestSid: string,
    messageTypeId: number,
    regionCode: string,
    areaCode: number,
    locality: string,
    twilioVerificationStatus: string,
    countryCallingCode: number,
    status: string,
    verified: boolean,
}

export interface BusinessProfile {
    businessProfileId: number,
    profileName: string,
    businessName: string,
    businessType: string,
    businessIndustry: string,
    street1: string,
    street2: string,
    city: string,
    regionCode: string,
    postalCode: string,
    countryCode2Char: string,
    typeCode: number,
    statusCode: number,
    websiteUrl: string,
    lastEvaluationResult: string,
    reviewStatus: string,
    stockExchange: string,
    stockTicker: string,
    trustBundleReviewStatus: string,
    businessVolumeCode: string,
    brandRegistrationSid: string,
    status: string
}

export interface BusinessRep {
    businessRepId: number,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    statusCode: number,
    status: string
}

export interface RegionOption {
    regionCode: string,
    regionName: string,
}

export interface CommonObjectResponse {
    description: string,
    value: string,
}
