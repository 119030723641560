import { on } from '@ngrx/store';
import * as PromoCodesActions from './promo-codes.actions';
import { CampaignsState } from '../interfaces';
import { PremiumCode } from './promo-codes.interfaces';

export const promoCodesReducer = [
    on(PromoCodesActions.GetPromoCodes, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state?.promoCodesState,
                promoCodesList: {
                    loading: true,
                    data: state.promoCodesState?.promoCodesList?.data
                },
            }
        };
    }),

    on(PromoCodesActions.GetPromoCodesSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodesList: {
                    loading: false,
                    data: action.payload?.data
                },
            }
        };
    }),

    on(PromoCodesActions.GetPromoCodesError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodesList: {
                    loading: false,
                    data: state.promoCodesState?.promoCodesList?.data
                },
            }
        };
    }),

    on(PromoCodesActions.GetPromoCode, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: true,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
                currentPromoCodeState: null
            }
        };
    }),

    on(PromoCodesActions.GetPromoCodeSuccess, (state: CampaignsState, action) => {
        let data = action.payload?.data || null;
        if (action.payload?.data?.premiumCodesByProductId && action.payload?.data?.premiumCodesByProductId?.length > 0) {
            const premiumCodesMap = new Map<string, Array<PremiumCode>>();
            action.payload?.data?.premiumCodesByProductId?.forEach(item => {
                premiumCodesMap[item.productId] = item.premiumCodes;
            });
            data = {
                ...action.payload?.data,
                premiumCodesMap
            };
        }

        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: false,
                    data
                },
            }
        };
    }),

    on(PromoCodesActions.GetPromoCodeError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: false,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
            }
        };
    }),

    on(PromoCodesActions.GetProducts, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeProducts: {
                    loading: true,
                    data: null
                },
            }
        };
    }),

    on(PromoCodesActions.GetProductsSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeProducts: {
                    loading: false,
                    data: action.payload?.data
                },
            }
        };
    }),

    on(PromoCodesActions.GetProductsError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeProducts: {
                    loading: false,
                    data: state.promoCodesState?.promoCodeProducts?.data
                },
            }
        };
    }),

    on(PromoCodesActions.SavePromoCodeData, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: true,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
            }
        };
    }),

    // on(PromoCodesActions.SavePromoCodeDataSuccess, (state: CampaignsState) => {
    //     return {
    //         ...state,
    //         promoCodesState: {
    //             ...state.promoCodesState,
    //             selectedPromoCode: {
    //                 loading: false,
    //                 data: state.promoCodesState?.selectedPromoCode?.data
    //             },
    //         }
    //     };
    // }),

    on(PromoCodesActions.SavePromoCodeDataError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: false,
                    data: state.promoCodesState?.selectedPromoCode?.data,
                    error: true
                },
            }
        };
    }),

    on(PromoCodesActions.SavePromoCodeProducts, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: true,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
            }
        };
    }),

    // on(PromoCodesActions.SavePromoCodeProductsSuccess, (state: CampaignsState) => {
    //     return {
    //         ...state,
    //         promoCodesState: {
    //             ...state.promoCodesState,
    //             selectedPromoCode: {
    //                 loading: false,
    //                 data: state.promoCodesState?.selectedPromoCode?.data
    //             },
    //         }
    //     };
    // }),

    on(PromoCodesActions.SavePromoCodeProductsError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: false,
                    data: state.promoCodesState?.selectedPromoCode?.data,
                    error: true
                },
            }
        };
    }),

    on(PromoCodesActions.SavePromoCodeCampaignPricing, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: true,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
            }
        };
    }),

    // on(PromoCodesActions.SavePromoCodeCampaignPricingSuccess, (state: CampaignsState) => {
    //     return {
    //         ...state,
    //         promoCodesState: {
    //             ...state.promoCodesState,
    //             selectedPromoCode: {
    //                 loading: false,
    //                 data: state.promoCodesState?.selectedPromoCode?.data
    //             },
    //         }
    //     };
    // }),

    on(PromoCodesActions.SavePromoCodeCampaignPricingError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                selectedPromoCode: {
                    loading: false,
                    data: state.promoCodesState?.selectedPromoCode?.data,
                    error: true
                },
            }
        };
    }),

    on(PromoCodesActions.DownloadPromoCodes, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                downloadLoader: true
            }
        };
    }),

    on(PromoCodesActions.DownloadPromoCodesSuccess, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                downloadLoader: false
            }
        };
    }),

    on(PromoCodesActions.DownloadPromoCodesError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                downloadLoader: false
            }
        };
    }),

    on(PromoCodesActions.UploadPromoCodes, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeUploadedData: {
                    loading: true,
                    data: null
                },
            }
        };
    }),

    on(PromoCodesActions.UploadPromoCodesSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeUploadedData: {
                    loading: false,
                    data: action.payload?.[0]
                },
            }
        };
    }),

    on(PromoCodesActions.UploadPromoCodesError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeUploadedData: {
                    loading: false,
                    data: state.promoCodesState?.promoCodeUploadedData?.data
                },
            }
        };
    }),

    on(PromoCodesActions.ClearUploadedFileData, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodeUploadedData: {
                    loading: false,
                    data: null
                },
            }
        };
    }),

    on(PromoCodesActions.ClonePromoCode, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                clonePromoCodeLoader: true,
            }
        };
    }),

    on(PromoCodesActions.ClonePromoCodeSuccess, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                clonePromoCodeLoader: false
            }
        };
    }),

    on(PromoCodesActions.ClonePromoCodeError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                clonePromoCodeLoader: false
            }
        };
    }),

    on(PromoCodesActions.UnsavedChanges, (state: CampaignsState, action) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                unsavedChanges: action.payload
            }
        };
    }),

    on(PromoCodesActions.CurrentPromoCodeState, (state: CampaignsState, action) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                currentPromoCodeState: action?.payload || null
            }
        };
    }),

    on(PromoCodesActions.UploadActivatePromoCodes, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                uploaderModalLoader: true
            }
        };
    }),

    on(PromoCodesActions.UploadActivatePromoCodesSuccess, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                uploaderModalLoader: false
            }
        };
    }),

    on(PromoCodesActions.UploadActivatePromoCodesError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                uploaderModalLoader: false
            }
        };
    }),

    on(PromoCodesActions.ChangePromoCodeStatus, (state: CampaignsState, action) => {
        if (!action.payload?.reloadPayload) {
            return {
                ...state,
                promoCodesState: {
                    ...state.promoCodesState,
                    selectedPromoCode: {
                        loading: true,
                        data: state.promoCodesState?.selectedPromoCode?.data
                    },
                }
            };
        }
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodesList: {
                    loading: true,
                    data: state.promoCodesState?.promoCodesList?.data
                },
            }
        };
    }),

    on(PromoCodesActions.ChangePromoCodeStatusError, (state: CampaignsState) => {
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                promoCodesList: {
                    loading: false,
                    data: state.promoCodesState?.promoCodesList?.data
                },
                selectedPromoCode: {
                    loading: false,
                    data: state.promoCodesState?.selectedPromoCode?.data
                },
            },
        };
    }),

    on(PromoCodesActions.GetSingleCopyIssues, (state: CampaignsState, action) => {
        const singleCopyIssuesState = Object.assign({}, state.promoCodesState?.singleCopyIssueList);
        if (singleCopyIssuesState[action.payload.productId]) {
            singleCopyIssuesState[action.payload.productId] = { ...singleCopyIssuesState[action.payload.productId], loading: true };
        } else {
            singleCopyIssuesState[action.payload.productId] = { loading: true };
        }
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                singleCopyIssueList: singleCopyIssuesState
            },
        };
    }),

    on(PromoCodesActions.GetSingleCopyIssuesSuccess, (state: CampaignsState, action) => {
        const singleCopyIssuesState = Object.assign({}, state.promoCodesState?.singleCopyIssueList);
        singleCopyIssuesState[action.payload.data?.productId] = {...action.payload?.data, loading: false };
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                singleCopyIssueList: singleCopyIssuesState
            },
        };
    }),

    on(PromoCodesActions.GetSingleCopyIssuesError, (state: CampaignsState, action) => {
        const singleCopyIssuesState = Object.assign({}, state.promoCodesState?.singleCopyIssueList);
        singleCopyIssuesState[action.payload.productId] = { loading: false };
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                singleCopyIssueList: singleCopyIssuesState
            },
        };
    }),

    on(PromoCodesActions.GetPriceChoices, (state: CampaignsState, action) => {
        const priceChoiceState = Object.assign({}, state.promoCodesState?.productPriceChoices);
        if (priceChoiceState[action.productId]) {
            priceChoiceState[action.productId] = [...priceChoiceState[action.productId]];
        } else {
            priceChoiceState[action.productId] = null;
        }
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                productPriceChoices: priceChoiceState
            }
        };
    }),

    on(PromoCodesActions.GetPriceChoicesSuccess, (state: CampaignsState, action) => {
        const priceChoiceState = Object.assign({}, state.promoCodesState?.productPriceChoices);
        priceChoiceState[action.productId] = [...action.payload];
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                productPriceChoices: priceChoiceState
            }
        };
    }),

    on(PromoCodesActions.GetPriceChoicesError, (state: CampaignsState, action) => {
        const priceChoiceState = Object.assign({}, state.promoCodesState?.productPriceChoices);
        priceChoiceState[action.payload.productId] = null;
        return {
            ...state,
            promoCodesState: {
                ...state.promoCodesState,
                productPriceChoices: priceChoiceState
            }
        };
    }),
];
