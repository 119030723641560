import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as LogosActions from './logos.actions';
import { LogoManagementTabs, LogoOptionBase } from './logos.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceLogosEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute
    ) { }

    updateLogosData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LogosActions.UpdateLogosData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                if (action.payload.field && action.payload.field != LogoManagementTabs.DEFAULT) {
                    searchParams.set('field', action.payload.field || '');
                }
                if (action.payload.fieldId) {
                    searchParams.set('fieldId', action.payload.fieldId || '');
                }
                const formData = new FormData();
                formData.append('imageFile', action.payload.file);
                return this.http
                    .post(environment.settingsApiEndPoint + `/logos?${searchParams}`, formData, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { url?: string, field?: string, messages?: string[] }) => {
                            const payload = {url: res?.messages?.[0] || '', field: action?.payload?.field || 'default'};

                            return LogosActions.UpdateLogosDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update logos.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(LogosActions.UpdateLogosDataError(error));
                        })
                    );
            })
        )
    );

    loadOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LogosActions.LoadOptions),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                let field = action.payload.field;
                if(action.payload.field == LogoManagementTabs.INVOICES) {
                    field = 'products';
                }

                return this.http
                    .get(this.baseUrl + `/${field}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: LogoOptionBase[]) => {
                            this.store.dispatch(LogosActions.LoadLogosData({ payload: { field: action.payload.field, fieldId: res[0].code } }));
                            return LogosActions.LoadOptionsSuccess({ payload: { options: res, field: action.payload.field} });
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to load ${action.payload.field}.`;
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        )
    );

    loadLogos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LogosActions.LoadLogosData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                if (action.payload.field && action.payload.field != LogoManagementTabs.DEFAULT) {
                    searchParams.set('field', action.payload.field || '');
                }
                if (action.payload.fieldId) {
                    searchParams.set('fieldId', action.payload.fieldId || '');
                }
                return this.http
                    .get(environment.settingsApiEndPoint + `/logos?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { url: string, message: string }) => {
                            const payload = {
                                url: res.url,
                                message: res.message,
                                field: action.payload.field,
                                fieldId: action.payload.fieldId,
                            };
                            return LogosActions.LoadLogosDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load logos.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        )
    );

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.settingsApiEndPoint + '/logos';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
