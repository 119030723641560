import { SelfServiceAlternateIdsEffects } from './alternate-ids/alternate-ids.effects';
import { SelfServiceApiKeysEffects } from './api-keys/api-keys.effects';
import { SelfServiceEffects } from './api.effects';
import { SelfServiceBehaviorsEffects } from './behaviors/behaviors.effects';
import { SelfServiceCalculatedDemographicsEffects } from './calculated-demographics/calculated-demographics.effects';
import { SelfServiceConsolidatedDemographicsEffects } from './consolidated-demographics/consolidated-demographics.effects';
import { SelfServiceDatabaseSettingsEffects } from './database-settings/database-settings.effects';
import { SelfServiceDemographicsEffects } from './demographics/demographics.effects';
import { SelfServiceDeploymentProductsEffects } from './deployment-products/deployment-products.effects';
import { SelfServiceDeploymentTypeEffects } from './deployment-types/deployment-types.effects';
import { SelfServiceLogosEffects } from './logos/logos.effects';
import { SelfServiceMessageTypesEffects } from './message-types/message-types.effects';
import { SelfServiceProductsEffects } from './products/products.effects';
import { SelfServiceProfilesEffects } from './profiles/profiles.effects';
import { SelfServiceProductNoticeEffects } from './product-notice/product-notice.effects';
import { SelfServicePaymentGatewaysEffects } from './payment-gateways/payment-gateways.effects';
import { SelfServiceSkuManagementEffects } from './sku-management/sku-management.effects';

// put all effect files related to SelfService here
// so we can easily import them in the main module
export const SelfServiceCommonEffects = [
    SelfServiceEffects,
    SelfServiceDatabaseSettingsEffects,
    SelfServiceDemographicsEffects,
    SelfServiceProductNoticeEffects,
    SelfServiceBehaviorsEffects,
    SelfServiceDeploymentTypeEffects,
    SelfServiceDeploymentProductsEffects,
    SelfServiceMessageTypesEffects,
    SelfServiceProductsEffects,
    SelfServiceProfilesEffects,
    SelfServiceApiKeysEffects,
    SelfServiceAlternateIdsEffects,
    SelfServiceLogosEffects,
    SelfServiceCalculatedDemographicsEffects,
    SelfServiceConsolidatedDemographicsEffects,
    SelfServicePaymentGatewaysEffects,
    SelfServiceSkuManagementEffects,
];
