import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as LogosActions from './logos.actions';

export const selfServiceLogosReducers = [
    on(LogosActions.LoadLogosData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                logosData: {
                    data: { ...state?.selfServiceLogosState?.logosData.data },
                    loading: true
                }
            }
        };
    }),
    on(LogosActions.LoadLogosDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                logosData: {
                    loading: false,
                    data: {
                        ...state.selfServiceLogosState.logosData.data,
                        [action.payload.field]: {
                            url: action.payload.url,
                            message: action.payload.message
                        }
                    }
                }
            }
        };
    }),
    on(LogosActions.LoadOptions, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                options: {
                    loading: true,
                    data: { ...state.selfServiceLogosState.options.data }
                }
            }
        };
    }),
    on(LogosActions.LoadOptionsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                options: {
                    loading: false,
                    data: {
                        ...state.selfServiceLogosState.options.data,
                        [action.payload.field]: action.payload.options
                    }
                }
            }
        };
    }),
    on(LogosActions.UpdateLogosData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                logosData: {
                    ...state.selfServiceLogosState.logosData,
                    loading: true
                }
            }
        };
    }),

    on(LogosActions.UpdateLogosDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                logosData: {
                    loading: false,
                    data: {
                        ...state.selfServiceLogosState.logosData.data,
                        [action.payload.field]: {
                            url: action.payload.url,
                            message: ''
                        },
                    }
                }
            }
        };
    }),

    on(LogosActions.UpdateLogosDataError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceLogosState: {
                ...state.selfServiceLogosState,
                logosData: {
                    ...state.selfServiceLogosState.logosData,
                    loading: false
                }
            }
        };
    })
];
