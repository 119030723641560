import { CustomerDataPlatformEffects } from './api.effects';
import { CustomerDataPlatformFormListenersEffects } from './form-listeners/form-listeners.effects';
import { CustomerDataPlatformMetersEffects } from './meters/meters.effects';
import { CustomerDataPlatformOlyticsEffects } from './olytics/olytics.effects';
import { CustomerDataPlatformPersonalizationsEffects } from './personalizations/personalizations.effects';

export const CustomerDataPlatformCommonEffects = [
    CustomerDataPlatformEffects,
    CustomerDataPlatformPersonalizationsEffects,
    CustomerDataPlatformMetersEffects,
    CustomerDataPlatformOlyticsEffects,
    CustomerDataPlatformFormListenersEffects
];
