import { on } from '@ngrx/store';
import * as IntegrationsDeltaFtpActions from './delta-ftp.actions';
import { IntegrationsState } from '../interfaces';
import { DeltaFtpState } from './delta-ftp.interface';

export const initialDeltaFtpState: DeltaFtpState = {
    deltaJobs: {
        loading: false,
        data: null
    },
    deltaJobDetails: {
        loading: false,
        data: null
    },
    fileFormatData: {
        loading: false,
        data: null
    },
    availableFileFields: {
        loading: false,
        data: null
    },
    mappedFields: {
        loading: false,
        data: null
    },
    currentJobDetailsState: null,
    unsavedFtpChanges: false,
    deltaFtpProductList: {
        loading: false,
        data: null
    }
};

export const IntegrationsDeltaFtpReducer = [
    on(IntegrationsDeltaFtpActions.GetDeltaJobs, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobs: {
                    loading: true,
                    data: state.deltaFtp?.deltaJobs?.data
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.GetDeltaJobsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobs: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.GetDeltaJobsError, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobs: {
                    loading: false,
                    data: state.deltaFtp?.deltaJobs?.data
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.GetDeltaJobDetails, (state: IntegrationsState, action) => {
        if (action.payload?.reloadProductsTable) {
            return {
                ...state,
                deltaFtp: {
                    ...state?.deltaFtp,
                    deltaFtpProductList: {
                        loading: true,
                        data: state.deltaFtp?.deltaJobDetails?.data?.extractProductList
                    },
                    ftpFileErrorMessage: state.deltaFtp?.deltaJobDetails?.data?.pageLoadFileGroupErrorMessage
                }
            };
        }
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: true,
                    data: state.deltaFtp?.deltaJobDetails?.data
                },
                currentJobDetailsState: state.deltaFtp?.deltaJobDetails?.data,
                deltaFtpProductList: {
                    loading: true,
                    data: state.deltaFtp?.deltaJobDetails?.data?.extractProductList
                },
                ftpFileErrorMessage: state.deltaFtp?.deltaJobDetails?.data?.pageLoadFileGroupErrorMessage
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetDeltaJobDetailsSuccess, (state: IntegrationsState, action) => {
        if (action.payload?.data?.reloadProductsTable) {
            return {
                ...state,
                deltaFtp: {
                    ...state?.deltaFtp,
                    deltaFtpProductList: {
                        loading: false,
                        data: action.payload?.data?.extractProductList
                    },
                    ftpFileErrorMessage: action.payload?.data?.pageLoadFileGroupErrorMessage
                }
            };
        }
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: false,
                    data: action.payload?.data
                },
                currentJobDetailsState: action.payload?.data,
                deltaFtpProductList: {
                    loading: false,
                    data: action.payload?.data?.extractProductList
                },
                ftpFileErrorMessage: action.payload?.data?.pageLoadFileGroupErrorMessage
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetDeltaJobDetailsError, (state: IntegrationsState, action) => {
        if (action?.reloadProductsTable) {
            return {
                ...state,
                deltaFtp: {
                    ...state?.deltaFtp,
                    deltaFtpProductList: {
                        loading: false,
                        data: state.deltaFtp?.deltaJobDetails?.data?.extractProductList
                    },
                    ftpFileErrorMessage: state.deltaFtp?.deltaJobDetails?.data?.pageLoadFileGroupErrorMessage
                }
            };
        }
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: false,
                    data: state.deltaFtp?.deltaJobDetails?.data
                },
                currentJobDetailsState: state.deltaFtp?.deltaJobDetails?.data,
                deltaFtpProductList: {
                    loading: false,
                    data: state.deltaFtp?.deltaJobDetails?.data?.extractProductList
                },
                ftpFileErrorMessage: state.deltaFtp?.deltaJobDetails?.data?.pageLoadFileGroupErrorMessage
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetFileFormatData, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                fileFormatData: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.GetFileFormatDataSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                fileFormatData: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.GetFileFormatDataError, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                fileFormatData: {
                    loading: false,
                    data: state.deltaFtp?.fileFormatData?.data
                }
            }
        };
    }),
    on(IntegrationsDeltaFtpActions.SaveJobDetails, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: true,
                    data: state.deltaFtp?.deltaJobDetails?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.SaveJobDetailsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: false,
                    data: { ...state.deltaFtp?.deltaJobDetails?.data, ...action.payload?.data }
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.SaveJobDetailsError, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                deltaJobDetails: {
                    loading: false,
                    data: state.deltaFtp?.deltaJobDetails?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetAvailableFileFields, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                availableFileFields: {
                    loading: true,
                    data: state.deltaFtp?.availableFileFields?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetAvailableFileFieldsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                availableFileFields: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.GetAvailableFileFieldsError, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                availableFileFields: {
                    loading: false,
                    data: state.deltaFtp?.availableFileFields?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.UpdateCurrentDeltaFtpJobState, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                currentJobDetailsState: {...state.deltaFtp?.currentJobDetailsState, ...action.payload }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.ViewMappedFields, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                mappedFields: {
                    loading: true,
                    data: state.deltaFtp?.mappedFields?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.ViewMappedFieldsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                mappedFields: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.ViewMappedFieldsError, (state: IntegrationsState) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                mappedFields: {
                    loading: false,
                    data: state.deltaFtp?.mappedFields?.data
                }
            }
        };
    }),

    on(IntegrationsDeltaFtpActions.UnsavedFtpChange, (state: IntegrationsState, action) => {
        return {
            ...state,
            deltaFtp: {
                ...state?.deltaFtp,
                unsavedFtpChanges: action.payload
            }
        };
    })
];
