import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { forkJoin, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeleteManageUsers, DeleteManageUsersError, DeleteManageUsersSuccess, GetManageUsers, GetManageUsersSuccess, UpdateManageUsers, UpdateManageUsersSuccess } from './actions';
import { ManageUsers, UserStatus } from './interfaces';

@Injectable({ providedIn: 'root' })

export class ManageUsersEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private location: Location,
        private shellStore: ShellStore,
    ) { }

    getManageUsersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetManageUsers),
            debounceTime(300),
            switchMap((action) => {
                const url = new URL(environment.apiEndPoint + '/user/all-profiles');
                url.searchParams.append('environmentId', this.currentDatabase?.id);
                url.searchParams.append('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                url.searchParams.append('numResults', action.payload.numResults?.toString() || '15');
                url.searchParams.append('searchText', action.payload.searchString || '');
                url.searchParams.append('offset', action.payload.offset < 0 ? '0' : (action.payload.offset?.toString() || '0'));
                url.searchParams.append('page', action.payload.page?.toString() || '1');
                url.searchParams.append('sortBy', action.payload.sortField?.toString() || '');
                url.searchParams.append('order', action.payload.order?.toString() || '');
                url.searchParams.append('status', action.payload.status?.toString() || '');

                return this.http.get(url.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: { totalResultCount: number, items: Array<ManageUsers> }) => {
                        const payload: ResponseWrapper<{ items: Array<ManageUsers>, totalItems: number }> = { loading: false, data: { items: res.items || [], totalItems: res.totalResultCount } };
                        return GetManageUsersSuccess({ payload });
                    })
                );
            })
        )
    );

    updateManageUsersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateManageUsers),
            mergeMap((action) => {
                const observables = action.payload.userId?.map((userId) => {
                    return this.http.post(environment.apiEndPoint + '/user/status', { userId, status: action.payload.status }, { withCredentials: true });
                });
                return forkJoin(observables).pipe(
                    take(1),
                    map((res: Array<UserStatus>) => {
                        this.store.dispatch(ShowToast({
                            payload: {
                                title: '',
                                message: 'The user\'s status was successfully updated!',
                                design: 'Success',
                                placement: 'TopEnd',
                                icon: 'fa-circle-check-solid'
                            }
                        }));
                        return UpdateManageUsersSuccess({ payload: res[0] });
                    }),
                    catchError((err) => {
                        this.store.dispatch(ShowToast({
                            payload: {
                                title: '',
                                message: 'An error has occurred while trying to update the user\'s status!',
                                design: 'Error',
                                placement: 'TopEnd',
                                icon: 'fa-circle-exclamation-solid'
                            }
                        }));
                        return of(UpdateManageUsersSuccess({ payload: err }));
                    })
                );
            })
        )
    );
    deleteManageUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteManageUsers),
            mergeMap((action) => {
                return this.http.post(environment.apiEndPoint + '/user/status', action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: UserStatus) => {
                        this.store.dispatch(ShowToast({
                            payload: {
                                title: '',
                                message: 'The user was successfully removed.',
                                design: 'Success',
                                placement: 'TopEnd',
                                icon: 'fa-circle-check-solid'
                            }
                        }));
                        return DeleteManageUsersSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to delete a user.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(DeleteManageUsersError(error));
                    })
                );
            })
        )
    );

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(take(1), map(database => res = database)).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(take(1), map(user => res = user)).subscribe();
        return res;
    }
}
