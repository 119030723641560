import { on } from '@ngrx/store';
import { CustomerDataPlatformState } from '.././interfaces';
import * as CdpFormListenersActions from './form-listeners.actions';
import { FormListenerConstantValue, FormListenerDetails, FormListenersResponse } from './form-listeners.interface';

export const formListenersReducers = [
    on(CdpFormListenersActions.LoadFormListeners, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenersData: {
                    loading: true,
                    data: state.formListenersState.formListenersData?.data ? { ...state.formListenersState.formListenersData?.data } : null
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadFormListenersSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenersData: {
                    loading: false,
                    data: action.payload as FormListenersResponse
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadFormListenersError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenersData: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadFormListenerDetails, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerDetails: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadFormListenerDetailsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerDetails: {
                    loading: false,
                    data: action.payload as FormListenerDetails
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadFormListenerDetailsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerDetails: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.UpdateFormListenerStatusSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerDetails: {
                    loading: false,
                    data: {
                        ...state.formListenersState.formListenerDetails?.data,
                        status: action.payload
                    }
                }
            }
        };
    }),
    on(CdpFormListenersActions.GetConstantValues, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.GetConstantValuesSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
    on(CdpFormListenersActions.UpdateConstantValue, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    ...state.formListenersState.constantValues,
                    loading: true,
                }
            }
        };
    }),
    on(CdpFormListenersActions.UpdateConstantValueError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    ...state.formListenersState.constantValues,
                    loading: false,
                }
            }
        };
    }),
    on(CdpFormListenersActions.UpdateConstantValueSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    loading: false,
                    data: state.formListenersState.constantValues?.data.map((constant: FormListenerConstantValue) => {
                        if (constant.applicationStringId === action.payload.applicationStringId) {
                            return action.payload;
                        }
                        return constant;
                    })
                }
            }
        };
    }),
    on(CdpFormListenersActions.DeleteConstantValue, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    ...state.formListenersState.constantValues,
                    loading: true,
                }
            }
        };
    }),
    on(CdpFormListenersActions.DeleteConstantValueSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                constantValues: {
                    loading: false,
                    data: state.formListenersState.constantValues?.data.filter((constant: FormListenerConstantValue) => constant.applicationStringId !== action.payload.applicationStringId)
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadRecentData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                recentData: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadRecentDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                recentData: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
    on(CdpFormListenersActions.GenerateTestLink, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                generatedTestLink: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.GenerateTestLinkSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                generatedTestLink: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
    on(CdpFormListenersActions.ClearGeneratedLinkState, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                generatedTestLink: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadReportDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerReport: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
    on(CdpFormListenersActions.LoadReportData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                formListenerReport: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.GetIgnoredFields, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                ignoredFields: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(CdpFormListenersActions.GetIgnoredFieldsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formListenersState: {
                ...state.formListenersState,
                ignoredFields: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
];
