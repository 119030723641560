import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ListSchema, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, debounceTime, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsActions from '@integrations/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { GetAdrollSites } from '@integrations/state/adroll-event-based/adroll-event-based.actions';
import { GetMetaEventsSites } from '@integrations/state/meta-events-manager/meta-events-manager.actions';
import { CodeNameI, FacebookAudienceI, QuerySearchListResponse } from '@integrations/state/interfaces';

@Injectable({ providedIn: 'root' })
export class IntegrationsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
        private casHttp: CasHttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }

    //COMMON
    searchQueries$ = createEffect(() =>
        this.actions$.pipe(
            debounceTime(300),
            ofType(IntegrationsActions.SearchQueries),
            switchMap((action) => {
                //TODO Loop through action payload
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('allDataViews', 'true');
                searchParams.set('profileId', action.payload.profileId ? action.payload.profileId : '');
                searchParams.set('createdBy', action.payload.createdBy ? action.payload.createdBy : '');
                searchParams.set('keyword', action.payload.keyword ? action.payload.keyword : '');
                searchParams.set('lastUpdated', action.payload.lastUpdated ? action.payload.lastUpdated : '');
                searchParams.set('lockedQueriesOnly', action.payload.lockedQueriesOnly ? action.payload.lockedQueriesOnly?.toString() : '');
                searchParams.set('name', action.payload.name ? action.payload.name : '');
                searchParams.set('nameSearchType', action.payload.nameSearchType ? action.payload.nameSearchType : '');
                searchParams.set('numResults', action.payload.numResults ? action.payload.numResults?.toString() : '');
                searchParams.set('olyticsQueriesOnly', action.payload.olyticsQueriesOnly ? action.payload.olyticsQueriesOnly?.toString() : '');
                searchParams.set('offset', action.payload.offset ? action.payload.offset?.toString() : '');
                searchParams.set('sortBy', action.payload.sortBy ? action.payload.sortBy : '');
                searchParams.set('order', action.payload.order ? action.payload.order : '');
                searchParams.set('mode', action.payload.mode ? action.payload.mode : '');
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/search?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: QuerySearchListResponse) => {
                        const payload: ResponseWrapper<QuerySearchListResponse> = { loading: false, data: res };
                        return IntegrationsActions.SearchQueriesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(IntegrationsActions.SearchQueriesError(error));
                    })
                );
            })
        )
    );

    getRecentAudiences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsActions.GetRecentAudiences),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload.searchText ? searchParams.set('searchText', action.payload.searchText) : null;
                action.payload.numResults ? searchParams.set('numResults', action.payload.numResults.toString()) : null;
                action.payload.offset ? searchParams.set('offset', action.payload.offset.toString()) : null;
                action.payload.sortBy ? searchParams.set('sortBy', action.payload.sortBy) : null;
                action.payload.order ? searchParams.set('order', action.payload.order) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/integration/facebook-ad-manager/recent-audience?${searchParams}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: ListSchema<FacebookAudienceI>) => {
                            const payload: ResponseWrapper<ListSchema<FacebookAudienceI>> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsActions.GetRecentAudiencesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get recent audiences.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsActions.GetRecentAudiencesError(error));
                        })
                    );
            })
        )
    );

    deleteSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsActions.DeleteSite),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/site/delete?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            if (action.payload?.isAdroll) {
                                this.store.dispatch(GetAdrollSites({ payload: { offset: 0, numResults: 15 } }));
                            } else {
                                this.store.dispatch(GetMetaEventsSites({ payload: { offset: 0, numResult: 15 } }));
                            }
                            return IntegrationsActions.DeleteSiteSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to delete site.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsActions.DeleteSiteError(error));
                        })
                    );
            })
        )
    );

    getMinimumDurationView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsActions.GetPageMinimumViewDuration),
            exhaustMap(() => {
                return this.http.get(
                    environment.campaignApiEndPoint +
                    '/integration/facebook-event-manager/minimum-page-view-duration',
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: CodeNameI[]) => {
                            const payload: ResponseWrapper<CodeNameI[]> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsActions.GetPageMinimumViewDurationSuccess({ payload });
                        }),
                        catchError((error) => {
                            return of(IntegrationsActions.GetPageMinimumViewDurationError(error));
                        })
                    );
            })
        )
    );
}
