import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DatabaseSettingsActions from './database-settings.actions';
import { CountryItem, SaveDatabaseDataPayload } from './database-settings.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceDatabaseSettingsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute
    ) { }

    loadDatabaseLanding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatabaseSettingsActions.LoadDatabaseSettingsData),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/database/dashboard?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SaveDatabaseDataPayload) => {
                            return DatabaseSettingsActions.LoadDatabaseSettingsDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load database settings data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DatabaseSettingsActions.LoadDatabaseSettingsDataError(error));
                        })
                    );
            })
        )
    );

    saveDatabaseSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatabaseSettingsActions.SaveDatabaseSettings),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/database/dashboard/save?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SaveDatabaseDataPayload) => {
                            this.showSuccess('Success', 'Database settings saved successfully.');
                            return DatabaseSettingsActions.SaveDatabaseSettingsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save database settings data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DatabaseSettingsActions.SaveDatabaseSettingsError(error));
                        })
                    );
            })
        )
    );

    getCountryList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatabaseSettingsActions.GetCountryList),
            exhaustMap(() => {
                return this.http
                    .get(environment.apiEndPoint + '/portal/country-map/', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CountryItem>) => {
                            return DatabaseSettingsActions.GetCountryListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get country list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DatabaseSettingsActions.GetCountryListError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
