import { ResponseWrapper } from 'common';

export const databaseSettingsInitialState: DatabaseSettingsState = {
    databaseSettingsData: {
        loading: false,
        data: null
    },
    countryListData: {
        loading: false,
        data: []
    }
};

export interface DatabaseSettingsState {
    databaseSettingsData: ResponseWrapper<SaveDatabaseDataPayload>;
    countryListData: ResponseWrapper<Array<CountryItem>>;
}

export interface SaveDatabaseDataPayload {
    street: string,
    city: string,
    postalCode: string,
    state: string,
    country: string,
    dataProtectionRepresentative: string,
    mfaRequired: boolean,
    eventMailingList?: string,
}

export interface CountryItem {
    description?: string,
    value?: string,
}