import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BasicResponse, getCurrentUser, ListSchema, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import * as IntegrationsAdrollActions from './adroll.actions';
import { AdrollAccessInfo, AdrollAudience, AdrollAuthorization } from './adroll.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsAdrollEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore
    ) { }

    getAdrollAudiences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.GetAdrollRecentAudiences),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload.name ? searchParams.set('name', action.payload.name) : null;
                action.payload.numResults ? searchParams.set('numResults', action.payload.numResults.toString()) : null;
                action.payload.offset ? searchParams.set('offset', action.payload.offset.toString()) : null;
                action.payload.sortBy ? searchParams.set('sortBy', action.payload.sortBy) : null;
                action.payload.order ? searchParams.set('order', action.payload.order) : null;
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-push/search?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: ListSchema<AdrollAudience>) => {
                            const payload: ResponseWrapper<ListSchema<AdrollAudience>> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsAdrollActions.GetAdrollRecentAudiencesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get Adroll audiences.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollActions.GetAdrollRecentAudiencesError(error));
                        })
                    );
            })
        )
    );

    pushAudienceToAdroll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.PushAudienceToAdroll),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll-push/send');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                    refreshToken: action.payload.refreshToken,
                    accessToken: action.payload.accessToken
                }).toString();
                const payload = {
                    selectionCriteriaId: action.payload.selectionCriteriaId,
                    advertiserId: action.payload.advertiserId,
                    audienceName: action.payload.audienceName,
                    refreshAbAudienceBeforeSending: action.payload.refreshAbAudienceBeforeSending,
                };
                return this.http.post(url.toString(), payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return IntegrationsAdrollActions.PushAudienceToAdrollSuccess({ payload: payload });
                    }
                    ),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to push Adroll Audiences.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollActions.PushAudienceToAdrollError(error));
                    }));
            })
        )
    );

    getAdrollAuthInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.GetAdrollAuthInfo),
            exhaustMap(() => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll/auth-url');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                }).toString();
                const params = {
                    odyssey: false,
                    redirectUrl: window.location.href
                };

                return this.http.post(url.toString(), params, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AdrollAuthorization) => {
                        const payload: ResponseWrapper<AdrollAuthorization> = { loading: false, data: res };
                        return IntegrationsAdrollActions.GetAdrollAuthInfoSuccess({ payload: payload });
                    }
                    ),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get Adroll Auth Info.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollActions.GetAdrollAuthInfoError(error));
                    }));
            })
        )
    );

    getAdrollAccessToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.GetAdrollAccessToken),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll/access-token-from-code');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                    code: action.payload.code
                }).toString();
                return this.http.get(url.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: AdrollAccessInfo) => {
                        if (res.errorMessage) {
                            throw new Error(res.errorMessage);
                        }
                        localStorage.setItem('adroll_access_object', JSON.stringify(res));
                        const payload: ResponseWrapper<AdrollAccessInfo> = { loading: false, data: res };
                        this.store.dispatch(IntegrationsAdrollActions.GetAdvertiserAccounts({ payload: { accessToken: res.accessToken } }));
                        return IntegrationsAdrollActions.GetAdrollAccessTokenSuccess({ payload: payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        error.message || 'An error has occurred while trying to get Adroll Access Token.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of();
                    }));
            })
        )
    );

    getAdvertiserAccounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.GetAdvertiserAccounts),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('accessToken', action.payload?.accessToken);
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll/advertiser-accounts?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: Array<{ advertiserId: string, description: string }>) => {
                            if (res.length === 0 && action.payload?.refreshToken?.length > 0) {
                                throw new Error();
                            }
                            const payload: ResponseWrapper<{ advertiserId: string, description: string }[]> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsAdrollActions.GetAdvertiserAccountsSuccess({ payload });
                        }),
                        catchError((error) => {
                            if (action.payload?.refreshToken?.length > 0) {
                                return of(IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshToken({ payload: { refreshToken: action.payload?.refreshToken } }));
                            }
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to view performance.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollActions.GetAdvertiserAccountsError(error));
                        })
                    );
            })
        )
    );

    getAdrollAcessTokenFromRefreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshToken),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll/access-token-from-refresh-token');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                    refreshToken: action.payload.refreshToken
                }).toString();
                return this.http.get(url.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: AdrollAccessInfo) => {
                        if (res.errorMessage) {
                            localStorage.removeItem('adroll_access_object');
                            return IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshTokenError(null);
                        }
                        const payload: ResponseWrapper<AdrollAccessInfo> = { loading: false, data: res };
                        this.store.dispatch(IntegrationsAdrollActions.GetAdvertiserAccounts({ payload: { accessToken: res.accessToken } }));
                        return IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshTokenSuccess({ payload: payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        error.message || 'An error has occurred while trying to get Adroll Access Token.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshTokenError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
