import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    BasicResponse,
    getCurrentUser,
    ModalService,
    ResponseWrapper,
    ShellStore,
    ShowToast
} from 'common';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    ApproveDeployment,
    ApproveDeploymentError,
    ApproveDeploymentSuccess,
    CancelAllDeployments,
    CancelAllDeploymentsError,
    CancelAllDeploymentsSuccess,
    CancelDeployment,
    CancelDeploymentError,
    CancelDeploymentSuccess,
    CloneDeployment,
    CloneDeploymentError,
    CloneDeploymentSuccess,
    CommonHttpErrorResponse,
    DeactivateDeployment,
    DeactivateDeploymentError,
    DeactivateDeploymentSuccess,
    DeleteDeployment,
    DeleteDeploymentError,
    DeleteDeploymentSuccess,
    DeleteMessage,
    DeleteMessageError,
    DeleteMessageSuccess,
    GetActiveDeployments,
    GetActiveDeploymentsError,
    GetActiveDeploymentsSuccess,
    GetAudienceBuilderQueries,
    GetAudienceBuilderQueriesError,
    GetAudienceBuilderQueriesSuccess,
    GetAvailableUrls,
    GetAvailableUrlsError,
    GetAvailableUrlsSuccess,
    GetDeploymentHomeMeta,
    GetDeploymentHomeMetaSuccess,
    GetExternalUrlDeployment,
    GetExternalUrlDeploymentError,
    GetExternalUrlDeploymentSuccess,
    GetInboxMessages,
    GetInboxMessagesError,
    GetInboxMessagesSuccess,
    GetRawMessage,
    GetRawMessageError,
    GetRawMessageSuccess,
    GetRecurringDeploymentDetails,
    GetRecurringDeploymentDetailsError,
    GetRecurringDeploymentDetailsSuccess,
    GetRegularDeploymentDetails,
    GetRegularDeploymentDetailsError,
    GetRegularDeploymentDetailsSuccess,
    GetReportUrl,
    GetReportUrlError,
    GetReportUrlSuccess,
    GetSupplementalDataDeployment,
    GetSupplementalDataDeploymentError,
    GetSupplementalDataDeploymentSuccess,
    MoveMessage,
    MoveMessageError,
    MoveMessageSuccess,
    ProcessComplaint,
    ProcessComplaintError,
    ProcessComplaintSuccess,
    ReplaceAvailableUrls,
    ReplaceAvailableUrlsError,
    ReplaceAvailableUrlsSuccess,
    RetryRegularSearchDeployment,
    RetryRegularSearchDeploymentError,
    RetryRegularSearchDeploymentSuccess,
    SendExternalUrl,
    SendExternalUrlError,
    SendExternalUrlSuccess,
    SendMessage,
    SendMessageError,
    SendMessageSuccess,
    SendQuickReportToDeployment,
    SendQuickReportToDeploymentError,
    SendQuickReportToDeploymentSuccess,
    UnscheduleAllDeployments,
    UnscheduleAllDeploymentsError,
    UnscheduleAllDeploymentsSuccess,
    UnscheduleDeployment,
    UnscheduleDeploymentError,
    UnscheduleDeploymentSuccess,
    UnsubscribeMessage,
    UnsubscribeMessageError,
    UnsubscribeMessageSuccess,
    UpdateDeployment,
    UpdateDeploymentError,
    UpdateDeploymentSuccess
} from './actions';
import {
    ABQuery,
    ActiveDeploymentsData,
    Deployment,
    DeploymentDetails,
    DeploymentHomeMeta, InboxFolder,
    NewBasicResponse,
    ReccuringDepl,
    SupplementalDataDeployment
} from './interfaces';
import { ReusableModal } from '../main/modals/reusable.modal';

@Injectable({ providedIn: 'root' })
export class EmailBuilderEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private location: Location,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) {}

    // Ready HTTP Effects
    getActiveDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetActiveDeployments),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            `/email-builder/deployments/all?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`,
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: ActiveDeploymentsData) => {
                            const payload: ResponseWrapper<ActiveDeploymentsData> = {
                                loading: false,
                                data: res,
                            };
                            return GetActiveDeploymentsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get active deployments.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetActiveDeploymentsError(error));
                        })
                    );
            })
        )
    );

    updateDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateDeployment),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload?.data?.databaseId || this.currentDatabase.id;
                return this.http
                    .post(
                        environment.apiEndPoint +
                            `/email-builder/deployment?id=${action.payload?.data?.id}&environmentId=${currentDatabaseId}&type=${action.payload.type}`,
                        action.payload?.data,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: Deployment) => {
                            const payload: ResponseWrapper<Deployment> = {
                                loading: false,
                                data: res,
                            };
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        message: 'Deployment updated.',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                    },
                                })
                            );
                            return UpdateDeploymentSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to update deployment.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(UpdateDeploymentError(error));
                        })
                    );
            })
        )
    );

    getDeploymentHomeMeta$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDeploymentHomeMeta),
            exhaustMap(() => {
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/deployments/meta?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res) => {
                            const payload: ResponseWrapper<DeploymentHomeMeta> =
                                { loading: false, data: res };
                            return GetDeploymentHomeMetaSuccess({ payload });
                        })
                    );
            }),
            catchError((error) => {
                this.store.dispatch(
                    ShowToast({
                        payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message:
                                'An error has occurred while trying to get deployments.',
                            icon: 'fa-circle-exclamation-solid',
                        },
                    })
                );
                return of(CommonHttpErrorResponse(error));
            })
        )
    );
    deleteDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteDeployment),
            exhaustMap((action) => {
                const databaseId = action.payload?.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/delete?id=${action.payload.deploymentId}&environmentId=${databaseId}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            return DeleteDeploymentSuccess({ payload });
                        })
                    );
            }),
            catchError((error) => {
                const errorMessage =
                    error?.error?.errors && error?.error?.errors?.length > 0
                        ? error?.error?.errors.join('\n').replaceAll(':', '\n')
                        : 'An error has occurred while trying to delete deployment.';
                this.store.dispatch(
                    ShowToast({
                        payload: {
                            title: 'Error',
                            design: 'Error',
                            placement: 'TopEnd',
                            message: errorMessage,
                            icon: 'fa-circle-exclamation-solid',
                        },
                    })
                );
                return of(DeleteDeploymentError(error));
            })
        )
    );

    getInboxMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetInboxMessages),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            '/email-builder/inbox/messages',
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: InboxFolder[]) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        message:
                                            'Messages successfully loaded.',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                    },
                                })
                            );
                            const payload: ResponseWrapper<Array<InboxFolder>> =
                                { loading: false, data: res };
                            return GetInboxMessagesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get inbox messages.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetInboxMessagesError(error));
                        })
                    );
            })
        )
    );
    deleteMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteMessage),
            exhaustMap((action) => {
                return this.http
                    .delete(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message?id=' +
                            action.payload.id,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: InboxFolder[]) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        message:
                                            'Messages deleted successfully.',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                    },
                                })
                            );
                            const payload: ResponseWrapper<Array<InboxFolder>> =
                                { loading: false, data: res };
                            return DeleteMessageSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to delete a message.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(DeleteMessageError(error));
                        })
                    );
            })
        )
    );

    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SendMessage),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message/send',
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        message: 'Messages sent successfully.',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                    },
                                })
                            );
                            return SendMessageSuccess();
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to send a message.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(SendMessageError(error));
                        })
                    );
            })
        )
    );

    moveMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MoveMessage),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message/move?id=' +
                            action.payload.id,
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: InboxFolder[]) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        message: 'Message moved successfully.',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                    },
                                })
                            );
                            const payload: ResponseWrapper<Array<InboxFolder>> =
                                { loading: false, data: res };
                            return MoveMessageSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to move a message.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(MoveMessageError(error));
                        })
                    );
            })
        )
    );

    getRawMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRawMessage),
            exhaustMap((action) => {
                return this.http
                    .get(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message/raw?id=' +
                            action.payload.id,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: string) => {
                            return GetRawMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get a raw message.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetRawMessageError(error));
                        })
                    );
            })
        )
    );
    unsubscribeMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UnsubscribeMessage),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message/unsubscribe?id=' +
                            action.payload.id,
                        action.payload.data,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Success',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                        message: 'Unsubscribed successfully!',
                                    },
                                })
                            );
                            return UnsubscribeMessageSuccess();
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get a raw message.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(UnsubscribeMessageError(error));
                        })
                    );
            })
        )
    );

    processComplaint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessComplaint),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            '/email-builder/inbox/message/complaint?id=' +
                            action.payload.id,
                        action.payload.data,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Success',
                                        design: 'Success',
                                        placement: 'TopEnd',
                                        message:
                                            'А complaint processed successfully!',
                                    },
                                })
                            );
                            return ProcessComplaintSuccess();
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to process a complaint.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(ProcessComplaintError(error));
                        })
                    );
            })
        )
    );

    approveDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApproveDeployment),
            exhaustMap((action) => {
                const databaseId = action.payload?.databaseId || this.currentDatabase.id;
                const payload = { ...action?.payload };
                delete payload?.databaseId;
                const url = new URL(environment.apiEndPoint + `/email-builder/deployment/approve?environmentId=${databaseId}`);
                return this.http
                    .post(url.toString(), payload)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.success && res?.message) {
                                this.showSuccess(null, res?.message);
                            } else if (res && !res?.success && res?.warningList) {
                                const titleMap = {
                                    'depl': 'Approve Deployment',
                                    'reschedule': 'Reschedule Deployment'
                                };
                                const optionForConfirmationMap = {
                                    'depl': { modifiedConfirmed: true },
                                    'reschedule': { warningConfirmed: true },
                                };
                                const reusableModal = this.modalService.createModal(
                                    ReusableModal,
                                    { draggable: true, width: '100%', maxWidth: '656px' },
                                    {
                                        title: titleMap[action?.payload?.approve] ? titleMap[action?.payload?.approve] : 'Action Needed',
                                        description: res?.warningList,
                                        needsConfirmation: true,
                                    });
                                reusableModal.open();
                                reusableModal.onClose.pipe(
                                    take(1),
                                    map((val) => {
                                        if (val) {
                                            this.store.dispatch(ApproveDeployment({ payload: { ...action?.payload, ...optionForConfirmationMap[action?.payload?.approve] } }));
                                        }
                                    })
                                ).subscribe();
                            } else if (res && !res?.success && res?.errorList) {
                                this.showError(null, res?.errorList[0]);
                            }
                            return ApproveDeploymentSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to execute action on deployment.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ApproveDeploymentError(error));
                        })
                    );
            })
        )
    );

    cancelDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CancelDeployment),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/cancel?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:
                                                res?.messages +
                                                '\n(' +
                                                res?.errors[0] +
                                                ')',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            return CancelDeploymentSuccess({ payload });
                        }),
                        catchError((error) => {
                            const errorMessage =
                                error?.error?.errors &&
                                error?.error?.errors?.length > 0
                                    ? error?.error?.errors
                                        .join('\n')
                                        .replaceAll(':', '\n')
                                    : 'An error has occurred while trying to cancel deployment.';
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: errorMessage,
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(CancelDeploymentError(error));
                        })
                    );
            })
        )
    );

    cloneDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CloneDeployment),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            `/email-builder/deployment/clone?id=${action.payload}&environmentId=${this.currentDatabase.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return CloneDeploymentSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to load recipients.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(CloneDeploymentError(error));
                        })
                    );
            })
        )
    );

    getABQueries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAudienceBuilderQueries),
            exhaustMap((action) => {
                return this.http
                    .post(
                        environment.apiEndPoint +
                            `/email-builder/audience/queries?environmentId=${this.currentDatabase.id}`,
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: ABQuery[]) => {
                            const payload: ResponseWrapper<Array<ABQuery>> = {
                                loading: false,
                                data: res,
                            };
                            return GetAudienceBuilderQueriesSuccess({
                                payload: payload,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to load recipients.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetAudienceBuilderQueriesError(error));
                        })
                    );
            })
        )
    );

    getAvailableUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAvailableUrls),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/link-urls?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: DeploymentDetails[]) => {
                            const payload: ResponseWrapper<
                            Array<DeploymentDetails>
                            > = { loading: false, data: res };
                            return GetAvailableUrlsSuccess({
                                payload: payload,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to load recipients.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetAvailableUrlsError(error));
                        })
                    );
            })
        )
    );

    replaceAvailableUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReplaceAvailableUrls),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/replace-urls?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        action.payload.availableUrls,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.messages && res?.messages.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Success',
                                            design: 'Success',
                                            placement: 'TopEnd',
                                            message: res?.messages[0],
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            }
                            return ReplaceAvailableUrlsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save Urls.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: errorMessage,
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(ReplaceAvailableUrlsError(error));
                        })
                    );
            })
        )
    );

    getRegularDeploymentDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRegularDeploymentDetails),
            mergeMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/regular/deployment/details?environmentId=${currentDatabaseId}&id=${action?.payload?.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: DeploymentDetails) => {
                            return GetRegularDeploymentDetailsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((err) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: err?.error.errors[0],
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(
                                GetRegularDeploymentDetailsError({
                                    payload: {
                                        error: err,
                                        id: action?.payload?.id,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    getRecurringDeploymentDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRecurringDeploymentDetails),
            mergeMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/recurring/deployment/details?environmentId=${currentDatabaseId}&id=${action?.payload?.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: ReccuringDepl) => {
                            return GetRecurringDeploymentDetailsSuccess({
                                payload: { ...res, id: action?.payload?.id },
                            });
                        }),
                        catchError((err) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: err?.error.errors[0],
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(
                                GetRecurringDeploymentDetailsError({
                                    payload: {
                                        error: err,
                                        id: action?.payload?.id,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    deactivateDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeactivateDeployment),
            exhaustMap((action) => {
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/deactivate?environmentId=${this.currentDatabase.id}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:
                                                res?.messages +
                                                '\n(' +
                                                res?.errors[0] +
                                                ')',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            return DeactivateDeploymentSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to deactivate deployment.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(DeactivateDeploymentError(error));
                        })
                    );
            })
        )
    );

    unscheduleDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UnscheduleDeployment),
            exhaustMap((action) => {
                const databaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/unschedule?environmentId=${databaseId}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:
                                                res?.messages +
                                                '\n(' +
                                                res?.errors[0] +
                                                ')',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            } else if (res?.messages?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Success',
                                            design: 'Success',
                                            placement: 'TopEnd',
                                            message: res?.messages[0],
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            }
                            return UnscheduleDeploymentSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to unschedule deployment.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(UnscheduleDeploymentError(error));
                        })
                    );
            })
        )
    );

    unscheduleAllDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UnscheduleAllDeployments),
            exhaustMap((action) => {
                const deployments = action.payload.id.join(',');
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/unschedule-all?environmentId=${this.currentDatabase.id}`,
                        { deployments: deployments },
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:
                                                res?.messages +
                                                '\n(' +
                                                res?.errors[0] +
                                                ')',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            } else if (res?.messages?.length > 0 && res?.success) {
                                this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res?.messages[0], icon: 'fa-circle-check-solid' } }));
                            }
                            return UnscheduleAllDeploymentsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to unschedule deployments.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(UnscheduleAllDeploymentsError(error));
                        })
                    );
            })
        )
    );

    cancelAllDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CancelAllDeployments),
            exhaustMap((action) => {
                const deployments = action.payload.id.join(',');
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/${action.payload.type}/cancel-all?environmentId=${this.currentDatabase.id}`,
                        { deployments: deployments },
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message:
                                                res?.messages +
                                                '\n(' +
                                                res?.errors[0] +
                                                ')',
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            } else {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Success',
                                            design: 'Success',
                                            placement: 'TopEnd',
                                            message: res?.messages[0],
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            }
                            return CancelAllDeploymentsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to cancel deployments.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(CancelAllDeploymentsError(error));
                        })
                    );
            })
        )
    );

    retryRegularSearchDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RetryRegularSearchDeployment),
            exhaustMap((action) => {
                const databaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/retry?environmentId=${databaseId}&id=${action.payload.id}`,
                        {
                            step: action.payload.data.step,
                            subDomains: action.payload.data.subDomains,
                        },
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: NewBasicResponse) => {
                            if (res?.messages?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message: res?.messages?.errors[0],
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            return RetryRegularSearchDeploymentSuccess({
                                payload: {
                                    data: res,
                                    deploymentId: action.payload.id,
                                },
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get regular search dropdowns.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(RetryRegularSearchDeploymentError(error));
                        })
                    );
            })
        )
    );

    getExternalUrlDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetExternalUrlDeployment),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/external-report-url?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: { externalUrl?: string }) => {
                            return GetExternalUrlDeploymentSuccess({
                                payload: res.externalUrl,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get regular search dropdowns.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetExternalUrlDeploymentError(error));
                        })
                    );
            })
        )
    );

    sendExternalUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SendExternalUrl),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                const payload = { emails: action.payload.emails || '' };
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/send-external-report?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        map((res: any) => {
                            if (res.success) {
                                const messages = JSON.parse(res.messages);
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Success',
                                            design: 'Success',
                                            placement: 'TopEnd',
                                            message: messages.message,
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            } else {
                                if (res?.messages?.errors?.length > 0) {
                                    this.store.dispatch(
                                        ShowToast({
                                            payload: {
                                                title: 'Error',
                                                design: 'Error',
                                                placement: 'TopEnd',
                                                message:
                                                    res?.messages?.errors[0],
                                                icon: 'fa-circle-exclamation-solid',
                                            },
                                        })
                                    );
                                }
                            }
                            return SendExternalUrlSuccess({
                                payload: res.externalUrl,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to send external url.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(SendExternalUrlError(error));
                        })
                    );
            })
        )
    );

    getSupplementalDataDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetSupplementalDataDeployment),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .get(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/supplemental-data?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: SupplementalDataDeployment | NewBasicResponse) => {
                            if ('messages' in res && 'errors' in res?.messages && res?.messages?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message: res?.messages?.errors[0],
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            }
                            return GetSupplementalDataDeploymentSuccess({
                                payload: res as SupplementalDataDeployment,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get regular search dropdowns.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(
                                GetSupplementalDataDeploymentError(error)
                            );
                        })
                    );
            })
        )
    );

    sendReportToDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SendQuickReportToDeployment),
            exhaustMap((action) => {
                const currentDatabaseId = action.payload.databaseId || this.currentDatabase.id;
                return this.http
                    .put(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/send-quick-report?environmentId=${currentDatabaseId}&id=${action.payload.id}`,
                        action.payload.data,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Error',
                                            design: 'Error',
                                            placement: 'TopEnd',
                                            message: res?.errors[0],
                                            icon: 'fa-circle-exclamation-solid',
                                        },
                                    })
                                );
                            } else if (res?.success) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: 'Success',
                                            design: 'Success',
                                            placement: 'TopEnd',
                                            message: res?.messages[0],
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            }
                            return SendQuickReportToDeploymentSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to send quick report',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(SendQuickReportToDeploymentError(error));
                        })
                    );
            })
        )
    );

    getReportUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReportUrl),
            exhaustMap((action) => {
                const deployments = action.payload.deployments.join('|');
                return this.http
                    .post(
                        environment.apiEndPoint +
                            `/email-builder/deployment/regular/deployment-reports?orgId=${action.payload.orgId}`,
                        { deployments, action: action.payload.action },
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success) {
                                window.open(res.messages[0], `${res.messages[0]}`);
                            }
                            return GetReportUrlSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get view report url.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(
                                GetReportUrlError(error)
                            );
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
