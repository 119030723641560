import { on } from '@ngrx/store';
import * as IntegrationsBlueconicActions from './blueconic.actions';
import { IntegrationsState } from '../interfaces';

export const initialBlueconicState = {
    blueconicData: {
        loading: false,
        data: null,
    },
};

export const IntegrationsBlueconicReducer = [
    on(IntegrationsBlueconicActions.GetBlueconicData, (state: IntegrationsState) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: true,
                data: state?.blueconic?.blueconicData?.data || null,
            },
        }
    })),
    on(IntegrationsBlueconicActions.GetBlueconicDataSuccess, (state: IntegrationsState, action) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: false,
                data: action.payload.data,
            },
        }
    })),
    on(IntegrationsBlueconicActions.GetBlueconicDataError, (state: IntegrationsState) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: false,
                data: state?.blueconic?.blueconicData?.data || null,
            },
        }
    })),

    on(IntegrationsBlueconicActions.SaveBlueconicData, (state: IntegrationsState) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: true,
                data: state?.blueconic?.blueconicData?.data || null,
            },
        }
    })),
    on(IntegrationsBlueconicActions.SaveBlueconicDataSuccess, (state: IntegrationsState, action) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: false,
                data: action.payload.data,
            },
        }
    })),
    on(IntegrationsBlueconicActions.SaveBlueconicDataError, (state: IntegrationsState) => ({
        ...state,
        blueconic: {
            blueconicData: {
                loading: false,
                data: state?.blueconic?.blueconicData?.data || null,
            },
        }
    })),
];
