import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as PaymentGatewaysActions from './payment-gateways.actions';

export const paymentGatewaysReducer = [
    on(PaymentGatewaysActions.LoadPaymentGatewaysData, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accounts: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(PaymentGatewaysActions.LoadPaymentGatewaysDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accounts: {
                    data: {
                        ...action.payload.data,
                        items: action.payload.data?.items?.map((item) => {
                            return {
                                ...item,
                                inProgress: item.status === 'In Progress'
                            };
                        }) || []
                    },
                    loading: false
                }
            }
        };
    }),
    on(PaymentGatewaysActions.CreatePaymentGateway, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: true
            }
        };
    }),
    on(PaymentGatewaysActions.CreatePaymentGatewaySuccess, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: false,
            }
        };
    }),

    on(PaymentGatewaysActions.CreatePaymentGatewayError, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: false
            }
        };
    }),

    on(PaymentGatewaysActions.DeletePaymentGateway, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accounts: {
                    ...state.paymentGatewaysState.accounts,
                    loading: true
                }
            }
        };
    }),
    on(PaymentGatewaysActions.ContinueOnboarding, (state: SelfServiceState, action) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accountsLoadingState: {
                    ...state.paymentGatewaysState.accountsLoadingState,
                    [action.payload.accountId]: true
                }
            }
        };
    }),
    on(PaymentGatewaysActions.SetAccountLoadingFalse, (state: SelfServiceState, action) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accountsLoadingState: {
                    ...state.paymentGatewaysState.accountsLoadingState,
                    [action.payload.accountId]: false
                }
            }
        };
    }),
    on(PaymentGatewaysActions.OpenReport, (state: SelfServiceState, action) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                accountsLoadingState: {
                    ...state.paymentGatewaysState.accountsLoadingState,
                    [action.payload.accountId]: true
                }
            }
        };
    }),
    on(PaymentGatewaysActions.GetPaymentCredential, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentCredentials: null,
                paymentGatewayLoader: true
            }
        };
    }),

    on(PaymentGatewaysActions.GetPaymentCredentialSuccess, (state: SelfServiceState, action) => {
        let paymentCredentials = null;

        if (action.payload) {
            paymentCredentials = {
                databaseId: action.payload.databaseId,
                paymentCredentialsId: action.payload.paymentCredentialsId,
                productId: action.payload.productId || null,
                productSpecific: action.payload.productId ? true : false
            };
        }

        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentCredentials: paymentCredentials,
                paymentGatewayLoader: false
            }
        };
    }),

    on(PaymentGatewaysActions.GetPaymentCredentialError, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentCredentials: null,
                paymentGatewayLoader: false
            }
        };
    }),

    on(PaymentGatewaysActions.UpdatePaymentCredentials, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: true
            }
        };
    }),

    on(PaymentGatewaysActions.UpdatePaymentCredentialsSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: false
            }
        };
    }),

    on(PaymentGatewaysActions.UpdatePaymentCredentialsError, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentGatewayLoader: false
            }
        };
    }),

    on(PaymentGatewaysActions.ClearPaymentCredentials, (state: SelfServiceState) => {
        return {
            ...state,
            paymentGatewaysState: {
                ...state.paymentGatewaysState,
                paymentCredentials: null
            }
        };
    })
];
