import { ResponseWrapper } from 'common';

export const calculatedDemographicsState: CalculatedDemographicsState = {
    calculatedDemographicsData: {
        loading: false,
        data: null
    },
    calculatedDemographicsDownloadLoader: false,
    selectedCalculatedDemographic: {
        loading: false,
        data: null
    },
    sourceDemographics: {
        loading: false,
        data: null
    }
};

export interface CalculatedDemographicsState {
    calculatedDemographicsData: ResponseWrapper<DemographicsListRes>;
    calculatedDemographicsDownloadLoader: boolean;
    selectedCalculatedDemographic: ResponseWrapper<SelectedDemographicRes>;
    sourceDemographics: ResponseWrapper<SourceDemographicsRes>;
}

export interface DemographicsListRes {
    totalResultCount: number;
    items: Array<DemographicData>;
}

export interface DemographicData {
    demographicId: number,
    environmentId: number,
    type: number,
    name: string,
    shortDescription?: string,
    usageType?: number,
    auditLocked?: boolean,
    calcField?: boolean,
    updateableWhileClosed?: boolean,
    unitField?: boolean,
    passAlong?: boolean,
    aggregateData?: boolean,
    isAudited?: boolean,
    createdDate?: string,
    createdBy?: string,
    changedDate?: string,
    changedBy?: string,
    updateCounter?: number,
    status?: number,
    captureDemoHistory?: boolean,
    replaceRuleCode?: number,
    jobTitleDemo?: boolean,
    omailRuleDemo?: boolean,
    consolidated?: boolean,
    webformText?: string,
    demographicValues?: Array<DemographicValue>
}

export interface SelectedDemographicRes {
    success?: boolean,
    demographicId?: number,
    message?: string,
    demographic?: DemographicData
}

export interface DemographicValue {
    shortDescription?: string,
    valueId?: number,
    valueText?: string,
    valueType?: number,
    status?: number,
    sequence?: number,
    legacyValueId?: string,
    alternateId?: string,
    webformText?: string,
    dragonHide?: number,
    delete?: boolean
}

export interface SaveCalculatedDemographicPayload {
    demographicId?: number,
    demographicName?: string,
    description?: string,
    webformText?: string,
    status?: number,
    type?: number,
    storeDemographicHistory?: boolean,
    profiles?: Array<number>,
    allProfiles?: boolean,
    selectedCalcForSingleResponse?: number,
    selectedCalcValues?: Array<number>,
    commingleDemographicValue?: number,
    demographicValues?: Array<{
        valueText?: string,
        webformText?: string,
        alternateId?: number,
        status?: number,
        sequence?: number,
        valueId?: number,
        oec?: boolean,
        delete?: boolean,
    }>;
}

export interface SourceDemographicsRes {
    totalResultCount?: number,
    items?: Array<SourceDemographicsItem>,
}

export interface SourceDemographicsItem {
    id?: number,
    name?: string,
    type?: string,
}