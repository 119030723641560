import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DemographicsActions from './consolidated-demographics.actions';
import { DemographicsListRes, SelectedDemographicRes } from './consolidated-demographics.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceConsolidatedDemographicsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadConsolidatedDemographicsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.LoadConsolidatedDemographicsData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || 'false');
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/consolidated?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicsListRes) => {
                            const payload: ResponseWrapper<DemographicsListRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.LoadConsolidatedDemographicsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load demographics data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (!action.payload?.singlePage) {
                                this.showError(null, errorMessage);
                            }
                            return of(DemographicsActions.LoadConsolidatedDemographicsDataError(error));
                        })
                    );
            })
        )
    );

    downloadConsolidatedDemographicList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.DownloadConsolidatedDemographicsList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/consolidated/export-dump?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const url = window.URL.createObjectURL(res);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = `consolidated_demographics_list_${this.currentDatabaseId}.xlsx`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return DemographicsActions.DownloadConsolidatedDemographicsListSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download demographics list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.DownloadConsolidatedDemographicsListError(error));
                        })
                    );
            })
        )
    );

    getConsolidatedDemographicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetConsolidatedDemographicData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/${action.payload.demographicId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDemographicRes) => {
                            const payload: ResponseWrapper<SelectedDemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetConsolidatedDemographicDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get demographic data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.GetConsolidatedDemographicDataError(error));
                        })
                    );
            })
        )
    );

    saveConsolidatedDemographic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.SaveConsolidatedDemographic),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/demographic/brand/consolidated?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess(null, `Consolidated Demographic has been ${action?.payload?.demographicId ? 'saved' : 'created'} successfully.`);
                            this.router.navigate(['/self-service/main/demographics/consolidated']);
                            return DemographicsActions.SaveConsolidatedDemographicSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to ${action?.payload?.demographicId ? 'save' : 'create'} Consolidated Demographic.`;
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.SaveConsolidatedDemographicError(error));
                        })
                    );
            })
        )
    );

    downloadAllMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.DownloadAllMapping),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/consolidated/download-mappings?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res) => {
                            const url = window.URL.createObjectURL(res);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = `consolidated_demographics_mapping_${this.currentDatabaseId}.csv`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download all mapping.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            }
            )
        ), { dispatch: false });

    uploadMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.UploadMapping),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('overwrite', action.payload.overwrite.toString());
                searchParams.set('addToOrOverwrite', 'true');
                const formData = new FormData();
                formData.append('dataFile', action.payload.file);
                return this.http
                    .post(environment.settingsApiEndPoint + `/demographic/brand/consolidated/upload-mappings?${searchParams}`, formData, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { errors: { lineNumber: string, message: string }[]}) => {
                            if(res.errors && res.errors.length) {
                                let errorMessage = 'An error has occurred while trying to upload mapping.\n';
                                res.errors.forEach(error => {
                                    errorMessage += `Line ${error.lineNumber}: ${error.message}\n`;
                                });
                                this.showError(null, errorMessage);
                                return of();
                            }
                            this.showSuccess(null, 'Mapping has been uploaded successfully.');
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload mapping.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        ), { dispatch: false });

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    showWarning(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: '',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
