import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ProductNoticeActions from './product-notice.actions';
import { NoticePanelsRes, PatternHistoryDataRes, PatternMatching, ProductNoticeCampaignPricingRes, ProductNoticeSetupsRes, SelectedProductNoticeRes } from './product-notice.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceProductNoticeEffects {
    constructor(private actions$: Actions, private store: Store, private http: HttpClient, private shellStore: ShellStore, private route: ActivatedRoute, private router: Router) {}

    loadProductNoticeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.LoadProductNoticeData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('noticeType', action.payload.noticeType?.toString() || '');
                searchParams.set('productId', action.payload.productId?.toString() || '');
                searchParams.set('automated', action.payload.automated?.toString() || 'all');

                return this.http
                    .get(environment.apiEndPoint + `/product-notices?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductNoticeSetupsRes) => {
                            const payload: ResponseWrapper<ProductNoticeSetupsRes> = {
                                data: res,
                                loading: false,
                            };
                            return ProductNoticeActions.LoadProductNoticeDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load product notice setups data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductNoticeActions.LoadProductNoticeDataError(error));
                        })
                    );
            })
        )
    );

    getProductNoticeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetProductNoticeData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.apiEndPoint + `/product-notices/${action.payload?.noticeSetupId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedProductNoticeRes) => {
                            const payload: ResponseWrapper<SelectedProductNoticeRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductNoticeActions.GetProductNoticeDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load product notice setup data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            this.router.navigate(['self-service/main/product-notice-setups']);
                            return of(ProductNoticeActions.GetProductNoticeDataError(error));
                        })
                    );
            })
        )
    );

    getNoticeTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetNoticeTypes),
            exhaustMap(() => {
                return this.http
                    .get(environment.apiEndPoint + '/product-notices/notice-types', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{code: number, name: string}>) => {
                            const payload: ResponseWrapper<Array<{code: number, name: string}>> = {
                                data: res,
                                loading: false
                            };
                            return ProductNoticeActions.GetNoticeTypesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load notice types.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductNoticeActions.GetNoticeTypesError(error));
                        })
                    );
            })
        )
    );

    getNoticeProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetNoticeProducts),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http.get(environment.apiEndPoint + `/product-notices/products?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: Array<{ code: number; name: string }>) => {
                        const payload: ResponseWrapper<Array<{ code: number; name: string }>> = {
                            data: res,
                            loading: false,
                        };
                        return ProductNoticeActions.GetNoticeProductsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load notice products.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProductNoticeActions.GetNoticeProductsError(error));
                    })
                );
            })
        )
    );

    getNoticePatternMatching$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetNoticePatternMatching),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('noticeSetupId', action.payload.noticeSetupId.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '10');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                return this.http
                    .get(environment.apiEndPoint + `/product-notices/${action.payload.noticeSetupId}/patterns?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res?: PatternMatching) => {
                            return ProductNoticeActions.GetNoticePatternMatchingSuccess({ payload: res || null });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load notice pattern matching data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductNoticeActions.GetNoticePatternMatchingError(error));
                        })
                    );
            })
        )
    );

    downloadPatternMatching$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.DownloadPatternMatching),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('noticeId', action.payload.noticeSetupId.toString());
                return this.http
                    .get(environment.apiEndPoint + `/product-notices/${action.payload.noticeSetupId}/patterns/download?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res) => {
                            const url = window.URL.createObjectURL(res);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = 'pattern-matching.csv';
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return ProductNoticeActions.DownloadPatternMatchingSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download notice pattern matching data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductNoticeActions.DownloadPatternMatchingError(error));
                        })
                    );
            })
        )
    );

    getProductNoticePanels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetProductNoticePanels),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('noticeSetupId', action.payload?.noticeSetupId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '10');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('scheduleGroupName', action.payload?.scheduleGroupName || '');
                return this.http.get(environment.apiEndPoint + `/product-notices/${action.payload?.noticeSetupId}/panels?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: NoticePanelsRes) => {
                        return ProductNoticeActions.GetProductNoticePanelsSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load notice panels.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProductNoticeActions.GetProductNoticePanelsError(error));
                    })
                );
            })
        )
    );

    downloadPanels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.DownloadPanels),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('noticeSetupId', action.payload.noticeSetupId.toString());
                return this.http
                    .get(environment.apiEndPoint + `/product-notices/${action.payload.noticeSetupId}/panels/download?${searchParams}`, { responseType: 'blob', withCredentials: true })
                    .pipe(
                        take(1),
                        map((res) => {
                            const url = window.URL.createObjectURL(res);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = 'panels.csv';
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return ProductNoticeActions.DownloadPanelsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download notice panels data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductNoticeActions.DownloadPanelsError(error));
                        })
                    );
            })
        )
    );

    getProductNoticeCampaignPrices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetProductNoticeCampaignPrices),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('numResults', action.payload.numResults?.toString() || '10');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.apiEndPoint + `/product-notices/${action.payload.noticeSetupId.toString()}/campaign-pricing?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: ProductNoticeCampaignPricingRes) => {
                        return ProductNoticeActions.GetProductNoticeCampaignPricesSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load notice campaign prices.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProductNoticeActions.GetProductNoticeCampaignPricesError(error));
                    })
                );
            })
        )
    );

    getPatternMatchingHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductNoticeActions.GetPatternHistory),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http.get(environment.apiEndPoint + `/product-notices/${action?.payload?.noticeSetupId}/patterns/history?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: PatternHistoryDataRes[]) => {
                        const payload = {
                            loading: false,
                            data: res,
                        };
                        return ProductNoticeActions.GetPatternHistorySuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load pattern matching history.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProductNoticeActions.GetPatternHistoryError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen,
                },
            })
        );
    }

    showWarning(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: '',
                    keepOpen,
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true,
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
