import { FormArray } from '@angular/forms';
import { ResponseWrapper } from 'common';
import { Observable } from 'rxjs';

export const profilesInitialState: ProfilesState = {
    profilesData: {
        loading: false,
        data: null
    },
    seachQueriesResults: {
        loading: false,
        data: null
    },
    selectedProfile: {
        loading: false,
        data: null
    },
    nameSpaces: {
        loading: false,
        data: null
    },
    contactRules: {
        loading: false,
        data: null
    },
    profileBehaviors: {
        loading: false,
        data: null
    },
    profileDemographics: {
        loading: false,
        data: null
    },
    profileProducts: {
        loading: false,
        data: null
    }
};

export interface ProfilesState {
    profilesData: ResponseWrapper<Product[]>;
    seachQueriesResults: ResponseWrapper<Array<CommonQuerySearchReq>>;
    selectedProfile: ResponseWrapper<SelectedProfileRes>;
    nameSpaces: ResponseWrapper<CommonReq>;
    contactRules: ResponseWrapper<CommonReq>;
    profileBehaviors: ResponseWrapper<CommonReq>;
    profileDemographics: ResponseWrapper<CommonReq>;
    profileProducts: ResponseWrapper<CommonReq>;
}

export interface ProfilesRes {
    totalResultCount: number;
    items: Array<Product>;
}

export interface Product {
    productName: string;
    productId: number;
    productTypeDesc: string;
    changedBy: string;
    changedDate: string;
    statusDesc: string;
    selected: boolean;
    disabled: boolean;
    userProductEditAuthorized: boolean;
}

export interface CommonQuerySearchReq {
    nameSearchType: string;
    name: string;
    keyword: string;
    createdBy: string;
    lastUpdated: string;
    profile: string;
    lockedQueriesOnly: boolean;
    olyticsQueriesOnly: boolean;
    dataViewId: string;
    numResults: number;
    profileId: string;
    offset: number;
    sortBy: string;
    order: string;
    status: string;
    resultsType: string;
    mode?: string;
}

export interface FieldAccessData {
    name: string,
    observable: Observable<unknown>,
    selectAllControl: string,
    formArray: string,
    formArrayControls: FormArray,
    filterInput: string,
    filterFunction?: () => void,
    filterPlaceholder: string,
    itemName: string,
    currentSelectedArray: Array<CommonReqItem>,
    itemId: string,
    offset?: number,
    currentPage?: number,
    numResult?: number
}

export interface SelectedProfileRes {
    dataViewId?: number,
    profileName?: string,
    dataWarehouse?: boolean,
    masterProfile?: boolean,
    query?: QueryData,
    selectedMembers?: SelectedMembersItem[],
    accessPermissions?: AccessPermissionsData,
    emailBuilderFreqRules?: EmailBuilderFreqRulesData,
    outputRules?: { ftpOutputForceDeploymentType?: boolean, ftpOutputApplyOptouts?: boolean },
    createdBy?: string,
    availableMembers?: SelectedMembersItem[],
    cloneFromDataViewId?: number,
    querySelection?: number,
}

export interface QueryData {
    selectionCriteriaId?: number,
    selectionCriteriaName?: string,
    locked?: boolean,
    count?: number,
}

export interface SelectedMembersItem {
    memberName?: string,
    output?: boolean,
    reports?: boolean,
    selected?: boolean,
}

export interface AccessPermissionsData {
    selectedProducts?: number[],
    products?: boolean,
    selectedContactRules?: number[],
    contactRules?: boolean,
    selectedNameSpaces?: number[],
    nameSpaces?: boolean,
    selectedDemographics?: number[],
    demographics?: boolean,
    selectedDeploymentTypes?: number[],
    deploymentTypes?: boolean,
    selectedBehaviors?: number[],
    behaviors?: boolean,
}

export interface EmailBuilderFreqRulesData {
    frequencyLimitRules?: boolean,
    inventoryCalendarRequired?: boolean,
    totalAllowedPerDay?: number,
    totalAllowedPerWeek?: number,
    limitAcrossAllDeplTypes?: boolean,
    limitAcrossSpecificGroupDeplTypes?: boolean,
    selectAllDeploymentTypes?: boolean,
    useRules?: boolean,
    deploymentTypeLimits?: DeploymentTypeLimitsItem[],
    selectedDeploymentTypes?: Record<number, number>,
}

export interface DeploymentTypeLimitsItem {
    deploymentTypeId?: number,
    deploymentTypeName?: string,
    dailyDeploymentLimit?: number,
    weeklyDeploymentLimit?: number,
}

export interface CommonReq {
    totalResultCount?: number,
    items?: CommonReqItem[],
}

export interface CommonReqItem {
    code?: number,
    name?: string,
}