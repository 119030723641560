import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DemographicsActions from './demographics.actions';
import { DataLoaderTemplatesRes, DeleteModalData, DemographicsListRes, SelectedDemographicRes } from './demographics.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceDemographicsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadDemographicsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.LoadDemographicsData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || 'false');
                searchParams.set('includeAll', action.payload.includeAll?.toString() || 'false');
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicsListRes) => {
                            const payload: ResponseWrapper<DemographicsListRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.LoadDemographicsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load demographics data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (!action.payload?.singlePage) {
                                this.showError(null, errorMessage);
                            }
                            return of(DemographicsActions.LoadDemographicsDataError(error));
                        })
                    );
            })
        )
    );

    downloadDemographicList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.DownloadDemographicsList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/export-dump?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const url = window.URL.createObjectURL(res);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = `demographics_list_${this.currentDatabaseId}.xlsx`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return DemographicsActions.DownloadDemographicsListSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download demographics list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.DownloadDemographicsListError(error));
                        })
                    );
            })
        )
    );

    getDemographicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetDemographicData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/${action.payload?.demographicId || ''}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDemographicRes) => {
                            const payload: ResponseWrapper<SelectedDemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetDemographicDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load demographic data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/demographics']);
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.GetDemographicDataError(error));
                        })
                    );
            })
        )
    );

    deleteDemographic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.DeleteDemographic),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('demographicId', action.payload.demographicId?.toString() || '');
                return this.http
                    .delete(environment.settingsApiEndPoint + `/demographic/brand/delete?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.payload?.demographicSearchPayload && !res.errors) {
                                this.store.dispatch(DemographicsActions.LoadDemographicsData({ payload: action.payload.demographicSearchPayload }));
                            }
                            if (res && res.errors && res.errors.length) {
                                this.showWarning(null, res.errors[0]);
                            }
                            if (!res.errors && !action.payload?.demographicSearchPayload) {
                                this.router.navigate(['/self-service/main/demographics']);
                            }
                            return DemographicsActions.DeleteDemographicSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete demographic.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.DeleteDemographicError(error));
                        })
                    );
            })
        )
    );

    saveDemographicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.SaveDemographicData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/demographic/brand/save?${searchParams}`, action.payload, { withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.messages?.length > 0 && !action.payload?.demographicId) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            message: res?.messages?.[0],
                                            design: '',
                                            placement: 'TopEnd',
                                        },
                                    })
                                );
                            }
                            this.router.navigate(['/self-service/main/demographics']);
                            return DemographicsActions.SaveDemographicDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save demographics data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.SaveDemographicDataError(error));
                        })
                    );
            })
        )
    );

    getDemographicDeleteModalData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetDemographicDeleteModalData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('demographicId', action.payload.demographicId?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/delete?${searchParams}`, {withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: DeleteModalData) => {
                            const payload: ResponseWrapper<DeleteModalData> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetDemographicDeleteModalDataSuccess({payload});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get delete data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.GetDemographicDeleteModalDataError(error));
                        })
                    );
            })
        )
    );

    getDataLoaderDemographicTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetDataLoaderDemographicTemplates),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.dataLoaderApiEndPoint + `/template/templates-using-demographic/${action.payload.demographicId?.toString()}?${searchParams}`, {withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: DataLoaderTemplatesRes) => {
                            const payload: ResponseWrapper<DataLoaderTemplatesRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetDataLoaderDemographicTemplatesSuccess({payload});
                        }),
                        catchError((error) => {
                            return of(DemographicsActions.GetDataLoaderDemographicTemplatesError(error));
                        })
                    );
            })
        )
    );

    checkDemographicName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.CheckDemographicName),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/exists?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res?.errors?.length > 0 && !res?.success) {
                                this.showError(null, res?.errors?.[0]);
                            }
                            return DemographicsActions.CheckDemographicNameSuccess({payload: res});
                        }),
                        catchError((error) => {
                            return of(DemographicsActions.CheckDemographicNameError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    showWarning(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: '',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
