import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { deploymentDefaultsReducer } from './deployment-defaults/deployment-defaults.reducer';
import { deploymentReducer } from './deployment/deployment.reducer';
import { IEmailBuilderState } from './interfaces';
import { optOutReducer } from './tools/opt-out/opt-out.reducer';
import { searchReducer } from './search/search.reducer';
import { toolsFilterReducer } from './tools/filter/filter.reducer';
import { calendarReducer } from './calendar/calendar.reducer';
import { summaryStatsReducer } from './summary-stats/summary-stats.reducer';
import { approvalQueueReducer } from './approval-queue/approval-queue.reducer';
import { ignoredIpsReducer } from './ignored-ips/ignored-ips.reducer';

const initalAppState: IEmailBuilderState = {
    currentDatabase: null,
    selectedDeployment: {
        data: null,
        loading: false
    },
    user: null,
    deploymentHomeItems: {
        loading: false,
        data: []
    },
    deploymentHomeMeta: {
        loading: false,
        data: null
    },
    inboxSelectedMessage: null,
    usersList: {
        loading: false,
        data: []
    },
    searchRegularDeploymentsResult: {
        loading: false,
        data: null
    },
    searchApprovalQueueDeploymentsResult: {
        loading: false,
        data: null
    },
    priorListSearchResults: {
        loading: false,
        data: {},
    },
    calendarWeekData: {
        loading: false,
        data: null
    },
    deploymentDefaults: {
        data: {
            orgDefaults: {
                loading: false,
                data: null
            },
            databaseDefaults: {
                loading: false,
                data: null
            },
            deploymentDefaults: {
                loading: false,
                data: null
            },
            contactList: {
                loading: false,
                data: [],
            },
            supressionFiles: {
                orgDefaults: {},
                databaseDefaults: {},
                deploymentDefaults: {}
            }
        }
    },
    toolsFilter: {
        loading: false,
        data: {
            filterCounts: {}
        }
    },
    approvalQueueSplitSpamReportResult: {
        loading: false,
        data: null
    },
    approvalQueueDateDropdownOptions: {
        loading: false,
        data: []
    },
    regularDeploymentsDetailsList: {},
};

function getInitalState(): IEmailBuilderState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initalAppState;
    // }
}

function getSavedState(): IEmailBuilderState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.UpdateDeploymentSuccess, (state, action) => {
        return {
            ...state,
            selectedDeployment: {
                data: action.payload?.data,
                loading: false
            },
        };
    }),
    on(AppActions.UpdateDeploymentError, (state) => {
        return {
            ...state,
            selectedDeployment: {
                loading: false
            },
        };
    }),
    on(AppActions.GetDeploymentHomeMeta, (state) => {
        return {
            ...state,
            deploymentHomeMeta: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetDeploymentHomeMetaSuccess, (state, action) => {
        return {
            ...state,
            deploymentHomeMeta: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetInboxMessages, (state) => {
        return {
            ...state,
            inboxMessages: {
                loading: true,
                data: []
            }
        };
    }),
    on(AppActions.GetInboxMessagesSuccess, (state, action) => {
        return {
            ...state,
            inboxMessages: {
                data: action.payload.data,
                loading: false
            },
        };
    }),
    on(AppActions.GetInboxMessagesError, (state) => {
        return {
            ...state,
            inboxMessages: {
                data: state.inboxMessages.data,
                loading: false
            },
        };
    }),
    on(AppActions.MoveMessageSuccess, (state, action) => {
        return {
            ...state,
            inboxMessages: {
                data: action.payload.data,
                loading: false
            },
        };
    }),
    on(AppActions.MoveMessageError, (state) => {
        return {
            ...state,
            inboxMessages: {
                data: state.inboxMessages.data,
                loading: false
            },
        };
    }),
    on(AppActions.GetRawMessageSuccess, (state, action) => {
        return {
            ...state,
            rawMessage: action.payload
        };
    }),
    on(AppActions.GetRawMessageError, (state) => {
        return {
            ...state,
            rawMessage: state.rawMessage,
        };
    }),
    on(AppActions.DeleteMessageSuccess, (state, action) => {
        return {
            ...state,
            inboxMessages: {
                data: action.payload.data,
                loading: false
            },
        };
    }),
    on(AppActions.DeleteMessageError, (state) => {
        return {
            ...state,
            inboxMessages: {
                data: state.inboxMessages.data,
                loading: false
            },
        };
    }),
    on(AppActions.SetInboxSelectedMessage, (state, action) => {
        return {
            ...state,
            inboxSelectedMessage: action.payload
        };
    }),
    on(AppActions.InboxNextMessage, (state) => {
        let nextItem;
        const currentItem = state.inboxSelectedMessage;
        let found = false;
        if (!currentItem) found = true;
        for (const folder of state.inboxMessages.data) {
            for (const message of folder.messages) {
                if (found) {
                    nextItem = message;
                    break;
                }
                if (message.id == currentItem.id) {
                    found = true;
                }
            }
            if (found && nextItem) break;
        }
        return {
            ...state,
            inboxSelectedMessage: nextItem
        };
    }),
    on(AppActions.InboxPreviousMessage, (state) => {
        let nextItem;
        const currentItem = state.inboxSelectedMessage;
        let found = false;
        for (const folder of state.inboxMessages.data) {
            for (const message of folder.messages) {
                if (found) {
                    nextItem = message;
                    break;
                }
                if (message.id == currentItem.id) {
                    found = true;
                }
            }
            if (found && nextItem) break;
        }
        return {
            ...state,
            inboxSelectedMessage: nextItem
        };
    }),
    on(AppActions.GetActiveDeployments, (state) => {
        return {
            ...state,
            activeDeployments: {
                loading: true,
                data: state.activeDeployments?.data
            }
        };
    }),
    on(AppActions.GetActiveDeploymentsSuccess, (state, action) => {
        return {
            ...state,
            activeDeployments: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetActiveDeploymentsError, (state) => {
        return {
            ...state,
            activeDeployments: {
                loading: false,
                data: state.activeDeployments?.data
            }
        };
    }),

    on(AppActions.ClearSelectedDeployment, (state) => {

        return {
            ...state,
            selectedDeployment: {
                data: null,
                loading: false,
            }
        };
    }),
    on(AppActions.GetAvailableUrls, (state) => {
        return {
            ...state,
            availableUrls: {
                loading: true,
                data: state.availableUrls?.data
            }
        };
    }),
    on(AppActions.GetAvailableUrlsSuccess, (state, action) => {
        return {
            ...state,
            availableUrls: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetAvailableUrlsError, (state) => {
        return {
            ...state,
            availableUrls: {
                loading: false,
                data: state.availableUrls?.data
            }
        };
    }),
    on(AppActions.ClearAvailableUrlsState, (state) => {
        return {
            ...state,
            availableUrls: {
                data: undefined
            }
        };
    }),
    on(AppActions.GetAudienceBuilderQueries, (state) => {
        return {
            ...state,
            audienceBuilderQueries: {
                loading: false,
                data: state.audienceBuilderQueries?.data
            }
        };
    }),
    on(AppActions.GetAudienceBuilderQueriesSuccess, (state, action) => {
        return {
            ...state,
            audienceBuilderQueries: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetAudienceBuilderQueriesError, (state) => {
        return {
            ...state,
            audienceBuilderQueries: {
                loading: false,
                data: state.audienceBuilderQueries?.data
            }
        };
    }),
    on(AppActions.GetRegularDeploymentDetails, (state, action) => {
        const deplDetailsList = Object.assign({}, state.regularDeploymentsDetailsList);

        if (deplDetailsList[action.payload.id]) {
            deplDetailsList[action.payload.id] = { ...deplDetailsList[action.payload.id], loading: true };
        } else {
            deplDetailsList[action.payload.id] = { loading: true };
        }

        return {
            ...state,
            regularDeploymentsDetailsList: deplDetailsList
        };
    }),
    on(AppActions.GetRegularDeploymentDetailsSuccess, (state, action) => {
        const deplDetailsList = Object.assign({}, state.regularDeploymentsDetailsList);
        deplDetailsList[action.payload.deplId] = {...action.payload, loading: false, };

        return {
            ...state,
            regularDeploymentsDetailsList: deplDetailsList
        };
    }),
    on(AppActions.GetRegularDeploymentDetailsError, (state, action) => {
        const deplDetailsList = Object.assign({}, state.regularDeploymentsDetailsList);
        deplDetailsList[action.payload.id] = { loading: false };
        return {
            ...state,
            regularDeploymentsDetailsList: deplDetailsList
        };
    }),
    on(AppActions.GetRecurringDeploymentDetails, (state, action) => {
        const deplDetailsList = Object.assign({}, state.recurringDeploymentsDetailsList);
        if(deplDetailsList[action.payload.id]) {
            deplDetailsList[action.payload.id] = { ...deplDetailsList[action.payload.id], loading: true };
        } else {
            deplDetailsList[action.payload.id] = { loading: true };
        }

        return {
            ...state,
            recurringDeploymentsDetailsList: deplDetailsList
        };
    }),
    on(AppActions.GetRecurringDeploymentDetailsSuccess, (state, action) => {
        const deplDetailsList = Object.assign({}, state.recurringDeploymentsDetailsList);
        deplDetailsList[action.payload.id] = { ...action.payload, loading: false };

        return {
            ...state,
            recurringDeploymentsDetailsList: deplDetailsList
        };
    }),
    on(AppActions.GetRecurringDeploymentDetailsError, (state) => {
        const deplDetailsList = Object.assign({}, state.recurringDeploymentsDetailsList);

        return {
            ...state,
            recurringDeploymentsDetailsList: deplDetailsList
        };
    }),

    on(AppActions.RetryRegularSearchDeploymentSuccess, (state, action) => {
        const deplDetailsList = JSON.parse(JSON.stringify(state.regularDeploymentsDetailsList));
        if(deplDetailsList[action.payload.deploymentId]) {
            deplDetailsList[action.payload.deploymentId] = { ...deplDetailsList[action.payload.deploymentId], retryData: action.payload.data };
        } else {
            deplDetailsList[action.payload.deploymentId] = { retryData: action.payload.data };
        }
        return {
            ...state,
            regularDeploymentsDetailsList: deplDetailsList
        };
    }),

    on(AppActions.GetSupplementalDataDeployment, (state) => {
        return {
            ...state,
            getSupplementalDataDeployment: {
                loading: true,
                data: state.getSupplementalDataDeployment?.data
            },
        };
    }),
    on(AppActions.GetSupplementalDataDeploymentSuccess, (state, action) => {
        return {
            ...state,
            getSupplementalDataDeployment: {
                loading: false,
                data: {
                    ...action.payload,
                    deplDeliveryThroughput: action.payload.deplDeliveryThroughput.filter((val) => Object.keys(val).length !== 0)
                }
            }
        };
    }),
    on(AppActions.GetSupplementalDataDeploymentError, (state) => {
        return {
            ...state,
            getSupplementalDataDeployment: {
                loading: false,
                data: state.getSupplementalDataDeployment?.data
            }
        };
    }),
    on(AppActions.GetExternalUrlDeploymentSuccess, (state, action) => {
        return {
            ...state,
            externalReportUrl: action.payload
        };
    }),
    on(AppActions.GetExternalUrlDeploymentError, (state) => {
        return {
            ...state,
            externalReportUrl: state.externalReportUrl
        };
    }),
    on(AppActions.ClearSupplementalDataDeploymentState, (state) => {
        return {
            ...state,
            getSupplementalDataDeployment: {
                data: null
            }
        };
    }),
    on(AppActions.DeleteDeployment, (state) => {
        return {
            ...state,
            deploymentDeleteStatus: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.DeleteDeploymentSuccess, (state, action) => {
        return {
            ...state,
            deploymentDeleteStatus: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.DeleteDeploymentError, (state) => {
        return {
            ...state,
            deploymentDeleteStatus: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.CancelDeployment, (state) => {
        return {
            ...state,
            deploymentCancelStatus: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.CancelDeploymentSuccess, (state, action) => {
        return {
            ...state,
            deploymentCancelStatus: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.CancelDeploymentError, (state) => {
        return {
            ...state,
            deploymentCancelStatus: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.DisableApprovalAvailability, (state, action) => {
        return {
            ...state,
            selectedDeployment: {
                loading: false,
                data: {
                    ...state.selectedDeployment.data,
                    deploymentSummary: {
                        ...state.selectedDeployment.data.deploymentSummary,
                        data: {
                            ...state.selectedDeployment.data.deploymentSummary.data,
                            recurringSettings: {
                                ...state.selectedDeployment.data.deploymentSummary.data.recurringSettings,
                                disableAutomaticApprovalAndScheduling: action.payload,
                            }
                        }
                    }
                }
            }
        };
    }),
    on(AppActions.AutomaticApprovalSelected, (state, action) => {
        return {
            ...state,
            selectedDeployment: {
                loading: false,
                data: {...state.selectedDeployment.data, automaticApprovalSelected: action.payload}
            }
        };
    }),
    ...deploymentReducer,
    ...deploymentDefaultsReducer,
    ...searchReducer,
    ...optOutReducer,
    ...toolsFilterReducer,
    ...calendarReducer,
    ...summaryStatsReducer,
    ...approvalQueueReducer,
    ...ignoredIpsReducer,
);

export function emailBuilderReducer(state: IEmailBuilderState | undefined, action: Action) {
    return appReducer(state, action);
}
