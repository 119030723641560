import * as IntegrationsShopifyActions from '@integrations/state/shopify/shopify.actions';
import { on } from '@ngrx/store';
import { IntegrationsState } from '@integrations/state/interfaces';
import { ShopifyState } from '@integrations/state/shopify/shopify.interface';
import { BasicResponse } from 'common';
import { HttpErrorResponse } from '@angular/common/http';

export const initialShopifyState: ShopifyState = {
    shopifyStores: { loading: false },
    shopifyStore: { loading: false },
    shopifyStoreSave: { loading: false },
    messageTypes: { loading: false },
    deploymentTypes: { loading: false },
    products: { loading: false },
    fileList: { loading: false },
    statuses: { loading: false },
    fileListCount: { loading: false },
    fileDetail: { loading: false },
    template: { loading: false }
};

function loadingState(state: IntegrationsState, key: string, loading = true) {
    return {
        ...state,
        shopify: { ...state.shopify, [key]: { loading } }
    };
}

function loadingCachedState(state: IntegrationsState, key: string) {
    return {
        ...state,
        shopify: { ...state.shopify, [key]: {
            loading: true,
            success: Boolean(state?.shopify?.[key]?.success),
            data: state?.shopify?.[key]?.data || null
        } }
    };
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function successState(state: IntegrationsState, key: string, data: any) {
    return {
        ...state,
        shopify: {
            ...state.shopify,
            [key]: { loading: false, success: true, data }
        }
    };
}

function errorState(state: IntegrationsState, key: string, errors?: BasicResponse | HttpErrorResponse) {
    return {
        ...state,
        shopify: {
            ...state.shopify,
            [key]: { loading: false, errors: errors != null ? errors : true }
        }
    };
}

export const IntegrationsShopifyReducer = [
    on(IntegrationsShopifyActions.GetShopifyStores, (state: IntegrationsState) => {
        return loadingState(state, 'shopifyStores');
    }),
    on(IntegrationsShopifyActions.GetShopifyStoresSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'shopifyStores', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetShopifyStoresError, (state: IntegrationsState) => {
        return errorState(state, 'shopifyStores');
    }),
    on(IntegrationsShopifyActions.GetShopifyStore, (state: IntegrationsState) => {
        return loadingState(state, 'shopifyStore');
    }),
    on(IntegrationsShopifyActions.GetShopifyStoreSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'shopifyStore', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetShopifyStoreError, (state: IntegrationsState) => {
        return errorState(state, 'shopifyStore');
    }),
    on(IntegrationsShopifyActions.SaveShopifyStore,
        IntegrationsShopifyActions.UpdateShopifyStoreStatus,
        (state: IntegrationsState) => {
            return loadingState(state, 'shopifyStoreSave');
        }),
    on(IntegrationsShopifyActions.SaveShopifyStoreSuccess,
        IntegrationsShopifyActions.UpdateShopifyStoreStatusSuccess,
        (state: IntegrationsState, action) => {
            return successState(state, 'shopifyStoreSave', action?.payload);
        }),
    on(IntegrationsShopifyActions.SaveShopifyStoreError,
        IntegrationsShopifyActions.UpdateShopifyStoreStatusError,
        (state: IntegrationsState) => {
            return errorState(state, 'shopifyStoreSave');
        }),
    on(IntegrationsShopifyActions.GetShopifyStoreByWebhookId, (state: IntegrationsState) => {
        return loadingState(state, 'shopifyStore');
    }),
    on(IntegrationsShopifyActions.GetShopifyStoreByWebhookIdSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'shopifyStore', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetShopifyStoreByWebhookIdError, (state: IntegrationsState) => {
        return errorState(state, 'shopifyStore');
    }),
    on(IntegrationsShopifyActions.ClearShopifyStoreValue, (state: IntegrationsState, action) => {
        return loadingState(state, action.storeValue, false);
    }),
    on(IntegrationsShopifyActions.GetMessageTypes, (state: IntegrationsState) => {
        return loadingCachedState(state, 'messageTypes');
    }),
    on(IntegrationsShopifyActions.GetMessageTypesSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'messageTypes', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetMessageTypesError, (state: IntegrationsState) => {
        return errorState(state, 'messageTypes');
    }),
    on(IntegrationsShopifyActions.GetDeploymentTypes, (state: IntegrationsState) => {
        return loadingCachedState(state, 'deploymentTypes');
    }),
    on(IntegrationsShopifyActions.GetDeploymentTypesSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'deploymentTypes', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetDeploymentTypesError, (state: IntegrationsState) => {
        return errorState(state, 'deploymentTypes');
    }),
    on(IntegrationsShopifyActions.GetProducts, (state: IntegrationsState) => {
        return loadingCachedState(state, 'products');
    }),
    on(IntegrationsShopifyActions.GetProductsSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'products', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetProductsError, (state: IntegrationsState) => {
        return errorState(state, 'products');
    }),
    on(IntegrationsShopifyActions.GetFileList, (state: IntegrationsState) => {
        return loadingState(state, 'fileList');
    }),
    on(IntegrationsShopifyActions.GetFileListSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'fileList', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetFileListError, (state: IntegrationsState) => {
        return errorState(state, 'fileList');
    }),
    on(IntegrationsShopifyActions.GetFileListCount, (state: IntegrationsState) => {
        return loadingState(state, 'fileListCount');
    }),
    on(IntegrationsShopifyActions.GetFileListCountSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'fileListCount', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetFileListCountError, (state: IntegrationsState) => {
        return errorState(state, 'fileListCount');
    }),
    on(IntegrationsShopifyActions.GetFileDetail, (state: IntegrationsState) => {
        return loadingState(state, 'fileDetail');
    }),
    on(IntegrationsShopifyActions.GetFileListDetailSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'fileDetail', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetFileListDetailError, (state: IntegrationsState) => {
        return errorState(state, 'fileDetail');
    }),
    on(IntegrationsShopifyActions.GetTemplate, (state: IntegrationsState) => {
        return loadingState(state, 'template');
    }),
    on(IntegrationsShopifyActions.GetTemplateSuccess, (state: IntegrationsState, action) => {
        return successState(state, 'template', action?.payload);
    }),
    on(IntegrationsShopifyActions.GetTemplateError, (state: IntegrationsState) => {
        return errorState(state, 'template');
    })
];
