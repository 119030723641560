<common-shell [class.d-none]="hideShell" [application]="application" [user]="user$ | ngrxPush"></common-shell>
<router-outlet></router-outlet>

<!-- <ui5-toast #toast></ui5-toast> -->
<div class="toasts-container">
    <div class="mt-2" *ngFor="let toast of toasts">
        <ui5-toast [open]="toast.open" bypass-positioning="true" [keepOpen]="toast.keepOpen" [title]="toast.title"
            [attr.design]="toast.design" [attr.duration]="toast.duration" placement="TopEnd"
            action="<ui5-button small>" slot="action" (onDismiss)="onToastDismiss(toast)"><div style="white-space: pre-line;">{{toast.message}}</div>
            <ui5-button *ngIf="toast.event && toast.event.actionText" small slot="action" design="Secondary" (click)="eventAction(toast)">{{toast?.event?.actionText}}</ui5-button>
            <ui5-button *ngIf="toast.redirection" small slot="action" design="Outlined" type="Secondary" (click)="redirect(toast)">Redirect</ui5-button>
        </ui5-toast>
    </div>
</div>
