import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getCurrentUser, ModalService, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangeIpStatus, ChangeIpStatusError, ChangeIpStatusSuccess, CreateOrUpdateIp, CreateOrUpdateIpError, CreateOrUpdateIpSuccess, GetIgnoredIps, GetIgnoredIpsError, GetIgnoredIpsSuccess } from './ignored-ips.actions';
import { IgnoredIps } from './ignored-ips.interface';

@Injectable({ providedIn: 'root' })
export class EmailBuilderIgnoredIpsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService,
        private route: ActivatedRoute
    ) { }

    getIgnoredIps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetIgnoredIps),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/ignored-ips?environmentId=${this.currentDatabase.id}&orgId=${action.payload.orgId}`);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<IgnoredIps>) => {
                            return GetIgnoredIpsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Ignored Ips list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetIgnoredIpsError(error));
                        })
                    );
            })
        )
    );

    createOrUpdateIp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateOrUpdateIp),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/ignored-ips?environmentId=${this.currentDatabase.id}&orgId=${action.payload.orgId}`);
                return this.http
                    .post(url.toString(), action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<IgnoredIps>) => {
                            return CreateOrUpdateIpSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create or update Ip.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CreateOrUpdateIpError(error));
                        })
                    );
            })
        )
    );

    changeIpStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeIpStatus),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/change-status?environmentId=${this.currentDatabase.id}&orgId=${action.payload.orgId}`);
                return this.http
                    .post(url.toString(), action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<IgnoredIps>) => {
                            return ChangeIpStatusSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to change Ip status.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ChangeIpStatusError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): number {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute;
        }
        return this.currentDatabase?.id;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
