import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as PaymentGatewayActions from './payment-gateways.actions';
import { PaymentCredentialRes, PaymentGatewayAccountRes } from './payment-gateways.interface';

@Injectable({ providedIn: 'root' })
export class SelfServicePaymentGatewaysEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    loadProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.LoadPaymentGatewaysData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.apiEndPoint + `/payment-gateways?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: PaymentGatewayAccountRes) => {
                            const payload: ResponseWrapper<PaymentGatewayAccountRes> = {
                                data: res,
                                loading: false
                            };
                            return PaymentGatewayActions.LoadPaymentGatewaysDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load PaymentGatewey accounts.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.LoadPaymentGatewaysDataError(error));
                        })
                    );
            })
        )
    );

    createPaymentGateway$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.CreatePaymentGateway),
            exhaustMap((action) => {
                const payload = {
                    databaseId: this.currentDatabaseId,
                    productId: action.payload.productId,
                    productSpecific: action.payload.productId ? true : false
                };

                return this.http
                    .post(environment.apiEndPoint + `/payment-gateways/create?environmentId=${this.currentDatabaseId}`, payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { accountId: string, onboardingUrl: string}) => {
                            this.showSuccess('Payment Gateway Account Created Successfully');
                            // navigate to the onboarding url in same page
                            if (action.modalId) {
                                this.modalService.getModalRef(action.modalId)?.close();
                            }

                            if (res.onboardingUrl) {
                                window.open(res.onboardingUrl).focus();
                            }
                            this.store.dispatch(PaymentGatewayActions.LoadPaymentGatewaysData({ payload: {} }));

                            return PaymentGatewayActions.CreatePaymentGatewaySuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create Payment Gateway account.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (action.modalId) {
                                this.modalService.getModalRef(action.modalId)?.close();
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.CreatePaymentGatewayError({payload: error}));
                        })
                    );
            })
        )
    );

    deletePaymentGateway$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.DeletePaymentGateway),
            exhaustMap((action) => {
                // build query params for envId and accountId
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('connectedAccountId', action.payload.accountId);

                return this.http
                    .delete(environment.apiEndPoint + `/payment-gateways/delete?${searchParams.toString()}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.showSuccess('Payment Gateway Account Deleted Successfully');
                            return PaymentGatewayActions.LoadPaymentGatewaysData({ payload: {} });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete Payment Gateway account.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        )
    );

    continueOnboarding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.ContinueOnboarding),
            exhaustMap((action) => {
                // build query params for envId and accountId
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('connectedAccountId', action.payload.accountId);

                return this.http
                    .get(environment.apiEndPoint + `/payment-gateways/continue?${searchParams.toString()}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const newWindow = window.open(res.messages[0]);
                            if (!newWindow || newWindow.closed || typeof newWindow.closed=='undefined') {
                                this.showError(
                                    null,
                                    `Unable to open Express Dashboard. Please enable the popups for this site or open ${res.messages[0]} in a new tab`,
                                    true
                                );
                            }
                            return PaymentGatewayActions.SetAccountLoadingFalse({
                                payload: {
                                    accountId: action.payload.accountId
                                }
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to continue onboarding.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.SetAccountLoadingFalse({
                                payload: {
                                    accountId: action.payload.accountId
                                }
                            }));
                        })
                    );
            })
        )
    );

    openReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.OpenReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('connectedAccountId', action.payload.accountId);
                return this.http
                    .get(environment.apiEndPoint + `/payment-gateways/login-link?${searchParams.toString()}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.messages) {
                                const newWindow = window.open(res.messages[0]);
                                if (!newWindow || newWindow.closed || typeof newWindow.closed=='undefined') {
                                    this.showError(
                                        null,
                                        `Unable to open external link. Please enable the popups for this site or open ${res.messages[0]} in a new tab`,
                                        true
                                    );
                                }
                            }
                            return PaymentGatewayActions.SetAccountLoadingFalse({
                                payload: {
                                    accountId: action.payload.accountId
                                }
                            });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to open express dashboard.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.SetAccountLoadingFalse({
                                payload: {
                                    accountId: action.payload.accountId
                                }
                            }));
                        })
                    );
            })
        )
    );

    updatePaymentCredentials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.UpdatePaymentCredentials),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('connectedAccountId', action.accountId);
                searchParams.set('paymentCredentialsId', action.paymentCredentialsId?.toString());

                const payload = {
                    databaseId: this.currentDatabaseId,
                    productId: action.payload.productId,
                    productSpecific: action.payload.productId ? true : false
                };

                return this.http
                    .put(environment.apiEndPoint + `/payment-gateways/credentials?${searchParams}`, payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess(res?.messages[0] || 'Gateway Credentials Updated Successfully');
                            if (action.modalId) {
                                this.modalService.getModalRef(action.modalId).close();
                            }
                            return PaymentGatewayActions.UpdatePaymentCredentialsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create Payment Gateway account.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (action.modalId) {
                                this.modalService.getModalRef(action.modalId)?.close();
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.UpdatePaymentCredentialsError(error));
                        })
                    );
            })
        )
    );

    getPaymentCredentials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentGatewayActions.GetPaymentCredential),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('connectedAccountId', action.payload.connectedAccountId);

                return this.http
                    .get(environment.apiEndPoint + `/payment-gateways/credentials?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<PaymentCredentialRes>) => {
                            if (action.modalId && !res[0]) {
                                this.modalService.getModalRef(action.modalId).close();
                                this.showError(null, 'No payment credentials found for this gateway.');
                            }
                            return PaymentGatewayActions.GetPaymentCredentialSuccess({payload: res[0] || null});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load PaymentGatewey accounts.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PaymentGatewayActions.GetPaymentCredentialError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
