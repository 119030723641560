import { Action, createReducer, on } from '@ngrx/store';
import { GetProducts, GetProductsError, GetProductsSuccess } from './actions';
import { apiKeysInitialState } from './api-keys/api-keys.interface';
import { selfServiceApiKeysReducers } from './api-keys/api-keys.reducer';
import { behaviorsInitialState } from './behaviors/behaviors.interface';
import { behaviorsReducers } from './behaviors/behaviors.reducer';
import { databaseSettingsInitialState } from './database-settings/database-settings.interface';
import { databaseSettingsReducers } from './database-settings/database-settings.reducer';
import { demographicsInitialState } from './demographics/demographics.interface';
import { demographicsReducers } from './demographics/demographics.reducer';
import { deploymentProductsInitialState } from './deployment-products/deployment-products.interface';
import { deploymentProductsReducers } from './deployment-products/deployment-products.reducer';
import { deploymentTypesInitialState } from './deployment-types/deployment-types.interface';
import { deploymentTypeReducer } from './deployment-types/deployment-types.reducer';
import { SelfServiceState } from './interfaces';
import { messageTypesInitialState } from './message-types/message-types.interface';
import { messageTypesReducers } from './message-types/message-types.reducer';
import { productsInitialState } from './products/products.interface';
import { profilesInitialState } from './profiles/profiles.interface';
import { selfServiceProfilesReducers } from './profiles/profiles.reducer';
import { alternateIdsReducer } from './alternate-ids/alternate-ids.reducer';
import { alternateIdsInitialState } from './alternate-ids/alternate-ids.interface';
import { logosInitialState } from './logos/logos.interface';
import { selfServiceLogosReducers } from './logos/logos.reducer';
import { calculatedDemographicsReducers } from './calculated-demographics/calculated-demographics.reducer';
import { calculatedDemographicsState } from './calculated-demographics/calculated-demographics.interface';
import { productsReducer } from './products/products.reducer';
import { consolidatedDemographicsState } from './consolidated-demographics/consolidated-demographics.interface';
import { consolidatedDemographicsReducers } from './consolidated-demographics/consolidated-demographics.reducer';
import { productNoticeInitialState } from './product-notice/product-notice.interface';
import { productNoticeReducers } from './product-notice/product-notice.reducer';
import { paymentGatewaysInitialState } from './payment-gateways/payment-gateways.interface';
import { paymentGatewaysReducer } from './payment-gateways/payment-gateways.reducer';
import { skuManagementInitialState } from './sku-management/sku-management.interface';
import { skuManagementReducers } from './sku-management/sku-management.reducer';

const initialAppState: SelfServiceState = {
    databaseSettingsState: { ...databaseSettingsInitialState },
    demographicsState: { ...demographicsInitialState },
    productNoticeState: { ...productNoticeInitialState },
    behaviorsState: { ...behaviorsInitialState },
    deploymentTypesState: { ...deploymentTypesInitialState },
    messageTypesState: { ...messageTypesInitialState },
    deploymentProductsState: { ...deploymentProductsInitialState },
    productsState: { ...productsInitialState },
    selfServiceProducts: {
        loading: false,
        data: null
    },
    selfServiceProfilesState: { ...profilesInitialState },
    selfServiceApiKeysState: { ...apiKeysInitialState },
    selfServiceAlternateIdsState: { ...alternateIdsInitialState },
    selfServiceLogosState: { ...logosInitialState },
    calculatedDemographicsState: { ...calculatedDemographicsState },
    consolidatedDemographicsState: { ...consolidatedDemographicsState },
    paymentGatewaysState: { ...paymentGatewaysInitialState },
    skuState: { ...skuManagementInitialState },
};

function getInitalState(): SelfServiceState {

    // TODO: find out if we still want to save the state in the localstorage here?
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

// function getSavedState(): SelfServiceState {
//     const savedState = sessionStorage.getItem('appState');
//     if (savedState) {
//         return JSON.parse(savedState);
//     }
//     return null;
// }

const appReducer = createReducer(
    getInitalState(),
    ...databaseSettingsReducers,
    ...demographicsReducers,
    ...productNoticeReducers,
    ...behaviorsReducers,
    ...deploymentProductsReducers,
    ...deploymentTypeReducer,
    ...messageTypesReducers,
    ...selfServiceApiKeysReducers,
    ...selfServiceProfilesReducers,
    ...alternateIdsReducer,
    ...selfServiceLogosReducers,
    ...calculatedDemographicsReducers,
    ...productsReducer,
    ...consolidatedDemographicsReducers,
    ...paymentGatewaysReducer,
    ...skuManagementReducers,
    on(GetProducts, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProducts: {
                data: null,
                loading: true
            }
        };
    }),
    on(GetProductsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProducts: {
                data: action.payload?.data,
                loading: false
            }
        };
    }),
    on(GetProductsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProducts: {
                data: null,
                loading: false
            }
        };
    }),
);

export function selfServiceReducer(state: SelfServiceState | undefined, action: Action) {
    return appReducer(state, action);
}
