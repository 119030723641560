import { on } from '@ngrx/store';
import { IEmailBuilderState } from '.././interfaces';
import * as DeploymentActions from './ignored-ips.actions';

export const ignoredIpsReducer = [
    on(DeploymentActions.GetIgnoredIps, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: [],
                loading: true
            },
        };
    }),
    on(DeploymentActions.GetIgnoredIpsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            ignoredIps: {
                data: action?.payload,
                loading: false
            },
        };
    }),
    on(DeploymentActions.GetIgnoredIpsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: [],
                loading: false
            },
        };
    }),
    on(DeploymentActions.CreateOrUpdateIp, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: state?.ignoredIps?.data || [],
                loading: true
            },
        };
    }),
    on(DeploymentActions.CreateOrUpdateIpSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            ignoredIps: {
                data: action?.payload,
                loading: false
            },
        };
    }),
    on(DeploymentActions.CreateOrUpdateIpError, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: state.ignoredIps.data,
                loading: false
            },
        };
    }),
    on(DeploymentActions.ChangeIpStatus, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: state.ignoredIps.data,
                loading: true
            },
        };
    }),
    on(DeploymentActions.ChangeIpStatusSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            ignoredIps: {
                data: action?.payload,
                loading: false
            },
        };
    }),
    on(DeploymentActions.ChangeIpStatusError, (state: IEmailBuilderState) => {
        return {
            ...state,
            ignoredIps: {
                data: state.ignoredIps.data,
                loading: false
            },
        };
    }),
];