import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as IntegrationsShopifyActions from '@integrations/state/shopify/shopify.actions';
import { catchError, exhaustMap, map, of, take, withLatestFrom } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProductI, StoreDetailI } from '@integrations/state/shopify/shopify.interface';
import { BasicResponse, CasHttpClient, getCurrentUser, ListSchema, ShellStore, ShowToast } from 'common';
import { Store } from '@ngrx/store';
import { DeploymentType } from 'common';
import { MessageType } from '@self-service/state/message-types/message-types.interface';
import { getMessageTypes, getDeploymentTypes, getProducts } from '@integrations/state/shopify/shopify.selector';
import { DataFile, FileContentsResponse, FileMappingTemplate, FileResponseBase } from '@data-loader/app/state/interfaces';

@Injectable({ providedIn: 'root' })
export class IntegrationsShopifyEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store,
        private shellStore: ShellStore,
        private casHttp: CasHttpClient
    ) {}

    private get currentDatabaseId(): string {
        let res: string;
        this.shellStore.currentDatabase$.pipe(take(1)).subscribe((database) => {
            res = database.id.toString();
        });
        return res;
    }

    private get currentUserId(): string {
        let res: string;
        this.store.select(getCurrentUser).pipe(take(1)).subscribe(user => {
            res = user?.currentProfile?.dataViewId?.toString();
        });
        return res;
    }

    getShopifyStores$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetShopifyStores),
            exhaustMap(({props}) => {
                const params = new URLSearchParams();
                for (const key in props) {
                    params.set(key, props[key]);
                }
                const url = this.urlParams('integration/shopify', params);
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<StoreDetailI>) => IntegrationsShopifyActions.GetShopifyStoresSuccess({ payload: res })),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting Shopify stores', error);
                        return of(IntegrationsShopifyActions.GetShopifyStoresError());
                    })
                );
            })
        );
    });

    getShopifyStore$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetShopifyStore),
            exhaustMap(action => {
                const url = this.urlParams(`integration/shopify/${action.payload.shopifyStoreId}`);
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: StoreDetailI) => IntegrationsShopifyActions.GetShopifyStoreSuccess({ payload: res })),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting Shopify store', error);
                        return of(IntegrationsShopifyActions.GetShopifyStoreError());
                    })
                );
            })
        );
    });

    saveShopifyStore$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.SaveShopifyStore),
            exhaustMap(action => {
                return this.http.post(this.urlParams('integration/shopify'), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.store.dispatch(ShowToast({payload: { title: 'Success', message: res?.message || 'Shopify store successfully saved.', design: 'Success', placement: 'top' }}));
                        return IntegrationsShopifyActions.SaveShopifyStoreSuccess({ payload: res });
                    }),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('saving Shopify store', error);
                        return of(IntegrationsShopifyActions.SaveShopifyStoreError());
                    })
                );
            })
        );
    });

    updateShopifyStoreStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.UpdateShopifyStoreStatus),
            exhaustMap(action => {
                const params = new URLSearchParams({
                    shopifyStoreId: action.payload.shopifyStoreId.toString(),
                    statusCode: action.payload.statusCode
                });
                const url = this.urlParams(`integration/shopify/${action.payload.shopifyStoreId}`, params);
                return this.http.put(url, { statusCode: action.payload.statusCode }, { withCredentials: true }).pipe(
                    take(1),
                    map((res: StoreDetailI) => {
                        this.store.dispatch(ShowToast({payload: { title: 'Success', message: res?.message || 'Shopify store status successfully updated.', design: 'Success', placement: 'top' }}));
                        return IntegrationsShopifyActions.UpdateShopifyStoreStatusSuccess({ payload: res });
                    }),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('updating Shopify store status', error);
                        return of(IntegrationsShopifyActions.UpdateShopifyStoreStatusError());
                    })
                );
            })
        );
    });

    getShopifyStoreByWebhookId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetShopifyStoreByWebhookId),
            exhaustMap(action => {
                const url = this.urlParams(`integration/shopify/webhook/${action.payload.webhookId}`);
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: StoreDetailI) => IntegrationsShopifyActions.GetShopifyStoreByWebhookIdSuccess({ payload: res })),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting Shopify store by webhook ID', error);
                        return of(IntegrationsShopifyActions.GetShopifyStoreByWebhookIdError());
                    })
                );
            })
        );
    });

    getMessageTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetMessageTypes),
            withLatestFrom(this.store.select(getMessageTypes)),
            exhaustMap(([action, payload]) => {
                if (action?.props?.useCache && payload?.success) {
                    return of(IntegrationsShopifyActions.GetMessageTypesSuccess({ payload: payload?.data || null }));
                }
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    singlePage: 'true'
                });
                const url = environment.settingsApiEndPoint + '/sms/message-types?' + params.toString();
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<MessageType>) => {
                        return IntegrationsShopifyActions.GetMessageTypesSuccess({ payload: res?.items });
                    }),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting message types', error);
                        return of(IntegrationsShopifyActions.GetMessageTypesError());
                    })
                );
            })
        );
    });

    getDeploymentTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetDeploymentTypes),
            withLatestFrom(this.store.select(getDeploymentTypes)),
            exhaustMap(([action, payload]) => {
                if (action?.props?.useCache && payload?.success) {
                    return of(IntegrationsShopifyActions.GetDeploymentTypesSuccess({ payload: payload?.data || null }));
                }
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    singlePage: 'true'
                });
                const url = environment.settingsApiEndPoint + '/deployment-type/all?' + params.toString();
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<DeploymentType>) => {
                        return IntegrationsShopifyActions.GetDeploymentTypesSuccess({ payload: res?.items });
                    }),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting deployment types', error);
                        return of(IntegrationsShopifyActions.GetDeploymentTypesError());
                    })
                );
            })
        );
    });

    getProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetProducts),
            withLatestFrom(this.store.select(getProducts)),
            exhaustMap(([action, payload]) => {
                if (action?.props?.useCache && payload?.success) {
                    return of(IntegrationsShopifyActions.GetProductsSuccess({ payload: payload?.data || null }));
                }
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    productType: '10',
                    singlePage: 'true',
                    productAttribute: 'Setting:ThirdPartyDataSourceType',
                    productAttributeValue: '1' //ProductConstants.ThirdPartyDataSourceType.SHOPIFY
                });
                const url = environment.settingsApiEndPoint + '/product?' + params.toString();
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<ProductI>) => {
                        return IntegrationsShopifyActions.GetProductsSuccess({ payload: res });
                    }),
                    catchError((error: BasicResponse | HttpErrorResponse) => {
                        this.errorToast('getting products', error);
                        return of(IntegrationsShopifyActions.GetProductsError());
                    })
                );
            })
        );
    });

    getFileList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetFileList),
            exhaustMap((action) => {
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    fileOrigin: '5',
                    consolidatedStatus: '-1',
                    isShopify: 'true'
                });
                for (const [key, val] of Object.entries(action.payload)) {
                    params.append(key, val);
                }
                const url = environment.dataLoaderApiEndPoint + '/file/file-list?' + params.toString();
                return this.casHttp.get(environment.dataLoaderApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FileResponseBase & {fileList: DataFile[]}) => {
                        return IntegrationsShopifyActions.GetFileListSuccess({ payload: res.fileList });
                    }),
                    catchError(error => {
                        this.errorToast('getting records', error);
                        return of(IntegrationsShopifyActions.GetFileListError(error));
                    })
                );
            })
        );
    });

    getFileListCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetFileListCount),
            exhaustMap((action) => {
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    consolidatedStatus: '-1',
                    fileOrigin: '5'
                });
                for (const [key, val] of Object.entries(action.payload)) {
                    params.append(key, val.toString());
                }
                const url = environment.dataLoaderApiEndPoint + '/file/file-list-count?' + params.toString();
                return this.casHttp.get(environment.dataLoaderApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FileResponseBase) => {
                        return IntegrationsShopifyActions.GetFileListCountSuccess({ payload: res?.fileCount || 0 });
                    }),
                    catchError(error => {
                        this.errorToast('getting record count', error);
                        return of(IntegrationsShopifyActions.GetFileListCountError(error));
                    })
                );
            })
        );
    });

    getFileDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetFileDetail),
            exhaustMap((action) => {
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId,
                    dataViewId: this.currentUserId,
                    currentPage: '0',
                    pageSize: '1'
                });
                const url = environment.dataLoaderApiEndPoint + `/file/${action.payload.fileId}/rows?${params.toString()}`;
                return this.casHttp.get(environment.dataLoaderApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FileContentsResponse) => {
                        return IntegrationsShopifyActions.GetFileListDetailSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.errorToast('getting record detail', error);
                        return of(IntegrationsShopifyActions.GetFileListDetailError());
                    })
                );
            })
        );
    });

    getTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IntegrationsShopifyActions.GetTemplate),
            exhaustMap(action => {
                const params = new URLSearchParams({
                    environmentId: this.currentDatabaseId
                });
                const url = `${environment.dataLoaderApiEndPoint}/template/${action.payload.fileMappingId}?${params.toString()}`;
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FileMappingTemplate) => {
                        return IntegrationsShopifyActions.GetTemplateSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.errorToast('getting template', error);
                        return of(IntegrationsShopifyActions.GetTemplateError());
                    })
                );
            })
        );
    });

    private urlParams(url: string, params?: URLSearchParams) {
        if (params == null) params = new URLSearchParams();
        params.set('environmentId', this.currentDatabaseId);
        return `${environment.campaignApiEndPoint}/${url}${params?.toString() ? '?' + params.toString() : ''}`;
    }

    private errorToast(action: string, errors?: BasicResponse | HttpErrorResponse) {
        let message = `There was an error while ${action}.`;
        if (errors != null) {
            if ((errors as BasicResponse)?.errors?.length) {
                message = (errors as BasicResponse).errors[0];
            } else if ((errors as HttpErrorResponse)?.error?.errors?.length) {
                message = (errors as HttpErrorResponse).error.errors[0];
            } else if (errors?.message) message = errors.message;
            else if ((errors as BasicResponse)?.messages?.length) { 
                message = (errors as BasicResponse).messages[0];
            } else if ((errors as BasicResponse)?.errorMessages?.length) {
                message = (errors as BasicResponse).errorMessages[0];
            }
        }
        this.store.dispatch(ShowToast({payload: { title: 'Error', message, design: 'Error', placement: 'top'  }}));
    }
}
