import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CasHttpClient, ShellStore, ShowToast, getCurrentUser } from 'common';
import { debounceTime, exhaustMap, map, switchMap, take } from 'rxjs';
import * as SmsBuilderActions from './actions';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CommonItem, MessageType, MetaData, PhoneNumber, SearchResults } from './interfaces';

@Injectable({ providedIn: 'root' })
export class SmsBuilderEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private casHttp: CasHttpClient,
        private route: ActivatedRoute
    ) { }

    getMetaData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.GetMetaData),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(environment.smsApiEndPoint,
                        environment.smsApiEndPoint + `/messages/meta?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MetaData) => {
                            return SmsBuilderActions.GetMetaDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Meta data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(SmsBuilderActions.GetMetaDataError(error));
                        })
                    );
            })
        )
    );

    searchAllMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.SearchAllMessages),
            debounceTime(300),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', action?.payload?.environmentId ? action?.payload?.environmentId?.toString() : this.currentDatabaseId);
                searchParams.set('numResults', action?.payload?.queryParams?.numResults?.toString());
                searchParams.set('offset', action?.payload?.queryParams?.offset?.toString());
                searchParams.set('order', action?.payload?.queryParams?.order);
                searchParams.set('sortBy', action?.payload?.queryParams?.sortBy);
                if (action?.payload?.queryParams?.dashboard) {
                    searchParams.set('dashboard', action?.payload?.queryParams?.dashboard?.toString());
                }
                return this.casHttp.post(
                    environment.smsApiEndPoint,
                    environment.smsApiEndPoint + `/messages?${searchParams}`,
                    { filters: action?.payload?.filterData },
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: SearchResults) => {
                        return SmsBuilderActions.SearchAllMessagesSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get available Messages.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SmsBuilderActions.SearchAllMessagesError(error));
                    })
                );
            })
        )
    );

    getMessageTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.GetMessageTypesList),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                if (action?.payload?.isCreateEdit) {
                    searchParams.set('verificationCode', '1');
                    searchParams.set('statusCode', '1');
                }
                return this.casHttp.get(
                    environment.smsApiEndPoint,
                    environment.smsApiEndPoint + `/message-types/list?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<MessageType>) => {
                        return SmsBuilderActions.GetMessageTypesListSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get available Message Types.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SmsBuilderActions.GetMessageTypesListError(error));
                    })
                );
            })
        )
    );

    getStatusOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.GetStatusOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp.get(
                    environment.smsApiEndPoint,
                    environment.smsApiEndPoint + `/messages/status-options?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<CommonItem>) => {
                        return SmsBuilderActions.GetStatusOptionsListSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get available Status Options.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SmsBuilderActions.GetStatusOptionsListError(error));
                    })
                );
            })
        )
    );

    getSendingPhoneNumbersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.GetSendingPhoneNumbersList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp.get(
                    environment.smsApiEndPoint,
                    environment.smsApiEndPoint + `/sending-phone-numbers?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<PhoneNumber>) => {
                        return SmsBuilderActions.GetSendingPhoneNumbersListSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get available Phone Numbers.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SmsBuilderActions.GetSendingPhoneNumbersListError(error));
                    })
                );
            })
        )
    );

    getReportUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmsBuilderActions.GetReportUrl),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('messageId', action?.payload?.messageId?.toString());
                return this.http.get(
                    environment.smsApiEndPoint + `/message-report?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: { description: string, value: string }) => {
                        if (res?.value) {
                            window.open(res.value, `${res.value}`);
                        }
                        return SmsBuilderActions.GetReportUrlSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get report.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SmsBuilderActions.GetReportUrlError(error));
                    })
                );
            })
        )
    );

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }
}
