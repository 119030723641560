import { Location } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, take, mergeMap, switchMap } from 'rxjs';
import { BasicResponse, ShellStore, ShowToast, getCurrentUser } from 'common';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { ArchiveVoyage, ArchiveVoyageError, ArchiveVoyageSuccess, CloneVoyage, CloneVoyageError, CloneVoyageSuccess, DownloadExportDetails, DownloadExportDetailsError, DownloadExportDetailsSuccess, DownloadVisitDetails, DownloadVisitDetailsError, DownloadVisitDetailsSuccess, GetAnalyticsHistory, GetAnalyticsHistoryError, GetAnalyticsHistorySuccess, GetAnalyticsReport, GetAnalyticsReportError, GetAnalyticsReportSuccess, GetGlobalSettings, GetGlobalSettingsError, GetGlobalSettingsSuccess, GetTemplatesAndMiscData, GetTemplatesAndMiscDataError, GetTemplatesAndMiscDataSuccess, GetTestHistory, GetTestHistoryError, GetTestHistorySuccess, RestoreVoyage, RestoreVoyageError, RestoreVoyageSuccess, SearchVoyages, SearchVoyagesError, SearchVoyagesSuccess, UpdateGlobalSettings, UpdateGlobalSettingsError, UpdateGlobalSettingsSuccess, UpdateVoyageName, UpdateVoyageNameError, UpdateVoyageNameSuccess } from './actions';
import { AnalyticsHistoryItem, AnalyticsReport, BasicResponseAction, BusinessHours, TemplatesAndMiscData, TestHistory, VoyagesData } from './interfaces';

@Injectable({ providedIn: 'root' })

export class OdysseyEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private location: Location,
        private shellStore: ShellStore,
    ) { }

    searchVoyages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchVoyages),
            switchMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/dashboard?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`);
                url.searchParams.append('offset', action.payload.offset.toString());
                url.searchParams.append('numResults', action.payload.numResults.toString());
                url.searchParams.append('sortBy', action.payload.sortBy);
                url.searchParams.append('order', action.payload.order);
                if (action?.payload?.filter?.length > 0) {
                    url.searchParams.append('filter', action.payload.filter);
                }
                if (action?.payload?.startDate) {
                    url.searchParams.append('startDate', action?.payload?.startDate?.toString());
                }
                if (action?.payload?.endDate) {
                    url.searchParams.append('endDate', action.payload.endDate?.toString());
                }
                if (action?.payload?.status) {
                    url.searchParams.append('status', action.payload.status);
                }
                if (action?.payload?.templateType) {
                    url.searchParams.append('templateType', action.payload.templateType);
                }
                if (action?.payload?.deploymentType && action?.payload?.deploymentType != 'null') {
                    url.searchParams.append('deploymentType', action.payload.deploymentType);
                }
                if (action?.payload?.creator && action?.payload?.creator != 'null') {
                    url.searchParams.append('creator', action.payload.creator);
                }
                return this.http.get(url?.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: VoyagesData) => {
                        return SearchVoyagesSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to search voyages.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SearchVoyagesError(error));
                    })
                );
            })
        )
    );

    getTemplatesAndMiscData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetTemplatesAndMiscData),
            mergeMap(() => {
                return this.http.get(environment.odysseyApiEndPoint + '/data?environmentId=' + this.currentDatabase.id + '&dataViewId=' + this.currentUser.currentProfile.dataViewId, { withCredentials: true }).pipe(
                    take(1),
                    map((res: TemplatesAndMiscData) => {
                        return GetTemplatesAndMiscDataSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to get templates and fields data.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(GetTemplatesAndMiscDataError(error));
                    })
                );
            })
        )
    );

    updateVoyageName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateVoyageName),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/rename?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`);
                return this.http.post(url?.toString(), action?.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponseAction) => {
                        this.showSuccess(null, 'Voyage name changed successfully.');
                        return UpdateVoyageNameSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to rename voyage.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(UpdateVoyageNameError(error));
                    })
                );
            })
        )
    );

    cloneVoyage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CloneVoyage),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/clone?environmentId=${this.currentDatabase.id}`);
                return this.http.post(url?.toString(), action?.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponseAction) => {
                        let successMessage = 'Voyage cloned successfully.';
                        if (res && res?.success && res?.messages && res?.messages?.length) {
                            successMessage = res?.messages[0];
                        }
                        this.showSuccess(null, successMessage);
                        return CloneVoyageSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to clone voyage.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(CloneVoyageError(error));
                    })
                );
            })
        )
    );

    archiveVoyage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ArchiveVoyage),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/archive?environmentId=${this.currentDatabase.id}`);
                return this.http.post(url?.toString(), { voyageId: action?.payload?.voyageId }, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponseAction) => {
                        this.showSuccess(null, 'The voyage was successfully archived!');
                        return ArchiveVoyageSuccess({ payload: { ...res, action: 'Archive' } });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to archive voyage.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ArchiveVoyageError(error));
                    })
                );
            })
        )
    );

    restoreVoyage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RestoreVoyage),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/restore?environmentId=${this.currentDatabase.id}`);
                return this.http.post(url?.toString(), { voyageId: action?.payload?.voyageId }, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponseAction) => {
                        this.showSuccess(null, 'The voyage was successfully restored!');
                        return RestoreVoyageSuccess({ payload: { ...res, action: 'Restore' } });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to restore voyage.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(RestoreVoyageError(error));
                    })
                );
            })
        )
    );

    getGlobalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetGlobalSettings),
            mergeMap(() => {
                const url = new URL(`${environment.odysseyApiEndPoint}/dashboard/global-settings?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`);
                return this.http.get(url?.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: BusinessHours) => {
                        return GetGlobalSettingsSuccess({ payload: { ...res } });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to get global settings.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(GetGlobalSettingsError(error));
                    })
                );
            })
        )
    );

    updateGlobalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateGlobalSettings),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/dashboard/global-settings?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser.currentProfile.dataViewId}`);
                return this.http.post(url?.toString(), action?.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        let successMessage = 'Global Settings updated succesfully!';
                        if (res && res?.success && res?.messages && res?.messages?.length) {
                            successMessage = res?.messages[0];
                        }
                        this.showSuccess(null, successMessage);
                        return UpdateGlobalSettingsSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to update global settings.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(UpdateGlobalSettingsError(error));
                    })
                );
            })
        )
    );

    getAnalyticsReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAnalyticsReport),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/analytics?environmentId=${this.currentDatabase.id}&voyageId=${action?.payload?.voyageId}`);
                return this.http.get(url?.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: AnalyticsReport) => {
                        return GetAnalyticsReportSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to get analytics report.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(GetAnalyticsReportError(error));
                    })
                );
            })
        )
    );

    downloadVisitDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DownloadVisitDetails),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/analytics/visit-report?environmentId=${this.currentDatabase.id}&voyageId=${action?.payload?.voyageId}`);
                if (action?.payload?.elementId) {
                    url.searchParams.append('elementId', action?.payload?.elementId);
                }
                return this.http.get(url?.toString(), {
                    withCredentials: true,
                    responseType: 'blob',
                    observe: 'response',
                }).pipe(
                    take(1),
                    map((res: HttpResponse<Blob>) => {
                        const blob: Blob = new Blob([res.body], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        let fileName = `VisitReport_${action?.payload?.voyageId}`;
                        if (action?.payload?.elementId) {
                            fileName = fileName + `_${action?.payload?.elementId}`;
                        }
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        return DownloadVisitDetailsSuccess();
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to download visit details.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(DownloadVisitDetailsError(error));
                    })
                );
            })
        )
    );

    downloadExportDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DownloadExportDetails),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/analytics/export-report?environmentId=${this.currentDatabase.id}&voyageId=${action?.payload?.voyageId}`);
                return this.http.get(url?.toString(), {
                    withCredentials: true,
                    responseType: 'blob',
                    observe: 'response',
                }).pipe(
                    take(1),
                    map((res: HttpResponse<Blob>) => {
                        const blob: Blob = new Blob([res.body], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        const fileName = `ExportReport_${action?.payload?.voyageId}`;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        return DownloadExportDetailsSuccess();
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to download export details.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(DownloadExportDetailsError(error));
                    })
                );
            })
        )
    );

    getTestHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetTestHistory),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/element/test-history?environmentId=${this.currentDatabase.id}&trackingNumber=${action?.payload?.trackingNumber}`);
                return this.http.get(url?.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: TestHistory) => {
                        return GetTestHistorySuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to get test history.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(GetTestHistoryError(error));
                    })
                );
            })
        )
    );

    getAnalyticsHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAnalyticsHistory),
            mergeMap((action) => {
                const url = new URL(`${environment.odysseyApiEndPoint}/voyage/analytics/history?environmentId=${this.currentDatabase.id}&voyageId=${action?.payload?.voyageId}`);
                return this.http.get(url?.toString(), { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<AnalyticsHistoryItem>) => {
                        return GetAnalyticsHistorySuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to load analytics history data.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(GetAnalyticsHistoryError(error));
                    })
                );
            })
        )
    );

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(take(1), map(database => res = database)).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(take(1), map(user => res = user)).subscribe();
        return res;
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }
}
