/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable} from '@angular/core';

declare const window: Window & { onUsersnapLoad?: (api) => void };

@Injectable({ providedIn: 'root' })
export class UsersnapService {
    private script: HTMLScriptElement | null = null;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    public usersnapApi: any | null = null;

    initialize() {
        return new Promise(resolve => {
            window.onUsersnapLoad = (api) => {
                api.init({
                    nativeScreenshot: true
                });
                resolve(api);
            };
            this.script = document.createElement('script');
            this.script.defer = false;
            this.script.type = 'text/javascript';
            this.script.src = 'https://widget.usersnap.com/global/load/87c6a810-8d36-49d6-8272-ce24c41109d4?onload=onUsersnapLoad';
            document.body.appendChild(this.script);
        });
    }

    ngOnDestroy() {
        if (this.usersnapApi) {
            this.usersnapApi.destroy();
        }
        this.script?.remove();
    }

}