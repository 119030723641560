import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ProductsActions from './products.actions';
import { DemographicRes, ProductClassesRes, ProductResponse, ProductsRes } from './products.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceProductsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.LoadProductsData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || '');
                searchParams.set('status', action.payload.status?.toString() || '');
                searchParams.set('productType', action.payload.productType?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductsRes) => {
                            const payload: ResponseWrapper<ProductsRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.LoadProductsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load products.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.LoadProductsDataError(error));
                        })
                    );
            })
        )
    );

    downloadProductsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.DownloadProductsData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('status', action.payload.status?.toString() || '');
                searchParams.set('productType', action.payload.productType?.toString() || '');

                return this.http
                    .get(environment.settingsApiEndPoint + `/product/download-list?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.style.display = 'none';
                            a.href = url;
                            a.download = 'products';
                            a.click();
                            window.URL.revokeObjectURL(url);

                            return ProductsActions.DownloadProductsDataSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download deployment products list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.DownloadProductsDataError(error));
                        })
                    );
            })
        )
    );

    getProductDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductDetails),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => ProductsActions.GetProductDetailsSuccess({ payload: res })),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load product general details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['self-service/main/products']);
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductDetailsError(error));
                        })
                    );
            })
        )
    );

    getAuditBureaus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductDetails),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/product/audit-bureaus', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<string>) => {
                            return ProductsActions.GetAuditBureausSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load audit bureaus.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetAuditBureausError(error));
                        })
                    );
            })
        )
    );

    getProductDefinitions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductDefinitions),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/definition?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductDefinitionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load magazine product definitions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductDefinitionsError(error));
                        })
                    );
            })
        )
    );

    getPaymentOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetPaymentOptions),
            switchMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/product/payment-options', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{code: number, name: string}>) => {
                            return ProductsActions.GetPaymentOptionsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load payment options.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetPaymentOptionsError(error));
                        })
                    );
            })
        )
    );

    getPaidClasses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetPaidClasses),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/paid-classes?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{code: number, name: string}>) => {
                            return ProductsActions.GetPaidClassesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load paid classes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetPaidClassesError(error));
                        })
                    );
            })
        )
    );

    getPostalBarcodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetPostalBarcodes),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/imbCodes?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{code: number, name: string}>) => {
                            return ProductsActions.GetPostalBarcodesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load postal barcodes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetPostalBarcodesError(error));
                        })
                    );
            })
        )
    );

    getProductCompanies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductCompanies),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/companies?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{name: string, value: string}>) => {
                            return ProductsActions.GetProductCompaniesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load product companies.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductCompaniesError(error));
                        })
                    );
            })
        )
    );

    getProductDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load product demographics.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductDemographicsError(error));
                        })
                    );
            })
        )
    );

    getProductClasses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductClasses),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');

                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action?.payload?.productId?.toString()}/classes?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductClassesRes) => {
                            const payload: ResponseWrapper<ProductClassesRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductClassesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load magazine classes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductClassesError(error));
                        })
                    );
            })
        )
    );

    getMergeDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductMergeData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/merge-details?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductMergeDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load merge details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductMergeDataError(error));
                        })
                    );
            })
        )
    );

    getProductRequestorRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductRequestorRules),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/requestor-rules?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductRequestorRulesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load requestor rules.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductRequestorRulesError(error));
                        })
                    );
            })
        )
    );

    getProductOtherDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductOtherDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/others?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductOtherDetailsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load other details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductOtherDetailsError(error));
                        })
                    );
            })
        )
    );

    getProductCreditGracing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductCreditGracing),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/credit-gracing?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ProductResponse) => {
                            const payload: ResponseWrapper<ProductResponse> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductCreditGracingSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load credit gracing details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductCreditGracingError(error));
                        })
                    );
            })
        )
    );

    getProductAccounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductAccounts),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/accounts?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<{accountId: number, accountName: string, accountType: string}>) => {
                            return ProductsActions.GetProductAccountsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load accounts details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductAccountsError(error));
                        })
                    );
            })
        )
    );

    getProductAuditedDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductAuditedDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('sortBy', action.payload.sortBy?.toString() || '');
                searchParams.set('order', action.payload.order?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics/audited?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicRes) => {
                            const payload: ResponseWrapper<DemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductAuditedDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load audited demographics details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductAuditedDemographicsError(error));
                        })
                    );
            })
        )
    );

    getProductAddressedDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductAddressedDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('sortBy', action.payload.sortBy?.toString() || '');
                searchParams.set('order', action.payload.order?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics/how-addressed-values?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicRes) => {
                            const payload: ResponseWrapper<DemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductAddressedDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load addressed demographics details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductAddressedDemographicsError(error));
                        })
                    );
            })
        )
    );

    getProductIssueCloseDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductIssueCloseDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('sortBy', action.payload.sortBy?.toString() || '');
                searchParams.set('order', action.payload.order?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics/issue-close?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicRes) => {
                            const payload: ResponseWrapper<DemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductIssueCloseDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load issue close demographics details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductIssueCloseDemographicsError(error));
                        })
                    );
            })
        )
    );

    getProductAssociatedDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductAssociatedDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('sortBy', action.payload.sortBy?.toString() || '');
                searchParams.set('order', action.payload.order?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics/associated?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicRes) => {
                            const payload: ResponseWrapper<DemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductAssociatedDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load associated demographics details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductAssociatedDemographicsError(error));
                        })
                    );
            })
        )
    );

    getProductPassAlongDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.GetProductPassAlongDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('productId', action.payload.productId?.toString());
                searchParams.set('numResults', action.payload.numResults?.toString() || '10');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('sortBy', action.payload.sortBy?.toString() || '');
                searchParams.set('order', action.payload.order?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/product/${action.payload.productId?.toString()}/demographics/pass-along?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicRes) => {
                            const payload: ResponseWrapper<DemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return ProductsActions.GetProductPassAlongDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load pass along demographics details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProductsActions.GetProductPassAlongDemographicsError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
