import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ShellStore, ModalService, ShowToast, getCurrentUser, BasicResponse } from 'common';
import { exhaustMap, take, map, catchError, of, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SaveToolsFilter, SaveToolsFilterSuccess, SaveToolsFilterError, GetDownloadHistory, GetDownloadHistorySuccess, GetDownloadHistoryError, GetExistingFilterHistory, GetExistingFilterHistorySuccess, GetExistingFilterHistoryError, AttachFile, AttachFileSuccess, AttachFileError, GetExistingFilters, GetExistingFiltersSuccess, GetExistingFiltersError, OutputAsTextFile, OutputAsTextFileSuccess, OutputAsTextFileError, RollbackFilterUploadUsers, RollbackFilterUploadUsersSuccess, RollbackFilterUploadUsersError, RollbackFilterUploadsByUser, RollbackFilterUploadsByUserSuccess, RollbackFilterUploadsByUserError, FilterCounts, FilterCountsSuccess, FilterCountsError } from './filter.actions';
import { DownloadHistory, ExistingFilter, ExistingFilterHistory, FileData, FilerItem, FilterCountsData, UserItem } from './filter.interfaces';

@Injectable({ providedIn: 'root' })
export class EmailBuilderFilterEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) { }

    getExistingFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetExistingFilters),
            exhaustMap((action) => {
                let url = `?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&email=${action?.payload?.email}`;
                url += action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                return this.http
                    .get(this.baseUrl + url, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ExistingFilter>) => {
                            return GetExistingFiltersSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get existing filters.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetExistingFiltersError(error));
                        })
                    );
            })
        )
    );

    getExistingFilterHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetExistingFilterHistory),
            exhaustMap((action) => {
                return this.http
                    .get(this.baseUrl + `/existing-filter-history?environmentId=${this.currentDatabase?.id}&orgId=${action?.payload?.orgId}&databaseId=${action?.payload?.databaseId}&id=${action?.payload?.id}&email=${action?.payload?.email}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ExistingFilterHistory>) => {
                            return GetExistingFilterHistorySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get existing filter history.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetExistingFilterHistoryError(error));
                        })
                    );
            })
        )
    );

    saveToolsFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SaveToolsFilter),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `?environmentId=${this.currentDatabase.id}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if (res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Filters Saved Successfully.');
                            }
                            if (!action?.payload?.uploadFile && action?.payload?.status !== 'rollback') {
                                this.store.dispatch(GetExistingFilters({ payload: { orgId: action?.payload?.orgId, databaseId: action?.payload?.databaseId, email: action?.payload?.email } }));
                            }
                            return SaveToolsFilterSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save filters.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(SaveToolsFilterError(error));
                        })
                    );
            })
        )
    );

    getDownloadHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDownloadHistory),
            exhaustMap((action) => {
                let url = `/download-history?environmentId=${this.currentDatabase?.id}&orgId=${action?.payload?.orgId}`;
                url += +action?.payload?.databaseId ? `&databaseId=${action?.payload?.databaseId}` : '';
                url += `&numResults=${action.payload.numResults}`;
                url += `&offset=${action.payload.offset}`;
                url += `&sortBy=${action.payload.sortBy}`;
                url += `&order=${action.payload.order}`;
                return this.http
                    .get(this.baseUrl + url, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<DownloadHistory>) => {
                            return GetDownloadHistorySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get download history.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDownloadHistoryError(error));
                        })
                    );
            })
        )
    );

    attachFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AttachFile),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/attach-file?environmentId=${this.currentDatabase?.id}&orgId=${action?.payload?.orgId}`);
                if (action?.payload?.databaseId) {
                    url?.searchParams?.append('databaseId', action?.payload?.databaseId?.toString());
                }
                return this.http
                    .post(url?.toString(), action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FileData) => {
                            if (res?.invalidRecords) {
                                this.showWarning(null, 'File was uploaded with the following error: ' + res.invalidRecords);
                            } else {
                                this.showSuccess(null, 'File uploaded successfully.');
                            }
                            return AttachFileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload file.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(AttachFileError(error));
                        })
                    );
            })
        )
    );

    outputAsTextFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OutputAsTextFile),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `/output?environmentId=${this.currentDatabase?.id}`, action?.payload, { withCredentials: true, responseType: 'blob', observe: 'response' })
                    .pipe(
                        take(1),
                        map((res: HttpResponse<Blob>) => {
                            this.downloadFile(res);
                            return OutputAsTextFileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to output as text file.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(OutputAsTextFileError(error));
                        })
                    );
            })
        )
    );

    rollbackFilterUploadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RollbackFilterUploadUsers),
            exhaustMap((action) => {
                return this.http
                    .get(this.baseUrl + `/filter-upload-users?environmentId=${this.currentDatabase?.id}&databaseId=${action?.payload?.databaseId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<UserItem> | BasicResponse) => {
                            if ('messages' in res && res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if ('errors' in res && res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else if (Array.isArray(res)) {
                                if (!res?.length) {
                                    this.showWarning(null, 'No users found for selected database!');
                                } else {
                                    this.showSuccess(null, 'Users Loaded Successfully.');
                                }
                            }
                            return RollbackFilterUploadUsersSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load filter users.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(RollbackFilterUploadUsersError(error));
                        })
                    );
            })
        )
    );

    rollbackFilterUploadsByUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RollbackFilterUploadsByUser),
            exhaustMap((action) => {
                return this.http
                    .get(this.baseUrl + `/load-filter?environmentId=${this.currentDatabase?.id}&databaseId=${action?.payload?.databaseId}&userId=${action?.payload?.userId}&startDate=${action?.payload?.startDate}&endDate=${action?.payload?.endDate}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<FilerItem> | BasicResponse) => {
                            if ('messages' in res && res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            } else if ('errors' in res && res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            } else {
                                this.showSuccess(null, 'Uploads By Selected User Loaded Successfully.');
                            }
                            return RollbackFilterUploadsByUserSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load filters uploaded by user.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(RollbackFilterUploadsByUserError(error));
                        })
                    );
            })
        )
    );

    filterCounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilterCounts),
            mergeMap((action) => {
                const url = new URL(`${this.baseUrl}/filter-counts?orgId=${action?.payload?.orgId}`);
                return this.http
                    .get(url?.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FilterCountsData) => {
                            return FilterCountsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load filter counts.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(FilterCountsError(error));
                        })
                    );
            })
        )
    );

    downloadFile(res: HttpResponse<Blob>) {
        const blob: Blob = new Blob(['"EMAIL_ADDRESS"\n', res.body ? res.body : ''], {
            type: 'text/csv',
        });
        const url = window.URL.createObjectURL(blob);
        const fileName = res.headers
            .get('Content-Disposition')
            .split(';')[1]
            .trim()
            .split('=')[1];
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    message: message || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    icon: icon || 'fa-triangle-exclamation-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools/filter';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}