import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as DemographicsActions from './calculated-demographics.actions';

export const calculatedDemographicsReducers = [
    on(DemographicsActions.LoadCalculatedDemographicsData, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsData: {
                    data: state.calculatedDemographicsState?.calculatedDemographicsData?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.LoadCalculatedDemographicsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.LoadCalculatedDemographicsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.DownloadCalculatedDemographicsList, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsDownloadLoader: true
            }
        };
    }),

    on(DemographicsActions.DownloadCalculatedDemographicsListSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsDownloadLoader: false
            }
        };
    }),

    on(DemographicsActions.DownloadCalculatedDemographicsListError, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                calculatedDemographicsDownloadLoader: false
            }
        };
    }),

    on(DemographicsActions.GetCalculatedDemographicData, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: state.calculatedDemographicsState?.selectedCalculatedDemographic?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.GetCalculatedDemographicDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetCalculatedDemographicDataError, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetSourceDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                sourceDemographics: {
                    data: state.calculatedDemographicsState?.sourceDemographics?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.GetSourceDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                sourceDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetSourceDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                sourceDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.ClearCalculatedDemographicData, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.SaveCalculatedDemographic, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: state.calculatedDemographicsState?.selectedCalculatedDemographic?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.SaveCalculatedDemographicSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: state.calculatedDemographicsState?.selectedCalculatedDemographic?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.SaveCalculatedDemographicError, (state: SelfServiceState) => {
        return {
            ...state,
            calculatedDemographicsState: {
                ...state.calculatedDemographicsState,
                selectedCalculatedDemographic: {
                    data: state.calculatedDemographicsState?.selectedCalculatedDemographic?.data,
                    loading: false
                }
            }
        };
    })
];
