import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as ApiKeysActions from './api-keys.actions';

export const selfServiceApiKeysReducers = [
    on(ApiKeysActions.LoadApiKeysData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                apiKeysData: {
                    data: state?.selfServiceApiKeysState?.apiKeysData?.data,
                    loading: true
                }
            }
        };
    }),
    on(ApiKeysActions.LoadApiKeysDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                apiKeysData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ApiKeysActions.LoadApiKeysDataError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                apiKeysData: {
                    data: state?.selfServiceApiKeysState?.apiKeysData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetCustomerLookupServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                customerLookups: {
                    data: state?.selfServiceApiKeysState?.customerLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetCustomerLookupServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                customerLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetCustomerLookupServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                customerLookups: {
                    data: state?.selfServiceApiKeysState?.customerLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetStoreCustomerInfoServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                storeCustomerInfo: {
                    data: state?.selfServiceApiKeysState?.storeCustomerInfo?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetStoreCustomerInfoServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                storeCustomerInfo: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetStoreCustomerInfoServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                storeCustomerInfo: {
                    data: state?.selfServiceApiKeysState?.storeCustomerInfo?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetBrandLookupServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                brandLookups: {
                    data: state?.selfServiceApiKeysState?.brandLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetBrandLookupServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                brandLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetBrandLookupServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                brandLookups: {
                    data: state?.selfServiceApiKeysState?.brandLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetEmailBuilderServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                emailBuilderLookups: {
                    data: state?.selfServiceApiKeysState?.emailBuilderLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetEmailBuilderServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                emailBuilderLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetEmailBuilderServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                emailBuilderLookups: {
                    data: state?.selfServiceApiKeysState?.emailBuilderLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetUtilityServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                utilityLookups: {
                    data: state?.selfServiceApiKeysState?.utilityLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetUtilityServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                utilityLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetUtilityServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                utilityLookups: {
                    data: state?.selfServiceApiKeysState?.utilityLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GenerateApiKey, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                generatedApiKey: null
            }
        };
    }),

    on(ApiKeysActions.GenerateApiKeySuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                generatedApiKey: action?.payload
            }
        };
    }),

    on(ApiKeysActions.ClearGeneratedApiKey, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                generatedApiKey: null
            }
        };
    }),

    on(ApiKeysActions.GetSpecificApiKeyDetails, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: state?.selfServiceApiKeysState?.selectedApiKey?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetSpecificApiKeyDetailsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetSpecificApiKeyDetailsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: state?.selfServiceApiKeysState?.selectedApiKey?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.ClearApiKeyDetails, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.SaveApiKey, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: state?.selfServiceApiKeysState?.selectedApiKey?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.SaveApiKeyError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                selectedApiKey: {
                    data: state?.selfServiceApiKeysState?.selectedApiKey?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetOlyticsServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                olyticsLookups: {
                    data: state?.selfServiceApiKeysState?.olyticsLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetOlyticsServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                olyticsLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetOlyticsServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                olyticsLookups: {
                    data: state?.selfServiceApiKeysState?.olyticsLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetSmsServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                smsLookups: {
                    data: state?.selfServiceApiKeysState?.smsLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetSmsServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                smsLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetSmsServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                smsLookups: {
                    data: state?.selfServiceApiKeysState?.smsLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetAuthenticationServices, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                authenticationLookups: {
                    data: state?.selfServiceApiKeysState?.authenticationLookups?.data,
                    loading: true
                }
            }
        };
    }),

    on(ApiKeysActions.GetAuthenticationServicesSuccess, (state: SelfServiceState, action) => {
        const res = [];
        if (action?.payload?.data) {
            Object.keys(action?.payload?.data).forEach((key) => {
                res.push({
                    id: key,
                    name: action?.payload?.data?.[key],
                    selected: false
                });
            });
            res.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                authenticationLookups: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetAuthenticationServicesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                authenticationLookups: {
                    data: state?.selfServiceApiKeysState?.authenticationLookups?.data,
                    loading: false
                }
            }
        };
    }),

    on(ApiKeysActions.GetFullApiKey, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                fullApiKey: null
            }
        };
    }),

    on(ApiKeysActions.GetFullApiKeySuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                fullApiKey: action?.payload
            }
        };
    }),

    on(ApiKeysActions.ClearFullApiKey, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceApiKeysState: {
                ...state.selfServiceApiKeysState,
                fullApiKey: null
            }
        };
    }),
];
