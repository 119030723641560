import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, getCurrentUser, ModalService, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, mergeMap, of, take, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalendarDayData, CalendarWeekData } from '../interfaces';
import { GetCalendarDayData, GetCalendarDayDataError, GetCalendarDayDataSuccess, GetCalendarWeekData, GetCalendarWeekDataError, GetCalendarWeekDataSuccess, SaveMessageBoard, SaveMessageBoardError, SaveMessageBoardSuccess } from './calendar.actions';

@Injectable({ providedIn: 'root' })
export class EmailBuilderCalendarEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) {}

    getCalendarDayData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetCalendarDayData),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('orgId', action.payload?.orgId?.toString());
                searchParams.set('databaseId', action.payload?.databaseId?.toString());
                searchParams.set('id', action.payload?.id?.toString());
                searchParams.set('year', action.payload?.year?.toString());
                searchParams.set('month', action.payload?.month?.toString());
                searchParams.set('day', action.payload?.day?.toString());
                searchParams.set('numResults', action.payload?.numResults?.toString());
                searchParams.set('offset', action.payload?.offset?.toString());
                searchParams.set('order', action.payload?.order?.toString());
                searchParams.set('sortBy', action.payload?.sortBy?.toString());

                return this.http
                    .get(environment.apiEndPoint +
                        `/email-builder/calendar/deployment?${searchParams}`,
                    { withCredentials: true })
                    .pipe(
                        take(1),
                        map((res: CalendarDayData) => {
                            const payload: ResponseWrapper<CalendarDayData> = { loading: false, data: res };
                            return GetCalendarDayDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to load calendar day data.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetCalendarDayDataError(error));
                        })
                    );
            })
        )
    );

    getCalendarWeekData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetCalendarWeekData),
            switchMap((action) => {
                return this.http
                    .get(environment.apiEndPoint +
                            `/email-builder/calendar/email-deployment?environmentId=${this.currentDatabase.id}&mode=${action?.payload?.mode}&orgId=${action.payload.orgId}&databaseId=${action.payload.databaseId}&id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&day=${action.payload.day}`,
                    { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: CalendarWeekData) => {
                            const payload: ResponseWrapper<CalendarWeekData> = { loading: false, data: res };
                            return GetCalendarWeekDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to load calendar week data.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(GetCalendarWeekDataError(error));
                        })
                    );
            })
        )
    );
    saveMessageBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SaveMessageBoard),
            exhaustMap((action) => {
                return this.http
                    .post(environment.apiEndPoint +
                            `/email-builder/calendar/message-board?environmentId=${this.currentDatabase.id}`,
                    action.payload,
                    { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return SaveMessageBoardSuccess({ payload: res});
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:'An error has occurred while trying to save message board.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(SaveMessageBoardError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools/filter';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
