import { on } from '@ngrx/store';
import * as IntegrationsAdrollActions from './adroll.actions';
import { IntegrationsState } from '../interfaces';

export const initialAdrollState = {
    adrollRecentAudiences: {
        loading: false,
        data: {
            totalResultCount: null,
            items: []
        }
    },
    adrollAccounts: {
        loading: false,
        data: []
    },
    adrollAccessInfo: {
        loading: false,
        data: null
    },
    adrollAuthInfo: {
        loading: false,
        data: null
    },
};

export const IntegrationsAdrollReducer = [
    on(IntegrationsAdrollActions.GetAdrollRecentAudiences, (state: IntegrationsState) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollRecentAudiences: {
                    loading: true,
                    data: state.adroll?.adrollRecentAudiences?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdrollRecentAudiencesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollRecentAudiences: {
                    loading: false,
                    data: action?.payload?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdrollRecentAudiencesError, (state: IntegrationsState) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollRecentAudiences: {
                    loading: false,
                    data: state.adroll?.adrollRecentAudiences?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdvertiserAccountsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollAccounts: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdrollAccessTokenSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollAccessInfo: action.payload
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdrollAuthInfoSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollAuthInfo: action.payload
            }
        };
    }),
    on(IntegrationsAdrollActions.GetAdrollAccessTokenFromRefreshTokenSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adroll: {
                ...state?.adroll,
                adrollAccessInfo: {
                    ...state.adroll?.adrollAccessInfo,
                    data: {
                        ...state.adroll?.adrollAccessInfo?.data,
                        ...action.payload
                    }
                }
            }
        };
    }),
];
