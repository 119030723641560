import { on } from '@ngrx/store';
import { CustomerDataPlatformState } from '.././interfaces';
import * as CdpMetersActions from './meters.actions';
import { OlyticSiteData } from './meters.interface';

export const ignoredIpsReducer = [
    on(CdpMetersActions.ClearReusableProperty, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: null,
                }
            },
        };
    }),
    on(CdpMetersActions.ClearMeterState, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    [action.key]: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),

    // OLYTICS SITES ACTIONS
    on(CdpMetersActions.GetOlyticsSites, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteList: {
                        loading: true,
                        data: null
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSitesSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteList: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSitesError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteList: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticSiteMetersList, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: null,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticSiteMetersListSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: action?.payload?.response,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticSiteMetersListError, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: null,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteDomain, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    olyticsSiteList: {
                        loading: true,
                        data: state?.metersData?.data?.olyticsSiteList?.data,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteDomainSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    olyticsSiteList: {
                        loading: true,
                        data: state?.metersData?.data?.olyticsSiteList?.data,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteDomainError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    olyticsSiteList: {
                        loading: false,
                        data: state?.metersData?.data?.olyticsSiteList?.data,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteReport, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteReportSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteReportError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: true,
                        data: state?.metersData?.data?.olyticsSiteData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetOlyticsSiteDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateOlyticsSite, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: true,
                        data: state?.metersData?.data?.olyticsSiteData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateOlyticsSiteSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: false,
                        data: action?.payload as OlyticSiteData,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateOlyticsSiteError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    olyticsSiteData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),

    // METERS ACTIONS
    on(CdpMetersActions.ShowUnsavedChangesModal, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    showUnsavedModal: action?.payload?.showModal,
                }
            },
        };
    }),
    on(CdpMetersActions.GetArchivedMeters, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    archivedMeters: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetArchivedMetersSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    archivedMeters: {
                        loading: false,
                        data: action?.payload?.response,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetArchivedMetersError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    archivedMeters: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterPriority, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterPrioritySuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterPriorityError, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.ArchiveMeter, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.ArchiveMeterSuccess, (state: CustomerDataPlatformState, action) => {
        const olyticsSiteList = JSON.parse(JSON.stringify(state?.metersData?.data?.olyticsSiteList?.data || null));
        if (action?.payload && action?.payload?.olyticsSiteId) {
            olyticsSiteList?.olyticsSiteList?.items?.forEach(item => {
                if (item?.olyticsSiteId === action?.payload?.olyticsSiteId) {
                    item.meterCount = item?.meterCount - 1;
                }
            });
        }
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    olyticsSiteList: {
                        loading: false,
                        data: olyticsSiteList,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.ArchiveMeterError, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.RestoreArchivedMeter, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    meterPageLoading: true,
                }
            },
        };
    }),
    on(CdpMetersActions.RestoreArchivedMeterSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    meterPageLoading: false,
                }
            },
        };
    }),
    on(CdpMetersActions.RestoreArchivedMeterError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    meterPageLoading: false
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteMeter, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteMeterSuccess, (state: CustomerDataPlatformState, action) => {
        const olyticsSiteList = JSON.parse(JSON.stringify(state?.metersData?.data?.olyticsSiteList?.data || null));
        if (action?.payload && action?.payload?.olyticsSiteId) {
            olyticsSiteList?.olyticsSiteList?.items?.forEach(item => {
                if (item?.olyticsSiteId === action?.payload?.olyticsSiteId) {
                    item.meterCount = item?.meterCount - 1;
                }
            });
        }
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    olyticsSiteList: {
                        loading: false,
                        data: olyticsSiteList,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteMeterError, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeter, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: true,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeterSuccess, (state: CustomerDataPlatformState, action) => {
        let olyticsSiteList = null;
        if (state?.metersData?.data?.olyticsSiteList?.data) {
            olyticsSiteList = JSON.parse(JSON.stringify(state?.metersData?.data?.olyticsSiteList?.data || null));
            olyticsSiteList?.olyticsSiteList?.items?.forEach(item => {
                if (item?.olyticsSiteId === action?.payload?.olyticsSiteId) {
                    item.meterCount = item?.meterCount + 1;
                }
            });
        }
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                    olyticsSiteList: {
                        loading: false,
                        data: olyticsSiteList,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeterError, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                    olyticsSiteMetersMap: {
                        ...state?.metersData?.data?.olyticsSiteMetersMap,
                        [action?.payload?.olyticsSiteId]: {
                            loading: false,
                            data: state?.metersData?.data?.olyticsSiteMetersMap[action?.payload?.olyticsSiteId]?.data,
                        }
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterReport, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterReportSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterReportError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reportData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: action?.payload?.response,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateMeterData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateMeterDataSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CreateUpdateMeterDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                        hasErrors: true,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterMessageContentData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    messageContentData: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterMessageContentDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    messageContentData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterMessageContentDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    messageContentData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeterMessage, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeterMessageSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.CloneMeterMessageError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterSingleMessageContent, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    selectedMessageContent: {
                        loading: true,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterSingleMessageContentSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    selectedMessageContent: {
                        loading: false,
                        success: true,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterSingleMessageContentError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    selectedMessageContent: {
                        loading: false,
                        errors: true,
                        data: null,
                    }
                }
            },
        };
    }),

    on(CdpMetersActions.DeleteOrResetTester, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteOrResetTesterSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.DeleteOrResetTesterError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.AddTester, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.AddTesterSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: true,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.AddTesterError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    createUpdateMeterData: {
                        loading: false,
                        data: state?.metersData?.data?.createUpdateMeterData?.data || null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterHistory, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterModificationHistory: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterHistorySuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterModificationHistory: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GetMeterHistoryError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterModificationHistory: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GenerateMeterLink, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    generateTestLinkData: {
                        loading: true,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GenerateMeterLinkSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    generateTestLinkData: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.GenerateMeterLinkError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    generateTestLinkData: {
                        loading: false,
                        data: null,
                    }
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInTestMode, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: true,
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInTestModeSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: false,
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInTestModeError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: false,
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInActivateMode, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: true,
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInActivateModeSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: false,
                }
            },
        };
    }),
    on(CdpMetersActions.SetMeterInActivateModeError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterPageLoading: false,
                }
            },
        };
    }),
    on(CdpMetersActions.GetIpAddressExclusionList, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: true,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.GetIpAddressExclusionListSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: action?.payload,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.GetIpAddressExclusionListError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.AddIpAddressToExclusionList, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: true,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.AddIpAddressToExclusionListSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.AddIpAddressToExclusionListError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveIpAddressExclusion, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: true,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveIpAddressExclusionSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveIpAddressExclusionError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    meterIpAddressExlusionList: {
                        loading: false,
                        data: state?.metersData?.data?.meterIpAddressExlusionList?.data || null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveTargetedAudience, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: true,
                        data: null,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveTargetedAudienceSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: action?.payload,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.RemoveTargetedAudienceError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                loading: false,
                data: {
                    ...state?.metersData?.data,
                    reusableProperty: {
                        loading: false,
                        data: null,
                        error: true,
                    },
                }
            },
        };
    }),
    on(CdpMetersActions.SaveMeterMessageContent, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                data: {
                    ...state?.metersData?.data,
                    saveMeterMessage: {
                        loading: true
                    }
                }
            }
        };
    }),
    on(CdpMetersActions.SaveMeterMessageContentSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                data: {
                    ...state?.metersData?.data,
                    saveMeterMessage: {
                        loading: false,
                        data: action?.payload,
                        success: true
                    }
                }
            }
        };
    }),
    on(CdpMetersActions.SaveMeterMessageContentError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            metersData: {
                data: {
                    ...state?.metersData?.data,
                    saveMeterMessage: {
                        loading: false,
                        errors: true
                    }
                }
            }
        };
    }),
    on(CdpMetersActions.ExecuteNavbarActionOnStep, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersData: {
                data: {
                    ...state?.metersData?.data,
                    navBarActions: action?.payload,
                }
            }
        };
    })
];
