import { on } from '@ngrx/store';
import { IEmailBuilderState } from '.././interfaces';
import * as DeploymentDefaultsActions from './deployment-defaults.actions';

export const deploymentDefaultsReducer = [
    on(DeploymentDefaultsActions.GetDeploymentDefaults, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = true;
        sourceData.success = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: true,
                data: {
                    ...state.deploymentDefaults?.data,
                    [action.payload.sourceKey]: sourceData,
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDeploymentDefaultsSuccess, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = false;
        sourceData.success = true;
        sourceData.data = action.payload?.data[action?.payload?.sourceKey];

        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    [action?.payload?.sourceKey]: {
                        ...sourceData,
                        data: {
                            ...action.payload?.data[action?.payload?.sourceKey],
                            audLimitValue: action.payload?.data[action?.payload?.sourceKey]?.audLimitValue || 1
                        }
                    },
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDeploymentDefaultsError, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = false;
        sourceData.success = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    [action?.payload?.sourceKey]: sourceData,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateDeploymentDefaults, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = true;
        sourceData.success = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    [action.payload.sourceKey]: sourceData,
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateDeploymentDefaultsSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateDeploymentDefaultsError, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = false;
        sourceData.success = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    [action?.payload?.sourceKey]: sourceData,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactList, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    contactList: {
                        loading: true,
                        data: state.deploymentDefaults?.data?.contactList?.data,
                    },
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    contactList: {
                        loading: false,
                        data: action?.payload,
                    },
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    contactList: {
                        loading: false,
                        data: state.deploymentDefaults?.data?.contactList?.data,
                    },
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactDetails, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: true,
                data: {
                    ...state.deploymentDefaults?.data,
                    contactDetails: {
                        loading: true,
                        data: null
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactDetailsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    contactDetails: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetContactDetailsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    contactDetails: {
                        loading: false,
                        data: null,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateContactDetails, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: true,
                data: {
                    ...state.deploymentDefaults?.data,
                    contactDetails: {
                        loading: true,
                        data: state.deploymentDefaults?.data?.contactDetails?.data,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateContactDetailsSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    contactDetails: {
                        loading: false,
                        data: state.deploymentDefaults?.data?.contactDetails?.data,
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UpdateContactDetailsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    contactDetails: {
                        loading: false,
                        data: state.deploymentDefaults?.data?.contactDetails?.data,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.ClearContactDetails, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    contactDetails: {
                        data: null,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetFinalApproverList, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: true,
                data: {
                    ...state.deploymentDefaults?.data,
                    finalApproverList: [],
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetFinalApproverListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    finalApproverList: action?.payload
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetFinalApproverListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    finalApproverList: [],
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.DomainAndEmailSuppressionTestFile, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.data?.emailSuppression;
        const data = JSON.parse(JSON.stringify(state?.deploymentDefaults?.data?.supressionFiles[action?.payload?.sourceKey]));
        if (!type) {
            data['domain'] = {
                data: null,
                loading: true
            };
        } else if (type) {
            data['email'] = {
                data: null,
                loading: true
            };
        }
        return {
            ...state,
            deploymentDefaults: {
                data: {
                    ...state.deploymentDefaults?.data,
                    supressionFiles: {
                        ...state.deploymentDefaults?.data?.supressionFiles,
                        [action.payload.sourceKey]: data
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.DomainAndEmailSuppressionTestFileSuccess, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.emailSuppression;
        const data = JSON.parse(JSON.stringify(state?.deploymentDefaults?.data?.supressionFiles[action?.payload?.sourceKey]));
        if (!type) {
            data['domain'] = {
                data: action?.payload?.data?.domain,
                loading: false
            };
        } else if (type) {
            data['email'] = {
                data: action?.payload?.data?.email,
                loading: false
            };
        }
        return {
            ...state,
            deploymentDefaults: {
                data: {
                    ...state?.deploymentDefaults?.data,
                    supressionFiles: {
                        ...state.deploymentDefaults?.data?.supressionFiles,
                        [action.payload.sourceKey]: data
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.DomainAndEmailSuppressionTestFileError, (state: IEmailBuilderState, action) => {
        const type = action?.payload?.emailSuppression;
        const data = JSON.parse(JSON.stringify(state?.deploymentDefaults?.data?.supressionFiles[action?.payload?.sourceKey]));
        if (!type) {
            data['domain'] = {
                data: null,
                loading: false
            };
        } else if (type) {
            data['email'] = {
                data: null,
                loading: false
            };
        }
        return {
            ...state,
            deploymentDefaults: {
                data: {
                    ...state?.deploymentDefaults?.data,
                    supressionFiles: {
                        ...state.deploymentDefaults?.data?.supressionFiles,
                        [action.payload.sourceKey]: data
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPreferencePageData, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    preferencePageData: {
                        loading: true,
                        data: null
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPreferencePageDataSuccess, (state: IEmailBuilderState, action) => {
        const databaseDefaultsData = JSON.parse(JSON.stringify(state?.deploymentDefaults?.data?.databaseDefaults));
        const deploymentDefaultsData = JSON.parse(JSON.stringify(state?.deploymentDefaults?.data?.deploymentDefaults));
        if (action?.payload && action?.payload?.listPrefPage?.length) {
            if (databaseDefaultsData?.data && databaseDefaultsData?.data?.listPrefPage && databaseDefaultsData?.data?.listPrefPage?.length) {
                databaseDefaultsData.data.listPrefPage = action?.payload?.listPrefPage;
            }
            if (deploymentDefaultsData?.data && deploymentDefaultsData?.data?.listPrefPage && deploymentDefaultsData?.data?.listPrefPage?.length) {
                deploymentDefaultsData.data.listPrefPage = action?.payload?.listPrefPage;
            }
        }
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    databaseDefaults: { ...databaseDefaultsData },
                    deploymentDefaults: { ...deploymentDefaultsData },
                    preferencePageData: {
                        loading: false,
                        data: action?.payload
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPreferencePageDataError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    preferencePageData: {
                        loading: false,
                        data: null,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.SavePreferencePageData, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    savePreferencePage: {
                        loading: true,
                        data: null
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.SavePreferencePageDataSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    savePreferencePage: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.SavePreferencePageDataError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    savePreferencePage: {
                        loading: false,
                        data: { isErrored: true },
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.ClearPreferencePageData, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state?.deploymentDefaults?.data,
                    preferencePageData: {
                        loading: false,
                        data: null,
                    },
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UploadSeedFromFile, (state: IEmailBuilderState, action) => {
        const deplDefaultsData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data));
        if (action?.payload?.sourceKey) {
            deplDefaultsData[action?.payload?.sourceKey].loading = true;
        } else if (action?.payload?.mode) {
            deplDefaultsData.contactList.loading = true;
        }
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...deplDefaultsData
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.UploadSeedFromFileSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.UploadSeedFromFileError, (state: IEmailBuilderState, action) => {
        const deplDefaultsData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data));
        if (action?.payload?.sourceKey) {
            deplDefaultsData[action?.payload?.sourceKey].loading = false;
        } else if (action?.payload?.mode) {
            deplDefaultsData.contactList.loading = false;
        }
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...deplDefaultsData
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.AddRecipientDeploymentDefaults, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    addRecipient: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.AddRecipientDeploymentDefaultsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    addRecipient: {
                        loading: false,
                        data: action?.payload
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.AddRecipientDeploymentDefaultsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    addRecipient: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.DeleteRecipients, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = true;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    [action.payload.sourceKey]: sourceData,
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.DeleteRecipientsSuccess, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    [action.payload.sourceKey]: sourceData,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.DeleteRecipientsError, (state: IEmailBuilderState, action) => {
        const sourceData = JSON.parse(JSON.stringify(state.deploymentDefaults?.data[action?.payload?.sourceKey]));
        sourceData.loading = false;
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    [action.payload.sourceKey]: sourceData,
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetSingleResponseDemographics, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetSingleResponseDemographicsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: action?.payload
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetSingleResponseDemographicsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPromocodeProduct, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    promocodeProduct: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPromocodeProductSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    promocodeProduct: {
                        loading: false,
                        data: action?.payload
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetPromocodeProductError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    promocodeProduct: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicDemographics, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicDemographicsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: action?.payload
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicDemographicsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicBehaviors, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: {
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicBehaviorsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: action?.payload
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.GetDynamicBehaviorsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
    on(DeploymentDefaultsActions.ClearDemographicData, (state: IEmailBuilderState) => {
        return {
            ...state,
            deploymentDefaults: {
                loading: false,
                data: ({
                    ...state.deploymentDefaults?.data,
                    demographicsData: {
                        loading: false,
                        data: null
                    },
                    promocodeProduct: {
                        loading: false,
                        data: null
                    }
                })
            }
        };
    }),
];
