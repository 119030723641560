import { ResponseWrapper } from 'common';

export const behaviorsInitialState: BehaviorsState = {
    behaviorsData: {
        loading: false,
        data: null
    },
    selectedBehaviorData: {
        loading: false,
        data: null
    },
    downloadLoader: false,
    behaviorAttributesData: {
        loading: false,
        data: null
    },
    behaviorActionsData: {
        loading: false,
        data: null
    },
    behaviorTypes: {
        loading: false,
        data: null
    },
    behaviorTypeActions: {
        loading: false,
        data: null
    },
    selectedBehavior: {
        loading: false,
        data: null
    },
    selectedBehaviorAttribute: {
        loading: false,
        data: null
    },
    attributeTypes: {
        loading: false,
        data: null
    },
    selectedBehaviorAction: {
        loading: false,
        data: null
    },
    actionTypes: {
        loading: false,
        data: null
    }
};

export interface BehaviorsState {
    behaviorsData?: ResponseWrapper<BehaviorsListRes>;
    selectedBehaviorData?: ResponseWrapper<SelectedBehaviorRes>;
    downloadLoader?: boolean;
    behaviorAttributesData?: ResponseWrapper<BehaviorAttributeRes>;
    behaviorActionsData?: ResponseWrapper<BehaviorActionRes>;
    behaviorTypes?: ResponseWrapper<Record<string, string>>;
    behaviorTypeActions?: ResponseWrapper<Record<string, string>>;
    selectedBehavior?: ResponseWrapper<SelectedBehaviorData>;
    selectedBehaviorAttribute?: ResponseWrapper<BehaviorAttributeItem>;
    attributeTypes?: ResponseWrapper<Record<string, string>>;
    selectedBehaviorAction?: ResponseWrapper<BehaviorActionItem>;
    actionTypes?: ResponseWrapper<Record<string, string>>;
}

export interface BehaviorsListRes {
    totalResultCount: number;
    items: Array<BehaviorData>;
}

export interface BehaviorData {
    behaviorId: number,
    environmentId: number,
    type: number,
    name: string,
    shortDescription?: string,
    usageType?: number,
    auditLocked?: boolean,
    calcField?: boolean,
    updateableWhileClosed?: boolean,
    unitField?: boolean,
    passAlong?: boolean,
    aggregateData?: boolean,
    isAudited?: boolean,
    createdDate?: string,
    createdBy?: string,
    changedDate?: string,
    changedBy?: string,
    updateCounter?: number,
    status?: number,
    captureDemoHistory?: boolean,
    replaceRuleCode?: number,
    jobTitleDemo?: boolean,
    omailRuleDemo?: boolean,
    consolidated?: boolean,
    webformText?: string,
    behaviorValues?: Array<BehaviorValue>
}

export interface SelectedBehaviorRes {
    success?: boolean,
    behaviorId?: number,
    message?: string,
    behavior?: BehaviorData
}

export interface BehaviorValue {
    shortDescription?: string,
    valueId?: number,
    valueText?: string,
    valueType?: number,
    status?: number,
    sequence?: number,
    legacyValueId?: string,
    alternateId?: string,
    webformText?: string,
    dragonHide?: number,
    delete?: boolean
}

export interface SelectedBehaviorData {
    actionId?: number,
    behaviorId?: number,
    typeId?: number,
    behaviorName?: string,
    productId?: number
    createdBy?: string,
    profiles?: Array<number>,
    allProfiles?: boolean,
}

export interface SelectedBehaviorSaveReq {
    behaviorAttributeName: string,
    typeId?: number,
    behaviorAttributeId?: number,
    definedValues?: DefinedValuesItem[],
}

export interface BehaviorAttributeRes {
    totalResultCount?: number,
    items: BehaviorAttributeItem[],
}

export interface BehaviorAttributeItem {
    behaviorAttributeName?: string,
    behaviorAttributeId?: number,
    typeId?: number,
    type?: string,
    changedDate?: string,
    changedBy?: string,
    createdBy?: string,
    definedValues: DefinedValuesItem[],
}

export interface DefinedValuesItem {
    valueId?: number,
    value?: string,
}

export interface BehaviorActionRes {
    totalResultCount?: number,
    items: BehaviorActionItem[],
}

export interface BehaviorActionItem {
    behaviorActionName?: string,
    behaviorActionId?: number,
    typeId?: number,
    type?: string,
    products?: string,
    changedDate?: string,
    changedBy?: string,
    createdBy?: string,
}