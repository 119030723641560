import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DeployemntTypesActions from './deployment-types.actions';
import { DeploymentTypeListRes, DeploymentTypeProductList, SelectedDeploymentType } from './deployment-types.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceDeploymentTypeEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadDeploymentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntTypesActions.LoadDeploymentTypes),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || 'false');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-type/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DeploymentTypeListRes) => {
                            const payload: ResponseWrapper<DeploymentTypeListRes> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntTypesActions.LoadDeploymentTypesDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load deployment types.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntTypesActions.LoadDeploymentTypesDataError(error));
                        })
                    );
            })
        )
    );

    downloadDeploymentProductsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntTypesActions.DownloadDeploymentTypesList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-type/download-list?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = 'deployment-types';
                            a.click();
                            window.URL.revokeObjectURL(url);

                            return DeployemntTypesActions.DownloadDeploymentTypesListSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download deployment types list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntTypesActions.DownloadDeploymentTypesListError(error));
                        })
                    );
            })
        )
    );

    getSelectedDeploymentType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntTypesActions.GetSelectedDeploymentTypeData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('deploymentTypeId', action.payload?.deploymentTypeId?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-type?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDeploymentType) => {
                            const payload: ResponseWrapper<SelectedDeploymentType> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntTypesActions.GetSelectedDeploymentTypeDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load deployment type.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['self-service', 'main', 'deployment-types']);
                            this.showError(null, errorMessage);
                            return of(DeployemntTypesActions.GetSelectedDeploymentTypeDataError(error));
                        })
                    );
            })
        )
    );

    saveDeploymentType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntTypesActions.SaveDeploymentType),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/deployment-type?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                data: res,
                                loading: false
                            };
                            if (res?.errors && res?.errors?.length > 0 && !res?.success) {
                                this.store.dispatch(ShowToast({ payload: { message: res?.errors?.[0], placement: 'TopEnd', design: 'Warning', duration: 6000 } }));
                            } else {
                                this.showSuccess('', `Deployment type has been ${action.payload?.deploymentTypeId ? 'saved' : 'created'} successfully.`);
                            }
                            this.router.navigate(['self-service', 'main', 'deployment-types']);
                            return DeployemntTypesActions.SaveDeploymentTypeSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save deployment type.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntTypesActions.SaveDeploymentTypeError(error));
                        })
                    );
            })
        )
    );

    getDeploymentTypeProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeployemntTypesActions.GetDeploymentTypeProducts),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('deploymentTypeId', action.payload.deploymentTypeId?.toString() || '');
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/deployment-type/available-products?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DeploymentTypeProductList) => {
                            const payload: ResponseWrapper<DeploymentTypeProductList> = {
                                data: res,
                                loading: false
                            };
                            return DeployemntTypesActions.GetDeploymentTypeProductsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load deployment type products.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DeployemntTypesActions.GetDeploymentTypeProductsError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
