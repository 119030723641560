import { on } from '@ngrx/store';
import { IEmailBuilderState } from '../interfaces';
import * as ApprovalQueueActions from './approval-queue.actions';

export const approvalQueueReducer = [
    on(ApprovalQueueActions.ApprovalQueueSearchDeployments, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchApprovalQueueDeploymentsResult: {
                data: null,
                loading: true,
            }
        };
    }),
    on(ApprovalQueueActions.SearchApprovalQueueDeploymentsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            searchApprovalQueueDeploymentsResult: action.payload
        };
    }),
    on(ApprovalQueueActions.SearchApprovalQueueDeploymentsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchApprovalQueueDeploymentsResult: {
                data: null,
                loading: false,
            }
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetDeploymentDetails, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            approvalQueueSplitsResponseMap: {
                ...state.approvalQueueSplitsResponseMap,
                [action.payload.id]: {
                    data: null,
                    loading: true,
                }
            }
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetDeploymentDetailsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            approvalQueueSplitsResponseMap: {
                ...state.approvalQueueSplitsResponseMap,
                [action.payload.data.deploymentId]: { ...action.payload }
            }
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetSplitSpamReportSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            approvalQueueSplitSpamReportResult: action.payload
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetSplitSpamReport, (state: IEmailBuilderState) => {
        return {
            ...state,
            approvalQueueSplitSpamReportResult: {
                data: null,
                loading: true,
            }
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetSplitSpamReportError, (state: IEmailBuilderState) => {
        return {
            ...state,
            approvalQueueSplitSpamReportResult: {
                data: null,
                loading: false,
            }
        };
    }),
    on(ApprovalQueueActions.ApprovalQueueGetDateDropdownOptionsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            approvalQueueDateDropdownOptions: action.payload
        };
    }),
];
