import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { IOdysseyState } from './interfaces';

const initialAppState: IOdysseyState = {
    voyagesData: {
        loading: false,
        data: null
    },
    customTemplateVoyages: {
        loading: false,
        data: []
    }
};

function getInitalState(): IOdysseyState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

function getSavedState(): IOdysseyState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.SearchVoyages, (state) => {
        return {
            ...state,
            voyagesData: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SearchVoyagesSuccess, (state, action) => {
        return {
            ...state,
            voyagesData: {
                loading: false,
                data: action?.payload
            }
        };
    }),
    on(AppActions.SearchVoyagesError, (state) => {
        return {
            ...state,
            voyagesData: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetTemplatesAndMiscData, (state) => {
        return {
            ...state,
            customTemplateVoyages: {
                loading: true,
                data: []
            },
            miscellaneousData: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetTemplatesAndMiscDataSuccess, (state, action) => {
        const miscellaneousData = JSON.parse(JSON.stringify(action?.payload));
        let customTemplateVoyages = [];
        if (action?.payload?.customTemplateVoyages) {
            customTemplateVoyages = JSON.parse(JSON.stringify(action?.payload?.customTemplateVoyages));
            delete miscellaneousData.customTemplateVoyages;
        }
        if (!action?.payload?.standardTemplateVoyages) {
            miscellaneousData.standardTemplateVoyages = [];
        }
        return {
            ...state,
            customTemplateVoyages: {
                loading: false,
                data: customTemplateVoyages
            },
            miscellaneousData: {
                loading: false,
                data: miscellaneousData
            }
        };
    }),
    on(AppActions.GetTemplatesAndMiscDataError, (state) => {
        return {
            ...state,
            customTemplateVoyages: {
                loading: false,
                data: []
            },
            miscellaneousData: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.UpdateVoyageName, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.UpdateVoyageNameSuccess, (state, action) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.UpdateVoyageNameError, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.CloneVoyage, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.CloneVoyageSuccess, (state, action) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.CloneVoyageError, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.ArchiveVoyage, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.ArchiveVoyageSuccess, (state, action) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.ArchiveVoyageError, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.RestoreVoyage, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.RestoreVoyageSuccess, (state, action) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.RestoreVoyageError, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.ClearVoyageAction, (state) => {
        return {
            ...state,
            voyageActions: {
                loading: false,
                data: null
            },
            updateGlobalSettings: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetGlobalSettings, (state) => {
        return {
            ...state,
            globalSettings: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.GetGlobalSettingsSuccess, (state, action) => {
        return {
            ...state,
            globalSettings: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.GetGlobalSettingsError, (state) => {
        return {
            ...state,
            globalSettings: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.UpdateGlobalSettings, (state) => {
        return {
            ...state,
            updateGlobalSettings: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.UpdateGlobalSettingsSuccess, (state, action) => {
        return {
            ...state,
            updateGlobalSettings: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.UpdateGlobalSettingsError, (state) => {
        return {
            ...state,
            updateGlobalSettings: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.GetAnalyticsReport, (state) => {
        return {
            ...state,
            analyticsReport: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.GetAnalyticsReportSuccess, (state, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        const newReportData = [];
        data?.reportData?.forEach(reportDataItem => {
            const newElementReport = [];
            reportDataItem?.elementReport?.forEach(elementReportItem => {
                const newElemenmtReportData = [];
                elementReportItem?.reportData?.forEach(elementReportDataItem => {
                    const reportDataItemObj = {};
                    elementReportDataItem?.forEach(reportDataInnerItem => {
                        const desc = reportDataInnerItem?.description?.slice(0, 1)?.toLowerCase() + reportDataInnerItem?.description?.slice(1);
                        reportDataItemObj[desc] = reportDataInnerItem?.value ? reportDataInnerItem?.value : '';
                    });
                    newElemenmtReportData.push(reportDataItemObj);
                });
                newElementReport.push({ name: elementReportItem?.name, reportDataSubRows: newElemenmtReportData });
            });
            const mainReportData = {};
            if (reportDataItem?.dataSize) {
                mainReportData['dataSize'] = reportDataItem?.dataSize;
            }
            if (reportDataItem?.combinedReportLink) {
                mainReportData['combinedReportLink'] = reportDataItem?.combinedReportLink;
            }
            mainReportData['reportDataRows'] = newElementReport;
            newReportData[reportDataItem?.name] = mainReportData;
        });
        data.reportData = newReportData;
        return {
            ...state,
            analyticsReport: {
                loading: false,
                data: data
            },
        };
    }),
    on(AppActions.GetAnalyticsReportError, (state) => {
        return {
            ...state,
            analyticsReport: {
                loading: false,
                data: null
            },
        };
    }),
    on(AppActions.GetTestHistory, (state) => {
        return {
            ...state,
            testHistory: {
                loading: true,
                data: null
            },
        };
    }),
    on(AppActions.GetTestHistorySuccess, (state, action) => {
        return {
            ...state,
            testHistory: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.GetTestHistoryError, (state) => {
        return {
            ...state,
            testHistory: {
                loading: false,
                data: null
            },
        };
    }),

    on(AppActions.GetAnalyticsHistory, (state) => {
        return {
            ...state,
            analyticsHistory: {
                loading: true,
                data: []
            },
        };
    }),
    on(AppActions.GetAnalyticsHistorySuccess, (state, action) => {
        return {
            ...state,
            analyticsHistory: {
                loading: false,
                data: action?.payload
            },
        };
    }),
    on(AppActions.GetAnalyticsHistoryError, (state) => {
        return {
            ...state,
            analyticsHistory: {
                loading: false,
                data: []
            },
        };
    }),
);

// // tslint:disable-next-line: typedef
export function odysseyReducer(state: IOdysseyState | undefined, action: Action) {
    return appReducer(state, action);
}
