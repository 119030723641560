import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ChangeMainPath, getCurrentUser, ModalService, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, mergeMap, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetDeploymentTypesListError } from './summary-stats.action';
import { GetDeploymentTypesListSuccess } from './summary-stats.action';
import { GetDeploymentTypesList } from './summary-stats.action';
import { GenerateReport, GenerateReportError, GenerateReportSuccess, GetDesignationTypesList, GetDesignationTypesListError, GetDesignationTypesListSuccess, GetDomainsList, GetDomainsListError, GetDomainsListSuccess, GetReportCriteriaList, GetReportCriteriaListError, GetReportCriteriaListSuccess, GetReportTableColumns, GetReportTableColumnsError, GetReportTableColumnsSuccess, GetYearList, GetYearListError, GetYearListSuccess, LoadReportCriteria, LoadReportCriteriaError, LoadReportCriteriaSuccess, SaveReportCriteria, SaveReportCriteriaError, SaveReportCriteriaSuccess } from './summary-stats.action';
import { DeploymentType, ReportCriteria, ReportCriteriaItem } from './summary-stats.interfaces';
import { DesignationTypeItem, Domain, ReportTableColumn } from './summary-stats.interfaces';

@Injectable({ providedIn: 'root' })
export class EmailBuilderSummaryStatsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) { }

    getYearList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetYearList),
            mergeMap(() => {
                return this.http.get(this.baseUrl + '/year', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { years: Array<number> }) => {
                            return GetYearListSuccess({ payload: res?.years });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get year list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetYearListError(error));
                        })
                    );
            })
        )
    );
    getReportTableColumns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReportTableColumns),
            mergeMap(() => {
                return this.http.get(this.baseUrl + `/report-table-columns?environmentId=${this.currentDatabase.id}&orgId=0`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ReportTableColumn>) => {
                            return GetReportTableColumnsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get report table columns.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetReportTableColumnsError(error));
                        })
                    );
            })
        )
    );
    getDeploymentTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDeploymentTypesList),
            mergeMap((action) => {
                return this.http.put(this.baseUrl + `/deployment-types?environmentId=${this.currentDatabase.id}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<DeploymentType>) => {
                            return GetDeploymentTypesListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get deployment types list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDeploymentTypesListError(error));
                        })
                    );
            })
        )
    );
    getReportCriteriaList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReportCriteriaList),
            mergeMap((action) => {
                return this.http.get(this.baseUrl + `/report-criteria-list?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ReportCriteriaItem>) => {
                            return GetReportCriteriaListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get report criteria list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetReportCriteriaListError(error));
                        })
                    );
            })
        )
    );
    loadReportCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadReportCriteria),
            mergeMap((action) => {
                return this.http.get(this.baseUrl + `/load-report-criteria?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&criteriaId=${action?.payload?.criteriaId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ReportCriteria) => {
                            return LoadReportCriteriaSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load selected report criteria.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(LoadReportCriteriaError(error));
                        })
                    );
            })
        )
    );
    getDesignationTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDesignationTypesList),
            mergeMap((action) => {
                return this.http.get(this.baseUrl + `/designation-type?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<DesignationTypeItem>) => {
                            return GetDesignationTypesListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get designation types list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDesignationTypesListError(error));
                        })
                    );
            })
        )
    );
    getDomainsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDomainsList),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `/domains?environmentId=${this.currentDatabase.id}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<Domain>) => {
                            return GetDomainsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get domains list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GetDomainsListError(error));
                        })
                    );
            })
        )
    );
    generateReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerateReport),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `?environmentId=${this.currentDatabase.id}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (!res.success && res.errors && res.errors.length) {
                                this.showError(null, res.errors[0]);
                            }
                            if (res && res?.success && res?.messages?.length) {
                                const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                                width=600,height=300,left=100,top=100`;
                                const url = res.messages[0];
                                window.open(url, '', params);
                            }
                            return GenerateReportSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to generate report.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(GenerateReportError(error));
                        })
                    );
            })
        )
    );
    saveReportCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SaveReportCriteria),
            exhaustMap((action) => {
                return this.http
                    .post(this.baseUrl + `/save?environmentId=${this.currentDatabase.id}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.success && res.messages && res.messages.length) {
                                this.showSuccess(null, res.messages[0]);
                            }
                            this.store.dispatch(GetReportCriteriaList({ payload: { orgId: action?.payload?.orgId?.toString() } }));
                            return SaveReportCriteriaSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save report criteria.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(SaveReportCriteriaError(error));
                        })
                    );
            })
        )
    );

    changePath(path, queryParams) {
        if (!path) {
            return;
        }
        this.store.dispatch(
            ChangeMainPath({
                payload: {
                    path: [path],
                    queryParams,
                    extras: null,
                },
            })
        );
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/summary-stats';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
