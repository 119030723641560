import { on } from '@ngrx/store';
import * as IntegrationsMetaEventsManagerActions from './meta-events-manager.actions';
import { IntegrationsState } from '../interfaces';

export const initialMetaEventsManagerState = {
    metaEventsSites: {
        loading: false,
        data: {
            totalResultCount: null,
            items: []
        }
    },
    metaEventsPreviousPixels: {
        loading: false,
        data: []
    },
    siteReport: {
        loading: false,
        data: null
    },
    facebookPixel: {
        loading: false,
        data: null
    },
    sitePixels: {
        loading: false,
        data: null
    },
    sitePixelsArchived: {
        loading: false,
        data: null
    },
    facebookPixelPerformance: {
        loading: false,
        data: null
    },
};

export const IntegrationsMetaEventsManagerReducer = [
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsSites, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsSites: {
                    loading: true,
                    data: state.metaEventsManager?.metaEventsSites?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsSitesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsSites: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsSitesError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsSites: {
                    loading: false,
                    data: state.metaEventsManager?.metaEventsSites?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixels, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsPreviousPixels: {
                    loading: true,
                    data: state.metaEventsManager?.metaEventsPreviousPixels?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixelsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsPreviousPixels: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixelsError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                metaEventsPreviousPixels: {
                    loading: false,
                    data: state.metaEventsManager?.metaEventsPreviousPixels?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewSiteReport, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                siteReport: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewSiteReportSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                siteReport: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewSiteReportError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                siteReport: {
                    loading: false,
                    data: state.metaEventsManager?.siteReport?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.LoadFacebookPixel, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.LoadFacebookPixelSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.LoadFacebookPixelError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: false,
                    data: state.metaEventsManager?.facebookPixel?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.SaveFacebookPixel, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: true,
                    data: state.metaEventsManager?.facebookPixel?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.SaveFacebookPixelSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.SaveFacebookPixelError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: false,
                    data: state.metaEventsManager?.facebookPixel?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSitePixels, (state: IntegrationsState, action) => {
        const sitePixels = Object.assign({}, state.metaEventsManager?.sitePixels?.data);

        if (sitePixels[action.payload?.olyticsSiteId]) {
            sitePixels[action.payload?.olyticsSiteId] = { ...sitePixels[action.payload.olyticsSiteId], loading: true };
        } else {
            sitePixels[action.payload?.olyticsSiteId] = { loading: true };
        }

        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixels: {
                    loading: true,
                    data: sitePixels
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSitePixelsSuccess, (state: IntegrationsState, action) => {
        const sitePixels = Object.assign({}, state.metaEventsManager?.sitePixels?.data);
        sitePixels[action.payload?.data?.olyticsSiteId] = { pixels: [...action.payload?.data?.res], loading: false };
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixels: {
                    loading: false,
                    data: sitePixels
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSitePixelsError, (state: IntegrationsState, action) => {
        const sitePixels = Object.assign({}, state.metaEventsManager?.sitePixels?.data);
        sitePixels[action.payload?.olyticsSiteId] = { ...sitePixels[action.payload?.olyticsSiteId], loading: false };
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixels: {
                    loading: false,
                    data: sitePixels
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixels, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixelsArchived: {
                    loading: true,
                    data: [],
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixelsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixelsArchived: {
                    loading: false,
                    data: action?.payload?.data,
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixelsError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                sitePixelsArchived: {
                    loading: false,
                    data: [],
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.SetCurrentPixelState, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixel: {
                    loading: false,
                    data: { ...state.metaEventsManager?.facebookPixel?.data, ...action.payload }
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEvent, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: true
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEventSuccess, (state: IntegrationsState, action) => {
        let facebookPixelAudiences = [];
        if (state.metaEventsManager?.facebookPixel?.data?.facebookPixelAudiences) {
            facebookPixelAudiences = [...state.metaEventsManager?.facebookPixel?.data?.facebookPixelAudiences];
        }
        facebookPixelAudiences.push({
            audienceName: action.payload?.data?.audienceName,
            type: 'Audience Builder',
            targetCode: 1,
            facebookPixelSegmentAudienceId: action.payload?.data?.facebookPixelSegmentAudienceId
        });
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: false,
                facebookPixel: {
                    loading: false,
                    data: {
                        ...state.metaEventsManager?.facebookPixel?.data,
                        facebookPixelAudiences: [...facebookPixelAudiences]
                    }
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEventError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: false,
                facebookPixel: {
                    loading: false,
                    data: state.metaEventsManager?.facebookPixel?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.DeletePixelAudience, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: true
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.DeletePixelAudienceSuccess, (state: IntegrationsState, action) => {
        const facebookPixelAudiences = state.metaEventsManager?.facebookPixel?.data?.facebookPixelAudiences.filter((audience) => audience.facebookPixelSegmentAudienceId !== action.payload?.data?.facebookPixelSegmentAudienceId);
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: false,
                facebookPixel: {
                    loading: false,
                    data: {
                        ...state.metaEventsManager?.facebookPixel?.data,
                        facebookPixelAudiences: [...facebookPixelAudiences]
                    }
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.DeletePixelAudienceError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                audienceTargetTableLoading: false,
                facebookPixel: {
                    loading: false,
                    data: state.metaEventsManager?.facebookPixel?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewPixelEventReport, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixelPerformance: {
                    loading: true,
                    data: state.metaEventsManager?.facebookPixelPerformance?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewPixelEventReportSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixelPerformance: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsMetaEventsManagerActions.ViewPixelEventReportError, (state: IntegrationsState) => {
        return {
            ...state,
            metaEventsManager: {
                ...state?.metaEventsManager,
                facebookPixelPerformance: {
                    loading: false,
                    data: state.metaEventsManager?.facebookPixelPerformance?.data
                }
            }
        };
    }),
];
