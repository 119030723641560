import { on } from '@ngrx/store';
import { CustomerDataPlatformState } from '.././interfaces';
import * as CdpOlyticsActions from './olytics.actions';
import { UrlRestrictionsResponse } from './olytics.interface';

export const olyticsReducer = [

    on(CdpOlyticsActions.GetOlyticsImplementationData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsImplementationData: {
                    loading: true,
                    data: state?.olyticsData?.olyticsImplementationData?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsImplementationDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsImplementationData: {
                    loading: false,
                    data: action?.payload?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsImplementationDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsImplementationData: {
                    loading: false,
                    data: state?.olyticsData?.olyticsImplementationData?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetOlyticsBehaviors, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviorData: {
                    loading: true,
                    data: state?.olyticsData?.olyticsBehaviorData?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsBehaviorsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviorData: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsBehaviorsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviorData: {
                    loading: false,
                    data: state?.olyticsData?.olyticsBehaviorData?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetOlyticsDomains, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsDomainsData: {
                    loading: true,
                    data: state?.olyticsData?.olyticsDomainsData?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsDomainsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsDomainsData: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsDomainsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsDomainsData: {
                    loading: false,
                    data: state?.olyticsData?.olyticsDomainsData?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveOlyticsBehavior, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviorData: {
                    loading: true,
                    data: state?.olyticsData?.olyticsBehaviorData?.data
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetOlyticsProfiles, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsProfiles: {
                    loading: true,
                    data: state?.olyticsData?.olyticsProfiles?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsProfilesSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsProfiles: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsProfilesError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsProfiles: {
                    loading: false,
                    data: state?.olyticsData?.olyticsProfiles?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetOlyticsTags, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTags: {
                    loading: true,
                    data: state?.olyticsData?.olyticsTags?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsTagsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTags: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetOlyticsTagsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTags: {
                    loading: false,
                    data: state?.olyticsData?.olyticsTags?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetContentRecommendationBehaviors, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviors: {
                    loading: true,
                    data: state?.olyticsData?.olyticsBehaviors?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetContentRecommendationBehaviorsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviors: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetContentRecommendationBehaviorsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviors: {
                    loading: false,
                    data: state?.olyticsData?.olyticsBehaviors?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetBehaviorPageTypeExclusions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsPageTypeExclusions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetBehaviorPageTypeExclusionsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetBehaviorPageTypeExclusionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsPageTypeExclusions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetAvailableSpecificExclusions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsAvailableSpecificExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsAvailableSpecificExclusions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetAvailableSpecificExclusionsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsAvailableSpecificExclusions: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetAvailableSpecificExclusionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsAvailableSpecificExclusions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsAvailableSpecificExclusions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetSelectedSpecificExclusions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsSelectedSpecificExclusions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetSelectedSpecificExclusionsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: false,
                    data: action?.payload?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetSelectedSpecificExclusionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsSelectedSpecificExclusions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetUrlStringRestrictions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsUrlStringRestrictions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsUrlStringRestrictions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetUrlStringRestrictionsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsUrlStringRestrictions: {
                    loading: false,
                    // TODO: this is a hack, need to fix typings
                    // parse to unknown to avoid type error
                    // will have to check typings inconsistency
                    data: action?.payload?.data as unknown as UrlRestrictionsResponse
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetUrlStringRestrictionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsUrlStringRestrictions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsUrlStringRestrictions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveBehaviorPageTypeExlusions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsPageTypeExclusions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveBehaviorPageTypeExlusionsSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsPageTypeExclusions?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveBehaviorPageTypeExlusionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsPageTypeExclusions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsPageTypeExclusions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveUrlStringRestrictions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsUrlStringRestrictions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsUrlStringRestrictions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveUrlStringRestrictionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsUrlStringRestrictions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsUrlStringRestrictions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveSpecificExclusions, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsSelectedSpecificExclusions?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveSpecificExclusionsSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: true,
                    data: state?.olyticsData?.olyticsSelectedSpecificExclusions?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveSpecificExclusionsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsSelectedSpecificExclusions: {
                    loading: false,
                    data: state?.olyticsData?.olyticsSelectedSpecificExclusions?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveOlyticsBehaviorError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsBehaviorData: {
                    loading: false,
                    data: state?.olyticsData?.olyticsBehaviorData?.data
                }
            },
        };
    }),

    on(CdpOlyticsActions.GetTitleSource, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: true,
                    data: state?.olyticsData?.olyticsTitleSource?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetTitleSourceSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: false,
                    data: action?.payload?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.GetTitleSourceError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: false,
                    data: state?.olyticsData?.olyticsTitleSource?.data,
                }
            },
        };
    }),

    on(CdpOlyticsActions.SaveTitleSource, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: true,
                    data: state?.olyticsData?.olyticsTitleSource?.data
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveTitleSourceSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: true,
                    data: state?.olyticsData?.olyticsTitleSource?.data,
                }
            },
        };
    }),
    on(CdpOlyticsActions.SaveTitleSourceError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsData: {
                ...state?.olyticsData,
                olyticsTitleSource: {
                    loading: false,
                    data: state?.olyticsData?.olyticsTitleSource?.data,
                }
            },
        };
    }),

];
