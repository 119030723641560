import { on } from '@ngrx/store';
import { IEmailBuilderState } from '.././interfaces';
import * as SearchActions from './search.actions';

export const searchReducer = [
    on(SearchActions.SearchRegularDeployments, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRegularDeploymentsResult: {
                loading: true,
                data: null
            }
        };
    }),
    on(SearchActions.SearchRegularDeploymentsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            searchRegularDeploymentsResult: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(SearchActions.SearchRegularDeploymentsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRegularDeploymentsResult: {
                data: null,
                loading: false,
            }
        };
    }),
    on(SearchActions.SearchRecurringDeploymentsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRecurringDeploymentsResult: {
                ...state.searchRecurringDeploymentsResult,
                loading: false,
            }
        };
    }),
    on(SearchActions.SearchRecurringDeployments, (state: IEmailBuilderState) => {

        return {
            ...state,
            searchRecurringDeploymentsResult: {
                loading: true,
                data: state?.searchRecurringDeploymentsResult?.data
            }
        };
    }),
    on(SearchActions.SearchRecurringDeploymentsSuccess, (state: IEmailBuilderState, action) => {

        return {
            ...state,
            searchRecurringDeploymentsResult: action.payload
        };
    }),
    on(SearchActions.ClearSearchRegularDeployments, (state: IEmailBuilderState) => {

        return {
            ...state,
            searchRegularDeploymentsResult: {
                loading: false,
                data: null
            }
        };
    }),
    on(SearchActions.ClearSearchRecurringDeployments, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRecurringDeploymentsResult: {
                loading: false,
                data: null
            }
        };
    }),
    on(SearchActions.GetRegularSearchDropdowns, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRegularDeploymentsDropdowns: {
                loading: true,
                data: state.searchRegularDeploymentsDropdowns?.data
            },
        };
    }),
    on(SearchActions.GetRegularSearchDropdownsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            searchRegularDeploymentsDropdowns: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(SearchActions.GetRegularSearchDropdownsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRegularDeploymentsDropdowns: {
                loading: false,
                data: state.searchRegularDeploymentsDropdowns?.data
            }
        };
    }),
    on(SearchActions.GetRecurringSearchDropdowns, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRecurringDeploymentsDropdowns: {
                loading: true,
                data: state.searchRegularDeploymentsDropdowns?.data
            },
        };
    }),
    on(SearchActions.GetRecurringSearchDropdownsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            searchRecurringDeploymentsDropdowns: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(SearchActions.GetRecurringSearchDropdownsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            searchRecurringDeploymentsDropdowns: {
                loading: false,
                data: state.searchRegularDeploymentsDropdowns?.data
            }
        };
    }),
    on(SearchActions.ClearRegularSearchRetryState, (state: IEmailBuilderState, action) => {
        const deplDetailsList = JSON.parse(JSON.stringify(state.regularDeploymentsDetailsList));
        if (deplDetailsList[action.payload.deploymentId] && deplDetailsList[action.payload.deploymentId]?.retryData) {
            delete deplDetailsList[action.payload.deploymentId]?.retryData;
        }
        return {
            ...state,
            regularDeploymentsDetailsList: deplDetailsList
        };
    })
];