import { BasicResponse, ResponseWrapper } from 'common';

export const demographicsInitialState: DemographicsState = {
    demographicsData: {
        loading: false,
        data: null
    },
    selectedDemographicData: {
        loading: false,
        data: null
    },
    downloadLoader: false,
    deleteModalData: {
        loading: false,
        data: null
    },
    dataLoaderTemplates: {
        loading: false,
        data: null
    },
    existingDemographicName: false
};

export interface DemographicsState {
    demographicsData: ResponseWrapper<DemographicsListRes>;
    selectedDemographicData: ResponseWrapper<SelectedDemographicRes>;
    downloadLoader: boolean;
    deleteModalData: ResponseWrapper<DeleteModalData>;
    dataLoaderTemplates: ResponseWrapper<DataLoaderTemplatesRes>;
    existingDemographicName: boolean;
}

export interface DemographicsListRes {
    totalResultCount: number;
    items: Array<DemographicData>;
}

export interface DemographicData {
    demographicId?: number,
    environmentId?: number,
    type?: number,
    name?: string,
    shortDescription?: string,
    usageType?: number,
    auditLocked?: boolean,
    calcField?: boolean,
    updateableWhileClosed?: boolean,
    unitField?: boolean,
    passAlong?: boolean,
    aggregateData?: boolean,
    isAudited?: boolean,
    createdDate?: string,
    createdBy?: string,
    changedDate?: string,
    changedBy?: string,
    updateCounter?: number,
    status?: number,
    captureDemoHistory?: boolean,
    replaceRuleCode?: number,
    jobTitleDemo?: boolean,
    omailRuleDemo?: boolean,
    consolidated?: boolean,
    webformText?: string,
    demographicValues?: Array<DemographicValue>,
    profiles?: Array<number>,
    allProfiles?: boolean,
    selectedCalcValues?: Array<number>
}

export interface SelectedDemographicRes {
    success?: boolean,
    demographicId?: number,
    message?: string,
    demographic?: DemographicData
}

export interface DemographicValue {
    shortDescription?: string,
    valueId?: number,
    valueText?: string,
    valueType?: number,
    status?: number,
    sequence?: number,
    legacyValueId?: string,
    alternateId?: string,
    webformText?: string,
    dragonHide?: number,
    delete?: boolean
}

export interface DeleteModalData {
    inputs?: InputItem[],
    formBuilderForms?: FormBuilderFormsItem[],
    dataLoaderTemplates?: DataLoaderTemplates[],
    audienceBuilderProfiles?: AudienceBuilderProfilesItem[]
}

export interface InputItem {
    inputId?: number,
    inputName?: string,
}

export interface FormBuilderFormsItem {
    siteId?: number,
    context?: string,
    description?: string,
    owner?: string,
    link?: string,
}

export interface DataLoaderTemplates {
    fileMappingFieldId?: number,
    mappingName?: string,
    link?: string,
}

export interface AudienceBuilderProfilesItem {
    dataViewId?: number,
    profileName?: string,
}

export interface DataLoaderTemplatesRes extends BasicResponse {
    dataloaderTemplates?: DataloaderTemplatesItem[],
}

export interface DataloaderTemplatesItem {
    fileMappingFieldId?: number,
    mappingName?: string,
    link?: string,
}