import { ResponseWrapper } from 'common';

export const apiKeysInitialState: ApiKeysState = {
    apiKeysData: {
        loading: false,
        data: null
    },
    customerLookups: {
        loading: false,
        data: null
    },
    brandLookups: {
        loading: false,
        data: null
    },
    emailBuilderLookups: {
        loading: false,
        data: null
    },
    utilityLookups: {
        loading: false,
        data: null
    },
    storeCustomerInfo: {
        loading: false,
        data: null
    },
    generatedApiKey: null,
    selectedApiKey: {
        loading: false,
        data: null
    },
    olyticsLookups: {
        loading: false,
        data: null
    },
    smsLookups: {
        loading: false,
        data: null
    },
    authenticationLookups: {
        loading: false,
        data: null
    },
    fullApiKey: null
};

export interface ApiKeysState {
    apiKeysData: ResponseWrapper<ApiKeysRes>;
    customerLookups: ResponseWrapper<Array<CommonApiItem>>;
    brandLookups: ResponseWrapper<Array<CommonApiItem>>;
    emailBuilderLookups: ResponseWrapper<Array<CommonApiItem>>;
    utilityLookups: ResponseWrapper<Array<CommonApiItem>>;
    storeCustomerInfo: ResponseWrapper<Array<CommonApiItem>>;
    generatedApiKey: string;
    selectedApiKey: ResponseWrapper<SelectedApiKeyData>;
    olyticsLookups: ResponseWrapper<Array<CommonApiItem>>;
    smsLookups: ResponseWrapper<Array<CommonApiItem>>;
    authenticationLookups: ResponseWrapper<Array<CommonApiItem>>;
    fullApiKey: string;
}

export interface ApiKeysRes {
    totalResultCount: number;
    items: Array<ApiKeyItem>;
}

export interface CommonApiItem {
    id?: string;
    name?: string;
    selected?: boolean;
}

export interface SelectedApiKeyData {
    edit?: boolean;
    apiKey?: string;
    description?: string;
    expirationDate?: string;
    status?: string;
    createdBy?: string;
    allCustomerLookupServices?: boolean;
    allStoreCustomerInfoServices?: boolean;
    allBrandLookupAndStoreServices?: boolean;
    allEmailBuilderServices?: boolean;
    allUtilityServices?: boolean;
    allOlyticsServices?: boolean;
    allAuthenticationServices?: boolean;
    allSmsServices?: boolean;
    customerLookupServices?: number[];
    storeCustomerInfoServices?: number[];
    brandLookupAndStoreServices?: number[];
    emailBuilderServices?: number[];
    utilityServices?: number[];
    olyticsServices?: number[];
    authenticationServices?: number[];
    smsServices?: number[];
}

export interface ApiKeyItem {
    apiKey?: string;
    description?: string;
    changedDate?: string;
    changedBy?: string;
    expiresOn?: string;
    status?: string;
}