import { Action, createReducer } from '@ngrx/store';
import { CampaignsState } from './interfaces';
import { campaignsLeadScoringReducer } from './lead-scoring/lead-scoring.reducer';
import { promoCodesReducer } from './promo-codes/promo-codes.reducer';
import { campaignsScheduleOutputsReducer } from './schedule-outputs/schedule-outputs.reducer';
import { promoCodesInitialState as promoCodesState} from './promo-codes/promo-codes.interfaces';
import { scheduleOutputsInitialState } from './schedule-outputs/schedule-outputs.interfaces';
import { hostedContentReducer } from './hosted-content/hosted-content.reducer';
import { AttributeType, AttributeValue } from './lead-scoring/lead-scoring.interfaces';

const initialAppState: CampaignsState = {
    promoCodesState: { ...promoCodesState },
    scheduleOutputsState: { ...scheduleOutputsInitialState },
    leadScoringState: {
        leadScoringData: {
            loading: false,
            data: null,
        },
        behaviorAttributeTypesMap: new Map<string, Array<AttributeType>>(),
        behaviorAttributeTypeSearchValuesMap: new Map<string, Array<AttributeValue>>(),
    },
    // COMMON INITIAL STATE
};

function getInitalState(): CampaignsState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

function getSavedState(): CampaignsState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    ...promoCodesReducer,
    ...campaignsLeadScoringReducer,
    ...campaignsScheduleOutputsReducer,
    ...hostedContentReducer,
    //COMMON REDUCERS
);

// // tslint:disable-next-line: typedef
export function campaignsReducer(state: CampaignsState | undefined, action: Action) {
    return appReducer(state, action);
}
