import { on } from '@ngrx/store';
import { IEmailBuilderState } from '.././interfaces';
import * as SummaryStatsActions from './summary-stats.action';

export const summaryStatsReducer = [
    on(SummaryStatsActions.ClearSummaryStatsState, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: null
            }
        };
    }),
    on(SummaryStatsActions.GetYearList, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    yearList: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetYearListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    yearList: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetYearListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    yearList: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportTableColumns, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportTableColumns: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportTableColumnsSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportTableColumns: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportTableColumnsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportTableColumns: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDeploymentTypesList, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    deploymentTypesList: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDeploymentTypesListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    deploymentTypesList: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDeploymentTypesListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    deploymentTypesList: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportCriteriaList, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteriaList: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportCriteriaListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteriaList: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetReportCriteriaListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteriaList: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.LoadReportCriteria, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteria: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.LoadReportCriteriaSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteria: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.LoadReportCriteriaError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    reportCriteria: {
                        loading: false,
                        data: null
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDesignationTypesList, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    designationTypesList: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDesignationTypesListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    designationTypesList: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDesignationTypesListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    designationTypesList: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDomainsList, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    domainList: {
                        loading: true,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDomainsListSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    domainList: {
                        loading: false,
                        data: action.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GetDomainsListError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    domainList: {
                        loading: false,
                        data: []
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GenerateReport, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    generateReport: {
                        loading: true,
                        data: null
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GenerateReportSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    generateReport: {
                        loading: false,
                        data: action?.payload
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.GenerateReportError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    generateReport: {
                        loading: false,
                        data: null
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.SaveReportCriteria, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    saveReportCriteria: {
                        loading: true
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.SaveReportCriteriaSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    saveReportCriteria: {
                        loading: false
                    }
                }
            }
        };
    }),
    on(SummaryStatsActions.SaveReportCriteriaError, (state: IEmailBuilderState) => {
        return {
            ...state,
            summaryStats: {
                loading: false,
                data: {
                    ...state?.summaryStats?.data,
                    saveReportCriteria: {
                        loading: false
                    }
                }
            }
        };
    }),
];