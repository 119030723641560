import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, debounceTime, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ProfilesActions from './profiles.actions';
import { CommonQuerySearchReq, CommonReq, Product, SelectedProfileRes } from './profiles.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceProfilesEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.LoadProfilesData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/profiles?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Product[]) => {
                            const payload: ResponseWrapper<Product[]> = {
                                data: res,
                                loading: false
                            };
                            return ProfilesActions.LoadProfilesDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.router.navigate(['self-service/main/profiles']);
                            let errorMessage = 'An error has occurred while trying to load profiles.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ProfilesActions.LoadProfilesDataError(error));
                        })
                    );
            })
        )
    );

    searchQueries$ = createEffect(() =>
        this.actions$.pipe(
            debounceTime(300),
            ofType(ProfilesActions.SearchQueries),
            switchMap((action) => {
                //TODO Loop through action payload
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('allDataViews', 'true');
                searchParams.set('profileId', action.payload.profileId ? action.payload.profileId : '');
                searchParams.set('createdBy', action.payload.createdBy ? action.payload.createdBy : '');
                searchParams.set('keyword', action.payload.keyword ? action.payload.keyword : '');
                searchParams.set('lastUpdated', action.payload.lastUpdated ? action.payload.lastUpdated : '');
                searchParams.set('lockedQueriesOnly', action.payload.lockedQueriesOnly ? action.payload.lockedQueriesOnly?.toString() : '');
                searchParams.set('name', action.payload.name ? action.payload.name : '');
                searchParams.set('nameSearchType', action.payload.nameSearchType ? action.payload.nameSearchType : '');
                searchParams.set('numResults', action.payload.numResults ? action.payload.numResults?.toString() : '');
                searchParams.set('olyticsQueriesOnly', action.payload.olyticsQueriesOnly ? action.payload.olyticsQueriesOnly?.toString() : '');
                searchParams.set('offset', action.payload.offset ? action.payload.offset?.toString() : '');
                searchParams.set('sortBy', action.payload.sortBy ? action.payload.sortBy : '');
                searchParams.set('order', action.payload.order ? action.payload.order : '');
                searchParams.set('mode', action.payload.mode ? action.payload.mode : '');
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/search?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: Array<CommonQuerySearchReq>) => {
                        const payload: ResponseWrapper<Array<CommonQuerySearchReq>> = { loading: false, data: res };
                        return ProfilesActions.SearchQueriesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(ProfilesActions.SearchQueriesError(error));
                    })
                );
            })
        )
    );

    changeProfilePriority$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.ChangeProfilePriority),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('dataViewId', action.payload.id.toString());
                searchParams.set('direction', action.payload.direction);

                return this.http.put(environment.settingsApiEndPoint + `/profiles/update-priority?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = {
                            data: res,
                            loading: false
                        };

                        if (action?.reloadPayload) {
                            this.store.dispatch(ProfilesActions.LoadProfilesData({ payload: action.reloadPayload }));
                        }

                        return ProfilesActions.ChangeProfilePrioritySuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to change profile priority.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.ChangeProfilePriorityError(error));
                    })
                );
            })
        )
    );

    loadSelectedProfileDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetSelectedProfileDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http.get(environment.settingsApiEndPoint + `/profiles/${action.payload.dataViewId.toString()}?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: SelectedProfileRes) => {
                        const payload: ResponseWrapper<SelectedProfileRes> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetSelectedProfileDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load profile details.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.router.navigate(['self-service/main/profiles']);
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetSelectedProfileDetailsError(error));
                    })
                );
            })
        )
    );

    getContactRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetContactRules),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.settingsApiEndPoint + `/profiles/contact-rules?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: CommonReq) => {
                        const payload: ResponseWrapper<CommonReq> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetContactRulesSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load contact rules.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetContactRulesError(error));
                    })
                );
            })
        )
    );

    getNameSpaces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetNameSpaces),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.settingsApiEndPoint + `/profiles/name-spaces?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: CommonReq) => {
                        const payload: ResponseWrapper<CommonReq> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetNameSpacesSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load name spaces.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetNameSpacesError(error));
                    })
                );
            })
        )
    );

    getProfileBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetProfileBehaviors),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.settingsApiEndPoint + `/profiles/behaviors?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: CommonReq) => {
                        const payload: ResponseWrapper<CommonReq> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetProfileBehaviorsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load behaviors.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetProfileBehaviorsError(error));
                    })
                );
            })
        )
    );

    getProfileDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetProfileDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.settingsApiEndPoint + `/profiles/demographics?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: CommonReq) => {
                        const payload: ResponseWrapper<CommonReq> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetProfileDemographicsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load demographics.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetProfileDemographicsError(error));
                    })
                );
            })
        )
    );

    getProfileProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.GetProfileProducts),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http.get(environment.settingsApiEndPoint + `/profiles/products?${searchParams}`, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: CommonReq) => {
                        const payload: ResponseWrapper<CommonReq> = {
                            data: res,
                            loading: false
                        };
                        return ProfilesActions.GetProfileProductsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to load products.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.GetProfileProductsError(error));
                    })
                );
            })
        )
    );

    saveProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfilesActions.SaveProfile),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http.post(environment.settingsApiEndPoint + `/profiles?${searchParams}`, action.payload, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.showSuccess('Success', `Profile has been ${action?.payload?.dataViewId ? 'saved' : 'created'} successfully.`);
                        this.router.navigate(['self-service/main/profiles']);
                        return ProfilesActions.SaveProfileSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = `An error has occurred while trying to ${action?.payload?.dataViewId ? 'save' : 'create'} profile.`;
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ProfilesActions.SaveProfileError({error, payload: action?.payload}));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
