import { ResponseWrapper } from 'common';

export const deploymentProductsInitialState: DeploymentProductsState = {
    deploymentProductsList: {
        loading: false,
        data: null
    },
    downloadLoader: false,
    selectedDeploymentProduct: {
        loading: false,
        data: null
    },
    deploymentsWithoutProducts: {
        loading: false,
        data: null
    },
    deploymentDesignations: {
        loading: false,
        data: null
    },
    deploymentDomains: {
        loading: false,
        data: null
    }
};

export interface DeploymentProductsState {
    deploymentProductsList: ResponseWrapper<DeploymentProductsListRes>;
    downloadLoader: boolean;
    selectedDeploymentProduct: ResponseWrapper<SelectedDeploymentProductData>;
    deploymentsWithoutProducts: ResponseWrapper<DeploymentTypesWithoutProductRes>;
    deploymentDesignations: ResponseWrapper<DeploymentDesignation[]>;
    deploymentDomains: ResponseWrapper<Array<DeploymentDesignation>>;
}

export interface DeploymentProductsListRes {
    totalResultCount: number;
    items: Array<DeploymentProduct>;
}

export interface DeploymentTypesWithoutProductRes {
    totalResultCount: number;
    items: Array<DeploymentWithoutProduct>;
}

export interface DeploymentProduct {
    deploymentProductId: number,
    environmentId: number,
    type: number,
    name: string,
    shortDescription?: string,
    usageType?: number,
    auditLocked?: boolean,
    calcField?: boolean,
    updateableWhileClosed?: boolean,
    unitField?: boolean,
    passAlong?: boolean,
    aggregateData?: boolean,
    isAudited?: boolean,
    createdDate?: string,
    createdBy?: string,
    changedDate?: string,
    changedBy?: string,
    updateCounter?: number,
    status?: number,
    captureDemoHistory?: boolean,
    replaceRuleCode?: number,
    jobTitleDemo?: boolean,
    omailRuleDemo?: boolean,
    consolidated?: boolean,
    webformText?: string
}

export interface DeploymentWithoutProduct {
    deploymentTypeId: number,
    deploymentTypeName: string,
    status?: number,
    designationId?: number,
    audited?: number
}

export interface DeploymentDesignation {
    designationId: number;
    designationName: string;
}

export interface SelectedDeploymentProductData {
    productName?: string,
    productId?: number,
    deplProductId?: number,
    deplTypeId?: string,
    productTypeId?: number,
    productTypeDesc?: string,
    changedBy?: string,
    changedDate?: string,
    status?: number,
    statusDesc?: string,
    displayName?: string,
    deployedThroughOmeda?: boolean,
    requiresDeploymentType?: boolean,
    deploymentTypeDetails?: DeploymentTypeDetails,
    designationId?: number,
    deploymentDomain?: string,
    unSubscribeMessage?: string,
    confirmUnsubscribeMessage?: string,
    cancelUnsubscribeMessage?: string,
    createdBy?: string,
    profiles?: number[],
    allProfiles?: boolean
}

export interface DeploymentTypeDetails {
    deploymentTypeId?: number,
    deploymentTypeName?: string,
    deploymentTypeDesc?: string,
    status?: number,
    statusDescription?: string,
    alternateId?: string,
    productName?: string,
    designationDesc?: string,
    deploymentDomain?: string,
    changedBy?: string,
    changedDate?: string,
    formBuilderSendEmails?: string,
    designationId?: number,
    unSubscribeMessage?: string,
    confirmUnsubscribeMessage?: string,
    cancelUnsubscribeMessage?: string,
    audited?: string,
    deploymentProduct?: number,
    createdBy?: string,
}