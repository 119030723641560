import { Action, createReducer, on } from '@ngrx/store';
import { SmsBuilderState } from './interfaces';
import * as SmsBuilderActions from './actions';
import { smsMessageReducer } from './manage-sms-message/manage-sms-message.reducer';
import { manageSmsMessageInitialState } from './manage-sms-message/manage-sms-message.interface';

const initialAppState: SmsBuilderState = {
    appLoaded: false,
    smsMessageState: { ...manageSmsMessageInitialState },
};

function getInitalState(): SmsBuilderState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    return initialAppState;
}

function getSavedState(): SmsBuilderState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...smsMessageReducer as any,
    on(SmsBuilderActions.GetMetaData, (state: SmsBuilderState) => {
        return {
            ...state,
            metaData: {
                loading: true,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetMetaDataSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            metaData: {
                loading: false,
                data: action.payload,
            },
            appLoaded: true,
        };
    }),
    on(SmsBuilderActions.GetMetaDataError, (state: SmsBuilderState) => {
        return {
            ...state,
            metaData: {
                loading: false,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.SearchAllMessages, (state: SmsBuilderState) => {
        return {
            ...state,
            messagesList: {
                loading: true,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.SearchAllMessagesSuccess, (state: SmsBuilderState, action) => {
        return {
            ...state,
            messagesList: {
                loading: false,
                data: action.payload,
            }
        };
    }),
    on(SmsBuilderActions.SearchAllMessagesError, (state: SmsBuilderState) => {
        return {
            ...state,
            messagesList: {
                loading: false,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetMessageTypesList, (state: SmsBuilderState) => {
        return {
            ...state,
            messageTypesList: {
                loading: true,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetMessageTypesListSuccess, (state: SmsBuilderState, action) => {
        const messageTypesList = JSON.parse(JSON.stringify(action?.payload));
        messageTypesList.sort((a, b) => a?.name?.localeCompare(b?.name));
        return {
            ...state,
            messageTypesList: {
                loading: false,
                data: messageTypesList,
            }
        };
    }),
    on(SmsBuilderActions.GetMessageTypesListError, (state: SmsBuilderState) => {
        return {
            ...state,
            messageTypesList: {
                loading: false,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetStatusOptionsList, (state: SmsBuilderState) => {
        return {
            ...state,
            statusOptionsList: {
                loading: true,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetStatusOptionsListSuccess, (state: SmsBuilderState, action) => {
        const statusOptionsList = JSON.parse(JSON.stringify(action?.payload));
        if (statusOptionsList && statusOptionsList.length && statusOptionsList?.find(status => status?.value === '2') && statusOptionsList?.find(status => status?.value === '3')) {
            statusOptionsList?.splice((statusOptionsList?.findIndex(status => status?.value === '3') + 1), 0, { description: 'Sent and Sending', value: '99' });
        }
        return {
            ...state,
            statusOptionsList: {
                loading: false,
                data: statusOptionsList,
            }
        };
    }),
    on(SmsBuilderActions.GetStatusOptionsListError, (state: SmsBuilderState) => {
        return {
            ...state,
            statusOptionsList: {
                loading: false,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetSendingPhoneNumbersList, (state: SmsBuilderState) => {
        return {
            ...state,
            sendingPhoneNumbersList: {
                loading: true,
                data: null,
            }
        };
    }),
    on(SmsBuilderActions.GetSendingPhoneNumbersListSuccess, (state: SmsBuilderState, action) => {
        const phoneNumberList = JSON.parse(JSON.stringify(action?.payload));
        phoneNumberList.forEach((phone) => {
            phone.formattedNumber = `(${phone.phoneNumber.slice(2, 5)}) ${phone.phoneNumber.slice(5, 8)}-${phone.phoneNumber.slice(8)}`;
        });
        return {
            ...state,
            sendingPhoneNumbersList: {
                loading: false,
                data: phoneNumberList,
            }
        };
    }),
    on(SmsBuilderActions.GetSendingPhoneNumbersListError, (state: SmsBuilderState) => {
        return {
            ...state,
            sendingPhoneNumbersList: {
                loading: false,
                data: null,
            }
        };
    }),
);

export function smsBuilderReducer(state: SmsBuilderState | undefined, action: Action) {
    return appReducer(state, action);
}
