import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as ProductNoticeActions from './product-notice.actions';

export const productNoticeReducers = [
    on(ProductNoticeActions.LoadProductNoticeData, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                productNoticeData: {
                    data: state.productNoticeState?.productNoticeData?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProductNoticeActions.LoadProductNoticeDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                productNoticeData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.LoadProductNoticeDataError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                productNoticeData: {
                    data: state.productNoticeState?.productNoticeData?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductNoticeActions.GetProductNoticeData, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticeDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: {...state.productNoticeState?.selectedProductNoticeData?.data, ...action.payload?.data},
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticeDataError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeTypes, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeTypes: {
                    data: state.productNoticeState?.noticeTypes?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeTypesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeTypes: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeTypesError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeTypes: {
                    data: state.productNoticeState?.noticeTypes?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeProducts, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeProducts: {
                    data: state.productNoticeState?.noticeProducts?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeProductsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeProducts: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticeProductsError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                noticeProducts: {
                    data: state.productNoticeState?.noticeProducts?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticePatternMatching, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: true
                }
            },
        };
    }),

    on(ProductNoticeActions.GetNoticePatternMatchingSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: {
                        ...state.productNoticeState?.selectedProductNoticeData?.data,
                        patternMatchingData: action?.payload
                    },
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetNoticePatternMatchingError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.DownloadPatternMatching, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: true
            }
        };
    }),

    on(ProductNoticeActions.DownloadPatternMatchingSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: false
            }
        };
    }),

    on(ProductNoticeActions.DownloadPatternMatchingError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: false
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticePanels, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: true
                }
            },
        };
    }),

    on(ProductNoticeActions.GetProductNoticePanelsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: {
                        ...state.productNoticeState?.selectedProductNoticeData?.data,
                        noticePanelsData: action?.payload
                    },
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticePanelsError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.DownloadPanels, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: true
            }
        };
    }),

    on(ProductNoticeActions.DownloadPanelsSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: false
            }
        };
    }),

    on(ProductNoticeActions.DownloadPanelsError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                downloadLoader: false
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticeCampaignPrices, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: true
                }
            },
        };
    }),

    on(ProductNoticeActions.GetProductNoticeCampaignPricesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: {
                        ...state.productNoticeState?.selectedProductNoticeData?.data,
                        campaignPricingData: action?.payload
                    },
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetProductNoticeCampaignPricesError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                selectedProductNoticeData: {
                    data: state.productNoticeState?.selectedProductNoticeData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetPatternHistory, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                patternHistoryData: {
                    data: [],
                    loading: true
                }
            },
        };
    }),

    on(ProductNoticeActions.GetPatternHistorySuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                patternHistoryData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductNoticeActions.GetPatternHistoryError, (state: SelfServiceState) => {
        return {
            ...state,
            productNoticeState: {
                ...state.productNoticeState,
                patternHistoryData: {
                    data: [],
                    loading: false
                }
            }
        };
    }),
];
