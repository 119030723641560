import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ShellStore, ModalService, ShowToast, ResponseWrapper, getCurrentUser } from 'common';
import { exhaustMap, take, map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetOptOutDatabaseSettings, GetOptOutDatabaseSettingsError, GetOptOutDatabaseSettingsSuccess, GetOptOutTypeSettings, GetOptOutTypeSettingsError, GetOptOutTypeSettingsSuccess, UpdateOptOutDatabaseSettings, UpdateOptOutDatabaseSettingsError, UpdateOptOutDatabaseSettingsSuccess, UpdateOptOutOrganizationSettings, UpdateOptOutOrganizationSettingsError, UpdateOptOutOrganizationSettingsSuccess, UpdateOptOutTypeSettings, UpdateOptOutTypeSettingsError, UpdateOptOutTypeSettingsSuccess } from './opt-out.actions';
import { DatabaseSettings, OrganizationSettings, TypeSettings } from '../../interfaces';
import { GetOptOutOrganizationSettings, GetOptOutOrganizationSettingsSuccess, GetOptOutOrganizationSettingsError } from './opt-out.actions';

@Injectable({ providedIn: 'root' })
export class EmailBuilderOptOutEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) { }

    //OptOut summary

    getOptOutOrganizationSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOptOutOrganizationSettings),
            exhaustMap((action) => {
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                return this.http
                    .get(this.baseUrl + `?environmentId=${this.currentDatabase.id}` + orgId,
                        this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OrganizationSettings) => {
                            const payload: ResponseWrapper<OrganizationSettings> = {
                                loading: false,
                                data: res,
                            };
                            return GetOptOutOrganizationSettingsSuccess({
                                payload: payload,
                            });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to load opt-out organization settings.');
                            return of(GetOptOutOrganizationSettingsError(error));
                        })
                    );
            })
        )
    );

    getOptOutDatabaseSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOptOutDatabaseSettings),
            exhaustMap((action) => {
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                const databaseId = action.payload.databaseId ? '&databaseId=' + action.payload.databaseId : '';
                return this.http
                    .get(this.baseUrl + `?environmentId=${this.currentDatabase.id}` + orgId + databaseId,
                        this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DatabaseSettings) => {
                            const payload: ResponseWrapper<DatabaseSettings> = {
                                loading: false,
                                data: res,
                            };
                            return GetOptOutDatabaseSettingsSuccess({
                                payload: payload,
                            });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to load opt-out database settings.');
                            return of(GetOptOutDatabaseSettingsError(error));
                        })
                    );
            })
        )
    );

    getOptOutTypeSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOptOutTypeSettings),
            exhaustMap((action) => {
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                const databaseId = action.payload.databaseId ? '&databaseId=' + action.payload.databaseId : '';
                const typeId = action.payload.id ? '&id=' + action.payload.id : '';
                return this.http
                    .get(this.baseUrl + `?environmentId=${this.currentDatabase.id}` + orgId + databaseId + typeId,
                        this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TypeSettings) => {
                            const payload: ResponseWrapper<TypeSettings> = {
                                loading: false,
                                data: res,
                            };
                            return GetOptOutTypeSettingsSuccess({
                                payload: payload,
                            });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to load opt-out type settings.');
                            return of(GetOptOutTypeSettingsError(error));
                        })
                    );
            })
        )
    );

    updateOptOutOrganizationSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateOptOutOrganizationSettings),
            exhaustMap((action) => {
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                return this.http
                    .put(this.baseUrl + `?environmentId=${this.currentDatabase.id}` + orgId, action.payload.data,
                        this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OrganizationSettings) => {
                            this.store.dispatch(GetOptOutOrganizationSettings({ payload: { orgId: action.payload.orgId } }));
                            const payload: ResponseWrapper<OrganizationSettings> = {
                                loading: false,
                                data: res,
                            };
                            return UpdateOptOutOrganizationSettingsSuccess({ payload: payload });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to update opt-out organization settings.');
                            return of(
                                UpdateOptOutOrganizationSettingsError(error)
                            );
                        })
                    );
            })
        )
    );
    updateOptOutDatabaseSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateOptOutDatabaseSettings),
            exhaustMap((action) => {
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                const databaseId = action.payload.databaseId ? '&databaseId=' + action.payload.databaseId : '';
                return this.http
                    .put(this.baseUrl + `?environmentId=${this.currentDatabase.id}` +
                        orgId + databaseId, action.payload.data, { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: DatabaseSettings) => {
                            this.store.dispatch(GetOptOutDatabaseSettings({ payload: { orgId: action.payload.orgId, databaseId: action.payload.databaseId } }));
                            const payload: ResponseWrapper<DatabaseSettings> = {
                                loading: false,
                                data: res,
                            };
                            return UpdateOptOutDatabaseSettingsSuccess({ payload: payload });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to update opt-out database settings.');
                            return of(UpdateOptOutDatabaseSettingsError(error));
                        })
                    );
            })
        )
    );
    updateOptOutTypeSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateOptOutTypeSettings),
            exhaustMap((action) => {
                const typeId = action.payload.id ? '&id=' + action.payload.id : '';
                const orgId = action.payload.orgId ? '&orgId=' + action.payload.orgId : '';
                const databaseId = action.payload.databaseId ? '&databaseId=' + action.payload.databaseId : '';
                return this.http
                    .put(this.baseUrl + `?environmentId=${this.currentDatabase.id}` +
                        orgId + databaseId + typeId,
                    action.payload.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TypeSettings) => {
                            this.store.dispatch(GetOptOutTypeSettings({ payload: { orgId: action.payload.orgId, databaseId: action.payload.databaseId, id: action.payload.id } }));
                            const payload: ResponseWrapper<TypeSettings> = {
                                loading: false,
                                data: res,
                            };
                            return UpdateOptOutTypeSettingsSuccess({ payload: payload });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to update opt-out type settings.');
                            return of(UpdateOptOutTypeSettingsError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools/opt-out';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
