import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getCurrentUser, ModalService, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CdpFormListenersActions from './form-listeners.actions';
import { FormListenerConstantValue, FormListenerDetails, FormListenerIgnoredField, FormListenerRecentDataItem, FormListenerReportItem, FormListenersResponse, GeneratedTestLink } from './form-listeners.interface';

@Injectable({ providedIn: 'root' })
export class CustomerDataPlatformFormListenersEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService,
        private route: ActivatedRoute
    ) { }

    loadFormListeners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.LoadFormListeners),
            exhaustMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/all');
                url.searchParams.append('environmentId', databaseId?.toString());
                url.searchParams.append('offset', action.payload.offset?.toString());
                url.searchParams.append('numResults', action.payload.numResults?.toString());
                url.searchParams.append('sortBy', action.payload.sortBy);
                url.searchParams.append('order', action.payload.order);

                if (action.payload.name) {
                    url.searchParams.append('name', action.payload.name || '');
                }
                if (action.payload.createdDate) {
                    url.searchParams.append('createdDate', action.payload.createdDate || '');
                }
                if (action.payload.createdBy) {
                    url.searchParams.append('createdBy', action.payload.createdBy || '');
                }
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenersResponse) => {
                            return CdpFormListenersActions.LoadFormListenersSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to form listeners.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.LoadFormListenersError(error));
                        })
                    );
            })
        )
    );

    loadFormListenerDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.LoadFormListenerDetails),
            exhaustMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerDetails) => {
                            return CdpFormListenersActions.LoadFormListenerDetailsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load form listener details.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.LoadFormListenerDetailsError(error));
                        })
                    );
            })
        )
    );

    saveFormListener$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.SaveFormListener),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/save');
                url.searchParams.append('environmentId', databaseId.toString());
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerDetails) => {
                            this.showSuccess(null, 'Form listener saved successfully.');
                            return CdpFormListenersActions.SaveFormListenerSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save form listener.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.SaveFormListenerError(error));
                        })
                    );
            })
        )
    );

    updateFormListenerStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.UpdateFormListenerStatus),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/update');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                url.searchParams.append('action', action.payload.action);
                return this.http
                    .put(url.toString(), {}, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            const message = action.payload.action === 'delete' ? 'Form listener deleted successfully.' : 'Form listener status updated successfully.';
                            this.showSuccess(null, message);
                            return CdpFormListenersActions.UpdateFormListenerStatusSuccess({ payload: action.payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update form listener status.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.UpdateFormListenerStatusError(error));
                        })
                    );
            })
        )
    );

    getConstantValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.GetConstantValues),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/constant-value');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerConstantValue[]) => {
                            return CdpFormListenersActions.GetConstantValuesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get constant values.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.GetConstantValuesError(error));
                        })
                    );
            })
        )
    );

    loadReportData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.LoadReportData),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/run-report');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                url.searchParams.append('startDate', action.payload.startDate);
                url.searchParams.append('endDate', action.payload.endDate);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerReportItem[]) => {
                            return CdpFormListenersActions.LoadReportDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load report data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.LoadReportDataError(error));
                        })
                    );
            })
        )
    );

    udateConstantValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.UpdateConstantValue),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/constant-value/save');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());

                const bodyParams = {
                    applicationStringId: action.payload.constantValue.applicationStringId,
                    constantName: action.payload.constantValue.name,
                    constantValue: action.payload.constantValue.value
                };

                return this.http
                    .post(url.toString(), [bodyParams], this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            if (action.payload.constantValue.applicationStringId === 0) {

                            }
                            return CdpFormListenersActions.UpdateConstantValueSuccess({ payload: action.payload.constantValue });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update constant value.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpFormListenersActions.UpdateConstantValueError(error));
                        })
                    );
            })
        )
    );

    deleteConstantValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.DeleteConstantValue),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/constant-value/delete');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('applicationStringId', action.payload.applicationStringId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .post(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            return CdpFormListenersActions.DeleteConstantValueSuccess({ payload: { applicationStringId: action.payload.applicationStringId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete constant value.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        )
    );

    outputTestSubmissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.OutputTestSubmissions),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/output-test-submissions');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .get(url.toString(), {
                        withCredentials: true,
                        responseType: 'blob',
                        observe: 'response',
                    })
                    .pipe(
                        take(1),
                        map((res: HttpResponse<Blob>) => {
                            this.downloadFile(res);
                            return CdpFormListenersActions.OutputTestSubmissionsSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to output test submissions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of();
                        })
                    );
            })
        )
    );

    loadRecentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.LoadRecentData),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/recent-data');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerRecentDataItem[]) => {
                            return CdpFormListenersActions.LoadRecentDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            return of(CdpFormListenersActions.LoadRecentDataError(error));
                        })
                    );
            })
        )
    );

    ignoreFormField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.IgnoreFormField),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/ignore-form-field');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                url.searchParams.append('formFieldName', action.payload.formFieldName);
                return this.http
                    .post(url.toString(), {}, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            return CdpFormListenersActions.IgnoreFormFieldSuccess();
                        }),
                        catchError((error) => {
                            return of(CdpFormListenersActions.IgnoreFormFieldError(error));
                        })
                    );
            })
        )
    );

    generateTestLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.GenerateTestLink),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/generate-test-link');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .post(url.toString(), {}, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: GeneratedTestLink) => {
                            return CdpFormListenersActions.GenerateTestLinkSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            return of(CdpFormListenersActions.GenerateTestLinkError(error));
                        })
                    );
            })
        )
    );

    getIgnoredFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.GetIgnoredFields),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/ignored-field');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: FormListenerIgnoredField[]) => {
                            return CdpFormListenersActions.GetIgnoredFieldsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get ignored fields.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError('Error', errorMessage);
                            return of(CdpFormListenersActions.GetIgnoredFieldsError());
                        })
                    );
            })
        )
    );

    ignoreField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.IgnoreField),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/recent-data/ignore');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                url.searchParams.append('formFieldName', action.payload.formFieldName);
                return this.http
                    .post(url.toString(), {}, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            return CdpFormListenersActions.IgnoreFieldSuccess(action);
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to ignore field.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError('Error', errorMessage);
                            return of(CdpFormListenersActions.IgnoreFieldError());
                        })
                    );
            })
        )
    );

    ignoreFieldSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.IgnoreFieldSuccess),
            map((action) => {
                this.showSuccess('Success', 'Field ignored successfully.');
                return CdpFormListenersActions.GetIgnoredFields({ payload: { formListenerId: action.payload.formListenerId } });
            })
        )
    );

    stopIgnoringField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.StopIgnoringField),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/stop-ignored-field');
                url.searchParams.append('environmentId', databaseId.toString());
                if (action.payload.formListenerId) {
                    url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                }
                url.searchParams.append('applicationStringId', action.payload.applicationStringId);
                return this.http
                    .post(url.toString(), {}, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(CdpFormListenersActions.GetIgnoredFields({ payload: { formListenerId: action.payload.formListenerId } }));
                            return CdpFormListenersActions.StopIgnoringFieldSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to stop ignoring field.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError('Error', errorMessage);
                            return of(CdpFormListenersActions.StopIgnoringFieldError());
                        })
                    );
            })
        )
    );

    setupFollowUpEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpFormListenersActions.SetupFollowUpEmail),
            exhaustMap((action) => {
                const databaseId = this.currentDatabaseId;
                const url = new URL(this.baseUrl + '/form-listener/follow-up-email/save');
                url.searchParams.append('environmentId', databaseId.toString());
                url.searchParams.append('formListenerId', action.payload.formListenerId.toString());
                return this.http
                    .post(url.toString(), action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map(() => {
                            this.showSuccess('Success', 'Follow up email saved successfully.');
                            return CdpFormListenersActions.SetupFollowUpEmailSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to setup follow up email.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError('Error', errorMessage);
                            return of(CdpFormListenersActions.SetupFollowUpEmailError());
                        })
                    );
            })
        )
    );

    downloadFile(res: HttpResponse<Blob>) {
        const blob: Blob = new Blob([res.body], {
            type: res.headers.get('Content-Type'),
        });
        const url = window.URL.createObjectURL(blob);
        const fileName = res.headers
            .get('Content-Disposition')
            .split(';')[1]
            .trim()
            .split('=')[1];
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.id = '_downloadLink';
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): number {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute;
        }
        return this.currentDatabase?.id;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
