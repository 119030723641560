import { ResponseWrapper } from 'common';

export const deploymentTypesInitialState: DeploymentTypesState = {
    deploymentTypesData: {
        loading: false,
        data: null
    },
    downloadLoader: false,
    selectedDeploymentType: {
        loading: false,
        data: null
    },
    deploymentTypeProductList: {
        loading: false,
        data: null
    }
};

export interface DeploymentTypesState {
    deploymentTypesData: ResponseWrapper<DeploymentTypeListRes>;
    downloadLoader: boolean;
    selectedDeploymentType: ResponseWrapper<SelectedDeploymentType>;
    deploymentTypeProductList: ResponseWrapper<DeploymentTypeProductList>;
}

export interface DeploymentTypeListRes {
    totalResultCount: number;
    items: Array<DeploymentType>;
}

export interface DeploymentType {
    deploymentType: number,
    environmentId: number,
    type: number,
    name: string,
    shortDescription?: string,
    usageType?: number,
    auditLocked?: boolean,
    calcField?: boolean,
    updateableWhileClosed?: boolean,
    unitField?: boolean,
    passAlong?: boolean,
    aggregateData?: boolean,
    isAudited?: boolean,
    createdDate?: string,
    createdBy?: string,
    changedDate?: string,
    changedBy?: string,
    updateCounter?: number,
    status?: number,
    captureDemoHistory?: boolean,
    replaceRuleCode?: number,
    jobTitleDemo?: boolean,
    omailRuleDemo?: boolean,
    consolidated?: boolean,
    webformText?: string,
    deploymentDomain?: string
}

export interface SelectedDeploymentType {
    deploymentTypeId: number;
    alternateId?: string;
    audited?: number;
    cancelUnsubscribeMessage?: string;
    changedBy?: string;
    changedDate?: string;
    confirmUnsubscribeMessage?: string;
    deploymentTypeDescr?: string;
    deploymentTypeName?: string;
    status?: number;
    unsubscribeMessage?: string;
    designationId?: number;
    deploymentProducts?: Array<DeploymentProduct>;
}

export interface DeploymentProduct {
    disabled: boolean;
    productId: number;
    productName: string;
    selected: boolean;
    userProductEditAuthorized: boolean;
}

export interface DeploymentTypeProductList {
    totalResultCount: number;
    items: Array<DeploymentTypeProduct>;
}

export interface DeploymentTypeProduct {
    productId: number;
    productName: string;
    selected: boolean;
    userProductEditAuthorized: boolean;
    disabled: boolean;
}
