import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BasicResponse, getCurrentUser, ListSchema, NameValueItem, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsMetaAdsManagerActions from './meta-ads-manager.actions';
import { Store } from '@ngrx/store';
import { GetRecentAudiences } from '../actions';
import { FacebookAudiencePushItem, FacebookChart } from './meta-ads-manager.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsMetaAdsManagerEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore
    ) { }

    getFacebookAdAccounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaAdsManagerActions.GetFacebookAdAccounts),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-ad-manager/ad-account');
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('accessToken', action.payload.accessToken);
                searchParams.set('userId', action.payload.userId);
                url.search = searchParams.toString();

                return this.http.get(url.toString()).pipe(
                    take(1),
                    map((res: NameValueItem[]) => {
                        return IntegrationsMetaAdsManagerActions.GetFacebookAdAccountsSuccess({ payload: res });
                    }),
                    catchError(() => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get recent audiences.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaAdsManagerActions.GetFacebookAdAccountsError());
                    })
                );
            })
        )
    );

    getPastAudiences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaAdsManagerActions.GetPastAudiences),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload.searchText ? searchParams.set('searchText', action.payload.searchText) : null;
                action.payload.searchText ? searchParams.set('searchText', action.payload.searchText) : null;
                action.payload.numResults ? searchParams.set('numResults', action.payload.numResults.toString()) : null;
                action.payload.offset ? searchParams.set('offset', action.payload.offset.toString()) : null;
                action.payload.sortBy ? searchParams.set('sortBy', action.payload.sortBy) : null;
                action.payload.order ? searchParams.set('order', action.payload.order) : null;
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/integration/facebook-ad-manager/past-audience?${searchParams}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: ListSchema<FacebookAudiencePushItem>) => {
                            const payload: ResponseWrapper<ListSchema<FacebookAudiencePushItem>> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaAdsManagerActions.GetPastAudiencesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to get past audiences.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaAdsManagerActions.GetPastAudiencesError(error));
                        })
                    );
            })
        )
    );

    sendAudienceToFacebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaAdsManagerActions.SendAudienceToFacebook),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-ad-manager/audience/');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id
                }).toString();
                return this.http.post(url.toString(), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.store.dispatch(GetRecentAudiences({ payload: { sortBy: 'sentOn' } }));
                        return IntegrationsMetaAdsManagerActions.SendAudienceToFacebookSuccess({ payload: payload });
                    }
                    ), catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        'An error has occurred while trying to send audience to Facebook.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaAdsManagerActions.SendAudienceToFacebookError(error));
                    }));
            })
        )
    );

    viewPerformance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaAdsManagerActions.ViewPerformance),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookAudiencePushId', action.payload.facebookAudiencePushId?.toString());
                searchParams.set('accessToken', action.payload?.accessToken);
                searchParams.set('adAccount', action.payload.adAccount?.toString());
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/integration/facebook-ad-manager/audience/view-performance?${searchParams}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: Array<FacebookChart>) => {
                            const payload: ResponseWrapper<Array<FacebookChart>> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaAdsManagerActions.ViewPerformanceSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message:
                                            'An error has occurred while trying to view performance.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaAdsManagerActions.ViewPerformanceError(error));
                        })
                    );
            })
        )
    );

    updateFacebookAudience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaAdsManagerActions.UpdateFacebookAudience),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookAudiencePushId', action.payload.facebookAudiencePushId.toString());
                searchParams.set('action', action.payload.action);
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/integration/facebook-ad-manager/audience/update?${searchParams}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: `Successfully ${action.payload?.action}d facebook audience` } }));
                            this.store.dispatch(GetRecentAudiences({ payload: { sortBy: 'sentOn' } }));
                            return IntegrationsMetaAdsManagerActions.UpdateFacebookAudienceSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to update facebook audience.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaAdsManagerActions.UpdateFacebookAudienceError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
