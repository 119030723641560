import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as behaviorsActions from './behaviors.actions';

export const behaviorsReducers = [
    on(behaviorsActions.LoadBehaviorData, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorsData: {
                    data: state.behaviorsState?.behaviorsData?.data,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.LoadBehaviorDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.LoadBehaviorDataError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorData, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorDataError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorData: {
                    data: state.behaviorsState?.selectedBehaviorData?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.ClearBehaviorData, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.DownloadBehaviorList, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                downloadLoader: true
            }
        };
    }),

    on(behaviorsActions.DownloadBehaviorListSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                downloadLoader: false
            }
        };
    }),

    on(behaviorsActions.DownloadBehaviorListError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                downloadLoader: false
            }
        };
    }),

    on(behaviorsActions.GetBehaviorAttributes, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorAttributesData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorAttributesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorAttributesData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorAttributesError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorAttributesData: {
                    data: state.behaviorsState?.behaviorAttributesData?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorActions, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorActionsData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorActionsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorActionsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorActionsError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorActionsData: {
                    data: state.behaviorsState?.behaviorActionsData?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypes, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypes: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypes: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypesError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypes: {
                    data: state.behaviorsState?.behaviorTypes?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypeActions, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypeActions: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypeActionsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypeActions: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetBehaviorTypeActionsError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                behaviorTypeActions: {
                    data: state.behaviorsState?.behaviorTypeActions?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehavior, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: action?.payload?.data,
                    loading: false

                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: state.behaviorsState?.selectedBehavior?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.ClearSelectedBehavior, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.DeleteBehavior, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehavior, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: state.behaviorsState?.selectedBehavior?.data,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehaviorSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: state.behaviorsState?.selectedBehavior?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehaviorError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehavior: {
                    data: state.behaviorsState?.selectedBehavior?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorAttribute, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorAttributeSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorAttributeError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: state.behaviorsState?.selectedBehaviorAttribute?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.ClearBehaviorAttribute, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.SaveBehaviorAttribute, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: state.behaviorsState?.selectedBehaviorAttribute?.data,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.SaveBehaviorAttributeSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: state.behaviorsState?.selectedBehaviorAttribute?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.SaveBehaviorAttributeError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAttribute: {
                    data: state.behaviorsState?.selectedBehaviorAttribute?.data,
                    loading: false
                }
            }
        };
    }),
    on(behaviorsActions.GetSelectedBehaviorAction, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorActionSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetSelectedBehaviorActionError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: state.behaviorsState?.selectedBehaviorAction?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.ClearBehaviorActionState, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehaviorAction, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: state.behaviorsState?.selectedBehaviorAction?.data,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehaviorActionSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: state.behaviorsState?.selectedBehaviorAction?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.SaveSelectedBehaviorActionError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                selectedBehaviorAction: {
                    data: state.behaviorsState?.selectedBehaviorAction?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetActionTypes, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                actionTypes: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetActionTypesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                actionTypes: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetActionTypesError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                actionTypes: {
                    data: state.behaviorsState?.actionTypes?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetAttributeTypes, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                attributeTypes: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(behaviorsActions.GetAttributeTypesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                attributeTypes: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(behaviorsActions.GetAttributeTypesError, (state: SelfServiceState) => {
        return {
            ...state,
            behaviorsState: {
                ...state.behaviorsState,
                attributeTypes: {
                    data: state.behaviorsState?.attributeTypes?.data,
                    loading: false
                }
            }
        };
    }),
];
