import { ResponseWrapper } from 'common';

export const productNoticeInitialState: ProductNoticeState = {
    productNoticeData: {
        loading: false,
        data: null
    },
    selectedProductNoticeData: {
        loading: false,
        data: null
    },
    downloadLoader: false,
    noticeTypes: {
        loading: false,
        data: null
    },
    noticeProducts: {
        loading: false,
        data: null
    },
    patternHistoryData: {
        loading: false,
        data: []
    }
};

export interface ProductNoticeState {
    productNoticeData: ResponseWrapper<ProductNoticeSetupsRes>;
    selectedProductNoticeData?: ResponseWrapper<SelectedProductNoticeRes>;
    downloadLoader?: boolean;
    noticeTypes: ResponseWrapper<Array<{code: number, name: string}>>;
    noticeProducts: ResponseWrapper<Array<{code: number, name: string}>>;
    patternHistoryData?: ResponseWrapper<PatternHistoryDataRes[]>;
}

export interface ProductNoticeSetupsRes {
    totalResultCount?: number;
    items?: Array<ProductNoticeDetails>;
}

export interface ProductNoticeDetails {
    name?: string,
    noticeSetupId?: number,
    noticeType?: string,
    primaryProduct?: string,
    additionalProducts?: string,
    confirmationEmailAddress?: string,
    createdBy?: string,
    createdOn?: string,
    changedBy?: string,
    noticeId?: number,
    automated?: string,
    primaryProductId?: number
}

export interface EffortDetails {
    effortAlias?: string,
    unit?: number,
    unitMeasure?: string,
    calculatedBy?: string,
    media?: string
    newEffort?: boolean
}

export interface ProductDetails {
    productId: number,
    productName: string,
    type: string,
    createNewSubscription?: boolean
}

export interface FrequencyDetails {
    frequency?: string,
    frequencyDailyAll?: boolean,
    frequencyDailySunday?: boolean,
    frequencyDailyMonday?: boolean,
    frequencyDailyTuesday?: boolean,
    frequencyDailyWednesday?: boolean,
    frequencyDailyThursday?: boolean,
    frequencyDailyFriday?: boolean,
    frequencyDailySaturday?: boolean,
    freqMonthlyType?: string,
    weeksInMonth?: string,
    daysInWeek?: string,
    freqMonthlyMonthNumbers?: string,
    freqMonthlyDayNumbers?: string,
    lastdayOfMonth?: boolean,
    freqQuarterlyType?: string,
    freqQuarterlyDayNumber?: string,
    quarters?: string,
    freqYearlyType?: string,
    freqYearlyDayNumber?: string,
    hourOfDay?: string,
    minuteOfHour?: string,
    allDayMinuteOfHour?: string,
    timeZone?: string,
    everyOtherWeek?: number
}

export interface ScheduleGroupDetails {
    scheduleName?: string,
    scheduleCodeId?: number,
    panelIdentifier?: string,
    groupingCode?: string,
    salesChannel?: string,
    nameOfAudienceBuilderQuery?: string,
    priority?: number,
    efforts?: Array<EffortDetails>,
    default?: boolean
}

export interface SelectedProductNoticeRes {
    noticeSetupId?: number,
    noticeTypeCode?: number,
    noticeTypeName?: string,
    name?: string,
    productDetails?: Array<ProductDetails>,
    extendExpireDate?: boolean,
    useEmail?: boolean,
    useFtp?: boolean,
    recipientEmail?: Array<string>,
    ftpLocation?: string,
    username?: string,
    password?: string,
    outputMethod?: number,
    separateGeographicLocation?: boolean,
    frequency?: FrequencyDetails,
    chargeFailureOptionValue?: string,
    sendCCFailsToVindicia?: boolean,
    runAutomatedAutoRenewal?: boolean,
    runWithIssueClose?: boolean,
    salesChannel?: string,
    unitOfMeasure?: number,
    calculateBillingGroupFrom?: number,
    successfulChargeDeployment?: string,
    failedChargeDeployment?: string,
    onqSelectionCriteriaId?: number,
    onqSelectionCriteriaName?: string,
    salesChannelOptions?: Array<{name?: string,value?: string}>,
    chargeFailureOptions?: Array<{name?: string,value?: string}>,
    productScheduleGroups?: Array<ScheduleGroupDetails>,
    deploymentOptions?: Array<{name?: string,value?: string}>
    patternMatchingData?: PatternMatching
    noticePanelsData?: NoticePanelsRes;
    campaignPricingData?: ProductNoticeCampaignPricingRes;
}

export interface PatternMatching {
    patternAttributes?: {
        items?: Array<PatternItem>
        totalResultCount?: number
    },
    validCodeIds?: Array<{value?: number, description?: string}>,
    validYMMs?: Array<{value?: number, description?: string}>,
    validSources?: Array<{value?: number, description?: string}>,
    validEfforts?: Array<{code?: number, name?: string}>,
    validPanels?: Array<{code?: number, name?: string}>,
    validMedias?: Array<{code?: number, name?: string}>,
    validCombos?: Array<{code?: number, name?: string}>,
    validFormats?: Array<{code?: number, name?: string}>,
    deploymentTypes?: Array<{code?: number, name?: string}>,
    validPriceGroups?: Array<{code?: number, name?: string}>,
}

export interface PatternItem {
    scheduleAttributeId?: number,
    codeId?: string,
    yearMonthCode?: string,
    salesChannelId?: string,
    effortNumber?: number,
    panelCode?: number,
    mediumCode?: number,
    statusCode?: number,
    outputCriteriaId?: number,
    deploymentTypeId?: number,
    comboStatusCode?: number,
    priceGroupId?: number,
    fileGroupIdentifier?: string
}

export interface NoticePanelsRes {
    totalResultCount?: number;
    items?: Array<NoticePanelItem>;
}

export interface NoticePanelItem {
    noticeSetupId?: number,
    panelCode?: string,
    priority?: number,
    extractType?: string,
    productName?: string,
    audienceBuilderQuery?: string,
    reExecuteQuery?: boolean,
    createdBy?: string,
    createdDate?: string,
    changedBy?: string,
    changedDate?: string,
    default?: boolean,
    scheduleGroupName?: string
}

export interface ProductNoticeCampaignPricingRes {
    countries?: Array<{description?: string, value?: string}>,
    requestedVersionTypes?: Array<{description?: string, value?: string}>
    states?: Array<{description?: string, value?: string}>
    pricingCampaigns?: {
        items?: Array<PricingCampaing>,
        totalResultCount?: number
    }
}

export interface PricingCampaing {
    productPriceGroupId?: number,
    productName?: string,
    productId?: number,
    priceList?: Array<Price>,
    priceGroupDescription?: string,
    priceChoices?: Array<{code?: number, name?: string}>,
    frequencyUom?: string,
    frequency?: number,
    combineWithList?: Array<number>,
    childPricingTableList?: Array<ChildPricing>
}

export interface Price {
    amount?: number,
    hasStateAssociation?: boolean,
    locationId?: string,
    priceCodeId?: number,
    priceChoiceId?: number,
    requestedVersionTypeValue?: string,
    termValue?: number | string,
    multiplePriceCodes?: Array<{locationId?: string, amount?: number, priceCodeId?: number}>,
    webFormText?: string
}

export interface ChildPricing {
    priceChoices?: Array<{code?: number, name?: string}>,
    priceGroupDescription?: string,
    priceList?: Array<ChildPrice>,
    productId?: number,
    productName?: string,
    productPriceGroupId?: number
    frequencyUom?: string,
    frequency?: number,
}

export interface ChildPrice {
    amount?: number,
    combineWithRow?: number,
    hasStateAssociation?: boolean,
    locationId?: string,
    priceCodeId?: number,
    priceChoiceId?: number,
    requestedVersionTypeValue?: string,
    termValue?: number,
    frequency?: number,
    frequencyUom?: string,
    multiplePriceCodes?: Array<{locationId?: string, amount?: number, priceCodeId?: number}>,
    webFormText?: string
}

export interface PatternHistoryDataRes {
    productNoticeId?: number,
    patternAttributes?: PatternItem[],
}