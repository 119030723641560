import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CasHttpClient, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, concatMap, exhaustMap, first, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as LeadScoringActions from './lead-scoring.actions';
import { AttributeType, ExternalId, LeadScoring, LeadScoringLogs, OlyticsUrlsData, RuleType } from './lead-scoring.interfaces';
import { getLeadScoringData } from './lead-scoring.selectors';

@Injectable({ providedIn: 'root' })
export class CampaignsLeadScoringEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
        private casHttp: CasHttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    getLeadScoringLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetLeadScoringLogs),
            switchMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('numResults', action.payload?.numResults?.toString());
                searchParams.set('offset', action.payload?.offset?.toString());
                searchParams.set('searchText', action.payload?.searchText);
                searchParams.set('order', action.payload?.order);
                searchParams.set('sortBy', action.payload?.sortBy);
                searchParams.set('status', action.payload?.status);
                return this.http
                    .get(this.baseUrl + `/search?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: LeadScoringLogs) => {
                            return LeadScoringActions.GetLeadScoringLogsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Lead Scoring Logs.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(LeadScoringActions.GetLeadScoringLogsError(error));
                        })
                    );
            })
        )
    );

    getLeadScoring$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetLeadScoring),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('orgId', this.currentDatabase?.org?.id);
                searchParams.set('leadGroupId', action.payload?.leadGroupId?.toString());
                return this.http
                    .get(this.baseUrl + `?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: LeadScoring) => {
                            const isOwnerActive = res?.ownersList?.some((owner) => {
                                return owner?.userId === this.currentUser?.userId;
                            });
                            const payload = {
                                ...res,
                                owner: res?.status === 'New' ? this.currentUser?.userId : isOwnerActive ? res?.owner : ''
                            };
                            return LeadScoringActions.GetLeadScoringSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Lead Scoring data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            this.router.navigate([`${environment.campaignsPath}/main/lead-scoring/search-logs`]);
                            return of(LeadScoringActions.GetLeadScoringError(error));
                        })
                    );
            })
        )
    );

    manageLeadScoring$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.ManageLeadScoring),
            exhaustMap(() => {
                let data = null;
                this.store.select(getLeadScoringData)?.pipe(
                    first(res => !!res?.data),
                    map(res => {
                        // data = { ...res?.data };
                        data = JSON.parse(JSON.stringify(res?.data));
                    })
                )?.subscribe();
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('orgId', this.currentUser?.currentOrganization?.id);
                delete data?.changedDate;
                delete data?.createdDate;
                delete data?.changedBy;
                delete data?.createdBy;
                delete data?.lastRanDate;
                delete data?.ran;
                delete data?.dataViewId;
                delete data?.highestScore;
                delete data?.ownersList;
                data?.leadRulesList?.forEach(rule => {
                    delete rule?.displayDescription;
                    rule?.conditions?.forEach(condition => {
                        delete condition?.displayDescription;
                        condition?.conditionParameters?.forEach(param => {
                            delete param?.externalId;
                        });
                    });
                });
                return this.http
                    .post(this.baseUrl + `?${searchParams}`,
                        data,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: LeadScoring) => {
                            return LeadScoringActions.ManageLeadScoringSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create or update Lead Scoring Event.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(LeadScoringActions.ManageLeadScoringError(error));
                        })
                    );
            })
        )
    );

    getLeadScoringRuleTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetLeadScoringRuleTypes),
            exhaustMap(() => {
                return this.http.get(environment.campaignApiEndPoint + '/campaign/rule-types', { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<RuleType>) => {
                        return LeadScoringActions.GetLeadScoringRuleTypesSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get Rule Types.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.GetLeadScoringRuleTypesError(error));
                    })
                );
            })
        )
    );

    getRuleTypeExternalIdList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetRuleTypeExternalIdList),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('ruleTypeId', action.payload?.ruleTypeId?.toString());
                return this.http.get(environment.campaignApiEndPoint + `/campaign/external-ids-by-ruletype?${searchParams}`, { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<ExternalId>) => {
                        return LeadScoringActions.GetRuleTypeExternalIdListSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get External Ids by Rule Type.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.GetRuleTypeExternalIdListError(error));
                    })
                );
            })
        )
    );

    getOlyticsUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetOlyticsUrls),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('singlePage', 'true');
                searchParams.set('searchText', action.payload?.searchText);
                return this.http.get(environment.campaignApiEndPoint + `/campaign/olytics/pages?${searchParams}`, { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: OlyticsUrlsData) => {
                        const payload: ResponseWrapper<OlyticsUrlsData> = {
                            loading: false,
                            data: res
                        };
                        return LeadScoringActions.GetOlyticsUrlsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get Olytics Urls.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.GetOlyticsUrlsError(error));
                    })
                );
            })
        )
    );

    getBehaviorAttributeTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetBehaviorAttributeTypes),
            concatMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('behaviorId', action.payload?.behaviorId);
                return this.casHttp.get(environment.settingsApiEndPoint, environment.settingsApiEndPoint + `/behavior/attribute-type?${searchParams}`, { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<AttributeType>) => {
                        let data = [];
                        if (res?.length > 0) {
                            data = res.map(attrType => ({
                                attributeTypeId: attrType?.attributeTypeId?.toString(),
                                attributeTypeName: attrType?.attributeTypeName
                            }));
                        }
                        return LeadScoringActions.GetBehaviorAttributeTypesSuccess({ payload: { behaviorId: action?.payload?.behaviorId, data } });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get Behavior Attribute Types.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.GetBehaviorAttributeTypesError(error));
                    })
                );
            })
        )
    );

    getBehaviorAttributeTypesSearchValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LeadScoringActions.GetBehaviorAttributeTypesSearchValues),
            concatMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(environment.settingsApiEndPoint + `/behavior/attribute-value/${action?.payload?.behaviorAttributeTypeId}?${searchParams}`, { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: Array<ExternalId>) => {
                        return LeadScoringActions.GetBehaviorAttributeTypesSearchValuesSuccess({ payload: { behaviorAttributeTypeId: action?.payload?.behaviorAttributeTypeId, data: res } });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get Behavior Attribute Type Search Values.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.GetBehaviorAttributeTypesSearchValuesError(error));
                    })
                );
            })
        )
    );

    saveLeadScoring$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LeadScoringActions.SaveLeadScoring),
            exhaustMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('orgId', this.currentUser?.currentOrganization?.id);

                //TODO Backend should allow receiving these properties since it's sending them in the first place
                const payloadWithoutRangeStartEnd = JSON.parse(JSON.stringify(action.payload));
                payloadWithoutRangeStartEnd?.leadScoresList?.map(score => {
                    delete score?.rangeStart;
                    delete score?.rangeEnd;
                });
                payloadWithoutRangeStartEnd?.leadRulesList?.map(rule => {
                    if (rule?.ruleId?.toString()?.includes('temporary-')) {
                        rule.ruleId = null;
                    }
                });
                return this.http.post(
                    environment.campaignApiEndPoint + `/campaign/lead-scoring?${searchParams}`,
                    payloadWithoutRangeStartEnd,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: LeadScoring) => {
                        if (action?.navigate) {
                            this.router.navigate([`${environment.campaignsPath}/main/lead-scoring/${action?.navigate}`], {
                                queryParams: { leadGroupId: res?.leadGroupId },
                                queryParamsHandling: 'merge',
                            });
                        } else {
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: { leadGroupId: res?.leadGroupId },
                                queryParamsHandling: 'merge',
                            });
                        }
                        return LeadScoringActions.SaveLeadScoringSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to save lead scoring.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(LeadScoringActions.SaveLeadScoringError(error));
                    })
                );
            })
        );
    });

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/campaign/lead-scoring';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
