import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BasicResponse, getCurrentUser, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import * as IntegrationsAdrollEventBasedActions from '@integrations/state/adroll-event-based/adroll-event-based.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdrollAccessInfo, AdrollAudienceSyncData, AdrollEBASiteReport, AdrollPixelSegment, AdrollPixelSegmentDetail, AdrollSegmentAudienceI, AdrollSitesRes } from '@integrations/state/adroll-event-based/adroll-event-based.interface';
import { GetAdvertiserAccounts } from '@integrations/state/adroll/adroll.actions';
import { AdrollAuthorization } from '@integrations/state/adroll/adroll.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsAdrollEventBasedEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
    ) { }

    getAdrollSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.GetAdrollSites),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('order', action.payload?.order || '');
                searchParams.set('isArchived', action.payload?.isArchived?.toString() || 'false');

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/site?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: AdrollSitesRes) => {
                            const payload: ResponseWrapper<AdrollSitesRes> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsAdrollEventBasedActions.GetAdrollSitesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get Sites.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.GetAdrollSitesError(error));
                        })
                    );
            })
        )
    );

    getAdrollAudienceSyncs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.GetAudienceSyncs),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                searchParams.set('isArchived', action.payload?.isArchived != undefined ? action.payload.isArchived.toString() : '');
                searchParams.set('offset', action.payload?.offset ? action.payload.offset.toString() : '');
                searchParams.set('numResult', action.payload?.numResults ? action.payload.numResults.toString() : '');
                searchParams.set('sortBy', action.payload?.sortBy ? action.payload.sortBy.toString() : '');
                searchParams.set('order', action.payload?.order ? action.payload.order.toString() : '');

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/all?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: AdrollPixelSegment[]) => {
                            const payload: ResponseWrapper<AdrollAudienceSyncData> = {
                                loading: false,
                                data: {res, olyticsSiteId: action.payload.olyticsSiteId, isArchived: action.payload?.isArchived},
                            };
                            return IntegrationsAdrollEventBasedActions.GetAudienceSyncsSuccess({ payload });
                        }),
                        catchError(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get audience syncs.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.GetAudienceSyncsError({ payload: { olyticsSiteId: action.payload.olyticsSiteId, isArchived: action.payload?.isArchived } }));
                        })
                    );
            })
        )
    );

    getArchivedAudienceSyncs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncs),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                searchParams.set('isArchived', 'true');
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/all?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: AdrollPixelSegment[]) => {
                            const payload: ResponseWrapper<AdrollPixelSegment[]> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get archived audience syncs.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncsError({ payload: error }));
                        })
                    );
            })
        )
    );

    loadAudienceSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: AdrollPixelSegmentDetail) => {
                            const payload: ResponseWrapper<AdrollPixelSegmentDetail> = {
                                loading: false,
                                data: res,
                            };
                            if (!action.payload?.adrollPixelSegmentId && res?.adrollPixelSegmentId) {
                                const currentPath = '/integrations/main/adroll-event-based/audience-sync';
                                const newUrl = `${currentPath}/${res?.adrollPixelSegmentId?.toString()}`;
                                this.location.go(newUrl);
                            }
                            return IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSyncSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to load Adroll Audience Sync.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSyncError(error));
                        })
                    );
            })
        )
    );

    deleteAudienceSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.DeleteAdrollAudiecneSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.delete(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.payload?.olyticsSiteId) {
                                this.store.dispatch(IntegrationsAdrollEventBasedActions.GetAudienceSyncs({ payload: { olyticsSiteId: action.payload.olyticsSiteId } }));
                            }
                            return IntegrationsAdrollEventBasedActions.DeleteAdrollAudienceSyncSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to delete Adroll Audience Sync.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.DeleteAdrollAudienceSyncError(error));
                        })
                    );
            })
        )
    );

    saveAudienceSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('accessToken', action.payload.accessToken.toString());
                action.payload?.activate != undefined ? searchParams.set('activate', action.payload.activate.toString()) : null;
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment?${searchParams}`,
                    action.payload?.saveAdrollAudienceReq,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: AdrollPixelSegmentDetail) => {
                        const payload: ResponseWrapper<AdrollPixelSegmentDetail> = {
                            loading: false,
                            data: res,
                        };
                        if (action.payload?.saveAdrollAudienceReq?.adrollPixelSegmentId == 0) {
                            this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/settings'], { queryParams: { adrollPixelSegmentId: res?.adrollPixelSegmentId?.toString(), olyticsSiteId: res?.olyticsSiteId?.toString() } });
                        }

                        if (action.payload?.saveAdrollAudienceReq?.adrollPixelSegmentId && action.payload?.currentRoute) {
                            switch (action.payload?.currentRoute) {
                                case 'general':
                                    this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/settings'], { queryParams: { adrollPixelSegmentId: res?.adrollPixelSegmentId?.toString(), olyticsSiteId: res?.olyticsSiteId?.toString() } });
                                    break;
                                case 'settings':
                                    this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/url-targeting'], { queryParams: { adrollPixelSegmentId: res?.adrollPixelSegmentId?.toString(), olyticsSiteId: res?.olyticsSiteId?.toString() } });
                                    break;
                                case 'url-targeting':
                                    this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/audience-targeting'], { queryParams: { adrollPixelSegmentId: res?.adrollPixelSegmentId?.toString(), olyticsSiteId: res?.olyticsSiteId?.toString() } });
                                    break;
                                default:
                                    break;
                            }
                        }
                        return IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSyncSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to save Adroll Audience Sync.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSyncError(error));
                    })
                );
            })
        )
    );

    updateAudienceSyncSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncSettings),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/settings?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/url-targeting'], { queryParamsHandling: 'merge' });
                        this.store.dispatch(IntegrationsAdrollEventBasedActions.SetCurrentAdrollAudienceSyncState({ payload: action.payload }));
                        return IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncSettingsSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to update settings for Adroll Audience Sync.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncSettingsError(error));
                    })
                );
            })
        )
    );

    updateAudienceSyncUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncUrls),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/url-targeting?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.router.navigate(['/integrations/main/adroll-event-based/audience-sync/audience-targeting'], { queryParamsHandling: 'merge' });
                        this.store.dispatch(IntegrationsAdrollEventBasedActions.SetCurrentAdrollAudienceSyncState({ payload: action.payload }));
                        return IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncUrlsSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to update url targeting for Adroll Audience Sync.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncUrlsError(error));
                    })
                );
            })
        )
    );

    updateAudienceSyncAudiences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncAudience),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/audience-targeting?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            // this.store.dispatch(IntegrationsAdrollEventBasedActions.SetCurrentAdrollAudienceSyncState({payload: action.payload}));
                            return IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncAudienceSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to update audience targeting for Adroll Audience Sync.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncAudienceError(error));
                        })
                    );
            })
        )
    );
    addAbQueryToAudienceSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                searchParams.set('selectionCriteriaId', action.payload.selectionCriteriaId.toString());
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/segment-audience?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: AdrollSegmentAudienceI) => {
                            res = { ...res, ...action.payload };
                            const payload: ResponseWrapper<AdrollSegmentAudienceI> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSyncSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to add AB Query to Audience Sync.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSyncError(error));
                        })
                    );
            })
        )
    );

    deleteAudienceSyncAudience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentAudienceId', action.payload.adrollPixelSegmentAudienceId.toString());
                return this.http.delete(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/segment-audience?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<{res: BasicResponse; adrollPixelSegmentAudienceId: number;}> = {
                                loading: false,
                                data: { res, adrollPixelSegmentAudienceId: action.payload.adrollPixelSegmentAudienceId },
                            };
                            return IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSyncSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to delete audience.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSyncError(error));
                        })
                    );
            })
        )
    );

    getAudienceSyncAuthInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.GetAudienceSyncAuthInfo),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll-audience-syncs/auth-url');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                }).toString();
                const params = {
                    odyssey: false,
                    redirectUrl: action.payload.redirectUrl
                };

                return this.http.post(url.toString(), params, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AdrollAuthorization) => {
                        const payload: ResponseWrapper<AdrollAuthorization> = { loading: false, data: res };
                        return IntegrationsAdrollEventBasedActions.GetAudienceSyncAuthInfoSuccess({ payload: payload });
                    }
                    ),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get Adroll Audience Sync Auth Info.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.GetAudienceSyncAuthInfoError(error));
                    })
                );
            })
        )
    );

    getAudienceSyncAccessToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.GetAudienceSyncAccessToken),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/adroll-audience-syncs/access-token-from-code');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id,
                    code: action.payload.code
                }).toString();
                const payload = {
                    redirectUrl: window.location.href,
                    odyssey: false
                };
                return this.http.post(url.toString(), payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AdrollAccessInfo) => {
                        if (res.errorMessage) {
                            throw new Error(res.errorMessage);
                        }
                        const payload: ResponseWrapper<AdrollAccessInfo> = { loading: false, data: res };
                        this.store.dispatch(GetAdvertiserAccounts({ payload: { accessToken: res.accessToken } }));
                        return IntegrationsAdrollEventBasedActions.GetAudienceSyncAccessTokenSuccess({ payload: payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        error.message || 'An error has occurred while trying to get Adroll Audience Sync Access Token.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.GetAudienceSyncAccessTokenError(error));
                    })
                );
            })
        )
    );

    archiveAudienceSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.ArchiveAdrollAudienceSync),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/archive?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = {
                            loading: false,
                            data: res,
                        };
                        if (action.payload?.olyticsSiteId) this.store.dispatch(IntegrationsAdrollEventBasedActions.GetAudienceSyncs({ payload: { olyticsSiteId: action.payload.olyticsSiteId } }));
                        else this.store.dispatch(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSync({ payload: { adrollPixelSegmentId: action.payload?.adrollPixelSegmentId } }));
                        return IntegrationsAdrollEventBasedActions.ArchiveAdrollAudienceSyncSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to archive adroll audience sync.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.ArchiveAdrollAudienceSyncError(error));
                    })
                );
            })
        )
    );

    restoreAdrollPixelEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.RestoreAdrollPixelEvent),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/restore?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = {
                            loading: false,
                            data: res,
                        };
                        if (action.payload?.olyticsSiteId) {
                            this.store.dispatch(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncs({ payload: { olyticsSiteId: action.payload.olyticsSiteId } }));
                        } else {
                            this.store.dispatch(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSync({ payload: { adrollPixelSegmentId: action.payload?.adrollPixelSegmentId } }));
                        }
                        return IntegrationsAdrollEventBasedActions.RestoreAdrollPixelEventSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to restore adroll audience sync.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.RestoreAdrollPixelEventError(error));
                    })
                );
            })
        )
    );

    viewAudienceSyncReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.ViewAudienceSyncReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/view-report?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: AdrollEBASiteReport) => {
                        const payload: ResponseWrapper<AdrollEBASiteReport> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsAdrollEventBasedActions.ViewAudienceSyncReportSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to view audience sync report.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.ViewAudienceSyncReportError(error));
                    })
                );
            })
        )
    );

    createAdrollSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.CreateAdrollSite),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/site?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = {
                            loading: false,
                            data: res,
                        };
                        this.store.dispatch(IntegrationsAdrollEventBasedActions.GetAdrollSites({ payload: { offset: 0, numResults: 15 } }));
                        return IntegrationsAdrollEventBasedActions.CreateAdrollSiteSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to create site.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.CreateAdrollSiteError(error));
                    })
                );
            })
        )
    );

    viewAdrollPixelSegmentReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('adrollPixelSegmentId', action.payload.adrollPixelSegmentId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/adroll-audience-syncs/pixel-segment/view-report?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: ResponseWrapper<AdrollEBASiteReport>) => {
                        return IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReportSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get pixel segment report.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReportError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
