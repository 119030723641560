import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsDeltFtpActions from './delta-ftp.actions';
import { IntegrationDashboardSchema, IntegrationDeltaExtractSetup, IntegrationExtractFileResponse, IntegrationFileFormatResponse, IntegrationHelperResponse, IntegrationOutputCriteriaGroupItem, MappedProduct } from './delta-ftp.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsDeltFtpEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore
    ) { }

    getDeltaJobs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetDeltaJobs),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('offset', action.payload?.offset?.toString());
                searchParams.set('numResults', action.payload?.numResult?.toString());
                searchParams.set('sortBy', action.payload?.sortBy?.toString() || '');
                searchParams.set('order', action.payload?.order?.toString() || '');
                action.payload?.searchText ? searchParams.set('searchText', action.payload.searchText.toString()) : null;
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/delta-to-ftp/jobs?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: IntegrationDashboardSchema) => {
                            const payload: ResponseWrapper<IntegrationDashboardSchema> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsDeltFtpActions.GetDeltaJobsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to load Delta to FTP Jobs.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsDeltFtpActions.GetDeltaJobsError(error));
                        })
                    );
            })
        )
    );

    getDeltaJobDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetDeltaJobDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('numResults', action.payload?.numResult?.toString() || '10');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('order', action.payload?.order || '');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                action.payload.extractSetupId ? searchParams.set('extractSetupId', action.payload.extractSetupId?.toString()) : null;
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/delta-to-ftp?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationHelperResponse) => {
                        const payload: ResponseWrapper<IntegrationHelperResponse & {reloadProductsTable?: boolean;}> = {
                            loading: false,
                            data: {...res, reloadProductsTable: action.payload?.reloadProductsTable},
                        };
                        return IntegrationsDeltFtpActions.GetDeltaJobDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get job details.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        this.router.navigate(['/integrations/main/delta-to-ftp']);
                        return of(IntegrationsDeltFtpActions.GetDeltaJobDetailsError({payload: error, reloadProductsTable: action.payload?.reloadProductsTable}));
                    })
                );
            })
        )
    );

    deltaResetFullFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.ResetDeltaFullFile),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('extractSetupId', action.payload.extractSetupId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/reset-full-file?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Success',
                                    design: 'Success',
                                    placement: 'TopEnd',
                                    message: res?.messages?.[0] ? res?.messages?.[0] : 'Reset for full file has been successfully initiated.',
                                    icon: 'fa-circle-check-solid',
                                },
                            })
                        );
                        return IntegrationsDeltFtpActions.ResetDeltaFullFileSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: error.error?.errors[0] ? error.error?.errors[0] : 'An error has occurred while trying to reset for full file.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.ResetDeltaFullFileError(error));
                    })
                );
            })
        )
    );

    saveDeltaJobDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.SaveJobDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                this.store.dispatch(IntegrationsDeltFtpActions.UnsavedFtpChange({ payload: false }));
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/delta-to-ftp?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationDeltaExtractSetup) => {
                        const payload: ResponseWrapper<IntegrationDeltaExtractSetup & {pageLoadPoolSelectionCriteriaName: string;}> = {
                            loading: false,
                            data: { ...res, ...action?.payload, pageLoadPoolSelectionCriteriaName: action?.pageLoadPoolSelectionCriteriaName || '' },
                        };

                        if (!action.payload?.extractSetupId) {
                            this.router.navigate(['/integrations/main/ftp-job/customer-selection'], { queryParams: { extractSetupId: res?.extractSetupId?.toString() } });
                        }

                        if (action.payload?.extractSetupId && action?.currentRoute) {
                            switch (action.currentRoute) {
                                case 'job-details':
                                    this.router.navigate(['/integrations/main/ftp-job/customer-selection'], { queryParams: { extractSetupId: res?.extractSetupId?.toString() } });
                                    break;
                                case 'customer-selection':
                                    this.router.navigate(['/integrations/main/ftp-job/file-mappings'], { queryParams: { extractSetupId: res?.extractSetupId?.toString() } });
                                    break;
                                case 'file-mappings':
                                    this.router.navigate(['/integrations/main/delta-to-ftp']);
                                    break;
                                default:
                                    break;
                            }
                        }
                        this.store.dispatch(IntegrationsDeltFtpActions.GetDeltaJobDetails({ payload: { extractSetupId: res?.extractSetupId } }));

                        if (res?.message) {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: '',
                                        design: '',
                                        placement: 'TopEnd',
                                        message: res?.message,
                                    },
                                })
                            );
                        }
                        return IntegrationsDeltFtpActions.SaveJobDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: error.error?.errors?.[0] ? error.error?.errors?.[0] : 'An error has occurred while trying save job details.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.SaveJobDetailsError(error));
                    })
                );
            })
        )
    );

    viewCurrentlyMappedFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.ViewMappedFields),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('pageLoadFileGroupId', action.payload.pageLoadFileGroupId?.toString());
                searchParams.set('productId', action.payload.productId?.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/view-mapped-fields?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: MappedProduct) => {
                        const payload: ResponseWrapper<MappedProduct> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.ViewMappedFieldsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to view currently mapped fields.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.ViewMappedFieldsError(error));
                    })
                );
            })
        )
    );

    getJobFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetJobFiles),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('extractSetupId', action.payload.extractSetupId?.toString());
                searchParams.set('pageLoadFileGroupId', action.payload.pageLoadFileGroupId?.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/delta-to-ftp?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationHelperResponse) => {
                        const payload: ResponseWrapper<IntegrationHelperResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.GetJobFilesSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get job file formats.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.GetJobFilesError(error));
                    })
                );
            })
        )
    );

    getFileFormatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetFileFormatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('extractSetupId', action.payload.extractSetupId?.toString());
                searchParams.set('pageLoadProductId', action.payload.pageLoadProductId?.toString());
                action.payload?.outputCriteriaId ? searchParams.set('outputCriteriaId', action.payload?.outputCriteriaId?.toString()) : null;
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/file-format?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationFileFormatResponse) => {
                        const payload: ResponseWrapper<IntegrationFileFormatResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.GetFileFormatDataSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get job file formats.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.GetFileFormatDataError(error));
                    })
                );
            })
        )
    );

    removeFileFormat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.RemoveFileFormat),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/integration/remove-file-format?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Successfully Removed File Format' } }));
                            this.store.dispatch(IntegrationsDeltFtpActions.GetDeltaJobDetails({ payload: action?.reloadPayload }));
                            return IntegrationsDeltFtpActions.RemoveFileFormatSuccess();
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to remove file format.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsDeltFtpActions.RemoveFileFormatError(error));
                        })
                    );
            })
        )
    );

    getAvailableFileFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetAvailableFileFields),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/available/fields?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationOutputCriteriaGroupItem) => {
                        const payload: ResponseWrapper<IntegrationOutputCriteriaGroupItem> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.GetAvailableFileFieldsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get available file fields.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.GetAvailableFileFieldsError(error));
                    })
                );
            })
        )
    );

    getDefaultFileFormatFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetDefaultFileFormatFields),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/file-format?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationFileFormatResponse) => {
                        const payload: ResponseWrapper<IntegrationFileFormatResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.GetDefaultFileFormatFieldsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get default file formats.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.GetDefaultFileFormatFieldsError(error));
                    })
                );
            })
        )
    );

    getFileFormat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.GetFileFormat),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('outputCriteriaId', action.payload.outputCriteriaId?.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/file-format?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: IntegrationFileFormatResponse) => {
                        const payload: ResponseWrapper<IntegrationFileFormatResponse> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsDeltFtpActions.GetFileFormatSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to get job file format.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsDeltFtpActions.GetFileFormatError(error));
                    })
                );
            })
        )
    );

    manageFileFormat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.ManageFileFormat),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/integration/file-format?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map((res: IntegrationExtractFileResponse) => {
                            const payload: ResponseWrapper<IntegrationExtractFileResponse> = {
                                loading: false,
                                data: res
                            };
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: res?.message || 'Successfully Managed File Format' } }));
                            this.store.dispatch(IntegrationsDeltFtpActions.GetDeltaJobDetails({ payload: action?.reloadPayload }));
                            return IntegrationsDeltFtpActions.ManageFileFormatSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: error.error?.errors[0] ? error.error?.errors[0] : 'An error has occurred while trying to manage file format.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsDeltFtpActions.ManageFileFormatError(error));
                        })
                    );
            })
        )
    );

    deltaJobStatusUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsDeltFtpActions.UpdateDeltaJobStatus),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('extractSetupId', action.payload.extractSetupId?.toString());
                searchParams.set('statusCode', action.payload.statusCode?.toString());
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/integration/delta-to-ftp/update-status?${searchParams}`,
                        { withCredentials: true }
                    )
                    .pipe(
                        take(1),
                        map(() => {
                            this.store.dispatch(ShowToast({ payload: { title: 'Success', design: 'Success', placement: 'TopEnd', message: 'Successfully Updated Delta Job' } }));
                            this.store.dispatch(IntegrationsDeltFtpActions.GetDeltaJobs({ payload: action.payload?.reload }));
                            return IntegrationsDeltFtpActions.UpdateDeltaJobStatusSuccess();
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: error.error?.errors[0] ? error.error?.errors[0] : 'An error has occurred while trying to manage file format.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsDeltFtpActions.UpdateDeltaJobStatusError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
