import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CasHttpClient, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as MessageTypesActions from './message-types.actions';
import { BusinessRep, BusinessProfile, CommonObjectResponse, MessageType, MessageTypesData, RegionOption } from './message-types.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceMessageTypesEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private casHttp: CasHttpClient,
    ) { }

    getSmsBuilderUserPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetSmsBuilderUserPermissions),
            switchMap(() => {
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + '/permissions', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: string[]) => {
                            return MessageTypesActions.GetSmsBuilderUserPermissionsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Sms Builder User permissions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetSmsBuilderUserPermissionsError(error));
                        })
                    );
            })
        )
    );

    getMessageTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetMessageTypesList),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('numResults', action?.payload?.numResults?.toString());
                searchParams.set('offset', action?.payload?.offset?.toString());
                searchParams.set('order', action?.payload?.order);
                searchParams.set('sortBy', action?.payload?.sortBy);
                searchParams.set('searchText', action?.payload?.searchText);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message-types?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageTypesData) => {
                            return MessageTypesActions.GetMessageTypesListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Message Types list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetMessageTypesListError(error));
                        })
                    );
            })
        )
    );

    createMessageType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.CreateMessageType),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(this.baseUrl + `/message-type?${searchParams}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageType) => {
                            this.router.navigate(['self-service', 'main', 'edit-message-type', res?.messageTypeId]);
                            return MessageTypesActions.CreateMessageTypeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create a new Message Type.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.CreateMessageTypeError(error));
                        })
                    );
            })
        )
    );

    getMessageType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetMessageType),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/message-type/${action?.payload?.messageTypeId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageType) => {
                            return MessageTypesActions.GetMessageTypeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Message Type.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetMessageTypeError(error));
                        })
                    );
            })
        )
    );

    updateMessageType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.UpdateMessageType),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/message-type/${action?.payload?.messageTypeId}?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageType) => {
                            return MessageTypesActions.UpdateMessageTypeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update Message Type.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (!errorMessage.startsWith('This message-type can not be deactivated because it is being used by the following forms:')) {
                                this.showError(null, errorMessage);
                            }
                            return of(MessageTypesActions.UpdateMessageTypeError(error));
                        })
                    );
            })
        )
    );

    getUseCasesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetUseCasesList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/message-type/options/use-cases?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetUseCasesListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Use Cases list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetUseCasesListError(error));
                        })
                    );
            })
        )
    );

    getVolumeOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetVolumeOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/message-type/options/volume-options?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetVolumeOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Volume Options list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetVolumeOptionsListError(error));
                        })
                    );
            })
        )
    );

    getOptInOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetOptInOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/message-type/options/optin-options?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetOptInOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Opt-in Options list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetOptInOptionsListError(error));
                        })
                    );
            })
        )
    );

    // Bussiness Profiles
    getBusinessProfilesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessProfilesList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-profiles?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<BusinessProfile>) => {
                            return MessageTypesActions.GetBusinessProfilesListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Business Profiles list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessProfilesListError(error));
                        })
                    );
            })
        )
    );

    createBusinessProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.CreateBusinessProfile),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(this.baseUrl + `/business-profile?${searchParams}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessProfile) => {
                            return MessageTypesActions.CreateBusinessProfileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create new Business Profile.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.CreateBusinessProfileError(error));
                        })
                    );
            })
        )
    );

    getBusinessProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessProfile),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-profile/${action?.payload?.businessProfileId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessProfile) => {
                            return MessageTypesActions.GetBusinessProfileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Business Profile.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessProfileError(error));
                        })
                    );
            })
        )
    );

    updateBusinessProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.UpdateBusinessProfile),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/business-profile/${action?.payload?.businessProfileId}?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessProfile) => {
                            return MessageTypesActions.UpdateBusinessProfileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update Business Profile.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.UpdateBusinessProfileError(error));
                        })
                    );
            })
        )
    );

    getBusinessIndustryOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessIndustryOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-profile/options/business-industry-options?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetBusinessIndustryOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Business Industry Options list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessIndustryOptionsListError(error));
                        })
                    );
            })
        )
    );

    getBusinessTypeOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessTypeOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-profile/options/business-type-options?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetBusinessTypeOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Business Type Options list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessTypeOptionsListError(error));
                        })
                    );
            })
        )
    );

    getRegionOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetRegionOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-profile/options/region-options?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<RegionOption>) => {
                            return MessageTypesActions.GetRegionOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Region Options list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetRegionOptionsListError(error));
                        })
                    );
            })
        )
    );

    // Business Reps
    getBusinessRepsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessRepsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-reps?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<BusinessRep>) => {
                            return MessageTypesActions.GetBusinessRepsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load available Business Reps list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessRepsListError(error));
                        })
                    );
            })
        )
    );

    createBusinessRep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.CreateBusinessRep),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(this.baseUrl + `/business-rep?${searchParams}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessRep) => {
                            return MessageTypesActions.CreateBusinessRepSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create Business Contact.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.CreateBusinessRepError(error));
                        })
                    );
            })
        )
    );

    getBusinessRep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetBusinessRep),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-rep/${action?.payload?.businessRepId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessRep) => {
                            return MessageTypesActions.GetBusinessRepSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Business Contact.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetBusinessRepError(error));
                        })
                    );
            })
        )
    );

    updateBusinessRep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.UpdateBusinessRep),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/business-rep/${action?.payload?.businessRepId}?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BusinessRep) => {
                            return MessageTypesActions.UpdateBusinessRepSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update Business Contact.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.UpdateBusinessRepError(error));
                        })
                    );
            })
        )
    );

    getJobTitlesOptionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageTypesActions.GetJobTitlesOptionsList),
            switchMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(this.baseUrl + `/business-rep/options/job-titles?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<CommonObjectResponse>) => {
                            return MessageTypesActions.GetJobTitlesOptionsListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Job Positions list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(MessageTypesActions.GetJobTitlesOptionsListError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.smsApiEndPoint;
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.activatedRoute?.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
