import { on } from '@ngrx/store';
import { CustomerDataPlatformState, GeneratedHtmlResponse } from '../interfaces';
import * as CdpPersonalizationsActions from './personalizations.actions';

export const personalizationsReducer = [
    on(CdpPersonalizationsActions.GetPersonalizationsData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: true,
                data: state.personalizationsData?.data
            },
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationsDataSuccess, (state: CustomerDataPlatformState, action) => {
        const data = {
            ...action.payload.data,
            personalizationList: action.payload.data?.personalizationList?.map((personalization) => {
                const position = personalization?.displayPositions?.length && personalization?.displayDescriptions?.length
                    ? personalization.displayDescriptions.slice(0,2).filter(d => d).join(', ')
                    : personalization?.displayDescription
                        ? personalization?.displayDescription
                        : action.payload.data?.positionList?.length
                            ? action.payload.data.positionList?.find((position) => position?.code === personalization?.displayPosition)?.name
                            : '';
                return { ...personalization, position };
            })
        };
        return {
            ...state,
            personalizationsData: {
                loading: false,
                data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationsDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: false,
                data: state.personalizationsData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationsArchivedData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsArchivedData: {
                loading: true,
                data: state.personalizationsArchivedData?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationsArchivedDataSuccess, (state: CustomerDataPlatformState, action) => {
        let data = JSON.parse(JSON.stringify(action.payload.data));
        const positionList = state?.personalizationsData?.data?.positionList;
        if (positionList && positionList?.length > 0) {
            data = {
                ...data,
                personalizationList: data?.personalizationList?.map((personalization) => {
                    return {
                        ...personalization,
                        position: positionList?.find((position) => position?.code === personalization?.displayPosition)?.name
                    };
                })
            };
        }
        return {
            ...state,
            personalizationsArchivedData: {
                loading: false,
                data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationsArchivedDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsArchivedData: {
                loading: false,
                data: state.personalizationsArchivedData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.ClearCurrentPersonalization, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: null
            },
            olyticsParameters: {
                loading: false,
                data: null
            }
        };
    }),

    on(CdpPersonalizationsActions.LoadPersonalizationBasicsData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: true,
                data: state.currentPersonalizationData?.data
            },
            olyticsParameters: {
                loading: false,
                data: null
            }
        };
    }),
    on(CdpPersonalizationsActions.LoadPersonalizationDataBasicsSuccess, (state: CustomerDataPlatformState, action) => {
        const productMap = action.payload?.data?.productMap;
        const paymentStatusMap = action.payload?.data?.paymentStatusMap;
        let responseData = action.payload?.data;
        const oldPersonalizationId = state.currentPersonalizationData?.data?.personalizationId;
        const typeList = action.payload?.data?.typeList;
        if (oldPersonalizationId == -1) {
            responseData = {...responseData, saveBasicState: true};
        }
        if (typeList && typeList?.length > 0 && action.payload?.data?.typeCode) {
            responseData = {
                ...responseData,
                personalizationType: typeList?.find((type) => type?.code === action.payload?.data?.typeCode)?.name
            };
        }
        if (productMap && !action.payload?.data?.productListMap) {
            const productListMap = Object.keys(productMap)?.map((key) => {
                return {
                    loading: false,
                    productName: key,
                    productId: productMap[key]
                };
            })?.sort((a, b) => a?.productName?.localeCompare(b?.productName));

            responseData = {
                ...responseData,
                productListMap
            };
        }
        if (paymentStatusMap && !action.payload?.data?.paymentStatusListMap) {
            const paymentStatusListMap = Object.keys(paymentStatusMap)?.map((key) => {
                return {paymentStatusName: paymentStatusMap[key], paymentStatusId: parseInt(key)};
            });
            responseData = {
                ...responseData,
                paymentStatusListMap
            };
        }
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: {
                    ...state.currentPersonalizationData?.data,
                    ...responseData
                }
            }
        };
    }),
    on(CdpPersonalizationsActions.LoadPersonalizationDataBasicsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: state.currentPersonalizationData?.data,
                errors: true
            }
        };
    }),
    on(CdpPersonalizationsActions.GenerateRecommendationHtml, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            recommendationContentData: {
                loading: true,
                data: state.recommendationContentData?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GenerateRecommendationHtmlSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            recommendationContentData: {
                loading: false,
                data: {
                    ...state.recommendationContentData?.data,
                    ...action.payload?.data
                }
            }
        };
    }),
    on(CdpPersonalizationsActions.GenerateRecommendationHtmlError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            recommendationContentData: {
                loading: false,
                data: state.recommendationContentData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetEuropeanCountries, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            europeanCountries: {
                loading: true,
                data: state.europeanCountries?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetEuropeanCountriesSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            europeanCountries: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetEuropeanCountriesError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            europeanCountries: {
                loading: false,
                data: state.europeanCountries?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetProductData, (state: CustomerDataPlatformState, action) => {
        const productListMap = Object.assign({}, state.audienceProductTarget?.data);

        if (productListMap[action.payload?.productId]) {
            // productListMap[action.payload?.productId] = { ...productListMap[action.payload.productId], loading: true };
            return state;
        } else {
            productListMap[action.payload?.productId] = { loading: true };
        }
        return {
            ...state,
            audienceProductTarget: {
                loading: false,
                data: productListMap
            }
        };
    }),

    on(CdpPersonalizationsActions.GetProductDataSuccess, (state: CustomerDataPlatformState, action) => {
        const productListMap = Object.assign({}, state.audienceProductTarget?.data);
        if (productListMap[action.payload?.data?.productId]?.classDefinitionList) return state;

        productListMap[action.payload?.data?.productId] = {...action.payload?.data, loading: false };

        return {
            ...state,
            audienceProductTarget: {
                loading: false,
                data: productListMap
            }
        };
    }),

    on(CdpPersonalizationsActions.GetProductDataError, (state: CustomerDataPlatformState, action) => {
        const productListMap = Object.assign({}, state.audienceProductTarget?.data);
        productListMap[action.payload?.productId] = { ...productListMap[action.payload?.productId], loading: false };

        return {
            ...state,
            audienceProductTarget: {
                loading: false,
                data: state.audienceProductTarget?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.LoadPersonalizationAudienceData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: true,
                data: state.currentPersonalizationData?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.LoadPersonalizationAudienceDataSuccess, (state: CustomerDataPlatformState, action) => {
        const productMap = action.payload?.data?.productMap;
        const paymentStatusMap = action.payload?.data?.paymentStatusMap;
        let data = action.payload?.data;
        if (productMap && !action.payload?.data?.productListMap) {
            const productListMap = Object.keys(productMap)?.map((key) => {
                return {
                    loading: false,
                    productName: key,
                    productId: productMap[key]
                };
            })?.sort((a, b) => a?.productName?.localeCompare(b?.productName));

            data = {
                ...data,
                productListMap
            };
        }
        if (paymentStatusMap && !action.payload?.data?.paymentStatusListMap) {
            const paymentStatusListMap = Object.keys(paymentStatusMap)?.map((key) => {
                return {paymentStatusName: paymentStatusMap[key], paymentStatusId: parseInt(key)};
            });
            data = {
                ...data,
                paymentStatusListMap
            };
        }
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: {
                    ...state.currentPersonalizationData?.data,
                    ...data
                }
            }
        };
    }),
    on(CdpPersonalizationsActions.LoadPersonalizationAudienceDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetOlyticsParameters, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsParameters: {
                loading: true,
                data: null
            }
        };
    }),

    on(CdpPersonalizationsActions.GetOlyticsParametersSuccess, (state: CustomerDataPlatformState, action) => {
        let data = state.olyticsParameters?.data;
        if (!data) {
            data = [action.payload?.data];
        } else if (data?.length > 0) {
            const index = data?.findIndex((item) => item?.attributeTypeId === action.payload.data?.attributeTypeId);
            if (index > -1) {
                data = [...data?.slice(0, index), action.payload?.data, ...data.slice(index + 1)];
            } else {
                data = [...data, action.payload?.data];
            }
        }
        return {
            ...state,
            olyticsParameters: {
                loading: false,
                data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetOlyticsParametersError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            olyticsParameters: {
                loading: false,
                data: state.olyticsParameters?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.RemoveOlyticParameter, (state: CustomerDataPlatformState, action) => {
        let data = state.olyticsParameters?.data;
        if (data?.length > 0) {
            const olyticParameterId = action.payload.olyticParameterId;
            const index = data.findIndex((item) => item?.attributeTypeId === olyticParameterId);
            if (index > -1) {
                data = [...data.slice(0, index), ...data.slice(index + 1)];
            }
        }
        return {
            ...state,
            olyticsParameters: {
                loading: false,
                data
            }
        };
    }),

    on(CdpPersonalizationsActions.SetInitiallyOlyticsParameters, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            olyticsParameters: {
                loading: false,
                data: action.payload.initiallyOlyticsParameters
            }
        };
    }),

    on(CdpPersonalizationsActions.LoadPersonalizationRestrictionsData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: true,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.LoadPersonalizationRestrictionsDataSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: {
                    ...state.currentPersonalizationData?.data,
                    ...action.payload
                }
            }
        };
    }),

    on(CdpPersonalizationsActions.LoadPersonalizationRestrictionsDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.SavePersonalizationState, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: {
                    ...state.currentPersonalizationData?.data,
                    ...action.payload
                }
            }
        };
    }),

    on(CdpPersonalizationsActions.GetOlyticsBehaviors, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            recommendationContentData: {
                loading: true,
                data: state.recommendationContentData?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetOlyticsBehaviorsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            recommendationContentData: {
                loading: false,
                data: {
                    ...state.recommendationContentData?.data,
                    behaviorList: action.payload?.data,
                }
            }
        };
    }),
    on(CdpPersonalizationsActions.GetOlyticsBehaviorsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            recommendationContentData: {
                loading: false,
                data: state.recommendationContentData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetFormBuilderList, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formBuilderList: {
                loading: true,
                data: state.formBuilderList?.data,
                hasErrors: Boolean(action?.payload?.ignoreError)
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormBuilderListSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formBuilderList: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormBuilderListError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formBuilderList: {
                loading: false,
                data: state.formBuilderList?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetFormSelectionKeywords, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formBuilderList: {
                loading: true,
                data: state.formBuilderList?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormSelectionKeywordsSuccess, (state: CustomerDataPlatformState, action) => {
        const keywords = action.payload?.data;

        const keywordsMap = {};
        if (keywords && keywords?.length > 0) {
            keywords.forEach((keyword) => {
                keywordsMap[keyword?.keywordId] = keyword;
            });
        }

        return {
            ...state,
            formBuilderList: {
                loading: false,
                data: {
                    ...state.formBuilderList?.data,
                    keywordsMap: {...keywordsMap}
                }
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormSelectionKeywordsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formBuilderList: {
                loading: false,
                data: state.formBuilderList?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetFormSearchFilters, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formSelectionFilters: {
                loading: true,
                data: state.formSelectionFilters?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormSearchFiltersSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            formSelectionFilters: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetFormSearchFiltersError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            formSelectionFilters: {
                loading: false,
                data: state.formSelectionFilters?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetCredsparkFormList, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            credsparkFormList: {
                loading: true,
                data: state.credsparkFormList?.data,
                hasErrors: Boolean(action?.payload?.ignoreError)
            }
        };
    }),
    on(CdpPersonalizationsActions.GetCredsparkFormListSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            credsparkFormList: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetCredsparkFormListError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            credsparkFormList: {
                loading: false,
                data: state.credsparkFormList?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetCredsparkFormSearchFilters, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            credsparkFormSelectionFilters: {
                loading: true,
                data: state.credsparkFormSelectionFilters?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetCredsparkFormSearchFiltersSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            credsparkFormSelectionFilters: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetCredsparkFormSearchFiltersError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            credsparkFormSelectionFilters: {
                loading: false,
                data: state.credsparkFormSelectionFilters?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationGeneratedHtml, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: true,
                data: state.personalizationGeneratedHtml?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationGeneratedHtmlSuccess, (state: CustomerDataPlatformState, action) => {
        const data = action.payload?.data;
        const generatedHtmlObject: GeneratedHtmlResponse = {};
        if (data?.length > 0) {
            data.forEach((html) => {
                generatedHtmlObject[html?.description] = html?.value;
            });
        }
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: false,
                data: generatedHtmlObject
            }
        };
    }),
    on(CdpPersonalizationsActions.GetPersonalizationGeneratedHtmlError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: false,
                data: state.personalizationGeneratedHtml?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetBeeAuthToken, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            beeAuthToken: {
                loading: true,
                data: null
            }
        };
    }),
    on(CdpPersonalizationsActions.GetBeeAuthTokenSuccess, (state: CustomerDataPlatformState, action) => {
        const beeData = JSON.parse(JSON.stringify(action.payload));
        return {
            ...state,
            beeAuthToken: {
                loading: false,
                data: beeData
            }
        };
    }),
    on(CdpPersonalizationsActions.GetBeeAuthTokenError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            beeAuthToken: {
                loading: false
            },
        };
    }),

    on(CdpPersonalizationsActions.GetMergeVariableDefaults, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            usedMergeVariables: {
                loading: true,
                data: null
            }
        };
    }),
    on(CdpPersonalizationsActions.GetMergeVariableDefaultsSuccess, (state: CustomerDataPlatformState, action) => {
        let mergeVariableDefaults = [];
        if (action.payload?.data?.mergeVariablesDefaults?.length > 0) {
            mergeVariableDefaults = action.payload?.data?.mergeVariablesDefaults;
            if (action.payload?.data?.usedMergeVariables?.length > action.payload?.data?.mergeVariablesDefaults?.length) {
                action.payload?.data?.usedMergeVariables.forEach(variable => {
                    if (mergeVariableDefaults.findIndex(item => item?.description === variable) === -1) {
                        mergeVariableDefaults = [...mergeVariableDefaults, {description: variable, value: ''}];
                    }
                });
            }
        } else if (action.payload?.data?.usedMergeVariables?.length > 0) {
            action.payload?.data?.usedMergeVariables?.forEach((item) => {
                mergeVariableDefaults = [...mergeVariableDefaults, {description: item, value: ''}];
            });
        }

        return {
            ...state,
            usedMergeVariables: {
                loading: false,
                data: mergeVariableDefaults
            }
        };
    }),
    on(CdpPersonalizationsActions.GetMergeVariableDefaultsError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            usedMergeVariables: {
                loading: false
            },
        };
    }),

    on(CdpPersonalizationsActions.GetTrackingLinks, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            trackingLinks: {
                loading: true,
                data: state?.trackingLinks?.data
            }
        };
    }),
    on(CdpPersonalizationsActions.GetTrackingLinksSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            trackingLinks: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(CdpPersonalizationsActions.GetTrackingLinksError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            trackingLinks: {
                loading: false
            },
        };
    }),

    on(CdpPersonalizationsActions.SavePersonalizationGeneratedHtml, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: true
            }
        };
    }),
    on(CdpPersonalizationsActions.SavePersonalizationGeneratedHtmlSuccess, (state: CustomerDataPlatformState, action) => {
        const data = action.payload?.data;
        const generatedHtmlObject = {};
        if (data?.length > 0) {
            data.forEach((html) => {
                generatedHtmlObject[html?.description] = html?.value;
            });
        }
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: false,
                data: generatedHtmlObject
            }
        };
    }),
    on(CdpPersonalizationsActions.SavePersonalizationGeneratedHtmlError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationGeneratedHtml: {
                loading: false,
                errors: true
            }
        };
    }),

    on(CdpPersonalizationsActions.SavePersonalizationData, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: true,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.SavePersonalizationDataSuccess, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.SavePersonalizationDataError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            currentPersonalizationData: {
                loading: false,
                data: state.currentPersonalizationData?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationHistory, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationModificationHistory: {
                loading: true,
                data: state.personalizationModificationHistory?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationHistorySuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            personalizationModificationHistory: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationHistoryError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationModificationHistory: {
                loading: false,
                data: state.personalizationModificationHistory?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationReport, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationReport: {
                loading: true,
                data: state.personalizationReport?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationReportSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            personalizationReport: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationReportError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationReport: {
                loading: false,
                data: state.personalizationReport?.data
            }
        };
    }),

    on(CdpPersonalizationsActions.ShowUnsavedChangesModal, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            showUnsavedModal: action.payload?.showModal || false
        };
    }),

    on(CdpPersonalizationsActions.SetPersonalizationPriority, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: true,
                data: state.personalizationsData?.data
            },
        };
    }),

    on(CdpPersonalizationsActions.SetPersonalizationPrioritySuccess, (state: CustomerDataPlatformState, action) => {
        if (!state?.currentPersonalizationData?.data) {
            return {
                ...state,
                currentPersonalizationData: {
                    loading: false,
                    data: state.currentPersonalizationData?.data
                },
            };
        }
        return {
            ...state,
            currentPersonalizationData: {
                ...state.currentPersonalizationData,
                data: {
                    ...state.currentPersonalizationData?.data,
                    priority: action.priorityValue
                }
            }
        };
    }),

    on(CdpPersonalizationsActions.SetPersonalizationPriorityError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: false,
                data: state.personalizationsData?.data
            },
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationsOffset, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: true,
                data: state.personalizationsData?.data
            },
        };
    }),

    on(CdpPersonalizationsActions.GetPersonalizationsOffsetError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: false,
                data: state.personalizationsData?.data
            },
        };
    }),

    on(CdpPersonalizationsActions.ChangePersonalizationPriority, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: true,
                data: state.personalizationsData?.data
            },
        };
    }),

    on(CdpPersonalizationsActions.ChangePersonalizationPrioritySuccess, (state: CustomerDataPlatformState, action) => {
        if (!state?.currentPersonalizationData?.data || !action?.priority || !action?.changeUrl) {
            return {
                ...state,
                currentPersonalizationData: {
                    loading: false,
                    data: state.currentPersonalizationData?.data
                },
            };
        }
        return {
            ...state,
            currentPersonalizationData: {
                ...state.currentPersonalizationData,
                data: {
                    ...state.currentPersonalizationData?.data,
                    priority: action?.priority || state.currentPersonalizationData?.data?.priority || 0
                }
            }
        };
    }),

    on(CdpPersonalizationsActions.ChangePersonalizationPriorityError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            personalizationsData: {
                loading: false,
                data: state.personalizationsData?.data
            },
        };
    }),
];
