import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChangeMainPath, getCurrentUser, ModalService, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, map, mergeMap, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Deployment, RegularSearchResp, SearchRecurringDeploymentsDropdowns, SearchRegularDeploymentsDropdowns } from '../interfaces';
import { GetRecurringSearchDropdowns, GetRecurringSearchDropdownsError, GetRecurringSearchDropdownsSuccess, GetRegularSearchDropdowns, GetRegularSearchDropdownsError, GetRegularSearchDropdownsSuccess, SearchRecurringDeployments, SearchRecurringDeploymentsError, SearchRecurringDeploymentsSuccess, SearchRegularDeployments, SearchRegularDeploymentsError, SearchRegularDeploymentsSuccess, ViewReport, ViewReportError, ViewReportSuccess, ViewSummary, ViewSummaryError, ViewSummarySuccess } from './search.actions';

@Injectable({ providedIn: 'root' })
export class EmailBuilderSearchEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) {}
    searchRegularDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchRegularDeployments),
            switchMap((action) => {
                const url = new URL(environment.apiEndPoint + `/email-builder/deployments/regular?environmentId=${this.currentDatabase.id}`);
                if (action?.payload?.trackIdExactMatch) {
                    url.searchParams.append('trackIdExactMatch', 'true');
                }
                return this.http.post(
                    url?.toString(),
                    action.payload,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: RegularSearchResp) => {
                        return SearchRegularDeploymentsSuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to get regular deployments.');
                        return of(SearchRegularDeploymentsError(error));
                    })
                );
            })
        )
    );
    searchRecurringDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchRecurringDeployments),
            switchMap((action) => {
                return this.http.post(
                    environment.apiEndPoint + `/email-builder/deployments/recurring?environmentId=${this.currentDatabase.id}&dataViewId=${this.currentUser?.currentProfile?.dataViewId}`,
                    action.payload,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: Deployment[]) => {
                        const payload: ResponseWrapper<Array<Deployment>> = { loading: false, data: res };
                        return SearchRecurringDeploymentsSuccess({
                            payload,
                        });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to get recurring deployments.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SearchRecurringDeploymentsError(error));
                    })
                );
            })
        )
    );
    viewSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewSummary),
            mergeMap((action) => {
                return this.http.get(
                    this.baseUrl + `/view-summary?id=${action.payload}&environmentId=${this.currentDatabase.id}`,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: { reportUrl?: string }) => {
                        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                            width=600,height=300,left=100,top=100`;
                        const url = res.reportUrl;
                        window.open(url, '', params);
                        return ViewSummarySuccess({ payload: res });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to generate report.');
                        return of(ViewSummaryError(error));
                    })
                );
            })
        )
    );
    viewReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewReport),
            mergeMap((action) => {
                return this.http
                    .get(
                        this.baseUrl + `/view-report?id=${action.payload}&environmenId=${this.currentDatabase.id}`,
                        this.defaultHttpOptions
                    )
                    .pipe(
                        take(1),
                        map((res: { reportUrl?: string }) => {
                            const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                                width=600,height=300,left=100,top=100`;
                            const url = res.reportUrl;
                            window.open(url, '', params);
                            return ViewReportSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to generate report.');
                            return of(ViewReportError(error));
                        })
                    );
            })
        )
    );
    getRegularSearchDropdowns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRegularSearchDropdowns),
            switchMap((action) => {
                const url = new URL(this.baseUrl +'/regular/search-dropdowns');
                if(action.payload.database !=null) {
                    url.searchParams.set('environmentId', action.payload.database.toString());
                }
                if(action.payload.organization !=null) {
                    url.searchParams.set('orgId', action.payload.organization.toString());
                }
                return this.http.post(url.toString(),this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: SearchRegularDeploymentsDropdowns) => {
                        // if (res && res.messages && res.errors && res.errors.length) {
                        //     this.showError(null, `${res.messages}\n(${res.errors[0]})`);
                        // }
                        return GetRegularSearchDropdownsSuccess({
                            payload: res,
                        });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to get regular search dropdowns.');
                        return of(GetRegularSearchDropdownsError(error));
                    })
                );
            })
        )
    );
    getRecurringSearchDropdowns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRecurringSearchDropdowns),
            switchMap((action) => {

                const url = new URL(this.baseUrl +'/recurring/search-dropdowns');
                if(action.payload.database !=null) {
                    url.searchParams.set('environmentId', action.payload.database.toString());
                }
                if(action.payload.organization !=null) {
                    url.searchParams.set('orgId', action.payload.organization.toString());
                }
                return this.http.post(url.toString(),this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SearchRecurringDeploymentsDropdowns) => {
                        // if (res && res.messages && res.errors && res.errors.length) {
                        //     this.showError(null, `${res.messages}\n(${res.errors[0]})`);
                        // }
                            return GetRecurringSearchDropdownsSuccess({
                                payload: res,
                            });
                        }),
                        catchError((error) => {
                            this.showError(null, 'An error has occurred while trying to get recurring search dropdowns.');
                            return of(GetRecurringSearchDropdownsError(error));
                        })
                    );
            })
        )
    );

    changePath(path, queryParams) {
        if (!path) {
            return;
        }
        this.store.dispatch(
            ChangeMainPath({
                payload: {
                    path: [ path ],
                    queryParams,
                    extras: null,
                },
            })
        );
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/deployment';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
