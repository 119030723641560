import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChangeMainPath, getCurrentUser, ModalService, ShellStore, ShowToast, CasHttpClient } from 'common';
import { catchError, map, mergeMap, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CampaignsHostedContentActions from './hosted-content.action';
import { File, HostedFiles } from './hosted-content.interface';

@Injectable({ providedIn: 'root' })
export class HostedContentEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private casHttp: CasHttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService
    ) { }

    getHostedContentFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignsHostedContentActions.GetHostedContentFiles),
            mergeMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + `/campaign/hosted-content?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}&numResults=${action?.payload?.numResults}&offset=${action?.payload?.offset}&sortBy=${action?.payload?.sortBy}&order=${action?.payload?.order}`);
                if (action?.payload?.orgLevel) {
                    url?.searchParams.append('orgLevel', `${action?.payload?.orgLevel}`);
                } else {
                    url?.searchParams.append('databaseId', action?.payload?.databaseId);
                }
                if (action?.payload?.filename && action?.payload?.filename?.length > 0) {
                    url?.searchParams.append('filename', action?.payload?.filename);
                }
                if (action?.payload?.searchText) {
                    url?.searchParams.append('searchText', action?.payload?.searchText);
                }
                return this.casHttp.get(environment.campaignApiEndPoint, url?.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: HostedFiles) => {
                            return CampaignsHostedContentActions.GetHostedContentFilesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get hosted content content files.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CampaignsHostedContentActions.GetHostedContentFilesError(error));
                        })
                    );
            })
        )
    );

    uploadHostedContentFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignsHostedContentActions.UploadHostedContentFiles),
            mergeMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + `/campaign/hosted-content?environmentId=${this.currentDatabase.id}&orgId=${action?.payload?.orgId}`);
                if (action?.payload?.orgLevel) {
                    url?.searchParams.append('orgLevel', `${action?.payload?.orgLevel}`);
                } else {
                    url?.searchParams.append('databaseId', action?.payload?.databaseId);
                }
                if (action?.payload?.overwriteDirectoryId) {
                    url?.searchParams.append('mode', 'modeOverwriteExistingFile');
                    url?.searchParams.append('filename', `${action?.payload?.filename}`);
                    url?.searchParams.append('overwriteDirectoryId', `${action?.payload?.overwriteDirectoryId}`);
                }
                return this.http.post(url?.toString(), action?.payload?.files, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { fileList: Array<File> }) => {
                            if (!action?.payload?.overwriteDirectoryId) {
                                let counter = 0;
                                res?.fileList?.forEach(file => {
                                    if (!file?.uploadSuccess && file?.canOverwrite) {
                                        counter++;
                                    }
                                });
                                if (counter > 0) {
                                    this.showWarning('Existing Files', 'There are ' + counter + ' existing files. Please use the Upload Results section to overwrite any existing files.');
                                }
                            }
                            return CampaignsHostedContentActions.UploadHostedContentFilesSuccess({ payload: res?.fileList });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload files.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CampaignsHostedContentActions.UploadHostedContentFilesError(error));
                        })
                    );
            })
        )
    );

    changePath(path, queryParams) {
        if (!path) {
            return;
        }
        this.store.dispatch(
            ChangeMainPath({
                payload: {
                    path: [path],
                    queryParams,
                    extras: null,
                },
            })
        );
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    message: message || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    icon: icon || 'fa-triangle-exclamation-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/deployment';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
