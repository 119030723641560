import { ResponseWrapper } from 'common';

export const scheduleOutputsInitialState: ScheduleOutputsState = {
    scheduleOutputs: {
        loading: false,
        data: {
            leadGenJobsCount: 0,
            leadGenList: []
        }
    },
    selectedScheduleOutput: {
        loading: false,
        data: null
    },
    seachQueriesResults: {
        loading: false,
        data: null
    },
    outputCriteriaList: {
        loading: false,
        data: null
    },
};

export interface ScheduleOutputsState {
    scheduleOutputs: ResponseWrapper<ScheduleOutputsRes>;
    selectedScheduleOutput: ResponseWrapper<SelectedScheduleOutput>;
    seachQueriesResults?: ResponseWrapper<QueriesSearchResults>;
    outputCriteriaList?: ResponseWrapper<OutputCritRes>;
}

export interface ScheduleOutputsRes {
    leadGenJobsCount: number,
    leadGenList: Array<ScheduleOutput>,
}

export interface ScheduleOutput {
    eventId: number,
    eventName: string,
    status: string,
    createdBy: string,
    changedDate: string,
}

export interface ScheduleOutputsCommonSearchRequest {
    numResults?: number,
    offset?: number,
    searchText?: string,
    order?: string,
    sortBy?: string,
    active?: boolean,
}

export interface CommonSearchReq {
    nameSearchType: string;
    name: string;
    keyword: string;
    createdBy: string;
    lastUpdated: string;
    profile: string;
    lockedQueriesOnly: boolean;
    olyticsQueriesOnly: boolean;
    dataViewId: string;
    numResults: number;
    profileId: string;
    offset: number;
    sortBy: string;
    order: string;
    status: string;
    resultsType: string;
    mode?: string;
}

export interface OutputCritRes {
    outputCriteriaList: Array<OutputCriteriaItem>
}
export interface OutputCriteriaItem {
    outputCriteriaId: number,
    outputCriteriaName: string;
}

export interface OutputCriteriaSearchResults {
    listSize?: number,
    resultList?: Array<OutputCriteriaSearchItem>,
}

export interface OutputCriteriaSearchItem {
    allowedToOpenQuery?: boolean,
    changedBy?: string,
    changedDate?: string,
    createdBy?: string,
    createdDate?: string,
    customerMembershipCount?: number,
    dataViewId?: number,
    isOlytics?: boolean,
    name?: string,
    profileName?: string,
    selectionCriteriaId?: number,
    statusCode?: number,
    type?: number,
}

export interface SelectedFieldData {
    columnName?: string,
    demographicId?: number,
    displayLabel?: string,
    fieldIdentifier?: string,
    header?: string,
    inOutValues?: Array<string>,
    isConstant?: boolean,
    isDate?: boolean,
    isFullName?: boolean,
    isInOut?: boolean,
    isPaid?: boolean,
    isPaidOutputColumn?: boolean,
    isStandardOmailField?: boolean,
    isTypeUnit?: boolean,
    outputMetadataId?: number,
    id?: number,
    dateFormat?: string,
    sortDirection?: string,
    limit?: number,
    sortCharacterLength?: number,
}

interface EventFrequency {
    frequency?: string;
    frequencyDailyAll?: boolean;
    frequencyDailySunday?: boolean;
    frequencyDailyMonday?: boolean;
    frequencyDailyTuesday?: boolean;
    frequencyDailyWednesday?: boolean;
    frequencyDailyThursday?: boolean;
    frequencyDailyFriday?: boolean;
    frequencyDailySaturday?: boolean;
    freqMonthlyType?: string;
    weeksInMonth?: string;
    daysInWeek?: string;
    freqMonthlyMonthNumbers?: string;
    freqMonthlyDayNumbers?: string;
    lastdayOfMonth?: boolean;
    freqQuarterlyType?: string;
    freqQuarterlyDayNumber?: string;
    quarters?: string;
    freqYearlyType?: string;
    freqYearlyDayNumber?: string;
    hourOfDay?: string;
    minuteOfHour?: string;
    allDayMinuteOfHour?: string;
    timeZone?: string;
}

interface EmailDefault {
    approvalNotificationsStatusCode?: number;
    followupDisabled?: number;
    emptySourceAlertStatusCode?: number;
    mailboxDomain?: string;
    finalApprover?: string;
    internalHardBounceFilter?: number;
}

interface DeploymentType {
    active?: number;
    alternateId?: string;
    deploymentTypeId?: number;
    name?: string;
    formBuilderSendEmails?: string;
    emailDefaults?: EmailDefault[];
}

export interface SelectedScheduleOutput {
    eventId?: number;
    eventName?: string;
    status?: string;
    createdDate?: string;
    createdBy?: string;
    outputCriteriaName?: string;
    outputCriteriaId?: number;
    deploymentTypeId?: string;
    deploymentTypeName?: string;
    selectionCriteriaName?: string;
    selectionCriteriaId?: number;
    scheduledOutputId?: number;
    useEmail?: boolean;
    useFtp?: boolean;
    useS3?: boolean;
    fileName?: string;
    fileType?: string;
    appendDateToFile?: boolean;
    recipientEmail?: string[];
    ftpLocation?: string;
    username?: string;
    password?: string;
    outputEmptyFile?: number;
    startDate?: string;
    endDate?: string;
    eventDayCode?: string;
    eventFrequency?: EventFrequency;
    validOwnersList?: string[];
    deploymentTypeList?: DeploymentType[];
    accessKey?: string;
    secretKey?: string;
    chosenBucket?: string;
    region?: string;
}

interface OutputCriteria {
    changedBy?: string;
    changedDate?: string;
    createdBy?: string;
    outputCriteriaId?: number;
    outputCriteriaName?: string;
    status?: number;
    statusDescription?: string;
    type?: number;
    typeName?: string;
}
  
interface Result {
    allowedToOpenQuery?: boolean;
    count?: number;
    changedBy?: string;
    changedDate?: string;
    createdBy?: string;
    createdDate?: string;
    customerMembershipCount?: number;
    dataViewId?: number;
    description?: string;
    isOlytics?: boolean;
    issueName?: string;
    keyword?: string;
    onqAdmins?: string[];
    name?: string;
    owner?: string;
    productName?: string;
    profileName?: string;
    selectionCriteriaId?: number;
    source?: string;
    statusCode?: number;
    switchToProfileId?: number;
    type?: number;
    value?: string;
}
  
export interface QueriesSearchResults {
    listSize?: number;
    outputCriteriaList?: OutputCriteria[];
    resultList?: Result[];
}
  