import { on } from '@ngrx/store';
import { SelfServiceState } from '.././interfaces';
import * as DatabaseSettingsActions from './database-settings.actions';

export const databaseSettingsReducers = [
    on(DatabaseSettingsActions.LoadDatabaseSettingsData, (state: SelfServiceState) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(DatabaseSettingsActions.LoadDatabaseSettingsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(DatabaseSettingsActions.LoadDatabaseSettingsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),
    on(DatabaseSettingsActions.SaveDatabaseSettings, (state: SelfServiceState) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: true,
                    data: state.databaseSettingsState.databaseSettingsData.data
                }
            }
        };
    }),
    on(DatabaseSettingsActions.SaveDatabaseSettingsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    }),

    on(DatabaseSettingsActions.SaveDatabaseSettingsError, (state: SelfServiceState) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                databaseSettingsData: {
                    loading: false,
                    data: state.databaseSettingsState.databaseSettingsData.data
                }
            }
        };
    }),

    on(DatabaseSettingsActions.GetCountryListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            databaseSettingsState: {
                ...state.databaseSettingsState,
                countryListData: {
                    loading: false,
                    data: action.payload
                }
            }
        };
    })
];
