import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonLibModule, reducer, NavigationEffects, ApiEffects } from 'common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { emailBuilderReducer } from 'projects/email-builder/src/app/state/app.reducer';
import { manageUsersReducer } from 'projects/manage-users/src/state/app.reducer';
import { ManageUsersEffects } from 'projects/manage-users/src/state/api.effects';
import { ManageUsersNavigationEffects } from 'projects/manage-users/src/state/navigation.effects';
import { audienceBuilderReducer } from 'projects/audience-builder/src/state/app.reducer';
import { AudienceBuilderEffects } from 'projects/audience-builder/src/state/api.effects';
import { AudienceBuilderNavigationEffects } from 'projects/audience-builder/src/state/navigation.effects';
import { dataLoaderReducer } from 'projects/data-loader/src/app/state/app.reducer';
import { DataLoaderEffects } from 'projects/data-loader/src/app/state/api.effects';
import { DataLoaderNavigationEffects } from 'projects/data-loader/src/app/state/navigation.effects';
import { formBuilderReducer } from 'projects/form-builder/src/app/state/app.reducer';
import { FormBuilderEffects } from 'projects/form-builder/src/app/state/api.effects';
import { FormBuilderNavigationEffects } from 'projects/form-builder/src/app/state/navigation.effects';
import { EmailBuilderCommonEffects } from 'projects/email-builder/src/app/state/email-builder-state.common';
import { AppInterceptor } from 'src/providers/app-interceptor';
import { UsersnapService } from 'src/providers/user-snap.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { CasHttpClient, casHttpClientCreator } from 'projects/common/src/lib/utils/cas-http';
import { OdysseyEffects } from 'projects/odyssey/src/app/state/api.effects';
import { odysseyReducer } from 'projects/odyssey/src/app/state/app.reducer';
import { BrowserStorageService } from 'projects/common/src/public-api';
import { IntegrationsEffects } from '@integrations/state/api.effects';
import { integrationsReducer } from '@integrations/state/app.reducer';
import { customerDataPlatformReducer } from 'projects/customer-data-platform/src/state/app.reducer';
import { CustomerDataPlatformCommonEffects } from 'projects/customer-data-platform/src/state/customer-data-platform-state.common';
import { selfServiceReducer } from '@self-service/state/app.reducer';
import { SelfServiceCommonEffects } from '@self-service/state/self-service-state.common';
import { IntegrationsCommonEffects } from '@integrations/state/integrations-state.common';
import { CampaignsEffects } from 'projects/campaigns/src/state/api.effects';
import { campaignsReducer } from 'projects/campaigns/src/state/app.reducer';
import { CampaignsCommonEffects } from 'projects/campaigns/src/state/campaigns-state.common';
import { smsBuilderReducer } from 'projects/sms-builder/src/state/app.reducer';
import { SmsBuilderCommonEffects } from 'projects/sms-builder/src/state/sms-builder-state.common';

import { AudienceInsightsReducer } from 'projects/audience-insights/src/state/dashboard/app.reducer';
import { AudienceInsightsEffects } from 'projects/audience-insights/src/state/dashboard/api.effects';

@NgModule({ declarations: [
    AppComponent
],
exports: [CommonLibModule],
schemas: [CUSTOM_ELEMENTS_SCHEMA],
bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonLibModule,
    StoreModule.forRoot({
        appState: reducer,
        emailBuilderState: emailBuilderReducer,
        manageUsersState: manageUsersReducer,
        campaignsState: campaignsReducer,
        audienceBuilderState: audienceBuilderReducer,
        dataLoaderState: dataLoaderReducer,
        formBuilderState: formBuilderReducer,
        odysseyState: odysseyReducer,
        integrationsState: integrationsReducer,
        customerDataPlatformState: customerDataPlatformReducer,
        smsBuilderState: smsBuilderReducer,
        selfServiceState: selfServiceReducer,
        audienceInsightsState: AudienceInsightsReducer
    }),
    EffectsModule.forRoot([
        ApiEffects,
        NavigationEffects,
        ...EmailBuilderCommonEffects,
        ManageUsersEffects,
        ManageUsersNavigationEffects,
        CampaignsEffects,
        ...CampaignsCommonEffects,
        AudienceBuilderEffects,
        AudienceBuilderNavigationEffects,
        DataLoaderEffects,
        DataLoaderNavigationEffects,
        FormBuilderEffects,
        FormBuilderNavigationEffects,
        HighchartsChartModule,
        OdysseyEffects,
        IntegrationsEffects,
        ...CustomerDataPlatformCommonEffects,
        ...SelfServiceCommonEffects,
        ...IntegrationsCommonEffects,
        ...SmsBuilderCommonEffects,
        AudienceInsightsEffects
    ]),
    StoreDevtoolsModule.instrument({
        maxAge: 25,
        connectInZone: true
    })], providers: [
    UsersnapService, BrowserStorageService,
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    {
        provide: CasHttpClient,
        useFactory: casHttpClientCreator,
        deps: [HttpClient]
    },
    provideHttpClient(withInterceptorsFromDi()),
] })
export class AppModule { }
