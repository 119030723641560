import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as ProductsActions from './products.actions';

export const productsReducer = [
    on(ProductsActions.LoadProductsData, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productsData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.LoadProductsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.LoadProductsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.DownloadProductsData, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                downloadProductsLoader: true
            }
        };
    }),
    on(ProductsActions.DownloadProductsDataSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                downloadProductsLoader: false
            }
        };
    }),
    on(ProductsActions.DownloadProductsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                downloadProductsLoader: false
            }
        };
    }),
    on(ProductsActions.GetProductDetails, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductDetailsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: action?.payload,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductDetailsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetAuditBureaus, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditBureaus: []
            }
        };
    }),
    on(ProductsActions.GetAuditBureausSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditBureaus: action?.payload
            }
        };
    }),
    on(ProductsActions.GetAuditBureausError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditBureaus: []
            }
        };
    }),

    on(ProductsActions.GetProductDefinitions, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state.productsState.selectedProduct.data,
                    loading: false
                }
            }
        };
    }),

    on(ProductsActions.GetProductDefinitionsSuccess, (state: SelfServiceState, action) => {
        const taxableStates = [];
        if (action?.payload?.data?.taxStates) {
            action?.payload?.data?.taxStates?.forEach((state) => {
                if (state?.selected) {
                    taxableStates.push(state);
                }
            });
        }
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, ...action?.payload?.data, taxStates: taxableStates || []},
                    loading: false
                }
            }
        };
    }),

    on(ProductsActions.GetProductDefinitionsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state.productsState.selectedProduct.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetPaymentOptions, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paymentOptions: []
            }
        };
    }),
    on(ProductsActions.GetPaymentOptionsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paymentOptions: action?.payload
            }
        };
    }),
    on(ProductsActions.GetPaymentOptionsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paymentOptions: []
            }
        };
    }),
    on(ProductsActions.GetPaidClasses, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paidClasses: []
            }
        };
    }),
    on(ProductsActions.GetPaidClassesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paidClasses: action?.payload
            }
        };
    }),
    on(ProductsActions.GetPaidClassesError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                paidClasses: []
            }
        };
    }),
    on(ProductsActions.GetPostalBarcodes, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                postalBarcodes: []
            }
        };
    }),
    on(ProductsActions.GetPostalBarcodesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                postalBarcodes: action?.payload
            }
        };
    }),
    on(ProductsActions.GetPostalBarcodesError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                postalBarcodes: []
            }
        };
    }),
    on(ProductsActions.GetProductCompanies, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productCompanies: null
            }
        };
    }),
    on(ProductsActions.GetProductCompaniesSuccess, (state: SelfServiceState, action) => {
        const companies = action?.payload?.reduce((acc, curr) => {
            acc[curr?.name] = curr?.value;
            return acc;
        }, {});

        return {
            ...state,
            productsState: {
                ...state.productsState,
                productCompanies: companies || null
            }
        };
    }),
    on(ProductsActions.GetProductCompaniesError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productCompanies: []
            }
        };
    }),
    on(ProductsActions.GetProductDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, ...action?.payload?.data},
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductClasses, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productClasses: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductClassesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productClasses: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductClassesError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productClasses: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductMergeData, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductMergeDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, ...action?.payload?.data},
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductMergeDataError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductRequestorRules, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductRequestorRulesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, requestorRules: action?.payload?.data},
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductRequestorRulesError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductOtherDetails, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductOtherDetailsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, ...action?.payload?.data},
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductOtherDetailsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductCreditGracing, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductCreditGracingSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: {...state.productsState.selectedProduct.data, ...action?.payload?.data},
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductCreditGracingError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                selectedProduct: {
                    data: state?.productsState?.selectedProduct?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAccounts, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productAccounts: null
            }
        };
    }),
    on(ProductsActions.GetProductAccountsSuccess, (state: SelfServiceState, action) => {
        const accounts = action?.payload?.reduce((acc, curr) => {
            acc[curr?.accountId] = curr;
            return acc;
        }, {});

        return {
            ...state,
            productsState: {
                ...state.productsState,
                productAccounts: accounts || null
            }
        };
    }),
    on(ProductsActions.GetProductAccountsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                productAccounts: null
            }
        };
    }),
    on(ProductsActions.GetProductAuditedDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditedDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductAuditedDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditedDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAuditedDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                auditedDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAddressedDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                addressedDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductAddressedDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                addressedDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAddressedDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                addressedDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductIssueCloseDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                issueCloseDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductIssueCloseDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                issueCloseDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductIssueCloseDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                issueCloseDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAssociatedDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                associatedDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductAssociatedDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                associatedDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductAssociatedDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                associatedDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductPassAlongDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                passAlongDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(ProductsActions.GetProductPassAlongDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                passAlongDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProductsActions.GetProductPassAlongDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            productsState: {
                ...state.productsState,
                passAlongDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    })
];
