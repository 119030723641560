import { Action, createReducer, on } from '@ngrx/store';
import { IntegrationsState } from '@integrations/state/interfaces';
import * as IntegrationsActions from '@integrations/state/actions';
import { IntegrationsAdrollEventBasedReducer, initialAdrollEventBasedState } from '@integrations/state/adroll-event-based/adroll-event-based.reducer';
import { IntegrationsAdrollReducer, initialAdrollState } from '@integrations/state/adroll/adroll.reducer';
import { IntegrationsMetaAdsManagerReducer, initialMetaAdsManagerState } from '@integrations/state/meta-ads-manager/meta-ads-manager.reducer';
import { IntegrationsMetaEventsManagerReducer, initialMetaEventsManagerState } from '@integrations/state/meta-events-manager/meta-events-manager.reducer';
import { IntegrationsDeltaFtpReducer, initialDeltaFtpState } from '@integrations/state/delta-ftp/delta-ftp.reducer';
import { IntegrationsGoogleAdManagerReducer, initialGoogleAdManagerState } from '@integrations/state/google-ad-manager/google-ad-manager.reducer';
import { IntegrationsBlueconicReducer, initialBlueconicState } from '@integrations/state/blueconic/blueconic.reducer';
import { initialShopifyState, IntegrationsShopifyReducer } from '@integrations/state/shopify/shopify.reducer';

const initialAppState: IntegrationsState = {
    adroll: initialAdrollState,
    adrollEventBased: initialAdrollEventBasedState,
    blueconic: initialBlueconicState,
    deltaFtp: initialDeltaFtpState,
    googleAdManager: initialGoogleAdManagerState,
    metaAdsManager: initialMetaAdsManagerState,
    metaEventsManager: initialMetaEventsManagerState,
    shopify: initialShopifyState,

    // COMMON 
    recentAudiences: {
        loading: false,
        data: {
            totalResultCount: 0,
            items: []
        }
    },
    seachQueriesResults: {
        loading: false,
    },
    adrollSelectedAccount: null,
    pageViewDuration: {
        loading: false,
        data: null
    },
};

function getInitalState(): IntegrationsState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

function getSavedState(): IntegrationsState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    ...IntegrationsAdrollReducer,
    ...IntegrationsAdrollEventBasedReducer,
    ...IntegrationsBlueconicReducer,
    ...IntegrationsDeltaFtpReducer,
    ...IntegrationsGoogleAdManagerReducer,
    ...IntegrationsMetaAdsManagerReducer,
    ...IntegrationsMetaEventsManagerReducer,
    ...IntegrationsShopifyReducer,

    //COMMON
    on(IntegrationsActions.SetAdrollSelectedAccount, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollSelectedAccount: action.payload
        };
    }),
    on(IntegrationsActions.SearchQueries, (state: IntegrationsState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: true,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(IntegrationsActions.SearchQueriesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(IntegrationsActions.SearchQueriesError, (state: IntegrationsState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(IntegrationsActions.ClearSearchQueriesResults, (state: IntegrationsState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false
            }
        };
    }),
    on(IntegrationsActions.GetRecentAudiences, (state: IntegrationsState) => {
        return {
            ...state,
            recentAudiences: {
                loading: true,
                data: state.recentAudiences?.data
            }
        };
    }),
    on(IntegrationsActions.GetRecentAudiencesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            recentAudiences: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(IntegrationsActions.GetRecentAudiencesError, (state: IntegrationsState) => {
        return {
            ...state,
            recentAudiences: {
                loading: false,
                data: state.recentAudiences?.data
            }
        };
    }),
    on(IntegrationsActions.GetPageMinimumViewDuration, (state: IntegrationsState) => {
        return {
            ...state,
            pageViewDuration: {
                loading: true,
                data: state.pageViewDuration?.data
            }
        };
    }),
    on(IntegrationsActions.GetPageMinimumViewDurationSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            pageViewDuration: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(IntegrationsActions.GetPageMinimumViewDurationError, (state: IntegrationsState) => {
        return {
            ...state,
            pageViewDuration: {
                loading: false,
                data: state.pageViewDuration?.data
            }
        };
    }),
);

// // tslint:disable-next-line: typedef
export function integrationsReducer(state: IntegrationsState | undefined, action: Action) {
    return appReducer(state, action);
}