import { EmailBuilderEffects } from './api.effects';
import { EmailBuilderDeploymentDefaultsEffects } from './deployment-defaults/deployment-defaults.effects';
import { EmailBuilderDeploymentEffects } from './deployment/deployment.effects';
import { EmailBuilderNavigationEffects } from './navigation.effects';
import { EmailBuilderOptOutEffects } from './tools/opt-out/opt-out.effects';
import { EmailBuilderSearchEffects } from './search/search.effects';
import { EmailBuilderFilterEffects } from './tools/filter/filter.effects';
import { EmailBuilderCalendarEffects } from './calendar/calendar.effects';
import { EmailBuilderSummaryStatsEffects } from './summary-stats/summary-stats.effect';
// import { EmailBuilderApprovalQueueEffects } from './approval-queue/approval-queue.effects';
import { EmailBuilderApprovalQueueEffects } from './approval-queue/approval-queue.effects';
import { EmailBuilderIgnoredIpsEffects } from './ignored-ips/ignored-ips.effects';

export const EmailBuilderCommonEffects = [
    EmailBuilderEffects,
    EmailBuilderDeploymentEffects,
    EmailBuilderDeploymentDefaultsEffects,
    EmailBuilderSearchEffects,
    EmailBuilderApprovalQueueEffects,
    EmailBuilderNavigationEffects,
    EmailBuilderOptOutEffects,
    EmailBuilderFilterEffects,
    EmailBuilderCalendarEffects,
    EmailBuilderSummaryStatsEffects,
    EmailBuilderIgnoredIpsEffects
];
