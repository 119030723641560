import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as DemographicsActions from './consolidated-demographics.actions';

export const consolidatedDemographicsReducers = [
    on(DemographicsActions.LoadConsolidatedDemographicsData, (state: SelfServiceState) => {
        return {
            ...state,
            consolidatedDemographicsState: {
                ...state.consolidatedDemographicsState,
                consolidatedDemographicsData: {
                    data: state.consolidatedDemographicsState?.consolidatedDemographicsData?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.LoadConsolidatedDemographicsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            consolidatedDemographicsState: {
                ...state.consolidatedDemographicsState,
                consolidatedDemographicsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.LoadConsolidatedDemographicsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            consolidatedDemographicsState: {
                ...state.consolidatedDemographicsState,
                consolidatedDemographicsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
];
