import { on } from '@ngrx/store';
import * as CalendarActions from './calendar.actions';
import { IEmailBuilderState } from '../interfaces';
import { DeploymentTypeEnum } from 'common';

export const calendarReducer = [
    on(CalendarActions.GetCalendarDayData, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarDayData: {
                loading: true,
                data: null
            }
        };
    }),
    on(CalendarActions.GetCalendarDayDataSuccess, (state: IEmailBuilderState, action) => {
        const deployments = [
            ...(action.payload.data.deployment || []).map(res => {
                return {...res, type: DeploymentTypeEnum.REGULAR};
            }),
            ...(action.payload.data.recurringDeployment || []).map(res => {
                return {...res, type: DeploymentTypeEnum.RECURRING};
            }),
        ];

        return {
            ...state,
            calendarDayData: {
                loading: false,
                data: {
                    ...action.payload.data,
                    deployments: deployments,
                }
            }
        };
    }),
    on(CalendarActions.GetCalendarDayDataError, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarDayData: {
                loading: false,
                data: state.calendarDayData.data
            }
        };
    }),
    on(CalendarActions.GetCalendarWeekData, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarWeekData: {
                loading: true,
                data: null
            }
        };
    }),
    on(CalendarActions.GetCalendarWeekDataSuccess, (state: IEmailBuilderState, action) => {
        if (action.payload && action.payload.data) {
            const data = JSON.parse(JSON.stringify(action.payload.data));
            const emailDeployment = {};
            const recurringDeployment = {};
            const holidayMap = {};
            if (data.holidayMap) {
                Object.keys(data.holidayMap).forEach(key => {
                    const formattedKey = key.split('T')[0];
                    holidayMap[formattedKey] = data.holidayMap[key];
                });
                data.holidayMap = holidayMap;
            }
            if (data.emailDeployment) {
                Object.keys(data.emailDeployment).forEach(key => {
                    const formattedKey = key.split('T')[0];
                    emailDeployment[formattedKey] = data.emailDeployment[key];
                });
                data.emailDeployment = emailDeployment;
            }
            if (data.recurringDeployment) {
                Object.keys(data.recurringDeployment).forEach(key => {
                    const formattedKey = key.split('T')[0];
                    recurringDeployment[formattedKey] = data.recurringDeployment[key];
                });
                data.recurringDeployment = recurringDeployment;
            }
            return {
                ...state,
                calendarWeekData: {
                    loading: false,
                    data: data
                }
            };
        }
    }),
    on(CalendarActions.GetCalendarWeekDataError, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarWeekData: {
                loading: false,
                data: state.calendarWeekData.data
            }
        };
    }),
    on(CalendarActions.SaveMessageBoard, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarMessageBoardLoading: true
        };
    }),
    on(CalendarActions.SaveMessageBoardSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarMessageBoardLoading: false
        };
    }),
    on(CalendarActions.SaveMessageBoardError, (state: IEmailBuilderState) => {
        return {
            ...state,
            calendarMessageBoardLoading: false
        };
    }),
];
