import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppInterceptor implements HttpInterceptor {

    private readonly urlExceptions = {
        inventoryCalendarNotAllowed : 'inventory-calendar/allowed'
    };

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!(request?.body instanceof FormData)) {
            request = request.clone({
                headers: request.headers.set('Content-Type', 'application/json')
            });
        }

        request = request.clone({ withCredentials: true });
        return this.handleRequest(next, request);
    }

    private handleRequest(next: HttpHandler, request: HttpRequest<unknown>) {
        return next.handle(request).pipe(
            tap((res) => {
                if (res instanceof HttpResponse) {
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse && error.status == 401 && !(request.urlWithParams.indexOf('login') > -1) && !request.url.includes(this.urlExceptions.inventoryCalendarNotAllowed)) {
                    const portalApiIndex = request.url.indexOf('/portal/api');
                    //if we hit 401 on an Omeda /portal/ API
                    //Redirect to the main Portal login page
                    let redirectURL = request.url.substring(0, portalApiIndex + '/portal'.length);

                    //include location to redirect back here again after login.
                    redirectURL += '/?redirectURL=' + window.location.href;

                    window.location.href = redirectURL; //navigate browser
                }
                return throwError(error);
            }));
    }
}

export const httpInterceptProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
];
