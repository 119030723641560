import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { AudienceInsightsState } from './interfaces';

const initialAppState: AudienceInsightsState = {
    appLoaded: false,
    selectedDashboard: null,
    selectedDashboardTokenInfo: {
        loading: false,
        data: null
    },
    shared: {
        loading: false,
        data: []
    },
    recent: {
        loading: false,
        data: []
    },
    favorite: {
        loading: false,
        data: []
    },
    audienceInsightsSendABInfo : {
        loading: true,
        data: null
    }
};

function getInitalState(): AudienceInsightsState {
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

const appReducer = createReducer(
    getInitalState(),
    
    on(AppActions.AudienceInsightsToken, (state) => {
        return {
            ...state,
            selectedDashboardTokenInfo: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.AudienceInsightsTokenSuccess, (state , action) => {
        return {
            ...state,
            selectedDashboardTokenInfo: {
                loading: false,
                data: action.payload
            },
            appLoaded: true
        };
    }),
    on(AppActions.AudienceInsightsTokenError ,(state) => {
        return {
            ...state,
            audienceInsightsInfo: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.AudienceInsightsSendAB, (state) => {
        return {
            ...state,
            audienceInsightsSendABInfo : {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.AudienceInsightsSendABSuccess, (state, action) => {
        return {
            ...state,
            audienceInsightsSendABInfo : {
                loading: false,
                data: action?.payload        
            }
        };
    }),
    on(AppActions.AudienceInsightsSendABError, (state ) => {       
        return {
            ...state,
            audienceInsightsSendABInfo : {
                loading: false,
                data: null
            }
        };
    }), 
    on(AppActions.GetSharedDashboardList, (state) => {
        return {
            ...state,
            shared: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSharedDashboardListSuccess, (state , action) => {       
        return {
            ...state,
            shared: {
                loading: false,
                data: action?.payload
            }
        };
    }),
    on(AppActions.GetSharedDashboardListError ,(state) => {
        return {
            ...state,
            shared: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetFavoriteDashboardList, (state) => {
        return {
            ...state,
            favorite: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFavoriteDashboardListSuccess, (state , action) => {       
        return {
            ...state,
            favorite: {
                loading: false,
                data: action?.payload
            }
        };
    }),
    on(AppActions.GetFavoriteDashboardListError ,(state) => {
        return {
            ...state,
            favorite: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetRecentDashboardList, (state) => {
        return {
            ...state,
            recent: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetRecentDashboardListSuccess, (state, action) => {
        return {
            ...state,
            recent: {
                loading: false,
                data: action?.payload
            }
        };
    }),
    on(AppActions.GetRecentDashboardListError ,(state) => {
        return {
            ...state,
            recent: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SetSelectedDashboard, (state, action) => {
        return {
            ...state,
            selectedDashboard: { ...action.payload }
        };
    })
);

// // tslint:disable-next-line: typedef
export function AudienceInsightsReducer(state: AudienceInsightsState | undefined, action: Action) {
    return appReducer(state, action);
}
