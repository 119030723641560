import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as ProfilesActions from './profiles.actions';
import { SelectedProfileRes } from './profiles.interface';

export const selfServiceProfilesReducers = [
    on(ProfilesActions.LoadProfilesData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profilesData: {
                    data: state?.selfServiceProfilesState?.profilesData?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProfilesActions.LoadProfilesDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profilesData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProfilesActions.LoadProfilesDataError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profilesData: {
                    data: state?.selfServiceProfilesState?.profilesData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.SearchQueries, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                seachQueriesResults: {
                    data: state?.selfServiceProfilesState?.seachQueriesResults?.data,
                    loading: true
                }
            }
        };
    }),
    on(ProfilesActions.SearchQueriesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                seachQueriesResults: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProfilesActions.SearchQueriesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                seachQueriesResults: {
                    data: state?.selfServiceProfilesState?.seachQueriesResults?.data,
                    loading: false
                }
            }
        };
    }),
    on(ProfilesActions.ClearSearchQueriesResults, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                seachQueriesResults: {
                    data: [],
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.ChangeProfilePriority, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profilesData: {
                    data: state?.selfServiceProfilesState?.profilesData?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.ChangeProfilePriorityError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profilesData: {
                    data: state?.selfServiceProfilesState?.profilesData?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetSelectedProfileDetails, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: state?.selfServiceProfilesState?.selectedProfile?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetSelectedProfileDetailsSuccess, (state: SelfServiceState, action) => {
        let res = action?.payload?.data;
        const selectedMembers = [];
        const availableMembers = [];
        if (res?.selectedMembers?.length > 0) {
            res?.selectedMembers?.forEach((member) => {
                if (member?.selected) {
                    selectedMembers.push(member);
                } else {
                    availableMembers.push(member);
                }
            });

            res = {
                ...res,
                selectedMembers,
                availableMembers
            };
        }

        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: res,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetSelectedProfileDetailsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: state?.selfServiceProfilesState?.selectedProfile?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.ClearSelectedProfileDetails, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetContactRules, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                contactRules: {
                    data: state?.selfServiceProfilesState?.contactRules?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetContactRulesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                contactRules: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetContactRulesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                contactRules: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetNameSpaces, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                nameSpaces: {
                    data: state?.selfServiceProfilesState?.nameSpaces?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetNameSpacesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                nameSpaces: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetNameSpacesError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                nameSpaces: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.SaveProfile, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: state?.selfServiceProfilesState?.selectedProfile?.data,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.SaveProfileSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: action?.payload as SelectedProfileRes,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.SaveProfileError, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                selectedProfile: {
                    data: action?.payload,
                    loading: false,
                    errors: true
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileBehaviors, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileBehaviors: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileBehaviorsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileBehaviors: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileBehaviorsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileBehaviors: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileDemographics, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileDemographics: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileDemographicsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileDemographics: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileDemographicsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileDemographics: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileProducts, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileProducts: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileProductsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileProducts: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(ProfilesActions.GetProfileProductsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceProfilesState: {
                ...state.selfServiceProfilesState,
                profileProducts: {
                    data: null,
                    loading: false
                }
            }
        };
    }),
];
