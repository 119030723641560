import { AudienceBuilderState } from '../interfaces';
import * as FoldersActions from './folders.actions';
import { on } from '@ngrx/store';

export const foldersReducer = [
    on(FoldersActions.AddGroup, (state: AudienceBuilderState) => {
        return {
            ...state,
            queryLoader: true
        };
    }
    ),
    on(FoldersActions.AddGroupSuccess, (state: AudienceBuilderState, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: action.payload?.data
            },
            queryLoader: false
        };
    }
    ),
    on(FoldersActions.AddGroupError, (state: AudienceBuilderState) => {
        return {
            ...state,
            queryLoader: false
        };
    }
    ),
    on(FoldersActions.GetCurrentSessionGroups, (state: AudienceBuilderState) => {
        return {
            ...state,
            loading: true
        };
    }
    ),
    on(FoldersActions.GetCurrentSessionGroupsSuccess, (state: AudienceBuilderState, action) => {
        return {
            ...state,
            currentSessionGroups: action.payload.data,
            loading: false
        };
    }
    ),
    on(FoldersActions.GetCurrentSessionGroupsError, (state: AudienceBuilderState) => {
        return {
            ...state,
            loading: false
        };
    }
    ),

    on(FoldersActions.GetFolderTextSearch, (state: AudienceBuilderState) => {
        return {
            ...state,
            folderTextSearch: {
                data: state.folderTextSearch?.data
            }
        };
    }),

    on(FoldersActions.SetFolderTextSearch, (state: AudienceBuilderState, action) => {
        const folderText = Object.assign({}, state.folderTextSearch?.data);

        if (folderText[action.payload.folderId]) {
            folderText[action.payload.folderId] = null;
        }
        folderText[action.payload.folderId] = { textSearch: action.payload.textSearch };

        return {
            ...state,
            folderTextSearch: {
                data: folderText
            }
        };
    }),

    on(FoldersActions.ClearFolderTextSearch, (state: AudienceBuilderState, action) => {
        const folderText = Object.assign({}, state.folderTextSearch?.data);

        if (folderText[action.payload.folderId]) {
            folderText[action.payload.folderId] = null;
        }

        return {
            ...state,
            folderTextSearch: {
                data: folderText
            }
        };
    }),
];
