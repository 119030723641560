// import { SelfServiceEffects } from './api.effects';
// import { SelfServiceBehaviorsEffects } from './behaviors/behaviors.effects';
// import { SelfServiceDatabaseSettingsEffects } from './database-settings/database-settings.effects';
// import { SelfServiceDemographicsEffects } from './demographics/demographics.effects';
// import { SelfServiceDeploymentProductsEffects } from './deployment-products/deployment-products.effects';
// import { SelfServiceDeploymentTypeEffects } from './deployment-types/deployment-types.effects';
// import { SelfServiceMessageTypesEffects } from './message-types/message-types.effects';

import { SmsBuilderEffects } from './api.effects';
import { SmsBuilderManageSmsMessageEffects } from './manage-sms-message/manage-sms-message.effects';

// put all effect files related to SelfService here
// so we can easily import them in the main module
export const SmsBuilderCommonEffects = [
    SmsBuilderEffects,
    SmsBuilderManageSmsMessageEffects,
    // SelfServiceDatabaseSettingsEffects,
    // SelfServiceDemographicsEffects,
    // SelfServiceBehaviorsEffects,
    // SelfServiceDeploymentTypeEffects,
    // SelfServiceDeploymentProductsEffects,
    // SelfServiceMessageTypesEffects,
];
