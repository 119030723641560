import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmptyDialogModal } from '@cdp/app/main/modals/empty-dialog/empty-dialog.modal';
import { DescriptionValue, PersonalizationChartResponse, PersonalizationContentBase, RecommendationData, SelectedProductItem, TrackingLinkI } from '@cdp/state/interfaces';
import { GenerateMeterLinkError, GenerateMeterLinkSuccess } from '@cdp/state/meters/meters.actions';
import * as CdpPersonalizationActions from '@cdp/state/personalizations/personalizations.actions';
import { BasicSuccessResponse, BeeAiTokenCount, BeeAuthTokenResponse, CredsparkForm, CredsparkFormSearchFilter, ContentMergeVariables, ContentRecommendationBehaviorItem, CurrentPersonalizationResponse, FormBuilderKeywordItem, FormBuilderKeywords, GetPersonalizationsDataResponse, OlyticsParameters, PersonalizationItem, PersonalizationLink, SavePersonalizationDataResponse } from '@cdp/state/personalizations/personalizations.interface';
import { checkUserPersonalizationPermissions } from '@cdp/state/personalizations/personalizations.selector';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ListSchema, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { DragonSite, FormSearchFilter } from 'projects/form-builder/src/app/state/interfaces';
import { catchError, exhaustMap, map, mergeMap, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerDataPlatformPersonalizationsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private casHttp: CasHttpClient,
        private shellStore: ShellStore,
        private modalService: ModalService,
        private route: ActivatedRoute,
    ) { }

    getPersonalizationsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationsData),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('nameSearch', action.payload?.nameSearch || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('displayPosition', action.payload?.displayPosition?.toString() || '');
                searchParams.set('personalizationStatus', action.payload?.personalizationStatus?.toString() || '');
                searchParams.set('personalizationType', action.payload?.personalizationType?.toString() || '');
                searchParams.set('sortBy', action.payload?.sortBy ? action.payload?.sortBy : 'priority');
                searchParams.set('urlRestrictionSearch', action.payload?.urlRestrictionSearch || '');
                searchParams.set('order', action.payload?.order || '');
                action.payload?.currentPersonalizationId ? searchParams.set('currentPersonalizationId', action.payload.currentPersonalizationId?.toString()) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalizations?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: GetPersonalizationsDataResponse) => {
                            // TODO: remove this once the backend stops sending the GDPR flag as an image
                            if (res && res.personalizationList && res.personalizationList.length) {
                                const regex: RegExp = /&nbsp;<img src="\/campaign\/images\/gdpr_flag\.png" style="width:37px;height:21px;">/g;
                                //TOD: Temporary fix, since the back end sometimes sends names as html span
                                const regex2: RegExp = /^<span/g;
                                res.personalizationList = res.personalizationList.map((personalization) => {
                                    if (personalization.name.match(regex)) {
                                        personalization.name = personalization.name.replace(regex, '');
                                        personalization.gdpr = true;
                                    } else if (personalization.name.match(regex2) != null) {
                                        const tmp = document.createElement('div');
                                        tmp.innerHTML = personalization.name;
                                        personalization.name = tmp.querySelector('span').innerText;
                                    }
                                    return personalization;
                                });
                            }
                            const payload: ResponseWrapper<GetPersonalizationsDataResponse> = {
                                loading: false,
                                data: {...res, offset: action.payload?.offset, numResults: action.payload?.numResults},
                            };
                            return CdpPersonalizationActions.GetPersonalizationsDataSuccess({ payload: payload, userPersonalizationPermissions: this.userPersonalizationPermissions });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalizations data.');
                            return of(CdpPersonalizationActions.GetPersonalizationsDataError(error));
                        })
                    );
            }
            )
        )
    );

    changePersonalizationPriority$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.ChangePersonalizationPriority),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/priority?${searchParams}`,
                        {gdpr: action.payload.gdpr, personalizationId: action.payload.personalizationId, direction: action.payload.direction, typeCode: action.payload.typeCode},
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicSuccessResponse) => {
                            const payload: ResponseWrapper<BasicSuccessResponse> = {
                                loading: false,
                                data: res,
                            };
                            let priority: number = null;
                            if (action.payload.changeUrl) {
                                const currentPriority = parseInt(this.route.snapshot.queryParams.priority) || null;
                                priority = action.payload.direction === 'up' ? currentPriority - 1 : currentPriority + 1;
                                this.router.navigate([], { queryParams: {...this.route.snapshot.queryParams, priority} });
                                const payloadPersonalizationsOffset = {
                                    personalizationId: action.payload?.reloadData?.personalizationId,
                                    numResults: action.payload?.reloadData?.numResults,
                                    order: action.payload?.reloadData?.order,
                                    personalizationType: action.payload?.reloadData?.personalizationType,
                                };
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsOffset({payload: payloadPersonalizationsOffset, reloadData: action.payload?.reloadData }));
                            } else {
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsData({payload: action.payload?.reloadData }));
                            }
                            return CdpPersonalizationActions.ChangePersonalizationPrioritySuccess({ payload, priority, changeUrl: action.payload.changeUrl });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to change personalization prirority.');
                            return of(CdpPersonalizationActions.ChangePersonalizationPriorityError(error));
                        })
                    );
            }
            )
        )
    );

    archivePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.ArchivePersonalization),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                action.payload?.gdpr ? searchParams.set('gdpr', action.payload.gdpr.toString()) : null;

                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/archive?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.payload?.reloadPersonalize) {
                                this.store.dispatch(CdpPersonalizationActions.LoadPersonalizationBasicsData({ payload: { personalizationId: action.payload.personalizationId }}));
                            } else {
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsData({payload: action.payload?.reloadData}));
                            }
                            return CdpPersonalizationActions.ArchivePersonalizationSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to archive personalization.');
                            return of(CdpPersonalizationActions.ArchivePersonalizationError(error));
                        })
                    );
            }
            )
        )
    );

    deletePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.DeletePersonalization),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                action.payload?.gdpr ? searchParams.set('gdpr', action.payload.gdpr.toString()) : null;

                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/delete?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.payload?.isArchived) {
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsArchivedData({payload: {}}));
                            } else {
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsData({payload: action.payload?.reloadData}));
                            }
                            return CdpPersonalizationActions.DeletePersonalizationSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to delete personalization.');
                            return of(CdpPersonalizationActions.DeletePersonalizationError(error));
                        })
                    );
            }
            )
        )
    );

    getPersonalizationReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                action.payload?.gdpr ? searchParams.set('gdpr', action.payload.gdpr.toString()) : null;
                action.payload?.fromHistory ? searchParams.set('fromHistory', action.payload.fromHistory.toString()) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/report?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PersonalizationChartResponse) => {
                            const payload: ResponseWrapper<PersonalizationChartResponse> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetPersonalizationReportSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization report data.');
                            return of(CdpPersonalizationActions.GetPersonalizationReportError(error));
                        })
                    );
            }
            )
        )
    );

    getPersonalizationTest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationTest),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                action.payload?.gdpr ? searchParams.set('gdpr', action.payload.gdpr.toString()) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/test-on-my-site?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PersonalizationLink) => {
                            const payload: ResponseWrapper<PersonalizationLink> = {
                                loading: false,
                                data: res,
                            };
                            if (res?.messageContent) {
                                const modal = this.modalService.createModal(
                                    EmptyDialogModal,
                                    { draggable: true, width: '100%', maxWidth: '600px' },
                                    { messageContent: res?.messageContent });
                                modal.open();
                            }
                            return CdpPersonalizationActions.GetPersonalizationTestSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization test data.');
                            return of(CdpPersonalizationActions.GetPersonalizationReportError(error));
                        })
                    );
            }
            )
        )
    );

    getPersonalizationSiteTest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationSiteTest),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                if (action.payload?.personalizationVersionId) {
                    searchParams.set('personalizationVersionId', action.payload.personalizationVersionId.toString());
                }
                action.payload?.gdpr ? searchParams.set('gdpr', action.payload.gdpr.toString()) : null;

                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/test-on-my-site?${searchParams}`,
                        { siteUrl: action?.payload?.siteUrl },
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PersonalizationLink) => {
                            return GenerateMeterLinkSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to create personalization site test data.');
                            return of(GenerateMeterLinkError(error));
                        })
                    );
            }
            )
        )
    );

    getPersonalizationsArchivedData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationsArchivedData),
            switchMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('nameSearch', action.payload?.nameSearch || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '10');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('displayPosition', action.payload?.displayPosition?.toString() || '');
                searchParams.set('personalizationStatus', action.payload?.personalizationStatus?.toString() || '');
                searchParams.set('personalizationType', action.payload?.personalizationType?.toString() || '');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('urlRestrictionSearch', action.payload?.urlRestrictionSearch || '');
                searchParams.set('order', action.payload?.order || '');

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalizations/archived?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PersonalizationContentBase) => {
                            const payload: ResponseWrapper<PersonalizationContentBase> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetPersonalizationsArchivedDataSuccess({ payload: payload, userPersonalizationPermissions: this.userPersonalizationPermissions });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalizations archived data.');
                            return of(CdpPersonalizationActions.GetPersonalizationsArchivedDataError(error));
                        })
                    );
            }
            )
        )
    );

    loadBasicPersonalizationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.LoadPersonalizationBasicsData),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload?.personalizationId ? searchParams.set('personalizationId', action.payload.personalizationId?.toString()) : null;
                action.payload?.toClone ? searchParams.set('toClone', action.payload.toClone.toString()) : null;
                action.payload?.originalPersonalizationId ? searchParams.set('originalPersonalizationId', action.payload.originalPersonalizationId.toString()) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/basics?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: CurrentPersonalizationResponse) => {
                            res = {...res, saveBasicState: action.payload?.saveBasicState, priority: action.payload?.priority || null};
                            const payload: ResponseWrapper<CurrentPersonalizationResponse> = {
                                loading: false,
                                data: res,
                            };
                            if (!action.payload?.personalizationId) {
                                this.store.dispatch(CdpPersonalizationActions.ClearCurrentPersonalization());
                            }
                            return CdpPersonalizationActions.LoadPersonalizationDataBasicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization basic data.');
                            return of(CdpPersonalizationActions.LoadPersonalizationDataBasicsError(error));
                        })
                    );
            }
            )
        )
    );

    generateRecHtml$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GenerateRecommendationHtml),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                searchParams.set('behaviorId', action.payload.behaviorId.toString());
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/recommendation-html?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: RecommendationData) => {
                            const payload: ResponseWrapper<RecommendationData> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GenerateRecommendationHtmlSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get recommendation html data.');
                            return of(CdpPersonalizationActions.GenerateRecommendationHtmlError(error));
                        })
                    );
            }
            )
        )
    );

    savePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SavePersonalizationData),
            exhaustMap((action) => {
                const payload = {...action.payload};
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('pleat', action?.payload?.pleat);
                delete payload?.pleat;
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/${action?.payload?.pleat}?${searchParams}`,
                        payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SavePersonalizationDataResponse) => {
                            if (action.payload?.personalizationId == -1) {
                                this.router.navigate(['/customer-data-platform/main/personalized-content/personalize/audience'], { queryParams: {personalizationId: res?.personalizationId?.toString(), voyageId: this.route.snapshot?.queryParams?.voyageId, voyageName: this.route.snapshot?.queryParams?.voyageName, elementName: this.route.snapshot?.queryParams?.elementName, elementId: this.route.snapshot?.queryParams?.elementId}});
                                this.store.dispatch(CdpPersonalizationActions.SavePersonalizationState({ payload: { personalizationId: res?.personalizationId } }));
                                if (res?.personalizationId > 0) {
                                    this.store.dispatch(CdpPersonalizationActions.LoadPersonalizationAudienceData({ payload: {personalizationId: res.personalizationId}}));
                                }
                            }

                            if (this.route.snapshot?.queryParams?.toClone == 'true') {
                                this.router.navigate(['/customer-data-platform/main/personalized-content/personalize/audience'], { queryParams: {personalizationId: res?.personalizationId?.toString()} });
                                this.store.dispatch(CdpPersonalizationActions.SavePersonalizationState({ payload: { personalizationId: res?.personalizationId, statusCode: 2, statusDescription: 'Drafted' }}));
                            }
                            const payload: ResponseWrapper<SavePersonalizationDataResponse> = {
                                loading: false,
                                data: res,
                            };

                            if (res?.mergeMessage) {
                                this.store.dispatch(ShowToast({ payload: { title: 'Warning', design: '', placement: 'TopEnd', message: res?.mergeMessage, icon: 'fa-circle-exclamation-solid' } }));
                            }

                            if (!res?.mergeMessage && action?.payload?.personalizationId > 0) {
                                this.showSuccess('Success', 'Personalization message successfully saved');
                            }

                            payload.data = {...payload.data, routeUrl: this.router.url};
                            return CdpPersonalizationActions.SavePersonalizationDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', error?.error?.errors?.[0] ? error?.error?.errors?.[0] : 'An error has occurred while trying to save personalization data.');
                            return of(CdpPersonalizationActions.SavePersonalizationDataError(error));
                        })
                    );
            }
            )
        )
    );

    saveContentRecommendation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SaveContentRecommendations),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/recommendation-html?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: { behaviorName?: string, html?: string }) => {
                            const payload: ResponseWrapper<{ behaviorName?: string, html?: string }> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.SaveContentRecommendationsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to save content recommendations data.');
                            return of(CdpPersonalizationActions.SaveContentRecommendationsError(error));
                        })
                    );
            }
            )
        )
    );

    loadAudiencePersonalizationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.LoadPersonalizationAudienceData),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId?.toString());
                searchParams.set('numResults', '1');
                searchParams.set('offset', '1');

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/audience?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: CurrentPersonalizationResponse) => {
                            const payload: ResponseWrapper<CurrentPersonalizationResponse> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.LoadPersonalizationAudienceDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization audience data.');
                            return of(CdpPersonalizationActions.LoadPersonalizationAudienceDataError(error));
                        })
                    );
            }
            )
        )
    );

    getEuropeanCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetEuropeanCountries),
            exhaustMap(() => {
                return this.http
                    .get(
                        environment.apiEndPoint +
                        '/portal/country-map/eu',
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Array<DescriptionValue>) => {
                            const payload: ResponseWrapper<Array<DescriptionValue>> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetEuropeanCountriesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get European countries data.');
                            return of(CdpPersonalizationActions.GetEuropeanCountriesError(error));
                        })
                    );
            }
            )
        )
    );

    getProductsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetProductData),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                action.payload?.includeClassDefinitions ? searchParams.set('includeClassDefinitions', action.payload.includeClassDefinitions.toString()) : null;
                action.payload?.productId ? searchParams.set('productId', action.payload.productId.toString()) : null;

                return this.http.get(environment.apiEndPoint + `/portal/products?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedProductItem) => {
                        res = {...res[0], productId: action.payload.productId};
                        const payload = {loading: false, data: res};
                        return CdpPersonalizationActions.GetProductDataSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get products list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(CdpPersonalizationActions.GetProductDataError(error));
                    })
                );
            })
        )
    );

    loadRestrictionsPersonalizationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.LoadPersonalizationRestrictionsData),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload.personalizationId ?  searchParams.set('personalizationId', action.payload.personalizationId?.toString()) : null;

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/restrictions?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: CurrentPersonalizationResponse) => {
                            const payload: ResponseWrapper<CurrentPersonalizationResponse> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.LoadPersonalizationRestrictionsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization restrictions data.');
                            return of(CdpPersonalizationActions.LoadPersonalizationRestrictionsDataError(error));
                        })
                    );
            }
            )
        )
    );

    getOlyticsParameters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetOlyticsParameters),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('behaviorAttributeTypeId', action.payload.attributeTypeId.toString());
                searchParams.set('searchValue', action.payload?.searchValue || ' ');

                return this.http.get(
                    environment.campaignApiEndPoint +
                        `/cdp/personalization/olytics-parameter/count?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: OlyticsParameters) => {
                        res = {...res, attributeTypeId: action.payload.attributeTypeId, attributeTypeName: action.payload.attributeTypeName};
                        const payload = {loading: false, data: res};
                        return CdpPersonalizationActions.GetOlyticsParametersSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get olytics parameters.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(CdpPersonalizationActions.GetOlyticsParametersError(error));
                    })
                );
            })
        )
    );

    setPersonalizationPriority$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SetPersonalizationPriority),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId?.toString());
                searchParams.set('priorityValue', action.payload.priorityValue?.toString());
                return this.http
                    .put(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/priority-value?${searchParams}`, { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            const payloadPersonalizationsOffset = {
                                personalizationId: action.payload?.reloadData?.personalizationId,
                                numResults: action.payload?.reloadData?.numResults,
                                order: action.payload?.reloadData?.order,
                                personalizationType: action.payload?.reloadData?.personalizationType,
                            };

                            if (action.payload.changeUrl) {
                                this.router.navigate([], { queryParams: {...this.route.snapshot.queryParams, priority: action.payload.priorityValue} });
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsOffset({payload: payloadPersonalizationsOffset, reloadData: action.payload?.reloadData}));
                            } else {
                                this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsData({payload: action.payload?.reloadData}));
                            }
                            return CdpPersonalizationActions.SetPersonalizationPrioritySuccess({ payload, priorityValue: action.payload.priorityValue });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to set personalization priority value.');
                            return of(CdpPersonalizationActions.SetPersonalizationPriorityError(error));
                        })
                    );
            }
            )
        )
    );

    getOlyticsBehaviors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetOlyticsBehaviors),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.casHttp
                    .get( environment.campaignApiEndPoint,
                        environment.campaignApiEndPoint +
                        `/cdp/olytics/content-recommendation/behavior?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Array<ContentRecommendationBehaviorItem>) => {
                            const payload: ResponseWrapper<Array<ContentRecommendationBehaviorItem>> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetOlyticsBehaviorsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get behaviors.');
                            return of(CdpPersonalizationActions.GetOlyticsBehaviorsError(error));
                        })
                    );
            }
            )
        )
    );

    getFormList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetFormBuilderList),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('searchText', action.payload?.searchText || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('sortBy', action.payload?.sortBy || 'changedate');
                searchParams.set('order', action.payload?.order || 'desc');
                searchParams.set('templateCode', action.payload?.templateCode?.toString() || '0');
                searchParams.set('productId', action.payload?.productId?.toString() || '-1');
                searchParams.set('siteTypeId', action.payload?.siteTypeId?.toString() || '0');
                searchParams.set('siteCategoryId', action.payload?.siteCategoryId?.toString() || '0');
                searchParams.set('status', action.payload?.status?.toString() || '5');
                searchParams.set('owner', action.payload?.owner?.toString() || '');
                searchParams.set('keywordId', action.payload?.keywordId?.toString() || '');

                const url = environment.formBuilderApiEndPoint+`/form/form-list?${searchParams}`;
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<DragonSite>) => {
                        const payload: ResponseWrapper<ListSchema<DragonSite>> = { loading: false, data: res };
                        if (!action.payload?.ignoreError) {
                            this.store.dispatch(CdpPersonalizationActions.GetFormSelectionKeywords());
                        }
                        return CdpPersonalizationActions.GetFormBuilderListSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (!action.payload?.ignoreError) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get form list' } }));
                        }
                        return of(CdpPersonalizationActions.GetFormBuilderListError(error));
                    })
                );
            })
        )
    );

    getKeywords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetFormSelectionKeywords),
            exhaustMap(() => {
                const url = environment.formBuilderApiEndPoint+'/form/keyword?environmentId='+this.currentDatabase.id;
                return this.http.get(url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FormBuilderKeywords) => {
                        const payload: ResponseWrapper<Array<FormBuilderKeywordItem>> = { loading: false, data: res?.keywords };
                        return CdpPersonalizationActions.GetFormSelectionKeywordsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get keywords' } }));
                        return of(CdpPersonalizationActions.GetFormSelectionKeywordsError(error));
                    })
                );
            })
        )
    );

    getFormSearchFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetFormSearchFilters),
            exhaustMap(() => {
                const url = environment.formBuilderApiEndPoint+'/form/form-search-filters?environmentId='+this.currentDatabase.id+'&dataViewId='+this.currentUser?.currentProfile?.dataViewId;
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FormSearchFilter) => {
                        const payload: ResponseWrapper<FormSearchFilter> = { loading: false, data: res };
                        return CdpPersonalizationActions.GetFormSearchFiltersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get form search filters' } }));
                        return of(CdpPersonalizationActions.GetFormSearchFiltersError(error));
                    })
                );
            })
        )
    );

    getCredsparkFormList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetCredsparkFormList),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('searchText', action.payload?.searchText || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('sortBy', action.payload?.sortBy || 'lastEditedDate');
                searchParams.set('order', action.payload?.order || 'desc');
                searchParams.set('formType', action.payload?.formType?.toString() || '');
                searchParams.set('owner', action.payload?.owner?.toString() || '');

                const url = environment.campaignApiEndPoint+`/integration/credspark?${searchParams}`;
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: ListSchema<CredsparkForm>) => {
                        const payload: ResponseWrapper<ListSchema<CredsparkForm>> = { loading: false, data: res };
                        return CdpPersonalizationActions.GetCredsparkFormListSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        if (!action.payload?.ignoreError) {
                            this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get credspark form list' } }));
                        }
                        return of(CdpPersonalizationActions.GetCredsparkFormListError(error));
                    })
                );
            })
        )
    );

    getCredsparkFormSearchFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetCredsparkFormSearchFilters),
            exhaustMap(() => {
                const url = environment.campaignApiEndPoint+'/integration/credspark/form-filter?environmentId='+this.currentDatabase.id;
                return this.casHttp.get(environment.settingsApiEndPoint, url, { withCredentials: true }).pipe(
                    take(1),
                    map((res: CredsparkFormSearchFilter) => {
                        const payload: ResponseWrapper<CredsparkFormSearchFilter> = { loading: false, data: res };
                        return CdpPersonalizationActions.GetCredsparkFormSearchFiltersSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get form search filters' } }));
                        return of(CdpPersonalizationActions.GetCredsparkFormSearchFiltersError(error));
                    })
                );
            })
        )
    );

    getPersonalizationGeneratedHtml$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationGeneratedHtml),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                if (action.payload.personalizationId) {
                    searchParams.set('personalizationId', action.payload.personalizationId.toString());
                }
                searchParams.set('resetClicked', action.payload?.resetClicked?.toString() || 'false');
                if (action.payload?.isMeter) {
                    searchParams.set('isMeter', action.payload?.isMeter?.toString());
                }
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/html-content?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: DescriptionValue[]) => {
                            const payload: ResponseWrapper<DescriptionValue[]> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetPersonalizationGeneratedHtmlSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalization generated html.');
                            return of(CdpPersonalizationActions.GetPersonalizationGeneratedHtmlError(error));
                        })
                    );
            }
            )
        )
    );

    savePersonalizationGeneratedHtml$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SavePersonalizationGeneratedHtml),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/html-content?${searchParams}`,
                        [...action.payload?.generatedHtml],
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: DescriptionValue[]) => {
                            const payload: ResponseWrapper<DescriptionValue[]> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.SavePersonalizationGeneratedHtmlSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to save personalization generated html.');
                            return of(CdpPersonalizationActions.SavePersonalizationGeneratedHtmlError(error));
                        })
                    );
            }
            )
        )
    );

    getBeeAuthToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetBeeAuthToken),
            exhaustMap(() => {
                return this.http.get(
                    environment.campaignApiEndPoint + `/cdp/bee-token?environmentId=${this.currentDatabaseId}`,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: BeeAuthTokenResponse) => {
                        if (res && res.messages && res.messages.errors && res.messages.errors.length) {
                            this.showError(null, res.messages.errors[0]);
                        }
                        res.reloadBeePlugin = true;
                        return CdpPersonalizationActions.GetBeeAuthTokenSuccess({
                            payload: res,
                        });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to get Bee Auth token.');
                        return of(CdpPersonalizationActions.GetBeeAuthTokenError(error));
                    })
                );
            })
        )
    );

    saveBeeAIToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SaveBeeAIToken),
            exhaustMap((action) => {
                return this.http.post(
                    environment.apiEndPoint + `/email-builder/bee/ai-token-count?environmentId=${this.currentDatabaseId}`,
                    action.payload,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: BeeAiTokenCount) => {
                        if (res && res.messages && res.messages.errors && res.messages.errors.length) {
                            this.showError(null, res.messages.errors[0]);
                        }
                        res.reloadBeePlugin = false;
                        return CdpPersonalizationActions.SaveBeeAITokenSuccess({
                            payload: res,
                        });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to save Bee AI token.');
                        return of(CdpPersonalizationActions.SaveBeeAITokenError(error));
                    })
                );
            })
        )
    );

    getMergeVariableDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetMergeVariableDefaults),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());

                return this.http.post(
                    environment.campaignApiEndPoint + `/cdp/personalization/content-merge-variables?${searchParams}`,
                    {messageContent: action.payload?.messageContent},
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: ContentMergeVariables) => {
                        const payload: ResponseWrapper<ContentMergeVariables> = { loading: false, data: res };
                        return CdpPersonalizationActions.GetMergeVariableDefaultsSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get merge variable defaults' } }));
                        return of(CdpPersonalizationActions.GetMergeVariableDefaultsError(error));
                    })
                );
            })
        )
    );

    saveMergeVariableDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.SaveMergeVariables),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http.post(
                    environment.campaignApiEndPoint + `/cdp/personalization/merge-variables?${searchParams}`,
                    [...action.payload.mergeDefaults],
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return CdpPersonalizationActions.SaveMergeVariablesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get merge variable defaults' } }));
                        return of(CdpPersonalizationActions.SaveMergeVariablesError(error));
                    })
                );
            })
        )
    );

    getTrackingLinks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetTrackingLinks),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http.post(
                    environment.campaignApiEndPoint + `/cdp/personalization/tracking-links?${searchParams}`,
                    {messageContent: action.payload?.messageContent},
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: TrackingLinkI[]) => {
                        return CdpPersonalizationActions.GetTrackingLinksSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to get merge variable defaults' } }));
                        return of(CdpPersonalizationActions.GetTrackingLinksError(error));
                    })
                );
            })
        )
    );

    activatePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.ActivatePersonalization),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint + `/cdp/personalization/activate?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.store.dispatch(CdpPersonalizationActions.LoadPersonalizationBasicsData({payload: {personalizationId: action.payload.personalizationId}}));
                        return CdpPersonalizationActions.ActivatePersonalizationSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to activate personalization' } }));
                        return of(CdpPersonalizationActions.ActivatePersonalizationError(error));
                    })
                );
            })
        )
    );

    pausePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.PausePersonalization),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint + `/cdp/personalization/pause?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.store.dispatch(CdpPersonalizationActions.LoadPersonalizationBasicsData({payload: {personalizationId: action.payload.personalizationId}}));
                        return CdpPersonalizationActions.PausePersonalizationSuccess({ payload: res });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to pause personalization' } }));
                        return of(CdpPersonalizationActions.PausePersonalizationError(error));
                    })
                );
            })
        )
    );

    getPersonalizationHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationHistory),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('order', action.payload?.order || '');

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalization/history?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PersonalizationItem) => {
                            const payload: ResponseWrapper<PersonalizationItem> = {
                                loading: false,
                                data: res,
                            };
                            return CdpPersonalizationActions.GetPersonalizationHistorySuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalizations data.');
                            return of(CdpPersonalizationActions.GetPersonalizationHistoryError(error));
                        })
                    );
            }
            )
        )
    );

    getPersonalizationOffset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.GetPersonalizationsOffset),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('order', action.payload?.order?.toString() || 'asc');
                searchParams.set('nameSearch', action.reloadData?.nameSearch?.toString() || '');
                searchParams.set('urlRestrictionSearch', action.reloadData?.urlRestrictionSearch?.toString() || '');
                searchParams.set('personalizationStatus', action.reloadData?.personalizationStatus?.toString() || '');
                searchParams.set('personalizationType', action.reloadData?.personalizationType?.toString() || '');
                searchParams.set('displayPosition', action.reloadData?.displayPosition?.toString() || '');
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/cdp/personalizations/offset?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: number) => {
                            const payload: ResponseWrapper<number> = {
                                loading: false,
                                data: res,
                            };
                            const personalizationPayload = {
                                ...action?.reloadData,
                                personalizationId: action.payload.personalizationId,
                                offset: action.payload?.personalizationType == 1 && !action?.reloadData?.nameSearch && !action?.reloadData?.urlRestrictionSearch && !action?.reloadData?.personalizationStatus && !action?.reloadData?.displayPosition ? res : 0,
                                numResults: action.payload?.numResults,
                                personalizationType: action.payload?.personalizationType,
                                currentPersonalizationId: action.payload.personalizationId
                            };
                            this.store.dispatch(CdpPersonalizationActions.GetPersonalizationsData({ payload: personalizationPayload }));
                            return CdpPersonalizationActions.GetPersonalizationsOffsetSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.showError('Error', 'An error has occurred while trying to get personalizations offset.');
                            return of(CdpPersonalizationActions.GetPersonalizationsOffsetError(error));
                        })
                    );
            }
            )
        )
    );

    clonePersonalization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpPersonalizationActions.ClonePersonalization),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('personalizationId', action.payload.personalizationId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint + `/cdp/personalization/clone?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        if (res.success) {
                            this.router.navigate(['/customer-data-platform/main/personalized-content/personalize/basic'], { queryParams: { personalizationId: res?.messages[0] } });
                            if(action.payload.reloadPage) {
                                // will need the timeout, to make sure that the navigation has ended
                                setTimeout(() => {
                                    window.location.reload();
                                });
                            }
                        }
                        return of();
                    }),
                    catchError(_error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'Error occurred while trying to clone personalization' } }));
                        return of();
                    })
                );
            })
        )
    , { dispatch: false });

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/tools';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): number {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute;
        }
        return this.currentDatabase?.id;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }

    get userPersonalizationPermissions() {
        let permissions = null;
        this.store.select(checkUserPersonalizationPermissions)?.pipe(
            take(1),
            map(res => {
                permissions = res;
            })
        )?.subscribe();
        return permissions;
    }
}
