import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, debounceTime, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ScheduleOutputActions from './schedule-outputs.actions';
import { OutputCritRes, QueriesSearchResults, ScheduleOutputsRes, SelectedScheduleOutput } from './schedule-outputs.interfaces';

@Injectable({ providedIn: 'root' })
export class CampaignsScheduleOutputsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
        private casHttp: CasHttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    getScheduleOutputsLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleOutputActions.GetScheduleOutputsLogs),
            switchMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('numResults', action.payload?.numResults?.toString());
                searchParams.set('offset', action.payload?.offset?.toString());
                searchParams.set('searchText', action.payload?.searchText);
                searchParams.set('order', action.payload?.order);
                searchParams.set('sortBy', action.payload?.sortBy);
                action.payload?.active != null && action.payload?.active != undefined ? searchParams.set('active', action.payload?.active?.toString()) : null;
                return this.http
                    .get(this.baseUrl + `/search?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: ScheduleOutputsRes) => {
                            const payload: ResponseWrapper<ScheduleOutputsRes> = {
                                data: res,
                                loading: false
                            };
                            return ScheduleOutputActions.GetScheduleOutputsLogsSuccess({payload});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get schedule outputs.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ScheduleOutputActions.GetScheduleOutputsLogsError(error));
                        })
                    );
            })
        )
    );

    getScheduleOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleOutputActions.GetSelectedScheduleOutput),
            switchMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('eventId', action.payload?.eventId?.toString());
                return this.http
                    .get(this.baseUrl + `?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SelectedScheduleOutput) => {
                            const payload: ResponseWrapper<SelectedScheduleOutput> = {
                                data: res,
                                loading: false
                            };
                            return ScheduleOutputActions.GetSelectedScheduleOutputSuccess({payload});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get schedule output data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            this.router.navigate([`${environment?.campaignsPath}/main/scheduled-outputs`]);
                            return of(ScheduleOutputActions.GetSelectedScheduleOutputError(error));
                        })
                    );
            })
        )
    );

    saveScheduleOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleOutputActions.SaveSelectedScheduleOutput),
            switchMap(action => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(this.baseUrl + `?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SelectedScheduleOutput) => {
                            const payload: ResponseWrapper<SelectedScheduleOutput> = {
                                data: res,
                                loading: false
                            };
                            if (!action.payload?.eventId) {
                                this.router.navigate([`${environment?.campaignsPath}/main/scheduled-outputs`]);
                            }
                            this.showSuccess('Success', 'Schedule output data has been saved successfully.');
                            return ScheduleOutputActions.SaveSelectedScheduleOutputSuccess({payload});
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save schedule output data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ScheduleOutputActions.SaveSelectedScheduleOutputError(error));
                        })
                    );
            })
        )
    );

    searchQueries$ = createEffect(() =>
        this.actions$.pipe(
            debounceTime(300),
            ofType(ScheduleOutputActions.SearchQueries),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('allDataViews', 'true');
                searchParams.set('profileId', action.payload.profileId ? action.payload.profileId : '');
                searchParams.set('createdBy', action.payload.createdBy ? action.payload.createdBy : '');
                searchParams.set('keyword', action.payload.keyword ? action.payload.keyword : '');
                searchParams.set('lastUpdated', action.payload.lastUpdated ? action.payload.lastUpdated : '');
                searchParams.set('lockedQueriesOnly', action.payload.lockedQueriesOnly ? action.payload.lockedQueriesOnly?.toString() : '');
                searchParams.set('name', action.payload.name ? action.payload.name : '');
                searchParams.set('nameSearchType', action.payload.nameSearchType ? action.payload.nameSearchType : '');
                searchParams.set('numResults', action.payload.numResults ? action.payload.numResults?.toString() : '');
                searchParams.set('olyticsQueriesOnly', action.payload.olyticsQueriesOnly ? action.payload.olyticsQueriesOnly?.toString() : '');
                searchParams.set('offset', action.payload.offset ? action.payload.offset?.toString() : '');
                searchParams.set('sortBy', action.payload.sortBy ? action.payload.sortBy : '');
                searchParams.set('order', action.payload.order ? action.payload.order : '');
                searchParams.set('mode', action.payload.mode ? action.payload.mode : '');
                return this.http.get(environment.apiEndPoint + `/audience-builder/selection-criteria/search?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: QueriesSearchResults) => {
                        const payload: ResponseWrapper<QueriesSearchResults> = { loading: false, data: res };
                        return ScheduleOutputActions.SearchQueriesSuccess({ payload: payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to search for queries.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(ScheduleOutputActions.SearchQueriesError(error));
                    })
                );
            })
        )
    );

    getOutputCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleOutputActions.GetOutputCriteria),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                return this.http.get(
                    environment.apiEndPoint +
                        `/audience-builder/output/output-criteria/list?${searchParams}`,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: OutputCritRes) => {
                        const payload: ResponseWrapper<OutputCritRes> = {
                            data: res,
                            loading: false
                        };
                        return ScheduleOutputActions.GetOutputCriteriaSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to get output criteria list.');
                        return of(ScheduleOutputActions.GetOutputCriteriaError(error));
                    })
                );
            })
        )
    );

    changeStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleOutputActions.ChangeStatus),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id?.toString());
                searchParams.set('eventId', action.payload.eventId?.toString());
                searchParams.set('name', action.payload.name);
                searchParams.set('statusCode', action.payload.statusCode?.toString());
                return this.http.put(
                    this.baseUrl + `/update-status?${searchParams}`,
                    this.defaultHttpOptions
                ).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = {
                            data: res,
                            loading: false
                        };
                        this.store.dispatch(ScheduleOutputActions.GetScheduleOutputsLogs({ payload: action.payload?.searchPayload || null }));
                        return ScheduleOutputActions.ChangeStatusSuccess({ payload });
                    }),
                    catchError((error) => {
                        let errorMessage = 'An error has occurred while trying to change output status.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(ScheduleOutputActions.ChangeStatusError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/campaign/lead-gen';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
