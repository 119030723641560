import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, take, mergeMap, exhaustMap, switchMap  } from 'rxjs';
import { ShellStore, ShowToast, getCurrentUser,CasHttpClient } from 'common';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { AudienceInsightsSendABSuccess,AudienceInsightsSendABError,AudienceInsightsTokenSuccess,AudienceInsightsTokenError, GetSharedDashboardList, GetSharedDashboardListSuccess, GetSharedDashboardListError, SetSelectedDashboard, GetFavoriteDashboardList, GetFavoriteDashboardListSuccess, GetFavoriteDashboardListError, GetRecentDashboardList, GetRecentDashboardListSuccess, GetRecentDashboardListError, AudienceInsightsSendAB} from './actions';
import { AudienceInsightsNavigationItem, SelectedDashboardTokenInfo } from './interfaces';

@Injectable({ providedIn: 'root' })

export class AudienceInsightsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private casHttp: CasHttpClient,
        private shellStore: ShellStore,
    ) { }

    sendABAudienceInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AudienceInsightsSendAB),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('sigmaWorkbookUrlId',action.payload.workbookId);
                return this.casHttp.post(environment.campaignApiEndPoint,environment.campaignApiEndPoint +`/audience-insights/dashboard-customers?${searchParams}`, action.payload.data,{ withCredentials: true }).pipe(
                    take(1),
                    map((res) => {
                        return AudienceInsightsSendABSuccess({ payload: res });
                    }),
                    catchError(error => {
                        let errorMessage = 'An error has occurred while trying to send audience insight data to audience builder.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(AudienceInsightsSendABError(error));
                    })
                );
            })
        )
    );

    setSelectedDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetSelectedDashboard),
            switchMap(({ payload }) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('sigmaWorkbookUrlId', payload.sigmaUrlId);
                return this.casHttp.get(environment.campaignApiEndPoint,environment.campaignApiEndPoint + `/audience-insights/token-info?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelectedDashboardTokenInfo) => {
                        return AudienceInsightsTokenSuccess({ payload: res });
                    }),
                    catchError(error => {
                        return of(AudienceInsightsTokenError(error));
                    })
                );
            })
        )
    );

    getSharedDashboardList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetSharedDashboardList),
            mergeMap(() => {
                return this.casHttp.get(environment.campaignApiEndPoint,environment.campaignApiEndPoint +`/audience-insights/dashboards/shared?environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AudienceInsightsNavigationItem[]) => {
                        return GetSharedDashboardListSuccess({ payload: res });
                    }),
                    catchError(error => {
                        // let errorMessage = 'An error has occurred while trying to get Teams Dashboard List.';
                        // if (error && error.error && error.error.errors && error.error.errors.length) {
                        //     errorMessage = error.error.errors[0];
                        // }
                        // this.showError(null, errorMessage);
                        return of(GetSharedDashboardListError(error));
                    })
                );
            })
        )
    );

    getFavoriteDashboardList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFavoriteDashboardList),
            mergeMap(() => {
                return this.casHttp.get(environment.campaignApiEndPoint,environment.campaignApiEndPoint +`/audience-insights/dashboards/favorite?environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AudienceInsightsNavigationItem[]) => {
                        return GetFavoriteDashboardListSuccess({ payload: res });
                    }),
                    catchError(error => {
                        return of(GetFavoriteDashboardListError(error));
                    })
                );
            })
        )
    );

    getRecentDashboardList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRecentDashboardList),
            mergeMap(() => {
                return this.casHttp.get(environment.campaignApiEndPoint,environment.campaignApiEndPoint +`/audience-insights/dashboards/recent?environmentId=${this.currentDatabase.id}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: AudienceInsightsNavigationItem[]) => {
                        return GetRecentDashboardListSuccess({ payload: res });
                    }),
                    catchError(error => {
                        return of(GetRecentDashboardListError(error));
                    })
                );
            })
        )
    );

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(take(1), map(database => res = database)).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(take(1), map(user => res = user)).subscribe();
        return res;
    }

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }
}
