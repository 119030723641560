import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ModalService, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, mergeMap, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as PromoCodesActions from './promo-codes.actions';
import { PriceChoice, PromoCodeData, PromoCodeProduct, PromoCodesRes, SinglePromoCodeData, UploadPromocodesResponse } from './promo-codes.interfaces';

@Injectable({ providedIn: 'root' })
export class PromoCodesEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
        private casHttp: CasHttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }

    getPromoCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetPromoCodes),
            switchMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('searchText', action.payload?.searchText || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '15');
                searchParams.set('offset', action.payload?.offset?.toString() || '0');
                searchParams.set('status', action.payload?.status || '');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('order', action.payload?.order || '');

                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/search?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PromoCodesRes) => {
                            const payload: ResponseWrapper<PromoCodesRes> = {
                                loading: false,
                                data: res,
                            };
                            return PromoCodesActions.GetPromoCodesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get promo codes data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                                if (error.status === 403) {
                                    this.router.navigate([`${environment?.campaignsPath}`, 'main', 'dashboard']);
                                }
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.GetPromoCodesError(error));
                        })
                    );
            }
            )
        )
    );

    clonePromoCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.ClonePromoCode),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('promotionId', action.payload.promotionId);
                searchParams.set('newPromotion', action.payload.newPromotion);

                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/clone?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PromoCodeData) => {
                            const payload: ResponseWrapper<PromoCodeData> = {
                                loading: false,
                                data: res,
                            };
                            this.showSuccess('Success', 'Promo Code has been cloned successfully.');
                            this.modalService.closeModal(action.modal);
                            this.router.navigate([`${environment?.campaignsPath}`, 'main', 'manage-promo-code'], { queryParams: { promotionId: action.payload.newPromotion } });
                            return PromoCodesActions.ClonePromoCodeSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to clone promo codes data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.ClonePromoCodeError(error));
                        })
                    );
            }
            )
        )
    );

    changePromoCodeStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.ChangePromoCodeStatus),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('orgId', this.currentDatabase?.org?.id.toString());
                searchParams.set('promotionId', action.payload.promotionId);
                searchParams.set('mode', action.payload.mode);

                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/status-change?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: PromoCodeData) => {
                            const payload: ResponseWrapper<PromoCodeData> = {
                                loading: false,
                                data: res,
                            };
                            if (action.payload?.reloadPayload) {
                                this.store.dispatch(PromoCodesActions.GetPromoCodes({ payload: action.payload?.reloadPayload }));
                            } else {
                                this.store.dispatch(PromoCodesActions.GetPromoCode({ payload: { promotionId: action.payload?.promotionId } }));
                            }
                            return PromoCodesActions.ChangePromoCodeStatusSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to change promo code status.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.ChangePromoCodeStatusError(error));
                        })
                    );
            }
            )
        )
    );

    downloadPromoCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.DownloadPromoCodes),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('downloadOption', action.payload?.downloadOption || '');
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/download?${searchParams}`,
                        { responseType: 'text', withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: string) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = 'promocodes';
                            a.click();
                            window.URL.revokeObjectURL(url);

                            return PromoCodesActions.DownloadPromoCodesSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download promo codes data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.DownloadPromoCodesError(error));
                        })
                    );
            }
            )
        )
    );

    getPromoCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetPromoCode),
            exhaustMap((action?) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('promotionId', action.payload?.promotionId || '');
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SinglePromoCodeData) => {
                            const payload: ResponseWrapper<SinglePromoCodeData> = {
                                loading: false,
                                data: res,
                            };
                            return PromoCodesActions.GetPromoCodeSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get promo code data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            this.router.navigate([`${environment?.campaignsPath}`, 'main', 'promo-codes']);
                            return of(PromoCodesActions.GetPromoCodeError(error));
                        })
                    );
            }
            )
        )
    );

    getPromoCodeTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetPromoCodeTemplate),
            exhaustMap(() => {
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        '/campaign/promo-codes/upload-template',
                        { responseType: 'blob', withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Blob) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = 'promo_code_upload_template';
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return PromoCodesActions.GetPromoCodeTemplateSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get promo code template.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.GetPromoCodeTemplateError(error));
                        })
                    );
            }
            )
        )
    );

    savePromoCodePricing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.SavePromoCodeCampaignPricing),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/save-campaign-pricing?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(PromoCodesActions.UnsavedChanges({ payload: false }));
                            const promotionId = this.route.snapshot.queryParams?.promotionId || null;
                            this.store.dispatch(PromoCodesActions.GetPromoCode({ payload: { promotionId } }));
                            return PromoCodesActions.SavePromoCodeCampaignPricingSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save promo code campaign pricing.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.SavePromoCodeCampaignPricingError(error));
                        })
                    );
            }
            )
        )
    );

    savePromoCodeProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.SavePromoCodeProducts),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/save-products?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(PromoCodesActions.UnsavedChanges({ payload: false }));
                            const promotionId = this.route.snapshot.queryParams?.promotionId || null;
                            if (action?.isPaid) {
                                this.router.navigate([`${environment?.campaignsPath}`, 'main', 'manage-promo-code', 'campaign-pricing'], { queryParams: { promotionId } });
                            }
                            this.store.dispatch(PromoCodesActions.GetPromoCode({ payload: { promotionId } }));
                            return PromoCodesActions.SavePromoCodeProductsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save promo code products.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.SavePromoCodeProductsError(error));
                        })
                    );
            }
            )
        )
    );

    savePromoCodeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.SavePromoCodeData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/save-data?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(PromoCodesActions.UnsavedChanges({ payload: false }));
                            const promotionId = this.route.snapshot.queryParams?.promotionId || action.payload?.promoCode || null;
                            if (action?.redirect) {
                                this.router.navigate([`${environment?.campaignsPath}`, 'main', 'manage-promo-code', 'product-group'], { queryParams: { promotionId } });
                            }
                            this.store.dispatch(PromoCodesActions.GetPromoCode({ payload: { promotionId } }));
                            return PromoCodesActions.SavePromoCodeDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            if (error.error.errors?.[0] == 'This Promo Code already exists. Would you like to go to the existing Promo Code?') {
                                this.showError('Error', error.error.errors[0], { commands: [`${environment?.campaignsPath}/main/manage-promo-code`], extras: { queryParams: { promotionId: action.payload?.promoCode } } });
                            } else {
                                let errorMessage = 'An error has occurred while trying to save promo code data details.';
                                if (error && error.error && error.error.errors && error.error.errors.length) {
                                    errorMessage = error.error.errors[0];
                                }
                                this.showError(null, errorMessage);
                            }
                            return of(PromoCodesActions.SavePromoCodeDataError(error));
                        })
                    );
            }
            )
        )
    );

    uploadPromoCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.UploadPromoCodes),
            exhaustMap((action) => {
                const formData = new FormData();
                formData.append('uploadfile', action.payload);
                formData.append('envId', this.currentDatabase.id);
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/upload?${searchParams}`,
                        formData,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Array<UploadPromocodesResponse>) => {
                            return PromoCodesActions.UploadPromoCodesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload promo codes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.UploadPromoCodesError(error));
                        })
                    );
            }
            )
        )
    );

    getProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetProducts),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser.currentProfile.dataViewId);
                searchParams.set('mode', 'promoCode');
                return this.http
                    .get(
                        environment.apiEndPoint +
                        `/portal/products?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Array<PromoCodeProduct>) => {
                            const payload: ResponseWrapper<Array<PromoCodeProduct>> = {
                                loading: false,
                                data: res,
                            };
                            return PromoCodesActions.GetProductsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get promo code products.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.GetProductsError(error));
                        })
                    );
            }
            )
        )
    );

    uploadActivatePromoCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.UploadActivatePromoCodes),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('orgId', this.currentDatabase?.org?.id?.toString());
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/upload/activate?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            this.modalService.closeModal(action.modal);
                            let successMessage = 'Promo Codes have been uploaded and activated successfully.';
                            if (res && res?.messages && res?.messages?.length) {
                                successMessage = res.messages?.join(', ');
                            }
                            this.showSuccess('Success', successMessage);
                            return PromoCodesActions.UploadActivatePromoCodesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload and activate Promo Codes.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.UploadActivatePromoCodesError(error));
                        })
                    );
            }
            )
        )
    );

    getSingleCopyIssues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetSingleCopyIssues),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('productId', action.payload?.productId?.toString());
                searchParams.set('singlePage', 'true');
                return this.casHttp
                    .get(
                        environment.settingsApiEndPoint,
                        environment.settingsApiEndPoint +
                        `/product/issues?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: SinglePromoCodeData) => {
                            const payload: ResponseWrapper<SinglePromoCodeData> = {
                                loading: false,
                                data: { ...res, productId: action.payload.productId },
                            };
                            return PromoCodesActions.GetSingleCopyIssuesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get single copy issues.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.GetSingleCopyIssuesError({ payload: { error, productId: action.payload?.productId } }));
                        })
                    );
            }
            )
        )
    );

    saveSingleCopyIssues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.SaveSingleCopyIssues),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http
                    .post(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/single-copy-products?${searchParams}`,
                        action.payload,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action?.savePromoPayload) {
                                this.store.dispatch(PromoCodesActions.SavePromoCodeProducts({ ...action.savePromoPayload }));
                            }
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            return PromoCodesActions.SaveSingleCopyIssuesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save single copy issues.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.SaveSingleCopyIssues(error));
                        })
                    );
            }
            )
        )
    );

    getPriceChoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromoCodesActions.GetPriceChoices),
            mergeMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('productId', action?.productId?.toString());
                return this.http
                    .get(
                        environment.campaignApiEndPoint +
                        `/campaign/promo-codes/price-choices?${searchParams}`,
                        { withCredentials: true }
                    ).pipe(
                        take(1),
                        map((res: Array<PriceChoice>) => {
                            return PromoCodesActions.GetPriceChoicesSuccess({ payload: res, productId: action.productId });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get price choices.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(PromoCodesActions.GetPriceChoicesError({ payload: { error, productId: action?.productId } }));
                        })
                    );
            }
            )
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, redirection?: { commands: Array<string>, extras?: NavigationExtras }) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    redirection: redirection || null,
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }
}

