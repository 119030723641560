import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CasHttpClient, ShellStore, ShowToast, getCurrentUser } from 'common';
import { exhaustMap, map, take } from 'rxjs';
import * as SelfServiceActions from './actions';

import { ActivatedRoute } from '@angular/router';
import { BasicResponse } from 'common';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelfServiceProductsRes } from './interfaces';

@Injectable({ providedIn: 'root' })
export class SelfServiceEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private casHttp: CasHttpClient,
        private route: ActivatedRoute
    ) { }

    syncEnvironment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SelfServiceActions.SyncEnvironment),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('mode', action.payload.mode || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/sync-env?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res.errors && res.errors.length && res.errors[0]) {
                                this.showError(null, res.errors[0]);
                            }
                            return SelfServiceActions.SyncEnvironmentSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to sync environment.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(SelfServiceActions.SyncEnvironmentError(error));
                        })
                    );
            })
        )
    );

    getProductsList = createEffect(() =>
        this.actions$.pipe(
            ofType(SelfServiceActions.GetProducts),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId);
                searchParams.set('mode', 'active');
                return this.http.get(environment.apiEndPoint + `/portal/products?${searchParams}`, { withCredentials: true }).pipe(
                    take(1),
                    map((res: SelfServiceProductsRes) => {
                        const payload = {loading: false, data: res};
                        return SelfServiceActions.GetProductsSuccess({ payload });
                    }),
                    catchError(error => {
                        this.store.dispatch(ShowToast({ payload: { title: 'Error', design: 'Error', placement: 'TopEnd', message: 'An error has occurred while trying to get products list.', icon: 'fa-circle-exclamation-solid' } }));
                        return of(SelfServiceActions.GetProductsError(error));
                    })
                );
            })
        )
    );

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }
}
