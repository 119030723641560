import { on } from '@ngrx/store';
import * as ToolsFilter from './filter.actions';
import { IEmailBuilderState } from '../../interfaces';

export const toolsFilterReducer = [
    on(ToolsFilter.GetExistingFilters, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilters: {
                        loading: true,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetExistingFiltersSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilters: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetExistingFiltersError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilters: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.ClearExistingFilters, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilters: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetExistingFilterHistory, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilterHistory: {
                        loading: true,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetExistingFilterHistorySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilterHistory: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetExistingFilterHistoryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    existingFilterHistory: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.SaveToolsFilter, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filter: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.SaveToolsFilterSuccess, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filter: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.SaveToolsFilterError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filter: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetDownloadHistory, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    downloadHistory: {
                        loading: true,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetDownloadHistorySuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    downloadHistory: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.GetDownloadHistoryError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    downloadHistory: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.AttachFile, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    attachedFile: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.AttachFileSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    attachedFile: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.AttachFileError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    attachedFile: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.ClearAttachFile, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    attachedFile: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadUsers, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filterUploadUsers: {
                        loading: true,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadUsersSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filterUploadUsers: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadUsersError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filterUploadUsers: {
                        loading: false,
                        data: null,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.ClearRollbackFilterUploadUsers, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filterUploadUsers: {
                        loading: false,
                        data: null,
                    },
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadsByUser, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filtersUploadByUser: {
                        loading: true,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadsByUserSuccess, (state: IEmailBuilderState, action) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filtersUploadByUser: {
                        loading: false,
                        data: action?.payload,
                    }
                }
            }
        };
    }),
    on(ToolsFilter.RollbackFilterUploadsByUserError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filtersUploadByUser: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.ClearRollbackFilterUploadsByUser, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filtersUploadByUser: {
                        loading: false,
                        data: [],
                    }
                }
            }
        };
    }),
    on(ToolsFilter.FilterCounts, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                }
            }
        };
    }),
    on(ToolsFilter.FilterCountsSuccess, (state: IEmailBuilderState, action) => {
        const filterCounts = JSON.parse(JSON.stringify(state?.toolsFilter?.data?.filterCounts));
        Object.keys(action?.payload).map(key => {
            filterCounts[key] = { ...filterCounts[key], ...action?.payload[key] };
        });
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                    filterCounts: filterCounts
                }
            }
        };
    }),
    on(ToolsFilter.FilterCountsError, (state: IEmailBuilderState) => {
        return {
            ...state,
            toolsFilter: {
                loading: false,
                data: {
                    ...state?.toolsFilter?.data,
                }
            }
        };
    }),
];