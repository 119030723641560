import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, getCurrentUser, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, mergeMap, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CdpMeterActions from 'projects/customer-data-platform/src/state/meters/meters.actions';
import { AddedMeterMessageItem, AddTesterResponse, IpAddressExlusionList, MeterDataResponse, MeterLink, MeterMessagesTableResponse, OlyticSiteData, OlyticsSiteMeters, OlyticsSitesResults, ReportData, SaveMeterMessageResponse, SelectedMeterContent } from 'projects/customer-data-platform/src/state/meters/meters.interface';
import { checkUserMeterPermissions } from 'projects/customer-data-platform/src/state/meters/meters.selector';

@Injectable({ providedIn: 'root' })
export class CustomerDataPlatformMetersEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute
    ) { }

    // OLYTICS SITES ACTIONS
    getOlyticsSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetOlyticsSites),
            exhaustMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/olytics-sites?environmentId=${databaseId}`);
                url.searchParams.append('offset', action.payload.offset?.toString() || '0');
                url.searchParams.append('numResults', action.payload.numResults?.toString() || '15');
                url.searchParams.append('sortBy', action.payload.sortBy || '');
                url.searchParams.append('order', action.payload.order || '');
                url.searchParams.append('searchText', action.payload.searchText || '');
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsSitesResults) => {
                            return CdpMeterActions.GetOlyticsSitesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load olytics sites.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetOlyticsSitesError(error));
                        })
                    );
            })
        )
    );

    getOlyticSiteMetersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetOlyticSiteMetersList),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meters?environmentId=${databaseId}`);
                if (action.payload.offset?.toString()) {
                    url.searchParams.append('offset', action.payload.offset?.toString());
                }
                if (action.payload.numResults?.toString()) {
                    url.searchParams.append('numResults', action.payload.numResults?.toString());
                }
                if (action.payload.sortBy) {
                    url.searchParams.append('sortBy', action.payload.sortBy);
                }
                if (action.payload.order) {
                    url.searchParams.append('order', action.payload.order);
                }
                if (action.payload.olyticsSiteId?.toString()) {
                    url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                }
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsSiteMeters) => {
                            return CdpMeterActions.GetOlyticSiteMetersListSuccess({ payload: { response: res, olyticsSiteId: action?.payload?.olyticsSiteId, userMeterPermissions: this.userMeterPermissions } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load meters list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetOlyticSiteMetersListError({ payload: { error, olyticsSiteId: action?.payload?.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    deleteDomain$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.DeleteDomain),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/olytics-site/delete?environmentId=${databaseId}`);
                url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.reloadData) {
                                this.store.dispatch(CdpMeterActions.GetOlyticsSites({ payload: {...action.reloadData, environmentId: databaseId } }));
                            }
                            return CdpMeterActions.DeleteDomainSuccess({ payload: { response: res, action: 'DeleteDomain' } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete domain.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.DeleteDomainError(error));
                        })
                    );
            })
        )
    );

    getOlyticsSiteReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetOlyticsSiteReport),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/olytics-site/report?environmentId=${databaseId}`);
                url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ReportData) => {
                            return CdpMeterActions.GetOlyticsSiteReportSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get olytics site report.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetOlyticsSiteReportError(error));
                        })
                    );
            })
        )
    );

    getOlyticsSiteData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetOlyticsSiteData),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/olytics-site?environmentId=${databaseId}`);
                url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticSiteData) => {
                            return CdpMeterActions.GetOlyticsSiteDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get olytics site data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetOlyticsSiteDataError(error));
                        })
                    );
            })
        )
    );

    createUpdateOlyticsSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.CreateUpdateOlyticsSite),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/olytics-site?environmentId=${databaseId}`);
                url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                return this.http
                    .post(url.toString(), action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return CdpMeterActions.CreateUpdateOlyticsSiteSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create or update a olytics site.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.CreateUpdateOlyticsSiteError(error));
                        })
                    );
            })
        )
    );

    // METERS ACTIONS
    getArchivedMeters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetArchivedMeters),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meters/archived?environmentId=${databaseId}`);
                url.searchParams.append('olyticsSiteId', action.payload.olyticsSiteId?.toString());
                if (action.payload.offset?.toString()) {
                    url.searchParams.append('offset', action.payload.offset?.toString());
                }
                if (action.payload.numResults?.toString()) {
                    url.searchParams.append('numResults', action.payload.numResults?.toString());
                }
                if (action.payload.sortBy) {
                    url.searchParams.append('sortBy', action.payload.sortBy);
                }
                if (action.payload.order) {
                    url.searchParams.append('order', action.payload.order);
                }
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: OlyticsSiteMeters) => {
                            return CdpMeterActions.GetArchivedMetersSuccess({ payload: { response: res, userMeterPermissions: this.userMeterPermissions } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get list of archived meters for olytics site.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetArchivedMetersError(error));
                        })
                    );
            })
        )
    );

    setMeterPriority$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.SetMeterPriority),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/priority?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('direction', action?.payload?.direction);
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return CdpMeterActions.SetMeterPrioritySuccess({ payload: { response: res, action: 'SetMeterPriority', olyticsSiteId: action?.payload?.olyticsSiteId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to change meter priority.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.SetMeterPriorityError({ payload: { error, olyticsSiteId: action?.payload?.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    archiveMeter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.ArchiveMeter),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/archive?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .put(url.toString(), null)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.success && res?.messages && res?.messages?.length) {
                                this.showSuccess(null, res?.messages[0]);
                            }
                            if (action.reloadData) {
                                this.store.dispatch(CdpMeterActions.GetOlyticSiteMetersList({ payload: action.reloadData }));
                            }
                            return CdpMeterActions.ArchiveMeterSuccess({ payload: { response: res, action: 'ArchiveMeter', olyticsSiteId: action?.payload?.olyticsSiteId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to archive meter.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.ArchiveMeterError({ payload: { error, olyticsSiteId: action?.payload?.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    restoreArchivedMeter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.RestoreArchivedMeter),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/restore?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.success && res?.messages && res?.messages?.length && !action?.payload?.getMeterData) {
                                this.showSuccess(null, res?.messages[0]);
                            }
                            if (action?.payload?.getMeterData) {
                                this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: action.payload.contentMeterId } }));
                            }
                            if (action?.reloadData) {
                                this.store.dispatch(CdpMeterActions.GetArchivedMeters({ payload: action?.reloadData }));
                            }
                            return CdpMeterActions.RestoreArchivedMeterSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to restore archived meter.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.RestoreArchivedMeterError(error));
                        })
                    );
            })
        )
    );

    deleteMeter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.DeleteMeter),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/delete?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (action.reloadData) {
                                if (action.reloadArchived) {
                                    this.store.dispatch(CdpMeterActions.GetArchivedMeters({ payload: action.reloadData }));
                                } else {
                                    this.store.dispatch(CdpMeterActions.GetOlyticSiteMetersList({ payload: action.reloadData }));
                                }
                            }
                            return CdpMeterActions.DeleteMeterSuccess({ payload: { response: res, action: 'DeleteMeter', olyticsSiteId: action?.payload?.olyticsSiteId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete meter.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.DeleteMeterError({ payload: { error, olyticsSiteId: action?.payload?.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    cloneMeter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.CloneMeter),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/clone?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.success && res?.messages && res?.messages?.length) {
                                this.showSuccess(null, 'Meter cloned succesfully!');
                            }
                            if (action.reloadData) {
                                this.store.dispatch(CdpMeterActions.GetOlyticSiteMetersList({ payload: action.reloadData }));
                            }
                            return CdpMeterActions.CloneMeterSuccess({ payload: { response: res, action: 'CloneMeter', olyticsSiteId: action?.payload?.olyticsSiteId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to clone meter.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.CloneMeterError({ payload: { error, olyticsSiteId: action?.payload?.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    getMeterReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetMeterReport),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/report?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: ReportData) => {
                            return CdpMeterActions.GetMeterReportSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get meter report.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetMeterReportError(error));
                        })
                    );
            })
        )
    );

    getMeterData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetMeterData),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                // TO DO remove next two rows, when Luc fixes and removes the needing of them
                url.searchParams.append('offset', '0');
                url.searchParams.append('numResults', '15');
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MeterDataResponse) => {
                            return CdpMeterActions.GetMeterDataSuccess({ payload: { response: res, contentMeterId: action.payload.contentMeterId } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get meter data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetMeterDataError(error));
                        })
                    );
            })
        )
    );

    createUpdateMeterData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.CreateUpdateMeterData),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/${action?.payload?.step}?environmentId=${databaseId}`);
                const data = JSON.parse(JSON.stringify(action?.payload));
                const doNotNavigateToNextStep = action?.payload?.doNotNavigateToNextStep;
                delete data?.doNotNavigateToNextStep;
                delete data?.step;
                return this.http
                    .post(url.toString(), data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: {contentMeterId: number}) => {
                            this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: res?.contentMeterId } }));
                            return CdpMeterActions.CreateUpdateMeterDataSuccess({ payload: { ...res, doNotNavigateToNextStep: doNotNavigateToNextStep } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create or update meter data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.CreateUpdateMeterDataError(error));
                        })
                    );
            })
        )
    );

    getMeterMessagesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetMeterMessageContentData),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/meter/messages?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('offset', action.payload.offset?.toString());
                url.searchParams.append('numResults', action.payload.numResults?.toString());
                url.searchParams.append('sortBy', action.payload.sortBy);
                url.searchParams.append('order', action.payload.order);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MeterMessagesTableResponse) => {
                            return CdpMeterActions.GetMeterMessageContentDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load meter messages.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetMeterMessageContentDataError(error));
                        })
                    );
            })
        )
    );

    getMeterSingleMessagesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetMeterSingleMessageContent),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/personalization/content?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                action.payload.personalizedMessageId ? url.searchParams.append('personalizationId', action.payload.personalizedMessageId?.toString()) : null;
                url.searchParams.append('isMeter', action.payload.isMeter?.toString());

                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedMeterContent) => {
                            return CdpMeterActions.GetMeterSingleMessageContentSuccess({ payload: {
                                ...res,
                                personalizedMessageId: action.payload.personalizedMessageId,
                                contentMeterStepId: action.payload?.meteringStepId
                            } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load selected meter message content.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetMeterSingleMessageContentError(error));
                        })
                    );
            })
        )
    );

    cloneMeterMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.CloneMeterMessage),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/meter/clone-message?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('contentMeterStepId', action.payload.contentMeterStepId?.toString());
                return this.http
                    .post(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AddedMeterMessageItem) => {
                            return CdpMeterActions.CloneMeterMessageSuccess({ payload: { response: res, action: 'CloneMeterMessage' } });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to clone selected meter message content.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.CloneMeterMessageError(error));
                        })
                    );
            }
            )
        )
    );

    deleteMeterMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.DeleteMeterMessage),
            exhaustMap((action) => {
                const url = new URL(this.baseUrl + `/meter/delete-message?environmentId=${this.currentDatabaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('contentMeterStepId', action.payload.contentMeterStepId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(CdpMeterActions.GetMeterMessageContentData({ payload: { ...action?.payload?.searchData, contentMeterId: action.payload.contentMeterId } }));
                            return CdpMeterActions.DeleteMeterMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete selected meter message content.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.DeleteMeterMessageError(error));
                        })
                    );
            }
            )
        )
    );

    saveMeterMessageContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.SaveMeterMessageContent),
            exhaustMap((action) => {
                let payload = action.payload;
                if (action.payload?.messageContent) {
                    payload = { ...action.payload, messageContent: encodeURIComponent(action.payload.messageContent) };
                }
                const url = new URL(this.baseUrl + `/personalization/content?environmentId=${this.currentDatabaseId}`);
                return this.http
                    .post(url.toString(), payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SaveMeterMessageResponse) => {
                            this.showSuccess('Success', 'Meter message successfully saved');
                            return CdpMeterActions.SaveMeterMessageContentSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save selected meter message content.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.SaveMeterMessageContentError(error));
                        })
                    );
            }
            )
        )
    );

    deleteOrResetTester$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.DeleteOrResetTester),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/remove-tester?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('contentMeterTesterId', action.payload.contentMeterTesterId?.toString());
                url.searchParams.append('mode', action.payload.mode);
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: action.payload.contentMeterId } }));
                            return CdpMeterActions.DeleteOrResetTesterSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to reset or delete a tester.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.DeleteOrResetTesterError(error));
                        })
                    );
            })
        )
    );

    addTester$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.AddTester),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/add-tester?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .post(url.toString(), action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AddTesterResponse) => {
                            this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: action.payload.contentMeterId } }));
                            return CdpMeterActions.AddTesterSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to add a tester.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.AddTesterError(error));
                        })
                    );
            })
        )
    );

    getMeterHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetMeterHistory),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/history?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('numResults', action.payload.numResults?.toString());
                url.searchParams.append('offset', action.payload.offset?.toString());
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MeterDataResponse) => {
                            return CdpMeterActions.GetMeterHistorySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get meter modification history.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetMeterHistoryError(error));
                        })
                    );
            })
        )
    );

    generateMeterLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GenerateMeterLink),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/test-on-my-site?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                return this.http
                    .post(url.toString(), { siteUrl: action?.payload?.siteUrl }, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MeterLink) => {
                            return CdpMeterActions.GenerateMeterLinkSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to generate test link.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GenerateMeterLinkError(error));
                        })
                    );
            })
        )
    );

    setMeterInTestMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.SetMeterInTestMode),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/test?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('confirmChange', 'true');
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: action.payload.contentMeterId } }));
                            return CdpMeterActions.SetMeterInTestModeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to set meter in Test mode.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.SetMeterInTestModeError(error));
                        })
                    );
            })
        )
    );

    setMeterInActivateMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.SetMeterInActivateMode),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/activate?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('confirmChange', 'true');
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(CdpMeterActions.GetMeterData({ payload: { contentMeterId: action.payload.contentMeterId } }));
                            return CdpMeterActions.SetMeterInActivateModeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to set meter in Activate mode.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.SetMeterInActivateModeError(error));
                        })
                    );
            })
        )
    );

    getIpAddressExclusionList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.GetIpAddressExclusionList),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/ip-address-exclusion-list?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action.payload.contentMeterId?.toString());
                url.searchParams.append('offset', action.payload.offset?.toString());
                url.searchParams.append('numResults', action.payload.numResults?.toString());
                url.searchParams.append('sortBy', action.payload.sortBy);
                url.searchParams.append('order', action.payload.order);
                return this.http
                    .get(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: IpAddressExlusionList) => {
                            return CdpMeterActions.GetIpAddressExclusionListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Ip Addresses Exlusion list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.GetIpAddressExclusionListError(error));
                        })
                    );
            })
        )
    );

    addIpAddressToExclusionList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.AddIpAddressToExclusionList),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/audience?environmentId=${databaseId}`);
                const data = JSON.parse(JSON.stringify(action?.payload));
                const isUploadFile = data.isUpload;
                delete data.isUpload;
                return this.http
                    .post(url.toString(), data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { contentMeterId?: number, duplicateIPList?: Array<string>, doNotNavigateToNextStep?: boolean }) => {
                            if(isUploadFile){
                                if(res.hasOwnProperty('duplicateIPList')){
                                    this.showSuccess('Success', 'File has been successfully uploaded. The following IP addresses were skipped because they were already added: ' + res.duplicateIPList.join('\n'));
                                }else{
                                    this.showSuccess('Success', 'File has been successfully uploaded.');
                                }
                            }
                            return CdpMeterActions.AddIpAddressToExclusionListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to add Ip Address to Exlusion list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.AddIpAddressToExclusionListError(error));
                        })
                    );
            })
        )
    );

    removeIpAddressExclusion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.RemoveIpAddressExclusion),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/remove-ip-address-exclusion?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action?.payload?.contentMeterId?.toString());
                url.searchParams.append('ipAddressRuleId', action?.payload?.ipAddressRuleId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { doNotNavigateToNextStep?: boolean }) => {
                            return CdpMeterActions.RemoveIpAddressExclusionSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to remove Ip Address from exclusions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.RemoveIpAddressExclusionError(error));
                        })
                    );
            })
        )
    );

    removeTargetedAudience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CdpMeterActions.RemoveTargetedAudience),
            mergeMap((action) => {
                const databaseId = action?.payload?.environmentId || this.currentDatabaseId;
                const url = new URL(this.baseUrl + `/meter/remove-targeted-audience?environmentId=${databaseId}`);
                url.searchParams.append('contentMeterId', action?.payload?.contentMeterId?.toString());
                url.searchParams.append('contentMeterAudienceId', action?.payload?.contentMeterAudienceId?.toString());
                return this.http
                    .put(url.toString(), this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return CdpMeterActions.RemoveTargetedAudienceSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to remove targeted audience item.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(CdpMeterActions.RemoveTargetedAudienceError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): number {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute;
        }
        return this.currentDatabase?.id;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }

    get userMeterPermissions() {
        let permissions = null;
        this.store.select(checkUserMeterPermissions)?.pipe(
            take(1),
            map(res => {
                permissions = res;
            })
        )?.subscribe();
        return permissions;
    }
}
