import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as MessageTypesActions from './message-types.actions';

export const messageTypesReducers = [
    on(MessageTypesActions.ClearMessageType, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetSmsBuilderUserPermissions, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                smsBuilderPermissions: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetSmsBuilderUserPermissionsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                smsBuilderPermissions: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetSmsBuilderUserPermissionsError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                smsBuilderPermissions: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageTypesList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypesList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageTypesListSuccess, (state: SelfServiceState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        data?.items?.forEach((item) => {
            if (item?.assignedPhoneNumber) {
                item.formattedNumber = `(${item?.assignedPhoneNumber?.phoneNumber?.slice(2, 5)}) ${item?.assignedPhoneNumber?.phoneNumber?.slice(5, 8)}-${item?.assignedPhoneNumber?.phoneNumber?.slice(8)}`;
            } else {
                item.formattedNumber = 'Unassigned';
            }
        });
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypesList: {
                    loading: false,
                    data: data,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageTypesListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypesList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateMessageType, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateMessageTypeSuccess, (state: SelfServiceState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        if (data?.helpEmail) {
            data.customerSupportContactMethod = 'email';
        }
        if (data?.helpPhone) {
            data.helpPhoneNumber = data?.helpPhone;
            data.customerSupportContactMethod = 'phone';
        }
        if (data?.assignedPhoneNumber && (data?.assignedPhoneNumber?.verificationCode === 1 || data?.assignedPhoneNumber?.verificationCode === 2)) {
            data.disableCampaignDetailFields = true;
        } else {
            data.disableCampaignDetailFields = false;
        }
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: data,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateMessageTypeError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageType, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageTypeSuccess, (state: SelfServiceState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        if (data?.helpEmail) {
            data.customerSupportContactMethod = 'email';
        }
        if (data?.helpPhone) {
            data.helpPhoneNumber = data?.helpPhone;
            data.customerSupportContactMethod = 'phone';
        }
        if (data?.assignedPhoneNumber && (data?.assignedPhoneNumber?.verificationCode === 1 || data?.assignedPhoneNumber?.verificationCode === 2)) {
            data.disableCampaignDetailFields = true;
        } else {
            data.disableCampaignDetailFields = false;
        }
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: data,
                }
            }
        };
    }),
    on(MessageTypesActions.GetMessageTypeError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateMessageType, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: true,
                    data: state?.messageTypesState?.messageTypeData?.data,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateMessageTypeSuccess, (state: SelfServiceState, action) => {
        const data = JSON.parse(JSON.stringify(action?.payload));
        if (data?.helpEmail) {
            data.customerSupportContactMethod = 'email';
        }
        if (data?.helpPhone) {
            data.helpPhoneNumber = data?.helpPhone;
            data.customerSupportContactMethod = 'phone';
        }
        if (data?.assignedPhoneNumber && (data?.assignedPhoneNumber?.verificationCode === 1 || data?.assignedPhoneNumber?.verificationCode === 2)) {
            data.disableCampaignDetailFields = true;
        } else {
            data.disableCampaignDetailFields = false;
        }
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: data,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateMessageTypeError, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                messageTypeData: {
                    loading: false,
                    data: state?.messageTypesState?.messageTypeData?.data,
                    errors: action
                }
            }
        };
    }),
    on(MessageTypesActions.GetUseCasesList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                useCasesList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetUseCasesListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                useCasesList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetUseCasesListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                useCasesList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetVolumeOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                volumeOptionsList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetVolumeOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                volumeOptionsList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetVolumeOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                volumeOptionsList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetOptInOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                optInOptionsList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetOptInOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                optInOptionsList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetOptInOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                optInOptionsList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),

    // Bussiness Profiles
    on(MessageTypesActions.ClearBusinessProfile, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfilesList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfilesList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfilesListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfilesList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfilesListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfilesList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessProfile, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessProfileSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessProfileError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfile, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfileSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessProfileError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessProfile, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessProfileSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessProfileError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessProfile: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessIndustryOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessIndustryOptions: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessIndustryOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessIndustryOptions: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessIndustryOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessIndustryOptions: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessTypeOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessTypeOptions: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessTypeOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessTypeOptions: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessTypeOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessTypeOptions: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetRegionOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                regionOptions: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetRegionOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                regionOptions: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetRegionOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                regionOptions: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),

    // Business Reps
    on(MessageTypesActions.ClearBusinessRep, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRepsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessRepsList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRepsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessRepsList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRepsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessRepsList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessRep, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessRepSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.CreateBusinessRepError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRep, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRepSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetBusinessRepError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessRep, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessRepSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.UpdateBusinessRepError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                businessContact: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetJobTitlesOptionsList, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                jobTitlesOptionsList: {
                    loading: true,
                    data: null,
                }
            }
        };
    }),
    on(MessageTypesActions.GetJobTitlesOptionsListSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                jobTitlesOptionsList: {
                    loading: false,
                    data: action?.payload,
                }
            }
        };
    }),
    on(MessageTypesActions.GetJobTitlesOptionsListError, (state: SelfServiceState) => {
        return {
            ...state,
            messageTypesState: {
                ...state.messageTypesState,
                jobTitlesOptionsList: {
                    loading: false,
                    data: null,
                }
            }
        };
    }),
];