import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppPermissionsEnum, checkUserAppPermissions, getCurrentUser, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { BasicResponse } from 'projects/common/src/public-api';
import { catchError, exhaustMap, map, mergeMap, of, take, tap, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalQueueResponse, ApprovalQueueSplitActions, ApprovalQueueSplitSpamReport, ApprovalQueueSplitsResponse, DataValuesItem, SchedulingPayload } from '../interfaces';
import { SearchRegularDeploymentsError } from '../search/search.actions';
import { ApprovalQueueActionSplit, ApprovalQueueActionSplitError, ApprovalQueueGetDateDropdownOptions, ApprovalQueueGetDateDropdownOptionsSuccess, ApprovalQueueGetDeploymentDetails, ApprovalQueueGetDeploymentDetailsError, ApprovalQueueGetDeploymentDetailsSuccess, ApprovalQueueGetSplitSpamReport, ApprovalQueueGetSplitSpamReportError, ApprovalQueueGetSplitSpamReportSuccess, ApprovalQueueMultipleDeploymentsAction, ApprovalQueueMultipleDeploymentsActionError, ApprovalQueueMultipleDeploymentsActionSuccess, ApprovalQueueScheduleDeployment, ApprovalQueueScheduleDeploymentError, ApprovalQueueScheduleDeploymentSuccess, ApprovalQueueSearchDeployments, SearchApprovalQueueDeploymentsSuccess } from './approval-queue.actions';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class EmailBuilderApprovalQueueEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private router: Router,
    ) { }

    approvalQueueSearchDeployments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueSearchDeployments),
            switchMap((action) => {
                const url = new URL(environment.apiEndPoint + '/email-builder/approval-queue/search');
                if(action.payload.database !=null) {
                    url.searchParams.set('environmentId', action.payload.database.toString());
                }
                if(action.payload.organization !=null) {
                    url.searchParams.set('orgId', action.payload.organization.toString());
                }
                const params = {
                    numResults: action.payload.numResults,
                    offset: action.payload.offset,
                    order: action.payload.order,
                    sortBy: action.payload.sortBy,
                    deploymentDate: action.payload.deploymentDate,
                    nameContains: action.payload.nameContains,
                    trackId: action.payload.trackId,
                    type: action.payload.type,
                    status: action.payload.status,
                    assigned: action.payload.enteredBy,
                    //TODO: fix this once the API is fixed
                    // enteredBy: action.payload.enteredBy,
                    sendAs: action.payload.sendAs
                };
                return this.http.post(url.toString(), { ...params }, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: ApprovalQueueResponse) => {
                        const payload: ResponseWrapper<ApprovalQueueResponse> = { loading: false, data: res };
                        return SearchApprovalQueueDeploymentsSuccess({ payload });
                    }),
                    catchError((error) => {
                        let canApprove = true;
                        this.store.select(checkUserAppPermissions(AppPermissionsEnum.EB_approve_deployment))?.pipe(take(1), map(res => { canApprove = res; })).subscribe();
                        if (error && error.status === 403 && !canApprove) {
                            this.router.navigate(['/email-builder/main/deployments']);
                        }
                        let errorMessage = 'An error has occurred while trying to get regular deployments.';
                        if (error && error.error && error.error.errors && error.error.errors.length) {
                            errorMessage = error.error.errors[0];
                        }
                        this.showError(null, errorMessage);
                        return of(SearchRegularDeploymentsError(error));
                    })
                );
            })
        )
    );

    approvalQueueGetDeploymentDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueGetDeploymentDetails),
            mergeMap((action) => {
                const url = new URL(environment.apiEndPoint + '/email-builder/approval-queue/deployment-splits');
                url.searchParams.set('environmentId', action.payload.databaseId || this.currentDatabase.id);
                url.searchParams.set('id', action.payload.id);

                return this.http.get(url.toString(), this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: ApprovalQueueSplitsResponse) => {
                        const payload: ResponseWrapper<ApprovalQueueSplitsResponse> = {
                            loading: false,
                            data: {
                                ...res,
                                deploymentId: action.payload.id,
                                databaseId: action.payload.databaseId || this.currentDatabase.id
                            }
                        };
                        return ApprovalQueueGetDeploymentDetailsSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to get approval queue deployment details.');
                        return of(ApprovalQueueGetDeploymentDetailsError(error));
                    })
                );
            })
        )
    );
    approvalQueueGetSplitSpamReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueGetSplitSpamReport),
            mergeMap((action) => {
                const url = new URL(environment.apiEndPoint + '/email-builder/approval-queue/split/spam-score');
                url.searchParams.set('environmentId', action.payload.databaseId || this.currentDatabase.id);
                url.searchParams.set('splitId', action.payload.splitId);
                url.searchParams.set('id', action.payload.deploymentId);

                return this.http.get(url.toString(), this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: ApprovalQueueSplitSpamReport) => {
                        // check if res is empty object
                        if (Object.keys(res).length === 0) {
                            return ApprovalQueueGetSplitSpamReportError({ error: {errors: ['There is no spam report'] }});
                        }
                        const payload: ResponseWrapper<ApprovalQueueSplitSpamReport> = {
                            loading: false,
                            data: {
                                ...res
                            }
                        };
                        return ApprovalQueueGetSplitSpamReportSuccess({ payload });
                    }),
                    catchError((error) => {
                        // const errorMsg = error.error?.message || 'An error has occured while trying to get approval queue deployment details.';
                        // this.showError(null, 'An error has occured while trying to get approval queue deployment details.');
                        return of(ApprovalQueueGetSplitSpamReportError(error));
                    })
                );
            })
        )
    );

    approvalQueueActionSplit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueActionSplit),
            mergeMap((action) => {
                const url = new URL(environment.apiEndPoint + '/email-builder/approval-queue/split/action');
                url.searchParams.set('environmentId', action.payload.databaseId || this.currentDatabase.id);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let params: any = {
                    action: action.payload.action,
                    id: action.payload.deploymentId,
                    splitId: action.payload.splitId
                };
                if(action.payload.action === ApprovalQueueSplitActions.APPROVE_ALL) {
                    params = {
                        ...params,
                        approveAll: true
                    };
                }
                return this.http.post(url.toString(), params, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.showSuccess('Success', res?.messages?.[0] || 'Successfully performed action on Split(s).');
                        return ApprovalQueueGetDeploymentDetails({
                            payload: {
                                id: action.payload.deploymentId,
                                databaseId: action.payload.databaseId || this.currentDatabase.id
                            }
                        });
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to approve split.');
                        return of(ApprovalQueueActionSplitError(error));
                    })
                );
            })
        )
    );

    approvalQueueScheduleDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueScheduleDeployment),
            mergeMap((action) => {
                const url = new URL(environment.apiEndPoint + '/email-builder/deployment/schedule-activate');
                url.searchParams.set('environmentId', action.payload.databaseId || this.currentDatabase.id);
                let params: SchedulingPayload;
                if (action.payload.sendNow) {
                    params = {
                        deploymentId: action.payload.deploymentId,
                        sendNow: action.payload.sendNow,
                        rapid:action.payload.rapid
                    };
                } else {
                    params = {
                        deploymentId: action.payload.deploymentId,
                        requestDate: action.payload.requestDate,
                        hour: action.payload.hour,
                        minute: action.payload.minute,
                        ampm: action.payload.ampm,
                        sendNow: action.payload.sendNow,
                        rapid:action.payload.rapid
                    };
                }
                return this.http.post(url.toString(), params, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        if (res?.success) {
                            this.showSuccess('Success', res?.errorMessages?.[0] || 'Deployment has been scheduled successfully.');
                            return ApprovalQueueScheduleDeploymentSuccess({
                                payload: {
                                    loading: false,
                                    data: null
                                }
                            });
                        } else {
                            this.showError(null, `${res?.errors?.join(', ')}`);
                        }
                    }),
                    catchError((error) => {
                        this.showError(null, 'An error has occurred while trying to schedule deployment.');
                        return of(ApprovalQueueScheduleDeploymentError(error));
                    })
                );
            })
        )
    );

    approvalQueueMultipleDeploymentsAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueMultipleDeploymentsAction),
            exhaustMap((action) => {
                const deployments = action.payload.deployments.join(',');
                const message = action.payload.action === 'reload_content' ? 'reload' : `${action.payload.action}`;
                const url = new URL(environment.apiEndPoint + '/email-builder/deployment/multiple-deployments-action');
                url.searchParams.set('environmentId', action.payload.databaseId || this.currentDatabase.id);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const params = {
                    deployments: deployments,
                    action: action.payload.action,
                    requestDate: action.payload.requestDate,
                };
                return this.http.post(url.toString(), params, this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        if (res?.success) {
                            this.showSuccess('Success', `Deployment has been ${message == 'reload' ? 'reloaded' : message+'d'} successfully.`);
                            return ApprovalQueueMultipleDeploymentsActionSuccess({ payload: res });
                        } else {
                            this.showError(null, `${res?.errors[0]}`);
                        }
                    }),
                    catchError((error) => {
                        this.showError(null, `An error has occurred while trying to ${message} deployment.`);
                        return of(ApprovalQueueMultipleDeploymentsActionError(error));
                    })
                );
            })
        )
    );

    approvalQueueGetSplitSpamReportError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueGetSplitSpamReportError),
            tap((action) => {
                const errorMsg = action.error?.errors?.[0] || 'An error has occured while trying to get approval queue deployment details.';
                this.showWarning(null, errorMsg);
            })
        ),
    { dispatch: false }
    );

    approvalQueueGetDateDropdownOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalQueueGetDateDropdownOptions),
            mergeMap(() => {
                const url = new URL(environment.apiEndPoint + '/email-builder/approval-queue/deployment-date-dropdown');
                return this.http.get(url.toString(), this.defaultHttpOptions).pipe(
                    take(1),
                    map((res: Array<DataValuesItem>) => {
                        return ApprovalQueueGetDateDropdownOptionsSuccess({
                            payload: {
                                loading: false,
                                data: res
                            }
                        });
                    }),
                    catchError(() => {
                        this.showError(null, 'An error has occurred while trying to get date dropdown options.');
                        return of();
                        // return of(ApprovalQueueGetDeploymentDetailsError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    get baseUrl() {
        return environment.apiEndPoint + '/email-builder/deployment';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
