import { ResponseWrapper } from 'common';

export const promoCodesInitialState: PromoCodesState = {
    promoCodesList: {
        loading: false,
        data: {
            promoCodesCount: 0,
            promoCodes: []
        }
    },
    selectedPromoCode: {
        loading: false,
        data: {}
    },
    promoCodeProducts: {
        loading: false,
        data: []
    },
    downloadLoader: false,
    promoCodeUploadedData: {
        loading: false,
        data: null
    },
    unsavedChanges: false,
    currentPromoCodeState: null,
    uploaderModalLoader: false,
    clonePromoCodeLoader: false,
    singleCopyIssueList: null,
    productPriceChoices: null
};

export interface PromoCodesState {
    promoCodesList?: ResponseWrapper<PromoCodesRes>;
    selectedPromoCode?: ResponseWrapper<SinglePromoCodeData>;
    promoCodeProducts?: ResponseWrapper<Array<PromoCodeProduct>>;
    downloadLoader?: boolean;
    promoCodeUploadedData?: ResponseWrapper<UploadPromocodesResponse>;
    unsavedChanges?: boolean;
    // TO DO fix the warning at some point
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentPromoCodeState?: any;
    uploaderModalLoader?: boolean;
    clonePromoCodeLoader?: boolean;
    singleCopyIssueList?: SinglePromoCodeData;
    productPriceChoices?: PriceChoice;
}

export interface PromoCodesRes {
    promoCodesCount: number,
    promoCodes: Array<PromoCodeData>
}

export interface PromoCodeData {
    promotionId?: string,
    promoCode?: string,
    description?: string,
    changedBy?: string,
    changedDate?: string,
    status?: string,
    allowBillMe?: boolean,
    allowPayNow?: boolean,
    quantitySent?: number,
    addAllProducts?: boolean,
    products?: Array<string>
    active?: boolean,
    inactive?: boolean,
    new?: boolean,
    htmlcontentClient?: boolean,
    checkBoxesAndSaveVisible?: boolean,
    editableAllActive?: boolean,
    editableAllActivePaid?: boolean,
    notUsedOnOrders?: boolean,
    incomplete?: boolean,
    webType?: boolean,
    paid?: boolean,
    dragonPromo?: boolean,
    paidActive?: boolean,
    editMode?: boolean,
    fieldsDisabled?: boolean
}

export interface SinglePromoCodeData {
    productId?: number,
    promotionId?: string,
    promoCode?: string,
    description?: string,
    createdBy?: string,
    createdDate?: string,
    status?: string,
    allowBillMe?: boolean,
    promoChannel?: number,
    allowPayNow?: boolean,
    autoRenewalCode?: number,
    quantitySent?: number,
    startDate?: string,
    usedOnDragonPages?: number,
    salesChannel?: string,
    addAllProducts?: boolean,
    promoChannels?: Array<PromoChannels>,
    promoTypes?: Array<PromoTypes>,
    autoRenewalCodes?: Array<AutoRenewalCodes>,
    auditTypes?: Array<AuditTypes>,
    salesChannels?: Array<SalesChannels>,
    productGroup?: Array<ProductGroup>,
    premiumCodesByProductId?: Array<PremiumCodeByProductId>,
    premiumCodesMap?: Map<string, Array<PremiumCode>>,
    priceTableCounter?: number,
    productsPricing?: Array<ProductPricing>,
    active?: boolean,
    inactive?: boolean,
    new?: boolean,
    htmlcontentClient?: boolean,
    checkBoxesAndSaveVisible?: boolean,
    editableAllActive?: boolean,
    editableAllActivePaid?: boolean,
    notUsedOnOrders?: boolean,
    incomplete?: boolean,
    webType?: boolean,
    paid?: boolean,
    dragonPromo?: boolean,
    paidActive?: boolean,
    editMode?: boolean,
    fieldsDisabled?: boolean,
    hasCampaignPricing?: boolean,
    isDragonPromo?: boolean,
    endDate?: string,
}

export interface AuditTypes {
    auditType: string,
    description: string
}

export interface PromoChannels {
    promoChannel: number,
    description: string
}

export interface PromoTypes {
    promoType: number,
    description: string
}

export interface SalesChannels {
    salesChannel: string,
    description: string
}

export interface ProductGroup {
    productId: number,
    promotionProductId: number,
    paid: boolean,
    paidDisabled: boolean,
    premium: boolean,
    premiumDisabled: boolean,
    premiumCodeOptions: Array<string>,
    combinedOfferDisabled: boolean,
    showAddRemoveButton: boolean,
    premiumId?: number,
    isCombinedOffer?: boolean,
    combinedProducts?: Array<CombinedProductsItem>,
    isSingleCopyProduct?: boolean,
    paidIssueList?: Array<unknown>,
    freeIssueList?: Array<unknown>,
}

export interface CombinedProductsItem {
    productId?: number,
    isSingleCopyProduct?: boolean,
    premium?: boolean,
    premiumId?: number,
    premiumCodeOptions?: Array<string>,
    premiumDisabled?: boolean,
    promotionProductId?: number,
    paidIssueList?: Array<unknown>,
    freeIssueList?: Array<unknown>,
}

export interface ProductPricing {
    promotionProductId: number,
    pricingType: number,
    productId: number,
    frequency: number,
    frequencyType: string,
    priceGroupId: number,
    priceGroupDescription: string,
    idSuffix: number,
    countries: Array<{ description?: string, value?: string }>,
    requestedVersionTypes: Array<{ description?: string, value?: string }>,
    priceChoices: Array<{ description?: string, value?: string }>
    prices: Array<Price>,
    webType: boolean,
    singleCopyType: boolean,
    editable: boolean,
    combinedOfferPrices?: Array<CombinedOfferPricesItem>,
}

export interface ProductsPricingItem extends Omit<ProductPricing, 'combinedOfferPrices'> {
    parentPromotionProductId?: number;
    defaultProductPriceCodeId?: number;
    issueId?: number;
    combinedOfferPrices?: ProductsPricingItem[];
}

export interface CombinedOfferPricesItem {
    productId?: number,
    prices?: Array<PriceItem>
}

export interface PriceItem {
    term?: number,
    price?: string,
    productPriceChoice?: string,
    priceRowId?: number,
    parentPriceRowId?: number,
    requestedVersionType?: string
}

export interface Price {
    row: number,
    rowSuffix: string,
    term: string,
    productPriceChoice: string,
    location: string,
    locationDescription: string,
    price: string,
    priceRowId: number,
    parentPriceRowId?: number,
    webFormText?: string,
    requestedVersionType?: string,
    defaultProductPriceCodeId?: boolean;
}

export interface AutoRenewalCodes {
    autoRenewalCode: number,
    description: string
}

export interface PromoCodeProduct {
    productId: number,
    productName: string,
    productType: number,
}

export interface SingleCopyIssuesSave {
    promoCode: string,
    products: Array<SingleCopyIssueProduct>
}

export interface SingleCopyIssueProduct {
    productId: number,
    promotionProductId: number,
    paidIssueIdList: Array<number>,
    freeIssueIdList: Array<number>,
}
export interface PriceChoice {
    priceChoiceId: number,
    description: string,
    term: number
}

export interface PremiumCodeByProductId {
    productId: number,
    premiumCodes: Array<PremiumCode>
}
export interface PremiumCode {
    code: number,
    name: string
}

interface PriceRow {
    productPriceChoice?: string;
    term?: string;
    location?: string;
    price?: string;
    requestedVersionType?: string;
    webFormText?: string;
    priceRowId?: string;
    isComboChild?: boolean;
    parentPriceRowId?: string;
    defaultProductPriceCodeId?: boolean;
}

interface CampaignPricingTable {
    productId?: number;
    issueId?: number;
    promotionProductId?: number;
    priceGroupId?: number;
    priceGroupDescription?: string;
    parentPromotionProductId?: number;
    campaignPricingRows?: PriceRow[];
    remove?: boolean;
}

export interface CampaignPricingTables extends Array<CampaignPricingTable> { }

export interface UploadPromocodesResponse {
    errorLog?: Array<string>;
    dupeLog?: Array<string>;
    errorCount?: number;
    dupeCount?: number;
    pricesCreated?: number;
    fileInformation?: Array<string>;
    overwritePayNows?: string;
    overwriteBillMes?: string;
}
