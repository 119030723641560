import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from '@data-loader/app/state/actions';
import { IDataLoaderState } from '@data-loader/app/state/interfaces';
import { DL_WORKING_STATUSES } from '../utils/dl-file-enums';

const initalAppState: IDataLoaderState = {
    currentDatabase: null,
    user: null,
    searchFileCount: {
        loading: false,
        data: -1,
    },
    searchTemplateCount: {
        loading: false,
        data: 0,
    },
    searchFtpSiteCount: {
        loading: false,
        data: 0,
    },
    searchWebhookCount: {
        loading: false,
        data: 0,
    },
    searchFiles: {
        loading: false,
        data: [],
    },
    fileOrigins: {
        loading: false,
        data: null,
    },
    filesPollingState: {},
    fileViewStatuses: {
        loading: false,
        data: null,
    },
    consolidatedStatuses: {
        loading: false,
        data: null,
    },
    templates: {
        loading: false,
        data: null,
    },
    audienceBuilderQueryUrl: {
        loading: false,
        data: null,
    },
    searchTemplates: {
        loading: false,
        data: [],
    },
    fileMappingStatuses: {
        loading: false,
        data: null,
    },
    delimiters: {
        loading: false,
        data: null,
    },
    ftpSites: {
        loading: false,
        data: [],
    },
    ftpSiteCount: {
        loading: false,
        data: -1
    },
    singleFtpSite: {
        loading: false,
        data: null,
    },
    webhooks: {
        loading: false,
        data: [],
    },
    singleWebhook: {
        loading: false,
        data: null,
    },
    searchFtpSites: {
        loading: false,
        data: [],
    },
    ftpSiteStatuses: {
        loading: false,
        data: [],
    },
    ftpProtocolTypes: {
        loading: false,
        data: [],
    },
    ftpSearchTypes: {
        loading: false,
        data: null,
    },
    webhookStatuses: {
        loading: false,
        data: [],
    },
    integrationTypes: {
        loading: false,
        data:  [],
    },
    s3Buckets:{
        loading: false,
        data: [],
    },
    s3BucketStatuses: {
        loading: false,
        data: [],
    },
    singleS3Bucket: {
        loading: false,
        data: null,
    },
    applyMapping: {
        loading: false
    },
    createNewMapping: {
        loading: false
    },
    updateMapping: {
        loading: false
    },
    downloadRowErrors: {
        loading: false,
        data: null,
    },
    verifySingleFileJob: {
        loading: false,
        data: null,
    },
    verifyBulkJob: {
        loading: false,
        data: null,
    },
    bulkApply: {
        loading: false
    },
    jobElapsedTime: {
        loading: false,
        data: null,
    },
    singleTemplate: {
        loading: false,
        data: null,
    },
    singleFile: {
        loading: false,
        data: null,
    },
    updateTemplate: {
        loading: false
    },
    updateSite: {
        loading: false
    },
    updateWebhook: {
        loading: false
    },
    jobQueues: {
        loading: false,
        data: null,
    },
    updateFile: {
        loading: false
    },
    regenerateUrl: {
        loading: false
    },
    searchMailingLists: {
        loading: false,
        data: [],
    },
    updateWebhookMailingList: {
        loading: false
    },
    searchFileNameRelations: {
        loading: false,
        data: null,
    },
    mailingListsWithCriteria: {
        loading: false,
        data: null
    },
    updateMailingListEmail: {
        loading: false
    },
    fileNameRelationStatuses: {
        loading: false,
        data: null,
    },
    updateFileNameRelation: {
        loading: false
    },
    searchFtpDirectoryFiles: {
        loading: false,
        data: [],
    },
    mappingMessages: {
        loading: false,
        data: null,
    },
    fileContents: {
        loading: false,
        data: null,
    },
    fileContentsCount: {
        loading: false,
        data: null
    },
    mappedProducts: {
        loading: false,
        data: [],
    },
    dbFields: {
        loading: false,
        data: null,
    },
    conversionFormats: {
        loading: false,
        data: null,
    },
    earnings: {
        loading: false,
        data: null,
    },
    categories: {
        loading: false,
        data: null,
    },
    externalNamespaces: {
        loading: false,
        data: null,
    },
    behaviors: {
        loading: false,
        data: null,
    },
    behaviorAttributeTypes: {
        loading: false,
        data: null,
    },
    products: {
        loading: false,
        data: null,
    },
    demographics: {
        loading: false,
        data: null,
    },
    updateHeader: {
        loading: false
    },
    validationRules: {
        loading: false,
        data: null,
    },
    saveResponse: {
        loading: false
    },
    fileKeywords: {
        loading: false,
        data: null,
    },
    bulkActionsResponse: {
        loading: false
    },
    processFile: {
        loading: false,
        data: null
    },
    saveFileDetails: {
        loading: false
    },
    saveFileKeywords: {
        loading: false
    },
    cancelFile: {
        loading: false
    },
    pauseFile: {
        loading: false
    },
    fileFilters: {},
    templateFilters: {},
    webhookFilters: {},
    webhookRecordFilters: {},
    ftpFilters: {},
    ftpMailingsFilters: {},
    ftpTrackingsFilters: {},
    ftpDirectoryFilters: {},
    searchS3DirectoryFiles: { loading: false },
    ftpFileStatuses: { loading: false },
    matchedCodes: { loading: false }
};

function getInitalState(): IDataLoaderState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initalAppState;
    // }
}

function getSavedState(): IDataLoaderState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.GetFileOrigins, (state) => {
        return {
            ...state,
            fileOrigins: {
                ...state.fileOrigins,
                loading: true,
            }
        };
    }),
    on(AppActions.GetFileOriginsSuccess, (state, action) => {
        return {
            ...state,
            fileOrigins: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileOriginsError, (state, action) => {
        return {
            ...state,
            fileOrigins: {
                loading: false,
                data: state.fileOrigins?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileStatuses, (state) => {
        return {
            ...state,
            fileStatuses: {
                loading: true,
                data: state.fileStatuses?.data
            }
        };
    }),
    on(AppActions.GetFileStatusesSuccess, (state, action) => {
        return {
            ...state,
            fileStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileStatusesError, (state, action) => {
        return {
            ...state,
            fileStatuses: {
                loading: false,
                data: state.fileStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetViewStatuses, (state) => {
        return {
            ...state,
            fileViewStatuses: {
                ...state.fileViewStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetViewStatusesSuccess, (state, action) => {
        return {
            ...state,
            fileViewStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetViewStatusesError, (state, action) => {
        return {
            ...state,
            fileViewStatuses: {
                loading: false,
                data: state.fileViewStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileListCount, (state) => {
        return {
            ...state,
            searchFileCount: {
                loading: true,
                data: -1
            }
        };
    }),
    on(AppActions.GetFileListCountSuccess, (state, action) => {
        return {
            ...state,
            searchFileCount: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileListCountError, (state, action) => {
        return {
            ...state,
            searchFileCount: {
                loading: false,
                data: state.searchFileCount?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileList, (state) => {
        return {
            ...state,
            searchFiles: {
                loading: true,
                data: state.searchFiles?.data
            }
        };
    }),
    on(AppActions.GetFileListSuccess, (state, action) => {
        return {
            ...state,
            searchFiles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileListError, (state, action) => {
        return {
            ...state,
            searchFiles: {
                loading: false,
                data: state.searchFiles?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileContents, (state) => {
        return {
            ...state,
            fileContents: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFileContentsSuccess, (state, action) => {
        return {
            ...state,
            fileContents: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileContentsError, (state, action) => {
        return {
            ...state,
            fileContents: {
                loading: false,
                data: state.fileContents?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileContentsCount, state => {
        return {
            ...state,
            fileContentsCount: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFileContentsCountSuccess, (state, action) => {
        return {
            ...state,
            fileContentsCount: {
                loading: false,
                data: action.payload.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileContentsCountError, state => {
        return {
            ...state,
            fileContentsCount: {
                loading: false,
                data: null,
                errors: true
            }
        };
    }),

    on(AppActions.GetFileMappingFieldValues, (state) => {
        return {
            ...state,
            fileValues: {
                loading: true,
                data: state.fileValues?.data
            }
        };
    }),
    on(AppActions.GetFileMappingFieldValuesSuccess, (state, action) => {
        return {
            ...state,
            fileValues: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileMappingFieldValuesError, (state, action) => {
        return {
            ...state,
            fileValues: {
                loading: false,
                data: state.fileValues?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileKeywords, (state) => {
        return {
            ...state,
            fileKeywords: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFileKeywordsSuccess, (state, action) => {
        return {
            ...state,
            fileKeywords: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileKeywordsError, (state, action) => {
        return {
            ...state,
            fileKeywords: {
                loading: false,
                data: state.fileKeywords?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetSingleDataFile, (state) => {
        return {
            ...state,
            singleFile: {
                loading: true,
                data: state.singleFile?.dataFile
            }
        };
    }),
    on(AppActions.GetSingleDataFileSuccess, (state, action) => {
        return {
            ...state,
            singleFile: {
                loading: false,
                data: action.payload?.dataFile,
                success: true
            }
        };
    }),
    on(AppActions.GetSingleDataFileError, (state, action) => {
        return {
            ...state,
            singleFile: {
                loading: false,
                data: state.singleFile?.dataFile,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetAudienceBuilderQueryUrl, (state) => {
        return {
            ...state,
            audienceBuilderQueryUrl: {
                loading: true,
                data: state.audienceBuilderQueryUrl?.data
            }
        };
    }),
    on(AppActions.GetAudienceBuilderQueryUrlSuccess, (state, action) => {
        return {
            ...state,
            audienceBuilderQueryUrl: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetAudienceBuilderQueryUrlError, (state, action) => {
        return {
            ...state,
            audienceBuilderQueryUrl: {
                loading: false,
                data: state.audienceBuilderQueryUrl?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.DownloadRowErrors, (state) => {
        return {
            ...state,
            downloadRowErrors: {
                loading: true
            }
        };
    }),
    on(AppActions.DownloadRowErrorsSuccess, (state, action) => {
        return {
            ...state,
            downloadRowErrors: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.DownloadRowErrorsError, (state, action) => {
        return {
            ...state,
            downloadRowErrors: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetAvailableValidationRules, (state) => {
        return {
            ...state,
            validationRules: {
                ...state.validationRules,
                loading: true
            }
        };
    }),
    on(AppActions.GetAvailableValidationRulesSuccess, (state, action) => {
        return {
            ...state,
            validationRules: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetAvailableValidationRulesError, (state, action) => {
        return {
            ...state,
            validationRules: {
                loading: false,
                data: state.validationRules?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetApplyToProductsCodes, (state) => {
        return {
            ...state,
            applyToProductsCodes: {
                ...state.applyToProductsCodes,
                loading: true
            }
        };
    }),
    on(AppActions.GetApplyToProductsCodesSuccess, (state, action) => {
        return {
            ...state,
            applyToProductsCodes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetApplyToProductsCodesError, (state, action) => {
        return {
            ...state,
            applyToProductsCodes: {
                loading: false,
                data: state.applyToProductsCodes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileMappingStatuses, (state) => {
        return {
            ...state,
            fileMappingStatuses: {
                ...state.fileMappingStatuses,
                loading: true
            }
        };
    }),
    on(AppActions.GetFileMappingStatusesSuccess, (state, action) => {
        return {
            ...state,
            fileMappingStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileMappingStatusesError, (state, action) => {
        return {
            ...state,
            fileMappingStatuses: {
                loading: false,
                data: state.fileMappingStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetTemplateValidationRules, (state) => {
        return {
            ...state,
            templateValidationRules: {
                ...state.templateValidationRules,
                loading: true,
            }
        };
    }),
    on(AppActions.GetTemplateValidationRulesSuccess, (state, action) => {
        return {
            ...state,
            templateValidationRules: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetTemplateValidationRulesError, (state, action) => {
        return {
            ...state,
            templateValidationRules: {
                loading: false,
                data: state.templateValidationRules?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetTemplatePages, (state) => {
        return {
            ...state,
            templatePages: {
                loading: true,
                data: state.templatePages?.data
            }
        };
    }),
    on(AppActions.GetTemplatePagesSuccess, (state, action) => {
        return {
            ...state,
            templatePages: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetTemplatePagesError, (state, action) => {
        return {
            ...state,
            templatePages: {
                loading: false,
                data: state.templatePages?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetTemplates, (state) => {
        return {
            ...state,
            templates: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetTemplatesSuccess, (state, action) => {
        return {
            ...state,
            templates: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetTemplatesError, (state, action) => {
        return {
            ...state,
            templates: {
                loading: false,
                data: null,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetTemplate, (state) => {
        return {
            ...state,
            singleTemplate: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetTemplateSuccess, (state, action) => {
        return {
            ...state,
            singleTemplate: {
                loading: false,
                success: true,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetTemplateError, (state, action) => {
        return {
            ...state,
            singleTemplate: {
                loading: false,
                data: state.singleTemplate?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetCategories, (state) => {
        return {
            ...state,
            categories: {
                ...state.categories,
                loading: true
            }
        };
    }),
    on(AppActions.GetCategoriesSuccess, (state, action) => {
        return {
            ...state,
            categories: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetCategoriesError, (state, action) => {
        return {
            ...state,
            categories: {
                loading: false,
                data: state.categories?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDemoValueMappingForField, (state) => {
        return {
            ...state,
            demoValueMappings: {
                loading: true,
                data: state.demoValueMappings?.data
            }
        };
    }),
    on(AppActions.GetDemoValueMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            demoValueMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDemoValueMappingForFieldError, (state, action) => {
        return {
            ...state,
            demoValueMappings: {
                loading: false,
                data: state.demoValueMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDeploymentTypeMappingForField, (state) => {
        return {
            ...state,
            deploymentTypeMappingForField: {
                loading: true,
                data: state.deploymentTypeMappingForField?.data
            }
        };
    }),
    on(AppActions.GetDeploymentTypeMappingForFieldSuccesss, (state, action) => {
        return {
            ...state,
            deploymentTypeMappingForField: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDeploymentTypeMappingForFieldError, (state, action) => {
        return {
            ...state,
            deploymentTypeMappingForField: {
                loading: false,
                data: state.deploymentTypeMappingForField?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDeploymentTypeMappingForFileMapping, (state) => {
        return {
            ...state,
            deploymentTypeMappings: {
                loading: true,
                data: state.deploymentTypeMappings?.data
            }
        };
    }),
    on(AppActions.getDeploymentTypeMappingForFileMappingSuccess, (state, action) => {
        return {
            ...state,
            deploymentTypeMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDeploymentTypeMappingForFileMappingError, (state, action) => {
        return {
            ...state,
            deploymentTypeMappings: {
                loading: false,
                data: state.deploymentTypeMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorMappingForField, (state) => {
        return {
            ...state,
            behaviorMappings: {
                loading: true,
                data: state.behaviorMappings?.data
            }
        };
    }),
    on(AppActions.GetBehaviorMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            behaviorMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorMappingForFieldError, (state, action) => {
        return {
            ...state,
            behaviorMappings: {
                loading: false,
                data: state.behaviorMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorMappingForFileMapping, (state) => {
        return {
            ...state,
            behaviorMappings: {
                loading: true,
                data: state.behaviorMappings?.data
            }
        };
    }),
    on(AppActions.GetBehaviorMappingForFileMappingSuccess, (state, action) => {
        return {
            ...state,
            behaviorMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorMappingForFileMappingError, (state, action) => {
        return {
            ...state,
            behaviorMappings: {
                loading: false,
                data: state.behaviorMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorAttributeValueMappingForField, (state) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: true,
                data: state.behaviorAttributeValueMappings?.data
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValueMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValueMappingForFieldError, (state, action) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: false,
                data: state.behaviorAttributeValueMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorAttributeValueMappingForFileMapping, (state) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: true,
                data: state.behaviorAttributeValueMappings?.data
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValueMappingForFileMappingSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValueMappingForFileMappingError, (state, action) => {
        return {
            ...state,
            behaviorAttributeValueMappings: {
                loading: false,
                data: state.behaviorAttributeValueMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetProductClassMappingForField, (state) => {
        return {
            ...state,
            productMappings: {
                loading: true,
                data: state.productMappings?.data
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFieldSuccess, (state) => {
        return {
            ...state,
            productMappings: {
                loading: false,
                data: state.productMappings?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFieldError, (state, action) => {
        return {
            ...state,
            productMappings: {
                loading: false,
                data: state.productMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetProductClassMappingForFileMapping, (state) => {
        return {
            ...state,
            productMappings: {
                loading: true,
                data: state.productMappings?.data
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFileMappingSuccess, (state, action) => {
        return {
            ...state,
            productMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFileMappingError, (state, action) => {
        return {
            ...state,
            productMappings: {
                loading: false,
                data: state.productMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetProductForField, (state) => {
        return {
            ...state,
            productForField: {
                loading: true,
                data: state.productForField?.data
            }
        };
    }),
    on(AppActions.GetProductForFieldSuccess, (state, action) => {
        return {
            ...state,
            productForField: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductForFieldError, (state, action) => {
        return {
            ...state,
            productForField: {
                loading: false,
                data: state.productForField?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMessageTypeMappingForField, (state) => {
        return {
            ...state,
            messageTypeMappingForField: {
                loading: true,
                data: state.messageTypeMappingForField?.data
            }
        };
    }),
    on(AppActions.GetMessageTypeMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            messageTypeMappingForField: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMessageTypeMappingForFieldError, (state, action) => {
        return {
            ...state,
            messageTypeMappingForField: {
                loading: false,
                data: state.messageTypeMappingForField?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMessageTypeMappingForFileMapping, (state) => {
        return {
            ...state,
            messageTypeMappings: {
                loading: true,
                data: state.messageTypeMappings?.data
            }
        };
    }),
    on(AppActions.getMessageTypeMappingForFileMappingSuccess, (state, action) => {
        return {
            ...state,
            messageTypeMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMessageTypeMappingForFileMappingError, (state, action) => {
        return {
            ...state,
            messageTypeMappings: {
                loading: false,
                data: state.messageTypeMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetVersionMappingForField, (state) => {
        return {
            ...state,
            versionMappingForField: {
                loading: true,
                data: state.versionMappingForField?.data
            }
        };
    }),
    on(AppActions.GetVersionMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            versionMappingForField: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetVersionMappingForFieldError, (state, action) => {
        return {
            ...state,
            versionMappingForField: {
                loading: false,
                data: state.versionMappingForField?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDLProcessingRules, (state) => {
        return {
            ...state,
            processingRules: {
                loading: true,
                data: state.processingRules?.data
            }
        };
    }),
    on(AppActions.GetDLProcessingRulesSuccess, (state, action) => {
        return {
            ...state,
            processingRules: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDLProcessingRulesError, (state, action) => {
        return {
            ...state,
            processingRules: {
                loading: false,
                data: state.processingRules?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMappedProducts, (state) => {
        return {
            ...state,
            mappedProducts: {
                loading: true,
                data: state.mappedProducts?.data
            }
        };
    }),
    on(AppActions.GetMappedProductsSuccess, (state, action) => {
        return {
            ...state,
            mappedProducts: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMappedProductsError, (state, action) => {
        return {
            ...state,
            mappedProducts: {
                loading: false,
                data: state.mappedProducts?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMappingTemplateCount, (state) => {
        return {
            ...state,
            searchFileCount: {
                loading: true,
                data: -1
            }
        };
    }),
    on(AppActions.GetMappingTemplateCountSuccess, (state, action) => {
        return {
            ...state,
            searchFileCount: {
                loading: false,
                data: action.payload.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMappingTemplateCountError, (state, action) => {
        return {
            ...state,
            searchFileCount: {
                loading: false,
                data: state.searchFileCount?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMappingTemplates, (state) => {
        return {
            ...state,
            searchTemplates: {
                loading: true,
                data: []
            }
        };
    }),
    on(AppActions.GetMappingTemplatesSuccess, (state, action) => {
        return {
            ...state,
            searchTemplates: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMappingTemplatesError, (state, action) => {
        return {
            ...state,
            searchTemplates: {
                loading: false,
                data: state.searchTemplates?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileMappingMessages, (state) => {
        return {
            ...state,
            mappingMessages: {
                loading: true,
                data: state.mappingMessages?.data
            }
        };
    }),
    on(AppActions.GetFileMappingMessagesSuccess, (state, action) => {
        return {
            ...state,
            mappingMessages: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileMappingMessagesError, (state, action) => {
        return {
            ...state,
            mappingMessages: {
                loading: false,
                data: state.mappingMessages?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetSourcePriorityMappingForField, (state) => {
        return {
            ...state,
            sourcePriorities: {
                loading: true,
                data: state.sourcePriorities?.data
            }
        };
    }),
    on(AppActions.GetSourcePriorityMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            sourcePriorities: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetSourcePriorityMappingForFieldError, (state, action) => {
        return {
            ...state,
            sourcePriorities: {
                loading: false,
                data: state.sourcePriorities?.data,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetTermMappingForField, (state) => {
        return {
            ...state,
            termMappings: {
                loading: true,
                data: state.termMappings?.data
            }
        };
    }),
    on(AppActions.GetTermMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            termMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetTermMappingForFieldError, (state, action) => {
        return {
            ...state,
            termMappings: {
                loading: false,
                data: state.termMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetUnits, (state) => {
        return {
            ...state,
            units: {
                loading: true,
                data: state.units?.data
            }
        };
    }),
    on(AppActions.GetUnitsSuccess, (state, action) => {
        return {
            ...state,
            units: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetUnitsError, (state, action) => {
        return {
            ...state,
            units: {
                loading: false,
                data: state.units?.data,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetPaymentTypeMappingForField, (state) => {
        return {
            ...state,
            paymentTypeMappings: {
                loading: true,
                data: state.paymentTypeMappings?.data
            }
        };
    }),
    on(AppActions.GetPaymentTypeMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            paymentTypeMappings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetPaymentTypeMappingForFieldError, (state, action) => {
        return {
            ...state,
            paymentTypeMappings: {
                loading: false,
                data: state.paymentTypeMappings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpSiteStatuses, (state) => {
        return {
            ...state,
            ftpSiteStatuses: {
                ...state.ftpSiteStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetFtpSiteStatusesSuccess, (state, action) => {
        return {
            ...state,
            ftpSiteStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpSiteStatusesError, (state, action) => {
        return {
            ...state,
            ftpSiteStatuses: {
                loading: false,
                data: state.ftpSiteStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileNameRelationStatuses, (state) => {
        return {
            ...state,
            fileNameRelationStatuses: {
                ...state.fileNameRelationStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetFileNameRelationStatusesSuccess, (state, action) => {
        return {
            ...state,
            fileNameRelationStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileNameRelationStatusesError, (state, action) => {
        return {
            ...state,
            fileNameRelationStatuses: {
                loading: false,
                data: state.fileNameRelationStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpProtocolTypes, (state) => {
        return {
            ...state,
            ftpProtocolTypes: {
                loading: true,
                data: state.ftpProtocolTypes?.data
            }
        };
    }),
    on(AppActions.GetFtpProtocolTypesSuccess, (state, action) => {
        return {
            ...state,
            ftpProtocolTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpProtocolTypesError, (state, action) => {
        return {
            ...state,
            ftpProtocolTypes: {
                loading: false,
                data: state.ftpProtocolTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpSearchTypes, (state) => {
        return {
            ...state,
            ftpSearchTypes: {
                ...state.ftpSearchTypes,
                loading: true,
            }
        };
    }),
    on(AppActions.GetFtpSearchTypesSuccess, (state, action) => {
        return {
            ...state,
            ftpSearchTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpSearchTypesError, (state, action) => {
        return {
            ...state,
            ftpSearchTypes: {
                loading: false,
                data: state.ftpSearchTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpFileStatuses, (state) => {
        return {
            ...state,
            ftpFileStatuses: {
                ...state.ftpFileStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetFtpFileStatusesSuccess, (state, action) => {
        return {
            ...state,
            ftpFileStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpFileStatusesError, (state, action) => {
        return {
            ...state,
            ftpFileStatuses: {
                loading: false,
                data: state.ftpFileStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpSites,
        AppActions.GetFtpSiteList,
        (state) => {
            return {
                ...state,
                ftpSites: {
                    loading: true,
                    data: state.ftpSites?.data
                }
            };
        }),
    on(AppActions.GetFtpSitesSuccess,
        AppActions.GetFtpSiteListSuccess,
        (state, action) => {
            return {
                ...state,
                ftpSites: {
                    loading: false,
                    data: action.payload?.data,
                    success: true
                }
            };
        }),
    on(AppActions.GetFtpSitesError,
        AppActions.GetFtpSiteListError,
        (state, action) => {
            return {
                ...state,
                ftpSites: {
                    loading: false,
                    data: state.ftpSites?.data,
                    errors: action.error
                }
            };
        }),

    on(AppActions.GetFtpSiteListCount, (state) => {
        return {
            ...state,
            ftpSiteCount: {
                loading: true,
                data: -1
            }
        };
    }),
    on(AppActions.GetFtpSiteListCountSuccess, (state, action) => {
        return {
            ...state,
            ftpSiteCount: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpSiteListCountError, (state, action) => {
        return {
            ...state,
            ftpSiteCount: {
                loading: false,
                data: -1,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpSite, (state) => {
        return {
            ...state,
            singleFtpSite: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetFtpSiteSuccess, (state, action) => {
        return {
            ...state,
            singleFtpSite: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpSiteError, (state, action) => {
        return {
            ...state,
            singleFtpSite: {
                loading: false,
                data: state.singleFtpSite?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileNameRelationsWithCriteria, (state) => {
        return {
            ...state,
            searchFileNameRelations: {
                loading: true,
                data: state.searchFileNameRelations?.data
            }
        };
    }),
    on(AppActions.GetFileNameRelationsWithCriteriaSuccess, (state, action) => {
        return {
            ...state,
            searchFileNameRelations: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileNameRelationsWithCriteriaError, (state, action) => {
        return {
            ...state,
            searchFileNameRelations: {
                loading: false,
                data: state.searchFileNameRelations?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFileNameRelationsCount, (state) => {
        return {
            ...state,
            fileNameRelationsCount: {
                loading: true,
                data: state.fileNameRelationsCount?.data
            }
        };
    }),
    on(AppActions.GetFileNameRelationsCountSuccess, (state, action) => {
        return {
            ...state,
            fileNameRelationsCount: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileNameRelationsCountError, (state, action) => {
        return {
            ...state,
            fileNameRelationsCount: {
                loading: false,
                data: state.fileNameRelationsCount?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMailingListsWithCriteriaForSite, (state) => {
        return {
            ...state,
            mailingListsWithCriteria: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetMailingListsWithCriteriaForSiteSuccess, (state, action) => {
        return {
            ...state,
            mailingListsWithCriteria: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMailingListsWithCriteriaForSiteError, (state, action) => {
        return {
            ...state,
            mailingListsWithCriteria: {
                loading: false,
                data: state.mailingListsWithCriteria?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetFtpDirectoryFilesWithCriteria, (state) => {
        return {
            ...state,
            searchFtpFiles: {
                loading: true,
                data: state.searchFtpFiles?.data
            }
        };
    }),
    on(AppActions.GetFtpDirectoryFilesWithCriteriaSuccess, (state, action) => {
        return {
            ...state,
            searchFtpFiles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFtpDirectoryFilesWithCriteriaError, (state, action) => {
        return {
            ...state,
            searchFtpFiles: {
                loading: false,
                data: state.searchFtpFiles?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetWebhookStatuses, (state) => {
        return {
            ...state,
            webhookStatuses: {
                ...state.webhookStatuses,
                loading: true
            }
        };
    }),
    on(AppActions.GetWebhookStatusesSuccess, (state, action) => {
        return {
            ...state,
            webhookStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetWebhookStatusesError, (state, action) => {
        return {
            ...state,
            webhookStatuses: {
                loading: false,
                data: [],
                errors: action.error
            }
        };
    }),

    on(AppActions.GetIntegrationTypes, (state) => {
        return {
            ...state,
            integrationTypes: {
                loading: true,
                data: state.integrationTypes?.data
            }
        };
    }),
    on(AppActions.GetIntegrationTypesSuccess, (state, action) => {
        return {
            ...state,
            integrationTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetIntegrationTypesError, (state, action) => {
        return {
            ...state,
            integrationTypes: {
                loading: false,
                data: state.integrationTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetWebhooks,
        AppActions.GetWebhookList,
        (state) => {
            return {
                ...state,
                webhooks: {
                    loading: true,
                    data: []
                }
            };
        }),
    on(AppActions.GetWebhooksSuccess,
        AppActions.GetWebhookListSuccess,
        (state, action) => {
            return {
                ...state,
                webhooks: {
                    loading: false,
                    data: action.payload?.data,
                    success: true
                }
            };
        }),
    on(AppActions.GetWebhooksError,
        AppActions.GetWebhookListError,
        (state, action) => {
            return {
                ...state,
                webhooks: {
                    loading: false,
                    data: [],
                    errors: action.error
                }
            };
        }),

    on(AppActions.GetWebhookListCount, (state) => {
        return {
            ...state,
            searchWebhookCount: {
                loading: true,
                data: 0
            }
        };
    }),
    on(AppActions.GetWebhookListCountSuccess, (state, action) => {
        return {
            ...state,
            searchWebhookCount: {
                loading: false,
                data: action.payload.data,
                success: true
            }
        };
    }),
    on(AppActions.GetWebhookListCountError, (state, action) => {
        return {
            ...state,
            searchWebhookCount: {
                loading: false,
                data: 0,
                errors: action
            }
        };
    }),

    on(AppActions.GetWebhook, (state) => {
        return {
            ...state,
            singleWebhook: {
                loading: true,
                data: state.singleWebhook?.data
            }
        };
    }),
    on(AppActions.GetWebhookSuccess, (state, action) => {
        return {
            ...state,
            singleWebhook: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetWebhookError, (state, action) => {
        return {
            ...state,
            singleWebhook: {
                loading: false,
                data: state.singleWebhook?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetS3Buckets,
        AppActions.GetS3BucketsList,
        (state) => {
            return {
                ...state,
                s3Buckets: {
                    loading: true,
                    data: state.s3Buckets?.data
                }
            };
        }),
    on(AppActions.GetS3BucketsListSuccess,
        AppActions.GetS3BucketsListSuccess,
        (state, action) => {
            return {
                ...state,
                s3Buckets: {
                    loading: false,
                    data: action.payload?.data,
                    success: true
                }
            };
        }),
    on(AppActions.GetS3BucketsListError,
        AppActions.GetS3BucketsListError,
        (state, action) => {
            return {
                ...state,
                s3Buckets: {
                    loading: false,
                    data: state.s3Buckets?.data,
                    errors: action.error
                }
            };
        }),

    on(AppActions.GetS3BucketStatuses, (state) => {
        return {
            ...state,
            s3BucketStatuses: {
                ...state.s3BucketStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetS3BucketStatusesSuccess, (state, action) => {
        return {
            ...state,
            s3BucketStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetS3BucketStatusesError, (state, action) => {
        return {
            ...state,
            s3BucketStatuses: {
                loading: false,
                data: state.s3BucketStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetS3BucketListCount, (state) => {
        return {
            ...state,
            s3BucketCount: {
                loading: true,
                data: -1
            }
        };
    }),
    on(AppActions.GetS3BucketListCountSuccess, (state, action) => {
        return {
            ...state,
            s3BucketCount: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetS3BucketListCountError, (state, action) => {
        return {
            ...state,
            s3BucketCount: {
                loading: false,
                data: -1,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetS3Bucket, (state) => {
        return {
            ...state,
            singleS3Bucket: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetS3BucketSuccess, (state, action) => {
        return {
            ...state,
            singleS3Bucket: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetS3BucketError, (state, action) => {
        return {
            ...state,
            singleS3Bucket: {
                loading: false,
                data: state.singleS3Bucket?.data,
                errors: action.error
            }
        };
    }),
    on(AppActions.UpdateBucket, (state) => {
        return {
            ...state,
            updateBucket: {
                loading: true
            }
        };
    }),
    on(AppActions.UpdateBucketSuccess, (state) => {
        return {
            ...state,
            updateBucket: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.UpdateBucketError, (state, action) => {
        return {
            ...state,
            updateBucket: {
                loading: false,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetFileNameRelationsWithCriteriaForBucket, (state) => {
        return {
            ...state,
            searchFileNameRelationsForBucket: {
                loading: true,
                data: state.searchFileNameRelationsForBucket?.data
            }
        };
    }),
    on(AppActions.GetFileNameRelationsWithCriteriaForBucketSuccess, (state, action) => {
        return {
            ...state,
            searchFileNameRelationsForBucket: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetFileNameRelationsWithCriteriaForBucketError, (state, action) => {
        return {
            ...state,
            searchFileNameRelationsForBucket: {
                loading: false,
                data: state.searchFileNameRelationsForBucket?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMailingListsWithCriteriaForBucket, (state) => {
        return {
            ...state,
            mailingListsWithCriteriaForBucket: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetMailingListsWithCriteriaForBucketSuccess, (state, action) => {
        return {
            ...state,
            mailingListsWithCriteriaForBucket: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMailingListsWithCriteriaForBucketError, (state) => {
        return {
            ...state,
            mailingListsWithCriteriaForBucket: {
                loading: false,
                data: state.mailingListsWithCriteriaForBucket?.data,
            }
        };
    }),

    on(AppActions.GetS3DirectoryFilesWithCriteria, (state) => {
        return {
            ...state,
            searchS3DirectoryFiles: {
                loading: true,
                data: state.searchS3DirectoryFiles?.data
            }
        };
    }),

    on(AppActions.GetS3DirectoryFilesWithCriteriaSuccess, (state, action) => {
        return {
            ...state,
            searchS3DirectoryFiles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),

    on(AppActions.GetS3DirectoryFilesWithCriteriaError, (state, action) => {
        return {
            ...state,
            searchS3DirectoryFiles: {
                loading: false,
                data: state.searchS3DirectoryFiles?.data,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetRegions, (state) => {
        return {
            ...state,
            regions: {
                loading: true,
                data: state.regions?.data
            }
        };
    }),

    on(AppActions.GetRegionsSuccess, (state, action) => {
        return {
            ...state,
            regions: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),

    on(AppActions.GetRegionsError, (state, action) => {
        return {
            ...state,
            regions: {
                loading: false,
                data: state.regions?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetJobs, (state) => {
        return {
            ...state,
            jobQueues: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetJobsSuccess, (state, action) => {
        return {
            ...state,
            jobQueues: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetJobsError, (state, action) => {
        return {
            ...state,
            jobQueues: {
                loading: false,
                data: state.jobQueues?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetElapsedTime, (state) => {
        return {
            ...state,
            jobElapsedTime: {
                loading: true,
                data: state.jobElapsedTime?.data
            }
        };
    }),
    on(AppActions.GetElapsedTimeSuccess, (state, action) => {
        return {
            ...state,
            jobElapsedTime: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetElapsedTimeError, (state, action) => {
        return {
            ...state,
            jobElapsedTime: {
                loading: false,
                data: state.jobElapsedTime?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.VerifySingleFileJob, (state) => {
        return {
            ...state,
            verifySingleFileJob: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.VerifySingleFileJobSuccess, (state, action) => {
        return {
            ...state,
            verifySingleFileJob: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.VerifySingleFileJobError, (state, action) => {
        return {
            ...state,
            verifySingleFileJob: {
                loading: false,
                data: state.verifySingleFileJob?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.VerifyBulkJob, (state) => {
        return {
            ...state,
            verifyBulkJob: {
                loading: true,
                data: state.verifyBulkJob?.data
            }
        };
    }),
    on(AppActions.VerifyBulkJobSuccess, (state, action) => {
        return {
            ...state,
            verifyBulkJob: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.VerifyBulkJobError, (state, action) => {
        return {
            ...state,
            verifyBulkJob: {
                loading: false,
                data: state.verifyBulkJob?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.BulkApply, (state) => {
        return {
            ...state,
            bulkApply: {
                loading: true
            }
        };
    }),
    on(AppActions.BulkApplySuccess, (state) => {
        return {
            ...state,
            bulkApply: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.BulkApplyError, (state, action) => {
        return {
            ...state,
            bulkApply: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetConsolidatedStatuses, (state) => {
        return {
            ...state,
            consolidatedStatuses: {
                ...state.consolidatedStatuses,
                loading: true,
            }
        };
    }),
    on(AppActions.GetConsolidatedStatusesSuccess, (state, action) => {
        return {
            ...state,
            consolidatedStatuses: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetConsolidatedStatusesError, (state, action) => {
        return {
            ...state,
            consolidatedStatuses: {
                loading: false,
                data: state.consolidatedStatuses?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetMatchedCodes, (state) => {
        return {
            ...state,
            matchedCodes: {
                ...state.matchedCodes,
                loading: true,
            }
        };
    }),
    on(AppActions.GetMatchedCodesSuccess, (state, action) => {
        return {
            ...state,
            matchedCodes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetMatchedCodesError, (state, action) => {
        return {
            ...state,
            matchedCodes: {
                loading: false,
                data: state.matchedCodes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDelimiters, (state) => {
        return {
            ...state,
            delimiters: {
                ...state.delimiters,
                loading: true
            }
        };
    }),
    on(AppActions.GetDelimitersSuccess, (state, action) => {
        return {
            ...state,
            delimiters: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDelimitersError, (state, action) => {
        return {
            ...state,
            delimiters: {
                loading: false,
                data: state.delimiters?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetAvailableDbFields, (state) => {
        return {
            ...state,
            dbFields: {
                ...state.dbFields,
                loading: true
            }
        };
    }),
    on(AppActions.GetAvailableDbFieldsSuccess, (state, action) => {
        return {
            ...state,
            dbFields: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetAvailableDbFieldsError, (state, action) => {
        return {
            ...state,
            dbFields: {
                loading: false,
                data: state.dbFields?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetConversionFormats, (state) => {
        return {
            ...state,
            conversionFormats: {
                ...state.conversionFormats,
                loading: true
            }
        };
    }),
    on(AppActions.GetConversionFormatsSuccess, (state, action) => {
        return {
            ...state,
            conversionFormats: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetConversionFormatsError, (state, action) => {
        return {
            ...state,
            conversionFormats: {
                loading: false,
                data: state.conversionFormats?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetEarnings, (state) => {
        return {
            ...state,
            earnings: {
                ...state.earnings,
                loading: true
            }
        };
    }),
    on(AppActions.GetEarningsSuccess, (state, action) => {
        return {
            ...state,
            earnings: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetEarningsError, (state, action) => {
        return {
            ...state,
            earnings: {
                loading: false,
                data: state.earnings?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDataSources, (state) => {
        return {
            ...state,
            dataSources: {
                ...state.dataSources,
                loading: true
            }
        };
    }),
    on(AppActions.GetDataSourcesSuccess, (state, action) => {
        return {
            ...state,
            dataSources: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDataSourcesError, (state, action) => {
        return {
            ...state,
            dataSources: {
                loading: false,
                data: state.dataSources?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetExternalNamespaces, (state) => {
        return {
            ...state,
            externalNamespaces: {
                loading: true,
                data: state.externalNamespaces?.data
            }
        };
    }),
    on(AppActions.GetExternalNamespacesSuccess, (state, action) => {
        return {
            ...state,
            externalNamespaces: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetExternalNamespacesError, (state, action) => {
        return {
            ...state,
            externalNamespaces: {
                loading: false,
                data: state.externalNamespaces?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDemographics, (state) => {
        return {
            ...state,
            demographics: {
                loading: true,
                data: state.demographics?.data
            }
        };
    }),
    on(AppActions.GetDemographicsSuccess, (state, action) => {
        return {
            ...state,
            demographics: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDemographicsError, (state, action) => {
        return {
            ...state,
            demographics: {
                loading: false,
                data: state.demographics?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDemographicValues, (state) => {
        return {
            ...state,
            demographicValues: {
                loading: true,
                data: state.demographicValues?.data
            }
        };
    }),
    on(AppActions.GetDemographicValuesSuccess, (state, action) => {
        return {
            ...state,
            demographicValues: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDemographicValuesError, (state, action) => {
        return {
            ...state,
            demographicValues: {
                loading: false,
                data: state.demographicValues?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetDeploymentTypesForDL, (state) => {
        return {
            ...state,
            deploymentTypesForDL: {
                loading: true,
                data: state.deploymentTypesForDL?.data
            }
        };
    }),
    on(AppActions.GetDeploymentTypesForDLSuccess, (state, action) => {
        return {
            ...state,
            deploymentTypesForDL: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetDeploymentTypesForDLError, (state, action) => {
        return {
            ...state,
            deploymentTypesForDL: {
                loading: false,
                data: state.deploymentTypesForDL?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetProducts, (state) => {
        return {
            ...state,
            products: {
                loading: true,
                data: state.products?.data
            }
        };
    }),
    on(AppActions.GetProductsSuccess, (state, action) => {
        return {
            ...state,
            products: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductsError, (state, action) => {
        return {
            ...state,
            products: {
                loading: false,
                data: state.products?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetClassDefinitions, (state) => {
        return {
            ...state,
            classDefinitions: {
                loading: true,
                data: state.classDefinitions?.data
            }
        };
    }),
    on(AppActions.GetClassDefinitionsSuccess, (state, action) => {
        return {
            ...state,
            classDefinitions: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetClassDefinitionsError, (state, action) => {
        return {
            ...state,
            classDefinitions: {
                loading: false,
                data: state.classDefinitions?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBpaTypes, (state) => {
        return {
            ...state,
            bpaTypes: {
                ...state.bpaTypes,
                loading: true
            }
        };
    }),
    on(AppActions.GetBpaTypesSuccess, (state, action) => {
        return {
            ...state,
            bpaTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBpaTypesError, (state, action) => {
        return {
            ...state,
            bpaTypes: {
                loading: false,
                data: state.bpaTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetSalesChannels, (state) => {
        return {
            ...state,
            salesChannels: {
                loading: true,
                data: state.salesChannels?.data
            }
        };
    }),
    on(AppActions.GetSalesChannelsSuccess, (state, action) => {
        return {
            ...state,
            salesChannels: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetSalesChannelsError, (state, action) => {
        return {
            ...state,
            salesChannels: {
                loading: false,
                data: state.salesChannels?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviors, (state) => {
        return {
            ...state,
            behaviors: {
                loading: true,
                data: state.behaviors?.data
            }
        };
    }),
    on(AppActions.GetBehaviorsSuccess, (state, action) => {
        return {
            ...state,
            behaviors: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorsError, (state, action) => {
        return {
            ...state,
            behaviors: {
                loading: false,
                data: state.behaviors?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorAttributeTypes, (state) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                ...state.behaviorAttributeTypes,
                loading: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeTypesSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeTypesError, (state, action) => {
        return {
            ...state,
            behaviorAttributeTypes: {
                loading: false,
                data: state.behaviorAttributeTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetBehaviorAttributeValues, (state) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: true,
                data: state.behaviorAttributeValues?.data
            }
        };
    }),

    on(AppActions.GetBehaviorAttributeValuesSuccess, (state, action) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetBehaviorAttributeValuesError, (state, action) => {
        return {
            ...state,
            behaviorAttributeValues: {
                loading: false,
                data: state.behaviorAttributeValues?.data,
                errors: action.error
            }
        };
    }),
    on(AppActions.GetDLMessageType, (state) => {
        return {
            ...state,
            messageTypes: {
                loading: true,
                data: state.messageTypes?.data
            }
        };
    }),

    on(AppActions.GetDLMessageTypeSuccess, (state, action) => {
        const sortedData = [...action.payload.data];
        sortedData.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return {
            ...state,
            messageTypes: {
                loading: false,
                data: sortedData,
                success: true
            }
        };
    }),
    on(AppActions.GetDLMessageTypeError, (state, action) => {
        return {
            ...state,
            messageTypes: {
                loading: false,
                data: state.messageTypes?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.ApplyTemplate, (state) => {
        return {
            ...state,
            applyMapping: {
                loading: true,
            }
        };
    }),

    on(AppActions.ApplyTemplateSuccess, (state) => {
        return {
            ...state,
            applyMapping: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.ApplyTemplateError, (state, action) => {
        return {
            ...state,
            applyMapping: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.CreateNewMapping, (state) => {
        return {
            ...state,
            createNewMapping: {
                loading: true,
            }
        };
    }),
    on(AppActions.CreateNewMappingSuccess, (state) => {
        return {
            ...state,
            createNewMapping: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.CreateNewMappingError, (state, action) => {
        return {
            ...state,
            createNewMapping: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.CloneMapping,
        AppActions.RemoveMapping,
        AppActions.UpdateStatusCode,
        (state) => {
            return {
                ...state,
                updateMapping: {
                    loading: true
                }
            };
        }),
    on(AppActions.CloneMappingSuccess,
        AppActions.RemoveMappingSuccess,
        AppActions.UpdateStatusCodeSuccess,
        (state) => {
            return {
                ...state,
                updateMapping: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.CloneMappingError,
        AppActions.RemoveMappingError,
        AppActions.UpdateStatusCodeError,
        (state, action) => {
            return {
                ...state,
                cloneMapping: {
                    loading: false,
                    success: false,
                    errors: action.error
                }
            };
        }),
    on(AppActions.UpdateTemplate, (state) => {
        return {
            ...state,
            updateTemplate: {
                loading: true
            }
        };
    }),
    on(AppActions.UpdateTemplateSuccess, (state) => {
        return {
            ...state,
            updateTemplate: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.UpdateTemplateError, (state, action) => {
        return {
            ...state,
            updateTemplate: {
                loading: false,
                errors: action.error
            }
        };
    }),
    on(AppActions.UpdateSite, (state) => {
        return {
            ...state,
            updateSite: {
                loading: true
            }
        };
    }),
    on(AppActions.UpdateSiteSuccess, (state) => {
        return {
            ...state,
            updateSite: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.UpdateSiteError, (state, action) => {
        return {
            ...state,
            updateSite: {
                loading: false,
                errors: action.error
            }
        };
    }),
    on(AppActions.UpdateWebhook,
        AppActions.DeleteWebhook,
        (state) => {
            return {
                ...state,
                updateWebhook: {
                    loading: true
                }
            };
        }),
    on(AppActions.UpdateWebhookSuccess,
        AppActions.DeleteWebhookSuccess,
        (state) => {
            return {
                ...state,
                updateWebhook: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.UpdateWebhookError,
        AppActions.DeleteWebhookError,
        (state, action) => {
            return {
                ...state,
                updateWebhook: {
                    loading: false,
                    errors: action.error
                }
            };
        }),

    on(AppActions.UploadFile,
        AppActions.DeleteFile,
        AppActions.ArchiveFile,
        AppActions.PreprocessFile,
        AppActions.RemoveFtpFile,
        (state) => {
            return {
                ...state,
                updateFile: {
                    loading: true,
                }
            };
        }),

    on(AppActions.UploadFileSuccess,
        AppActions.DeleteFileSuccess,
        AppActions.ArchiveFileSuccess,
        AppActions.PreprocessFileSuccess,
        AppActions.RemoveFtpFileSuccess,
        (state) => {
            return {
                ...state,
                updateFile: {
                    loading: false,
                    success: true,
                }
            };
        }),

    on(AppActions.UploadFileError,
        AppActions.DeleteFileError,
        AppActions.ArchiveFileError,
        AppActions.PreprocessFileError,
        AppActions.RemoveFtpFileError,
        (state, action) => {
            return {
                ...state,
                updateFile: {
                    loading: false,
                    errors: action.error
                }
            };
        }),
    on(AppActions.RegenerateUrl, (state) => {
        return {
            ...state,
            regenerateUrl: {
                loading: true
            }
        };
    }),
    on(AppActions.RegenerateUrlSuccess, (state) => {
        return {
            ...state,
            regenerateUrl: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.RegenerateUrlError, (state, action) => {
        return {
            ...state,
            regenerateUrl: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.GetWebhookMailingList, (state) => {
        return {
            ...state,
            searchMailingLists: {
                loading: true,
                data: []
            }
        };
    }),
    on(AppActions.GetWebhookMailingListSuccess, (state, action) => {
        return {
            ...state,
            searchMailingLists: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetWebhookMailingListError, (state, action) => {
        return {
            ...state,
            searchMailingLists: {
                loading: false,
                data: state.searchMailingLists?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.RemoveWebhookMailingList,
        AppActions.CreateWebhookMailingList,
        AppActions.UpdateWebhookMailingList,
        (state) => {
            return {
                ...state,
                updateWebhookMailingList: {
                    loading: true
                }
            };
        }),
    on(AppActions.RemoveWebhookMailingListSuccess,
        AppActions.CreateWebhookMailingListSuccess,
        AppActions.UpdateWebhookMailingListSuccess,
        (state) => {
            return {
                ...state,
                updateWebhookMailingList: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.RemoveWebhookMailingListError,
        AppActions.CreateWebhookMailingListError,
        AppActions.UpdateWebhookMailingListError,
        (state, action) => {
            return {
                ...state,
                updateWebhookMailingList: {
                    loading: false,
                    errors: action.error
                }
            };
        }),

    on(AppActions.RemoveMailingListEmail,
        AppActions.CreateMailingListEmail,
        AppActions.UpdateMailingListEmail,
        AppActions.RemoveMailingListEmailForBucket,
        AppActions.CreateMailingListEmailForBucket,
        AppActions.UpdateMailingListEmailForBucket,
        (state) => {
            return {
                ...state,
                updateMailingListEmail: {
                    loading: true
                }
            };
        }),
    on(AppActions.RemoveMailingListEmailSuccess,
        AppActions.CreateMailingListEmailSuccess,
        AppActions.UpdateMailingListEmailSuccess,
        AppActions.RemoveMailingListEmailForBucketSuccess,
        AppActions.CreateMailingListEmailForBucketSuccess,
        AppActions.UpdateMailingListEmailForBucketSuccess,
        (state) => {
            return {
                ...state,
                updateMailingListEmail: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.RemoveMailingListEmailError,
        AppActions.CreateMailingListEmailError,
        AppActions.UpdateMailingListEmailError,
        AppActions.RemoveMailingListEmailForBucketError,
        AppActions.CreateMailingListEmailForBucketError,
        AppActions.UpdateMailingListEmailForBucketError,
        (state, action) => {
            return {
                ...state,
                updateMailingListEmail: {
                    loading: false,
                    errors: action.error
                }
            };
        }),
    on(AppActions.UpdateFileNameRelation,
        AppActions.CreateFileNameRelation,
        AppActions.RemoveFileNameRelation,
        AppActions.UpdateFileNameRelationForBucket,
        AppActions.CreateFileNameRelationForBucket,
        AppActions.RemoveFileNameRelationForBucket,
        (state) => {
            return {
                ...state,
                updateFileNameRelation: {
                    loading: true,
                }
            };
        }),
    on(AppActions.UpdateFileNameRelationSuccess,
        AppActions.CreateFileNameRelationSuccess,
        AppActions.RemoveFileNameRelationSuccess,
        AppActions.UpdateFileNameRelationForBucketSuccess,
        AppActions.CreateFileNameRelationForBucketSuccess,
        AppActions.RemoveFileNameRelationForBucketSuccess,
        (state) => {
            return {
                ...state,
                updateFileNameRelation: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.UpdateFileNameRelationError,
        AppActions.CreateFileNameRelationError,
        AppActions.RemoveFileNameRelationError,
        AppActions.UpdateFileNameRelationForBucketError,
        AppActions.CreateFileNameRelationForBucketError,
        AppActions.RemoveFileNameRelationForBucketError,
        (state, action) => {
            return {
                ...state,
                updateFileNameRelation: {
                    loading: false,
                    errors: action.error
                }
            };
        }),

    on(AppActions.GetFtpDirectoryFilesWithCriteria, (state) => {
        return {
            ...state,
            searchFtpDirectoryFiles: {
                loading: true,
                data: state.searchFtpDirectoryFiles?.data
            }
        };
    }),

    on(AppActions.GetFtpDirectoryFilesWithCriteriaSuccess, (state, action) => {
        return {
            ...state,
            searchFtpDirectoryFiles: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),

    on(AppActions.GetFtpDirectoryFilesWithCriteriaError, (state, action) => {
        return {
            ...state,
            searchFtpDirectoryFiles: {
                loading: false,
                data: state.searchFtpDirectoryFiles?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.UpdateHeader, (state) => {
        return {
            ...state,
            updateHeader: {
                loading: true
            }
        };
    }),
    on(AppActions.UpdateHeaderSuccess, (state) => {
        return {
            ...state,
            updateHeader: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.UpdateHeaderError, (state, action) => {
        return {
            ...state,
            updateHeader: {
                loading: false,
                errors: action.error
            }
        };
    }),

    on(AppActions.SetIsMappingLocked, (state, action) => {
        return {
            ...state,
            isMappingLocked: {
                data: action.payload,
                loading: false
            }
        };
    }),

    /* Clears */
    on(AppActions.ClearDownloadRowErrors, (state) => {
        return {
            ...state,
            downloadRowErrors: {
                data: null,
                loading: false,
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFieldSuccess, (state, action) => {
        return {
            ...state,
            productClassMappingsForField: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetProductClassMappingForFieldError, (state, action) => {
        return {
            ...state,
            productClassMappingsForField: {
                loading: false,
                data: state.productClassMappingsForField?.errors,
                errors: action.error
            }
        };

    }),
    on(AppActions.SaveProductClassMappings,
        AppActions.SaveDemoMappings,
        AppActions.SaveDeploymentTypeMappings,
        AppActions.SaveBehaviorMappings,
        AppActions.SaveBehaviorAttributeValueMappings,
        AppActions.SaveMessageTypeMappings,
        AppActions.SaveMappingSettings,
        AppActions.SaveContactTypeMappings,
        AppActions.SaveHeaderMappings,
        AppActions.SaveValidationRules,
        AppActions.SaveSourcePriorityMappings,
        AppActions.SaveVersionMappings,
        AppActions.SaveTermMappings,
        AppActions.SavePaymentTypeMappings,
        (state) => {
            return {
                ...state,
                saveResponse: {
                    loading: true          }
            };
        }),
    on(AppActions.SaveProductClassMappingsSuccess,
        AppActions.SaveDemoMappingsSuccess,
        AppActions.SaveDeploymentTypeMappingsSuccess,
        AppActions.SaveBehaviorMappingsSuccess,
        AppActions.SaveBehaviorAttributeValueMappingsSuccess,
        AppActions.SaveMessageTypeMappingsSuccess,
        AppActions.SaveMappingSettingsSuccess,
        AppActions.SaveContactTypeMappingsSuccess,
        AppActions.SaveHeaderMappingsSuccess,
        AppActions.SaveValidationRulesSuccess,
        AppActions.SaveSourcePriorityMappingsSuccess,
        AppActions.SaveBehaviorMappingsSuccess,
        AppActions.SaveVersionMappingsSuccess,
        AppActions.SaveTermMappingsSuccess,
        AppActions.SavePaymentTypeMappingsSuccess,
        (state) => {
            return {
                ...state,
                saveResponse: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.SaveProductClassMappingsError,
        AppActions.SaveDemoMappingsError,
        AppActions.SaveDeploymentTypeMappingsError,
        AppActions.SaveBehaviorMappingsError,
        AppActions.SaveBehaviorAttributeValueMappingsError,
        AppActions.SaveMessageTypeMappingsError,
        AppActions.SaveMappingSettingsError,
        AppActions.SaveContactTypeMappingsError,
        AppActions.SaveHeaderMappingsError,
        AppActions.SaveValidationRulesError,
        AppActions.SaveSourcePriorityMappingsError,
        AppActions.SaveBehaviorMappingsError,
        AppActions.SaveVersionMappingsError,
        AppActions.SaveTermMappingsError,
        AppActions.SavePaymentTypeMappingsError,
        (state, action) => {
            return {
                ...state,
                saveResponse: {
                    loading: false,
                    errors: action.error
                }
            };
        }),

    on(AppActions.GetAvailableMatchingRules, (state) => {
        return {
            ...state,
            matchingRules: {
                ...state.matchingRules,
                loading: true
            }
        };
    }),
    on(AppActions.GetAvailableMatchingRulesSuccess, (state, action) => {
        return {
            ...state,
            matchingRules: {
                loading: false,
                data: action.payload?.data,
                success: true
            }
        };
    }),
    on(AppActions.GetAvailableMatchingRulesError, (state, action) => {
        return {
            ...state,
            matchingRules: {
                loading: false,
                data: state.matchingRules?.data,
                errors: action.error
            }
        };
    }),

    on(AppActions.ClearStoreValue, (state, action) => {
        const storeVal: string = action.payload.storeValue;
        const initVal = initalAppState[storeVal];
        return {
            ...state,
            [storeVal]: initVal
        };
    }),
    on(AppActions.BulkArchiveRestoreFiles,
        AppActions.BulkDeleteFiles,
        AppActions.BulkProcessFiles,
        (state) => {
            return {
                ...state,
                bulkActionsResponse: {
                    loading: true
                }
            };
        }),
    on(AppActions.BulkArchiveRestoreFilesSuccess,
        AppActions.BulkDeleteFilesSuccess,
        AppActions.BulkProcessFilesSuccess,
        (state) => {
            return {
                ...state,
                bulkActionsResponse: {
                    loading: false,
                    success: true
                }
            };
        }),
    on(AppActions.BulkArchiveRestoreFilesError,
        AppActions.BulkDeleteFilesError,
        AppActions.BulkProcessFilesError,
        (state, action) => {
            return {
                ...state,
                bulkActionsResponse: {
                    loading: false,
                    errors: action.error
                }
            };
        }),

    on(AppActions.SaveFileDetails, (state) => {
        return {
            ...state,
            saveFileDetails: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveFileDetailsSuccess, (state) => {
        return {
            ...state,
            saveFileDetails: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveFileDetailsError, (state) => {
        return {
            ...state,
            saveFileDetails: {
                loading: false,
                errors: true
            }
        };
    }),

    on(AppActions.SaveFileKeywords, state => {
        return {
            ...state,
            saveFileKeywords: {
                loading: true
            }
        };
    }),
    on(AppActions.SaveFileKeywordsSuccess, state => {
        return {
            ...state,
            saveFileKeywords: {
                loading: false,
                success: true
            }
        };
    }),
    on(AppActions.SaveFileKeywordsError, state => {
        return {
            ...state,
            saveFileKeywords: {
                loading: false,
                errors: true
            }
        };
    }),

    on(AppActions.CancelFile, state => {
        return {
            ...state,
            cancelFile: {
                loading: true
            }
        };
    }),
    on(AppActions.CancelFileSuccess, (state, action) => {
        return {
            ...state,
            cancelFile: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.CancelFileError, state => {
        return {
            ...state,
            cancelFile: {
                loading: false,
                errors: true
            }
        };
    }),

    on(AppActions.PauseFile, state => {
        return {
            ...state,
            pauseFile: {
                loading: true
            }
        };
    }),
    on(AppActions.PauseFileSuccess, (state, action) => {
        return {
            ...state,
            pauseFile: {
                loading: false,
                success: true,
                data: action.payload
            }
        };
    }),
    on(AppActions.PauseFileError, state => {
        return {
            ...state,
            pauseFile: {
                loading: false,
                errors: true
            }
        };
    }),
    on(AppActions.StoreFileSearchFilters, (state, action) => {
        return {
            ...state,
            fileFilters: {
                ...state.fileFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreTemplateSearchFilters, (state, action) => {
        return {
            ...state,
            templateFilters: {
                ...state.templateFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreWebhookFilters, (state, action) => {
        return {
            ...state,
            webhookFilters: {
                ...state.webhookFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreWebhookRecordFilters, (state, action) => {
        return {
            ...state,
            webhookRecordFilters: {
                ...state.webhookRecordFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreFtpFilters, (state, action) => {
        return {
            ...state,
            ftpFilters: {
                ...state.ftpFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreFtpMailingsFilters, (state, action) => {
        return {
            ...state,
            ftpMailingsFilters: {
                ...state.ftpMailingsFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreFtpTrackingsFilters, (state, action) => {
        return {
            ...state,
            ftpTrackingsFilters: {
                ...state.ftpTrackingsFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreFtpDirectoryFilters, (state, action) => {
        return {
            ...state,
            ftpDirectoryFilters: {
                ...state.ftpDirectoryFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreProductFilters, (state, action) => {
        return {
            ...state,
            productFilters: {
                ...state.productFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreDeploymentFilters, (state, action) => {
        return {
            ...state,
            deploymentFilters: {
                ...state.deploymentFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreDemographicFilters, (state, action) => {
        return {
            ...state,
            demographicFilters: {
                ...state.demographicFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreBehaviorFilters, (state, action) => {
        return {
            ...state,
            behaviorFilters: {
                ...state.behaviorFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreBehaviorAttributeFilters, (state, action) => {
        return {
            ...state,
            behaviorAttributeFilters: {
                ...state.behaviorAttributeFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreGeneralFilters, (state, action) => {
        return {
            ...state,
            generalFilters: {
                ...state.generalFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreValidationFilters, (state, action) => {
        return {
            ...state,
            validationFilters: {
                ...state.validationFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.StoreProcessingFilters, (state, action) => {
        return {
            ...state,
            processingFilters: {
                ...state.processingFilters,
                ...(action.payload)
            }
        };
    }),
    on(AppActions.GetFilesForPolling, (state, action) => {
        return {
            ...state,
            filesPollingState: action.payload.fileIds.reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            }, {})
        };
    }),
    on(AppActions.GetFilesForPollingSuccess, (state, action) => {
        const finishedFiles = action.payload.filter((file) => {
            return !DL_WORKING_STATUSES.includes(file.fileStatus.statusCode);
        });
        let searchFiles = state.searchFiles;
        if (finishedFiles.length > 0) {
            searchFiles = { ...state.searchFiles };
            searchFiles.data = searchFiles.data.map((file) => {
                const finishedFile = finishedFiles.find((f) => f.fileId === file.fileId);
                if (finishedFile) {
                    return {
                        ...file,
                        processedDate: finishedFile.processedDate,
                        consolidatedStatus: finishedFile.consolidatedStatus,
                        fileStatus: finishedFile.fileStatus,
                        fileMapping: finishedFile.fileMapping
                    };
                }
                return file;
            });
        }
        return {
            ...state,
            filesPollingState: {},
            searchFiles
        };
    }),
);

export function dataLoaderReducer(state: IDataLoaderState | undefined, action: Action) {
    return appReducer(state, action);
}
