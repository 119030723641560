import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as AlternateIdsActions from './alternate-ids.actions';

export const alternateIdsReducer = [
    on(AlternateIdsActions.LoadAlternateIdsData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),
    on(AlternateIdsActions.LoadAlternateIdsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(AlternateIdsActions.LoadAlternateIdsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(AlternateIdsActions.DownloadAlternateIds, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsDownloadLoader: true
            }
        };
    }),

    on(AlternateIdsActions.DownloadAlternateIdsSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsDownloadLoader: false
            }
        };
    }),

    on(AlternateIdsActions.DownloadAlternateIdsError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                alternateIdsDownloadLoader: false
            }
        };
    }),

    on(AlternateIdsActions.GetSelectedAlternateId, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(AlternateIdsActions.GetSelectedAlternateIdSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(AlternateIdsActions.GetSelectedAlternateIdError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(AlternateIdsActions.SaveAlternateId, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: state.selfServiceAlternateIdsState.selectedAlternateId.data,
                    loading: true
                }
            }
        };
    }),

    on(AlternateIdsActions.SaveAlternateIdSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: state.selfServiceAlternateIdsState.selectedAlternateId.data,
                    loading: false
                }
            }
        };
    }),

    on(AlternateIdsActions.SaveAlternateIdError, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: state.selfServiceAlternateIdsState.selectedAlternateId.data,
                    loading: false
                }
            }
        };
    }),

    on(AlternateIdsActions.ClearAlternateIdData, (state: SelfServiceState) => {
        return {
            ...state,
            selfServiceAlternateIdsState: {
                ...state.selfServiceAlternateIdsState,
                selectedAlternateId: {
                    data: null,
                    loading: false
                }
            }
        };
    })
];
