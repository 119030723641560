import { ResponseWrapper } from 'common';

export const alternateIdsInitialState: AlternateIdsState = {
    alternateIdsData: {
        loading: false,
        data: null
    },
    alternateIdsDownloadLoader: false,
    selectedAlternateId: {
        loading: false,
        data: null
    }
};

export interface AlternateIdsState {
    alternateIdsData: ResponseWrapper<AlternateIdsRes>;
    alternateIdsDownloadLoader: boolean;
    selectedAlternateId: ResponseWrapper<AlternateId>
}

export interface AlternateIdsRes {
    totalResultCount: number;
    items: Array<AlternateId>;
}

export interface AlternateId {
    customerExternalNamespaceId: number,
    namespace: string,
    description: string,
    editableInAudienceSearch: string,
    type: string,
    createdBy?: string,
    profiles?: Array<number>,
    allProfiles?: boolean,
}

export interface AlternateIdReqPayload {
    namespace: string,
    description: string,
    type: string,
    editableInAudienceSearch: boolean,
    customerExternalNamespaceId: number
}
