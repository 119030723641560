import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BasicResponse, getCurrentUser, ResponseWrapper, ShellStore, ShowToast } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsMetaEventsManagerActions from '@integrations/state/meta-events-manager/meta-events-manager.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FacebookPixelAudience, FacebookPixelEventDetail, FacebookPixelEventSchema, FacebookPixelsSite, GetSitePixelsData, OlyticsSite, SitesRes } from '@integrations/state/meta-events-manager/meta-events-manager.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsMetaEventsManagerEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore
    ) { }

    getMetaEventsSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.GetMetaEventsSites),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                action.payload?.offset ? searchParams.set('offset', action.payload?.offset?.toString()) : null;
                action.payload?.numResult ? searchParams.set('numResults', action.payload?.numResult?.toString()) : null;
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('order', action.payload?.order || '');
                searchParams.set('isArchived', action.payload?.isArchived?.toString() || 'false');

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/site?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: SitesRes) => {
                            const payload: ResponseWrapper<SitesRes> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.GetMetaEventsSitesSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get Meta Events Manager Sites.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.GetMetaEventsSitesError(error));
                        })
                    );
            })
        )
    );

    getMetaEventsPreviousPixels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixels),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/previously-used-pixels?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: Array<string>) => {
                            const payload: ResponseWrapper<Array<string>> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixelsSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get Meta Events Manager Previous Pixels.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.GetMetaEventsPreviousPixelsError(error));
                        })
                    );
            })
        )
    );

    createSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.CreateSite),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/site/save?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: OlyticsSite) => {
                            const payload: ResponseWrapper<OlyticsSite> = {
                                loading: false,
                                data: res,
                            };
                            this.store.dispatch(IntegrationsMetaEventsManagerActions.GetMetaEventsSites({ payload: { offset: 0, numResult: 15 } }));
                            return IntegrationsMetaEventsManagerActions.CreateSiteSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to create site.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.CreateSiteError(error));
                        })
                    );
            })
        )
    );

    viewSiteReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.ViewSiteReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/site/view-report?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelsSite) => {
                            const payload: ResponseWrapper<FacebookPixelsSite> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.ViewSiteReportSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to view site report.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.ViewSiteReportError(error));
                        })
                    );
            })
        )
    );

    updatePixelStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.UpdatePixelStatus),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookPixelSegmentId', action.payload.facebookPixelSegmentId.toString());
                searchParams.set('action', action.payload.action);
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-event/update-status?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<BasicResponse> = {
                                loading: false,
                                data: res,
                            };
                            if (action.payload?.olyticsSiteId) {
                                if (action?.payload?.isArchived) {
                                    this.store.dispatch(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixels({ payload: { olyticsSiteId: action.payload.olyticsSiteId } }));
                                } else {
                                    this.store.dispatch(IntegrationsMetaEventsManagerActions.GetSitePixels({ payload: { olyticsSiteId: action.payload.olyticsSiteId, isArchived: action.payload?.isArchived } }));
                                }
                            } else {
                                this.store.dispatch(IntegrationsMetaEventsManagerActions.LoadFacebookPixel({ payload: { facebookPixelSegmentId: action.payload?.facebookPixelSegmentId } }));
                            }
                            return IntegrationsMetaEventsManagerActions.UpdatePixelStatusSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to restore archived pixel.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.UpdatePixelStatusError(error));
                        })
                    );
            })
        )
    );

    loadFacebookPixel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.LoadFacebookPixel),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookPixelSegmentId', action.payload.facebookPixelSegmentId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-event?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelEventDetail) => {
                            const payload: ResponseWrapper<FacebookPixelEventDetail> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.LoadFacebookPixelSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to load Facebook Pixel.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.LoadFacebookPixelError(error));
                        })
                    );
            })
        )
    );

    addAbQueryToPixelEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEvent),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookPixelSegmentId', action.payload.facebookPixelSegmentId.toString());
                searchParams.set('selectionCriteriaId', action.payload.selectionCriteriaId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-audience/add?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelAudience) => {
                            res = { ...res, ...action.payload };
                            const payload: ResponseWrapper<FacebookPixelAudience> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEventSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to add AB Query to Pixel.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.AddAbQueryToPixelEventError(error));
                        })
                    );
            })
        )
    );

    viewPixelEventReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.ViewPixelEventReport),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookPixelSegmentId', action.payload.facebookPixelSegmentId.toString());
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-event/view-report?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelsSite) => {
                            const payload: ResponseWrapper<FacebookPixelsSite> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.ViewPixelEventReportSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to view Pixel Event Report.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.ViewPixelEventReportError(error));
                        })
                    );
            })
        )
    );

    getSitePixels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.GetSitePixels),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                searchParams.set('isArchived', action.payload.isArchived != undefined ? action.payload.isArchived.toString() : '');

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-event/all?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelEventSchema[]) => {
                            const payload: ResponseWrapper<GetSitePixelsData> = {
                                loading: false,
                                data: { res, olyticsSiteId: action.payload.olyticsSiteId, isArchived: action.payload?.isArchived },
                            };
                            return IntegrationsMetaEventsManagerActions.GetSitePixelsSuccess({ payload });
                        }),
                        catchError(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get site pixels.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.GetSitePixelsError({ payload: { olyticsSiteId: action.payload.olyticsSiteId, isArchived: action.payload?.isArchived } }));
                        })
                    );
            })
        )
    );

    getSiteArchivedPixels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixels),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('olyticsSiteId', action.payload.olyticsSiteId.toString());
                searchParams.set('isArchived', 'true');
                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-event/all?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: FacebookPixelEventSchema[]) => {
                            const payload: ResponseWrapper<FacebookPixelEventSchema[]> = {
                                loading: false,
                                data: res,
                            };
                            return IntegrationsMetaEventsManagerActions.GetSiteArchivedPixelsSuccess({ payload });
                        }),
                        catchError(() => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to get archived pixels.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.GetSiteArchivedPixelsError({ payload: { olyticsSiteId: action.payload.olyticsSiteId } }));
                        })
                    );
            })
        )
    );

    saveFacebookPixel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.SaveFacebookPixel),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-event-manager/pixel-event/save');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id
                }).toString();
                return this.http.post(url.toString(), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: FacebookPixelEventDetail) => {
                        const payload: ResponseWrapper<FacebookPixelEventDetail> = { loading: false, data: res };
                        if (action?.activate) {
                            this.store.dispatch(IntegrationsMetaEventsManagerActions.UpdatePixelStatus({ payload: { facebookPixelSegmentId: res.facebookPixelSegmentId, action: 'activate' } }));
                        }
                        this.router.navigate(['integrations/main/meta-events-manager/facebook-pixel/settings'], {
                            queryParams: {
                                olyticsSiteId: res?.olyticsSiteId,
                                facebookPixelSegmentId: res?.facebookPixelSegmentId,
                            },
                            queryParamsHandling: 'merge',
                        });
                        return IntegrationsMetaEventsManagerActions.SaveFacebookPixelSuccess({ payload: payload });
                    }
                    ), catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to save Facebook Pixel.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaEventsManagerActions.SaveFacebookPixelError(error));
                    }));
            })
        )
    );

    updateFacebookSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelSettings),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-event-manager/pixel-event/settings');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id
                }).toString();
                return this.http.put(url.toString(), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.router.navigate(['integrations/main/meta-events-manager/facebook-pixel/url-targeting'], {
                            relativeTo: this.route,
                            queryParamsHandling: 'merge',
                        });
                        return IntegrationsMetaEventsManagerActions.UpdateFacebookPixelSettingsSuccess({ payload: payload });
                    }
                    ), catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        'An error has occurred while trying to update Facebook Pixel Settings.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelSettingsError(error));
                    }));
            })
        )
    );

    updateFacebookUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelUrls),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-event-manager/pixel-event/update-url-targeting');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id
                }).toString();
                return this.http.put(url.toString(), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        this.router.navigate(['integrations/main/meta-events-manager/facebook-pixel/audience-targeting'], {
                            relativeTo: this.route,
                            queryParamsHandling: 'merge',
                        });
                        return IntegrationsMetaEventsManagerActions.UpdateFacebookPixelUrlsSuccess({ payload: payload });
                    }
                    ), catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        'An error has occurred while trying to update Facebook Pixel Url Targeting.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelUrlsError(error));
                    }));
            })
        )
    );

    updateFacebookAudienceTargeting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelAudience),
            exhaustMap((action) => {
                const url = new URL(environment.campaignApiEndPoint + '/integration/facebook-event-manager/pixel-event/update-audience-targeting');
                url.search = new URLSearchParams({
                    environmentId: this.currentDatabase.id
                }).toString();
                return this.http.put(url.toString(), action.payload, { withCredentials: true }).pipe(
                    take(1),
                    map((res: BasicResponse) => {
                        this.store.dispatch(IntegrationsMetaEventsManagerActions.SetCurrentPixelState({ payload: { typeCode: action.payload.typeCode, audienceSaved: true } }));
                        const payload: ResponseWrapper<BasicResponse> = { loading: false, data: res };
                        return IntegrationsMetaEventsManagerActions.UpdateFacebookPixelAudienceSuccess({ payload: payload });
                    }
                    ), catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message:
                                        'An error has occurred while trying to update Facebook Pixel Audience.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsMetaEventsManagerActions.UpdateFacebookPixelAudienceError(error));
                    }));
            })
        )
    );

    deletePixelAudience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsMetaEventsManagerActions.DeletePixelAudience),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);
                searchParams.set('facebookPixelSegmentAudienceId', action.payload.facebookPixelSegmentAudienceId.toString());
                return this.http.put(
                    environment.campaignApiEndPoint +
                    `/integration/facebook-event-manager/pixel-audience/delete?${searchParams}`,
                    { withCredentials: true }
                )
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            const payload: ResponseWrapper<{res: BasicResponse; facebookPixelSegmentAudienceId: number}> = {
                                loading: false,
                                data: { res, facebookPixelSegmentAudienceId: action.payload.facebookPixelSegmentAudienceId },
                            };
                            return IntegrationsMetaEventsManagerActions.DeletePixelAudienceSuccess({ payload });
                        }),
                        catchError((error) => {
                            this.store.dispatch(
                                ShowToast({
                                    payload: {
                                        title: 'Error',
                                        design: 'Error',
                                        placement: 'TopEnd',
                                        message: 'An error has occurred while trying to delete pixel audience.',
                                        icon: 'fa-circle-exclamation-solid',
                                    },
                                })
                            );
                            return of(IntegrationsMetaEventsManagerActions.DeletePixelAudienceError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
