import { Action, createReducer, on } from '@ngrx/store';
import { CustomerDataPlatformState, QueriesSearchResults } from './interfaces';
import { personalizationsReducer } from './personalizations/personalizations.reducer';
import * as CDPActions from './actions';
import { ignoredIpsReducer } from './meters/meters.reducer';
import { olyticsReducer } from './olytics/olytics.reducer';
import { formListenersReducers } from './form-listeners/form-listeners.reducer';
import { formsListenersInitialState } from './form-listeners/form-listeners.interface';
import { ResponseWrapper } from 'common';
import { OlyticsSiteMeters } from './meters/meters.interface';
import { GetPersonalizationsDataResponse } from './personalizations/personalizations.interface';

const initialAppState: CustomerDataPlatformState = {
    personalizationsData: {
        loading: false,
        data: null
    },
    personalizationsArchivedData: {
        loading: false,
        data: [] as GetPersonalizationsDataResponse,
    },
    globalSettingsData: {
        loading: false,
        data: null
    },
    metersData: {
        loading: false,
        data: {
            olyticsSiteMetersMap: new Map<string, ResponseWrapper<OlyticsSiteMeters, string>>(),
            showUnsavedModal: false,
        }
    },
    currentPersonalizationData: {
        loading: false,
        data: null
    },
    recommendationContentData: {
        loading: false,
        data: null
    },
    seachQueriesResults: {
        loading: false,
        data: [] as QueriesSearchResults,
    },
    europeanCountries: {
        loading: false,
        data: []
    },
    audienceProductTarget: {
        loading: false,
        data: null
    },
    olyticsParameters: {
        loading: false,
        data: []
    },
    formBuilderList: {
        loading: false,
        data: null
    },
    formSelectionFilters: {
        loading: false,
        data: null
    },
    personalizationGeneratedHtml: {
        loading: false,
        data: null
    },
    beeAuthToken: {
        loading: false,
        data: null
    },
    usedMergeVariables: {
        loading: false,
        data: null
    },
    trackingLinks: {
        loading: false,
        data: null
    },
    personalizationModificationHistory: {
        loading: false,
        data: null
    },
    personalizationReport: {
        loading: false,
        data: null
    },
    showUnsavedModal: false,
    olyticsData: null,
    formListenersState: {
        ...formsListenersInitialState
    },
    customerIdLookup: {
        loading: false,
        data: null
    },
    personalizationFilters: {},
    metersFilters: {}
};

function getInitalState(): CustomerDataPlatformState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initialAppState;
    // }
}

function getSavedState(): CustomerDataPlatformState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    ...personalizationsReducer,
    ...ignoredIpsReducer,
    ...formListenersReducers,

    ...olyticsReducer,
    on(CDPActions.GetGlobalSettings, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            globalSettingsData: {
                loading: true,
                data: state.globalSettingsData?.data
            }
        };
    }),
    on(CDPActions.GetGlobalSettingsSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            globalSettingsData: {
                loading: false,
                data: action.payload.data
            }
        };
    }),

    on(CDPActions.SearchQueries, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: true,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(CDPActions.SearchQueriesSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(CDPActions.SearchQueriesError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(CDPActions.ClearSearchQueriesResults, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: [] as QueriesSearchResults,
            }
        };
    }),
    on(CDPActions.CheckCustomerId, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            customerIdLookup: {
                loading: true,
                data: null,
            }
        };
    }),
    on(CDPActions.CheckCustomerIdSuccess, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            customerIdLookup: {
                loading: false,
                data: action?.payload,
            }
        };
    }),
    on(CDPActions.CheckCustomerIdError, (state: CustomerDataPlatformState) => {
        return {
            ...state,
            customerIdLookup: {
                loading: false,
                data: null,
            }
        };
    }),
    on(CDPActions.StorePersonalizationFilters, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            personalizationFilters: {
                ...(action.payload)
            }
        };
    }),
    on(CDPActions.StoreMetersFilters, (state: CustomerDataPlatformState, action) => {
        return {
            ...state,
            metersFilters: {
                ...(action.payload)
            }
        };
    }),
    on(CDPActions.ClearStoreValue, (state: CustomerDataPlatformState, action) => {
        const valState = initialAppState?.[action?.key] != null
            ? initialAppState[action.key]
            : { loading: false, data: null };
        return { ...state, [action.key]: valState };
    })
);

// // tslint:disable-next-line: typedef
export function customerDataPlatformReducer(state: CustomerDataPlatformState | undefined, action: Action) {
    return appReducer(state, action);
}
