import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as BehaviorsActions from './behaviors.actions';
import { BehaviorActionItem, BehaviorActionRes, BehaviorAttributeItem, BehaviorAttributeRes, BehaviorsListRes, SelectedBehaviorData, SelectedBehaviorRes } from './behaviors.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceBehaviorsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    loadBehaviorsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.LoadBehaviorData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || 'false');
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BehaviorsListRes) => {
                            const payload: ResponseWrapper<BehaviorsListRes> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.LoadBehaviorDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load behaviors data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (!action.payload?.singlePage) {
                                this.showError(null, errorMessage);
                            }
                            return of(BehaviorsActions.LoadBehaviorDataError(error));
                        })
                    );
            })
        )
    );

    downloadBehaviorsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.DownloadBehaviorList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/download-list?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: Blob) => {
                            const blob: Blob = new Blob([res], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = 'behaviors-list';
                            a.click();
                            window.URL.revokeObjectURL(url);

                            return BehaviorsActions.DownloadBehaviorListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download demographics list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.DownloadBehaviorListError(error));
                        })
                    );
            })
        )
    );

    getBehaviorData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetBehaviorData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('behaviorId', action.payload.behaviorId?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/${action.payload.behaviorId || ''}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedBehaviorRes) => {
                            const payload: ResponseWrapper<SelectedBehaviorRes> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetBehaviorDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load demographic data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetBehaviorDataError(error));
                        })
                    );
            })
        )
    );

    deleteDemographic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.DeleteBehavior),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('behaviorId', action.payload.behaviorId?.toString() || '');
                return this.http
                    .delete(environment.settingsApiEndPoint + `/demographic/brand/delete?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return BehaviorsActions.DeleteBehavioruccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete demographic.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.DeleteBehaviorError(error));
                        })
                    );
            })
        )
    );

    loadBehaviorAttributes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetBehaviorAttributes),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/attribute/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BehaviorAttributeRes) => {
                            const payload: ResponseWrapper<BehaviorAttributeRes> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetBehaviorAttributesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load behavior attributes data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetBehaviorAttributesError(error));
                        })
                    );
            })
        )
    );

    deleteBehaviorAttribute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.DeleteBehaviorAttribute),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('behaviorAttributeTypeId', action.payload.behaviorAttributeTypeId?.toString() || '');
                return this.http
                    .delete(environment.settingsApiEndPoint + `/behavior/attribute/delete?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return BehaviorsActions.DeleteBehaviorAttributeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to delete behavior attribute.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.DeleteBehaviorAttributeError(error));
                        })
                    );
            })
        )
    );

    loadBehaviorActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetBehaviorActions),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('dataViewId', this.currentUser?.currentProfile?.dataViewId?.toString());
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/action/all?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BehaviorActionRes) => {
                            const payload: ResponseWrapper<BehaviorActionRes> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetBehaviorActionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load behavior actions data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetBehaviorActionsError(error));
                        })
                    );
            })
        )
    );

    getBehavioTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetBehaviorTypes),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/types?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetBehaviorTypesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load Behavior Types.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetBehaviorTypesError(error));
                        })
                    );
            })
        )
    );

    getBehaviorTypeActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetBehaviorTypeActions),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/type-actions/${action.payload.typeId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetBehaviorTypeActionsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load Behavior Actions.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetBehaviorTypeActionsError(error));
                        })
                    );
            })
        )
    );

    getSelectedBehavior$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetSelectedBehavior),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/${action.payload.behaviorId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedBehaviorData) => {
                            const payload: ResponseWrapper<SelectedBehaviorData> = {
                                data: res,
                                loading: false
                            };
                            if (res && res?.typeId) {
                                this.store.dispatch(BehaviorsActions.GetBehaviorTypeActions({ payload: { typeId: res.typeId } }));
                            }
                            return BehaviorsActions.GetSelectedBehaviorSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load selected behavior.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/behavior-management']);
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetSelectedBehaviorError(error));
                        })
                    );
            })
        )
    );

    saveBehavior$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.SaveSelectedBehavior),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/behavior?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'Behavior has been saved successfully.');
                            this.router.navigate(['/self-service/main/behavior-management']);
                            return BehaviorsActions.SaveSelectedBehaviorSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save Behavior.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.SaveSelectedBehaviorError(error));
                        })
                    );
            })
        )
    );

    getSelectedBehaviorAttribute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetSelectedBehaviorAttribute),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/attribute/${action.payload.behaviorAttributeTypeId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BehaviorAttributeItem) => {
                            const payload: ResponseWrapper<BehaviorAttributeItem> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetSelectedBehaviorAttributeSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load selected behavior attribute.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/behavior-management/behavior-attributes']);
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetSelectedBehaviorAttributeError(error));
                        })
                    );
            })
        )
    );

    getAttributeTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetAttributeTypes),
            exhaustMap(() => {
                return this.http
                    .get(environment.settingsApiEndPoint + '/behavior/attribute/types', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetAttributeTypesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load attribute types.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetAttributeTypesError(error));
                        })
                    );
            })
        )
    );

    saveBehaviorAttribute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.SaveBehaviorAttribute),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/behavior/attribute?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'Behavior Attribute has been saved successfully.');
                            this.router.navigate(['/self-service/main/behavior-management/behavior-attributes']);
                            return BehaviorsActions.SaveBehaviorAttributeSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save Behavior Attribute.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.SaveBehaviorAttributeError(error));
                        })
                    );
            })
        )
    );

    getSelectedBehaviorAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetSelectedBehaviorAction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/action/${action.payload.behaviorActionId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BehaviorActionItem) => {
                            const payload: ResponseWrapper<BehaviorActionItem> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetSelectedBehaviorActionSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load selected behavior attribute.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/behavior-management/behavior-actions']);
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetSelectedBehaviorActionError(error));
                        })
                    );
            })
        )
    );

    saveBehaviorAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.SaveSelectedBehaviorAction),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/behavior/action?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Success', 'Behavior Action has been saved successfully.');
                            this.router.navigate(['/self-service/main/behavior-management/behavior-actions']);
                            return BehaviorsActions.SaveSelectedBehaviorActionSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to save Behavior Action.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.SaveSelectedBehaviorActionError(error));
                        })
                    );
            })
        )
    );

    getActionTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BehaviorsActions.GetActionTypes),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/behavior/types?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Record<string, string>) => {
                            const payload: ResponseWrapper<Record<string, string>> = {
                                data: res,
                                loading: false
                            };
                            return BehaviorsActions.GetActionTypesSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load action types.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(BehaviorsActions.GetActionTypesError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
