import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as IntegrationsBlueconicActions from './blueconic.actions';
import { BlueconicData } from './blueconic.interface';

@Injectable({ providedIn: 'root' })
export class IntegrationsBlueconicEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private location: Location,
    ) { }

    getBlueconicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsBlueconicActions.GetBlueconicData),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);

                return this.http.get(
                    environment.campaignApiEndPoint +
                    `/integration/blueconic?${searchParams}`,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BlueconicData) => {
                        const payload: ResponseWrapper<BlueconicData> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsBlueconicActions.GetBlueconicDataSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to load blueconic data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsBlueconicActions.GetBlueconicDataError(error));
                    })
                );
            })
        )
    );

    saveBlueconicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IntegrationsBlueconicActions.SaveBlueconicData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabase.id);

                return this.http.post(
                    environment.campaignApiEndPoint +
                    `/integration/blueconic?${searchParams}`,
                    action.payload,
                    { withCredentials: true }
                ).pipe(
                    take(1),
                    map((res: BlueconicData) => {
                        const payload: ResponseWrapper<BlueconicData> = {
                            loading: false,
                            data: res,
                        };
                        return IntegrationsBlueconicActions.SaveBlueconicDataSuccess({ payload });
                    }),
                    catchError((error) => {
                        this.store.dispatch(
                            ShowToast({
                                payload: {
                                    title: 'Error',
                                    design: 'Error',
                                    placement: 'TopEnd',
                                    message: 'An error has occurred while trying to save blueconic data.',
                                    icon: 'fa-circle-exclamation-solid',
                                },
                            })
                        );
                        return of(IntegrationsBlueconicActions.SaveBlueconicDataError(error));
                    })
                );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$
            .pipe(
                take(1),
                map((database) => {
                    res = database;
                })
            )
            .subscribe();
        return res;
    }

    get currentUser() {
        let res;
        this.store
            .select(getCurrentUser)
            .pipe(
                take(1),
                map((user) => {
                    res = user;
                })
            )
            .subscribe();
        return res;
    }
}
