import { on } from '@ngrx/store';
import * as IntegrationsAdrollEventBasedActions from '@integrations/state/adroll-event-based/adroll-event-based.actions';
import { IntegrationsState } from '@integrations/state/interfaces';

export const initialAdrollEventBasedState = {
    adrollSites: {
        loading: false,
        data: {
            totalResultCount: null,
            items: []
        }
    },
    audienceSyncs: {
        loading: false,
        data: null
    },
    currentAudienceSync: {
        loading: false,
        data: null
    },
    audienceSyncAuthInfo: {
        loading: false,
        data: null
    },
    audienceSyncAccessInfo: {
        loading: false,
        data: null
    },
    audienceTargetTableLoading: false,
    audienceSyncsArchived: {
        loading: false,
        data: null
    },
};

export const IntegrationsAdrollEventBasedReducer = [
    on(IntegrationsAdrollEventBasedActions.GetAdrollSites, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollSites: {
                    loading: true,
                    data: state.adrollEventBased?.adrollSites?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAdrollSitesSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollSites: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAdrollSitesError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollSites: {
                    loading: false,
                    data: state.adrollEventBased?.adrollSites?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAudienceSyncs, (state: IntegrationsState, action) => {
        const audienceSyncs = Object.assign({}, state.adrollEventBased?.audienceSyncs?.data);

        if (audienceSyncs[action.payload?.olyticsSiteId]) {
            audienceSyncs[action.payload?.olyticsSiteId] = { ...audienceSyncs[action.payload.olyticsSiteId], loading: true };
        } else {
            audienceSyncs[action.payload?.olyticsSiteId] = { loading: true };
        }

        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncs: {
                    loading: true,
                    data: audienceSyncs
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAudienceSyncsSuccess, (state: IntegrationsState, action) => {
        const audienceSyncs = Object.assign({}, state.adrollEventBased?.audienceSyncs?.data);
        audienceSyncs[action.payload?.data?.olyticsSiteId] = { syncs: [...action.payload?.data?.res], loading: false };

        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncs: {
                    loading: false,
                    data: audienceSyncs
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAudienceSyncsError, (state: IntegrationsState, action) => {
        const audienceSyncs = Object.assign({}, state.adrollEventBased?.audienceSyncs?.data);
        audienceSyncs[action.payload?.olyticsSiteId] = { ...audienceSyncs[action.payload?.olyticsSiteId], loading: false };

        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncs: {
                    loading: false,
                    data: audienceSyncs
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncs, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncsArchived: {
                    loading: true,
                    data: [],
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncsSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncsArchived: {
                    loading: false,
                    data: action?.payload?.data,
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetArchivedAudienceSyncsError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncsArchived: {
                    loading: false,
                    data: [],
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSync, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSyncSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: action.payload?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.LoadAdrollAudienceSyncError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSync, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: { ...state.adrollEventBased?.currentAudienceSync?.data, ...action.payload }
                }
            }
        };
    }),

    on(IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSyncSuccess, (state: IntegrationsState, action) => {
        let audiences = [];
        if (state.adrollEventBased?.currentAudienceSync?.data?.audiences) {
            audiences = [...state.adrollEventBased?.currentAudienceSync?.data?.audiences];
        }
        audiences.push({
            audienceName: action.payload?.data?.audienceName,
            type: 'Audience Builder',
            targetCode: 1,
            adrollPixelSegmentAudienceId: action.payload?.data?.adrollPixelSegmentAudienceId
        });
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceTargetTableLoading: false,
                currentAudienceSync: {
                    loading: false,
                    data: {
                        ...state.adrollEventBased?.currentAudienceSync?.data,
                        audiences: [...audiences]
                    }
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.AddAbQueryToAudienceSyncError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceTargetTableLoading: false,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSync, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceTargetTableLoading: true
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSyncSuccess, (state: IntegrationsState, action) => {
        const audiences = state.adrollEventBased?.currentAudienceSync?.data?.audiences?.filter((audience) => audience.adrollPixelSegmentAudienceId !== action.payload?.data?.adrollPixelSegmentAudienceId);
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceTargetTableLoading: false,
                currentAudienceSync: {
                    loading: false,
                    data: {
                        ...state.adrollEventBased?.currentAudienceSync?.data,
                        audiences: [...audiences]
                    }
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.DeleteAbQueryFromAudienceSyncError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceTargetTableLoading: false,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.SetCurrentAdrollAudienceSyncState, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: { ...state.adrollEventBased?.currentAudienceSync?.data, ...action.payload }
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAudienceSyncAuthInfoSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncAuthInfo: action.payload
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.GetAudienceSyncAccessTokenSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                audienceSyncAccessInfo: action.payload
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSync, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: true,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSyncSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: { ...state.adrollEventBased?.currentAudienceSync?.data, ...action.payload?.data }
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.SaveAdrollAudienceSyncError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReport, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollPixelSegmentReport: {
                    loading: true,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReportSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollPixelSegmentReport: {
                    loading: false,
                    data: action?.payload.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.ViewAdrollPixelSegmentReportError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                adrollPixelSegmentReport: {
                    loading: false,
                    data: null
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncSettings, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: true,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncSettingsError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncUrls, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: true,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncUrlsError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncAudience, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: true,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
    on(IntegrationsAdrollEventBasedActions.UpdateAdrollAudienceSyncAudienceError, (state: IntegrationsState) => {
        return {
            ...state,
            adrollEventBased: {
                ...state?.adrollEventBased,
                currentAudienceSync: {
                    loading: false,
                    data: state.adrollEventBased?.currentAudienceSync?.data
                }
            }
        };
    }),
];
