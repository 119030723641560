/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, ElementRef, Renderer2, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AcknoweledgeUserMessages, ModalService, SidePanelService, getCurrentUser, getToast } from 'common';
import { RefreshCounts, SetContactRulesFromPopup } from 'projects/audience-builder/src/state/actions';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { UsersnapService } from 'src/providers/user-snap.service';
import { GTMService } from '../providers/gtm.service';
import * as uuid from 'uuid';

enum EventActionCodes {
    REFRESH_AB_COUNTS = 'RefreshAbCounts',
    CHANGE_CONTACT_RULES_TO_SPECIFIC_PRODUCT = 'ChangeContactRulesToSpecificProduct',
    CLICK_TO_CHANGE_PASSWORD_PAGE = 'ClickToChangePasswordPage',
    QUICK_DEPLOY_OUTPUT = 'QuickDeployOutput',
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    hostDirectives: [CdkScrollable]
})
export class AppComponent implements OnInit {
    @ViewChild('toast') toast: ElementRef;
    public destroyed = new Subject();
    public currentPath: string;
    public user$ = this.store.select(getCurrentUser);
    public toasts: any[] = [];

    constructor(
        private gtmService: GTMService,
        private usersnapService: UsersnapService,
        private router: Router,
        private renderer: Renderer2,
        private store: Store,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private viewContainerRef: ViewContainerRef,
        private sidePanelService: SidePanelService
    ) {
        this.usersnapService.initialize();
    }

    eventAction(toast) {
        switch (toast.event.actionCode) {
            case EventActionCodes.REFRESH_AB_COUNTS:
                //TODO Handle error for refresh request - seperate closing of toast and dismissing
                this.store.dispatch(RefreshCounts({ payload: {
                    refreshOnQSelectionCounts: 'y',
                    dataViewId: toast.event?.supplementalData?.dataViewId || null,
                    environmentId: toast.event?.supplementalData?.environmentId || null,
                }}));
                this.onToastDismiss(toast);
                break;
            case EventActionCodes.CLICK_TO_CHANGE_PASSWORD_PAGE:
                this.router.navigate(['/account-settings'], { queryParams: { navigateToPassword: true } });
                this.onToastDismiss(toast);
                break;
            case EventActionCodes.CHANGE_CONTACT_RULES_TO_SPECIFIC_PRODUCT:
                this.store.dispatch(SetContactRulesFromPopup({
                    payload: {
                        emailProductRuleId: toast.event.supplementalData.emailRuleToUse || null,
                        shippingRuleId: toast.event.supplementalData.postalRuleToUse || null,
                        messageTypeRuleId: toast.event.supplementalData.phoneRuleToUse || null,
                        groupId: toast.event.supplementalData.groupId
                    }
                }));
                this.onToastDismiss(toast);
                break;
            case EventActionCodes.QUICK_DEPLOY_OUTPUT:
                this.router.navigate(['email-builder/main/edit-deployment/regular'], {
                    queryParams: {
                        id: toast.event.supplementalData.deploymentId,
                        databaseId: toast.event.supplementalData.environmentId,
                    }
                });
                this.onToastDismiss(toast);
                break;
            default:
                break;

        }
    }

    ngOnInit() {
        this.store.select(getCurrentUser).pipe(
            filter(user => !!user && !!user.userId), 
            take(1)
        ).subscribe(user => {
            this.gtmService.initializeGTM(user.userId, user.gtmIntercomUserIdHMAC);
        });
       
        this.modalService.setViewContainerRef(this.viewContainerRef);
        this.modalService.setRenderer(this.renderer);

        this.sidePanelService.setViewContainerRef(this.viewContainerRef);
        this.sidePanelService.setRenderer(this.renderer);

        this.router.events.pipe(
            takeUntil(this.destroyed),
            map((event: any) => {
                if (event?.url) {
                    this.currentPath = event.url;
                }
            })
        ).subscribe();
        this.store.select(getToast).pipe(
            takeUntil(this.destroyed),
            map((toast) => {
                const isDuplicate = toast?.event && 'ackUrl' in toast.event && this.toasts.some(existingToast => 'ackUrl' in toast?.event && existingToast.event?.ackUrl === toast.event?.ackUrl);
                if (toast?.message && toast?.message?.length > 0 && !isDuplicate) {
                    this.toasts.push({
                        title: toast?.title || '',
                        message: toast?.message,
                        design: toast?.design,
                        placement: toast?.placement,
                        icon: toast?.icon || null,
                        keepOpen: toast.design === 'Error' ? true : toast?.keepOpen,
                        duration: toast?.duration || 5000,
                        open: true,
                        event: toast?.event || null,
                        id: uuid.v4(),
                        redirection: toast?.redirection || null,
                    });
                }
            })
        ).subscribe();
    }

    onToastDismiss(toast) {
        if (toast?.event && toast?.event?.ackUrl) {
            this.store.dispatch(AcknoweledgeUserMessages({payload: {key: toast.event.ackUrl}}));
        }
        this.toasts = this.toasts.filter((x) => x.id !== toast.id);
    }

    showToast(title: string, message: string, design: string, placement: string = 'TopEnd', icon: string, keepOpen?: boolean, duration?: number) {
        this.toast.nativeElement.innerText = message;
        this.toast.nativeElement.setAttribute('title', title ? title : '');
        this.toast.nativeElement.setAttribute('design', design);
        this.toast.nativeElement.setAttribute('placement', placement);
        if (icon) {
            this.toast.nativeElement.setAttribute('icon', icon);
        } else {
            this.toast.nativeElement.removeAttribute('icon');
        }
        if (keepOpen) {
            this.toast.nativeElement.setAttribute('keep-open', keepOpen);
        } else {
            this.toast.nativeElement.removeAttribute('keep-open');
        }
        this.toast.nativeElement.setAttribute('duration', duration);
        this.toast.nativeElement.show();
    }

    redirect(toast) {
        if (toast?.redirection) {
            this.router.navigate(toast.redirection?.commands, { queryParams: toast.redirection?.extras?.queryParams || null});
        }
        this.onToastDismiss({ id: toast?.id });
    }

    ngOnDestroy() {
        this.destroyed.next(null);
        this.destroyed.complete();
    }

    get hideShell() {
        const reg = /login|odyssey\/diagram|facebook-pixel|pixel-performance|ftp-job|audience-sync|personalized-content\/personalize|create-edit-meter|manage-promo-code|manage-schedule-output|lead-scoring\/manage-event/;
        return (this.currentPath && reg.test(this.currentPath)) || this.route?.snapshot?.queryParamMap?.get('iframe');
    }

    get application(): string {
        if (!this.currentPath || (this.currentPath && this.currentPath.length < 5)) {
            return '';
        }
        const path = this.currentPath.replace('/', '');
        return path.split('/')[0];
    }

}
