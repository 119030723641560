import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, CasHttpClient, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, debounceTime, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as ManageSmsMessageActions from './manage-sms-message.actions';
import { AdditionalDetails, AudienceItem, AudienceTargetingData, AvailableTestersList, MessageContentData, ModificationHistoryItem, ParameterItem, SummaryPleatData, TestAndSeedData, Tester, TrackingData, WebTrackingConfigurationData, WebTrackingItem } from './manage-sms-message.interface';
import { TypeCode } from '@sms-builder/app/constants';

@Injectable({ providedIn: 'root' })
export class SmsBuilderManageSmsMessageEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private casHttp: CasHttpClient,
    ) { }

    getMessageAdditionalDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetMessageAdditionalDetails),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/additional-details?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AdditionalDetails) => {
                            return ManageSmsMessageActions.GetMessageAdditionalDetailsSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get additional message data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetMessageAdditionalDetailsError(error));
                        })
                    );
            })
        )
    );

    scheduleMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.ScheduleMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/schedule?${searchParams}`, { scheduledDate: action?.payload?.scheduledDate }, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return ManageSmsMessageActions.ScheduleMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to Schedule Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.ScheduleMessageError(error));
                        })
                    );
            })
        )
    );

    unscheduleMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UnscheduleMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/unschedule?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return ManageSmsMessageActions.UnscheduleMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to Unschedule Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UnscheduleMessageError(error));
                        })
                    );
            })
        )
    );

    sendMessageTest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.SendMessageTest),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/test?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess('Test Message Sent', 'Your test message has been sent');
                            return ManageSmsMessageActions.SendMessageTestSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to Send Message Test.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.SendMessageTestError(error));
                        })
                    );
            })
        )
    );

    sendToOne$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.SendToOne),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/message/${action?.payload?.messageId}/send-to-one?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res && res?.success) {
                                this.showSuccess('Message Sent', 'Message has been sent to provided phone number');
                            }
                            return ManageSmsMessageActions.SendToOneSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to Send To One.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.SendToOneError(error));
                        })
                    );
            })
        )
    );

    cancelMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.CancelMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/message/${action?.payload?.messageId}/cancel?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            return ManageSmsMessageActions.CancelMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to cancel Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.CancelMessageError(error));
                        })
                    );
            })
        )
    );

    // Summary Pleat
    createSmsMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.CreateSmsMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(this.baseUrl, this.baseUrl + `/message/summary-pleat?${searchParams}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SummaryPleatData) => {
                            if (res && res?.messageId) {
                                this.router.navigate([],
                                    {
                                        relativeTo: this.activatedRoute,
                                        queryParams: { messageId: res?.messageId },
                                        queryParamsHandling: 'merge',
                                    }
                                );
                            }
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: res?.messageId } }));
                            return ManageSmsMessageActions.CreateSmsMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create New Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.CreateSmsMessageError(error));
                        })
                    );
            })
        )
    );

    getSummaryPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetSummaryPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/summary-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SummaryPleatData) => {
                            if (res && (res?.typeCode != TypeCode.API_ONGOING && !res?.scheduledDate)) {
                                this.showWarning('Message not scheduled', 'Please save the Message Summary pleat with the desired schedule date for your message to continue updating the message details.');
                            }
                            return ManageSmsMessageActions.GetSummaryPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get SMS Message Summary Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetSummaryPleatDataError(error));
                        })
                    );
            })
        )
    );

    updateSummaryPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateSummaryPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .put(this.baseUrl + `/message/${action?.payload?.messageId}/summary-pleat?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SummaryPleatData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UpdateSummaryPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update SMS Message Summary Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateSummaryPleatDataError(error));
                        })
                    );
            })
        )
    );

    cloneSmsMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.CloneSmsMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('cloneMessageId', action?.payload?.cloneMessageId?.toString());
                return this.http
                    .post(this.baseUrl + `/message/clone?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SummaryPleatData) => {
                            this.showSuccess('Message Cloned', 'Your message has been cloned succesfully.');
                            return ManageSmsMessageActions.CloneSmsMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to clone SMS Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.CloneSmsMessageError(error));
                        })
                    );
            })
        )
    );

    // Audience Targeting Pleat
    getAudienceTargetingPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetAudienceTargetingPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/audience-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AudienceTargetingData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetMessageAdditionalDetails({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.GetAudienceTargetingPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get SMS Message Audience Targeting Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetAudienceTargetingPleatDataError(error));
                        })
                    );
            })
        )
    );

    updateAudienceTargetingPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateAudienceTargetingPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/audience-pleat?${searchParams}`, { reloadAudience: action?.payload?.reloadAudience }, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AudienceTargetingData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            this.store.dispatch(ManageSmsMessageActions.GetMessageAdditionalDetails({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UpdateAudienceTargetingPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update SMS Message Audience Targeting Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateAudienceTargetingPleatDataError(error));
                        })
                    );
            })
        )
    );

    audienceTargetingTableUploadQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.AudienceTargetingTableUploadQuery),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(
                        this.baseUrl,
                        this.baseUrl + `/message/${action?.payload?.messageId}/audience/query/${action?.payload?.selectionCritieriaId}?${searchParams}`,
                        action?.payload?.data,
                        this.defaultHttpOptions
                    ).pipe(
                        take(1),
                        map((res: AudienceItem) => {
                            this.store.dispatch(ManageSmsMessageActions.GetSingleAudienceData({ payload: { messageId: action?.payload?.messageId, audienceId: res?.audienceId } }));
                            this.showSuccess('Upload requested', 'Please be patient while we process your selected audience');
                            return ManageSmsMessageActions.AudienceTargetingTableUploadQuerySuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload query to Audience Targeting table.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.AudienceTargetingTableUploadQueryError(error));
                        })
                    );
            })
        )
    );

    audienceTargetingTableUploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.AudienceTargetingTableUploadFile),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(
                        this.baseUrl,
                        this.baseUrl + `/message/${action?.payload?.messageId}/audience/file?${searchParams}`,
                        action?.payload?.data,
                        this.defaultHttpOptions
                    ).pipe(
                        take(1),
                        map((res: AudienceItem) => {
                            this.store.dispatch(ManageSmsMessageActions.GetSingleAudienceData({ payload: { messageId: action?.payload?.messageId, audienceId: res?.audienceId } }));
                            this.showSuccess('Upload requested', 'Please be patient while we process your file');
                            return ManageSmsMessageActions.AudienceTargetingTableUploadFileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload file to Audience Targeting table.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.AudienceTargetingTableUploadFileError(error));
                        })
                    );
            })
        )
    );

    audienceTargetingTableDeleteAudiences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.AudienceTargetingTableDeleteAudiences),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                action?.payload?.ids?.forEach(id => searchParams.append('audienceId', id?.toString()));
                return this.http
                    .delete(
                        this.baseUrl + `/message/${action?.payload?.messageId}/audiences?${searchParams}`,
                        { withCredentials: true },
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.store.dispatch(ManageSmsMessageActions.GetAudienceTargetingPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.AudienceTargetingTableDeleteAudiencesSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload file to Audience Targeting table.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.AudienceTargetingTableDeleteAudiencesError(error));
                        })
                    );
            })
        )
    );

    getSingleAudienceData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetSingleAudienceData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(
                        this.baseUrl + `/audience/${action?.payload?.audienceId}?${searchParams}`,
                        { withCredentials: true },
                    ).pipe(
                        take(1),
                        map((res: AudienceItem) => {
                            let payload = null;
                            if (res) {
                                switch (res?.uploadStatusCode) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        payload = { audience: res, uploadInProgress: true };
                                        setTimeout(() => {
                                            this.store.dispatch(ManageSmsMessageActions.GetSingleAudienceData({ payload: { messageId: action?.payload?.messageId, audienceId: res?.audienceId } }));
                                        }, 500);
                                        break;
                                    case 3:
                                        this.showSuccess('Upload complete', 'Your upload has been processed');
                                        payload = { audience: res, uploadInProgress: false };
                                        this.store.dispatch(ManageSmsMessageActions.GetAudienceTargetingPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        this.store.dispatch(ManageSmsMessageActions.GetMessageContentPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        break;
                                    case 9:
                                        this.showError(res?.uploadStatusDescription, res?.uploadNotes);
                                        payload = { audience: res, uploadInProgress: false };
                                        this.store.dispatch(ManageSmsMessageActions.GetAudienceTargetingPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        this.store.dispatch(ManageSmsMessageActions.GetMessageContentPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                                        break;
                                }
                            }
                            return ManageSmsMessageActions.GetSingleAudienceDataSuccess({ payload: payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get data for Audience.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetSingleAudienceDataError(error));
                        })
                    );
            })
        )
    );

    // Message Content Pleat
    getMessageContentPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetMessageContentPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/content-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageContentData) => {
                            return ManageSmsMessageActions.GetMessageContentPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get SMS Message Content Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetMessageContentPleatDataError(error));
                        })
                    );
            })
        )
    );

    updateMessageContentPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateMessageContentPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/content-pleat?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: MessageContentData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetTrackingPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UpdateMessageContentPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update SMS Message Content Data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateMessageContentPleatDataError(error));
                        })
                    );
            })
        )
    );

    // Test and Seed Recipients Pleat
    getMessageTesterPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetMessageTesterPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tester-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TestAndSeedData) => {
                            return ManageSmsMessageActions.GetMessageTesterPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get SMS Test and Seed Recipients.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetMessageTesterPleatDataError(error));
                        })
                    );
            })
        )
    );

    updateMessageTesterPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateMessageTesterPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tester-pleat?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TestAndSeedData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UpdateMessageTesterPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update SMS Test and Seed Recipients.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateMessageTesterPleatDataError(error));
                        })
                    );
            })
        )
    );

    getTestersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetTestersList),
            debounceTime(300),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('numResults', action?.payload?.numResults?.toString());
                searchParams.set('offset', action?.payload?.offset?.toString());
                searchParams.set('order', action?.payload?.order);
                searchParams.set('sortBy', action?.payload?.sortBy);
                searchParams.set('firstName', action?.payload?.firstName);
                searchParams.set('lastName', action?.payload?.lastName);
                searchParams.set('phoneNumber', action?.payload?.phoneNumber);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/testers?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: AvailableTestersList) => {
                            return ManageSmsMessageActions.GetTestersListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get available testers list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetTestersListError(error));
                        })
                    );
            })
        )
    );

    createNewTesterAndAssignToMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.CreateNewTesterAndAssignToMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tester?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Tester) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.CreateNewTesterAndAssignToMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create new tester and assign to Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.CreateNewTesterAndAssignToMessageError(error));
                        })
                    );
            })
        )
    );

    assignTesterToMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.AssignTesterToMessage),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tester/${action?.payload?.testerId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Tester) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.AssignTesterToMessageSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to assign Tester to Message.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.AssignTesterToMessageError(error));
                        })
                    );
            })
        )
    );

    uploadRecipientListFromFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UploadRecipientListFromFile),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(
                        this.baseUrl,
                        this.baseUrl + `/message/${action?.payload?.messageId}/tester/file?${searchParams}`,
                        action?.payload?.data,
                        this.defaultHttpOptions
                    ).pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            if (res) {
                                if (!res?.success && res?.errors?.length) {
                                    this.showError(null, res?.errors[0]);
                                }
                                if (res?.success && res?.messages?.length) {
                                    this.showSuccess(null, res?.messages[0]);
                                }
                            }
                            this.store.dispatch(ManageSmsMessageActions.GetMessageTesterPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UploadRecipientListFromFileSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to upload file to Test and Seed Recipient table.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UploadRecipientListFromFileError(error));
                        })
                    );
            })
        )
    );

    // Tracking Pleat
    getTrackingPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetTrackingPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tracking-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TrackingData) => {
                            return ManageSmsMessageActions.GetTrackingPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get SMS Tracking data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetTrackingPleatDataError(error));
                        })
                    );
            })
        )
    );

    updateTrackingPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateTrackingPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .post(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/tracking-pleat?${searchParams}`, action?.payload?.data, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: TrackingData) => {
                            this.store.dispatch(ManageSmsMessageActions.GetModificationHistoryPleatData({ payload: { messageId: action?.payload?.messageId } }));
                            return ManageSmsMessageActions.UpdateTrackingPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to update SMS Tracking data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateTrackingPleatDataError(error));
                        })
                    );
            })
        )
    );

    getWebTrackingList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetWebTrackingList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/web-tracking?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<WebTrackingItem>) => {
                            return ManageSmsMessageActions.GetWebTrackingListSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Web Tracking String List.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetWebTrackingListError(error));
                        })
                    );
            })
        )
    );

    getAvailableWebTrackingParameters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetAvailableWebTrackingParameters),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + '/web-tracking/parameters', this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: { parameters: Array<ParameterItem> }) => {
                            return ManageSmsMessageActions.GetAvailableWebTrackingParametersSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Avialable Web Tracking Parameters.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetAvailableWebTrackingParametersError(error));
                        })
                    );
            })
        )
    );

    getWebTrackingConfigurationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetWebTrackingConfigurationData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/web-tracking/config/${action?.payload?.webConfigId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: WebTrackingConfigurationData) => {
                            return ManageSmsMessageActions.GetWebTrackingConfigurationDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Web Tracking Configuration data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetWebTrackingConfigurationDataError(error));
                        })
                    );
            })
        )
    );

    updateWebTrackingConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.UpdateWebTrackingConfiguration),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .put(this.baseUrl, this.baseUrl + `/web-tracking/config?${searchParams}`, action?.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: WebTrackingConfigurationData) => {
                            return ManageSmsMessageActions.UpdateWebTrackingConfigurationSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to create or update a Web Tracking Configuration.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.UpdateWebTrackingConfigurationError(error));
                        })
                    );
            })
        )
    );

    // Modification History Pleat
    getModificationHistoryPleatData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManageSmsMessageActions.GetModificationHistoryPleatData),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.casHttp
                    .get(this.baseUrl, this.baseUrl + `/message/${action?.payload?.messageId}/history-pleat?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: Array<ModificationHistoryItem>) => {
                            return ManageSmsMessageActions.GetModificationHistoryPleatDataSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get Modification History data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(ManageSmsMessageActions.GetModificationHistoryPleatDataError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Success',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showWarning(title?: string, message?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Warning',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.smsApiEndPoint;
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.activatedRoute?.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
