import { ResponseWrapper } from 'common';

export const productsInitialState: ProductsState = {
    productsData: {
        loading: false,
        data: null
    },
    downloadProductsLoader: false,
    selectedProduct: {
        loading: false,
        data: null
    },
    auditBureaus: [],
    paymentOptions: [],
    paidClasses: [],
    postalBarcodes: [],
    productCompanies: null,
    productClasses: {
        loading: false,
        data: null
    },
    productAccounts: null,
    auditedDemographics: {
        loading: false,
        data: null
    },
    addressedDemographics: {
        loading: false,
        data: null
    },
    issueCloseDemographics: {
        loading: false,
        data: null
    },
    associatedDemographics: {
        loading: false,
        data: null
    },
    passAlongDemographics: {
        loading: false,
        data: null
    }
};

export interface ProductsState {
    productsData: ResponseWrapper<ProductsRes>;
    downloadProductsLoader: boolean;
    selectedProduct: ResponseWrapper<ProductResponse>;
    auditBureaus: Array<string>;
    paymentOptions: Array<{ code: number, name: string }>;
    paidClasses: Array<{ code: number, name: string }>;
    postalBarcodes: Array<{ code: number, name: string }>;
    productCompanies: Record<number, string>;
    productClasses: ResponseWrapper<ProductClassesRes>;
    productAccounts: Record<number, { accountId?: number, accountName?: string, accountType?: string }>;
    auditedDemographics: ResponseWrapper<DemographicRes>;
    addressedDemographics: ResponseWrapper<DemographicRes>;
    issueCloseDemographics: ResponseWrapper<DemographicRes>;
    associatedDemographics: ResponseWrapper<DemographicRes>;
    passAlongDemographics: ResponseWrapper<DemographicRes>;
}

export interface ProductsRes {
    totalResultCount: number;
    items: Array<Product>;
}

export interface Product {
    productName: string;
    productId: number;
    productTypeDesc: string;
    changedBy: string;
    changedDate: string;
    statusDesc: string;
    selected: boolean;
    disabled: boolean;
    userProductEditAuthorized: boolean;
}

export interface Account {
    accountId: number,
    company: Array<string>,
    status: number
}

export interface DemographicRes {
    totalResultCount: number;
    items: Array<Demographic>;
}

export interface Demographic {
    code: number;
    name: string;
}

export interface ProductResponse {
    alternateId: string;
    auditDetails: AuditDetailsI;
    audited: boolean;
    includeInGLExport: string;
    numIssues: number;
    postalBarCode: number;
    productId: number;
    productName: string;
    profiles: number[];
    publicDisplayName: string;
    status: number;
    versionType: string;
    taxStates?: TaxStatesItemp[];
    writeOffOptions?: Array<{selected?: boolean, scheduleUnit?: string, scheduleType?: string, scheduleValue?: number}>;
    liabilityEarningOptions?: Array<{selected?: boolean, scheduleUnit?: string, scheduleType?: string, scheduleValue?: number}>;
}

interface AuditDetailsI {
    auditBureau: string;
    auditMonth: string;
    endDate: string;
    interimIssueMonth: string;
    startDate: string;
}

export interface ProductClassesRes {
    totalResultCount?: number;
    items?: Array<ProductClass>;
}

export interface ProductClass {
    classId?: number,
    classNumber?: number,
    className?: string,
    type?: string,
    count?: number,
}

export interface TaxStatesItemp {
    code?: string,
    name?: string,
    selected?: boolean,
}