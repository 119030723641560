import { on } from '@ngrx/store';
import * as LeadScoringActions from './lead-scoring.actions';
import { CampaignsState } from '../interfaces';
import { AttributeType, AttributeValue, LeadScoring } from './lead-scoring.interfaces';
import { ResponseWrapper } from 'common';

export const campaignsLeadScoringReducer = [
    on(LeadScoringActions.GetLeadScoringLogs, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringLogs: {
                    loading: true,
                    data: null,
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringLogsSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringLogs: {
                    loading: false,
                    data: action.payload,
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringLogsError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringLogs: {
                    loading: false,
                    data: null,
                },
            }
        };
    }),
    on(LeadScoringActions.GetOlyticsUrls, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                olyticsUrls: {
                    loading: true,
                    data: null,
                },
            }
        };
    }),
    on(LeadScoringActions.GetOlyticsUrlsSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                olyticsUrls: action.payload,
            }
        };
    }),
    on(LeadScoringActions.GetOlyticsUrlsError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                olyticsUrls: {
                    loading: false,
                    data: null,
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoring, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: true,
                    data: state?.leadScoringState?.leadScoringData?.data || {}, // Initialize with an empty object if it's null
                } as ResponseWrapper<LeadScoring>,
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: {
                        ...state?.leadScoringState?.leadScoringData?.data,
                        ...action.payload,
                        owner: state.leadScoringState.leadScoringData.data?.owner || action.payload.owner,
                    }
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: state.leadScoringState.leadScoringData.data || null,
                },
            }
        };
    }),
    on(LeadScoringActions.ManageLeadScoring, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: true,
                    data: state?.leadScoringState?.leadScoringData?.data || {}, // Initialize with an empty object if it's null
                } as ResponseWrapper<LeadScoring>,
            }
        };
    }),
    on(LeadScoringActions.ManageLeadScoringSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: action.payload,
                },
            }
        };
    }),
    on(LeadScoringActions.ManageLeadScoringError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: state?.leadScoringState?.leadScoringData?.data || {}, // Initialize with an empty object if it's null
                } as ResponseWrapper<LeadScoring>,
            }
        };
    }),
    on(LeadScoringActions.UpdateLeadScoringDataInStore, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: {
                        ...state?.leadScoringState?.leadScoringData?.data,
                        ...action.payload,
                    },
                },
            }
        };
    }),
    on(LeadScoringActions.UpdateLeadScoringRangesInStore, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: {
                        ...state?.leadScoringState?.leadScoringData?.data,
                        leadScoresList: action.payload.leadScoresList,
                    },
                },
            }
        };
    }),
    on(LeadScoringActions.ClearLeadScoring, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: null,
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringRuleTypes, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                ruleTypes: {
                    loading: true,
                    data: [],
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringRuleTypesSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                ruleTypes: {
                    loading: false,
                    data: action.payload,
                },
            }
        };
    }),
    on(LeadScoringActions.GetLeadScoringRuleTypesError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                ruleTypes: {
                    loading: false,
                    data: [],
                },
            }
        };
    }),
    on(LeadScoringActions.GetRuleTypeExternalIdList, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                externalIdList: {
                    loading: true,
                    data: [],
                },
            }
        };
    }),
    on(LeadScoringActions.GetRuleTypeExternalIdListSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                externalIdList: {
                    loading: false,
                    data: action.payload,
                },
            }
        };
    }),
    on(LeadScoringActions.GetRuleTypeExternalIdListError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                externalIdList: {
                    loading: false,
                    data: [],
                },
            }
        };
    }),
    on(LeadScoringActions.GetBehaviorAttributeTypes, (state: CampaignsState, action) => {
        const behaviorAttributeTypesMap = JSON.parse(JSON.stringify(state?.leadScoringState?.behaviorAttributeTypesMap));
        behaviorAttributeTypesMap[action.payload.behaviorId] = { loading: true };
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                behaviorAttributeTypesMap: behaviorAttributeTypesMap,
            }
        };
    }),
    on(LeadScoringActions.GetBehaviorAttributeTypesSuccess, (state: CampaignsState, action) => {
        const behaviorAttributeTypesMap = JSON.parse(JSON.stringify(state?.leadScoringState?.behaviorAttributeTypesMap));
        behaviorAttributeTypesMap[action?.payload?.behaviorId] = { loading: false, data: action?.payload?.data };
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                behaviorAttributeTypesMap: behaviorAttributeTypesMap,
                behaviorAttributeTypes: action.payload?.data,
            }
        };
    }),
    on(LeadScoringActions.GetBehaviorAttributeTypesSearchValues, (state: CampaignsState, action) => {
        const behaviorAttributeTypeSearchValuesMap = JSON.parse(JSON.stringify(state?.leadScoringState?.behaviorAttributeTypeSearchValuesMap));
        behaviorAttributeTypeSearchValuesMap[action.payload.behaviorAttributeTypeId] = { loading: true };
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                behaviorAttributeTypeSearchValuesMap: behaviorAttributeTypeSearchValuesMap,
            }
        };
    }),
    on(LeadScoringActions.GetBehaviorAttributeTypesSearchValuesSuccess, (state: CampaignsState, action) => {
        const behaviorAttributeTypeSearchValuesMap = JSON.parse(JSON.stringify(state?.leadScoringState?.behaviorAttributeTypeSearchValuesMap));
        behaviorAttributeTypeSearchValuesMap[action?.payload?.behaviorAttributeTypeId] = { loading: false, data: action?.payload?.data };
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                behaviorAttributeTypeSearchValuesMap: behaviorAttributeTypeSearchValuesMap,
                behaviorAttributeTypeSearchValues: action.payload
            }
        };
    }),
    on(LeadScoringActions.SaveRulesInState, (state: CampaignsState, action) => {
        const existingRuleIndex = state?.leadScoringState?.leadScoringData?.data?.leadRulesList?.findIndex(
            (rule) => rule.ruleId === action.payload.rawRule.ruleId
        );
        if (existingRuleIndex !== -1 && existingRuleIndex !== undefined) {
            const updatedLeadRulesList = [...state?.leadScoringState?.leadScoringData?.data?.leadRulesList];
            updatedLeadRulesList[existingRuleIndex] = action.payload.rawRule;
            return {
                ...state,
                leadScoringState: {
                    ...state?.leadScoringState,
                    leadScoringData: {
                        ...state?.leadScoringState?.leadScoringData,
                        data: {
                            ...state?.leadScoringState?.leadScoringData?.data,
                            leadRulesList: updatedLeadRulesList,
                        },
                    },
                    rules: {
                        ...state?.leadScoringState?.rules,
                        [action.payload.ruleType]: {
                            ...action.payload.rules,
                        },
                    },
                },
            };
        } else {
            return {
                ...state,
                leadScoringState: {
                    ...state?.leadScoringState,
                    leadScoringData: {
                        ...state?.leadScoringState?.leadScoringData,
                        data: {
                            ...state?.leadScoringState?.leadScoringData?.data,
                            leadRulesList: [
                                ...(state?.leadScoringState?.leadScoringData?.data?.leadRulesList || []),
                                action.payload.rawRule,
                            ],
                        },
                    },
                    rules: {
                        ...state?.leadScoringState?.rules,
                        [action.payload.ruleType]: {
                            ...action.payload.rules,
                        },
                    },
                },
            };
        }
    }),
    on(LeadScoringActions.DeleteRuleFromState, (state: CampaignsState, action) => {
        const updatedLeadRulesList = state?.leadScoringState?.leadScoringData?.data?.leadRulesList.filter(
            (rule, index) => index !== action.payload.ruleIndex
        );
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    ...state?.leadScoringState?.leadScoringData,
                    data: {
                        ...state?.leadScoringState?.leadScoringData?.data,
                        leadRulesList: updatedLeadRulesList,
                    },
                },
            },
        };
    }),
    on(LeadScoringActions.SaveLeadScoring, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: true,
                    data: state?.leadScoringState?.leadScoringData?.data || {},
                } as ResponseWrapper<LeadScoring>,
            }
        };
    }),
    on(LeadScoringActions.SaveLeadScoringSuccess, (state: CampaignsState, action) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: action.payload,
                },
            }
        };
    }),
    on(LeadScoringActions.SaveLeadScoringError, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                leadScoringData: {
                    loading: false,
                    data: state?.leadScoringState?.leadScoringData?.data || {}
                } as ResponseWrapper<LeadScoring>,
            }
        };
    }),
    on(LeadScoringActions.ClearMangeRuleDropdownValues, (state: CampaignsState) => {
        return {
            ...state,
            leadScoringState: {
                ...state?.leadScoringState,
                behaviorAttributeTypesMap: new Map<string, AttributeType[]>(),
                behaviorAttributeTypeSearchValuesMap: new Map<string, AttributeValue[]>(),
            }
        };
    }),
];
