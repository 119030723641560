import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicResponse, ResponseWrapper, ShellStore, ShowToast, getCurrentUser } from 'common';
import { catchError, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as DemographicsActions from './calculated-demographics.actions';
import { DemographicsListRes, SelectedDemographicRes, SourceDemographicsRes } from './calculated-demographics.interface';

@Injectable({ providedIn: 'root' })
export class SelfServiceCalculatedDemographicsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private http: HttpClient,
        private shellStore: ShellStore,
        private route: ActivatedRoute,
        private router: Router
    ) { }
    loadCalculatedDemographicsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.LoadCalculatedDemographicsData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('searchText', action.payload.searchText || '');
                searchParams.set('sortBy', action.payload.sortBy || '');
                searchParams.set('order', action.payload.order || '');
                searchParams.set('numResults', action.payload.numResults?.toString() || '');
                searchParams.set('offset', action.payload.offset?.toString() || '');
                searchParams.set('singlePage', action.payload.singlePage?.toString() || 'false');
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/calculated?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: DemographicsListRes) => {
                            const payload: ResponseWrapper<DemographicsListRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.LoadCalculatedDemographicsDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to load demographics data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            if (!action.payload?.singlePage) {
                                this.showError(null, errorMessage);
                            }
                            return of(DemographicsActions.LoadCalculatedDemographicsDataError(error));
                        })
                    );
            })
        )
    );

    downloadCalculatedDemographicList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.DownloadCalculatedDemographicsList),
            exhaustMap(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/calculated/export-dump?${searchParams}`, { responseType: 'blob', withCredentials: true})
                    .pipe(
                        take(1),
                        map((res: File) => {
                            const url = window.URL.createObjectURL(res);
                            const a: HTMLAnchorElement = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = `calculated_demographics_list_${this.currentDatabaseId}.xlsx`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return DemographicsActions.DownloadCalculatedDemographicsListSuccess();
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to download demographics list.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.DownloadCalculatedDemographicsListError(error));
                        })
                    );
            })
        )
    );

    getCalculatedDemographicData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetCalculatedDemographicData),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/${action.payload.demographicId}?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SelectedDemographicRes) => {
                            const payload: ResponseWrapper<SelectedDemographicRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetCalculatedDemographicDataSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get demographic data.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.router.navigate(['/self-service/main/demographics/calculated']);
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.GetCalculatedDemographicDataError(error));
                        })
                    );
            })
        )
    );

    getSourceDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.GetSourceDemographics),
            switchMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                searchParams.set('order', action.payload?.order || '');
                searchParams.set('sortBy', action.payload?.sortBy || '');
                searchParams.set('offset', action.payload?.offset?.toString() || '');
                searchParams.set('numResults', action.payload?.numResults?.toString() || '');
                searchParams.set('demographicType', action.payload?.demographicType?.toString());
                return this.http
                    .get(environment.settingsApiEndPoint + `/demographic/brand/calculated/source-demographics?${searchParams}`, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: SourceDemographicsRes) => {
                            const payload: ResponseWrapper<SourceDemographicsRes> = {
                                data: res,
                                loading: false
                            };
                            return DemographicsActions.GetSourceDemographicsSuccess({ payload });
                        }),
                        catchError((error) => {
                            let errorMessage = 'An error has occurred while trying to get source demographics.';
                            if (error && error.error && error.error.errors && error.error.errors.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.GetSourceDemographicsError(error));
                        })
                    );
            })
        )
    );

    saveCalculatedDemographic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemographicsActions.SaveCalculatedDemographic),
            exhaustMap((action) => {
                const searchParams = new URLSearchParams();
                searchParams.set('environmentId', this.currentDatabaseId);
                return this.http
                    .post(environment.settingsApiEndPoint + `/demographic/brand/calculated?${searchParams}`, action.payload, this.defaultHttpOptions)
                    .pipe(
                        take(1),
                        map((res: BasicResponse) => {
                            this.showSuccess(null, `Calculated Demographic has been ${action?.payload?.demographicId ? 'saved' : 'created'} successfully.`);
                            if (!action?.payload?.demographicId) {
                                this.store.dispatch(
                                    ShowToast({
                                        payload: {
                                            title: '',
                                            message: 'If applicable, this demographic will need to be added to the Issue Close Demographic UI for the appropriate product(s)',
                                            design: '',
                                            placement: 'TopEnd',
                                            icon: 'fa-circle-check-solid',
                                        },
                                    })
                                );
                            }
                            this.router.navigate(['/self-service/main/demographics/calculated']);
                            return DemographicsActions.SaveCalculatedDemographicSuccess({ payload: res });
                        }),
                        catchError((error) => {
                            let errorMessage = `An error has occurred while trying to ${action?.payload?.demographicId ? 'save' : 'create'} calculated demographic.`;
                            if (error && error?.error && error?.error?.errors && error?.error?.errors?.length) {
                                errorMessage = error.error.errors[0];
                            }
                            this.showError(null, errorMessage);
                            return of(DemographicsActions.SaveCalculatedDemographicError(error));
                        })
                    );
            })
        )
    );

    showSuccess(title?: string, message?: string, icon?: string) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    message: message || '',
                    design: 'Success',
                    placement: 'TopEnd',
                    icon: icon || 'fa-circle-check-solid',
                },
            })
        );
    }

    showError(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || 'Error',
                    design: 'Error',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: 'fa-circle-exclamation-solid',
                    keepOpen
                },
            })
        );
    }

    showWarning(title?: string, message?: string, keepOpen?: boolean) {
        this.store.dispatch(
            ShowToast({
                payload: {
                    title: title || '',
                    design: 'Warning',
                    placement: 'TopEnd',
                    message: message || '',
                    icon: '',
                    keepOpen
                },
            })
        );
    }

    get baseUrl() {
        return environment.campaignApiEndPoint + '/cdp';
    }

    get defaultHttpOptions() {
        return {
            withCredentials: true
        };
    }

    get currentDatabase() {
        let res;
        this.shellStore.currentDatabase$.pipe(
            take(1),
            map((database) => {
                res = database;
            })
        ).subscribe();
        return res;
    }

    get currentDatabaseId(): string {
        const idFromRoute = parseInt(this.route.snapshot?.queryParams?.databaseId);
        if (idFromRoute) {
            return idFromRoute?.toString();
        }
        return this.currentDatabase?.id?.toString();
    }

    get currentUser() {
        let res;
        this.store.select(getCurrentUser).pipe(
            take(1),
            map((user) => {
                res = user;
            })
        ).subscribe();
        return res;
    }
}
