import { BasicResponse, ResponseWrapper } from 'common';

export const manageSmsMessageInitialState: SmsMessageState = {};

export interface SmsMessageState {
    smsMessageData?: SmsMessage;
}

export interface SmsMessage {
    messageName: string;
    trackingNumber: string;
    summaryPleatData: ResponseWrapper<SummaryPleatData>;
    audienceTargetingPleatData: ResponseWrapper<AudienceTargetingData>;
    singleAudienceData: SingleAudienceData;
    messageContentPleatData: ResponseWrapper<MessageContentData>;
    testAndSeedPleatData: ResponseWrapper<TestAndSeedData>;
    availableTestersList: ResponseWrapper<AvailableTestersList>;
    assignTester: ResponseWrapper<Tester>;
    trackingPleatData: ResponseWrapper<TrackingData>;
    webTrackingStringList: ResponseWrapper<Array<WebTrackingItem>>;
    availableWebTrackingParametersList: ResponseWrapper<{ parameters: Array<ParameterItem> }>;
    webTrackingConfiguration: ResponseWrapper<WebTrackingConfigurationData>;
    additionalMessageDetails: ResponseWrapper<AdditionalDetails>;
    scheduleMessage: ResponseWrapper<BasicResponse>;
    unscheduleMessage: ResponseWrapper<BasicResponse>;
    sendTest: ResponseWrapper<BasicResponse>;
    modificationHistoryPleatData: ResponseWrapper<Array<ModificationHistoryItem>>;
    sendToOne: ResponseWrapper<BasicResponse>;
    cancelMessage: ResponseWrapper<BasicResponse>;
}

export interface CommonItem {
    description: string,
    value: string,
}

export interface SummaryPleatData {
    messageTypeId: string,
    name: string,
    scheduledDate: string,
    canEdit?: boolean,
    messageId?: number,
    notes?: string,
    trackingNumber?: string,
    typeCode?: number,
}

export interface AudienceTargetingData {
    reloadAudience: number,
    audiences: Array<AudienceItem>,
    canEdit: boolean,
    messageId: number,
}

export interface AudienceItem {
    audienceId: number,
    outputCriteriaId: number,
    selectCriteriaId: number,
    fileName: string,
    typeCode: number,
    shouldDedupe: number,
    shouldDedupeAll: number,
    statusCode: number,
    dedupedQuantity: number,
    filteredQuantity: number,
    originalQuantity: number,
    finalQuantity: number,
    uploadStatusCode: number,
    displayName: string,
    typeDescription: string,
    uploadStatusDescription: string,
    uploadNotes: string,
}

export interface SingleAudienceData {
    audience: AudienceItem,
    uploadInProgress: boolean,
}

export interface MessageContentData {
    canEdit?: boolean,
    mediaUrl?: string,
    messageContent?: string,
    availableMergeVariables?: Array<CommonItem>,
    mergeVariableDefaults?: Array<CommonItem>;
}

export interface TestersSearchParams {
    numResults: number,
    offset: number,
    sortBy: string,
    order: string,
    currentPage?: number,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
}

export interface TestAndSeedData {
    canEdit?: boolean,
    testers: Array<Tester>,
}

export interface Tester {
    testerId?: number,
    firstName?: string,
    lastName?: string,
    emailAddress?: string,
    phoneNumber?: string,
    messageId?: number,
    seedCode?: number,
    testCode?: number,
    delete?: boolean,
}

export interface AvailableTestersList {
    totalResultCount: number,
    items: Array<Tester>,
}

export interface TrackingData {
    canEdit?: boolean,
    trackOlyticsCode?: number,
    trackOpenCode?: number,
    webConfigId?: number,
    trackLinksCode?: number,
    links?: Array<Link>,
    webTrackingConfigs?: Array<WebTrackingItem>,
}

export interface Link {
    linkId?: number,
    linkName?: string,
    linkUrl?: string,
    trackLinkCode?: number,
    trackOlyticsCode?: number,
    webTrackingCode?: number,
    canWebTrack?: boolean,
    canOlyticsTrack?: boolean,
    validLinkUrl?: boolean,
}

export interface WebTrackingItem {
    code: number,
    name: string,
}

export interface ParameterItem {
    webtrackingTagParamId: number,
    webtrackingTagId: number,
    webtrackingParameterMappingId: number,
    webtrackingParameterMappingName: string,
    constantValue: string,
    sequence: number,
    webTrackingParaSampleValue: string
}

export interface WebTrackingConfigurationData {
    configId: number,
    name: string,
    separator: string,
    sampleString: string,
    tags: Array<TagData>,
}

export interface TagData {
    webtrackingTagId: number,
    tagName: string,
    sequence: number,
    parameters: Array<ParameterItem>,
}

export interface AdditionalDetails {
    canBeUnscheduled: boolean,
    canBeScheduled: boolean,
    canBeCloned: boolean,
    canBeCancelled: boolean,
    canEdit: boolean,
    recipientCount: number,
}

export interface ModificationHistoryItem {
    user: string,
    date: string,
    description: string,
}
