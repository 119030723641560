import { ResponseWrapper } from 'common';

export const paymentGatewaysInitialState: PaymentGatewaysState = {
    accounts: {
        loading: false,
        data: null
    },
    accountsLoadingState: {},
    paymentGatewayLoader: false,
    paymentCredentials: null
};

export interface PaymentGatewaysState {
    accounts: ResponseWrapper<PaymentGatewayAccountRes>;
    accountsLoadingState: { [key: string]: boolean };
    paymentGatewayLoader: boolean;
    paymentCredentials: PaymentCredentialRes;
}

export interface PaymentGatewayAccountRes {
    gatewayInProgress: boolean;
    totalResultCount: number;
    items: Array<PaymentGatewayAccount>;
}

export interface PaymentGatewayAccount {
    accountId: string,
    accountName: string,
    status: string,
    inProgress?: boolean
}

export interface PaymentCredentialRes {
    paymentCredentialsId?: number,
    databaseId?: number,
    productId?: number,
    productSpecific?: boolean
}
