import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as DeploymentTypesActions from './deployment-types.actions';

export const deploymentTypeReducer = [
    on(DeploymentTypesActions.LoadDeploymentTypes, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypesData: {
                    data: state.deploymentTypesState?.deploymentTypesData?.data,
                    loading: true
                }
            }
        };
    }),
    on(DeploymentTypesActions.LoadDeploymentTypesDataSuccess, (state: SelfServiceState, action) => {
        const deploymentTypes = [];
        if (action?.payload?.data?.items && action?.payload?.data?.items?.length > 0) {
            action?.payload?.data?.items.forEach((deploymentType) => {
                let deploymentDomain = deploymentType?.deploymentDomain;
                if (deploymentType?.deploymentDomain && deploymentType?.deploymentDomain?.includes('-')) {
                    deploymentDomain = deploymentDomain?.split('-')?.[0] || '';
                }
                deploymentTypes.push({
                    ...deploymentType,
                    deploymentDomain
                });
            });
        }
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypesData: {
                    data: {
                        totalResultCount: action?.payload?.data?.totalResultCount,
                        items: deploymentTypes
                    },
                    loading: false
                }
            }
        };
    }),
    on(DeploymentTypesActions.LoadDeploymentTypesDataError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypesData: {
                    data: state.deploymentTypesState?.deploymentTypesData.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentTypesActions.DownloadDeploymentTypesList, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                downloadLoader: true
            }
        };
    }),
    on(DeploymentTypesActions.DownloadDeploymentTypesListSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                downloadLoader: false
            }
        };
    }),
    on(DeploymentTypesActions.DownloadDeploymentTypesListError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                downloadLoader: false
            }
        };
    }),
    on(DeploymentTypesActions.GetSelectedDeploymentTypeData, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: state.deploymentTypesState?.selectedDeploymentType?.data,
                    loading: true
                }
            }
        };
    }),
    on(DeploymentTypesActions.GetSelectedDeploymentTypeDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(DeploymentTypesActions.GetSelectedDeploymentTypeDataError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: state.deploymentTypesState?.selectedDeploymentType?.data,
                    loading: false
                }
            }
        };
    }),
    on(DeploymentTypesActions.ClearDeploymentTypesData, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentTypesActions.SaveDeploymentType, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: state.deploymentTypesState?.selectedDeploymentType?.data,
                    loading: true
                }
            }
        };
    }),
    on(DeploymentTypesActions.SaveDeploymentTypeSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: state.deploymentTypesState?.selectedDeploymentType?.data,
                    loading: false
                }
            }
        };
    }),
    on(DeploymentTypesActions.SaveDeploymentTypeError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                selectedDeploymentType: {
                    data: state.deploymentTypesState?.selectedDeploymentType?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentTypesActions.GetDeploymentTypeProducts, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypeProductList: {
                    data: state.deploymentTypesState?.deploymentTypeProductList?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentTypesActions.GetDeploymentTypeProductsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypeProductList: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentTypesActions.GetDeploymentTypeProductsError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentTypesState: {
                ...state.deploymentTypesState,
                deploymentTypeProductList: {
                    data: state.deploymentTypesState?.deploymentTypeProductList?.data,
                    loading: false
                }
            }
        };
    }),
];
