import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as DeploymentProductsActions from './deployment-products.actions';

export const deploymentProductsReducers = [
    on(DeploymentProductsActions.LoadDeploymentProducts, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentProductsList: {
                    data: state.deploymentProductsState?.deploymentProductsList?.data,
                    loading: true
                }
            }
        };
    }),
    on(DeploymentProductsActions.LoadDeployemntProductsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentProductsList: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),
    on(DeploymentProductsActions.LoadDeployemntProductsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentProductsList: {
                    data: state.deploymentProductsState?.deploymentProductsList?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.DownloadDeployemntProductsList, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                downloadLoader: true
            }
        };
    }),

    on(DeploymentProductsActions.DownloadDeployemntProductsListSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                downloadLoader: false
            }
        };
    }),

    on(DeploymentProductsActions.DownloadDeployemntProductsListError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                downloadLoader: false
            }
        };
    }),

    on(DeploymentProductsActions.GetProductDetails, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetProductDetailsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetProductDetailsError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.CreateDeploymentProduct, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.CreateDeploymentProductSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.CreateDeploymentProductError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.UpdateEmailDeploymentProduct, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.UpdateEmailDeploymentProductSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.UpdateEmailDeploymentProductError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: state.deploymentProductsState?.selectedDeploymentProduct?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentTypesWithoutProducts, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentsWithoutProducts: {
                    data: state.deploymentProductsState?.deploymentsWithoutProducts?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentTypesWithoutProductsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentsWithoutProducts: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentTypesWithoutProductsError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentsWithoutProducts: {
                    data: state.deploymentProductsState?.deploymentsWithoutProducts?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDesignations, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDesignations: {
                    data: state.deploymentProductsState?.deploymentDesignations?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDesignationsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDesignations: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDesignationsError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDesignations: {
                    data: state.deploymentProductsState?.deploymentDesignations?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDomains, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDomains: {
                    data: state.deploymentProductsState?.deploymentDomains?.data,
                    loading: true
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDomainsSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDomains: {
                    data: action.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.GetDeploymentDomainsError, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                deploymentDomains: {
                    data: state.deploymentProductsState?.deploymentDomains?.data,
                    loading: false
                }
            }
        };
    }),

    on(DeploymentProductsActions.ClearDeployemntProductsData, (state: SelfServiceState) => {
        return {
            ...state,
            deploymentProductsState: {
                ...state.deploymentProductsState,
                selectedDeploymentProduct: {
                    data: null,
                    loading: false
                }
            }
        };
    })
];
