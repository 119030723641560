import { on } from '@ngrx/store';
import * as IntegrationsGoogleAdManagerActions from './google-ad-manager.actions';
import { IntegrationsState } from '../interfaces';

export const initialGoogleAdManagerState = {
    javascriptSetup: {
        loading: false,
        data: null
    },
};

export const IntegrationsGoogleAdManagerReducer = [
    on(IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetup, (state: IntegrationsState) => {
        return {
            ...state,
            googleAdManager: {
                ...state?.googleAdManager,
                javascriptSetup: {
                    loading: true,
                    data: state.googleAdManager?.javascriptSetup?.data
                }
            }
        };
    }),
    on(IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetupSuccess, (state: IntegrationsState, action) => {
        return {
            ...state,
            googleAdManager: {
                ...state?.googleAdManager,
                javascriptSetup: {
                    loading: false,
                    data: action?.payload?.data
                }
            }
        };
    }),
    on(IntegrationsGoogleAdManagerActions.GetGoogleJavascriptSetupError, (state: IntegrationsState) => {
        return {
            ...state,
            googleAdManager: {
                ...state?.googleAdManager,
                javascriptSetup: {
                    loading: false,
                    data: state.googleAdManager?.javascriptSetup?.data
                }
            }
        };
    }),
];
