import { on } from '@ngrx/store';
import { SelfServiceState } from '../interfaces';
import * as DemographicsActions from './demographics.actions';

export const demographicsReducers = [
    on(DemographicsActions.LoadDemographicsData, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                demographicsData: {
                    data: state.demographicsState?.demographicsData?.data,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.LoadDemographicsDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                demographicsData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.LoadDemographicsDataError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                demographicsData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetDemographicData, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.GetDemographicDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetDemographicDataError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: state.demographicsState?.selectedDemographicData?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.ClearDemographicData, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: null,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.SaveDemographicData, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.SaveDemographicDataSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: state.demographicsState?.selectedDemographicData?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.SaveDemographicDataError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                selectedDemographicData: {
                    data: state.demographicsState?.selectedDemographicData?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.DownloadDemographicsList, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                downloadLoader: true
            }
        };
    }),

    on(DemographicsActions.DownloadDemographicsListSuccess, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                downloadLoader: false
            }
        };
    }),

    on(DemographicsActions.DownloadDemographicsListError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                downloadLoader: false
            }
        };
    }),

    on(DemographicsActions.GetDemographicDeleteModalData, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                deleteModalData: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.GetDemographicDeleteModalDataSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                deleteModalData: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetDemographicDeleteModalDataError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                deleteModalData: {
                    data: state.demographicsState?.deleteModalData?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetDataLoaderDemographicTemplates, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                dataLoaderTemplates: {
                    data: null,
                    loading: true
                }
            }
        };
    }),

    on(DemographicsActions.GetDataLoaderDemographicTemplatesSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                dataLoaderTemplates: {
                    data: action?.payload?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.GetDataLoaderDemographicTemplatesError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                dataLoaderTemplates: {
                    data: state.demographicsState?.dataLoaderTemplates?.data,
                    loading: false
                }
            }
        };
    }),

    on(DemographicsActions.CheckDemographicName, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                existingDemographicName: false
            }
        };
    }),

    on(DemographicsActions.CheckDemographicNameSuccess, (state: SelfServiceState, action) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                existingDemographicName: !action?.payload?.success
            }
        };
    }),

    on(DemographicsActions.CheckDemographicNameError, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                existingDemographicName: false
            }
        };
    }),

    on(DemographicsActions.ClearDemographicNameCheck, (state: SelfServiceState) => {
        return {
            ...state,
            demographicsState: {
                ...state.demographicsState,
                existingDemographicName: false
            }
        };
    }),
];
